import $ from 'jquery';
import Utils from "../utils/Utils.js";
import SETTINGS from "../controllers/Settings.js";
import AppStatus from "../controllers/AppStatus.js";
import * as THREE from 'three';

import LoaderXHR from "./LoaderXHR.js"
import LoaderScript from "./LoaderScript.js"
import LoaderTexture from "./LoaderTexture.js"
import LoaderImage from "./LoaderImage.js"
import LoaderPrivateXHR from "./LoaderPrivateXHR.js"

var MAX_PARALLEL_ITEMS = 4,
	numLoadingItems = 0;

function itemDone() {
	numLoadingItems = Math.max(numLoadingItems-1, 0);
}

class PageBatch {
	constructor(pageId, isBranch) {
		this.pageId = pageId;
		this.isBranch = isBranch;
		this.branchView = null;
		this.parent = null;
		this.childs = [];
		this.currentBranchAdd = 0;

		this.loaded = false;
		this.loading = false;
		this.loadItems = [];
		this.altItems = [];
		this.loadedBytes = 0;
	}

	recursiveGetLoadItems(arr) {
		if (!this.isBranch) return arr.concat(this.loadItems);

		//proritize selected branch
		var priorityBranch = -1;
		if (this.branchView) {
			priorityBranch = this.branchView.selectedBranch;
			if (this.childs[priorityBranch]) arr = this.childs[priorityBranch].recursiveGetLoadItems(arr);
		}

		var shouldCheckForLoading = false;
		if ((this.branchView && this.branchView.isBranch) || SETTINGS.AUTO_MODE) shouldCheckForLoading = true;

		//load other branches (but only if necessary and branch is possible)
		for (var i=0; i<this.childs.length; i++) {
			if (i!==priorityBranch && (!shouldCheckForLoading || (shouldCheckForLoading && this.branchView.shouldLoadBranch(i)))) arr = this.childs[i].recursiveGetLoadItems(arr);
			// if (this.branchView && this.branchView.isBranch && this.branchView.shouldLoadBranch(i)) {
			// 	console.log(i, this.branchView, this.branchView.shouldLoadBranch(i));
			// }
		} //
		return arr;
	}

	load(mainPage) {

		var lb = 0;
		for (var i=0; i<this.loadItems.length; i++) {
			if (this.loadItems[i]) lb += this.loadItems[i].loadedBytes;
		}
		this.loadedBytes = Math.max(this.loadedBytes, lb);

		if (this.loaded) return;
		var ov = this.loading;
		this.loading = false;
		var items = !this.isBranch ? this.loadItems : this.recursiveGetLoadItems([]);
		var n = 0;
		var done = true;
		var mainPageLoading = mainPage ? 1 : 0; //always leave room for current page


		//main page force reload errors
		if (mainPage) {
			for (var i=0; i<items.length; i++) {
				if (items[i].loadError && items[i].reset) {
					done = false;
					items[i].lastErrorReset = items[i].lastErrorReset||0;
					if (performance.now() - items[i].lastErrorReset >= 5000) {
						console.log("Error: Resetting");
						if (items[i].loading) numLoadingItems--;
						items[i].reset();
						items[i].lastErrorReset = performance.now();
					}
				}
			}
		}

		//go through all and load
		while (n<items.length && (numLoadingItems<MAX_PARALLEL_ITEMS||mainPageLoading>0)) {
			if (items[n].loadError) done = false;
			if (!items[n].loaded && !items[n].loading && !items[n].loadError) {
				numLoadingItems++;
				mainPageLoading--;
				done = false;
				this.loading = true;
				items[n].start(itemDone, itemDone);
			} else if (items[n].loading && !items[n].loadError) {
				mainPageLoading--;
				done = false;
				this.loading = true;
			}
			n++;
		}


		// if (!ov && this.loading) console.log("PageLoader: starting to load", this.pageId);
		// if (done) console.log("PageLoader: Done loading page",this.pageId);
		this.loaded = done;
		return;	
	}

	dispose(minPage, maxPage) {
		if (!this.loaded && !this.loading) return;
		console.log("PageLoader: disposing", this.pageId);
		this.loaded = false;
		this.loading = false;
		var items = !this.isBranch ? this.loadItems : this.recursiveGetLoadItems([]);
		for (var i=0; i<items.length; i++) {
			// console.log("reset item", items[i], items[i].minPage,minPage, items[i].maxPage,maxPage);

			if (items[i].loaded && items[i].maxPageId < minPage || items[i].minPageId > maxPage) {
			//&& !this.pageContains(items[i], minPage) && !this.pageContains(items[i], maxPage)) { //}  items[i].maxPageId<minPage || items[i].minPageId>maxPage)) {
				// console.log("disposing", items[i]);
				if (items[i]) items[i].reset();
			}
		}
	}
}

class PageLoader {

	constructor() {
		this.updateBound = this.update.bind(this);
		this.pages = [];
		this.currentPageId = -1;
		this.started = false;
		this.currentPageBatchToAdd = null;
		this.addAltItems = false;
	}

	addPageLoadState(isBranch, info) {
		if (this.currentPageBatchToAdd && this.currentPageBatchToAdd.isBranch) {
			var b = new PageBatch(this.pages.length-1, isBranch);
			b.info = info;
			b.parent = this.currentPageBatchToAdd;
			this.currentPageBatchToAdd.childs.push(b);
			this.currentPageBatchToAdd = b;
		} else {
			this.currentPageBatchToAdd = new PageBatch(this.pages.length, isBranch);
			this.currentPageBatchToAdd.info = info;
			this.pages.push( this.currentPageBatchToAdd );
			this.currentPageId = this.pages.length-1;
		}
	}
	popPageLoadState() {
		this.currentPageBatchToAdd = this.currentPageBatchToAdd ? this.currentPageBatchToAdd.parent : null;
	}

	pushBranch() {
		var b = new PageBatch(this.pages.length-1, true);
		b.parent = this.currentPageBatchToAdd;
		this.currentPageBatchToAdd.childs.push(b);
		this.currentPageBatchToAdd = b;

		// var parent = this.currentPageBatchToAdd.currentBranchAdd?this.currentPageBatchToAdd.currentBranchAdd.parent:null;
		// this.currentPageBatchToAdd.currentBranchAdd = [];
		// this.currentPageBatchToAdd.currentBranchAdd.parent = this.currentPageBatchToAdd.currentBranchAdd?this.currentPageBatchToAdd.currentBranchAdd.parent:null;
		// this.currentPageBatchToAdd.childs.push(this.currentPageBatchToAdd.currentBranchAdd);
	}
	popBranch() {
		this.currentPageBatchToAdd = this.currentPageBatchToAdd ? this.currentPageBatchToAdd.parent : null;
		// this.currentPageBatchToAdd.currentBranchAdd = this.currentPageBatchToAdd.currentBranchAdd.parent;
	}


	pushAltState() {
		this.addAltItems = true;
	}
	popAltState() {
		this.addAltItems = false;
	}
	
	// //
	// // Branches
	// //
	// pushBranchLoadState(isBranch, branchView) {
	// 	var b = new PageBatch(this.pages.length-1, isBranch);
	// 	b.parent = this.currentPageBatchToAdd;
	// 	this.currentPageBatchToAdd.childs.push(b);
	// 	this.currentPageBatchToAdd = b;
	// }
	// popBranchLoadState(isBranch) {
	// 	this.currentPageBatchToAdd = this.currentPageBatchToAdd.parent;
	// }
	setBranchView(branchView) {
		this.currentPageBatchToAdd.branchView = branchView;
	}

	//
	// Add items to load
	//
	addXHR(batchName, path, type, forceReload) {
		if (SETTINGS.EDIT_MODE || SETTINGS.ERROR_MODE || AppStatus.forceMainLoader) {
			return Loader.addXHR(batchName, path, type, forceReload);
		}
		if (!this.currentPageBatchToAdd) console.error("TRYING TO LOAD BUT NO PAGE!");
		var loadItem;
		if (Loader.allAssets[path] && !forceReload) {
			loadItem = Loader.allAssets[path];
			loadItem.minPageId = Math.min(this.currentPageId, loadItem.minPageId);
			loadItem.maxPageId = Math.max(this.currentPageId, loadItem.maxPageId);
			this.currentPageBatchToAdd.loadItems.push(Loader.allAssets[path]);
			return Loader.allAssets[path].data;
		}

		loadItem = type=='private' ? new LoaderPrivateXHR(path, 'arraybuffer') : new LoaderXHR(path, type);
		loadItem.minPageId = Math.min(this.currentPageId, loadItem.minPageId);
		loadItem.maxPageId = Math.max(this.currentPageId, loadItem.maxPageId);

		Loader.allAssets[path] = loadItem;
		if (this.addAltItems) {
			loadItem.data.manualStart = loadItem.start;
			this.currentPageBatchToAdd.altItems.push(loadItem);
		}
		else this.currentPageBatchToAdd.loadItems.push(loadItem);

		if (this.currentFreezeList) this.currentFreezeList.push(loadItem);
		return loadItem.data;
	}

	addScript(batchName, path) {
		if (SETTINGS.EDIT_MODE || SETTINGS.ERROR_MODE) return Loader.addScript(batchName, path);
		if (!this.currentPageBatchToAdd) console.error("TRYING TO LOAD BUT NO PAGE!");
		var loadItem;
		if (Loader.allAssets[path]) {
			loadItem = Loader.allAssets[path];
			loadItem.minPageId = Math.min(this.currentPageId, loadItem.minPageId);
			loadItem.maxPageId = Math.max(this.currentPageId, loadItem.maxPageId);
			this.currentPageBatchToAdd.loadItems.push(Loader.allAssets[path]);
			return Loader.allAssets[path].data;
		}

		loadItem = new LoaderScript(path);
		loadItem.minPageId = Math.min(this.currentPageId, loadItem.minPageId);
		loadItem.maxPageId = Math.max(this.currentPageId, loadItem.maxPageId);

		Loader.allAssets[path] = loadItem;
		this.currentPageBatchToAdd.loadItems.push(loadItem);
		return loadItem.data;
	}

	addImage(batchName, path) {
		if (SETTINGS.EDIT_MODE || SETTINGS.ERROR_MODE) return Loader.addImage(batchName, path);
		if (!this.currentPageBatchToAdd) console.error("TRYING TO LOAD BUT NO PAGE!");
		var loadItem;
		if (Loader.allAssets[path]) {
			loadItem = Loader.allAssets[path];
			loadItem.minPageId = Math.min(this.currentPageId, loadItem.minPageId);
			loadItem.maxPageId = Math.max(this.currentPageId, loadItem.maxPageId);
			this.currentPageBatchToAdd.loadItems.push(Loader.allAssets[path]);
			return Loader.allAssets[path].data;
		}


		loadItem = new LoaderImage(path);
		loadItem.minPageId = Math.min(this.currentPageId, loadItem.minPageId);
		loadItem.maxPageId = Math.max(this.currentPageId, loadItem.maxPageId);

		Loader.allAssets[path] = loadItem;
		this.currentPageBatchToAdd.loadItems.push(loadItem);
		return loadItem.data;
	}

	start() {
		if (this.started) return;
		this.started = true;
		if (SETTINGS.EDIT_MODE || SETTINGS.ERROR_MODE) MAX_PARALLEL_ITEMS = 10;
		this.updateBound();
	}
	
	update() {
		window.requestAnimationFrame(this.updateBound);

		//stop if loader is active
		// if (!Loader.done) return;

		MAX_PARALLEL_ITEMS = (SETTINGS.INTRO_MODE || SETTINGS.EDIT_MODE || SETTINGS.ERROR_MODE) ? 10 : 4;

		//-------------------
		//
		// Going forward
		//
		//-------------------
		var startPoint = AppStatus.currentPage,
			endPoint = 0,
			minPage = AppStatus.currentPage-15,
			maxPage = AppStatus.currentPage+55,
			landingPage = AppStatus.currentPage==0 && SETTINGS.INTRO_MODE && !AppStatus.goingBackwards;
		if (landingPage) endPoint = maxPage =  6;
		if (AppStatus.goingBackwards) {
			minPage = AppStatus.currentPage-35;
		}

		if (numLoadingItems < MAX_PARALLEL_ITEMS) {

			//load backwards
			if (AppStatus.goingBackwards) {
				minPage = AppStatus.currentPage-35;
				if (window.disposeMode) minPage = AppStatus.currentPage-4;
				endPoint = Utils.clamp(AppStatus.currentPage-30, -1, this.pages.length);

				if (this.pages[AppStatus.currentPage]) {
					this.pages[AppStatus.currentPage]
				}

				for (var i=AppStatus.currentPage; i>endPoint; i--) {
					this.pages[i].load(i==AppStatus.currentPage);
					if (numLoadingItems >= MAX_PARALLEL_ITEMS) break;
				}
				if (numLoadingItems < MAX_PARALLEL_ITEMS) {
					endPoint = Utils.clamp(AppStatus.currentPage+40, 0,this.pages.length);
					for (var i=AppStatus.currentPage+1; i<endPoint; i++) {
						this.pages[i].load();
						if (numLoadingItems >= MAX_PARALLEL_ITEMS) break;
					}
				}

			//load forward
			} else {
				endPoint = Utils.clamp(AppStatus.currentPage+(landingPage?9:50), 0, this.pages.length);
				for (var i=AppStatus.currentPage; i<endPoint; i++) {
					this.pages[i].load(i==AppStatus.currentPage);
					if (numLoadingItems >= MAX_PARALLEL_ITEMS) break;
				}
				if (numLoadingItems < MAX_PARALLEL_ITEMS) {
					endPoint = Utils.clamp(AppStatus.currentPage-10, -1,this.pages.length);
					for (var i=AppStatus.currentPage-1; i>endPoint; i--) {
						this.pages[i].load();
						if (numLoadingItems >= MAX_PARALLEL_ITEMS) break;
					}
				}
			}
		}
		
		//dispose previously loaded/loading pages sometimes (go through slowly)
		var targetDispose = AppStatus.currentFrame % this.pages.length;
		if (window.disposeMode) minPage = AppStatus.currentPage-5;
		if (!SETTINGS.INTRO_MODE && (targetDispose < minPage || targetDispose > maxPage)) {
			this.pages[targetDispose].dispose(minPage, maxPage);
		}
	}


	logBytes() {
		var pageSizeInfo = {t:AppStatus.currentTaskId, tasks:[]};
		var total = 0; for (var i=0; i<this.pages.length; i++) {
			var size = (this.pages[i].loadedBytes/1024/1024);
			total += size;
			pageSizeInfo.tasks.push({page:i, size:size, info: size>1.0 ? JSON.stringify(this.pages[i].info) : this.pages[i].info.text})
			// console.log("Page:",this.pages[i].pageId,this.pages[i].loadedBytes/1024/1024);
		}
		pageSizeInfo.total = total;
		// console.log(JSON.stringify(pageSizeInfo, null, 4));

		pageSizeInfo.tasks = pageSizeInfo.tasks.sort(function(a,b) {
			return (a.size||0) > (b.size||0) ? -1 : 1
		});
		pageSizeInfo.tasks.sort(function(a,b) {
			return (a.size||0) > (b.size||0) ? -1 : 1
		});
		pageSizeInfo.tasks = pageSizeInfo.tasks.sort(function(a,b) {
			return (a.size||0) > (b.size||0) ? -1 : 1
		});

		console.log("------")
		console.log("------")
		console.log("------")
		console.log("------")
		console.log(JSON.stringify(pageSizeInfo, null, 4));
	}

	//
	// utils
	//
	freezeList() {
		this.currentFreezeList = [];
		return this.currentFreezeList;
	}
	unfreezeList() {
		this.currentFreezeList = null;
	}
	loadFreezeList(list) {
		this.currentPageBatchToAdd.loadItems = this.currentPageBatchToAdd.loadItems.concat(list);
	}
};

window.PageLoader = window.PageLoader || new PageLoader();
export default window.PageLoader;

