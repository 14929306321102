import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';
import Fbo from '../utils/Fbo.js'
import RendererController from '../controllers/RendererController.js';
import BranchController from '../controllers/BranchController.js';

import MultiStorage from '../utils/MultiStorage.js';

import MottoView from './MottoView.js';
import MottoViewCreate from './MottoViewCreate.js'
import BranchDebugView from '../views/BranchDebugView.js';

// window.totalBranchId = 0;

//------------------------
// 
//  Base class for all views
//
//-------------------------
class BranchView {
	constructor(_info, batchName, doneCallback) {
		this.isBranch = true;
		this.branchSelected = false;
		this.selectedBranch = 0;
		this.branchLocked = false; //should just skip completely the alternative branches

		this.info = _info||{};
		this.params = this.info.params || {};

		this.started = false;
		this.active = false;
		this.firstSet = false;
		this.gotViews = false;
		this.gotViewsId = 0;
		this.lastCondition = false;
		this.waitForConditions = true;
		this.isCameraRefused = this.info.isCameraRefused;

		this.isAllowCamera = !!this.info.isAllowCamera;

		this.isLoaderView = this.info.isLoaderView||false;

		this.activeViews = {};

		this.branchId = BranchController.currentId++;

		var tasksState = MultiStorage.getState();
		tasksState.branchSelected = tasksState.branchSelected||{};
		if (!SETTINGS.STORYBOARD_MODE) {
			if (tasksState.branchSelected[this.branchId]) {
				tasksState.branchSelection = tasksState.branchSelection||{};
				this.branchSelected = true;
				this.selectedBranch = tasksState.branchSelection[this.branchId] || 0;
				// console.log("SETTING BRANCH FROM COOKIES", this.info, this.selectedBranch);
			}
		} else {

			tasksState.branchSelectedStoryboard = tasksState.branchSelectedStoryboard||{};
			if (tasksState.branchSelectedStoryboard[this.branchId]) {
				tasksState.branchSelectionStoryboard = tasksState.branchSelectionStoryboard||{};
				this.branchSelected = true;
				this.selectedBranch = tasksState.branchSelectionStoryboard[this.branchId] || 0;
				// console.log("STORYBOARD SELECTING BRANCH",this.selectedBranch,tasksState.branchSelectionStoryboard);
			}
			if (this.info.branches && this.info.branches.length > 1) {
				for (var i=0; i<this.info.branches.length; i++) {
					if (/outside_not_allowed/.test(this.info.branches[i].condition)) {
						this.selectedBranch = SETTINGS.OUTSIDE_ALLOWED?1:0;
						console.log("SELECTED BRANCH OUTSIDE", this.selectedBranch);
					}
				}
			}
		}
		if (this.info.isAllowCamera) {
			this.branchSelected = false;
			this.selectedBranch = 0;
				// console.log("NO SETTING BRANCH FROM COOKIES", this.info, this.selectedBranch);
		}

		//----------------------
		//
		// Create all sub views
		//
		//----------------------
		this.branches = [];

		if (SETTINGS.STORYBOARD_MODE) {
			this.info.selectedBranch = this.selectedBranch;
			this.debugView = new BranchDebugView(this.info, batchName, doneCallback);
			this.debugView.onchange = function(e) {
				this.branchSelected = true;
				this.selectedBranch = parseInt(e.target.value,10);
				// console.log("SELECTING BRANCH FOR DEBUG VIEW",this.selectedBranch);
				var tasksState = MultiStorage.getState();
				tasksState.branchSelectedStoryboard = tasksState.branchSelectedStoryboard||{};
				tasksState.branchSelectionStoryboard = tasksState.branchSelectionStoryboard||{};
				tasksState.branchSelectedStoryboard[this.branchId] = this.branchSelected;
				tasksState.branchSelectionStoryboard[this.branchId] = this.selectedBranch;
				MultiStorage.setState(tasksState);
				if (this.doneCallback) this.doneCallback();
			}.bind(this);
			this.debugView.selectedBranch = this.selectedBranch;
		}

		this.introModeBranch = false;
		this.defaultTask = 0;
		for (var i=0; i<this.info.branches.length; i++) {
			var branchViews = [];
			var tasks = this.info.branches[i].tasks||[];
			// if (this.info.branches[i].condition == 'default') {this.selectedBranch = this.defaultTask = i;}
			if (SETTINGS.STORYBOARD_MODE) branchViews.push(this.debugView);
			if (this.info.branches[i].condition== "intro_mode") {
				this.introModeBranch = true;
			}

			PageLoader.pushBranch()
			// PageLoader.setBranchView(this.info.branches[i] );
			for (var j=0; j<tasks.length; j++) {
				var t = MottoViewCreate(tasks[j], doneCallback, batchName);
				t.parentBranch = this;
				if (SETTINGS.STORYBOARD_MODE && t.isBranch) branchViews.push(t.debugView);
				branchViews.push(t);
			}
			PageLoader.popBranch();


			this.branches.push(branchViews);
		}

		if (this.selectedBranch > this.branches.length) this.info.selectedBranch = this.selectedBranch = 0;


		// this.uid = (window.totalBranchId++).toString();
	}

	isBranchSelected() {
		return this.checkCondition(); //this.branchSelected || this.active;
	}
	checkCondition() {
		if (SETTINGS.STORYBOARD_MODE) {
			return true
		}

		// if (this.info.branches[0].condition == 'camera_not_allowed') {
		// 	if (!this.active) console.log('wtf');
		// 	console.log(">>>>>",this.active, this.selectedBranch, this.branchSelected, this.activeViews);
		// }

		if (this.active) {
			// this.selectedBranch = this.selectedBranch;
			this.branchSelected = true;
			return true;
		}

		// if (this.active) {console.log("branch active, returning cond"); return this.lastCondition;};
		var os = this.branchSelected, oi = this.selectedBranch;
		var bs = false;
		// for (var i=this.info.branches.length-1; i>-1; i--) {
		for (var i=0; i<this.info.branches.length; i++) {
			if (!bs && BranchController.checkCondition(this.info.branches[i].condition, this.info.branches[i].condition_id)) {
				// console.log("condition selected:", this.info.branches[i].condition, this.info.branches[i].condition_id);
				this.selectedBranch = i;
				bs = true;
				// if ('camera_allowed' == this.info.branches[i].condition || 'camera_not_allowed' == this.info.branches[i].condition) {
				// 	if (this.active) BranchController.lockedCameraCondition = true; else if (!this.started) BranchController.lockedCameraCondition = false;
				// }
			}
		}

		this.branchSelected = this.branchSelected||bs;
		if (this.branchSelected && !os || this.selectedBranch !== oi) {
			AppStatus.recalculateNumPages = true;
			tasksState = MultiStorage.getState();
			tasksState.branchSelected = tasksState.branchSelected||{};
			tasksState.branchSelection = tasksState.branchSelection||{};
			tasksState.branchSelected[this.branchId] = this.branchSelected;
			tasksState.branchSelection[this.branchId] = this.selectedBranch;
			MultiStorage.setState(tasksState);
		}

		if (this.branchSelected) {
			if (this.branches[this.selectedBranch] && (this.info.branches[this.selectedBranch].condition == "record"||this.info.branches[this.selectedBranch].condition=="record_either")) this.branchLocked = true;

			for (var i=0; i<this.branches[this.selectedBranch].length; i++) {
				var t = this.branches[this.selectedBranch][i];
				if (t.isBranch) {
					t.checkCondition();
				}
			}
		}


		this.lastCondition = this.branchSelected||this.active;
		return this.lastCondition;
	}

	setPosition(px) {

		// if (this.positionX > 1.9999 && this.positionX < -1.9999) {
		// 	this.started = true;
		// } else {
		// 	this.started = false;
		// }

		// if (this.positionX < 0.9999 && this.positionX > -0.9999 && !this.locked) {
		// 	this.active = true;
		// } else {
		// 	this.active = false;
		// }
	}

	setPage(x, numPages) {
		for (var i=0; i<this.branches.length; i++) {
			for (var j=0; j<this.branches[i].length; j++) {
				this.branches[i][j].setPage(x+j, numPages);
			}
		}
	}

	getAllViews() {
		var n = [];
		for (var i=0; i<this.branches.length; i++) {
			for (var j=0; j<this.branches[i].length; j++) {
				var t = this.branches[i][j];
				if (t.isBranch) {
					n = n.concat(t.getAllViews());
				} else {
					n.push(t)
				}
			}
		}
		return n;
	}


	activateView(vid) {
		this.activeViews[vid] = true;
		this.active = true;
		if (this.parentBranch) this.parentBranch.activateView(vid);
	}
	deactivateView(vid) {
		if (this.activeViews[vid]) {
			delete this.activeViews[vid];
			this.active = Object.keys(this.activeViews).length > 0;
		}
		if (this.parentBranch) this.parentBranch.deactivateView(vid);
	}
	unlockRight() {
		this.lockRight = false;
		for (var i=0; i<this.branches.length; i++) {
			for (var j=0; j<this.branches[i].length; j++) {
				this.branches[i][j].unlockRight();
			}
		}
	}

	getViews() {
		// console.log("getting views for branch", this.info.selectedBranch, this.selectedBranch, this.branches, this.branches[this.selectedBranch]);

		this.checkCondition();

		// console.log("getting views for branch", this.info.selectedBranch, this.selectedBranch, this.branches, this.branches[this.selectedBranch]);

		if (this.gotViews && this.selectedBranch !== this.gotViewsId && !this.active) {
			for (var i=0; i<this.branches[this.gotViewsId].length; i++) {
				var t = this.branches[this.gotViewsId][i];
				if (t.active) t.deactivate();
				if (t.started) t.stop();
			}
			this.gotViews = false;
			this.gotViewsId = this.selectedBranch;
		}
		this.gotViews = true;
		this.gotViewsId = this.selectedBranch;

		var currentViews = [];
		var onBranch = true;
		for (var i=0; i<this.branches[this.selectedBranch].length; i++) {
			var t = this.branches[this.selectedBranch][i];
			if (t.isBranch) {
				var selected = t.isBranchSelected();
				if (selected) {
					currentViews = currentViews.concat(t.getViews());
				} else {
					onBranch = false;
				}
			} else if (onBranch) {
				currentViews.push(t);
			}
		}
		return currentViews;
	}

	isReady() {
		return this.branchSelected;
	}

	shouldLoadBranch(id) {
		if (id == this.selectedBranch || SETTINGS.AUTO_MODE) return true;
		if (this.branchLocked) return false;
		if (this.info.branches[id]) {
			if (BranchController.altBranchIsImpossible[this.info.branches[id].condition]) return false;
			return true;
		}
		return false;
	}

	// getBranchUID() {
	// 	var uid = this.uid + this.selectedBranch.toString();
	// 	for (var i=0; i<this.branches[this.selectedBranch].length; i++) {
	// 		var t = this.branches[this.selectedBranch][i];
	// 		if (t.isBranch) {
	// 			uid += t.getBranchUID();
	// 		}
	// 	}
	// 	return uid;
	// }
}
export default BranchView;

