import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';
import Fbo from '../utils/Fbo.js'
import RendererController from '../controllers/RendererController.js';
import MultiStorage from '../utils/MultiStorage.js';

import MontageController from '../controllers/MontageController.js';
import Montage from '../objects/Montage.js';
import SpritesheetVideo from '../objects/SpritesheetVideo.js';

import MottoView from './MottoView.js';
import MontageView from './MontageView.js'

//------------------------
// 
//  Base class for all views
//
//-------------------------
class ContinueLaterView extends MontageView {

	constructor(_info, batchName, doneCallback) {
		super(_info, batchName);
		this.doneCallback = doneCallback;
		this.lockRight = true;
		this.audioStarted = false;
		
	}
	
	//
	// When starting - decode image for rendering
	//
	start() {
		if (this.started) return;
		super.start();
	}

	activate() {
		if (this.active) return;
		super.activate();
		this.lockRight = true;

		// var globalState = MultiStorage.getGlobalState();
		// globalState.tasksFinished = globalState.tasksFinished||{};
		// globalState.tasksFinished[AppStatus.currentTaskId] = true;
		// MultiStorage.setGlobalState(globalState);

	}

	deactivate() {
		if (!this.active) return;
		super.deactivate();
	}

	update() {
		if (!this.started) return;
		super.update();

	}

	// deactivate() {
	// 	if (!this.active) return;

	// }

	//
	// When disposing - release image
	//
	// stop() {
	// 	if (!this.started) return;
	// 	super.stop();
	// }

	// update() {
	// 	if (!this.started) return;
	// 	super.update();
	// }

	//
	// Render image to webgl
	//
	// render() {
	// 	if (!this.active) return;
	// 	super.render();
	// }
}

export default ContinueLaterView;	

