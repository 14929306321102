/**
 *
 *  NFB Projet 2
 *  An interactive film by Vincent Morisset, Caroline Robert, Philippe Lambert and Édouard Lanctôt-Benoit
 *  Created by AATOA
 *  Produced by the National Film Board of Canada and France Télévision
 *  Copyright 2014 AATOAA
 *
 */
 //
 // Global Loader and Assets manager for the entire project
 //
import $ from 'jquery';

import Utils from "../utils/Utils.js";
import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import LoaderXHR from "./LoaderXHR.js"
import LoaderScript from "./LoaderScript.js"
import LoaderTexture from "./LoaderTexture.js"
import LoaderImage from "./LoaderImage.js"
import LoaderPrivateXHR from "./LoaderPrivateXHR.js"

//constants
window.shadersByName = {}; //for shaders
window.soundBuffersByName = {}; //for audioBuffers
window.allLoadedFiles = [];

//------------------
//
// A loading batch for a specific scene
// Just a data structure
//
//------------------
function LoaderBatch(batchName) {

	var self = this;
	this.name = batchName;
	this.started = false;
	this.done = false;
	
	this.progress = 0.0;
	this.id = 0;
	this.estimatedTotalSize = 0;
	this.completeCallbacks = null;
	this.loadError = false;
	this.startTime = 0;
	this.endTime = 0;

	this.assetsToLoad = [];
	this.assetsLoading = [];
	this.assetsLoaded = [];
	this.globalCompleteCallback = null;


	this.totalSize = 1;
	this.totalSizeAudio = 0;
	this.totalSizeVideo = 0;
	this.totalSizeOthers = 0;

	this.textureTime = 0;


	this.start = function() {
		this.startTime = performance.now();
	}
	this.end = function() {
		this.endTime = performance.now();
	}
	this.estimateLoadingSpeed = function() {
		if (this.done) {
			return (this.totalSize / 1024 / 1024) / ((this.endTime - this.startTime) / 1000);
		}
		return ((this.totalSize / 1024 / 1024) * this.getProgress()) / Math.max(1.0,(performance.now() - this.startTime) / 1000);
	}

	this.addItem = function(item) {
		this.assetsToLoad.push(item);
	}
	this.getProgress = function() {
		if (self.done) return 1.0;
		if (!self.started) return 0.0;
		self.progress = 0.0;
		self.estimatedTotalSize = 1.0;

		var actualProgress = 0.0;
		for (var i=0; i<self.assetsLoading.length; i++) {
			actualProgress += self.assetsLoading[i].getProgress();
			self.progress += self.assetsLoading[i].getProgress() * self.assetsLoading[i].weight;
		}
		for (var i=0; i<self.assetsLoaded.length; i++) {
			actualProgress += self.assetsLoaded[i].getProgress();
			self.progress += self.assetsLoaded[i].weight;
		}
		self.progress /= self.totalSize;
		return Math.min(self.progress);
	}

	this.getTotalSize = function() {
		var totalSize = 0.0;
		for (var i=0; i<self.assetsLoading.length; i++) {
			if (self.assetsLoading[i].recalculateWeight) self.assetsLoading[i].recalculateWeight();
			totalSize += self.assetsLoading[i].weight;
		}
		for (var i=0; i<self.assetsToLoad.length; i++) {
			totalSize += self.assetsToLoad[i].weight;
		}
		for (var i=0; i<self.assetsLoaded.length; i++) {
			totalSize += self.assetsLoaded[i].weight;
		}
		return totalSize;
	}
}

//------------------
//
// The Global Loader object
//
//------------------
function Loader() {

	this.MAX_PARALLEL_ITEMS = 10;
	if (SETTINGS.EDIT_MODE) this.MAX_PARALLEL_ITEMS = 10;

	//------------------
	//
	// Status
	//
	//------------------
	var self = this;
	this.started = false;
	this.done = false;
	this.currentBatch = 0;
	this.allBatches = [];
	this.allBatchesByName = {};
	this.allAssets = {};
	this.allLoadedFileNames = [];
	this.loadingAll = false;
	this.currentLoadingSpeed = 0.95;
	this.currentVideoQuality = 'normal';
	this.lowQualityPerformance = false;
	this.lastDownloadPc = 0.0;

	//------------------
	//
	// Start & status
	//
	//------------------
	this.start = function() {
		// if (self.started) return;
		self.loadingAll = true;
		self.started = true;
		self.done = false;
		//self.currentBatch = 0;
		if (self.allBatches.length <= self.currentBatch) {
			self.done = true;
			if (this.globalCompleteCallback) this.globalCompleteCallback();
			return;
		}
		self.startBatch(self.allBatches[self.currentBatch].name);
	}
	this.startBatch = function(batchName) {
		var batch = self.getBatch(batchName);

		if (batch.started) return;

		if (batchName === 'scene0') {
			console.log('SELECTING QUALITY FOR SCENE0');
			var speed = this.getDownloadSpeedCurrent();
			if (speed <= 0.5 && this.currentVideoQuality !== 'low') {
				console.log('>>> CONNECTION TOO SLOW | SWITCHING TO LOW QUALITY <<<');
				this.currentVideoQuality = 'low';
				// $.onf_nfb.stats.trackEvent('video', 'resolution', 'downloadSpeedStart', 1);
			} else {
				if (!this.currentVideoQuality == 'low') {
					// $.onf_nfb.stats.trackEvent('video', 'resolution', 'downloadSpeedAlready', 1);
					console.log('SCENE0 - USING LOW-QUALITY VIDEO ALREADY');
				}
				else {
					// $.onf_nfb.stats.trackEvent('video', 'resolution', 'normal', 1);
					console.log('SCENE0 - USING NORMAL-QUALITY VIDEO',speed);
				}
			}
		}


		batch.started = true;
		batch.start();
		self.currentBatch = batch.id;
		self.batchAdvance();
	}
	this.isComplete = function(batchName) {
		return self.getBatch(batchName).done;
	}
	this.getProgress = function(batchName) {
		if (self.done) return 1.0;
		if (!self.started) return 0.0;

		var totalSize = 525000 * 1.23,
			totalLoadedSize = 0.0;

		for (var i=0; i<this.allBatches.length; i++) {
			var size = this.allBatches[i].getTotalSize();
			// totalSize += size;
			totalLoadedSize += this.allBatches[i].getProgress() * size;
		}
		console.log('total loaded size', totalLoadedSize);
		return Utils.clamp(totalLoadedSize/totalSize,0,1);
	}
	this.getCurrentBatchProgress = function() {
		return self.allBatches[self.currentBatch].getProgress();
	}
	this.getBatchProgress = function(batchName) {
		return self.getBatch(batchName).getProgress();
	}
	this.setBatchCompleteCallback = function(batchName, callback) {
		var batch = self.getBatch(batchName);
		batch.completeCallbacks = batch.completeCallbacks || [];
		batch.completeCallbacks.push(callback);
	}
	this.setGlobalCompleteCallback = function(callback) {
		this.globalCompleteCallback = callback;
	}


	//------------------
	//
	// Batch Progression Loading Functions
	//
	//------------------
	function batchItemComplete(item) {
		if (self.currentBatch>=self.allBatches.length) return;
		var batch = self.allBatches[self.currentBatch];
		var found = false;
		for (var i=0; i<batch.assetsLoading.length; i++) {
			if (batch.assetsLoading[i] === item) {
				batch.assetsLoading.splice(i,1);
				i--;
				found = true;
			}
		}
		if (!found) {
			console.warn('Got item complete event from other batch: ',item.url,batch);
			return;
		};
		batch.assetsLoaded.push(item);
		self.batchAdvance();
	}
	function batchItemError(item) {
		console.warn('Error while loading : ', item.url, item.dataType, item.loadType );
		if (!window.oneAlert && ((item.loadType === 'xhr'||item.loadType === 'XHR') && (item.dataType === 'json'||item.dataType === 'json5') ) || (item.loadType === 'multiimages') ) {
			window.alert('Error while loading : '+ item.url );
			window.oneAlert = true;
		}
		item.loadError = true;
		batchItemComplete(item);
	}
	function batchComplete() {
		var batch = self.allBatches[self.currentBatch];

		if (!/compo-/.test(batch.name)) console.log('Batch Done : ',batch.name, '. Estimated speed: ',batch.estimateLoadingSpeed());
		if (batch.name !== 'soundInfo' && batch.name !== 'info') {
			self.currentLoadingSpeed = self.currentLoadingSpeed * 0.5 + batch.estimateLoadingSpeed() * 0.5;
		}
		batch.done = true;
		batch.end();
		batch.progress = 1.0;
		if (batch.completeCallbacks) {
			for (var i=0; i<batch.completeCallbacks.length; i++) batch.completeCallbacks[i](batch.batchName);
		}

		self.currentBatch++;
		if (self.currentBatch<self.allBatches.length) {
			self.startBatch(self.allBatches[self.currentBatch].name);
		} else {
			console.log('Loading Complete');
			self.done = true;
			if (self.globalCompleteCallback) self.globalCompleteCallback();
		}

	}
	this.batchAdvance = function() {
		var batch = self.allBatches[self.currentBatch];
		while (batch.assetsToLoad.length > 0 && batch.assetsLoading < self.MAX_PARALLEL_ITEMS) { // < self.MAX_PARALLEL_ITEMS // && batch.assetsLoading.length > 0
			var item = batch.assetsToLoad.shift();
			item.start(batchItemComplete,batchItemError);
			batch.assetsLoading.push(item);
		}

		if (batch.assetsToLoad.length == 0 && batch.assetsLoading.length == 0) {
			batchComplete();
		}
	}


	//------------------
	//
	// Handle batches
	//
	//------------------
	this.hasBatch = function(batchName) {
		return self.allBatchesByName[batchName] ? true : false;
	}
	this.createBatch = function(batchName) {
		self.getBatch(batchName);
	}
	this.getBatch = function(batchName) {
		if (self.allBatchesByName[batchName]) return self.allBatchesByName[batchName];
		var batch = new LoaderBatch(batchName);
		batch.id = self.allBatches.length;
		self.allBatchesByName[batchName] = batch;
		self.allBatches.push(batch);
		return batch;
	}

	//------------------
	//
	// Add things to load
	//
	//------------------
	this.addXHR = function(batchName, path, type, forceReload) {
		if (self.allAssets[path] && !forceReload && !AppStatus.forceMainLoader) {
			self.allAssets[path].numBatches++;
			return self.allAssets[path].data;
		}

		var version;
		if (type === 'json' || type === 'json5') {
			version = SETTINGS.VERSION;
		} else if (type === 'arraybuffer') {
			version = SETTINGS.VERSION;
		} else {
			version = SETTINGS.VERSION;
		}
		// if (forceReload) {
		// 	version = Math.random();
		// }
		var batch = self.getBatch(batchName),
			loadItem = type==='private' ? new LoaderPrivateXHR(path, 'arraybuffer') : new LoaderXHR(path, type);
		loadItem.weight = self.getFileSize(path, 3000);
		loadItem.forceReload = forceReload;

		batch.totalSize += loadItem.weight;
		batch.totalSizeOthers += loadItem.weight;
		batch.addItem(loadItem);
		
		if (!forceReload) {
			self.allAssets[path] = loadItem;
			self.allAssets[path].numBatches = (self.allAssets[path].numBatches || 0)+1;
			self.allAssets[path].referenceName = path;
		}

		return loadItem.data;
	};

	this.addAutoFill = function(batchName, params, forceReload) {
		var path = 'autofill/'+params.folder;

		if (self.allAssets[path] && !forceReload) {
			self.allAssets[path].numBatches++;
			return self.allAssets[path].data;
		}

		var batch = self.getBatch(batchName),
			loadItem = new LoaderAutoFill(params);
		loadItem.weight = 500;

		batch.totalSize += loadItem.weight;
		batch.totalSizeOthers += loadItem.weight;
		batch.addItem(loadItem);

		// if (!forceReload) {
			self.allAssets[path] = loadItem;
			self.allAssets[path].numBatches = (self.allAssets[path].numBatches || 0)+1;
			self.allAssets[path].referenceName = path;
		// }
		return loadItem.data;

	};


	this.shaderBundle = null;
	this.addShaderBundle = function(batchName, path) {
		this.shaderBundle = self.addXHR(batchName, path, 'json'); //possibility for a compiled global json/js of shaders
		return this.shaderBundle;
	};

	this.addShader = function(batchName, path) {
		if (this.shaderBundle && this.shaderBundle.value && this.shaderBundle.value[path]) {return this.shaderBundle.value[path];}
		var shader = self.addXHR(batchName, path, 'text'); //possibility for a compiled global json/js of shaders
		shadersByName[path] = shader;
		return shader;
	};

	this.addScript = function(batchName, path) {
		if (self.allAssets[path]) {
			self.allAssets[path].numBatches++;
			return self.allAssets[path].data;
		}
		var batch = self.getBatch(batchName),
			loadItem = new LoaderScript(path); //SETTINGS.DATA_URL+
		loadItem.weight = self.getFileSize(path, 3000);

		batch.totalSize += loadItem.weight;
		batch.totalSizeOthers += loadItem.weight;

		batch.addItem(loadItem);
		self.allAssets[path] = loadItem;
		self.allAssets[path].numBatches = (self.allAssets[path].numBatches||0)+1;;
		self.allAssets[path].referenceName = path;
		return loadItem.data;
	};

	this.addImage = function(batchName,path) {
		if (!!self.allAssets[path]) {
			self.allAssets[path].numBatches++;
			return self.allAssets[path].data;
		}
		var batch = self.getBatch(batchName),
			loadItem = new LoaderImage(path);
		loadItem.weight = self.getFileSize(path, 100000);

		batch.totalSize += loadItem.weight;
		batch.totalSizeVideo += loadItem.weight;

		batch.addItem(loadItem);
		self.allAssets[path] = loadItem;
		self.allAssets[path].numBatches = (self.allAssets[path].numBatches||0)+1;;
		self.allAssets[path].referenceName = path;
		return loadItem.data;
	};

	this.addTexture = function(batchName, path, params) {
		
		if (self.allAssets[path]) {
			self.allAssets[path].numBatches++;
			return self.allAssets[path].data;
		}

		// var server = params.server || SETTINGS.IMAGE_URL;
		var batch = self.getBatch(batchName);
		var loadItem;
		// if (!path || /\.dds|\.png|\.jpg|\.webp|\.gif/gi.test(path)) {
		loadItem = new LoaderTexture(path, params);
		// } else {
		// 	loadItem = new LoaderTextureSequence(path, params);
		// }
		loadItem.weight = self.getFileSize(path, 100000);

		batch.totalSize += loadItem.weight;
		batch.totalSizeVideo += loadItem.weight;

		batch.addItem(loadItem);
		self.allAssets[path] = loadItem;
		self.allAssets[path].numBatches = (self.allAssets[path].numBatches||0)+1;;
		self.allAssets[path].referenceName = path;
		return loadItem.data;
	};


	this.addDDSImage = function(batchName,path) {
		if (self.allAssets[path]) {
			self.allAssets[path].numBatches++;
			return self.allAssets[path].data;
		}
		var batch = self.getBatch(batchName),
			loadItem = new LoaderImage(SETTINGS.IMAGE_URL+path);
		loadItem.weight = self.getFileSize(path, 100000);

		batch.totalSize += loadItem.weight;
		batch.totalSizeVideo += loadItem.weight;

		batch.addItem(loadItem);
		self.allAssets[path] = loadItem;
		self.allAssets[path].numBatches = (self.allAssets[path].numBatches||0)+1;;
		self.allAssets[path].referenceName = path;
		return loadItem.data;
	};

	//------------------
	//
	// Get global assets
	//
	//------------------
	this.hasAsset = function(path, path2) {
		return this.allAssets[path] !== undefined;
	};
	this.getAsset = function(path, path2) {
		//if (path2) return self.allAssets[path+path2].value;
		if (this.allAssets[path] === undefined) {
			console.error('NO ASSET THERE ',path)
		}
		return this.allAssets[path].data;
	};

	this.getShader = function(path) {
		if (this.shaderBundle && this.shaderBundle.value && this.shaderBundle.value[path]) return this.shaderBundle.value[path];
		if (!shadersByName[path]) {
			console.warn('Shader not loaded:',path);
			return null;
		}
		return shadersByName[path].value;
	};

	this.getAudio = function(path) {
		return soundBuffersByName[path];
	};

	this.getAudioBuffer = function(path) {
		if (!soundBuffersByName[path]) {
			console.warn('Sound Not Loaded: ',path);
		}
		//if (!soundBuffersByName[path].value.buffer) console.warn('Buffer Not Decoded: ',path);
		return soundBuffersByName[path].value.buffer;
	};


	this.resolveAudioBufferPromise = function(binaryBuffer, resolve) {
		if (!binaryBuffer) {resolve(null); return;}
		if (binaryBuffer.decoded) {resolve(binaryBuffer.buffer); return;}
		if (!binaryBuffer.decoded) {
			binaryBuffer.decode().then(function(){
				resolve(binaryBuffer.buffer);
			});
		}
	};


	this.getAudioBufferPromise = function(path) {
		return new Promise(function(resolve, reject) {
			//already loaded
			if (soundBuffersByName[path] && soundBuffersByName[path].loaded) {
				self.resolveAudioBufferPromise(soundBuffersByName[path].value, resolve);
				return;
			}

			if (SETTINGS.SITE_MODE) {
				console.warn('Audio',path,'not preloaded');
			}

			//already loading
			if (self.allAssets[path]) {
				self.allAssets[path].doneCallbacks.push(function(data) {
					self.resolveAudioBufferPromise(soundBuffersByName[path].value, resolve);
				});
				return;
			}

			//
			// Else load with LoaderAudio
			//
			var pt = path;
			pt = pt.replace(/sounds\//gi, SETTINGS.AUDIO_FOLDER)
			if (SETTINGS.AUDIO_REPLACE_OGG) pt = pt.replace(/\.mp3/, '.ogg'); 
			var loadItem = new LoaderAudio(SETTINGS.AUDIO_URL+pt, true);
			loadItem.weight = self.getFileSize(pt, 20000);
			self.allAssets[path] = loadItem;

			loadItem.start(function(data) {
				
				self.allAssets[path] = loadItem;
				self.allAssets[path].numBatches = (self.allAssets[path].numBatches||0)+1;
				self.allAssets[path].referenceName = path;
				soundBuffersByName[path] = loadItem.data;
				self.resolveAudioBufferPromise(loadItem.data.value, resolve);

			}, reject);
		});
	};

	this.getXHRPromise = function(path, type) {
		return new Promise(function(resolve, reject) {

			if (self.allAssets[path]) {
				if (self.allAssets[path].data && self.allAssets[path].loaded) {
					resolve(self.allAssets[path].data);
				} else {
					// if (!SETTINGS.RELEASE_MODE) {
					// 	console.warn('Setup before loading is done:', path);
					// }
					self.allAssets[path].doneCallbacks.push(function() {
						resolve(self.allAssets[path].data);
					});
				}
				return;
			}

			if (!SETTINGS.RELEASE_MODE) {
				console.warn('XHR',path,'not preloaded');
			}

			var pt = path;
			var loadItem = new LoaderXHR(pt, type?type:'text');
			loadItem.weight = self.getFileSize(pt, 20000);
			self.allAssets[path] = loadItem;

			loadItem.start(function(data) {
				
				self.allAssets[path] = loadItem;
				self.allAssets[path].numBatches = (self.allAssets[path].numBatches||0)+1;
				self.allAssets[path].referenceName = path;
				resolve(loadItem.data);

			}, reject);

		});
	};

	this.getScriptPromise = function(path, type) {
		return new Promise(function(resolve, reject) {

			if (self.allAssets[path]) {
				if (self.allAssets[path].data && self.allAssets[path].loaded) {
					resolve(self.allAssets[path].data);
				} else {
					if (SETTINGS.SITE_MODE) {
						console.warn('Setup before loading is done:', path);
					}
					self.allAssets[path].doneCallbacks.push(function() {
						resolve(self.allAssets[path].data);
					});
				}
				return;
			}

			if (SETTINGS.SITE_MODE) {
				console.warn('XHR',path,'not preloaded');
			}

			var pt = path;
			var loadItem = new LoaderScript(SETTINGS.DATA_URL+pt);
			loadItem.weight = self.getFileSize(pt, 20000);
			self.allAssets[path] = loadItem;

			loadItem.start(function(data) {
				
				self.allAssets[path] = loadItem;
				self.allAssets[path].numBatches = (self.allAssets[path].numBatches||0)+1;
				self.allAssets[path].referenceName = path;
				resolve(loadItem.data);

			}, reject);

		});
	};



	//------------------
	//
	// Get preloader progress
	//
	//------------------
	this.getPreloaderProgress = function(estimatedSize, startBatch, endBatch) {
		//Loader.allBatches
		if (this.currentBatch >= endBatch) return 1.0;

		var maxv = Math.min(endBatch, this.allBatches.length),
			totalSize = 0,
			currentSize = 0;

		for (var i=startBatch; i<maxv; i++) {
			if (this.allBatches[i].done) {
				totalSize += this.allBatches[i].getTotalSize();
			} else {
				totalSize += this.allBatches[i].getProgress() * this.allBatches[i].getTotalSize();
				i = maxv;
			}
		}
		return Utils.clamp(totalSize / estimatedSize,0.0,1.0);
	};
	



	//------------------
	//
	// Get Download Speed
	//
	//------------------
	this.getDownloadSpeedCurrent = function() {
		if (!self.currentBatch || self.allBatches.length >= self.currentBatch) return this.currentLoadingSpeed;
		var cb = self.allBatches[self.currentBatch];
		if (cb.getProgress() > 0.33) this.currentLoadingSpeed = this.currentLoadingSpeed * 0.5 + cb.estimateLoadingSpeed() * 0.5;
		return this.currentLoadingSpeed;
	};

	this.getDownloadSpeed = function() {
		return this.currentLoadingSpeed;
	};

	this.getFileList = function() {
		var list = [], exists = {};
		for (var o in this.allAssets) {
			list.push(o);
		}
		list = list.concat(this.allLoadedFileNames);
		console.log(JSON.stringify(list));
		// return list;
	};

	//
	// Get list of files added
	//
	this.createAudioList = function() {
		this.currentAudioList = [];
	}
	this.getAudioList = function() {
		var t = this.currentAudioList;
		this.currentAudioList = null;
		return t;
	};
	this.createTextureList = function() {
		this.currentTextureList = [];
	}
	this.getTextureList = function() {
		var t = this.currentTextureList;
		this.currentTextureList = null;
		return t;
	};


	this.getFileSize = function getFileSize(path, defaultv) {
		return defaultv;
		// if (!path || !SETTINGS.FILESIZES.value) return defaultv;
		// var pathlist = path.split('/'),
//        	currentObj = SETTINGS.FILESIZES.value;
		// for (var i=0; i<pathlist.length-1; i++) {
		// 	if (!currentObj[pathlist[i]]) {console.log('no filesize for file',path); return defaultv;}
		// 	currentObj = currentObj[pathlist[i]];
		// }
		// if (typeof currentObj !== 'number') {console.log('no filesize for file',path);};
		// return (typeof currentObj === 'number') ? currentObj : defaultv;
	}

	//------------------
	//
	// Cleanup and dispose batch once it's done ~~~~~
	//
	//------------------
	this.disposeBatch = function(batchName) {
		var batch = self.getBatch(batchName);
		// if (!batch.isComplete()) {
		// 	console.error('disposing incomplete batch: ',batchName);
		// }
		for (var i=0; i<batch.assetsLoaded.length; i++) {
			batch.assetsLoaded[i].numBatches--;
			if (batch.assetsLoaded[i].numBatches <= 0) {
				
				if (batch.assetsLoaded[i].dispose) batch.assetsLoaded[i].dispose();
				self.allAssets[batch.assetsLoaded[i].referenceName] = null;
				if (soundBuffersByName[batch.assetsLoaded[i].referenceName]) {
					soundBuffersByName[batch.assetsLoaded[i].referenceName] = null;
				}
				//console.log('Deleting: ',batch.assetsLoaded[i].referenceName);
				batch.assetsLoaded[i] = null;
			}
		}
		self.allBatchesByName[batch.name] = null;
		for (var i=0; i<self.allBatches.length; i++) {
			if (self.allBatches[i] == batch) {
				self.allBatches[i] = null;
			}
		}
	};
};

window.loader = window.Loader = new Loader();
export default window.Loader;

