//------------------------
//
//  The main renderer
//
//------------------------
import $ from 'jquery';
import SETTINGS from './Settings.js';
import AppStatus from './AppStatus.js';
import * as THREE from 'three';

function RendererController() {

	this.currentColor = new THREE.Color(0xffffff);
	this.wasSetup = false;
	var renderer = null;

	//-------------------
	//
	// Create webgl renderer and place on background
	//
	//-------------------
	this.init = function() {
		if (this.wasSetup) return;
		this.wasSetup = true;
		
		renderer = new THREE.WebGLRenderer({
			antialias: !SETTINGS.isIOS,
			transparent: SETTINGS.isIOS,
			alpha: SETTINGS.isIOS,
			preserveDrawingBuffer: false,
			precision: 'mediump',
			stencil: false
		});
		
		renderer.setSize(AppStatus.innerWidth(), AppStatus.innerHeight());
		renderer.setPixelRatio(window.devicePixelRatio ? window.devicePixelRatio : 1);
		// renderer.setSize(AppStatus.innerWidth(), AppStatus.innerHeight());
		// renderer.setPixelRatio(window.devicePixelRatio ? window.devicePixelRatio : 1);

		window.renderer = renderer;
		this.resize();

		this.pixelRatio = window.devicePixelRatio ? window.devicePixelRatio : 1;
		$(renderer.domElement).css('position', 'fixed').css('width', '100%').css('height', '100%');
		renderer.setClearColor(this.currentColor.getHex(), 1.0);
		renderer.clear();
		renderer.autoClear = false;

		this.renderHeight = AppStatus.innerHeight();
		this.renderWidth = AppStatus.innerWidth();


		//add to background div
		if (SETTINGS.BINS_APP_MODE||SETTINGS.MODERATION_MODE) $(renderer.domElement).insertBefore('#spritesheet-overlay');
		else $('#background').append(renderer.domElement);
		// $('#background').hide();
		$(window).on('resize', this.resize);
	};

	//transition background color
	this.setBackgroundColor = function(target, pc) {
		renderer.setClearColor(this.currentColor.clone().lerp(new THREE.Color(target), pc).getHex());
		if (pc >= 1.0) {
			this.currentColor.set(target);
		}
	};
	this.lerpBackgroundColor = function(target) {
		this.currentColor.lerp(target, 0.05);
		renderer.setClearColor(this.currentColor.getHex());
	};

	//set viewport and scissor to render divs that aren't directly under
	this.setViewport = function(positionX) {
		if (SETTINGS.EDIT_MODE) return;
		renderer.setRenderTarget(null);

		// var elementWidth = renderer.domElement.width;
		this.elementWidth = renderer.domElement.width;
		this.rendererWidth = renderer.getSize().width;

		// if (!SETTINGS.OLD_PHONE_MODE) 
		if (positionX < 0.0) {
			var sx = Math.round(Math.max(this.elementWidth*positionX/this.pixelRatio,0));
			var wx = Math.round(this.rendererWidth*(1.0-Math.abs(positionX)));
			renderer.setScissor(sx, 0, wx, this.elementHeight);
			renderer.setViewport(Math.round(this.rendererWidth*positionX), 0, Math.round(this.rendererWidth), this.rendererHeight);
		} else {
			var sx = Math.round(this.elementWidth*positionX/this.pixelRatio);
			var wx = Math.round(this.rendererWidth*(1.0-Math.abs(positionX)))+1;
			renderer.setScissor(sx, 0, wx, this.elementHeight);
			renderer.setViewport(Math.round(this.rendererWidth*positionX), 0, Math.round(this.rendererWidth)+1, this.rendererHeight);
		}
		renderer.setScissorTest(true);
	};
	this.clear = function(col, target) {
		this.setViewport(0);
		Utils.clearPlane.material.color.set(col);
		// renderer.clear();
		renderer.render(Utils.clearScene, Utils.orthographicCamera, target, false);
	}

	//resize viewport
	this.resize = function() {
		this.renderHeight = AppStatus.innerHeight();
		this.renderWidth = AppStatus.innerWidth();

		if (window.renderer && !SETTINGS.EDITOR_MODE && !SETTINGS.BINS_APP_MODE && !SETTINGS.MODERATION_MODE) {

			if (!SETTINGS.OLD_PHONE_MODE) {
				window.renderer.setPixelRatio(window.devicePixelRatio ? window.devicePixelRatio : 1);		
				window.renderer.setSize(AppStatus.innerWidth(), AppStatus.innerHeight());
			} else {
				renderer.setPixelRatio(1);
				window.renderer.setSize(AppStatus.innerWidth()/2, AppStatus.innerHeight()/2);
				$(renderer.domElement).css('transform-origin', '0 0').css('transform','scale(2.0, 2.0)')

			}	
		}

		if (window.renderer) {
			this.elementWidth = renderer.domElement.width;
			this.elementHeight = renderer.domElement.height;

			this.rendererWidth = renderer.getSize().width;
			this.rendererHeight = renderer.getSize().height;
			this.pixelRatio = renderer.getPixelRatio();
		}
	

		
	}.bind(this);

	this.dispose = function() {
		if (renderer) renderer.dispose();
		window.renderer = renderer = null;

	}
};

window.RendererController = window.RendererController|| new RendererController();
export default window.RendererController;
