
import SETTINGS from './Settings.js';
import AppStatus from './AppStatus.js';
import Utils from '../utils/Utils.js'
import Fbo from '../utils/Fbo.js'

// import * as faceapi from 'face-api.js';
import $ from 'jquery';
import * as THREE from 'three';

import MaterialController from './MaterialController.js';
import CameraController from './CameraController.js';
import OpticalFlowController from './OpticalFlowController.js';


import * as blazeface from '@tensorflow-models/blazeface';
import * as tf from '@tensorflow/tfjs-core';
import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';
import * as tfconv from '@tensorflow/tfjs-converter';

// window.blazeface = blazeface;
// window.tf = tf;
// window.tfconv = tfconv;
// window.tfjsWebgl = tfjsWebgl;

class FaceTracker {

	constructor() {
		this.life = 0;

		this.isTracking = performance.now();
		this.boundTracking = this.gotTrackingResult.bind(this);
		// this.boundTrackingCanvas = this.gotTrackingResultCanvas.bind(this);
		// this.detectorOptions = new faceapi.TinyFaceDetectorOptions({ inputSize: 64, scoreThreshold: 0.0 });

		this.cropCanvas = document.createElement('canvas');
		this.cropCanvas.width = 64;
		this.cropCanvas.height = 64;
		this.cropContext = this.cropCanvas.getContext('2d');

		this.lastTracking = 0;
		this.faceIsTracked = false;
		this.faceBox = [0.2, 0.3, 0.6, 0.25];
		this.faceCenter = [0.5,0.5];

		this.faceCenterSmooth = new THREE.Vector2(0.5, 0.4);
		this.faceSizeSmooth = new THREE.Vector2(0.25, 0.2);

		this.faceScene = new THREE.Scene();
		this.facePlane = new THREE.Mesh(Utils.planeGeometryFaceInv, new THREE.MeshBasicMaterial({
			color: 'red',
			side: THREE.DoubleSide,
			transparent: false,
			depthTest: false,
			depthWrite: false,
			blending: THREE.NoBlending
		}));
		this.faceScene.add(this.facePlane);

		this.motionPc = 0.0;
		this.motionPcTarget = 0.0;


		this.cvSetup = false;
		this.cvReady = false;
		this.netLoaded = false;
		this.netLoading = false;


		// this.tfImageData = new ImageData(64,64);
		// document.body.appendChild(this.cropCanvas);
		// $(this.cropCanvas).css({'position':'absolute','z-index':10000, 'left':'0px', 'top':'0px'});
	}

	preload(batchName) {
		// faceapi.loadTinyFaceDetectorModel('weights/');
		// faceapi.loadFaceLandmarkTinyModel('weights/');
		if (!this.netLoading && !this.bufTarget  && !SETTINGS.OLD_PHONE_MODE) {
			tfjsWasm.setWasmPath(SETTINGS.OTHER_ASSETS_URL+'libs/tfjs-backend-wasm.wasm');
			this.netLoading = true;
			window.blazeface = blazeface;
			this.netLoaded = false;
			tf.setBackend('wasm').then(function() {
				//load local static version
				tfconv.loadGraphModel(SETTINGS.OTHER_ASSETS_URL+"libs/blazeface/", { fromTFHub: true }).then(function(model) {
					var scoreThreshold = 0.333;
					this.net = new blazeface.BlazeFaceModel(model, 128, 128, 1, 0.3, scoreThreshold);
					this.netLoaded = true;
				}.bind(this))

				// load tfhub version instead
				// blazeface.load({scoreThreshold:0.33, maxFaces:1, inputWidth:128, inputHeight:128}).then(function(model) {
				// 	// console.log("blazeface loaded");
				// 	this.net = model;
				// 	this.netLoaded = true;
				// }.bind(this));
			}.bind(this));
			
			
			
			// this.net.load('weights/');

			this.bufTarget = new Fbo(64,64,{
				minFilter:THREE.NearestFilter,
				magFilter:THREE.NearestFilter,
				format:THREE.RGBAFormat,
				type:THREE.UnsignedByteType,
				antialias: true,
				depthBuffer: false,
				stencilBuffer: false,
				premultiplyAlpha: false,
				generateMipmaps: false,
				forceClear: false,
				pingpong: false,
				renderer: renderer
			});
			this.bufAlpha = new Uint8Array(64*64*4);
			// this.tfInput = faceapi.tf.buffer([64,64,3]);
			this.bufData = new ImageData(64,64);
			this.bufDataPrev = new ImageData(64,64);
			this.netCanvas = document.createElement('canvas');
			this.netCanvas.width = 64; this.netCanvas.height = 64;
			this.netContext = this.netCanvas.getContext('2d');

			$('body').append(FaceTracker.netCanvas); $(FaceTracker.netCanvas).css('position', 'fixed');
		
			this.prevFrame = new Fbo(64,64,{
				minFilter:THREE.NearestFilter,
				magFilter:THREE.NearestFilter,
				format:THREE.RGBAFormat,
				type:THREE.UnsignedByteType,
				antialias: false,
				depthBuffer: false,
				stencilBuffer: false,
				premultiplyAlpha: false,
				generateMipmaps: true,
				forceClear: false,
				pingpong: true,
				renderer: renderer
			});

			this.opticalFlowMaterial = MaterialController.getMaterial('opticalFlow');
			this.opticalFlowFbo = new Fbo(64,64,{
				minFilter:THREE.NearestFilter,
				magFilter:THREE.NearestFilter,
				format:THREE.RGBAFormat,
				type:THREE.UnsignedByteType,
				antialias: false,
				depthBuffer: false,
				stencilBuffer: false,
				premultiplyAlpha: false,
				generateMipmaps: false,
				forceClear: false,
				pingpong: false,
				renderer: renderer
			});
			// this.opticalFlowData = new Float32Array(64*64*4);
			this.opticalFlowData = new Uint8Array(64*64*4);


			this.blurredTarget = new Fbo(32,32,{
				minFilter:THREE.NearestFilter,
				magFilter:THREE.NearestFilter,
				format:THREE.RGBAFormat,
				type:THREE.UnsignedByteType,
				antialias: false,
				depthBuffer: false,
				stencilBuffer: false,
				premultiplyAlpha: false,
				generateMipmaps: false,
				forceClear: false,
				pingpong: false,
				renderer: renderer
			});
		}
	}
	activate() {
		this.firstTrack = true;
		
		this.faceIsTracked = true;
		this.lastTracking = this.isTracking = performance.now() + 6000;
		this.faceBox = [0.2, 0.3, 0.6, 0.25];
		this.faceCenter = [0.47,0.4];
		this.life = 4;
	}

	tracking() {
		return true; //(performance.now() - this.lastTracking < 4000) && this.faceIsTracked;
	}


	startRecording() {
		this.facesInfo = {faces:[]};
		this.isRecording = true;
	}

	track() {

		// tf.setBackend('wasm').then(function() {
		// 		console.log("blazeface wasm backend");
		// 		blazeface.load({scoreThreshold:0.33, maxFaces:1, inputWidth:128, inputHeight:128}).then(function(model) {
		// 			console.log("blazeface loaded");
		// 			this.net = model;
		// 			this.netLoaded = true;
		// 		}.bind(this));
		// 	}.bind(this));

		// if (tf.getBackend() !== 'wasm') tf.setBackend('wasm');
		// else if (!this.netLoading) {
		// 	console.log("loading blazeface");
		// 	this.netLoading = true;
		// 	blazeface.load({scoreThreshold:0.33, maxFaces:1, inputWidth:128, inputHeight:128}).then(function(model) {
		// 		console.log("blazeface loaded");
		// 		this.net = model;
		// 		this.netLoaded = true;
		// 	}.bind(this));
		// }
		if (performance.now() - this.lastTracking >= 4000) this.faceIsTracked = false;
		// this.faceIsTracked = true;

		if (this.net && this.netLoaded && CameraController.isReady() && !SETTINGS.OLD_PHONE_MODE) {

			this.prevFrame.pingPong();
			// CameraController.texture.needsUpdate = CameraController.texture.updateFrame != AppStatus.currentFrame;
			// CameraController.texture.updateFrame = AppStatus.currentFrame;
			CameraController.updateTexture();
			if (CameraController.isFlipped())
				Utils.renderTextureFlipInv(CameraController.texture, this.prevFrame.getPing(), true);
			else
				Utils.renderTextureInv(CameraController.texture, this.prevFrame.getPing(), true);

			if (this.firstTrack || (AppStatus.currentFrame % 48 == 0 && !FrameRecorder.recording)) {
				this.lastTracking = this.isTracking = performance.now();
				this.usingCropCanvas = false;
				this.firstTrack = false;

				Utils.renderTextureInv(CameraController.texture, this.bufTarget.texture);
				var tb = new Uint8Array(this.bufData.data.buffer);
				renderer.readRenderTargetPixels(this.bufTarget.texture, 0,0, 64, 64, tb);
				renderer.setRenderTarget();

				// Utils.time("face");
				this.netContext.putImageData(this.bufData, 0, 0);
				// this.net.estimateFaces(this.netCanvas, false).then(this.boundTracking);
				this.net.estimateFaces(this.netCanvas, false, false, true).then(this.boundTracking);
				// this.net.detect(this.netCanvas, this.detectorOptions).then(this.boundTracking);
			}				

			if (this.faceIsTracked) {

				var faceCenterX = Utils.clamp(Math.floor(this.faceCenter[0] * 32),0,31);
				var faceCenterY = Utils.clamp(Math.floor(this.faceCenter[1] * 32),0,31);

				if (!OpticalFlowController.cvSetup) {
					OpticalFlowController.setupCV();
				}

				if (AppStatus.currentFrame%3==0) {
					// if (!this.videoMat) {

						
					// 	this.videoMat = new cv.Mat(32, 32, cv.CV_8UC4);
					// 	this.grayMat = new cv.Mat(32, 32, cv.CV_8UC1);
					// 	this.lastFrameMat = new cv.Mat(32, 32, cv.CV_8UC1);
					// 	this.flowMat = new cv.Mat(32, 32, cv.CV_32FC2);
					// }


					
	

					if (OpticalFlowController.cvReady && OpticalFlowController.lastUpdate!== AppStatus.currentFrame) {
						OpticalFlowController.lastUpdate = AppStatus.currentFrame;

						// Utils.time("flow");

						var rt = renderer.getCurrentRenderTarget();
						Utils.renderTextureInv(CameraController.texture, this.blurredTarget.texture);
						renderer.readRenderTargetPixels(this.blurredTarget.texture, 0,0, 32, 32, OpticalFlowController.cvFlowInput);
						renderer.setRenderTarget(rt);

						var df = window.CvFlowModule.processFlow32();
					// }

						// Utils.timeEnd("flow");

					// if (df) {

						var vecx = df[( faceCenterY*32 + faceCenterX )*2   ];
						var vecy = df[( faceCenterY*32 + faceCenterX )*2 +1 ];

						this.motionPcTarget *= 1.0/5.0;
						this.motionPcTarget += Math.abs(vecx) + Math.abs(vecy);

						if (this.motionPc > this.motionPcTarget) {
							this.motionPc = this.motionPc*0.8 + this.motionPcTarget*0.2;
						} else {
							this.motionPc = this.motionPc*0.5 + this.motionPcTarget*0.5;
						}

						window.vx = 1.0/32; //window.vx||(-0.2); //-0.056 * ratio * ratio * 3.0);
						window.vy = 1.0/32; //window.vy||(-0.2); //-0.084375 * ratio* ratio * 3.0);

						this.faceCenter[0] += vecx*window.vx;
						this.faceCenter[1] += vecy*window.vy;
						this.faceBox[0] +=  vecx*window.vx;
						this.faceBox[1] +=  vecy*window.vy;
					}

					

				}


				
			





				// var faceCenterX = this.faceCenter[0];
				// var faceCenterY = this.faceCenter[1];

				// var flow = this.calculateFlow(this.bufDataPrev.data, this.bufData.data, 64, 64, 3)
				
				// var totalDst = 0.0;
				// var totalX = 0.0;
				// var totalY = 0.0;

				// flow.zones.sort(function(a,b) {
				// 	var dst = Math.sqrt(Math.pow(faceCenterX-a.x,2.0) + Math.pow(faceCenterY-a.y,2.0));
				// 	var dstb = Math.sqrt(Math.pow(faceCenterX-b.x,2.0) + Math.pow(faceCenterY-b.y,2.0));
				// 	return dst<dstb ? -1 : 1;
				// });

				// totalX = flow.zones[0].u;
				// totalY = flow.zones[0].v;

				// for (var i=0; i<flow.zones.length; i++) {
				// 	// flow.zones[i] = ;
				// 	var dst = Math.sqrt(Math.pow(faceCenterX-flow.zones[i].x,2.0) + Math.pow(faceCenterY-flow.zones[i].y,2.0));
				// 	dst = dst*dst;
				// 	totalX += flow.zones[i].u/dst;
				// 	totalY += flow.zones[i].v/dst;
				// 	totalDst += 1.0/dst;
				// }

				// totalX = totalX/totalDst;
				// totalY = totalY/totalDst;

				// console.log(flow.zones[0].x, flow.zones[0].y, totalX, totalY);


				// this.opticalFlowMaterial.uniforms.tPrev.value = this.prevFrame.getPong().texture;
				// this.opticalFlowMaterial.uniforms.tCurrent.value = this.prevFrame.getPing().texture;
				// Utils.renderMaterial(this.opticalFlowMaterial, this.opticalFlowFbo.texture, true);
				// renderer.readRenderTargetPixels(this.opticalFlowFbo.texture, 0,0, 64, 64, this.opticalFlowData);
				// renderer.setRenderTarget();



				// var vecx = 0.0;
				// var vecy = 0.0;
				// var totaln = 0;

				// for (var i=Utils.clamp(faceCenterX-2,2,61); i<Utils.clamp(faceCenterX+2,2,61); i++) {
				// 	for (var j=Utils.clamp(faceCenterY-2,2,61); j<Utils.clamp(faceCenterY+2,2,61); j++) {
				// 		vecx += this.opticalFlowData[ ((j*64) + i)*4 ]/255 * 2.0 - 1.0;
				// 		vecy += this.opticalFlowData[ ((j*64) + i)*4 + 1 ]/255 * 2.0 - 1.0;
				// 		totaln++;
				// 	}
				// }	
				// if (totaln > 0) {
				// 	vecx = vecx/totaln;
				// 	vecy = vecy/totaln;
				// 	// console.log(vecx*100, vecy*100);
				// }

				// // vecx = totalX * 0.01;
				// // vecy = totalY * 0.01;
				
				// if (!isNaN(vecx) && !isNaN(vecy)) {
				// 	// console.log(vecx, vecy);
				// 	var ratio = CameraController.getVideo().videoHeight/CameraController.getVideo().videoWidth;
				// 	window.vx = window.vx||(-0.2); //-0.056 * ratio * ratio * 3.0);
				// 	window.vy = window.vy||(-0.15); //-0.084375 * ratio* ratio * 3.0);
				// 	this.faceCenter[0] += vecx*window.vx;
				// 	this.faceCenter[1] += vecy*window.vy;
				// 	this.faceBox[0] +=  vecx*window.vx;
				// 	this.faceBox[1] +=  vecy*window.vy;
				// }
			} else {

				this.faceBox[0] = this.faceBox[0]*0.9 + 0.2*0.1;
				this.faceBox[1] = this.faceBox[1]*0.9 + 0.3*0.1;
				this.faceBox[2] = this.faceBox[2]*0.9 + 0.6*0.1;
				this.faceBox[3] = this.faceBox[3]*0.9 + 0.25*0.1;

				this.faceCenter[0] = this.faceCenter[0] * 0.9 + 0.5 * 0.1;
				this.faceCenter[1] = this.faceCenter[1] * 0.9 + 0.4 * 0.1;

			}

			
			//----------------------------
			//----------------------------
			//----------------------------
			// if (this.isRecording) {
			// 	console.log("recordddinng");
			// 	if (!this.faceIsTracked) {
			// 		this.facesInfo.faces.push([]);
			// 	} else {
			// 		this.facesInfo.faces.push(this.getFaces());
			// 	}
			// 	this.isRecording = FrameRecorder.recording;
			// }


			// var ni = 64*64*4, vs = this.bufAlpha, vt = this.tfInput.values, j=0;
			// for (var i=0; i<ni; i+=4) {
			// 	vt[j++] = vs[i];
			// 	vt[j++] = vs[i+1];
			// 	vt[j++] = vs[i+2];
			// }

			//detect from canvas
			// if (this.life >= 8) {
			// 	this.usingCropCanvas = true;

			// 	var canvasCenter = this.faceCenter.slice(0);

			// 	console.log('drawing');
			// 	// this.cropContext.setTransform(1, 0, 0, 1, 0, 0);
			// 	// this.cropContext.translate(-canvasCenter[0], -canvasCenter[1]);
			// 	// this.cropContext.scale(0.5, 0.5);
			// 	// this.cropContext.translate(-canvasCenter[0]*CameraController.getVideo().videoWidth, -canvasCenter[1]*CameraController.getVideo().videoHeight);
			// 	this.cropContext.clearRect(0,0,64,64);
			// 	var cropRect = [this.faceCenter[0]*CameraController.getVideo().videoWidth-CameraController.getVideo().videoWidth*0.25, this.faceCenter[1]*CameraController.getVideo().videoHeight-CameraController.getVideo().videoWidth*0.25, CameraController.getVideo().videoWidth*0.5, CameraController.getVideo().videoWidth*0.5];
			// 	this.cropContext.drawImage(CameraController.getVideo(),cropRect[0], cropRect[1], cropRect[2], cropRect[3], 0, 0, 64, 64);

			// 	this.net.detect(this.cropCanvas, this.detectorOptions).then((result) => {
			// 		this.boundTracking(result, true, cropRect);
			// 	});
			
			// //detect from full video
			// } else {
		
			// }
		} else {
			this.faceIsTracked = true;
			this.lastTracking = this.isTracking = performance.now() + 6000;
			this.faceBox = [0.2, 0.3, 0.6, 0.25];
			this.faceCenter = [0.47,0.4];
			this.life = 4;
		}		
	}



	gotTrackingResult(result) {
		// Utils.timeEnd("face");
		this.isTracking = 0;
		var face = result[0];
		// console.log(result);
		if (face) {
			// console.log("found face");

			this.life = Math.min(Math.floor(this.life)+1,3);
			var box = face;
			
			// if (usingCanvas) {

			// 	var vw = 9; //CameraController.getVideo().videoWidth;
			// 	var vh = 16; //CameraController.getVideo().videoHeight;

			// 	//coords inside box
			// 	var x = box.x / face.imageWidth;
			// 	var y = box.y / face.imageHeight;
			// 	var w = box.width / face.imageWidth; //CameraController.getVideo().videoWidth * 2;
			// 	var h = box.height / face.imageHeight; //CameraController.getVideo().videoHeight * 2;

			// 	//rescaled for whole video
			// 	x = x + cropRect[0]/vw;
			// 	y = y + cropRect[1]/vh;

			// 	w = box.width / 2.0 / vw;
			// 	h = box.height / 2.0 / vh;

			// 	this.faceBox = [x, y, w, h];

			// 	// console.log("face tracked",w,h);

			// } else {
				var w = 64;
				var h = 64;
				this.faceBox = [box.topLeft[0]/w, box.topLeft[1]/h, [box.bottomRight[0]-box.topLeft[0]]/w, [box.bottomRight[1]-box.topLeft[1]]/h];
				// console.log("face tracked",this.faceBox[2],this.faceBox[3], face.imageHeight);
			// }

			if (CameraController.isFlipped()) {
				this.faceBox[0] = (this.faceBox[0]-0.5)*-1.0 + 0.5;
			}

			var x_offset = 0.0;
			var y_offset = 0.0;
			var face_x = this.faceBox[0];
			var face_y = this.faceBox[1];
			var face_width = this.faceBox[2];
			var face_height = this.faceBox[3];

				// x_offset -= Math.pow(Math.abs(Utils.clamp(0.2-(face_x-face_width*0.5),0.0,0.2))*5.0,3.0) * 0.1 * face_width
            // x_offset += Math.pow((Utils.clamp((face_x+face_width*0.5)-0.8,0.0,0.2))*5.0,3.0) * 0.1 * face_width

            // y_offset -= Math.pow(Math.abs(Utils.clamp(0.2-(face_y-face_height*0.5),0.0,0.2))*5.0,3.0) * 0.2 * face_height
            // y_offset += Math.pow((Utils.clamp((face_y+face_height*0.5)-0.8,0.0,0.2))*5.0,3.0) * 0.2 * face_height

            var fx = face_x+face_width*0.5
			face_width *= 1.3;
			face_x = fx-face_width*0.5;

            // face_y = face_y+face_height*0.1;
            face_height = face_height*0.5;
            face_height *= 0.9;

            face_x += x_offset
            face_y += y_offset
            this.faceBox = [face_x, face_y, face_width, face_height];


			this.faceCenter = [this.faceBox[0] + CameraController.getFlip() * this.faceBox[2]/2, this.faceBox[1] + this.faceBox[3]/2];
			this.faceIsTracked = true;


		} else {
			this.life = Math.max((this.life-1), 0);
			if (this.life <= 0) this.faceIsTracked = false;
		}
	}


	getFaces(renderWidth, renderHeight) {
		// if (!this.faceIsTracked) return [];
		
		renderWidth = renderWidth||9;
		renderHeight = renderHeight||16;
		
		var videoWidth = 9; //CameraController.getVideo().videoWidth||9;
		var videoHeight = 16; //CameraController.getVideo().videoHeight||16;
		var sx, sy, xRatio=1.0, yRatio=1.0;
		if (videoWidth / videoHeight > renderWidth/renderHeight) {
			xRatio = (videoWidth / videoHeight) / (renderWidth / renderHeight);
			sx = this.faceBox[2];
			sy = this.faceBox[3];
		} else {
		 	yRatio = (videoHeight/videoWidth) / (renderHeight/renderWidth);
			sx = this.faceBox[2];
			sy = this.faceBox[3];
		}

		// console.log("getting face", xRatio, yRatio);
		
		var x = (this.faceCenter[0] - 0.5) * xRatio + 0.5;
		var y = (this.faceCenter[1] - 0.5) * yRatio + 0.5;
		
		this.faceCenterSmooth.x = Utils.lerp(this.faceCenterSmooth.x, x+this.faceSizeSmooth.x*0.05, 0.2);
		this.faceCenterSmooth.y = Utils.lerp(this.faceCenterSmooth.y, y+this.faceSizeSmooth.y*0.05, 0.2);
		this.faceSizeSmooth.x = Utils.lerp(this.faceSizeSmooth.x, sx*0.9, 0.05);
		this.faceSizeSmooth.y = Utils.lerp(this.faceSizeSmooth.y, sy*0.9, 0.05);
		
		return [{face:[this.faceCenterSmooth.x, this.faceCenterSmooth.y, this.faceSizeSmooth.x, this.faceSizeSmooth.y]}]
	}

	render(renderTarget, renderWidth, renderHeight) {
		if (!this.faceIsTracked) return;

		var videoWidth = 9; //CameraController.getVideo().videoWidth;
		var videoHeight = 16; //CameraController.getVideo().videoHeight;

		var xRatio = 1.0;
		var yRatio = 1.0;

		//x is scaled
		if (videoWidth / videoHeight > renderWidth/renderHeight) {
			xRatio = (videoWidth / videoHeight) / (renderWidth / renderHeight);
			this.facePlane.scale.set(this.faceBox[2] /(renderWidth / renderHeight) /2,  this.faceBox[3]/2, 1.0);

		//y is scaled
		} else {

		 	yRatio = (videoHeight/videoWidth) / (renderHeight/renderWidth);
			this.facePlane.scale.set(this.faceBox[2]/2,  this.faceBox[3]/(renderHeight/renderWidth), 1.0);

		}	

		var target = new THREE.Vector3((this.faceCenter[0] - 0.5) * xRatio + 0.02 + 0.5, (this.faceCenter[1] - 0.5) * yRatio + 0.5,0); //
		this.facePlane.position.lerp( target, 0.2);
		
		// Utils.renderMaterial(this.opticalFlowMaterial, renderTarget, true);
		renderer.render(this.faceScene, Utils.topLeftCamera, renderTarget, false);

	}

}
window.FaceTracker = window.FaceTracker || new FaceTracker();
export default window.FaceTracker;

