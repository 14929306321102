import SETTINGS from './Settings.js';
import AppStatus from './AppStatus.js';

import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import RendererController from './AppStatus.js';
import SpritesheetVideo from '../objects/SpritesheetVideo.js';
// import SpritesheetVideoCreate from '../objects/SpritesheetVideoCreate.js';

class UserVideoController {

	constructor() {
		this.bins = {};
		this.recordId = {};

		this.spritesheetById = {};
		this.temporaryBins = {};
		this.facesByName = {};

		this.temporaryId = null;
	}

	clearTemporaryBins() {
		this.temporaryBins = {};
		this.temporaryId = null;
	}

	setTemporaryBins(bins, id) {
		bins = [].concat(bins);
		this.temporaryBins = {};
		for (var i=0; i<bins.length; i++) {
			this.temporaryBins[bins[i]] = true;
		}
		this.temporaryId = id;
	}

	getRecordId(bins) {
		this.recordId[ bins.toString() ] = this.recordId[ bins.toString() ]||0;
		return (bins.toString()+'_'+(++this.recordId[bins.toString()]));
	}
	
	addSpritesheet(spritesheet, bins) {
		for (var i=0; i<bins.length; i++) {
			this.bins[bins[i]] = this.bins[bins[i]] || [];
			this.bins[bins[i]].push(spritesheet);
		}
	}

	addSpritesheetForId(spritesheet, id) {
		console.log("ADDED ID", id);
		this.spritesheetById[id] = spritesheet;
	}

	preferLocal(bin, isId) {
		if (!isId) {
			if (this.temporaryBins[bin]) return true;
			if (this.bins[bin] && this.bins[bin][this.bins[bin].length-1].isLocal) return true;
		} else {
			if (this.temporaryId == isId) return true;
			if (this.spritesheetById[isId] && this.spritesheetById[isId].isLocal) return true;
		}
		return false;
	}

	getVideoForBin(bin, params, isId, currentSp) {
		var sourceSp = null;
		var temporary = false;
		if (!isId) {
			sourceSp = this.bins[bin] ? this.bins[bin][this.bins[bin].length-1] : null;
			if (this.temporaryBins[bin]) {
				sourceSp = FrameRecorder.spritesheetVideo;
				temporary = true;
			}
		} else {
			sourceSp = this.spritesheetById[isId];
			if (this.temporaryId === isId) {
				sourceSp = FrameRecorder.spritesheetVideo;
				temporary = true;
			}
		}

		//pefer local source over global source
		var preferLocalSource = false;
		if (sourceSp && currentSp) {
			if (sourceSp.isLocal && !currentSp.isLocal) {
				if (!sourceSp.waitingForExport) {
					preferLocalSource = true;
					console.log("PREFER LOCAL SOURCE");
				}
			}
		}


		if (sourceSp && !sourceSp.hasErrors() &&  (preferLocalSource || !sourceSp || !currentSp || sourceSp.isTemporary != currentSp.isTemporary || sourceSp.name != currentSp.name || currentSp.disposed)) {
			console.log("New User spritesheetVideo", sourceSp, currentSp);
			var sourceParams = sourceSp.params;
			
			var sp = SpritesheetVideo.create(params);
			var framesType = sourceSp.framesType=='texture'?'texture':(sourceSp.reloaded?"blob":sourceSp.framesType);
			sp.reloaded = sourceSp.reloaded;
			sp.setup(Utils.clone(sourceSp.params), sourceSp.frames, framesType);
			if (sourceSp.waitingForExport) {
				if (sourceSp.waitingForExport) sp.waitForExport();
				sourceSp.childs.push(sp);
			}
			sp.analysisUUID = sourceSp.analysisUUID;
			sp.isUser = true;
			sp.isLocal = sourceSp.isLocal;
			sp.isTemporary = temporary;
			if (params) {
				sp.params.name = sourceSp.name || 'user/'+bin;
				sp.params.duration = Math.max(Math.min(sp.params.duration, params.videoDuration), 1/24);
				sp.params.videoSpeed = params.videoSpeed||1.0;
				sp.params.reverse = params.reverse||params.backward||params.backwards||false;
				sp.params.palindrome = params.palindrome;
				sp.params.effect = params.effect;
				sp.params.analysis = params.analysis;
				sp.params.faces = params.faces;
				sp.params.invertColors = params.invertColors;
				sp.params.facesInfo = sourceParams.facesInfo;
				sp.params.startOffset = Math.floor( (params.startOffset||0)*24 );
				// sp.params.jpeg = true;
				// if (temporary) {
				// 	sp.params.effect = params.effect || ((temporary && params.analysis && SETTINGS.ANALYSIS_DEBUG) ? AnalysisController.ANALYSIS_DEBUG_DEFAULTS[params.analysis] : null);
				// 	sp.params.analysis = params.analysis;
				// }

				sp.params.numFrames =  Math.max(Math.floor(sp.params.duration*24),1);
				if (sp.params.numFrames <= 1 && params.photoDuration !== undefined) sp.params.duration = Math.max(params.photoDuration, 1/24);
				// if (sp.params.numFrames > 1 && params.palindrome) sp.params.duration *= 2.0; 

				sp.setParams(sp.params, sp.frames, framesType);
			}
			return sp;
		}
		return null;
	}


	dispose() {
		this.bins = {};
		this.recordId = {};
		this.spritesheetById = {};
		this.temporaryBins = {};
		this.facesByName = {};
	}

};

window.UserVideoController = window.UserVideoController || new UserVideoController();
export default window.UserVideoController;
