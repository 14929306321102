import SETTINGS from './Settings.js';
import AppStatus from './AppStatus.js';

function S3SubmitController() {

	this.infoById = {};
	this.gettingInfoById = {};
	this.imagesWaitingById = {};

	//
	// Get private URL
	//
	this.getPrivateUrls = function(id, numSheets) {
		if (this.gettingInfoById[id]) return this.gettingInfoById[id];
		this.gettingInfoById[id] = new Promise((resolve, reject) => {
			if (this.infoById[id]) {
				resolve(this.infoById[id]);
				return;
			}
			console.log("GET PRIVATE URLS",id,numSheets);

			var resolved = false;

			//perform request
			var formData = new FormData();
				formData.append("video_name", id);
				formData.append("user_uuid", SETTINGS.uuid);
				formData.append("num_sheets", numSheets);
			var xhr = new XMLHttpRequest();
			xhr.open('POST', SETTINGS.API_SERVER_URL+"/submit_get_urls", true);
			console.log("Getting submit urls for id", id);
			xhr.onreadystatechange = ((e) => {
				if (e.target.readyState >= 4 && xhr.status >= 200 && xhr.status < 300) {
					// console.log(xhr.response.replace("'", '"'));
					this.infoById[id] = JSON.parse(xhr.response).urls;
					resolve(this.infoById[id]);
					formData = null;
					xhr.onreadystatechange = null;
					xhr = null;

				} else if (e.target.status >= 300) {
					if (!resolved) {
						console.log("Erroooorr");
						resolved = true;
						resolve(null);
					}
					console.log(e.target);
					if (!SETTINGS.RELEASE_MODE) {
						if (!window.oneAlert) alert("Upload Error. Reloading.");
						window.oneAlert = true;
					}
					// window.location.reload();

				}
			});
			xhr.onerror = (function() {
				if (!resolved) {
					console.log("Erroooorr");
					resolved = true;
					resolve(null);
				}
			});
			// xhr.responseType = 'text';
			xhr.send(formData);
		});
	};


	this.submitImages = function(images, id, submitParams) {

	};

};

// window.S3SubmitController || new S3SubmitController()
export default new S3SubmitController();

