
import SETTINGS from './Settings.js';
import AppStatus from './AppStatus.js';
import * as THREE from 'three';

import RendererController from './RendererController.js'
import CameraController from './CameraController.js';
import Fbo from "../utils/Fbo.js";

class TrackedPoint {
	constructor(x,y) {
		this.x = x;
		this.y = y;
	}
	getFlowVec() {
		return [Utils.clamp(Math.floor(Utils.cmap(this.x,0.0,1.0,0.0,31.99)), 1, 30), Utils.clamp(Math.abs(Math.round(Utils.cmap(this.y,0.0,1.0,0,31.99))),1,30)];
	}
	getScaled(spritesheetMode) {
		if (spritesheetMode) return [this.x, this.y]
		var vw = (!SETTINGS.EDIT_MODE) ? RendererController.renderWidth : SETTINGS.EDIT_MODE_VIEW_WIDTH;
		var vh = (!SETTINGS.EDIT_MODE) ? RendererController.renderHeight : SETTINGS.EDIT_MODE_VIEW_HEIGHT;
		var ratio = (CameraController.getVideo().videoWidth/CameraController.getVideo().videoHeight) / (vw/vh);
		if (ratio > 1.0) {
			return [(this.x-0.5)*ratio+0.5, this.y];
		} else {
			return [this.x, (this.y-0.5)/ratio+0.5];
		}
	}
	getDistance(spritesheetMode) {
		var d = this.getScaled(spritesheetMode);
		return Utils.distance(d[0],(d[1]-0.5)*1.25+0.5,0.5, 0.5) * 2.0;
	}
};


class OpticalFlowController {

	constructor() {
		this.lastUpdate = -1;
		this.trackedPoints = [];
		this.centerPoint = new THREE.Vector2(0.5,0.5);
		this.centerPointTarget = new THREE.Vector2(0.5,0.5);

		this.centerPointGlobal = new THREE.Vector2(0.5,0.5);
		this.centerPointGlobalIntensity = new THREE.Vector2(0.0,0.0);



		this.globalMotionX = 0.0;
		this.globalMotionY = 0.0;
		this.totalBrightness = 128;
		this.brightnessMode = false;
		this.globalMotionMode = false;
		this.lastTime = 0.0;
		this.spritesheetMode = false;


		this.cvFlowInput = new Uint8Array(32*32*4);
		this.cvFlowSetup = false;
		this.cvReady = false;
		this.cvFlowAB = null;
		this.cvSetup = false;
		this.offsetY = 0;
	}


	preload(batchName) {
		if (!this.cvFlowAB && !this.cvSetup) {
			Loader.addScript(batchName, SETTINGS.OTHER_ASSETS_URL+'libs/cvflow_O3.js');
			this.cvFlowAB = Loader.addXHR(batchName, SETTINGS.OTHER_ASSETS_URL+'libs/cvflow_O3.wasm', 'arraybuffer');
		}
	}

	setupCV() {
		if (!this.cvSetup && this.cvFlowAB && this.cvFlowAB.loaded && window.CvFlowModule && !SETTINGS.OLD_PHONE_MODE) {
			window.CvFlowModule.setupWasm(this.cvFlowAB.value).then(()=> {
				this.cvReady = true;
				this.cvFlowInput = window.CvFlowModule.getInput();
				console.log("OpenCV setup done");
			});
			this.cvFlowAB.value = null;
			this.cvFlowAB = null;
			this.cvSetup = true;
		}
	}

	activate(spritesheetMode, offsetY) {
		if (this.targetSpritesheet == spritesheetMode) return;
		this.globalMotionX = 0.0;
		this.globalMotionY = 0.0;
		this.totalBrightness = 128;
		this.lastUpdate = -1;
		this.trackedPoints = [];
		this.offsetY = offsetY||0;
		this.centerPoint = new THREE.Vector2(0.5,0.5+this.offsetY);
		this.centerPointTarget = new THREE.Vector2(0.5,0.5+this.offsetY);
		this.lastTime = 0.0;

		if (spritesheetMode) {
			this.spritesheetMode = true;
			this.targetSpritesheet = spritesheetMode;
		} else {
			this.spritesheetMode = false;
		}
		if (!this.cvSetup) this.setupCV();
	}

	update(opts) {
		if (!this.cvSetup) this.setupCV();


		//perform opencv optical flow
		if ( (this.cvReady || opts && opts.brightnessMode) && !SETTINGS.OLD_PHONE_MODE && AppStatus.currentFrame%3==0 && this.lastUpdate !== AppStatus.currentFrame) {

			var delta = Utils.ccmap(performance.now()-this.lastTime, 3/12.0, 3/120, 0.5, 2.0);
			this.lastTime = performance.now();
			if (this.cvReady) this.cvFlowInput = window.CvFlowModule.getInput(); else this.cvFlowInput = new Uint8Array(32*32*4);
			if (!this.videoPixelsTargetBlur) {
				this.videoPixelsTargetBlur = new Fbo(64,64,{
					minFilter:THREE.LinearMipMapLinearFilter,
					magFilter:THREE.LinearFilter,
					format:THREE.RGBAFormat,
					type:THREE.UnsignedByteType,
					antialias: false,
					depthBuffer: false,
					stencilBuffer: false,
					premultiplyAlpha: false,
					generateMipmaps: true,
					forceClear: false,
					pingpong: false,
					renderer: renderer
				});
				this.blurMaterial = new THREE.RawShaderMaterial({
					vertexShader:`
						precision mediump float;
						precision mediump int;

						attribute mediump vec3 position;
						attribute mediump vec2 uv;

						varying mediump vec2 vUv;

						uniform mediump mat4 modelViewMatrix; // optional
						uniform mediump mat4 projectionMatrix; // optional

						void main() {
							vUv = uv;
							gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
						}
					`,
					fragmentShader:`
						precision mediump float;
						precision mediump int;

						varying mediump vec2 vUv;

						uniform mediump sampler2D tDiffuse;

						void main() {
							gl_FragColor = vec4(texture2D(tDiffuse,vUv,1.9).rgb, 1.0);
						}
					`,
					uniforms: {
						tDiffuse: {type:'t', value: this.videoPixelsTargetBlur.texture.texture}
					},
					transparent: false,
					blending: THREE.NoBlending,
					depthTest: false,
					depthWrite: false,
					side: THREE.DoubleSide
				});

				this.videoPixelsTarget = new Fbo(32,32,{
					minFilter:THREE.NearestFilter,
					magFilter:THREE.NearestFilter,
					format:THREE.RGBAFormat,
					type:THREE.UnsignedByteType,
					antialias: false,
					depthBuffer: false,
					stencilBuffer: false,
					premultiplyAlpha: false,
					generateMipmaps: false,
					forceClear: false,
					pingpong: false,
					renderer: renderer
				});
				// this.videoMat = new cv.Mat(32, 32, cv.CV_8UC4);
				// this.grayMat = new cv.Mat(32, 32, cv.CV_8UC1);
				// this.lastFrameMat = new cv.Mat(32, 32, cv.CV_8UC1);
				// this.flowMat = new cv.Mat(32, 32, cv.CV_32FC2);
			}

			var rt = renderer.getCurrentRenderTarget();

			if (this.spritesheetMode) {
				if (this.targetSpritesheet.isReady()) {
					this.targetSpritesheet.render();
					Utils.renderTextureInv(this.targetSpritesheet.fbo.texture.texture, this.videoPixelsTargetBlur.texture, true);
				} else {
					Utils.renderTextureInv(Utils.blackTexture, this.videoPixelsTargetBlur.texture, true);
				}
				
			} else {

				CameraController.updateTexture();
				// Utils.renderTextureInv(CameraController.texture, this.videoPixelsTarget.texture);
				if (CameraController.isFlipped())
					Utils.renderTextureFlipInv(CameraController.texture, this.videoPixelsTargetBlur.texture, true);
				else
					Utils.renderTextureInv(CameraController.texture, this.videoPixelsTargetBlur.texture, true);

			}

			Utils.renderMaterial(this.blurMaterial, this.videoPixelsTarget.texture, true);


			// Utils.time("flow");

			renderer.readRenderTargetPixels(this.videoPixelsTarget.texture, 0,0, 32, 32, this.cvFlowInput);
			renderer.setRenderTarget(rt);

			// console.log(this.cvFlowInput);
			
			// videoMat.data.set(pixels.data);
			// cv.cvtColor(this.videoMat, this.grayMat, cv.COLOR_RGBA2GRAY);
			// cv.calcOpticalFlowFarneback(this.lastFrameMat, this.grayMat, this.flowMat, 0.5, 5, 15, 3, 5, 1.1, cv.OPTFLOW_FARNEBACK_GAUSSIAN);
			


			if (this.brightnessMode || (opts && opts.brightnessMode)) {
				var total = 0.0;
				for (var i=2; i<this.cvFlowInput.length; i+=4) {
					var val = this.cvFlowInput[i];
					total += val/15 + 
						Math.abs(val - this.cvFlowInput[Math.min(i+4,this.cvFlowInput.length-1-2)]) + 
						Math.abs(val - this.cvFlowInput[Math.max(i-32,2)]);
				}
				this.totalBrightness = total / (32*32);
				if (isNaN(this.totalBrightness)) {this.totalBrightness = 0.0;}
			}
			

			if (this.cvReady) {
				var df = window.CvFlowModule.processFlow32();
				// Utils.timeEnd("flow");

				// var t = this.grayMat;
				// this.grayMat = this.lastFrameMat;
				// this.lastFrameMat = t;

				//track points
				var closestToCenter = 100.0;
				var totalDst = 0.0;
				// var df = this.flowMat.data32F;

				



				for (var i=0; i<this.trackedPoints.length; i++) {
					var vec =  this.trackedPoints[i].getFlowVec();

					this.trackedPoints[i].x += df[ ( vec[1]*32 + vec[0] ) * 2 ] * 1/32;
					this.trackedPoints[i].y += df[ ( vec[1]*32 + vec[0] ) * 2 + 1 ] * 1 / 32;

					//remove point if too far
					var dst = this.trackedPoints[i].getDistance(this.spritesheetMode);
					if (dst>= 1.0) {
						this.trackedPoints.splice(i,1);
						i--;
					} else {
						closestToCenter = Math.min(closestToCenter, dst);
					}
				}

				if (this.globalMotionMode || (opts && opts.globalMotionMode)) {
					if (isNaN(this.globalMotionX)) {this.globalMotionX = 0.0; this.globalMotionY = 0.0;}
					// this.globalMotionX *= 1.0/5.0;
					// this.globalMotionY *= 1.0/5.0;

					this.globalMotionX = Utils.deltaSmoothing(this.globalMotionX, 0.0, 0.4, delta);
					this.globalMotionY = Utils.deltaSmoothing(this.globalMotionY, 0.0, 0.4, delta);

					for (var x=0; x<32; x++) {
						for (var y=0; y<32; y++) {
							this.globalMotionX += Math.abs(df[ ( y*32 + x ) * 2 ] * 1/32);
							this.globalMotionY += Math.abs(df[ ( y*32 + x ) * 2 + 1 ] * 1/32);
						}
					}
				}
				

				//crossfade target based on distance curve
				this.centerPointTarget.x = 0.0;
				this.centerPointTarget.y = 0.0;
				if (this.trackedPoints.length > 0) {
					for (var i=0; i<this.trackedPoints.length; i++) {
						var scaled = this.trackedPoints[i].getScaled(this.spritesheetMode);
						var dst = this.trackedPoints[i].getDistance(this.spritesheetMode);
						var dstx = Math.pow((this.trackedPoints.length-i)/this.trackedPoints.length, 3.0) * Utils.ccmap(dst, 0.5,1.0,1.0,0.05);//;  1.0/Math.max(dst,0.05);
						this.centerPointTarget.x += (scaled[0]*2.0-1.0) * dstx;
						this.centerPointTarget.y += (scaled[1]*2.0-1.0) * dstx;
						totalDst += dstx;
					}
					if (totalDst > 0) {
						this.centerPointTarget.x /= totalDst;
						this.centerPointTarget.y /= totalDst;
					}
					

					// this.centerPointTarget.x = this.trackedPoints[0].getScaled()[0]*2.0-1.0;
					// this.centerPointTarget.y = this.trackedPoints[0].getScaled()[1]*2.0-1.0;
				}
				this.centerPointTarget.x = this.centerPointTarget.x*0.5+0.5;
				this.centerPointTarget.y = this.centerPointTarget.y*0.5+0.5;


				if (opts && opts.globalCenter) {
					var avgx = 0;
					var avgy = 0;
					var totalValX = 0.1;
					var totalValY = 0.1;


					var ox = this.centerPointGlobal.x;
					var oy = this.centerPointGlobal.y;


					var topx = 16;
					var topy = 16;
					var topval = 1.0;

					for (var i=1; i<32; i++) {
						for (var j=7; j<31; j++) {
							var vx = df[ ( j*32 + i ) * 2 ];
							var vy = df[ ( j*32 + i ) * 2 + 1 ];

							if (Math.abs(vx) + Math.abs(vy) > topval) {
								topval = Math.abs(vx) + Math.abs(vy);
								topx = i;
								topy = j;
							}

							this.centerPointGlobal.x = Utils.lerp(this.centerPointGlobal.x, (i/32 - 0.45)*2.5 + 0.5 ,  Math.pow(Math.abs(vx) / 30,1.5) * 2.0  );
							this.centerPointGlobal.y = Utils.lerp(this.centerPointGlobal.y, (j/32 - 0.5)*1.33 + 0.5,  Math.pow(Math.abs(vy) / 30,1.5) * 1.1  );
						}
					}

					var vx = df[ ( topy*32 + topx ) * 2 ];
					var vy = df[ ( topy*32 + topx ) * 2 + 1 ];
					this.centerPointGlobal.x = Utils.lerp(this.centerPointGlobal.x, (i/32 - 0.45)*2.5 + 0.5 ,  Math.pow(Math.abs(vx) / 30,1.5) * 2.0  );
					this.centerPointGlobal.y = Utils.lerp(this.centerPointGlobal.y, (j/32 - 0.5)*1.33 + 0.5,  Math.pow(Math.abs(vy) / 30,1.5) * 1.5  );
	
					this.centerPointTarget.x = this.centerPointGlobal.x;
					this.centerPointTarget.y = this.centerPointGlobal.y-0.1;
				}
				
				// Utils.cmap(avgx/ totalValX,-16,16,0,1.0);
				// this.centerPointTarget.y = Utils.ccmap(avgy/ totalValY,16,-16,0,1.0);



				//add new tracked point if closest from center is too far off
				// console.log(closestToCenter > 0.33, this.trackedPoints.length);
				if (closestToCenter > 0.33 || this.trackedPoints.length == 0) {
					this.trackedPoints.push(new TrackedPoint(0.5, 0.5+this.offsetY));
				}

			}
			this.lastUpdate = AppStatus.currentFrame;
		}

		//lerp to target
		this.centerPoint.x = Utils.lerp(this.centerPoint.x, this.centerPointTarget.x, 0.33);
		this.centerPoint.y = Utils.lerp(this.centerPoint.y, this.centerPointTarget.y, 0.33);
	}


	getCenterPoint() {
		return this.centerPoint;
	}
	
};
window.OpticalFlowController = window.OpticalFlowController || new OpticalFlowController();
export default window.OpticalFlowController;

