import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import Fbo from '../utils/Fbo.js'
import MultiStorage from '../utils/MultiStorage.js';

import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';

import MaterialController from '../controllers/MaterialController.js';
import RendererController from '../controllers/RendererController.js';

import MottoView from './MottoView.js';
import PrebakedView from './PrebakedView.js'



//------------------------
// 
//  Base class for all views
//
//-------------------------
class PrebakedVideoView extends PrebakedView {
	constructor(_info, batchName) {
		super(_info, batchName);
		this.textType = this.info["textType"] || 'top';

		//
		// Add image to preloading queue
		//
		console.log('LOADING VIDEO', SETTINGS.VIDEOS_URL+this.params.video);
		Loader.addXHR(batchName, SETTINGS.VIDEOS_URL+this.params.video, 'arraybuffer');
		this.extension = this.params.video.split('.').pop().toLowerCase();
		this.loaded = false;
		this.videoWidth = 256;
		this.videoHeight = 256;
		this.playing = false;
		this.canStopVideo = false;
		this.canPlayVideo = true;
		this.startTime = 0;
		this.videoPlayingBound = this.videoPlaying.bind(this);
		this.videoErrorBound = this.videoError.bind(this);
	}

	//
	// When starting - decode image for rendering
	//
	start() {
		if (this.started) return;
		super.start();

		//create image & decode
		// window.onlyvid = this.video;
		// delete window.onlyvid.src;
		// delete window.onlyvid.srcObject;
		this.video = document.createElement('video');
		this.video.crossorigin = 'anonymous';
		this.video['webkit-playsinline'] = true;
		this.video['playsinline'] = true;
		this.video.setAttribute('playsinline', true);
		this.video.setAttribute('preload', true);
		this.video.setAttribute('webkit-playsinline', true);
		this.video.setAttribute('crossorigin', 'anonymous');
		this.video.volume = 0.0;
		this.video.muted = true;
		this.video.preload = true;
		this.video.playbackRate = this.params.videoSpeed || 1.0;
		this.video.setAttribute('muted', 'true');
		this.video.loop = true;
		this.videoLastUpdate = 0;

		Loader.getXHRPromise(SETTINGS.VIDEOS_URL+this.params.video).then(function(ab) {
			if (!this.video) return;
			
			this.blob = new Blob([new Uint8Array(ab.value)],{'type':'video/'+this.extension})
			this.url = window.URL.createObjectURL(this.blob);

			this.video.onloadedmetadata = function() {
				this.video.onloadedmetadata = null;
				this.loaded = true;
				this.videoWidth = this.video.videoWidth;
				this.videoHeight = this.video.videoHeight;
				this.texture.image = this.video;
				this.texture.needsUpdate = true;
			}.bind(this);
			this.video.src = this.url;
			this.video.load();

		}.bind(this));
	}

	//
	// When disposing - release image
	//
	stop() {
		if (!this.started) return;
		super.stop();

		if (this.playing && this.video) this.video.pause(); //.then(function() {
		this.playing = false;
		this.canStopVideo = false;
		this.canPlayVideo = true;

		if (this.url) window.URL.revokeObjectURL(this.url);
		this.blob = null;
		this.url = null;

		if (this.video) this.video.onloadedmetadata = null;
		this.video = null;
		this.loaded = false;
	}

	videoPlaying() {
		this.canStopVideo = true;
		this.canPlayVideo = false;
	}
	videoError() {
		this.canStopVideo = false;
		this.canPlayVideo = true;
		if (this.playing) this.video.pause();
		this.playing = false;
	}

	update() {
		if (!this.started) return;
		super.update();

		if (this.loaded) {
			this.texture.image = this.video;
			if (this.active && !this.playing && this.canPlayVideo) {
				this.playing = true;
				this.canPlayVideo = false;
				this.canStopVideo = false;
				this.video.play().then(this.videoPlayingBound).catch(this.videoErrorBound);
			} else if (!this.active && this.playing && this.canStopVideo) {
				this.playing = false;
				this.canPlayVideo = false;
				this.canStopVideo = false;
				this.video.pause(); //.then(function() {
				this.canStopVideo = false;
				this.canPlayVideo = true;
			}
		}

		if (this.active && this.loaded && this.playing) {
			this.videoLastUpdate = performance.now();
			this.texture.needsUpdate = true;
		}

	}

	//
	// Render image to webgl??
	//
	render() {
		if (!this.active) return;
		super.render();

	}
}

export default PrebakedVideoView;	

