import SETTINGS from '../controllers/Settings.js';
import * as THREE from 'three';

import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import RendererController from '../controllers/RendererController.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';
import MotionController from '../controllers/MotionController.js';

import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import SpritesheetVideo from './SpritesheetVideo.js';

//------------------------
class SpritesheetVideoOrientation extends SpritesheetVideo {
	constructor(_info, batchName) {
		super(_info, batchName);

		
	}

	preload(batchName) {
		super.preload(batchName);
	}

	prepare() {
		super.prepare();
	}

	start() {
		if (!this.started) return;
		super.start();
	}

	update(opt) {

		if (!this.isReady()) this.lastTime = performance.now();

		var numFramesTotal = Math.floor(this.numFrames); //this.frameLimit
		var duration = (numFramesTotal-0.0001) / this.frameRate;
		var deltaTime = (performance.now() - this.lastTime) / 1000;
		// this.currentTime = 

		var hasMin = this.params.effect.min !== undefined;
		var hasMax = this.params.effect.max !== undefined;

		switch (this.params.effect.control) {

			case 'updown-scrub':
				this.currentTime = Utils.cmap(MotionController.upDownPc, hasMin?this.params.effect.min:-1.0, hasMax?this.params.effect.max:1.0, 0.0, duration);
				// this.currentTime = this.currentTime % duration;
				if (this.currentTime < 0.0) this.currentTime += duration;
				break;

			case 'leftright-scrub':
				this.currentTime = Utils.cmap(MotionController.leftRightPc, hasMin?this.params.effect.min:-1.0, hasMax?this.params.effect.max:1.0, 0.0, duration);
				// this.currentTime = this.currentTime % duration;
				//if (this.currentTime < 0.0) this.currentTime += duration;
				break;

			case 'updown-speed':
				this.currentTime += MotionController.upDownPc * deltaTime * this.videoSpeed * (24/24);
				this.currentTime = this.currentTime % duration;
				if (this.currentTime < 0.0) this.currentTime += duration;
				break;

			case 'motion-scrub':
				this.currentTime = Utils.map(MotionController.totalMotionRotationSpeed, 0, 100.0, 0.0, duration);
				this.currentTime = this.currentTime % duration;
				if (this.currentTime < 0.0) this.currentTime += duration;
				break;

			case 'motion-speed':
				this.currentTime += MotionController.totalMotionRotationSpeed * deltaTime * this.videoSpeed * (3/24);
				this.currentTime = this.currentTime % duration;
				if (this.currentTime < 0.0) this.currentTime += duration;
				break;

			case 'forward-speed':
				this.currentTime += MotionController.forwardMotionSpeed * deltaTime * this.videoSpeed * (3/24);
				this.currentTime = this.currentTime % duration;
				if (this.currentTime < 0.0) this.currentTime += duration;
				break;

			case 'forward-scrub':
				this.currentTime = Utils.map(MotionController.forwardMotionSpeed, 0.0, 100.0, 0, duration);
				this.currentTime = this.currentTime % duration;
				if (this.currentTime < 0.0) this.currentTime += duration;
				break;
		}






		opt.forceTime = this.currentTime;
		super.update(opt);
		if (this.isReady()) {
		}
	}

	dispose() {
		super.dispose();

	}
	stop() {
		super.stop();
	}

	render() {
		super.render();
	}
};

export default SpritesheetVideoOrientation;


