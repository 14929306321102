
import * as THREE from 'three';
import SETTINGS from '../controllers/Settings.js';

import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import RendererController from '../controllers/RendererController.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';

import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import SpritesheetVideo from './SpritesheetVideo.js';

// var CanvasTextWrapper = require('canvas-text-wrapper').CanvasTextWrapper;

//------------------------
class SpritesheetVideoBoxes extends SpritesheetVideo {
	constructor(_info, batchName) {
		super(_info, batchName);
		this.boxesScene = new THREE.Scene();
		this.boxes = [];
		this.divs = [];

		//
		// Debug the class in the boxes
		//
		this.boxDebugPlanes = [];
		this.boxDebugCanvas = [];
		this.boxDebugContext = [];
		this.boxDebugTexture = [];
	}

	preload(batchName) {
		super.preload(batchName);
	}

	prepare() {
		super.prepare();
	}

	onAnalysisLoaded(info) {
		super.onAnalysisLoaded(info);
	}

	stop() {
		for (var i=0; i<this.divs.length; i++) {
			if (this.divs[i]) {
				$(this.divs[i].container).remove();
				$(this.divs[i].div).remove();
			}
		}
		this.boxesScene = new THREE.Scene();
		this.boxes = [];
		this.divs = [];
		super.stop();
	}

	update(opt) {
		super.update(opt);

		if (this.isReady() && this.analysis && this.contentDiv) {

			var currentFrame = this.lastFrame;

			var rects = this.analysis.rectangles[currentFrame+this.frameOffset];
			// console.log(this.analysis.rectangles[currentFrame+this.frameOffset], this.analysis.classes[currentFrame+this.frameOffset], this.analysis.ids[currentFrame+this.frameOffset], this.analysis.confidences[currentFrame+this.frameOffset])

			if (rects && rects.length) {
				for (var i=0; i<rects.length; i++) {
					var box = rects[i];

					if (!this.boxes[i]) {
						var mat = new THREE.LineBasicMaterial( { color: 0x0077ff, linewidth: this.params.effect.lineWidth || 3 } );
						Utils.segmentGeometry = Utils.segmentGeometry || new THREE.EdgesGeometry( Utils.planeGeometry );
						var p = new THREE.LineSegments(Utils.segmentGeometry , mat );
						this.boxesScene.add(p);
						this.boxes[i] = p;

						if (this.params.effect.debug) {
							var dc = document.createElement('div');
							$(dc).addClass('spritesheet-overlay-text-container');
							$(dc).css('background', 'none');
							$(dc).css('background-color', 'transparent');
							$(this.contentDiv).append(dc);

							var d = document.createElement('div');
							$(d).addClass('spritesheet-overlay-text');
							$(d).css('color', 'white');
							$(d).css('background-color', 'transparent');
							$(dc).append(d);

							// $(d).html(this.params.faces.text||'');
							this.divs[i] = {
								container: dc,
								div:d,
								classid: null,
								visible: false,
								fontSize: 0,
								text: null
							};
							// $(d).text(this.analysis.classes[currentFrame+this.frameOffset][i]);
						}
					}
					var p = this.boxes[i];
					p.scale.set(box[2], box[3],1.0);
					p.position.set(box[0]+box[2]/2,box[1]+box[3]/2, 0);

					var scale = (this.params.effect.scale||1.0);
					// p.position.x -= box[2]*0.5*(scale-1.0);
					// p.position.y -= box[3]*0.5*(scale-1.0);

					p.scale.x *= scale;
					p.scale.y *= scale


					// p.material.uniforms.tint.set(this.params.effect.color || '#ffffff');
					p.visible = true;
					if (this.params.effect.debug) {

						var classid = this.analysis.classes[currentFrame+this.frameOffset][i]||'class';
						if (this.divs[i].classid!==classid) {
							this.divs[i].classid = classid;
							$(this.divs[i].div).text(classid);
						}
						var renderWidth = renderer.getSize().width,
							renderHeight = renderer.getSize().height;
						if (SETTINGS.STORYBOARD_MODE) {
							renderWidth = 162;
							renderHeight = 288;
						}

						var w = (p.scale.x*renderWidth);
						var h = (Math.abs(p.scale.y)*renderHeight);

						var fontSize =  Utils.clamp((w / 15), '0.5', '1.1');
						// $(this.divs[i].div).css('font-size', fontSize +'em');
						// w = Math.max(w, $(this.divs[i].container).width());
						// h = Math.max(h, $(this.divs[i].div).height());


						var w = (p.scale.x*renderWidth);
						var h = (Math.abs(p.scale.y)*renderHeight);

						var tx = ((p.position.x)*renderWidth - w/2);
						var ty = ((p.position.y)*renderHeight - h/2);
						if (tx - w < 0) tx += Math.abs(tx - w);
						if (ty - h < 0) ty += Math.abs(ty - h);

						$(this.divs[i].container).css({
							'transform': 'translate('+tx+'px,'+ty+'px)',
							'width': w+'px',
							'height': h+'px'
						});

						if (!this.divs[i].visible) {
							$(this.divs[i].container).css('display', 'table');
							this.divs[i].visible = true;
						}
					}
				}

				for (var i=rects.length; i<this.boxes.length; i++) {
					this.boxes[i].visible = false;
					if (this.params.effect.debug) {
						if (this.boxDebugPlanes[i]) this.boxDebugPlanes[i].visible = false;
						if (this.divs[i] && this.divs[i].visible) {
							$(this.divs[i].container).hide();
							this.divs[i].visible = false;
						}
					}
				}	

			} else {
				for (var i=0; i<this.boxes.length; i++) {
					this.boxes[i].visible = false;
					if (this.params.effect.debug) {
						if (this.boxDebugPlanes[i]) this.boxDebugPlanes[i].visible = false;
						if (this.divs[i] && this.divs[i].visible) {
							$(this.divs[i].container).hide();
							this.divs[i].visible = false;
						}
					}
				}
			}
		}
	}

	dispose() {
		super.dispose();
		for (var i=0; i<this.divs.length; i++) {
			$(this.divs[i].d).remove();
			$(this.divs[i].dc).remove();
		}
		this.divs = [];
	}

	render() {
		super.render();
		renderer.render(this.boxesScene, Utils.topLeftCamera, this.fbo.texture, false);

	}
};

export default SpritesheetVideoBoxes;


