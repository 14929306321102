
import * as THREE from 'three';

import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'

import RendererController from './RendererController.js';
import MaterialController from './MaterialController.js';
import UserVideoController from './UserVideoController.js';
import TextureUpdateController from './TextureUpdateController.js'
import S3SubmitController from './S3SubmitController.js'
import AnalysisController from './AnalysisController.js'

import Loader from '../loading/Loader.js'
import PageLoader from '../loading/PageLoader.js'
import Fbo from '../utils/Fbo.js'
import SpritesheetVideoCreate from '../objects/SpritesheetVideoCreate.js'
import SpritesheetVideo from '../objects/SpritesheetVideo.js'


// //-----------
// //
// //  The Worker to be sent as bloburl
// //
// //-----------f
// function MozWorker() {
//  2016
//  7x4 288x512
//  432x768 || 4x2  ->  1728x1536
//  360x640 || 5x3  ->  1800x1920
//  360x640 || 4x3  ->  1440x1920
//  378x672 || 4x3  ->  1512x2016
//  378x672 || 4x3  ->  1512x2016
//  405x720 ||      ->      x2160
//  511.875x910 ||  4x2
//
//  var SPRITESHEET_WIDTH = 2048,
//  SPRITESHEET_HEIGHT = 1820,
// 	SPRITESHEET_X = 4,
// 	SPRITESHEET_Y = 2
//
var SPRITESHEET_WIDTH = 1512,
	SPRITESHEET_HEIGHT = 2016,
	SPRITESHEET_X = 4,
	SPRITESHEET_Y = 3,
	SPRITESHEET_QUALITY = 0.85,
	MAX_FRAMES = 72,
	NUM_SP = MAX_FRAMES / (SPRITESHEET_X*SPRITESHEET_Y);


//
// Even smaller for very old phones?
// 24 per spritesheet, 6x4, 288x512, 1728x2048, higher quality
//

//
//  Save every frame directly to a decent quality spritesheet using viewport & scissor
//  Save the spritesheet binary data for future decoding & playback if referenced later in task
//  Other option: canvas 2d + texture
//
function FrameRecorder() {

	this.moduleab = null;
	this.workerReady = false;
	this.preload = function(batchName) {
		this.moduleab = Loader.addXHR(batchName, SETTINGS.OTHER_ASSETS_URL+'libs/libjpeg-turbo-worker.wasm', 'arraybuffer');
		Loader.addImage(batchName, SETTINGS.OTHER_ASSETS_URL+'images/ui/capture_yes.png');
		Loader.addImage(batchName, SETTINGS.OTHER_ASSETS_URL+'images/ui/capture_redo.png');
		Loader.addImage(batchName, SETTINGS.OTHER_ASSETS_URL+'images/ui/skip.png');
		Loader.addImage(batchName, SETTINGS.OTHER_ASSETS_URL+'images/ui/camera.png');
		// Loader.addScript(batchName, 'https://sdk.amazonaws.com/js/aws-sdk-2.283.1.min.js');
	}


	this.wasSetup = false;
	this.init = function() {
		if (!this.fbos) {
			this.temporaryTextures = [];
			this.fbos = [];
			for (var i=0; i<NUM_SP; i++) {
				this.fbos.push(new Fbo(SPRITESHEET_WIDTH, SPRITESHEET_HEIGHT, {
					minFilter:THREE.LinearFilter,
					magFilter:THREE.LinearFilter,
					format:THREE.RGBAFormat,
					type:THREE.UnsignedByteType,
					antialias: true,
					depthBuffer: false,
					stencilBuffer: false,
					premultiplyAlpha: false,
					generateMipmaps: false,
					forceClear: false,
					pingpong: false,
					renderer: renderer
				}));
				// this.fbos[i].texture.flipY = false;
				// this.fbos[i].texture.texture.flipY = false;
				this.temporaryTextures.push(this.fbos[i].texture.texture);
				renderer.setRenderTarget(this.fbos[i].texture);
				renderer.setClearColor(Utils.blackColor, 0);
				renderer.clear(true,true,true);
				renderer.setRenderTarget();
			}

		}
		

		if (this.wasSetup || (!this.moduleab || (this.moduleab && !this.moduleab.loaded))) return;
		this.wasSetup = true;

		// if (SETTINGS.OLD_PHONE_MODE) {
		// 	SPRITESHEET_WIDTH = 1728;
		// 	SPRITESHEET_HEIGHT = 2048;
		// 	SPRITESHEET_X = 7;
		// 	SPRITESHEET_Y = 4;
		// }

		//FrameRecorder has it own renderer for exporting as jpeg
		// renderer = new THREE.WebGLRenderer({ antialias: true, autoClear: false, transparent:false, alpha:false, preserveDrawingBuffer:true, precision:'mediump', stencil:false});
		// renderer.setSize(SPRITESHEET_WIDTH, SPRITESHEET_HEIGHT);
		// renderer.setClearColor(0x000000, 1.0);
		// renderer.clear();
		// renderer.autoClear = false;

		this.camera = new THREE.OrthographicCamera( -1, 1, -1, 1, -1, 1 );
		// this.cameraTexture = new THREE.Texture(Utils.emptyCanvas);
		// this.cameraTexture.format = THREE.RGBFormat;
		// this.cameraTexture.type = THREE.UnsignedByteType;
		// this.cameraTexture.wrapT = this.cameraTexture.wrapS = THREE.ClampToEdgeWrapping;
		// this.cameraTexture.magFilter = THREE.LinearFilter;
		// this.cameraTexture.minFilter = THREE.LinearFilter;
		// this.cameraTexture.generateMipmaps = false;
		// this.cameraTexture.anisotropy = 1;
		// this.cameraTexture.needsUpdate = false;


		this.recordSpeedFps = 24.0;
		this.recordStartTime = 0.0;

		// this.encodeWorker = new Worker("libs/emozworker.js");
		// this.encodeWorker = new Worker("libs/MozWorker.js");

		// this.encodeWorker = new Worker("workers/pttjpeg.js");
		// this.encodeWorker = {postMessage:function(){}};

		console.log("Setting up encoder worker");
			
		var workerPath = "libs/libjpeg-turbo-worker.js";
		this.encodeWorker = new Worker(workerPath);
		this.encodeWorker.onmessage = this.receiveEncoded;
		this.encodeWorker.onerror = console.error;
		setTimeout(() => {
			if (this.moduleab) this.encodeWorker.postMessage({"setup":true, "wasmBinary": this.moduleab.value}, [this.moduleab.value]);
			if (this.moduleab) this.moduleab = this.moduleab.value = null;
		},10000);

		// var blobURL = window.URL.createObjectURL( new Blob([ '(',MozWorker.toString(), ')()' ], { type: 'application/javascript' } ) );
		// this.mozWorker = new Worker( blobURL );
		// URL.revokeObjectURL( blobURL );
		// this.worker.onmessage = this.receive.bind(this);

		//recording status
		this.hasAnalysis = false;
		this.lastFrameTime = performance.now();
		this.currentFrame = 0;
		this.numFrames = 48;
		this.numSheets = Math.ceil(this.numFrames / (SPRITESHEET_X * SPRITESHEET_Y));
		this.recordRate = 1000/24;
		this.exportId = "";
		this.activeId = "";
		this.tempInputData = [];
		this.uploadErrors = {};
		this.submitDoneByTask = {};

			
		// this.getFbos();

		this.spritesheetVideo = new SpritesheetVideo();
		this.spritesheetVideo.setup({
			numFrames: MAX_FRAMES,
			numX: SPRITESHEET_X,
			numY: SPRITESHEET_Y,
			videoSpeed: 1.0,
			palindrome: false,
			reverse: false,
			videoWidth: Math.floor(SPRITESHEET_WIDTH/SPRITESHEET_X),
			videoHeight: Math.floor(SPRITESHEET_HEIGHT/SPRITESHEET_Y),
			duration: 2.0,
		}, this.temporaryTextures, "texture");
		this.spritesheetVideo.isUser = true;
		this.spritesheetVideo.isTemporary = true;


		//-----------------------
		// Max rec for fast return : 24 frames??
		//-----------------------
		//8x256, 6x341 ou  8x192 6x341  1536x2048
		// this.fastFbo = new Fbo(2048, 2048, { //416x, 6x5
		// 	minFilter:THREE.NearestFilter,
		// 	magFilter:THREE.NearestFilter,
		// 	format:THREE.RGBAFormat,
		// 	type:THREE.UnsignedByteType,
		// 	antialias: true,
		// 	depthBuffer: false,
		// 	stencilBuffer: false,
		// 	premultiplyAlpha: false,
		// 	generateMipmaps: false,
		// 	forceClear: false,
		// 	pingpong: false,
		// 	renderer: renderer
		// }); //288x512
		// this.fastCanvas = document.createElement('canvas');
		// this.fastContext = this.fastCanvas.getContext('2d');


		//------------------
		//
		//  Recording scene
		//
		//------------------
		this.recordMaterial = new THREE.RawShaderMaterial({
			vertexShader:`
				precision mediump float;
				precision mediump int;

				uniform mediump mat4 modelViewMatrix; // optional
				uniform mediump mat4 projectionMatrix; // optional

				attribute mediump vec3 position;
				attribute mediump vec2 uv;

				varying mediump vec2 vUv;

				uniform mediump vec2 ratio;

				void main() {
					vUv = (vec2(uv.x, uv.y)-0.5) * ratio + 0.5;
					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}
			`,
			fragmentShader:`
				precision mediump float;
				precision mediump int;

				varying mediump vec2 vUv;
				uniform mediump sampler2D tDiffuse;

				void main() {
					gl_FragColor = vec4(texture2D(tDiffuse, vUv).rgb, 1.0);
				}
			`,
			uniforms: {
				tDiffuse: {type:'t', value: Utils.whiteTexture},
				ratio: {type:'v2', value: new THREE.Vector2(1, 1)}
			},
			transparent: false,
			blending: THREE.NoBlending,
			side: THREE.DoubleSide,
			depthTest: false,
			depthWrite: false
		});
		this.recordScene = new THREE.Scene();
		this.recordPlane = new THREE.Mesh(new THREE.PlaneBufferGeometry(2,2,1,1), this.recordMaterial);
		this.recordScene.add(this.recordPlane);


	};

	//------------------
	//
	//  Record
	//
	//------------------
	this.recording = false;
	this.record = function(params, task_info, recordId) {
		if (!this.isReady()) {
			window.alert("Error: Recording before export is ready.");
			return false;
		}

		UserVideoController.clearTemporaryBins();

		this.params = params;
		this.task_info = task_info
		this.exporting = false;
		this.recording = true;
		this.lastFrameTime = 0; //performance.now(); //>time offset
		this.currentFrame = 0;
		this.duration = params.duration==undefined ? 2.0 : params.duration;
		if (this.duration <= 1/24) this.duration = 1/24;
		this.recordRate = 1000/24;
		// if (this.duration>3.0) this.recordRate = 1000 / (MAX_FRAMES / this.duration);
		this.recordStart = performance.now();
		this.numFrames = Math.max(Math.floor(Math.min(this.duration*24, MAX_FRAMES)),1);
		this.numSheets = Math.ceil(this.numFrames / (SPRITESHEET_X*SPRITESHEET_Y));
		this.facesInfo = null;
		this.recordStartTime = performance.now();

		this.videoName = Utils.generateUUID();
		this.recordId = recordId;
		this.spritesheetVideo.setParams({
			name: this.videoName,
			numFrames: this.numFrames,
			numX: SPRITESHEET_X,
			numY: SPRITESHEET_Y,
			duration: this.duration,
			videoSpeed: params.speed||1.0,
			palindrome: params.palindrome,
			effect: params.effect || ((params.analysis && SETTINGS.ANALYSIS_DEBUG) ? AnalysisController.ANALYSIS_DEBUG_DEFAULTS[params.analysis] : null),
			analysis: SETTINGS.ANALYSIS_DEBUG ? params.analysis : null,
			analysis_class: params.analysis_class,
			noise: params.noise,
			upsideDown: params.upsideDown,
			fill: params.fill,
			padding: params.padding,
			reverse: params.reverse||params.backward||params.backwards||false,
			videoWidth: Math.floor(SPRITESHEET_WIDTH/SPRITESHEET_X),
			videoHeight: Math.floor(SPRITESHEET_HEIGHT/SPRITESHEET_Y),
			faces: params.faces,
			noFaces: params.noFaces,
			invertColors: params.invertColors
		}, this.temporaryTextures, "texture");

		if (SETTINGS.DIRECT_S3_MODE) {
			S3SubmitController.getPrivateUrls(this.videoName, this.numSheets);
		}

		//
		// Return info
		//
		this.analysis = false; //params.analysis;
		this.hasAnalysis = false; //!!params.analysis;

		// this.spritesheetVideo.analysisUUID = null;;

		// if (this.hasAnalysis) {
		// 	// AnalysisController.setUUID(this.recordId, this.analysisUUID);
		// 	// this.spritesheetVideo.analysisUUID = this.analysisUUID;
		// 	this.analysisNumFrames = this.numFrames;
		// 	this.analysisIsBinary = !! AnalysisController.ANALYSIS_IS_BINARY[this.analysis];

		// 	if (AnalysisController.ANALYSIS_FORCE_FRAMES[this.analysis]) {

		// 		this.analysisNumFrames =  Math.min(this.numFrames, AnalysisController.ANALYSIS_FORCE_FRAMES[this.analysis].numFrames);
		// 		this.analysisNumX = this.analysisNumFrames;
		// 		this.analysisNumY = 1;
		// 		this.fastFbo.resize(AnalysisController.ANALYSIS_FORCE_FRAMES[this.analysis].width*this.analysisNumX, AnalysisController.ANALYSIS_FORCE_FRAMES[this.analysis].height*this.analysisNumY); //192x341

		// 	} else {
		// 		if (this.numFrames <= 1) {
		// 			this.analysisNumX = 1;
		// 			this.analysisNumY = 1;
		// 			this.fastFbo.resize(288, 512);
		// 		} else if (this.numFrames <= 12) {
		// 			this.analysisNumX = 4;
		// 			this.analysisNumY = 3;
		// 			this.fastFbo.resize(768, 1024); //192x341
		// 		} else if (this.numFrames <= 24) {
		// 			this.analysisNumX = 4;
		// 			this.analysisNumY = 6;
		// 			this.fastFbo.resize(768, 2048); //192x341
		// 		} else {
		// 			this.analysisNumX = 8;
		// 			this.analysisNumY = 6;
		// 			this.fastFbo.resize(1536, 2048); //192x341
		// 		}
		// 	}
		// 	this.analysisSkipFrames = Math.max(Math.floor(this.numFrames/this.analysisNumFrames),1);
		// 	this.analysisCurrentFrame = 0;

		// 	this.fastCanvas.width = this.fastFbo.width;
		// 	this.fastCanvas.height = this.fastFbo.height;
		// }
		
	};

	this.prepare = function() {
		for (var i=0; i<NUM_SP; i++) {
			if (!this.fbos[i].prepared) {
				if (TextureUpdateController.updateRenderTarget(this.fbos[i].texture)) {
					this.fbos[i].prepared = true;
				}
			}
		}
	};
	this.prepareAll = function() {
		for (var i=0; i<NUM_SP; i++) {
			if (!this.fbos[i].prepared) {
				renderer.setRenderTarget(this.fbos[i].texture);
				renderer.clear();
				this.fbos[i].prepared = true;
			}
		}
		renderer.setRenderTarget();
	};

	this.update = function() {
		if (CameraController.isReady() && this.recording) {

			//params -> set record speed, number of frames, etc
			if (performance.now()-this.lastFrameTime >= this.recordRate && this.currentFrame < this.numFrames) {
				this.lastFrameTime += this.recordRate;
				this.lastFrameTime = Math.max(this.lastFrameTime, performance.now() - this.recordRate/2);

				// if (Math.abs(performance.now()-this.lastFrameTime) >= this.recordRate/2) { //low fps recording, record as fast as possible
				// 	console.log("low fps recording",(performance.now()-(this.lastFrameTime-this.recordRate) ), this.recordRate);
				// 	this.lastFrameTime = performance.now(); - this.recordRate/2;
				// }
				// if ((CameraController.getVideo().currentTime-this.lastFrameTime)*1000 >= this.recordRate && this.currentFrame < this.numFrames) {
				// 	this.lastFrameTime = CameraController.getVideo().currentTime;
				// 	console.log(this.currentFrame, CameraController.getVideo().currentTime);

				var spritesheetFrame = this.currentFrame % (SPRITESHEET_X * SPRITESHEET_Y);
				var currentSheet = Math.floor( this.currentFrame / (SPRITESHEET_X * SPRITESHEET_Y) );
				var frameX = spritesheetFrame % SPRITESHEET_X;
				var frameY = Math.floor(spritesheetFrame / SPRITESHEET_X);
				// frameY = SPRITESHEET_Y - 1 - frameY;

				this.fbos[currentSheet].prepared = true;
				renderer.setRenderTarget(this.fbos[currentSheet].texture);
				this.fbos[currentSheet].texture.scissor.set(
					(frameX / SPRITESHEET_X) * SPRITESHEET_WIDTH,
					(frameY / SPRITESHEET_Y) * SPRITESHEET_HEIGHT,
					(1 / SPRITESHEET_X) * SPRITESHEET_WIDTH,
					(1 / SPRITESHEET_Y) * SPRITESHEET_HEIGHT);
				this.fbos[currentSheet].texture.viewport.set(
					(frameX / SPRITESHEET_X) * SPRITESHEET_WIDTH,
					(frameY / SPRITESHEET_Y) * SPRITESHEET_HEIGHT,
					(1 / SPRITESHEET_X) * SPRITESHEET_WIDTH,
					(1 / SPRITESHEET_Y) * SPRITESHEET_HEIGHT);
				this.fbos[currentSheet].texture.scissorTest = true;

				//ratio
				var video = CameraController.getVideo();
				var videoWidth = video.videoWidth||9;
				var videoHeight = video.videoHeight||16;

				//
				if ( videoWidth/videoHeight > 9/16 ) { //wider
					this.recordMaterial.uniforms.ratio.value.set((9/16)/(videoWidth/videoHeight), 1);
				} else { //taller
					this.recordMaterial.uniforms.ratio.value.set(1, (16/9)/(videoHeight/videoWidth));
				}
				if (this.params.camera === 'front') {
					this.recordMaterial.uniforms.ratio.x *= -1.0;
					this.recordPlane.scale.x = -1;
				} else {
					this.recordPlane.scale.x = 1;
				}

				//record
				// CameraController.texture.needsUpdate = CameraController.texture.updateFrame != AppStatus.currentFrame;
				// CameraController.texture.updateFrame = AppStatus.currentFrame;
				CameraController.updateTexture();
				this.recordMaterial.uniforms.tDiffuse.value = CameraController.videoTexture;
				// this.recordPlane.scale.x = CameraController.getFlip();
				renderer.render(this.recordScene, this.camera, this.fbos[currentSheet].texture, false);

				//
				// Fast return
				//
				// if (this.hasAnalysis) {

				// 	// console.log(this.analysisCurrentFrame < this.analysisNumFrames, this.currentFrame,  this.analysisSkipFrames, this.currentFrame % this.analysisSkipFrames == 0)
				// 	if (this.analysisCurrentFrame < this.analysisNumFrames && this.currentFrame % this.analysisSkipFrames == 0) {
				// 		frameX = this.analysisCurrentFrame % this.analysisNumX;
				// 		frameY = Math.floor(this.analysisCurrentFrame / this.analysisNumX);

				// 		renderer.setRenderTarget(this.fastFbo.texture);
				// 		this.fastFbo.texture.scissor.set(
				// 			(frameX / this.analysisNumX) * this.fastFbo.width,
				// 			(frameY / this.analysisNumY) * this.fastFbo.height,
				// 			(1 / this.analysisNumX) * this.fastFbo.width,
				// 			(1 / this.analysisNumY) * this.fastFbo.height);
				// 		this.fastFbo.texture.viewport.set(
				// 			(frameX / this.analysisNumX) * this.fastFbo.width,
				// 			(frameY / this.analysisNumY) * this.fastFbo.height,
				// 			(1 / this.analysisNumX) * this.fastFbo.width,
				// 			(1 / this.analysisNumY) * this.fastFbo.height);
				// 		this.fastFbo.texture.scissorTest = true;
				// 		renderer.render(this.recordScene, this.camera, this.fastFbo.texture, false);
				// 		this.analysisCurrentFrame++;
				// 	}
				// }

				this.recordMaterial.uniforms.ratio.value.set(1,1);
				renderer.setRenderTarget();

				this.currentFrame++;

				if (this.currentFrame >= this.numFrames) {
					var actualRecordRate = ((performance.now() - this.recordStartTime) / (this.numFrames) / (1000/24));
					console.log("ACTUAL RECORD RATE:", actualRecordRate);
					if (actualRecordRate > 2.25) {
						console.log("Record rate is very slow. Setting old phone mode and disabling features.");
						var wasOld = SETTINGS.OLD_PHONE_MODE;
						SETTINGS.OLD_PHONE_MODE = true;
						if (!wasOld) AppStatus.resize();
					}
				}
			}
		} else {
			this.lastFrameTime = performance.now();
		}
		return this.currentFrame >= this.numFrames;
	};

	//------------------
	//
	//  Get fast return w/ face analysis & stuff
	//
	//------------------

	//------------------
	//
	//  Export the frames
	//
	//------------------
	this.submit_params = {
		video_name: "",
		user_uuid: "",
		bins: [],
		upload_source: "web",
		user_uuid: "",
		device_info: "",
		user_agent: "",
		num_frames: "",
		duration: "",
		video_speed: "",
		numx: "",
		numy: "",
		num_spritesheets: "",
		resolution_width: "",
		resolution_height: "",
		video_width: "",
		video_height: "",
		task_json: ""
	};

	// this.submit = function(blobs) {
	// 	var uploadCount = {n:0, num:blobs.length}
	// 	for (var i=0; i<blobs.length; i++) {
	// 		(function(blob, i, uploadCount, recordId, submit_params) {

	// 			setTimeout(function() {
	// 				var file = new Blob([blob], {type: "image/jpg"});
	// 				//new File([blob], "image");
	// 				var formData = new FormData();
	//  				formData.append('image', file);
	//  				for (o in submit_params) {
	//  					formData.append(o, submit_params[o]);
	//  				}
	// 				var xhr = new XMLHttpRequest();
	// 				xhr.open('POST', SETTINGS.API_SERVER_URL+"/submit", true);
	// 				console.log('SENDING FRAME',i, SETTINGS.API_SERVER_URL+"/submit", submit_params)
	// 				xhr.onreadystatechange = function (e) {
	// 					if (e.target.readyState >= 4 && e.target.status <= 200) {

	// 						console.log('Submit done', e.target);
	// 						formData = blob = file = null;
	// 						xhr.onreadystatechange = null;

	// 						uploadCount.n++;
	// 						if (uploadCount.n >= uploadCount.num) {
	// 							console.log("DONE UPLOADING >> SETTING STATE", recordId, submit_params.bins);
	// 							console.log(submit_params);
	// 							var tasksState = MultiStorage.getGlobalState();
	// 							tasksState.userVideos[recordId] = submit_params;
	// 							for (var i=0; i<submit_params.bins; i++) {
	// 								console.log("adding to bin", submit_params.bins[i], submit_params);
	// 								tasksState.userVideosByBin[submit_params.bins[i]] = submit_params;
	// 							}
	// 							MultiStorage.setGlobalState(tasksState);
	// 						}

	// 					//
	// 					// Parse error
	// 					//
	// 					} else if (e.target.status >= 300) {
	// 						console.log(e.target);
	// 						if (!window.oneAlert) alert("Upload Error. Reloading.");
	// 						window.oneAlert = true;
	// 						window.location.reload();
	// 					}
	// 				};
	// 				xhr.responseType = 'arraybuffer';
	// 				xhr.send(formData);
	// 			},i*10);

	// 		})(blobs[i], i, uploadCount, this.recordId, {
	// 			current_frame: i,
	// 			video_name: this.videoName,
	// 			user_uuid: SETTINGS.uuid,
	// 			bins: [].concat(this.params.bin),
	// 			upload_source: "web",
	// 			device_info: navigator.userAgent,
	// 			user_agent: navigator.userAgent,
	// 			num_frames: this.numFrames,
	// 			duration: this.duration,
	// 			video_speed: this.params.speed||1.0,
	// 			numx: SPRITESHEET_X,
	// 			numy: SPRITESHEET_Y,
	// 			num_spritesheets: this.numSheets,
	// 			resolution_width: SPRITESHEET_WIDTH,
	// 			resolution_height: SPRITESHEET_HEIGHT,
	// 			video_width: Math.floor(SPRITESHEET_WIDTH/SPRITESHEET_X),
	// 			video_height:Math.floor(SPRITESHEET_HEIGHT/SPRITESHEET_Y),
	// 			task_json: JSON.stringify(this.task_info)
	// 		});
	// 	}
	// };


	this.exportingSpritesheetsByName = {};

	this.receiveEncoded = function(e) {
		if (e.data.action == "ready") {

			this.workerReady = true;
			console.log("JPEG Worker ready!");

		} else if (e.data.action == "moduleReady") {

			if (this.moduleab) this.encodeWorker.postMessage({"setup":true, "wasmBinary": this.moduleab.value}, [this.moduleab.value]);
			console.log("ModuleReady sent");
			if (this.moduleab) this.moduleab = this.moduleab.value = null;

		} else if (e.data.action == "submitDone") {


		} else if (e.data.action == "uploadError") {

			console.log ("GOT UPLOAD ERROR", e.data.submitParams["video_name"]);
			if (e.data && e.data.submitParams) {
				if (!this.uploadErrors[e.data.submitParams["video_name"]]) {
					//upload error : cleanup submit
					var submit_params = e.data.submitParams;

					//set global state for reloading
					var tasksState = MultiStorage.getGlobalState();
					tasksState.userVideosByName = tasksState.userVideosByName||{};
					delete tasksState.userVideosByName[submit_params["video_name"]];
					delete tasksState.userVideos[e.data.recordId];
					for (var i=0; i<submit_params.bins.length; i++) {
						delete tasksState.userVideosByBin[submit_params.bins[i]];
					}
					MultiStorage.setGlobalState(tasksState);	

					//cleanup
					var tasksState = MultiStorage.getState();
					tasksState.userVideos = tasksState.userVideos||{};
					tasksState.userVideosByBin = tasksState.userVideosByBin||{};
					delete tasksState.userVideos[e.data.recordId];
					for (var i=0; i<submit_params.bins.length; i++) {
						delete tasksState.userVideosByBin[submit_params.bins[i]];
					}
					MultiStorage.setState(tasksState);
				}
				this.uploadErrors[e.data.submitParams["video_name"]] = true;
			}


		} else if (e.data.action === 'encodeDone') {

			// if (SETTINGS.DIRECT_S3_MODE) {
			// 	var formData = new FormData();
			// 	var fields =  e.data.direct_s3info.fields;
			// 	for (var o in fields) {
			// 		console.log(o,fields[o]);
			// 		formData.append(o, fields[o]);
			// 	}					
			// 	var blob = new Blob([e.data.bytes.buffer], {type: "image/jpeg"});
			// 	formData.append('file', blob, fields.key);
			// 	console.log("adding buffer to formData",fields.key,blob,e.data.bytes.buffer);
			// 	var xhr = new XMLHttpRequest();
			// 	xhr.open('POST', e.data.direct_s3info.url, true);
			// 	xhr.send(formData);
			// 	xhr = formData = null;
			// }


			var spritesheet = this.exportingSpritesheetsByName[e.data.name];
			spritesheet.frames[e.data.frameId] = {value:e.data.bytes, loaded:true, loadError:e.data.encodeError};
			if (e.data.inputData && this.tempInputData.length < 0) {
				this.tempInputData.push(new Uint8Array(e.data.inputData));
			} else {
				e.data.inputData = null; //~~MemoryDisposeController.disposeArraybuffer(e.data.inputData);
			}
			spritesheet.numReceived++;
			if (e.data.encodeError) {
				this.uploadErrors[e.data.submitParams["video_name"]] = true;
				console.log("Encode Error");
			}

			if (spritesheet.numReceived >= e.data.numSheets) {
				console.log("DONE ENCODING", e.data.recordId);
				spritesheet.exportDone(spritesheet.frames);
				this.exportingSpritesheetsByName[e.data.name] = null;


				// this.submitDoneByTask[e.data.recordId]++;
				// if (this.submitDoneByTask[e.data.recordId] >= e.data.numSheets) {

					if (!e.data.noUpload && !SETTINGS.NO_UPLOAD && !this.uploadErrors[e.data.submitParams["video_name"]]) {

						if (SETTINGS.DIRECT_S3_MODE) {

							// if (!SETTINGS.QA_MODE) {
							e.data.submitParams["beta"] = !!SETTINGS.BETA_MODE;
							e.data.submitParams["bins"] = e.data.submitParams["bins"].join(',');
							if (SETTINGS.QA_MODE) e.data.submitParams["qa_mode"] = !!SETTINGS.QA_MODE;
							var formData = new FormData();
							for (var o in e.data.submitParams) {
								formData.append(o, e.data.submitParams[o]);
							}

							// (function() {
							var submit_params = e.data.submitParams;
							submit_params.use_source = true;
							submit_params.bins = submit_params.bins.split(',');
							submit_params.source_numx = submit_params.numx;
							submit_params.source_numy = submit_params.numy;
							submit_params.source_num_spritesheets = submit_params.num_spritesheets;
							submit_params.source_video_width = submit_params.video_width;
							submit_params.source_video_height = submit_params.video_height;
							submit_params.source_path = "sources/"+submit_params["user_uuid"]+"/"+submit_params["video_name"]+"/frame_%d.jpg";
							
							//set global state for reloading
							var tasksState = MultiStorage.getGlobalState();
							tasksState.userVideosByName = tasksState.userVideosByName||{};
							tasksState.userVideosByName[submit_params["video_name"]] = submit_params;
							tasksState.userVideos[e.data.recordId] = submit_params["video_name"];
							for (var i=0; i<submit_params.bins.length; i++) {
								tasksState.userVideosByBin[submit_params.bins[i]] = submit_params["video_name"];
							}
							if (UserVideoController.facesByName[submit_params["video_name"]]) submit_params["faces_info"] = JSON.stringify(UserVideoController.facesByName[submit_params["video_name"]]);
							MultiStorage.setGlobalState(tasksState);	

							//set local state for reloading
							var tasksState = MultiStorage.getState();
							tasksState.userVideos = tasksState.userVideos||{};
							tasksState.userVideosByBin = tasksState.userVideosByBin||{};
							tasksState.userVideos[e.data.recordId] = submit_params["video_name"];
							for (var i=0; i<submit_params.bins.length; i++) {
								tasksState.userVideosByBin[submit_params.bins[i]] = submit_params["video_name"];
							}
							MultiStorage.setState(tasksState);


							// })();
							var xhr = new XMLHttpRequest();
							xhr.open('POST', SETTINGS.API_SERVER_URL+"/submit_done", true);
							// xhr.onreadystatechange = ((xhre) => {
							// 	if (xhre.target.readyState >= 4 && xhre.target.status >= 200 && xhre.target.status < 300) {
									// var tasksState = MultiStorage.getGlobalState();
									// tasksState.userVideos[e.data.recordId] = e.data.name;
									// if (e.data.bins) {
									// 	for (var i=0; i<e.data.bins.length; i++) {
									// 		console.log("saving info to bin", e.data.bins[i], e.data.name);
									// 		tasksState.userVideosByBin[e.data.bins[i]] = e.data.name;
									// 	}
									// }
									// MultiStorage.setGlobalState(tasksState);
									

							// 	}
							// });
							xhr.send(formData);
							xhr = formData = null;
							// } else {
							// 	var tasksState = MultiStorage.getGlobalState();
							// 	tasksState.userVideosByName[submit_params["video_name"]] = submit_params;
							// 	tasksState.userVideos[e.data.recordId] = submit_params["video_name"];
							// 	for (var i=0; i<submit_params.bins; i++) {
							// 		console.log("adding to bin", submit_params.bins[i], submit_params);
							// 		tasksState.userVideosByBin[submit_params.bins[i]] = submit_params["video_name"];
							// 	}
							// 	MultiStorage.setGlobalState(tasksState);
							// }
			

						} else {

							var submit_params = e.data.submitParams;
							submit_params.use_source = true;
							submit_params.bins = submit_params.bins.split(',');
							var tasksState = MultiStorage.getGlobalState();
							tasksState.userVideosByName = tasksState.userVideosByName||{};
							tasksState.userVideosByName[submit_params["video_name"]] = submit_params;
							tasksState.userVideos[e.data.recordId] = submit_params["video_name"];
							for (var i=0; i<submit_params.bins; i++) {
								tasksState.userVideosByBin[submit_params.bins[i]] = submit_params["video_name"];
							}
							MultiStorage.setGlobalState(tasksState);
						}



						
						// var tasksState = MultiStorage.getState();
						// tasksState.userVideos[e.data.recordId] = e.data.name;
						// if (e.data.bins) {
						// 	for (var i=0; i<e.data.bins.length; i++) {
						// 		tasksState.userVideosByBin[e.data.bins[i]] = e.data.name;
						// 	}
						// }
						// MultiStorage.setState(tasksState);
					}
					if (e.facesInfo) AnalysisController.addAnalysis(e.data.recordId, e.facesInfo);


					console.log("DONE SUBMITTING >> SETTING STATE", e.data.recordId,e.data.bins);
			}
		}
		e = null;
	}.bind(this);



	this.blobs = [];
	this.currentExport = 0;
	this.exportCallback = null;
	this.encode = function() {
		if (!this.workerReady) {
			console.log("waiting for worker");
			setTimeout(this.encode, 50);
			return;
		}


		if (SETTINGS.DIRECT_S3_MODE) {
			S3SubmitController.getPrivateUrls(this.videoName, this.numSheets).then((urls) => {

				var submitParams = {
					current_frame: this.currentExport,
					video_name: this.videoName,
					user_uuid: SETTINGS.uuid,
					bins: [].concat(this.params.bin),
					upload_source: "web",
					device_info: navigator.userAgent,
					user_agent: navigator.userAgent,
					num_frames: this.numFrames,
					duration: this.duration,
					video_speed: this.params.speed||1.0,
					numx: SPRITESHEET_X,
					numy: SPRITESHEET_Y,
					num_spritesheets: this.numSheets,
					resolution_width: SPRITESHEET_WIDTH,
					resolution_height: SPRITESHEET_HEIGHT,
					video_width: Math.floor(SPRITESHEET_WIDTH/SPRITESHEET_X),
					video_height:Math.floor(SPRITESHEET_HEIGHT/SPRITESHEET_Y),
					task_json: JSON.stringify(this.task_info),
					beta: true,
			   		betaId: SETTINGS.BETA_ID
			   };

				var readPixelsTime = performance.now();
				var buf = this.tempInputData.length>0?this.tempInputData.pop():new Uint8Array(SPRITESHEET_HEIGHT*SPRITESHEET_WIDTH*4);

				renderer.readRenderTargetPixels(this.fbos[this.currentExport].texture, 0, 0, SPRITESHEET_WIDTH,SPRITESHEET_HEIGHT, buf);
				renderer.setRenderTarget();


				var submitUrls = urls?JSON.parse(urls[this.currentExport].replace(/\'/g, '"')):null;

				// if (typeof urls[this.currentExport] == "string") urls[this.currentExport] = JSON.parse(urls[this.currentExport]);
				this.encodeWorker.postMessage({ 
				  'quality' : Math.floor(SPRITESHEET_QUALITY*100),              // quality desired
				  'imageData' : buf.buffer,      // the imageData object
				  'width' : SPRITESHEET_WIDTH,    // the width of the image
				  'height' : SPRITESHEET_HEIGHT,   // the height of the image
				  'submitParams': submitParams,
				   bins: [].concat(this.params.bin),
				   SERVER_URL: SETTINGS.API_SERVER_URL,
				   recordId: this.recordId,
				   beta: this.BETA_MODE,
				   betaId: SETTINGS.BETA_ID,
				   numSheets: this.numSheets,
				   name: this.videoName,
				   facesInfo: this.facesInfo,
				   frameId: this.currentExport,
				   noUpload: !submitUrls || this.params.noUpload || SETTINGS.NO_UPLOAD,
				   DIRECT_S3_MODE: SETTINGS.DIRECT_S3_MODE,
				   direct_s3info: submitUrls //urls[this.currentExport]
				}, [buf.buffer]);

				console.log("Jpeg Send time:", performance.now()-readPixelsTime);

				this.currentExport++;
				if (this.currentExport < this.numSheets) {
					if (this.activeId !== this.exportId) this.encode();
					else {
						setTimeout(this.encode, 16);
					}
				} else {

					this.exporting = false;
					if (this.exportCallback) this.exportCallback(this.blobs);
					this.exportCallback = null;
				}



			});
			return;
		}

		console.warn("this mode has since been disabled");

		//render the fbo to canvas
		// renderer.setRenderTarget(null);
		// renderer.setScissorTest(false);
		// renderer.setViewport(0, 0, SPRITESHEET_WIDTH, SPRITESHEET_HEIGHT);
		// this.recordMaterial.uniforms.tDiffuse.value = this.fbos[this.currentExport].texture.texture;
		// renderer.render(this.recordScene, this.camera, null, true);
				
		var readPixelsTime = performance.now();
		var buf = this.tempInputData.length>0?this.tempInputData.pop():new Uint8Array(SPRITESHEET_HEIGHT*SPRITESHEET_WIDTH*4);

		renderer.readRenderTargetPixels(this.fbos[this.currentExport].texture, 0, 0, SPRITESHEET_WIDTH,SPRITESHEET_HEIGHT, buf);
		renderer.setRenderTarget();
		this.encodeWorker.postMessage({ 
		  'quality' : Math.floor(SPRITESHEET_QUALITY*100),              // quality desired
		  'imageData' : buf.buffer,      // the imageData object
		  'width' : SPRITESHEET_WIDTH,    // the width of the image
		  'height' : SPRITESHEET_HEIGHT,   // the height of the image
		  'submitParams': {
				current_frame: this.currentExport,
				video_name: this.videoName,
				user_uuid: SETTINGS.uuid,
				bins: [].concat(this.params.bin),
				upload_source: "web",
				device_info: navigator.userAgent,
				user_agent: navigator.userAgent,
				num_frames: this.numFrames,
				duration: this.duration,
				video_speed: this.params.speed||1.0,
				numx: SPRITESHEET_X,
				numy: SPRITESHEET_Y,
				num_spritesheets: this.numSheets,
				resolution_width: SPRITESHEET_WIDTH,
				resolution_height: SPRITESHEET_HEIGHT,
				video_width: Math.floor(SPRITESHEET_WIDTH/SPRITESHEET_X),
				video_height:Math.floor(SPRITESHEET_HEIGHT/SPRITESHEET_Y),
				task_json: JSON.stringify(this.task_info),
				beta: true,
		   		betaId: SETTINGS.BETA_ID
		   },
		   bins: [].concat(this.params.bin),
		   SERVER_URL: SETTINGS.API_SERVER_URL,
		   recordId: this.recordId,
		   beta: true,
		   betaId: SETTINGS.BETA_ID,
		   numSheets: this.numSheets,
		   name: this.videoName,
		   facesInfo: this.facesInfo,
		   frameId: this.currentExport,
		   noUpload: this.params.noUpload || SETTINGS.NO_UPLOAD
		}, [buf.buffer]);


		this.currentExport++;
		if (this.currentExport < this.numSheets) {
			if (this.activeId !== this.exportId) this.encode();
			else {
				setTimeout(this.encode, 16);
			}
		} else {
			this.exporting = false;
			if (this.exportCallback) this.exportCallback(this.blobs);
			this.exportCallback = null;
		}

		// readPixelsTime = performance.now();
		// this.mozWorker.postMessage({ 
		//   'quality' : SPRITESHEET_QUALITY,              // quality desired
		//   'imageData' : buf.buffer,      // the imageData object
		//   'width' : SPRITESHEET_WIDTH,    // the width of the image
		//   'height' : SPRITESHEET_HEIGHT   // the height of the image
		// }, [buf.buffer]); 
		// console.log("PTTSend time:", performance.now()-readPixelsTime);

		// var self = this;
		// var blobTime = performance.now();;
		// renderer.domElement.toBlob(function(blob) {
		// 	console.log("Blob time:", performance.now()-blobTime);
		// 	self.blobs.push(blob);
			// self.currentExport++;
			// if (self.currentExport < self.numSheets) {
			// 	if (self.activeId !== self.exportId) self.encode();
			// 	else setTimeout(self.encode, 32);
			// } else {
			// 	self.exporting = false;
			// 	self.exportSpritesheet.exportDone(self.blobs);
			// 	self.exportSpritesheet = null;
			// 	if (self.exportCallback) self.exportCallback(self.blobs);
			// 	self.exportCallback = null;

			// 	self.submit(self.blobs);
			// 	self.blobs = [];
			// }
		// }, 'image/jpeg',  SPRITESHEET_QUALITY);
	}.bind(this);


	this.sendAnalysis = function() {
		// if (!this.hasAnalysis) return;
		// var buf = new Uint8Array(this.fastFbo.width*this.fastFbo.height*4);
		// renderer.readRenderTargetPixels(this.fastFbo.texture, 0,0,this.fastFbo.width,this.fastFbo.height, buf);
		// this.fastContext.putImageData(new ImageData(new Uint8ClampedArray(buf.buffer), this.fastCanvas.width, this.fastCanvas.height), 0, 0);
		// renderer.setRenderTarget();

		// var url = AnalysisController.ANALYSIS_URL[this.analysis];
		// var isBinary = this.analysisIsBinary;
		// var submit_params = {
		// 	'numX': this.analysisNumX,
		// 	'numY': this.analysisNumY,
		// 	"recordId": this.recordId,
		// 	"uuid": this.videoName,
		// 	"params": JSON.stringify(this.params)
		// };
		// AnalysisController.setLoading(this.videoName);
		// this.fastCanvas.toBlob((blob) => {
		// 	var formData = new FormData();
		// 	var file = new File([blob], "image");
		// 		formData.append('image', file);
		// 		for (o in submit_params) {
		// 			formData.append(o, submit_params[o]);
		// 		}
		// 	var xhr = new XMLHttpRequest();
		// 	console.log('SENDING ANALYSIS', SETTINGS.API_SERVER_URL+url, submit_params)
		// 	xhr.open('POST', SETTINGS.API_SERVER_URL+url, true);
		// 	xhr.onreadystatechange = function (e) {
		// 		if (e.target.readyState >= 4 && e.target.status <= 200) {

		// 			console.log(e.target.response);
		// 			// var returnInfo = isBinary ? window.BSON.deserialize(Buffer.Buffer.from(new Uint8Array(e.target.response))) : JSON.parse(e.target.response);
		// 			var returnInfo = isBinary ? window.BSON.deserialize(new Uint8Array(e.target.response)) : JSON.parse(e.target.response);
		// 			console.log('ANALYSIS RETURNED done', e.target, returnInfo);


		// 			if (returnInfo) {
		// 				AnalysisController.addAnalysis(returnInfo.uuid, returnInfo);
		// 			}
					
		// 			formData = blob = file = null;
		// 			xhr.onreadystatechange = null;
				
		// 		//
		// 		// Parse error
		// 		//
		// 		} else if (e.target.status >= 300) {
		// 			console.log(e.target);
		// 			if (!window.oneAlert) alert("Upload Error. Reloading.");
		// 			window.oneAlert = true;
		// 			window.location.reload();
		// 		}
		// 	}.bind(this);
		// 	if (this.analysisIsBinary) { xhr.overrideMimeType('application/octet-stream'); }
		// 	xhr.responseType = this.analysisIsBinary ? 'arraybuffer' : 'text';
		// 	xhr.send(formData);

		// }, 'image/jpeg',  0.66);
	};


	//------------------
	this.export = function(callback) {
		this.recording = false;
		this.exporting = true;
		this.exportId = this.activeId;
		this.currentExport = 0;
		this.currentReceive = 0;

		this.exportSpritesheet = SpritesheetVideo.create(this.spritesheetVideo.params); //new SpritesheetVideo();
		this.exportSpritesheet.setup(this.spritesheetVideo.params, [], "binary");
		this.exportSpritesheet.waitForExport();
		this.exportSpritesheet.isUser = true;
		this.exportSpritesheet.isLocal = true;
		this.exportSpritesheet.numReceived = 0;
		this.exportingSpritesheetsByName[this.videoName] = this.exportSpritesheet;
		this.exportSpritesheet.name = this.videoName;

		this.submitDoneByTask[this.recordId] = 0;
		UserVideoController.setTemporaryBins( this.params.bin, this.recordId );

		this.exportSpritesheet.isLocal = true;

		if (this.params.bin) UserVideoController.addSpritesheet(this.exportSpritesheet, [].concat(this.params.bin));
		if (this.params.id) UserVideoController.addSpritesheetForId(this.exportSpritesheet, this.params.id);
		this.exportCallback = callback;
		this.encode();
		this.facesInfo = null;
		return this.exportSpritesheet;
	}.bind(this);

	this.getTemporaryTextures = function() {
		// renderer.setSize(SPRITESHEET_WIDTH/2, SPRITESHEET_HEIGHT/2);
		// for (var i=0; i<this.numSheets; i++) {
		// 	renderer.setRenderTarget(null);
		// 	renderer.setScissorTest(false);
		// 	renderer.setViewport(0, 0, SPRITESHEET_WIDTH/2, SPRITESHEET_HEIGHT/2);
		// 	this.recordMaterial.uniforms.tDiffuse.value = this.fbos[i].texture.texture;
		// 	renderer.render(this.recordScene, this.camera, null, true);

		// 	this.temporaryTextures[i].needsUpdate = true;
		// 	window.renderer.setTexture2D(this.temporaryTextures[i], 0);
		// }	
		// renderer.setSize(SPRITESHEET_WIDTH, SPRITESHEET_HEIGHT);
		return this.temporaryTextures;
	}.bind(this);


	this.setTemporaryFaces = function(facesInfo) {
		this.facesInfo = facesInfo;
		this.spritesheetVideo.params.facesInfo = this.facesInfo;
		UserVideoController.facesByName[this.spritesheetVideo.name] = facesInfo;
		var tasksState = MultiStorage.getGlobalState();
		if (tasksState.userVideosByName && tasksState.userVideosByName[this.spritesheetVideo.name]) {
			tasksState.userVideosByName[this.spritesheetVideo.name].faces_info = this.facesInfo?JSON.stringify(this.facesInfo):undefined;
			MultiStorage.setGlobalState(tasksState);
		}
		// tasksState.facesInfoByName = tasksState.facesInfoByName||{};
		// tasksState.facesInfoByName[this.spritesheetVideo.name] = this.facesInfo;
		// MultiStorage.setGlobalState(tasksState);
	};

	//
	// this.getTemporarySpritesheet = function() {
	// 	this.spritesheetVideo.textures = this.getTemporaryTextures();
	// 	this.spritesheetVideo.prepare();
	// 	return this.spritesheetVideo;
	// };
	//
	this.getTemporarySpritesheet = function() {

		if (!SETTINGS.ANALYSIS_DEBUG && (!this.spritesheetVideo.params.effect || (this.spritesheetVideo.params.effect && !this.spritesheetVideo.params.effect.debug))){
			this.spritesheetVideo.textures = this.getTemporaryTextures();
			this.spritesheetVideo.prepare();
			this.spritesheetVideo.setLiveInfo(this.facesInfo);
			// if (this.facesInfo) this.spritesheetVideo.params.facesInfo = this.facesInfo;
			return this.spritesheetVideo;
		}

		var params =  Utils.clone(this.spritesheetVideo.params);
		params.effect = params.effect || ((params.analysis && SETTINGS.ANALYSIS_DEBUG) ? AnalysisController.ANALYSIS_DEBUG_DEFAULTS[params.analysis] : null);
		var sp = SpritesheetVideo.create(this.spritesheetVideo.params);
		sp.isUser = true;
		sp.isTemporary = true;
		sp.setup(params, this.getTemporaryTextures(), "texture");
		sp.prepare();
		sp.isUser = true;
		sp.isTemporary = true;
		return sp;
	};


	this.getPermanentSpritesheet = function() {

	};


	//wait for the export before allowing
	this.isReady = function() {
		return !this.exporting && this.workerReady;
	};


	//
	// Fbo pool temporary textures
	//
	// this.fbosPool = [];
	// this.getFbos = function() {
	// 	this.temporaryTextures = [];

	// 	if (this.fbosPool.length > 0) {

	// 		this.fbos = this.fbosPool.pop();

	// 	} else {
	// 		this.fbos = [];
	// 		for (var i=0; i<NUM_SP; i++) {
	// 			this.fbos.push(new Fbo(SPRITESHEET_WIDTH, SPRITESHEET_HEIGHT, {
	// 				minFilter:THREE.LinearFilter,
	// 				magFilter:THREE.LinearFilter,
	// 				format:THREE.RGBAFormat,
	// 				type:THREE.UnsignedByteType,
	// 				antialias: true,
	// 				depthBuffer: false,
	// 				stencilBuffer: false,
	// 				premultiplyAlpha: false,
	// 				generateMipmaps: false,
	// 				forceClear: false,
	// 				pingpong: false,
	// 				renderer: renderer
	// 			}));
	// 		}
	// 	}
	// 	for (var i=0; i<NUM_SP; i++) {
	// 		this.temporaryTextures.push(this.fbos[i].texture.texture);
	// 	}
	// };
	// this.releaseFbos = function(fbos) {
	// 	this.fbosPool.push(fbos);
	// };



};
window.FrameRecorder = window.FrameRecorder||new FrameRecorder();
export default window.FrameRecorder;

