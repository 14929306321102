import SETTINGS from '../controllers/Settings.js';
import * as THREE from 'three';

import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import RendererController from '../controllers/RendererController.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';

import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import SpritesheetVideo from './SpritesheetVideo.js';

// var CanvasTextWrapper = require('canvas-text-wrapper').CanvasTextWrapper;

//------------------------
class SpritesheetVideoZoom extends SpritesheetVideo {
	constructor(_info, batchName) {
		super(_info, batchName);

		
		this.faceCamera = new THREE.OrthographicCamera(-1,1,-1,1,-1,1);

	}

	preload(batchName) {
		super.preload(batchName);

	}
	
	prepare() {
		super.prepare();
		if (!this.effectFbo) this.effectFbo = SpritesheetVideo.getFbo(this.videoWidth, this.videoHeight);
		if (!this.effectMaterial) {
			this.effectMaterial = MaterialController.getMaterial('zoom');
			this.effectMaterial.uniforms.tDiffuse.value = this.fbo.texture.texture;
		} 

	}

	stop() {
		super.stop();
	}

	update(opt) {
		super.update(opt);

		this.zoomPc = 0.0;
		
		if (this.isReady()) {
			if (this.params.effect.zoomType == "in"||!this.params.effect.zoomType)  {
				this.zoomPc = Utils.ccmap(
					this.lastFrame,
					Math.max(Math.floor(this.numFrames)-this.params.effect.zoomTime*this.frameRate, 0),
					Math.floor(this.numFrames),
					0.0, 1.0
				);
			} else {
				this.zoomPc = Utils.ccmap(
					this.lastFrame,
					0,
					Math.min(Math.floor(this.numFrames), this.params.effect.zoomTime*this.frameRate ),
					1.0, 0.0);
			}

			this.effectMaterial.uniforms.zoomPc.value = Utils.map(this.zoomPc,0.0,1.0, 1.0, this.params.effect.zoomScale||0.5);
			this.effectMaterial.uniforms.noiseScale.value.set(1,16/9).multiplyScalar(10 * this.videoWidth/1024).multiplyScalar(0.98 + Math.random()*0.02)
			this.effectMaterial.uniforms.noiseOffset.value.set(Math.random()*10, Math.random()*10);
			this.effectMaterial.uniforms.noisePc.value = this.params.effect.noise==undefined?0.0:(this.params.effect.noise||0) * this.zoomPc;
			this.effectMaterial.uniforms.tNoise.value = SpritesheetVideo.noiseTexture;

			var randomRot = Math.random()*Math.PI*2.0;
			this.effectMaterial.uniforms.noiseRotation.value.elements.set([Math.cos(randomRot),-Math.sin(randomRot),Math.sin(randomRot),Math.cos(randomRot)]);

		}
	}

	dispose() {
		super.dispose();

		if (this.effectFbo) {
			SpritesheetVideo.disposeFbo(this.effectFbo);
			this.effectFbo = null;
		}
		if (this.effectMaterial) {
			this.effectMaterial.dispose();
			this.effectMaterial = null;
		}

	}

	render() {
		super.render();
		if (this.effectMaterial) Utils.renderMaterial(this.effectMaterial, this.effectFbo.texture, true);
		if (!this.noFaces && this.faceRenderer) {
			var off = (1.0-this.effectMaterial.uniforms.zoomPc.value)*0.5;
			this.faceCamera.left = off;
			this.faceCamera.right = 1.0-off;
			this.faceCamera.top = off;
			this.faceCamera.bottom = 1.0-off;
			this.faceCamera.updateProjectionMatrix();
			renderer.render(this.faceRenderer.faceScene, this.faceCamera, this.effectFbo.texture, false);
		}
	}


	getTexture() {
		if (!this.effectFbo || !this.fbo || !this.isReady()) return Utils.blackTexture;
		return this.effectFbo.texture.texture;
	}
};

export default SpritesheetVideoZoom;


