
import SETTINGS from './Settings.js';
import AppStatus from './AppStatus.js';
import PageLoader from '../loading/PageLoader.js'

function SoundController() {

	this.enabled = false;

	this.soundsPlayingById = {};
	this.soundsTargets = [];

	this.audioUnlocked = false;
	this.eventAdded = false;

	this.numStarted = 0;
	this.numActive = 0;

	this.starting = false;
	this.audioStarted = false;
	this.shouldStop = false;
	this.sound = null;
	this.audioBuffer = null;


	//visiblity stop
	this.visibilityAdded = false;
	this.visibilityStopped = false;

	this.enableNextOnNextTap = function() {
		if (this.audioUnlocked || this.eventAdded || SETTINGS.STORYBOARD_MODE) return;
		this.eventAdded = true;
		console.log("unlocking sound event");
		window.audioContext = window.audioContext||new AudioContext();
		$(document.body).one('touchstart.unlocksound', function(e) {
			if (this.audioUnlocked) return;
		console.log("UNLOCKING TAP");
			// $(document.body).off('touchstart.unlocksound');
			console.log("Unlocking sound");
			var forceSR = window.audioContext.createBufferSource()
			forceSR.buffer = window.audioContext.createBuffer(1, 1, 44100);
			forceSR.connect(window.audioContext.destination);
			forceSR.start(window.audioContext.currentTime);
			forceSR.loop = false;
			this.audioUnlocked = true;
		}.bind(this));
	};

	this.unlockAudioDirectly = function() {
		console.log("UNLOCKING DIRECT", this, window.audioContext);
		if (this.audioUnlocked) return;
		window.audioContext = window.audioContext||new AudioContext();
		var forceSR = window.audioContext.createBufferSource()
		forceSR.buffer = window.audioContext.createBuffer(1, 1, 44100);
		forceSR.connect(window.audioContext.destination);
		forceSR.start(window.audioContext.currentTime);
		forceSR.loop = false;
		this.audioUnlocked = true;
	}.bind(this);

	this.preload = function(batchName, path) {
		window.audioContext = window.audioContext||new AudioContext();
		PageLoader.addXHR(batchName, path, 'arraybuffer');
	};


	//decode and return a decoded object
	this.decode = function(ab) {
		if (!window.audioContext) return;
		var decoderObj = {decoded: false, buffer:null};
		window.audioContext.decodeAudioData(ab,
			function(buffer) {
			    decoderObj.decoded = true;
			    decoderObj.buffer = buffer;
			},
			function(er) {
				console.log(er);
			}
		);
		return decoderObj;
	};

	this.play = function() {
		this.audioStarted = true;
		this.starting = false;

		var snd = window.audioContext.createBufferSource();
		snd.buffer = this.audioBuffer;
		snd.loop = true;

		var gain = window.audioContext.createGain();
		gain.gain.value = 0.0;
		gain.gain.setValueAtTime(0.0,window.audioContext.currentTime);
		
		var timeStartMin = window.audioContext.currentTime+2.0;
		gain.gain.linearRampToValueAtTime(0.4, timeStartMin);
		gain.connect( window.audioContext.destination );
		snd.connect( gain );

		snd.start(window.audioContext.currentTime);

		this.sound = {snd:snd, gain:gain, timeStartMin:timeStartMin};

		if (!this.visibilityAdded) {
			this.visibilityAdded = true;
			document.addEventListener("visibilitychange", this.handleVisibilityChange, false);
			if (document.hidden) this.handleVisibilityChange();
		}
	};

	this.handleVisibilityChange = function(e) {
		if (document.hidden && this.audioStarted) {
		  	this.visibilityStopped = true;
		  	this.stopSound();
		} else if (!document.hidden && this.visibilityStopped) {
			this.visibilityStopped = false;
			this.play();
		}
	}.bind(this);

	this.stopSound = function() {
		this.starting = false;
		this.audioStarted = false;
		if (!this.sound) return;
		
		var minTime = Math.max(this.sound.timeStartMin, window.audioContext.currentTime);
		this.sound.gain.gain.setValueAtTime(0.5, minTime);
		this.sound.gain.gain.linearRampToValueAtTime(0.0, minTime+1.33);
		this.sound.snd.stop(minTime+1.33);
		this.sound = null;
	};

	this.activate = function(audioBinary) {
		this.numActive++;

		//start audio when loaded
		if (this.numActive>0 && audioBinary && !this.audioStarted && !this.starting) {
			this.starting = true;
			this.audioBinary = audioBinary;
			if (!this.audioBinary.loaded) audioBinary.doneCallbacks.push(this.startPartTwo); else this.startPartTwo();
		}
	};

	this.startPartTwo = function() {
		//decode
		if (!this.audioBuffer && !this.audioStarted && this.numActive>0) {
			// this.audioBuffer = this.decode(this.audioBinary.value).then(this.startPartThree);
			window.audioContext.decodeAudioData(this.audioBinary.value,
				function(buffer) {
				    this.audioBuffer = buffer;
				    this.startPartThree();
				}.bind(this),
				function(er) {
					console.log(er);
				}
			);

		} else if (this.audioBuffer) {this.startPartThree();} else {this.starting = false;}
	}.bind(this);

	this.startPartThree = function() {
	
		if (this.audioBuffer && !this.audioStarted && this.numActive>0) {this.play();}  else {this.starting = false;}
	
	}.bind(this);


	// this.update = function(audioBinary) {}
	this.deactivate = function() {
		this.numActive--;
		if (this.numStarted<=3 && this.numActive<=0 && this.audioStarted) {
			this.stopSound()
		}
	};
	this.start = function() {
		this.enableNextOnNextTap();
		this.numStarted++;
	};
	this.stop = function() {
		this.numStarted--;
		if (this.numStarted<=2 && this.numActive<=0 && this.audioStarted) {
			this.stopSound();
		}
	};



	// this.play = function(id) {
	// 	var found = false;
	// 	for (var i=0; i<this.soundsTargets.length; i++) {
	// 		if (this.soundsTargets[i].id == id) {
	// 			found = true;
	// 			this.soundsTargets[i].num++;
	// 		}
	// 	}
	// 	if (!found) this.soundsTargets.push({id:id, num:1});
	// 	// this.soundsTargetById[id] = this.soundsTargetById[id]||0;
	// 	// this.soundsTargetById[id]++;
	// };
	// this.stop = function(id) {
	// 	for (var i=0; i<this.soundsTargets.length; i++) {
	// 		if (this.soundsTargets[i].id == id) {
	// 			this.soundsTargets[i].num--;
	// 			// if (this.soundsTargets[i].num <= 0) {
	// 			// this.soundsTargets[i].splice(i,1);
	// 			// i--;
	// 			// }
	// 		}
	// 	}
	// };
	// this.updateAfter = function(id) {
	// 	if (!this.audioUnlocked) return;

	// 	for (var i=0; i<this.soundsTargets.length; i++) {
	// 		if (this.soundsTargets[i].num>0 && !this.soundsPlayingById[this.soundsTargets[i].id]) {
	// 			//play
	// 			this.soundsPlayingById[this.soundsTargets[i].id] = true;

			
	// 		} else if (this.soundsTargets[i].num<= 0 && this.soundsPlayingById[this.soundsTargets[i].id]) {
	// 			//stop
				
	// 			//dispose
	// 			this.soundsPlayingById[this.soundsTargets[i].id] = null
	// 			this.soundsTargets.splice(i,1);
	// 			i--;
	// 		}
	// 	}
	// };
};

window.SoundController = window.SoundController || new SoundController();
export default window.SoundController;

