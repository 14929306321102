import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';
import Fbo from '../utils/Fbo.js'
import RendererController from '../controllers/RendererController.js';
import MultiStorage from '../utils/MultiStorage.js';

import MontageController from '../controllers/MontageController.js';
import Montage from '../objects/Montage.js';
import SpritesheetVideo from '../objects/SpritesheetVideo.js';
import MaterialController from '../controllers/MaterialController.js';;


import MottoView from './MottoView.js';

var BLEND_MODES = {
	"normal": 0,
	"screen": 1,
	"multiply": 2,
	"add": 3,
	"difference": 4
};

//------------------------
// 
//  Base class for all views
//
//-------------------------
class MontageBlendView extends MottoView {

	constructor(_info, batchName) {

		super(_info, batchName);

		this.textType = this.info["textType"] || 'top';
		this.uuid = Utils.generateUUID();
		// this.textBackground = (this.info.textBackground || (this.info.textBackground==undefined && this.info.textBackgroundColor)) ? (this.info.textBackgroundColor || ( this.info.dark ? '#000000' : '#ffffff')) : '#ffffff';
		this.textBackground = this.info.textBackground;
		this.pageNumberWhite = true;
		if (!this.info.textBackground) {
			this.textBackground = this.info.dark ? '#000000' : '#ffffff';
		}
		
		//
		// Add image to preloading queue
		//
		this.loaded = false;
		this.videoWidth = 288;
		this.videoHeight = 512;
		this.currentTime = 0;
		this.currentSpritesheet = 0;

		// Create all necessary spritesheets
		this.montage = new Montage(this.params.montageA, batchName); //MontageController.create(this.params);
		this.montageBlend = new Montage(this.params.montageB, batchName); //MontageController.create(this.params);
		this.hasLivefeed = this.hasLivefeed||this.montage.hasLivefeed||this.montageBlend.hasLivefeed;
		this.livefeedDirection = this.montage.livefeedDirection || this.montageBlend.livefeedDirection;
		if (this.montage.hasMotionDelay||this.montageBlend.hasMotionDelay) this.pageNumberWhite = false;

	}


	//
	// When starting - decode image for rendering
	//
	start() {
		if (this.started) return;
		super.start();

		this.scene = new THREE.Scene();
		this.playbackMaterial = MaterialController.getMaterial('montage-blend', {
			BLEND_MODE: BLEND_MODES[this.params.blendMode]||0,
			TRACKING_ENABLED: this.info.type=='montage-tracking' ? 1 : 0
		});
		this.plane = new THREE.Mesh(Utils.planeGeometry, this.playbackMaterial);
		this.playbackMaterial.uniforms.tDiffuse.value = Utils.blackTexture;
		this.playbackMaterial.uniforms.tBlend.value = Utils.blackTexture;
		this.scene.add(this.plane);
		this.camera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, -0.5, 0.5);

		//prepare spritesheets
		this.montage.replaceUserVideos();
		this.montage.prepare();

		this.montageBlend.replaceUserVideos();
		this.montageBlend.prepare();
	}

	activate() {
		if (this.active) return;
		super.activate();

		//prepare spritesheets
		this.montage.replaceUserVideos();
		this.montage.prepare();
		this.montage.activate();

		this.montageBlend.replaceUserVideos();
		this.montageBlend.prepare();
		this.montageBlend.activate();
	}

	//
	// When disposing - release image
	//
	stop() {
		if (!this.started) return;
		super.stop();
		this.playbackMaterial.dispose();
		this.scene.remove(this.plane);
		this.plane = this.scene = this.camera = null;
		this.montage.dispose();
		this.montageBlend.dispose();
	}
	
	update() {
		if (!this.started) return;
		super.update();
		this.montage.contentDiv = this.contentDiv;
		this.montageBlend.contentDiv = this.contentDiv;

		//Utils.autoReloadShaderManual(this.playbackMaterial, 'shaders/tmp.vert', 'shaders/tmp.frag');



		//prepare spritesheets
		if (this.positionX > -0.001 || AppStatus.goingBackwards) {
			if (!this.active) {
				this.montage.replaceUserVideos();
				this.montageBlend.replaceUserVideos();
			}
			this.montage.prepare();
			this.montageBlend.prepare();
		}
		this.ready = this.active && this.montage.isReady() && this.montageBlend.isReady();

		//
		// Playback
		//
		if (this.active) {
			var shortest = this.montage.duration <= this.montageBlend.duration ? this.montage : this.montageBlend;
			var longest = this.montage.duration <= this.montageBlend.duration ? this.montageBlend : this.montage;

			if (!this.params.sync) {
				this.montage.update();
				this.montageBlend.update();
			} else if (this.params.sync === 'loop') {
				var loop = longest.update();
				shortest.update({sync:this.params.sync, currentTime:longest.currentTime, looped:loop});
			// } else if (this.params.sync === 'palindrome') {
			// 	longest.update();
			// 	shortest.update({sync:this.params.sync, currentTime:longest.currentTime});
			} else if (this.params.sync) {
				var loop = shortest.update();
				longest.update({sync:this.params.sync, currentTime:shortest.currentTime, looped:loop});
			}

			AppStatus.frameNeedsUpdate = AppStatus.frameNeedsUpdate||this.montage.needsUpdate||this.montageBlend.needsUpdate;

		}
		// if (this.active) this.montage.update();
		// if (this.active) this.montageBlend.update();
		if (this.ready) {

			this.playbackMaterial.uniforms.tDiffuse.value = this.montage.getTexture();
			this.playbackMaterial.uniforms.tBlend.value = this.montageBlend.getTexture();
			this.playbackMaterial.uniforms.blendAlpha.value = this.params.blendAlpha;

			if (this.info.type=='montage-tracking') this.playbackMaterial.uniforms.blendScale.value = 1.0 / (this.params.scale||this.params.montageB.scale||1.0);
			if (this.info.type=='montage-tracking' && this.montage.trackingEnabled) {
				// this.montageBlend
				this.playbackMaterial.uniforms.blendCenter.value.x = this.montage.trackingCenter.x-0.5;
				this.playbackMaterial.uniforms.blendCenter.value.y = 0.5-this.montage.trackingCenter.y;

			} else {
				this.playbackMaterial.uniforms.blendCenter.value.set(0.5,0.5);
			}
			// console.log(this.playbackMaterial.uniforms.blendCenter.value);
			if (this.montage.shouldFindTracking || this.params.shouldFindTracking) {
				if (this.montage.trackingFound) {
					this.playbackMaterial.uniforms.blendAlpha.value = this.params.blendAlpha;
				} else {
					this.playbackMaterial.uniforms.blendAlpha.value = 0.0;
				}
			}



			//update ratio
			var ratio = this.montage.getRatio();

			if (this.params.fill === 'center') {
		
				this.plane.scale.set(1, ratio * 1 * (this.renderWidth() / this.renderHeight()), 1.0);
				this.plane.scale.multiplyScalar(1.0 - (this.params.padding==undefined?0.1:this.params.padding));
				this.plane.material.uniforms.ratio.value.set(1,1);
				
			} else if (this.params.fill === 'square') {

				if (ratio > 1.0) {
					this.plane.material.uniforms.ratio.value.set(1, this.videoWidth/this.videoHeight);
				} else {
					this.plane.material.uniforms.ratio.value.set(this.videoHeight/this.videoWidth, 1);
				}
				if (this.renderWidth() > this.renderHeight()) {
					this.plane.scale.set(1 / (this.renderWidth() / this.renderHeight()), 1, 1);
				} else {
					this.plane.scale.set(1, 1 / (this.renderHeight()/this.renderWidth()), 1);
				}
				this.plane.scale.multiplyScalar(1.0 - (this.params.padding==undefined?0.1:this.params.padding));

			} else {

				this.plane.material.uniforms.ratio.value.set(1,1);
				if ( (1/ratio) > this.renderWidth() / this.renderHeight()) {
					this.plane.scale.set((1/ratio) / (this.renderWidth() / this.renderHeight()), 1, 1);
				} else {
					this.plane.scale.set(1, (ratio) / (this.renderHeight()/this.renderWidth()), 1);
				}
			}
		}
	}

	//
	// Render image to webgl??
	//
	render() {
		if (!this.active) return;
		super.render();

		if (this.montage.isReady() && this.montageBlend.isReady()) {
			this.montage.render();
			this.montageBlend.render();
			renderer.render(this.scene, this.camera, this.renderTarget, false);
		}

	}


	//outside access
	isReady() {
		return super.isReady() && this.ready;
	}
}

export default MontageBlendView;	

