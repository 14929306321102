import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';
import Fbo from '../utils/Fbo.js'
import RendererController from '../controllers/RendererController.js';
import MultiStorage from '../utils/MultiStorage.js';

import MottoView from './MottoView.js';
import RecordView from './RecordView.js'

//------------------------


class LiveFeedView extends RecordView {

	constructor(_info, batchName) {
		super(_info, batchName);
		this.isLiveFeed = true;
		this.hasLivefeed = true;
		this.lockRight = false;
	}
}

/*
//------------------------
// 
//  Base class for content view
//  Handles webgl texture creating & disposing & fill options
//
//-------------------------
class LiveFeedView extends MottoView {

	constructor(_info, batchName) {
		super(_info, batchName);
		this.textType = this.info["textType"] || 'top';

		this.fillType = this.params.fill;
		this.textBackground = this.info.textBackground;
		if (!this.info.textBackground) {
			this.textBackground = this.info.dark ? '#000000' : '#ffffff';
		}

		
		MaterialController.addMaterial('playback', new THREE.RawShaderMaterial({
			vertexShader:`
				precision mediump float;
				precision mediump int;

				uniform mediump mat4 modelViewMatrix; // optional
				uniform mediump mat4 projectionMatrix; // optional

				attribute mediump vec3 position;
				attribute mediump vec2 uv;

				varying mediump vec2 vUv;

				uniform mediump vec2 ratio;

				void main() {
					vUv = (vec2(uv.x, uv.y)-0.5) * ratio + 0.5;
					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}
			`,
			fragmentShader:`
				precision mediump float;
				precision mediump int;

				varying mediump vec2 vUv;
				uniform mediump sampler2D tDiffuse;

				void main() {
					gl_FragColor = vec4(texture2D(tDiffuse, vUv).rgb, 1.0);
				}
			`,
			uniforms: {
				tDiffuse: {type:'t', value: Utils.blackTexture},
				ratio: {type:'v2', value: new THREE.Vector2(1, 1)}
			},
			transparent: false,
			blending: THREE.NoBlending,
			side: THREE.DoubleSide,
			depthTest: false,
			depthWrite: false
		}));

		MaterialController.addMaterial('playback_mirror', new THREE.RawShaderMaterial({
			vertexShader:`
				precision mediump float;
				precision mediump int;

				uniform mediump mat4 modelViewMatrix; // optional
				uniform mediump mat4 projectionMatrix; // optional

				attribute mediump vec3 position;
				attribute mediump vec2 uv;

				varying mediump vec2 vUv;

				uniform mediump vec2 ratio;

				void main() {
					vUv = (uv - 0.5) * ratio + 0.5;
					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}
			`,
			fragmentShader:`
				precision mediump float;
				precision mediump int;

				varying mediump vec2 vUv;
				uniform mediump sampler2D tDiffuse;

				void main() {
					gl_FragColor = vec4(texture2D(tDiffuse, vec2(abs(0.5-vUv.x)+0.5, vUv.y)).rgb, 1.0);
				}
			`,
			uniforms: {
				tDiffuse: {type:'t', value: Utils.blackTexture},
				ratio: {type:'v2', value: new THREE.Vector2(1, 1)}
			},
			transparent: false,
			blending: THREE.NoBlending,
			side: THREE.DoubleSide,
			depthTest: false,
			depthWrite: false
		}));

		MaterialController.addMaterial('playback_motion_delay', new THREE.RawShaderMaterial({
				defines: {
					"INVERT_COLORS": 0
				},
				vertexShader:`
					precision mediump float;
					precision mediump int;

					uniform mediump mat4 modelViewMatrix; // optional
					uniform mediump mat4 projectionMatrix; // optional

					attribute mediump vec3 position;
					attribute mediump vec2 uv;

					varying mediump vec2 vUv;

					uniform mediump vec2 ratio;

					// varying mediump vec2 noiseUv;
					// uniform mediump vec2 noiseScale;
					// uniform mediump vec2 noiseOffset;
					// uniform mediump mat2 noiseRotation;

					void main() {
						vUv = (vec2(uv.x, uv.y)-0.5) * ratio + 0.5;
						gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
					}
				`,
				fragmentShader:`
					precision mediump float;
					precision mediump int;

					varying mediump vec2 vUv;
					uniform mediump sampler2D tDiffuse;
					uniform mediump sampler2D tDiffuse2;
					uniform mediump sampler2D tDiffuse3;


					// varying mediump vec2 noiseUv;
					// uniform mediump sampler2D tNoise;
					// uniform lowp float noisepc;

					uniform lowp vec3 colorA;
					uniform lowp vec3 colorB;
					uniform lowp vec3 colorC;


					void main() {
						mediump vec3 colA = texture2D(tDiffuse, vUv).rgb;
						mediump vec3 colB = texture2D(tDiffuse2, vUv).rgb;
						mediump vec3 colC = texture2D(tDiffuse3, vUv).rgb;

						float diffA = min(distance(colA, colB) * 1.0, 1.0);
						float diffB = min(distance(colA, colC) * 1.0, 1.0);
						float diffC = min(distance(colB, colC) * 1.0, 1.0);

						#if INVERT_COLORS == 1
							vec3 col = 1.0 - (colorA * diffA + colorB * diffB + colorC * diffC);
						#else
							vec3 col = colorA * diffA + colorB * diffB + colorC * diffC;
						#endif

						// mediump float noise = (texture2D(tNoise, noiseUv).r*2.0-1.0) * 0.11 * noisepc;
						gl_FragColor = vec4(col, 1.0);
					}
				`,
				uniforms: {
					ratio: {type:'v2', value: new THREE.Vector2(1,1)},

					tDiffuse: {type:'t', value: Utils.whiteTexture},
					tDiffuse2: {type:'t', value: Utils.whiteTexture},
					tDiffuse3: {type:'t', value: Utils.whiteTexture},

					colorA: {type:'c', value: new THREE.Color(0xffffff)},
					colorB: {type:'c', value: new THREE.Color(0xffffff)},
					colorC: {type:'c', value: new THREE.Color(0xffffff)},

					tNoise: {type:'t', value: Utils.whiteTexture},
					noisepc: {type:'f', value:0.0},
					noiseOffset: {type:'v2', value: new THREE.Vector2(0,0)},
					noiseScale: {type:'v2', value: new THREE.Vector2(1, 1)},
					noiseRotation: {type:'m2', value: {elements: new Float32Array(4)}}
				},
				transparent: false,
				depthTest: false,
				depthWrite: false,
				blending: THREE.NoBlending,
				side: THREE.DoubleSide
			}));


		if (this.params.reference) {
			this.referenceTexture = Loader.addTexture(batchName, SETTINGS.UI_IMAGES_URL+this.params.reference, {
				format: THREE.RGBAFormat,
				wrapping: THREE.ClampToEdgeWrapping,
				generateMipmaps: false,
				minFilter: THREE.LinearFilter
			});
		}
		
		if (this.params.referenceMontage) {
			this.referenceMontage = new Montage(this.params.referenceMontage, batchName); //MontageController.create(this.params);
		}

		if (this.params.reference || this.params.referenceMontage) {
			MaterialController.addMaterial('playback_reference', new THREE.RawShaderMaterial({
				defines: {
					"BLEND_MODE": 0
				},
				vertexShader:`
					precision mediump float;
					precision mediump int;

					uniform mediump mat4 modelViewMatrix; // optional
					uniform mediump mat4 projectionMatrix; // optional

					attribute mediump vec3 position;
					attribute mediump vec2 uv;

					varying mediump vec2 vUv;
					varying mediump vec2 referenceUv;

					uniform mediump vec2 ratio;
					uniform mediump vec2 referenceRatio;
					uniform mediump vec2 referenceOffset;

					void main() {
						vUv = (vec2(uv.x, uv.y)-0.5) * ratio + 0.5;
						referenceUv = (vec2(uv.x, uv.y)-0.5-referenceOffset) * referenceRatio + 0.5 + referenceOffset;
						gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
					}
				`,
				fragmentShader:`
					precision mediump float;
					precision mediump int;

					varying mediump vec2 vUv;
					varying mediump vec2 referenceUv;
					uniform mediump sampler2D tDiffuse;
					uniform mediump sampler2D tReference;

					#define BlendScreenf(base, blend) 		(1.0 - ((1.0 - base) * (1.0 - blend)))
					#define Blend(base, blend, funcf) 		vec3(funcf(base.r, blend.r), funcf(base.g, blend.g), funcf(base.b, blend.b))
					#define BlendScreen(base, blend) 		Blend(base, blend, BlendScreenf)

					uniform mediump float referenceAlpha;

					void main() {
						vec3 colA = texture2D(tDiffuse, vUv).rgb;
						vec4 colB = texture2D(tReference, referenceUv);

						//normal
						#if BLEND_MODE == 0
							vec3 col = mix(colA, colB.rgb, colB.a*referenceAlpha);

						//add
						#elif BLEND_MODE == 1
							vec3 col = colA + colB.rgb * colB.a*referenceAlpha;

						//screen
						#elif BLEND_MODE == 2
							vec3 colC = colB.rgb;
							vec3 col = mix(colA, BlendScreen(colA, colC), colB.a*referenceAlpha);

						//difference
						#elif BLEND_MODE == 3
							vec3 col = mix(colA, abs(colA - colB.rgb), colB.a*referenceAlpha);

						//multiply
						#elif BLEND_MODE == 4
							vec3 col = mix(colA, (colA * colB.rgb), colB.a*referenceAlpha);
						#endif

						gl_FragColor = vec4(col, 1.0);
					}
				`,
				uniforms: {
					tDiffuse: {type:'t', value: Utils.blackTexture},
					ratio: {type:'v2', value: new THREE.Vector2(1, 1)},
					tReference: {type:'t', value: Utils.blackTexture},
					referenceRatio: {type:'v2', value: new THREE.Vector2(1, 1)},
					referenceOffset: {type:'v2', value: new THREE.Vector2(0, 0)},
					referenceAlpha: {type:'f', value: 1.0}
				},
				transparent: false,
				blending: THREE.NoBlending,
				side: THREE.DoubleSide,
				depthTest: false,
				depthWrite: false
			}));
		}

		if (this.params.effect && this.params.effect.type == "motionDelay") {
			this.fbos = [];
			for (var i=0; i<24; i++) {
				this.fbos.push(new Fbo(288, 512, {
					minFilter:THREE.LinearFilter,
					magFilter:THREE.LinearFilter,
					format:THREE.RGBAFormat,
					type:THREE.UnsignedByteType,
					antialias: true,
					depthBuffer: false,
					stencilBuffer: false,
					premultiplyAlpha: false,
					generateMipmaps: false,
					forceClear: false,
					pingpong: false,
					renderer: renderer
				}));
			}
		}
	}


	activate() {
		super.activate();
		CameraController.start(this.params.camera || "back");
		this.lastTime = 0;
		this.maxActive = -1;
		this.animationStarted = false;
		this.frameOffset2 = 0;
		this.frameOffset3 = 0;
		this.animationStartTime = 0.0;
		this.animationPc = 0.0;
		this.currentMotionTime = 0.0;

		if (this.referenceMontage) {
			this.referenceMontage.contentDiv = this.contentDiv;
			this.referenceMontage.replaceUserVideos();
			this.referenceMontage.prepare();
			this.referenceMontage.activate();
		}

		if (this.info.params.faces) FaceTracker.preload();
	}

	deactivate() {
		super.deactivate();
		CameraController.stop(this.params.camera || "back");
		this.lastTime = 0;
		this.maxActive = -1;
		if (this.fbos) {
			for (var i=0; i<this.fbos.length; i++) {
				this.fbos[i].dispose();
				this.fbos[i].active = false;
			}
		}
		
	}

	start() {
		if (this.started) return;
		super.start();
		
		this.playbackMaterial = null;



		if (this.params.effect && this.params.effect.type == "mirror") {

			this.playbackMaterial = MaterialController.getMaterial('playback_mirror');

		} else if (this.params.effect && this.params.effect.type == "motionDelay") {
			this.playbackMaterial = MaterialController.getMaterial('playback_motion_delay', {
				"INVERT_COLORS": this.params.effect&&this.params.effect.invert ? 1 : 0
			});
			if (this.params.effect.colors) {
				this.playbackMaterial.uniforms.colorA.value.set(this.params.effect.colors[0]);
				this.playbackMaterial.uniforms.colorB.value.set(this.params.effect.colors[1]);
				this.playbackMaterial.uniforms.colorC.value.set(this.params.effect.colors[2]);
			} else {
				this.playbackMaterial.uniforms.colorA.value.set(0xff00ff);
				this.playbackMaterial.uniforms.colorB.value.set(0x00ffff);
				this.playbackMaterial.uniforms.colorC.value.set(0xffff00);
			}
		} else if (this.params.reference || this.params.referenceMontage) {

			this.playbackMaterial = (this.params.reference || this.params.referenceMontage) ? MaterialController.getMaterial('playback_reference', {
						"BLEND_MODE": (
							this.params.referenceBlend=='multiply' ? 4 :
							this.params.referenceBlend=='difference' ? 3 :
							this.params.referenceBlend=='screen' ? 2 :
							this.params.referenceBlend=='add' ? 1 : 0)
			}) : MaterialController.getMaterial('playback');
			// this.plane = new THREE.Mesh(Utils.planeGeometry, this.playbackMaterial);
			// this.playbackMaterial.uniforms.ratio.value.set(1,1);
			// this.plane.scale.set(1,1,1);
			// this.scene.add(this.plane);
			// this.camera = new THREE.OrthographicCamera( -0.5, 0.5, 0.5, -0.5, -0.5, 0.5 );

			if (this.referenceMontage) {
				this.referenceMontage.contentDiv = this.contentDiv;
				this.referenceMontage.replaceUserVideos();
				this.referenceMontage.prepare();
			}


		} else {
			this.playbackMaterial = MaterialController.getMaterial('playback');
		}

		
		this.scene = new THREE.Scene();
		this.plane = new THREE.Mesh(Utils.planeGeometry, this.playbackMaterial);
		this.plane.material.uniforms.tDiffuse.value = this.texture;
		this.plane.material.uniforms.ratio.value.set(1,1);
		// this.plane.scale.set(0.5,0.5,0.5);
		this.scene.add(this.plane);
		this.camera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, -0.5, 0.5);
		this.lastTime = 0;
		this.maxActive = -1;
		this.animationStarted = false;
		this.frameOffset2 = 0;
		this.frameOffset3 = 0;
		this.animationStartTime = 0.0;
		this.animationPc = 0.0;
		this.currentMotionTime = 0.0;


		if (this.params.tracking) {
			OpticalFlowController.activate();
			this.trackedScene = new THREE.Scene();
			this.trackedPlane = new THREE.Mesh(Utils.planeGeometryCorner, new THREE.MeshBasicMaterial({color:0xff000, side:THREE.DoubleSide, depthTest: false, depthWrite: false}))
			this.trackedPlane.scale.set(0.05,0.05);
			this.trackedScene.add(this.trackedPlane);
		}


	}

	stop() {
		if (!this.started) return;
		super.stop();

		this.scene = null;
		this.plane.material.dispose();
		this.plane = null;
		this.camera = null;
		this.lastTime = 0;
		if (this.trackingDiv) {
			$(this.trackingDiv.dc).remove();
			$(this.trackingDiv.d).remove();
			this.trackingDiv = null;
		}

		if (this.referenceMontage) this.referenceMontage.dispose();
		if (this.referenceTexture) this.referenceTexture.dispose();
	}

	update() {
		if (!this.started) return;
		super.update();

		if (this.referenceMontage) {
			this.referenceMontage.contentDiv = this.contentDiv;
			if (this.positionX > -0.001 || AppStatus.goingBackwards) {
				if (!this.active) {
					this.referenceMontage.replaceUserVideos();
				}
				this.referenceMontage.prepare();
			}
		}

		//
		// Update Texture
		//
		if (CameraController.isReady() && this.active) {



			if (this.params.tracking) {
				OpticalFlowController.update();
				this.trackedPlane.position.x = OpticalFlowController.getCenterPoint().x;
				this.trackedPlane.position.y = OpticalFlowController.getCenterPoint().y;


				if (!this.trackingDiv && this.contentDiv) {
					var dc = document.createElement('div');
					$(dc).addClass('spritesheet-overlay-text-container');
					// $(dc).css('background', 'none');
					$(this.contentDiv).append(dc);

					var d = document.createElement('div');
					$(d).addClass('spritesheet-overlay-text');
					// $(d).addClass('top');
					// $(d).css('color', 'white');
					$(dc).append(d);
					// $(d).css('background-color', '#ffffff');

					this.trackingDiv = {
						container: dc,
						div:d,
						visible: false
					};
					$(d).html(this.params.tracking.text||'');
				}
				
				if (this.trackingDiv) {
					var w = 50; //(p.scale.x*renderWidth);
					var h = 1;//(Math.abs(p.scale.y)*renderHeight);

					var fontSize =  Utils.clamp((w / 10), '0.75', '1.4') * this.params.tracking.scale;
					$(this.trackingDiv.div).css('font-size', fontSize +'em');
					w = Math.max(w, $(this.trackingDiv.container).width()) - 0.5;
					h = Math.max(h, $(this.trackingDiv.div).height());


					$(this.trackingDiv.container).css({
						'transform':' translate('+((OpticalFlowController.getCenterPoint().x)*this.renderWidth() - w/2)+'px,'+((OpticalFlowController.getCenterPoint().y)*this.renderHeight()-h/2)+'px)',
						// 'width': w+'px',
						'height': h+'px'
					});
					// console.log('translate('+((OpticalFlowController.getCenterPoint().x)*this.renderWidth())+'px,'+((OpticalFlowController.getCenterPoint().y)*this.renderHeight())+'px)');

					if (!this.trackingDiv.visible) {
						$(this.trackingDiv.container).css('display', 'table');
						this.trackingDiv.visible = true;
					}
				}
			}

			if (this.info.params.faces) FaceTracker.track();

			this.videoWidth = CameraController.getVideo().videoWidth;
			this.videoHeight = CameraController.getVideo().videoHeight;
			if (this.videoWidth/this.videoHeight > this.renderWidth()/this.renderHeight()) { //wider
				this.playbackMaterial.uniforms.ratio.value.set((this.renderWidth()/this.renderHeight())/(this.videoWidth/this.videoHeight), 1);
			} else { //taller
				this.playbackMaterial.uniforms.ratio.value.set(1, (this.renderHeight()/this.renderWidth())/(this.videoHeight/this.videoWidth));
			}

			this.playbackMaterial.uniforms.tDiffuse.value = CameraController.texture;

			var needsUpdate = CameraController.texture.updateFrame != AppStatus.currentFrame;
			CameraController.texture.needsUpdate = needsUpdate;
			CameraController.texture.updateFrame = AppStatus.currentFrame;
			CameraController.texture.needsUpdate = true;

			//---------------
			//
			// Update fbo
			//
			//---------------
			if (this.fbos && needsUpdate && (!this.lastTime || (performance.now()-this.lastTime >= 1000/24))) {

				var frameDelay = Math.ceil(Math.min(this.params.effect.delay!==undefined?this.params.effect.delay:0.2, (this.fbos.length-1.01)/24 ) * 24);

				this.fbos.unshift(this.fbos.splice(frameDelay,1)[0]);
				Utils.renderTexture(CameraController.texture, this.fbos[0].texture, true);
				this.fbos[0].active = true;
				this.maxActive++;
				this.lastTime = performance.now();
			}

			//-----------------
			//
			// Update MotionDelay
			//
			//-----------------
			if (this.params.effect && this.params.effect.type == "motionDelay") {
				this.playbackMaterial.uniforms.tDiffuse.value = this.fbos[ 0 ].texture.texture; //CameraController.texture;

				if (!this.animationStarted) {
					this.animationStarted = true;
					this.animationPc = 0.0;
					this.animationStartTime = performance.now()+(this.params.effect.animationDelay||0)*1000;
				}
				this.animationPc = !this.params.effect.animation ? 1.0 : Math.pow(Utils.cmap(performance.now(), this.animationStartTime+this.params.effect.animation*1000*0.25, this.animationStartTime+this.params.effect.animation*1000, 0.0, 1.0),2.0);
				if (isNaN(this.animationPc)) this.animationPc = 1.0;
			


				//--------------
				//
				// frameOffset
				//
				//--------------
				var frameDelay = Math.min(this.params.effect.delay||0.2, (this.fbos.length-1.001)/24 ) * 24;
				frameDelay = Math.min(frameDelay, this.maxActive);

				var frameOffset2 = Math.round(Utils.ccmap(this.animationPc,0.0,0.5,0.0,frameDelay/2));
				var frameOffset3 = Math.round(Utils.ccmap(this.animationPc,0.0,1.0,0.0,frameDelay));
				frameOffset3 = Math.max(frameOffset2, frameOffset3);

				this.plane.material.uniforms.tDiffuse2.value = frameOffset2==0 ? CameraController.texture : this.fbos[ 0 ].texture.texture;
				this.plane.material.uniforms.tDiffuse2.value = frameOffset2==0 ? CameraController.texture : this.fbos[ frameOffset2 ].texture.texture;
				this.plane.material.uniforms.tDiffuse3.value = frameOffset3==0 ? CameraController.texture : this.fbos[ frameOffset3 ].texture.texture;
			
			} else if (this.params.reference) {
				if (this.referenceTexture.loaded) {
					var vw = this.referenceTexture.image.width;
					var vh = this.referenceTexture.image.height;
					// if (vw/vh > this.renderWidth()/this.renderHeight()) { //wider
					// 	console.log("heh");
					// 	this.playbackMaterial.uniforms.referenceRatio.value.set(1, 1).multiplyScalar(1.0/(this.params.referenceScale||1.0));
					// } else { //taller
					// 	this.playbackMaterial.uniforms.referenceRatio.value.set(1, (this.renderHeight()/this.renderWidth())/(vh/vw)).multiplyScalar(1.0/(this.params.referenceScale||1.0));
					// }

					this.playbackMaterial.uniforms.referenceRatio.value.set(1, 1).multiplyScalar(1.0/(this.params.referenceScale||1.0));

					this.playbackMaterial.uniforms.referenceAlpha.value = this.params.referenceAlpha !== undefined ? this.params.referenceAlpha : 1.0;
					this.playbackMaterial.uniforms.referenceOffset.value.y = (this.params.referenceOffset||0.0);
					this.playbackMaterial.uniforms.tReference.value = this.referenceTexture;

					if (this.params.tracking) {
						this.playbackMaterial.uniforms.referenceOffset.value.x = (OpticalFlowController.getCenterPoint().x-0.5) * (this.renderWidth()/this.renderHeight());
						this.playbackMaterial.uniforms.referenceOffset.value.y = 0.5-OpticalFlowController.getCenterPoint().y + (this.params.referenceOffset||0.0);
					}


				} else {
					this.playbackMaterial.uniforms.tReference.value = Utils.blackTexture;
				}
			} else if (this.params.referenceMontage) {
				var ratio = this.referenceMontage.getRatio();

				this.playbackMaterial.uniforms.referenceRatio.value.set(1,1).multiplyScalar(1.0/(this.params.referenceScale||1.0));

				if (this.active) this.referenceMontage.update();

				this.playbackMaterial.uniforms.referenceAlpha.value = this.params.referenceAlpha !== undefined ? this.params.referenceAlpha : 1.0;
				this.playbackMaterial.uniforms.referenceOffset.value.set(0.0, 0.0);
				this.playbackMaterial.uniforms.tReference.value = this.referenceMontage.getTexture();

				if (this.params.tracking) {
					this.playbackMaterial.uniforms.referenceOffset.value.x = (0.5-OpticalFlowController.getCenterPoint().x) * (this.renderWidth()/this.renderHeight());
					this.playbackMaterial.uniforms.referenceOffset.value.y = 0.5-OpticalFlowController.getCenterPoint().y + (this.params.referenceOffset||0.0);
				}
			
			} else {
				this.playbackMaterial.uniforms.tDiffuse.value = CameraController.texture;
			}

		}


		if (this.params.fill === 'center') {
			
			this.plane.scale.set(1, (this.videoHeight/this.videoWidth) * 1 * (this.renderWidth() / this.renderHeight()), 1.0);
			this.plane.scale.multiplyScalar(1.0 - (this.params.padding==undefined?0.1:this.params.padding));
			this.plane.material.uniforms.ratio.value.set(1,1);
			
		} else if (this.params.fill === 'square') {

			if (this.videoHeight > this.videoWidth) {
				this.plane.material.uniforms.ratio.value.set(1, this.videoWidth/this.videoHeight);
			} else {
				this.plane.material.uniforms.ratio.value.set(this.videoHeight/this.videoWidth, 1);
			}
			if (this.renderWidth() > this.renderHeight()) {
				this.plane.scale.set(1 / (this.renderWidth() / this.renderHeight()), 1, 1);
			} else {
				this.plane.scale.set(1, 1 / (this.renderHeight()/this.renderWidth()), 1);
			}
			this.plane.scale.multiplyScalar(1.0 - (this.params.padding==undefined?0.1:this.params.padding));

		} else {

			this.plane.material.uniforms.ratio.value.set(1,1);
			if (this.videoWidth / this.videoHeight > this.renderWidth() / this.renderHeight()) {
				this.plane.scale.set((this.videoWidth / this.videoHeight) / (this.renderWidth() / this.renderHeight()), 1, 1);
			} else {
				this.plane.scale.set(1, (this.videoHeight/this.videoWidth) / (this.renderHeight()/this.renderWidth()), 1);
			}
		}

		this.plane.scale.x *= CameraController.getFlip();
	}

	render() {
		if (!this.active) return;
		super.render();

		//render main scene
		if (this.referenceMontage) this.referenceMontage.render();
		renderer.render(this.scene, this.camera, this.renderTarget, false);
		if (this.info.params.faces) FaceTracker.render(this.renderTarget, this.renderWidth(), this.renderHeight());
		// if (this.params.tracking) renderer.render(this.trackedScene, Utils.topLeftCamera, this.renderTarget, false);

	}
}
*/
export default LiveFeedView;	

