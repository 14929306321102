import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';
import Fbo from '../utils/Fbo.js'
import RendererController from '../controllers/RendererController.js';
import MultiStorage from '../utils/MultiStorage.js';


import BranchController from '../controllers/BranchController.js';
import MontageController from '../controllers/MontageController.js';
import Montage from '../objects/Montage.js';
import SpritesheetVideo from '../objects/SpritesheetVideo.js';

import getBranchTasks from './BranchViewUtils.js';
import BranchView from './BranchView.js';
import MottoView from './MottoView.js';



//------------------------
// 
//  Base class for all views
//
//-------------------------
class SimpleBranchView extends BranchView{
	constructor(_info, batchName, doneCallback) {

		var branches = getBranchTasks(_info);			
		super({
			"type": "branches",
			"branches": branches,
			isAllowCamera: _info.isAllowCamera,
			isCameraRefused: _info.isCameraRefused
		}, batchName, doneCallback);

		this.waitForConditions = (!_info['default'] || _info['default']=='wait');
	}



	// checkCondition() {
	// 	var os = this.branchSelected, oi = this.selectedBranch;
	// 	var bs = false;
	// 	for (var i=this.info.branches.length-1; i>-1; i--) {
	// 		if (!bs && BranchController.checkCondition(this.info.branches[i].condition, this.info.branches[i].condition_id)) {
	// 			this.selectedBranch = i;
	// 			bs = true;

	// 			if ('camera_allowed' == this.info.branches[i].condition || 'camera_not_allowed' == this.info.branches[i].condition) {
	// 				if (this.active) BranchController.lockedCameraCondition = true; else if (!this.started) BranchController.lockedCameraCondition = false;
	// 			}
	// 		}
	// 	}	
	// 	this.branchSelected = this.branchSelected||bs;


	// 	//for debugging
	// 	if (this.branchSelected && !os || this.selectedBranch !== oi) {
	// 		tasksState = MultiStorage.getState();
	// 		tasksState.branchSelected = tasksState.branchSelected||{};
	// 		tasksState.branchSelection = tasksState.branchSelection||{};
	// 		tasksState.branchSelected[this.branchId] = this.branchSelected;
	// 		tasksState.branchSelection[this.branchId] = this.selectedBranch;
	// 		MultiStorage.setState(tasksState);
	// 	}

	// 	this.lastCondition = this.branchSelected||this.active;
	// 	return this.lastCondition;

	// 	return super.checkCondition();

		// // if (this.active) {console.log("branch active, returning cond"); return this.lastCondition;};
		// var os = this.branchSelected, oi = this.selectedBranch;
		// var bs = false;
		// for (var i=this.info.branches.length-1; i>-1; i--) {
		// 	if (!bs && BranchController.checkCondition(this.info.branches[i].condition, this.info.branches[i].condition_id)) {
		// 		this.selectedBranch = i;
		// 		bs = true;

		// 		if ('camera_allowed' == this.info.branches[i].condition || 'camera_not_allowed' == this.info.branches[i].condition) {
		// 			if (this.active) BranchController.lockedCameraCondition = true; else if (!this.started) BranchController.lockedCameraCondition = false;
		// 		}
				
		// 	}
		// }
		// this.branchSelected = this.branchSelected||bs;
		// if (this.branchSelected && !os || this.selectedBranch !== oi) {
		// 	tasksState = MultiStorage.getState();
		// 	tasksState.branchSelected = tasksState.branchSelected||{};
		// 	tasksState.branchSelection = tasksState.branchSelection||{};
		// 	tasksState.branchSelected[this.branchId] = this.branchSelected;
		// 	tasksState.branchSelection[this.branchId] = this.selectedBranch;
		// 	MultiStorage.setState(tasksState);
		// }

		// this.lastCondition = this.branchSelected||this.active;
		// return this.lastCondition;
	// }
}






export default  SimpleBranchView;

