import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';
import Fbo from '../utils/Fbo.js'
import RendererController from '../controllers/RendererController.js';
import MultiStorage from '../utils/MultiStorage.js';

import MontageController from '../controllers/MontageController.js';
import Montage from '../objects/Montage.js';
import SpritesheetVideo from '../objects/SpritesheetVideo.js';
import MaterialController from "../controllers/MaterialController.js";

import MottoView from './MottoView.js';


//------------------------
// 
//  Base class for all views
//
//-------------------------
class MontageSetView extends MottoView {

	constructor(_info, batchName) {

		super(_info, batchName);

		this.textType = this.info["textType"] || 'top';
		this.uuid = Utils.generateUUID();
		// this.textBackground = (this.info.textBackground || (this.info.textBackground==undefined && this.info.textBackgroundColor)) ? (this.info.textBackgroundColor || ( this.info.dark ? '#000000' : '#ffffff')) : '#ffffff';
		this.textBackground = this.info.textBackground;
		if (!this.info.textBackground) {
			this.textBackground = this.info.dark ? '#000000' : '#ffffff';
		}
		
		//
		// Add image to preloading queue
		//
		this.loaded = false;
		this.videoWidth = 288;
		this.videoHeight = 512;
		this.currentTime = 0;
		this.currentSpritesheet = 0;

		// Create all necessary spritesheets
		this.montageA = new Montage(this.params.montageA, batchName); //MontageController.create(this.params);
		this.montageB = new Montage(this.params.montageB, batchName); //MontageController.create(this.params);
		this.montageC = new Montage(this.params.montageC, batchName); //MontageController.create(this.params)
		this.hasLivefeed = this.hasLivefeed||this.montageA.hasLivefeed||this.montageB.hasLivefeed||this.montageC.hasLivefeed;

		this.planes = [];
	}


	//
	// When starting - decode image for rendering
	//
	start() {
		if (this.started) return;
		super.start();

		this.scene = new THREE.Scene();

		this.planes = [];
		for (var i=0; i<3; i++) {
			var p = new THREE.Mesh(Utils.planeGeometry, MaterialController.getMaterial('playback'));
			p.material.uniforms.tDiffuse.value = Utils.blackTexture;
			this.scene.add(p);
			this.planes.push(p);
		}			
		this.camera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, -0.5, 0.5);

		//prepare spritesheets
		this.montageA.replaceUserVideos();
		this.montageB.prepare();

		this.montageB.replaceUserVideos();
		this.montageB.prepare();

		this.montageC.replaceUserVideos();
		this.montageC.prepare();
	}

	activate() {
		if (this.active) return;
		super.activate();

		//prepare spritesheets
		this.montageA.replaceUserVideos();
		this.montageA.prepare();
		this.montageA.activate();

		this.montageB.replaceUserVideos();
		this.montageB.prepare();
		this.montageB.activate();

		this.montageC.replaceUserVideos();
		this.montageC.prepare();
		this.montageC.activate();
	}

	//
	// When disposing - release image
	//
	stop() {
		if (!this.started) return;
		super.stop();
		for (var i=0; i<this.planes.length; i++) {
			this.planes[i].material.dispose();
			this.scene.remove(this.planes[i]);
		}
		this.planes = [];
		this.scene = this.camera = null;
		this.montageA.dispose();
		this.montageB.dispose();
		this.montageC.dispose();
	}
	
	update() {
		if (!this.started) return;
		super.update();
		this.montageA.contentDiv = this.contentDiv;
		this.montageB.contentDiv = this.contentDiv;
		this.montageC.contentDiv = this.contentDiv;

		//Utils.autoReloadShaderManual(this.playbackMaterial, 'shaders/tmp.vert', 'shaders/tmp.frag');



		//prepare spritesheets
		if (this.positionX > -0.001 || AppStatus.goingBackwards) {
			if (!this.active) {
				this.montageA.replaceUserVideos();
				this.montageB.replaceUserVideos();
				this.montageC.replaceUserVideos();
			}
			this.montageA.prepare();
			this.montageB.prepare();
			this.montageC.prepare();
		}
		this.ready = this.active && this.montageA.isReady() && this.montageB.isReady() && this.montageC.isReady();

		//
		// Playback
		//
		if (this.active) {


			var shortest = this.montageA.duration <= this.montageB.duration ? this.montageA : this.montageB;
			var longest = this.montageA.duration <= this.montageB.duration ? this.montageB : this.montageA;

			shortest = shortest.duration <= this.montageC.duration ? shortest : this.montageC;
			longest = longest.duration > this.montageC.duration ? longest : this.montageC;

			if (longest == shortest) {
				longest = (shortest == this.montageC) ? this.montageB : this.montageC;
			}

			var mid =
				 shortest !== this.montageC && longest !== this.montageC ? this.montageC :
				 shortest !== this.montageB && longest !== this.montageB ? this.montageB :
				 this.montageA;

			if (!this.params.sync) {
				this.montageA.update();
				this.montageB.update();
				this.montageC.update();
			} else if (this.params.sync) {
				shortest.update();
				mid.update({sync:this.params.sync, currentTime:shortest.currentTime});
				longest.update({sync:this.params.sync, currentTime:shortest.currentTime});
			}
			AppStatus.frameNeedsUpdate = AppStatus.frameNeedsUpdate||this.montageA.needsUpdate;
			AppStatus.frameNeedsUpdate = AppStatus.frameNeedsUpdate||this.montageB.needsUpdate;
			AppStatus.frameNeedsUpdate = AppStatus.frameNeedsUpdate||this.montageC.needsUpdate;
		}

		if (this.ready) {

			this.planes[0].material.uniforms.tDiffuse.value = this.montageA.getTexture();
			this.planes[1].material.uniforms.tDiffuse.value = this.montageB.getTexture();
			this.planes[2].material.uniforms.tDiffuse.value = this.montageC.getTexture();

			//update ratio
			var ratio = this.montageA.getRatio();

			for (var i=0; i<3; i++) {
				if (ratio > 1.0) {
					this.planes[i].material.uniforms.ratio.value.set(1, this.videoWidth/this.videoHeight);
				} else {
					this.planes[i].material.uniforms.ratio.value.set(this.videoHeight/this.videoWidth, 1);
				}
				if (this.renderWidth() > this.renderHeight()) {
					this.planes[i].scale.set(1 / (this.renderWidth() / this.renderHeight()), 1, 1);
				} else {
					this.planes[i].scale.set(1, 1 / (this.renderHeight()/this.renderWidth()), 1);
				}
				this.planes[i].scale.multiplyScalar(1.0 - (this.params.padding==undefined?0.1:this.params.padding));
				this.planes[i].scale.multiplyScalar(0.55);
				this.planes[i].position.y = this.planes[i].scale.y * (i-1) * (1.0+(this.params.spacing!==undefined?this.params.spacing:0.1));
			}
		}
	}

	//
	// Render image to webgl??
	//
	render() {
		if (!this.active) return;
		super.render();

		if (this.montageA.isReady() && this.montageB.isReady() && this.montageC.isReady()) {
			this.montageA.render();
			this.montageB.render();
			this.montageC.render();
			renderer.render(this.scene, this.camera, this.renderTarget, false);
		}

	}


	//outside access
	isReady() {
		return super.isReady() && this.ready;
	}
}

export default MontageSetView;	

