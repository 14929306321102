//------------------------
//
//  Handle webcam request
//
//------------------------
import * as THREE from 'three';

import SETTINGS from './Settings.js';
import AppStatus from './AppStatus.js';
import Utils from '../utils/Utils.js';
import MultiStorage from '../utils/MultiStorage.js';

import MaterialController from "./MaterialController.js";

function CameraController() {

	var cameraFeed = null,
		video = null,
		rePlayingVideo = false,
		lastReplayingVideo = 0,
		cameraStarted = false,
		cameraRequesting = false,
		alreadyRequested = false,
		gotConstraints = false;
	window.backConstraints = null,
	window.frontConstraints = null;

	this.facingMode = '';
	this.videoWidth = 1080;
	this.videoHeight = 1920;
	this.allowedOnce = false;
	this.allowedOnceOnThisPage = false;


	this.cameraAllowed = false;
	this.cameraRefused = false;
	this.inUse = 0;
	this.triedOnce = false;

	this.videoTexture = new THREE.Texture(Utils.emptyCanvas);
	this.videoTexture.format = THREE.RGBFormat;
	this.videoTexture.type = THREE.UnsignedByteType;
	this.videoTexture.wrapT = this.videoTexture.wrapS = THREE.ClampToEdgeWrapping;
	this.videoTexture.magFilter = THREE.LinearFilter;
	this.videoTexture.minFilter = THREE.LinearFilter;
	this.videoTexture.generateMipmaps = false;
	this.videoTexture.anisotropy = 1;
	this.videoTexture.needsUpdate = true;

    this.texture = Utils.blackTexture; 

    this.videoAdded = false;

    this.playbackMaterial = null;
	this.lastUpdate = -1;
	this.lastUpdateTime = 0;
	this.currentConstraintTest = 0;



	var pausedTime = 0;
	var triedRestarting = 0;
	this.updateTexture = function() {
		if (!SETTINGS.isIOS) {
			if (!document.hidden  && video && !triedRestarting && this.allowedOnceOnThisPage && video.paused  && !cameraRequesting) { //&& !rePlayingVideo
				console.log("Restarting paused camera",document.hidden);
				triedRestarting = 1;
				window.CameraController.start(window.CameraController.facingMode, true);
				if (SETTINGS.isIOS) {
					setTimeout(this.forceRestartVideoCheck, 500);
					setTimeout(this.forceRestartVideoCheck, 1000);
					setTimeout(this.forceRestartVideoCheck, 2000);
					setTimeout(this.forceRestartVideoCheck, 4000);
				}				
			}
		}
		
		// if (video && triedRestarting==1 && video.paused  && !cameraRequesting) { //&& !rePlayingVideo
		// 	triedRestarting == 2;
		// 	console.log("Force video restart");
		// 	setTimeout(this.forceRestartVideo, 500);
		// }
		if (!this.isReady()) return false;
		if (AppStatus.currentFrame == this.lastUpdate) return true;
		// if (SETTINGS.isIOS && !this.videoAdded) {
		// 	$('#usermedia').append(video);
		// 	this.videoAdded = true;
		// }
		this.lastUpdate = AppStatus.currentFrame;
		this.videoTexture.needsUpdate = true;
		Utils.renderMaterial(this.playbackMaterial, this.fbo, false);

		//safety check
		// if (video && cameraStarted && video.paused  && !cameraRequesting) { //&& !rePlayingVideo
		// 	// rePlayingVideo = true;

		// 	window.CameraController.start(window.CameraController.facingMode, true);
		// 	// this.start(this.facingMode, true);

		// 	// var self = this;
		// 	// navigator.mediaDevices.getUserMedia(this.facingMode==='front' ? window.frontConstraints : window.backConstraints).then(function(stream) {
		// 	// 	rePlayingVideo = false;
		// 	// 	video.srcObject = stream;
		// 	// 	video.play();
		// 	// 	stream = null;
		// 	// }).catch(function(err,a) {
		// 	// 	if (err && err.name == 'OverconstrainedError' && err.constraint=='facingMode' && SETTINGS.isIOS && window.backConstraints.video.facingMode && window.backConstraints.video.facingMode.exact) {
		// 	// 		console.log("RETRY!!");
		// 	// 		window.backConstraints.video.facingMode = 'environment';
		// 	// 		window.frontConstraints.video.facingMode = 'user';
		// 	// 		cameraRequesting = false;
		// 	// 		self.triedOnce = false;
		// 	// 		self.cameraAllowed = false;
		// 	// 		self.cameraRefused = false;
		// 	// 		self.start(direction);
		// 	// 	} else {
		// 	// 		if (self.triedOnce && self.cameraAllowed) {
		// 	// 			AppStatus.selectChapter();
		// 	// 		}
		// 	// 		self.triedOnce = true;
		// 	// 		self.cameraAllowed = false;
		// 	// 		self.cameraRefused = true;
		// 	// 		// window.location.reload();
		// 	// 	}
		// 	// }.bind(this));
			
		// }
		return true;
	}.bind(this);


	this.forceRestartVideoCheck = function() {
		if (cameraRequesting) return;
		if (video && video.paused) this.forceRestartVideo();
	}.bind(this);
	this.forceRestartVideo = function() {
		console.log("Force restarting video");
		cameraRequesting = true;
		window.frontConstraints = window.frontConstraints || window.frontConstraints;
		navigator.mediaDevices.getUserMedia(this.facingMode==='front' ? window.frontConstraints : window.backConstraints).then(function(stream) {
			video.srcObject = stream;
			stream = null;
			cameraRequesting = false;
			triedRestarting = 0;
		}).catch(function(err) {
			cameraRequesting = false;
			triedRestarting = 0;
		});
	}.bind(this);


	this.updateUI = function() {
		if (AppStatus.currentFrame % 30 == 0 && document.hidden) this.handleVisibilityChange();

		// if (SETTINGS.isIOS && cameraStarted && !cameraRequesting) {
		// 	if (AppStatus.currentFrame - this.lastUpdate >= 30*5) {
		// 		$('#usermedia').remove(video);
		// 		this.videoAdded = false;
		// 	}
		// }
		
	};


	//
	// Constraints for ios
	//
	this.POTENTIAL_BACK_CONSTRAINTS = [
		{ audio: false, video: {  frameRate: { ideal: 60 }, width: {ideal: 607}, height: {ideal: 1080}, aspectRatio: {ideal: 9/16}, facingMode:{ "exact":  "environment"}, resizeMode: {"exact": "none"}}},
		{ audio: false, video: {  frameRate: { ideal: 60 }, width: {exact: 1080}, height: {exact: 1920}, aspectRatio: {exact: 16/9}, facingMode:{ "exact":  "environment"}, resizeMode: {"exact": "none"}}},
		{ audio: false, video: {  frameRate: { ideal: 60 }, width: {exact: 1080}, height: {exact: 1920}, aspectRatio: {ideal: 16/9}, facingMode:{ "exact":  "environment"}, resizeMode: {"exact": "none"}}},

		{ audio: false, video: {  frameRate: { ideal: 60 }, width: {ideal: 607}, height: {ideal: 1080}, aspectRatio: {ideal: 16/9}, facingMode:{ "exact":  "environment"}, resizeMode: {"exact": "none"}}},

		{ audio: false, video: {  frameRate: { ideal: 60 }, width: {ideal: 607}, height: {ideal: 1080}, aspectRatio: {ideal: 9/16}, facingMode:{ "exact":  "environment"}}, resizeMode: {"exact": "none"}},
		{ audio: false, video: {  frameRate: { ideal: 60 }, width: {exact: 607}, height: {exact: 1080}, facingMode:{ "exact":  "environment"}}, resizeMode: {"exact": "none"}},
		{ audio: false, video: {  width: {exact: 607}, height: {exact: 1080}, facingMode:{ "exact":  "environment"}}, resizeMode: {"exact": "none"}},
		{ audio: false, video: {  width: {exact: 1080}, height: {exact: 1920}, facingMode:{ "exact":  "environment"}}, resizeMode: {"exact": "none"}},

		{ audio: false, video: {  frameRate: { ideal: 60 }, aspectRatio: {exact: 9/16}, facingMode:{ "exact":  "environment"}}, resizeMode: {"exact": "none"}},
		{ audio: false, video: {  frameRate: { ideal: 60 }, aspectRatio: {exact: 16/9}, facingMode:{ "exact":  "environment"}}, resizeMode: {"exact": "none"}},


		{ audio: false, video: {  aspectRatio: {exact: 9/16}, facingMode:{ "exact":  "environment"}}, resizeMode: {"exact": "none"}},
		{ audio: false, video: {  frameRate: { ideal: 60 }, aspectRatio: {ideal: 9/16}, facingMode:{ "exact":  "environment"}}, resizeMode: {"exact": "none"}},
		{ audio: false, video: {  aspectRatio: {ideal: 9/16}, facingMode:{ "exact":  "environment"}}, resizeMode: {"exact": "none"}},
		{ audio: false, video: {  aspectRatio: {exact: 9/16}, facingMode:{ "exact":  "environment"}}},
		{ audio: false, video: {  aspectRatio: {ideal: 9/16}, facingMode:{ "exact":  "environment"}}},
		{ audio: false, video: {  width: {ideal: 1080}, height: {ideal: 1920}, facingMode:{ "exact":  "environment"}}, resizeMode: {"exact": "none"}},
		{ audio: false, video: {  width: {ideal: 1080}, height: {ideal: 1920}, facingMode:{ "exact":  "environment"}}, resizeMode: {"exact": "none"}},

		//v2
		{ audio: false, video: {  width: 1080, height: 1920, facingMode: "environment", resizeMode: "none"}, width:1080, height:1920},
		{ audio: false, video: {  width: 1080, height: 1920, facingMode: "environment"}, width:1080, height:1920},
		{ audio: false, video: {  width: 607, height: 1080, facingMode: "environment"}, width:607, height:1080},
		{ audio: false, video: {  width: 1080, height: 1920, facingMode: "environment"}, width:1080, height:1920},
		{ audio: false, video: {  width: 607, height: 1080, facingMode: "environment"}},
		{ audio: false, video: {  width: 480, height: 640, facingMode: "environment"}},
		{ audio: false, video: {  aspectRatio:16/9, facingMode: "environment"}, width:607, height:1080},
		{ audio: false, video: {  aspectRatio:9/16, facingMode: "environment"}, width:607, height:1080},
		{ audio: false, video: {  aspectRatio:9/16, facingMode: "environment"}, width:480, height:640},
		{ audio: false, video: {  aspectRatio:9/16, facingMode: "environment"}, width:480, height:640},
		{ audio: false, video: {  facingMode: "environment"}, width:480, height:640}

	];

	//framerate on/off
	//aspectratio on/off
	//width: 480,607,1080,off
	//width in/out
	//resizeMode on/off
	//ideal/exact/string
	this.POTENTIAL_FRONT_CONSTRAINTS = [
		{ audio: false, video: {  frameRate: { ideal: 60 }, width: {ideal: 607}, height: {ideal: 1080}, aspectRatio: {ideal: 9/16}, facingMode:{ "exact":  "user"}, resizeMode: {"exact": "none"}}},
		{ audio: false, video: {  frameRate: { ideal: 60 }, width: {exact: 1080}, height: {exact: 1920}, aspectRatio: {exact: 16/9}, facingMode:{ "exact":  "user"}, resizeMode: {"exact": "none"}}},
		{ audio: false, video: {  frameRate: { ideal: 60 }, width: {exact: 1080}, height: {exact: 1920}, aspectRatio: {ideal: 16/9}, facingMode:{ "exact":  "user"}, resizeMode: {"exact": "none"}}},

		{ audio: false, video: {  frameRate: { ideal: 60 }, width: {ideal: 607}, height: {ideal: 1080}, aspectRatio: {ideal: 16/9}, facingMode:{ "exact":  "user"}, resizeMode: {"exact": "none"}}},

		{ audio: false, video: {  frameRate: { ideal: 60 }, width: {ideal: 607}, height: {ideal: 1080}, aspectRatio: {ideal: 9/16}, facingMode:{ "exact":  "user"}}, resizeMode: {"exact": "none"}},
		{ audio: false, video: {  frameRate: { ideal: 60 }, width: {exact: 607}, height: {exact: 1080}, facingMode:{ "exact":  "user"}}, resizeMode: {"exact": "none"}},
		{ audio: false, video: {  width: {exact: 607}, height: {exact: 1080}, facingMode:{ "exact":  "user"}}, resizeMode: {"exact": "none"}},
		{ audio: false, video: {  width: {exact: 1080}, height: {exact: 1920}, facingMode:{ "exact":  "user"}}, resizeMode: {"exact": "none"}},

		{ audio: false, video: {  frameRate: { ideal: 60 }, aspectRatio: {exact: 9/16}, facingMode:{ "exact":  "user"}}, resizeMode: {"exact": "none"}},
		{ audio: false, video: {  frameRate: { ideal: 60 }, aspectRatio: {exact: 16/9}, facingMode:{ "exact":  "user"}}, resizeMode: {"exact": "none"}},

		{ audio: false, video: {  aspectRatio: {exact: 9/16}, facingMode:{ "exact":  "user"}}, resizeMode: {"exact": "none"}},
		{ audio: false, video: {  frameRate: { ideal: 60 }, aspectRatio: {ideal: 9/16}, facingMode:{ "exact":  "user"}}, resizeMode: {"exact": "none"}},
		{ audio: false, video: {  aspectRatio: {ideal: 9/16}, facingMode:{ "exact":  "user"}}, resizeMode: {"exact": "none"}},
		{ audio: false, video: {  aspectRatio: {exact: 9/16}, facingMode:{ "exact":  "user"}}},
		{ audio: false, video: {  aspectRatio: {ideal: 9/16}, facingMode:{ "exact":  "user"}}},
		{ audio: false, video: {  width: {ideal: 1080}, height: {ideal: 1920}, facingMode:{ "exact":  "user"}}, resizeMode: {"exact": "none"}},
		{ audio: false, video: {  width: {ideal: 1080}, height: {ideal: 1920}, facingMode:{ "exact":  "user"}}, resizeMode: {"exact": "none"}},

		//v2
		{ audio: false, video: {  width: 1080, height: 1920, facingMode: "user", resizeMode: "none"}, width:1080, height:1920},
		{ audio: false, video: {  width: 1080, height: 1920, facingMode: "user"}, width:1080, height:1920},
		{ audio: false, video: {  width: 607, height: 1080, facingMode: "user"}, width:607, height:1080},
		{ audio: false, video: {  width: 1080, height: 1920, facingMode: "user"}, width:1080, height:1920},
		{ audio: false, video: {  width: 607, height: 1080, facingMode: "user"}},
		{ audio: false, video: {  width: 480, height: 640, facingMode: "user"}},
		{ audio: false, video: {  aspectRatio:16/9, facingMode: "user"}, width:607, height:1080},
		{ audio: false, video: {  aspectRatio:9/16, facingMode: "user"}, width:607, height:1080},
		{ audio: false, video: {  aspectRatio:9/16, facingMode: "user"}, width:480, height:640},
		{ audio: false, video: {  aspectRatio:9/16, facingMode: "user"}, width:480, height:640},
		{ audio: false, video: {  facingMode: "user"}, width:480, height:640}
	];



	//------------------
	//
	//  Init Constraints
	//
	//------------------
	this.getUserMediaConstraints = function() {

		//Test multiple constraints until we find one that works
		if (self.currentConstraintTest > 0) {
			window.backConstraints = window.backConstraints = self.POTENTIAL_BACK_CONSTRAINTS[self.currentConstraintTest];
			window.frontConstraints = window.frontConstraints = self.POTENTIAL_FRONT_CONSTRAINTS[self.currentConstraintTest];
		}


		return new Promise(function(resolve,reject) {
			if (gotConstraints) {
				resolve();
				return;
			}
			console.log("enumerating devices", !navigator.mediaDevices?false:true, !navigator.getUserMedia?false:true, !navigator.webkitGetUserMedia?false:true);
			// console.dir(navigator.mediaDevices);
			// console.log(SETTINGS.isSafari, SETTINGS.isIOS);
			// console.log(navigator.userAgent);

			// if (!navigator.mediaDevices.enumerateDevices) {
			gotConstraints = true;
	      	resolve();
	      	return;
			// }

			navigator.mediaDevices.enumerateDevices().then(function(devices) {
				// console.log("got devices", devices);
		    	var allVideoDevices = [];
		    	var deviceBack = null;
		    	var deviceFront = null;

		    	//get all cameras
		    	for (var i=0; i<devices.length; i++) {
		    		var dev = devices[i];
		    		if (dev.kind == "videoinput") {
		    			allVideoDevices.push(dev);

		    			// deviceFront = deviceFront || dev;
		    			// deviceBack = deviceBack || dev;

		    			//select front/back camera
		    			if (dev.facingMode == "environment" || (dev.name && /back/i.test(dev.name)) || dev.label.toLowerCase().indexOf("back")>=0 || dev.label.toLowerCase().indexOf("environment")>=0) {
		    				deviceBack = dev;
		    			} else if (dev.facingMode == "user" || dev.label.toLowerCase().indexOf("front")>=0) {
		    				deviceFront = dev;
		    			}
		    		}
		    	}
		    	window.devices = devices;

		    	if (allVideoDevices.length <= 0) { //} || (!deviceBack && !deviceFront)) {
		    		reject();
		    		return;
		    	}

		    	if (!SETTINGS.isIOS) {
		    		if (deviceFront && deviceFront.getCapabilities) {
		    			var caps = deviceFront.getCapabilities();
	
		    			if (((caps.aspectRatio && 1080/1920 <= caps.aspectRatio.max && 1080/1920 >= caps.aspectRatio.min)) && (1080 >= caps.width.min && 1080 <= caps.width.max) && (1920 >= caps.height.min && 1920 <= caps.height.max)) {
		    				window.frontConstraints.video.width.ideal = undefined;
		    				window.frontConstraints.video.height.ideal = undefined;
		    				window.frontConstraints.video.aspectRatio.ideal = undefined;

		    				window.frontConstraints.video.aspectRatio.require = 1080/1920;
		    				window.frontConstraints.video.width.require = 1080;
		    				window.frontConstraints.video.height.require = 1920;
		    			}
		    		}
		    		if (deviceBack && deviceBack.getCapabilities) {
		    			var caps = deviceBack.getCapabilities();

		    			if (((caps.aspectRatio &&  1080/1920 >= caps.aspectRatio.min && 1080/1920 <= caps.aspectRatio.max)) && (1080 >= caps.width.min && 1080 <= caps.width.max) && (1920 >= caps.height.min && 1920 <= caps.height.max)) {
		    				window.backConstraints.video.width.ideal = undefined;
		    				window.backConstraints.video.height.ideal = undefined;
		    				window.backConstraints.video.aspectRatio.ideal = undefined;

		    				window.backConstraints.video.aspectRatio.require = 1080/1920;
		    				window.backConstraints.video.width.require = 1080;
		    				window.backConstraints.video.height.require = 1920;
		    			}
		    		}
		    	}

		        // Set the constraints and call getUserMedia
		       gotConstraints = true;
		       // if (deviceBack) {window.backConstraints.video.deviceId = {"exact": deviceBack.deviceId}; window.backConstraints.video.mandatory.sourceId = deviceBack.deviceId}; 
		       // if (deviceFront && !SETTINGS.isIOS) window.frontConstraints.video.deviceId = {"exact": deviceFront.deviceId};
		       resolve();

			}).catch(function(err) {
				AnalyticsController.trackEvent('camera_error', 'enumerateDevices');
				console.log(err.name + ": " + err.message); reject(err);
			});
		});
	};


	this.init = function() {
		video = document.createElement('video');
		video.preload = true;
		video.crossorigin = 'anonymous';
		video['webkit-playsinline'] = true;
		video['playsinline'] = true;
		video.setAttribute('preload', true);
		video.setAttribute('crossorigin', 'anonymous');
		video.setAttribute('playsinline', true);
		video.setAttribute('muted', true);
		video.setAttribute('webkit-playsinline', true);
		video.volume = 0.0;
		video.muted = true;
		$(video).addClass('usermediavideo');

		if (SETTINGS.isIOS && !this.videoAdded) {
	    	$('#usermedia').append(video);
	    	this.videoAdded = true;
	    }

		this.videoTexture.image = video;
		// if (!SETTINGS.EDITOR_MODE && SETTINGS.isIOS) $('#usermedia').append(video);

		if (SETTINGS.isIOS) {
			// video.ontimeupdate = function() {
			// 	// $('body').toggleClass('force-redraw', AppStatus.currentFrame%2==1?true:false);
			// 	// window.getComputedStyle(document.body);
			// 	// $('body').toggleClass('force-redraw', AppStatus.currentFrame%2==1?true:false);
			// };
			// window.addEventListener("deviceorientation", function(e) {
			// 	window.orientationEv = e;
			// 	// console.log(e);
			// 	// document.body.id = AppStatus.currentFrame%2==0?" ":"";
			// 	// document.body.replaceWith(document.body);
			// 	// $(document.body).toggleClass('force-redraw', AppStatus.currentFrame%2==0?true:false);
			// 	// document.body.replaceWith(document.body);
			// 	// window.getComputedStyle(document.body);
			// 	// window.getComputedStyle($('#hack')[0]);
			// 	// video.currentTime = 0;
			// }, true);
		}		

		this.allowedOnce = 	MultiStorage.getGlobalState().cameraAllowedOnce;

		window.backConstraints = ((SETTINGS.isSafari || SETTINGS.isIOS ) && !SETTINGS.FAKE_IOS) ? 
			{ audio: false,
				video: { 
					aspectRatio:{exact:16/9},
					frameRate: { ideal: 60 },
					facingMode:{ "exact":  "environment"},
					resizeMode: {"exact": "none"},
					// width: {ideal: 1080},
					// height: {ideal: 1920},
				},
				width: 1080,
				height: 1920
				// frameRate: {deal: 24, max: 30, min: 24 }
			} : 
			{ audio: false, video: { height: { ideal: 1920 }, aspectRatio:{ideal:1920/1080}, facingMode: "environment", resizeMode: "none"}},
		window.frontConstraints = ((SETTINGS.isSafari || SETTINGS.isIOS ) && !SETTINGS.FAKE_IOS) ? 
			{ 
				audio: false,
				video: { 
					frameRate: { ideal: 30, min: 24 }, //ideal: 60 
					facingMode: { "exact": "user" },
					resizeMode: { "exact": "none" }
				},
				width: 1080, 
				height: 1920
			} : 
			{ audio: false, video: {  height: { ideal: 1920 }, aspectRatio:{ideal:1920/1080}, facingMode: "user", resizeMode: "none"}};
			// { audio: false, video: { width: {ideal:1080}, height: {ideal:1920}, aspectRatio:1080/1920, facingMode: "user", resizeMode: "none"}};
		// setInterval(function(){$('body').hide().show(0);}, 16);

		if (SETTINGS.isIOS && screen.height > 570) { //not iphone 5 fix
			window.backConstraints.video.width = {ideal: 1080};
			window.backConstraints.video.height = {ideal: 1920};
			delete window.backConstraints.width;
			delete window.backConstraints.height;


			if (SETTINGS.isIOS && !SETTINGS.FAKE_IOS) {//} && SETTINGS.LOWRES_CAMERA_MODE) {
				window.backConstraints.video.width = {ideal: 607};
				window.backConstraints.video.height = {ideal: 1080};
				window.backConstraints.video.aspectRatio = {ideal: 16/9};

				window.frontConstraints.video.width = {ideal: 607};
				window.frontConstraints.video.height = {ideal: 1080};
				window.frontConstraints.video.aspectRatio = {ideal: 16/9};
			}

			// window.frontConstraints.video.width = {ideal: 1080};
			// window.frontConstraints.video.height = {ideal: 1920};
			// delete window.frontConstraints.width;
			// delete window.frontConstraints.height;

			// if (SETTINGS.OLD_PHONE_MODE) {
			// 	window.backConstraints.video.width = {ideal: 480};
			// 	window.backConstraints.video.width = {ideal: 640};
			// 	// window.frontConstraints.video.width = {ideal: 480};
			// 	// window.frontConstraints.video.height = {ideal: 640};
			// }
		}

		

		window.backConstraints = window.backConstraints||window.backConstraints;
		document.addEventListener("visibilitychange", this.handleVisibilityChange, false);
		if (SETTINGS.isIOS) window.addEventListener("focus", this.handleVisibilityChange, false);

	};


	this.handleVisibilityChange = function(e) {
	  if (!this.allowedOnceOnThisPage) return;
	  if (document.hidden) {
		 if (video && cameraStarted) {
		  		triedRestarting = 0;
		  		console.log("Visibility Pause");
		  		pausedTime = performance.now()
				video.pause();
				if (video.srcObject) {
					video.srcObject.getTracks().forEach(function(track) {track.stop();});
					delete video.srcObject;
				}
				cameraStarted = false;
			}
		} else {
			triedRestarting = 0;
			if (alreadyRequested && !cameraRequesting) {
				console.log("VISIBILITY CHANGE: Restarting");
				if (SETTINGS.isIOS) RendererController.resize();
				this.start(this.facingMode, true);
				if (SETTINGS.isIOS) setTimeout(this.forceRestartVideoCheck, 1000);
				if (SETTINGS.isIOS) setTimeout(this.forceRestartVideoCheck, 2000);
				if (SETTINGS.isIOS) setTimeout(this.forceRestartVideoCheck, 4000);

			}
		}
	}.bind(this);

	//-------------------------
 	//
	//  Request Feed // Change Camera
	//
	//-------------------------
	this.waitingForPortraitMode = false;
	this.start = function(direction, force) {
		var self = this;
		this.inUse++;

		if (SETTINGS.STORYBOARD_MODE) direction = 'front';

		if (!direction) direction = self.facingMode||'back';
		if (cameraRequesting) {console.log("Already requesting"); return;}

		if (cameraStarted && video && video.srcObject && !video.srcObject.active) {
			cameraStarted = false;
		}

		// console.log("START", direction);
		// console.log("REQUESTING?", self.facingMode, direction);
		if (self.facingMode !== direction || force || !cameraStarted) {
			self.facingMode = direction;
			cameraRequesting = true;
			alreadyRequested = true;
			
			console.log("Requesting camera");
			// if (SETTINGS.isIOS && !this.videoAdded) {
		 //    	$('#usermedia').append(video);
		 //    	this.videoAdded = true;
		 //    }
	    	
			//stop stream to re-access it
			if (video && cameraStarted) {
				// cameraStarted = false;
				// if (SETTINGS.isIOS) $('#usermedia').remove(video);
				video.pause();
				// $(video).remove();
				// this.videoAdded = false;
				if (video.srcObject) {
					video.srcObject.getTracks().forEach(function(track) {track.stop();});
					delete video.srcObject;
				}
			}

			if (!self.allowedOnce) AnalyticsController.trackEvent('camera_requesting');
			self.getUserMediaConstraints().then(function() {
				navigator.mediaDevices.getUserMedia(self.facingMode==='front' ? window.frontConstraints : window.backConstraints).then(function(stream) {
					if (!self.allowedOnceOnThisPage) AnalyticsController.trackEvent('camera_allowed', AppStatus.currentTaskId);
					if (!self.allowedOnce) AnalyticsController.trackEvent('camera_allowed', 'first');
					console.log("CAMERA STARTED!", direction);
					self.triedOnce = true;
					var globalState = MultiStorage.getGlobalState();
					self.allowedOnceOnThisPage = self.allowedOnce = globalState.cameraAllowedOnce = true;
					MultiStorage.setGlobalState(globalState);
					video.onloadedmetadata = function(e) {

						//try again..
						if (video.videoWidth >= video.videoHeight && self.currentConstraintTest < self.POTENTIAL_BACK_CONSTRAINTS.length-1) {
							console.log("error: wrong video ratio", self.currentConstraintTest, video.videoWidth, video.videoHeight);
							cameraRequesting = false;
							self.currentConstraintTest++;
							window.backConstraints = self.POTENTIAL_BACK_CONSTRAINTS[self.currentConstraintTest];
					    	window.frontConstraints = self.POTENTIAL_FRONT_CONSTRAINTS[self.currentConstraintTest];
							self.triedOnce = false;
							self.cameraAllowed = false;
							self.cameraRefused = false;
							self.start(direction);

						} else {

							cameraRequesting = true;
							video.metaEvent = e;
							self.videoWidth = video.videoWidth;
							self.videoHeight = video.videoHeight;
							self.cameraAllowed = true;
							self.cameraRefused = false;
							triedRestarting = 0;

							// video.srcObject.getTracks().forEach(function(track) {console.log("track capabilities:",track.getCapabilities());});
							
							// if (SETTINGS.isIOS) {
							// 	 var globalState = MultiStorage.getGlobalState();
							// 	 globalState.cameraAttempts = 0;
							//   MultiStorage.setGlobalState(globalState);
							// }

							video.play().then(function() {
								console.log("VIDEO STARTED");
								if (SETTINGS.isIOS) RendererController.resize();
								cameraStarted = true;
								cameraRequesting = false;

								if (self.fbo) self.fbo.dispose();
								var t = new THREE.WebGLRenderTarget(Math.round(video.videoHeight * (9/16)), video.videoHeight);
							    t.texture.antialias = false;
							    t.texture.minFilter = THREE.LinearFilter;
							    t.texture.magFilter = THREE.LinearFilter;
							    t.texture.format = THREE.RGBAFormat;
							    t.texture.generateMipmaps = false;
							    t.texture.wrapS = t.texture.wrapT = THREE.ClampToEdgeWrapping;
							    t.texture.premultiplyAlpha = false;
							    t.texture.flipY = false;
							    t.depthBuffer = false;
							    t.stencilBuffer = false;
							    self.fbo = t;
							    self.texture = t.texture;
							    self.playbackMaterial = MaterialController.getMaterial('playback');
							    self.playbackMaterial.uniforms.tDiffuse.value = self.videoTexture;


							    var videoWidth = video.videoWidth||9;
								var videoHeight = video.videoHeight||16;
								if ( videoWidth/videoHeight > 9/16 ) { //wider
									self.playbackMaterial.uniforms.ratio.value.set((9/16)/(videoWidth/videoHeight), 1);
								} else { //taller
									self.playbackMaterial.uniforms.ratio.value.set(1, (16/9)/(videoHeight/videoWidth));
								}
							    if (self.facingMode === 'front')  self.playbackMaterial.uniforms.ratio.value.x *= -1;
							}).catch(function() {
								cameraRequesting = false;
								cameraStarted = false;
								AnalyticsController.trackEvent('camera_error', 'video_play');
							});
							// video.onloadedmetadata = null;
						}


					};

					video.srcObject = stream;
					stream = null;
				})
				.catch(function(err) {
					console.log("GetUserMedia error:",err);
					console.log(err);
					// console.log(err, err.name == 'OverconstrainedError', err.constraint=='facingMode',SETTINGS.isIOS, window.backConstraints.video.facingMode,window.backConstraints.video.facingMode.exact);
					if (err && err.name == 'OverconstrainedError' && err.constraint=='facingMode' && SETTINGS.isIOS && window.backConstraints.video.facingMode && window.backConstraints.video.facingMode.exact) {
						AnalyticsController.trackEvent('camera_error', 'OverconstrainedError_retry');

						window.backConstraints.video.facingMode = 'environment';
						window.frontConstraints.video.facingMode = 'user';
						cameraRequesting = false;
						self.triedOnce = false;
						self.cameraAllowed = false;
						self.cameraRefused = false;

						self.start(direction);
					
					} else if ( (SETTINGS.isIOS || self.currentConstraintTest>0) && self.currentConstraintTest < self.POTENTIAL_BACK_CONSTRAINTS.length-1) {
						
						console.log("GetUserMedia trying new constraint:",self.POTENTIAL_BACK_CONSTRAINTS[self.currentConstraintTest+1]);

						AnalyticsController.trackEvent('camera_error', 'OverconstrainedError_retry_'+(self.currentConstraintTest));
						cameraRequesting = false;
						self.currentConstraintTest++;
						window.backConstraints = self.POTENTIAL_BACK_CONSTRAINTS[self.currentConstraintTest];
					    window.frontConstraints = self.POTENTIAL_FRONT_CONSTRAINTS[self.currentConstraintTest];
						self.triedOnce = false;
						self.cameraAllowed = false;
						self.cameraRefused = false;
						self.start(direction);

					} else {

						cameraRequesting = false;
						cameraStarted = false;

						if (force) {
							console.log("Camera error from auto-start, retry.");
							AnalyticsController.trackEvent('camera_error', 'auto_start');

							self.triedOnce = false;
							self.cameraAllowed = false;
							self.cameraRefused = false;
							self.start(direction, false);

						} else if (self.allowedOnceOnThisPage) {
							console.log("Two camera errors : reload.");
							AnalyticsController.trackEventDirectly('camera_error', 'auto_reload');

							self.triedOnce = true;
							self.cameraAllowed = false;
							self.cameraRefused = true;
							// self.allowedOnceOnThisPage = false;
							AppStatus.selectChapter();

						} else {
							AnalyticsController.trackEvent('camera_error', err?(err.name||'refused'):'refused');

							self.triedOnce = true;
							self.cameraAllowed = false;
							self.cameraRefused = true;
							// self.allowedOnceOnThisPage = false;
						}
					}
				});
				
			}).catch(function(err,a) {
				console.log("getUserMediaConstraints error:",err,a);
				if (err && err.name == 'OverconstrainedError' && err.contraint=='facingMode' && SETTINGS.isIOS && window.backConstraints.video.facingMode && window.backConstraints.video.facingMode.exact) {
					window.backConstraints.video.facingMode = 'environment';
					window.frontConstraints.video.facingMode = 'user';
					cameraRequesting = false;
					self.triedOnce = false;
					self.cameraAllowed = false;
					self.cameraRefused = false;

					self.start(direction);
				} else {
					AnalyticsController.trackEvent('camera_error', 'get_constraints');
					self.triedOnce = true;
					self.cameraAllowed = false;
					self.cameraRefused = true;
				}
				console.warn(err);
			});
		}
	};


	this.dispose = function() {
		if (video && cameraStarted) {
			video.pause();
			if (video.srcObject) {
				video.srcObject.getTracks().forEach(function(track) {track.stop();});
				delete video.srcObject;
			}
			cameraStarted = false;
			video = null;
			$(video).remove();
		}
	}

	this.test = function(frontConstraints) {
		navigator.mediaDevices.getUserMedia(frontConstraints).then(function(stream) {
			console.log("CAMERA STARTED!", "front", stream);
			self.triedOnce = true;
			var globalState = MultiStorage.getGlobalState();
			globalState.cameraAllowedOnce = true;
			MultiStorage.setGlobalState(globalState);
			video.onloadedmetadata = function(e) {
				self.videoWidth = video.videoWidth;
				self.videoHeight = video.videoHeight;
				self.cameraAllowed = true;
				self.cameraRefused = false;

				// if (SETTINGS.isIOS) {
				// 	var globalState = MultiStorage.getGlobalState();
				// 	globalState.cameraAttempts = 0;
				// 	MultiStorage.setGlobalState(globalState);
				// }

				video.play().then(function() {
					console.log("VIDEO STARTED");
					cameraStarted = true;
					cameraRequesting = false;

					if (self.fbo) {
						self.fbo.dispose();
					}
					var t = new THREE.WebGLRenderTarget(Math.round(video.videoHeight * (9/16)), video.videoHeight);
				    t.texture.antialias = false;
				    t.texture.minFilter = THREE.LinearFilter;
				    t.texture.magFilter = THREE.LinearFilter;
				    t.texture.format = THREE.RGBAFormat;
				    t.texture.generateMipmaps = false;
				    t.texture.wrapS = t.texture.wrapT = THREE.ClampToEdgeWrapping;
				    t.texture.premultiplyAlpha = false;
				    t.texture.flipY = false;
				    t.depthBuffer = false;
				    t.stencilBuffer = false;
				    self.fbo = t;
				    self.texture = t.texture;
				    self.playbackMaterial = MaterialController.getMaterial('playback');
				    self.playbackMaterial.uniforms.tDiffuse.value = self.videoTexture;


				    var videoWidth = video.videoWidth||9;
					var videoHeight = video.videoHeight||16;
					if ( videoWidth/videoHeight > 9/16 ) { //wider
						self.playbackMaterial.uniforms.ratio.value.set((9/16)/(videoWidth/videoHeight), 1);
					} else { //taller
						self.playbackMaterial.uniforms.ratio.value.set(1, (16/9)/(videoHeight/videoWidth));
					}
				    if (self.facingMode === 'front')  self.playbackMaterial.uniforms.ratio.value.x *= -1;
				});
				// video.onloadedmetadata = null;
			};

			video.srcObject = stream;
			stream = null;
		})
		.catch(function(err) {
			console.log("GetUserMedia error:",err);
			console.log(err);
			self.triedOnce = true;
			self.cameraAllowed = false;
			self.cameraRefused = true;
		});
	};


	

	this.stop = function() {
		// this.inUse--;
		// if (this.inUse<=0) {
		// 	this.inUse = 0;
		// 	cameraStarted = false;
		// 	video.pause();
		// 	video.srcObject.getTracks().forEach(function(track) {track.stop();});
		// 	$(video).remove();
		// 	video = document.createElement('video');
		// 	video.preload = true;
		// 	video.crossorigin = 'anonymous';
		// 	video['webkit-playsinline'] = true;
		// 	video['playsinline'] = true;
		// 	video.setAttribute('preload', true);
		// 	video.setAttribute('crossorigin', 'anonymous');
		// 	video.setAttribute('playsinline', true);
		// 	video.setAttribute('webkit-playsinline', true);
		// 	$(video).addClass('usermediavideo');
		// }
	}

	this.getVideo = function() {
		return video;
	};

	this.isRequesting = function() {
		return this.cameraRefused || (!this.triedOnce && cameraRequesting);
	}

	this.getCameraRequesting = function() {
		return cameraRequesting;
	}
	this.getCameraRequested = function() {
		return cameraRequested;
	};
	this.getReplayingVideo = function() {
		return rePlayingVideo;
	};
	this.getCameraStarted = function() {
		return cameraStarted;
	}

	this.isReady = function() {
		return cameraStarted && !cameraRequesting && !!video && video.readyState>=3;
	};

	this.isFlipped = function() {
		return false;
		// return this.facingMode == 'front' ? true : false;
	};

	this.getFlip = function() {
		return 1;
		// return this.facingMode == 'front' ? -1 : 1;
	};
};
window.CameraController = window.CameraController || new CameraController();
export default window.CameraController;

