import SETTINGS from '../controllers/Settings.js';
import * as THREE from 'three';

import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import RendererController from '../controllers/RendererController.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';

import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import SpritesheetVideo from './SpritesheetVideo.js';

// var CanvasTextWrapper = require('canvas-text-wrapper').CanvasTextWrapper;

//------------------------
class SpritesheetVideoTracking extends SpritesheetVideo {
	constructor(_info, batchName) {
		super(_info, batchName);
		this.boxes = [];
		this.divs = [];


	}

	preload(batchName) {
		super.preload(batchName);
	}

	prepare() {
		super.prepare();
	}

	onAnalysisLoaded(info) {
		super.onAnalysisLoaded(info);
	}

	stop() {
		for (var i=0; i<this.divs.length; i++) {
			if (this.divs[i]) {
				$(this.divs[i].container).remove();
				$(this.divs[i].div).remove();
			}
		}
		this.divs = [];
		super.stop();
	}

	update(opt) {
		super.update(opt);

		if (this.isReady() && this.analysis && this.contentDiv) {

			var currentFrame = this.lastFrame;

			var rects = this.analysis.rectangles[currentFrame+this.frameOffset];

			if (rects && rects.length) {
				for (var i=0; i<rects.length; i++) {
					var box = rects[i];

					if (!this.divs[i]) {
						var dc = document.createElement('div');
						$(dc).addClass('spritesheet-overlay-text-container');
						$(dc).css('background', 'none');
						$(this.contentDiv).append(dc);

						var d = document.createElement('div');
						$(d).addClass('spritesheet-overlay-text');
						$(d).css('color', 'white');
						$(dc).append(d);

						$(d).css('font-size', '1.15em');
						$(d).css('background-color', 'white');
						$(d).css('color', '#000000');
						$(d).html(Utils.replaceGender(this.params.effect.text||'', AppStatus.SEPTEMBER_GENDER));

						this.divs[i] = {
							container: dc,
							div:d,
							classid: null,
							visible: false,
							fontSize: 0,
							text: null
						};
					}

					

					var renderWidth = renderer.getSize().width;
					var renderHeight = renderer.getSize().height;

					$(this.divs[i].container).css({
						'transform': 'translate('+((box[0]+box[2]/2)*renderWidth)+'px,'+((box[1]+box[3]/2)*renderHeight)+'px)',
						'width': 'auto',
						'height': 'auto'
					});
					if (!this.divs[i].visible) {
						$(this.divs[i].container).css('display', 'table');
						this.divs[i].visible = true;
					}
					// var tw = $(this.faceDivs[i].div).width();
					// var th = $(this.faceDivs[i].div).height();
					// var fontSize = 21.16;
					
					// // console.log(tw, w, th, h);
					// if (tw > w+1 || th > h+1) {
					// 	// console.log("fitting ratio", w/tw);
					// 	var ratio = Math.min( (w)/tw, (h)/th);
					// 	if (ratio < 0.075 && !this.params.faces.alwaysShow) {
					// 		$(this.faceDivs[i].div).html('');
					// 	} else {
					// 		ratio = Utils.clamp(ratio, 0.7/1.15, 1.0);
					// 		$(this.faceDivs[i].div).css('font-size', (1.15*ratio)+'em');
					// 		fontSize *= ratio;
					// 	}




				}

				for (var i=rects.length; i<this.divs.length; i++) {
					if (this.divs[i] && this.divs[i].visible) {
						$(this.divs[i].container).hide();
						this.divs[i].visible = false;
					}
				}	

			} else {
				for (var i=0; i<this.divs.length; i++) {
					if (this.divs[i] && this.divs[i].visible) {
						$(this.divs[i].container).hide();
						this.divs[i].visible = false;
					}
				}
			}
		}
	}

	dispose() {
		super.dispose();

	}

};

export default SpritesheetVideoTracking;


