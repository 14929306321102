import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import Fbo from '../utils/Fbo.js'
import MultiStorage from '../utils/MultiStorage.js';

import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';


import RendererController from '../controllers/RendererController.js';
import MaterialController from '../controllers/MaterialController.js';

import MottoView from './MottoView.js'

//------------------------
// 
//  Base class for content view
//  Handles webgl texture creating & disposing & fill options
//
//-------------------------
class PrebakedView extends MottoView {

	constructor(_info, batchName) {
		super(_info, batchName);

		this.fillType = this.params.fill;
		this.textBackground = this.info.textBackground;
		this.pageNumberWhite = true;
		if (!this.info.textBackground) {
			this.textBackground = this.info.dark ? '#000000' : '#ffffff';
		}
		// (this.info.textBackground || (this.info.textBackground==undefined && this.info.textBackgroundColor)) ? (this.info.textBackgroundColor || ( this.info.dark ? '#000000' : '#ffffff')) : '#ffffff';
		
	}

	start() {
		if (this.started) return;
		super.start();

		//
		// Create the webgl scene
		//
		this.texture = new THREE.Texture(Utils.emptyCanvas);
		this.texture.format = this.extension === 'png' ? THREE.RGBAFormat : THREE.RGBFormat;
		this.texture.type = THREE.UnsignedByteType;
		this.texture.wrapT = this.texture.wrapS = THREE.ClampToEdgeWrapping;
		this.texture.magFilter = THREE.LinearFilter;
		this.texture.minFilter = THREE.LinearFilter;
		this.texture.generateMipmaps = false;
		this.texture.anisotropy = 1;
		this.texture.needsUpdate = true;
		this.texture.wasUpdated = false;
		this.texture.loaded = false;

		this.scene = new THREE.Scene();
		this.plane = new THREE.Mesh(Utils.planeGeometry, MaterialController.getMaterial(this.extension === 'png' ? 'playback_rgba' : 'playback'));
		this.plane.material.uniforms.tDiffuse.value = this.texture;
		this.plane.material.uniforms.ratio.value.set(1,1);

		this.plane.scale.set(0.5,0.5,0.5);
		this.scene.add(this.plane);
		this.camera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, -0.5, 0.5);
	}

	stop() {
		if (!this.started) return;
		super.stop();
		this.texture.dispose();
		this.texture.needsUpdate = false;
		this.texture = null;

		this.scene = null;
		this.plane.material.dispose();
		this.plane = null;
		this.camera = null;
	}

	update() {
		if (!this.started) return;
		super.update();

		if (this.params.fill === 'center') {
			
			this.plane.scale.set(1, (this.videoHeight/this.videoWidth) * 1 * (this.renderWidth() / this.renderHeight()), 1.0);
			this.plane.scale.multiplyScalar(1.0 - (this.params.padding==undefined?0.1:this.params.padding));
			this.plane.material.uniforms.ratio.value.set(1,1);
			
		} else if (this.params.fill === 'square') {

			if (this.videoHeight > this.videoWidth) {
				this.plane.material.uniforms.ratio.value.set(1, this.videoWidth/this.videoHeight);
			} else {
				this.plane.material.uniforms.ratio.value.set(this.videoHeight/this.videoWidth, 1);
			}
			if (this.renderWidth() > this.renderHeight()) {
				this.plane.scale.set(1 / (this.renderWidth() / this.renderHeight()), 1, 1);
			} else {
				this.plane.scale.set(1, 1 / (this.renderHeight()/this.renderWidth()), 1);
			}
			this.plane.scale.multiplyScalar(1.0 - (this.params.padding==undefined?0.1:this.params.padding));

		} else {

			this.plane.material.uniforms.ratio.value.set(1,1);
			if (this.videoWidth / this.videoHeight > this.renderWidth() / this.renderHeight()) {
				this.plane.scale.set((this.videoWidth / this.videoHeight) / (this.renderWidth() / this.renderHeight()), 1, 1);
			} else {
				this.plane.scale.set(1, (this.videoHeight/this.videoWidth) / (this.renderHeight()/this.renderWidth()), 1);
			}
		}
	}

	render() {
		if (!this.active) return;
		super.render();

		//render main scene
		renderer.render(this.scene, this.camera, this.renderTarget, false);

	}
}

export default PrebakedView;	

