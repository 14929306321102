import PageLoader from '../loading/PageLoader.js';
import MottoView from './MottoView.js'

import PrebakedImageView from './PrebakedImageView.js'
import PrebakedVideoView from './PrebakedVideoView.js'
import RecordView from './RecordView.js'
import BranchView from './BranchView.js'
import SimpleBranchView from './SimpleBranchView.js'
import LoaderView from './LoaderView.js'
import CameraRefusedView from './CameraRefusedView.js'
import MontageViewReference from './MontageViewReference.js'
import MontageView from './MontageView.js'
import MontageSetView from './MontageSetView.js'
import MirrorMontageView from './MirrorMontageView.js'
import MontageBlendView from './MontageBlendView.js'
import MontageOrientationView from './MontageOrientationView.js'
import MontageCameraCoverView from './MontageCameraCoverView.js'
import MontageCameraSpinView from './MontageCameraSpinView.js'
import AllowCameraView from './AllowCameraView.js'
import LiveFeedView from './LiveFeedView.js'
import EndView from './EndView.js'
import ContinueLaterView from './ContinueLaterView.js'
import CameraSpinMotionDelayView from "./CameraSpinMotionDelayView.js";


MottoView.create = function (info, doneCallback, batchName) {

		if (!AppStatus.forceMainLoader) PageLoader.addPageLoadState((info.type == 'branch' || info.type == 'simple-branch'), info);

		batchName = batchName||'main';
		var t = null;
		switch (info.type) {
			case 'prebaked':
			if (info.params && info.params.image) {
				t = new PrebakedImageView(info, batchName);
			} else {
				t = new PrebakedVideoView(info, batchName);
			}
			break;

		case 'record':
			t = new RecordView(info, batchName);
			t.setupRecord(batchName);
			t.doneCallback = doneCallback;
			break;

		case 'branch':
			t = new BranchView(info, batchName, doneCallback);
			t.doneCallback = doneCallback;
			PageLoader.setBranchView(t);
			break;

		case 'simple-branch':
			t = new SimpleBranchView(info, batchName, doneCallback);
			t.doneCallback = doneCallback;
			PageLoader.setBranchView(t);
			break;

		case 'loader':
			t = new LoaderView(info, batchName, doneCallback);
			t.doneCallback = doneCallback;
			break;


		case 'camera-refused':
			t = new CameraRefusedView(info, batchName, doneCallback);
			t.doneCallback = doneCallback;
			break;

		// case 'activate-camera':
		// 	t = new ActivateCameraView(info, batchName, doneCallback);
		// 	t.doneCallback = doneCallback;
		// 	break;

		case 'montage':
			t = new MontageView(info, batchName);
			break;

		case 'montage-reference':
			t = new MontageViewReference(info, batchName);
			break;

		case 'montage-frozen':
			t = new MontageView(info, batchName);
			break;

		case 'montage-set':
			t = new MontageSetView(info, batchName);
			break;

		case 'montage-mirror':
			if (info && info.theEnd) {
				t = new EndView(info, batchName,doneCallback);
				t.doneCallback = doneCallback;
			} else {
				t = new MirrorMontageView(info, batchName);
			}
			break;

		case 'montage-blend':
			t = new MontageBlendView(info, batchName);
			break;

		case 'montage-tracking':
			t = new MontageBlendView(info, batchName);
			break;

		case 'montage-orientation':
			t = new MontageOrientationView(info, batchName);
			break;

		case 'montage-cameracover':
			t = new MontageCameraCoverView(info, batchName);
			break;

		case 'montage-cameraspin':
			t = new MontageCameraSpinView(info, batchName);
			break;


		case 'allowcamera':
			t = new AllowCameraView(info, batchName);
			t.doneCallback = doneCallback;
			break;

		case 'livefeed':
			t = new LiveFeedView(info, batchName);
			break;

		case 'the-end':
			t = new EndView(info, batchName, doneCallback);
			t.doneCallback = doneCallback;
			break;

		case 'continue-later':
			t = new ContinueLaterView(info, batchName, doneCallback);
			t.doneCallback = doneCallback;
			break;

		case 'cameraspin-motiondelay':
			t = new CameraSpinMotionDelayView(info, batchName);
			break;

		default:
			if (info && info.theEnd) {
				t = new EndView(info, batchName);
			} else {
				t = new MottoView(info, batchName);
			}
			break;
		}
		if (!AppStatus.forceMainLoader) PageLoader.popPageLoadState();
		return t;
	};

export default MottoView.create;

