//
// Subclasses creation
//
import SETTINGS from '../controllers/Settings.js';
import SpritesheetVideo from "./SpritesheetVideo.js"

import SpritesheetVideoArrow from "./SpritesheetVideoArrows.js"

import SpritesheetVideoPointAll from "./SpritesheetVideoPointAll.js"
import SpritesheetVideoBoxes from "./SpritesheetVideoBoxes.js"
import SpritesheetVideoBoxesSelect from "./SpritesheetVideoBoxesSelect.js"
import SpritesheetVideoDebugText from "./SpritesheetVideoDebugText.js"
import SpritesheetVideoIsolate from "./SpritesheetVideoIsolate.js"
import SpritesheetVideoDebugPoses from "./SpritesheetVideoDebugPoses.js"
import SpritesheetVideoDebugMask from "./SpritesheetVideoDebugMask.js"
import SpritesheetVideoText from "./SpritesheetVideoText.js"
import SpritesheetVideoTracking from "./SpritesheetVideoTracking.js"
import SpritesheetVideoOrientation from "./SpritesheetVideoOrientation.js"
import SpritesheetVideoMotionDelay from "./SpritesheetVideoMotionDelay.js"
import SpritesheetVideoDebugHands from "./SpritesheetVideoDebugHands.js"
import SpritesheetVideoZoom from "./SpritesheetVideoZoom.js"
import SpritesheetVideoRescale from "./SpritesheetVideoRescale.js"
import SpritesheetVideoOpticalFlow from "./SpritesheetVideoOpticalFlow.js"
import SpritesheetVideoFaceEditor from "./SpritesheetVideoFaceEditor.js"
import SpritesheetVideoOpticalFlowBlend from "./SpritesheetVideoOpticalFlowBlend.js"
import SpritesheetVideoFaceTrackingBlend from "./SpritesheetVideoFaceTrackingBlend.js"

function SpritesheetVideoCreate(params) {
	if (params && params.effect) {
		switch (params.effect.type) {

			case 'faceEditor':
				return new SpritesheetVideoFaceEditor();
				break;

			case 'opticalflow':
				return new SpritesheetVideoOpticalFlow();
				break;

			case 'opticalflow-blend':
				return new SpritesheetVideoOpticalFlowBlend();
				break;

			case 'face-tracking-blend':
				return new SpritesheetVideoFaceTrackingBlend();
				break;

			case 'arrow':
				return new SpritesheetVideoArrow();
				break;

			case 'SpritesheetVideoPointAll':
				return new SpritesheetVideoPointAll();
				break;

			case 'boxes':
				return new SpritesheetVideoBoxes();
				break;

			case 'boxesSelect':
				return new SpritesheetVideoBoxesSelect();
				break;

			case 'debugText':
				return new SpritesheetVideoDebugText();
				break;

			case 'isolate':
				return new SpritesheetVideoIsolate();
				break;

			case 'point_all':
				return new SpritesheetVideoPointAll();
				break;

			case 'debugPoses':
				return new SpritesheetVideoDebugPoses();
				break;

			case 'debugMask':
				return new SpritesheetVideoDebugMask();
				break;

			case 'text':
				return new SpritesheetVideoText();
				break;

			case 'tracking':
				return new SpritesheetVideoTracking();
				break;

			case 'orientation':
				return new SpritesheetVideoOrientation();
				break;

			case 'motionDelay':
				return new SpritesheetVideoMotionDelay();
				break;

			case 'debugHands':
				return new SpritesheetVideoDebugHands();
				break;

			case 'zoom':
				return new SpritesheetVideoZoom();
				break;

			case 'rescale':
				return new SpritesheetVideoRescale();
				break;

			case 'fingerSelect':
				var v = new SpritesheetVideoDebugHands();
				v.debug = false;
				return v;
				break;
		}
	}
	return new SpritesheetVideo();
};
SpritesheetVideo.create = SpritesheetVideoCreate;

export default SpritesheetVideoCreate;