import SETTINGS from './Settings.js';
import MpegDecodeController from './MpegDecodeController.js'
import FrameRecorder from './FrameRecorder.js'
import MenuController from './MenuController.js'
import RendererController from './RendererController.js'
import UserVideoController from './UserVideoController.js'
import CameraController from './CameraController.js'

import SpritesheetVideo from '../objects/SpritesheetVideo.js'
import FaceRenderer from '../objects/FaceRenderer.js'
import MultiStorage from '../utils/MultiStorage.js'


function AppStatus() {
	
	this.currentPage = 0;
	this.numPages = 0;
	
	this.draggedPage = false;
	this.targetPage = 0;


	this.currentPageDisplay = 0;
	this.numPageDisplay = 0;


	this.currentFrame = 0;
	this.goingBackwards = false;

	this.currentTaskId = 'in1';
	this.audioUnlocked = false;


	this.SEPTEMBER_GENDER = 0;
	this.NARRATOR = 0;
	this.DOUBLE_FEMININ = false;
	this.MAIN_LOADED = false;


	this.authToken = false;
	this.frameNeedsUpdate = true;

	this.forceUpdate = true;

	this.started = false;
	this.mainErrorCalled = [];

	this.restarting = false;



	this.landscapeMode = window.outerWidth > window.outerHeight && !SETTINGS.isTablet; //SETTINGS.isMobile && window.screen && window.screen.orientation && /landscape/.test(window.screen.orientation.type);;
	this.isLandscapeMode = function() {
		return ((window.orientation==90||window.orientation==-90) && window.outerWidth>window.outerHeight) && !SETTINGS.EDIT_MODE; //this.landscapeMode;
	};

	this.resize = function() {
		document.body.scrollTop=0;
		this.landscapeMode = ((window.orientation==90||window.orientation==-90) && window.outerWidth>window.outerHeight) && !SETTINGS.EDIT_MODE; //SETTINGS.isMobile && window.screen && window.screen.orientation && /landscape/.test(window.screen.orientation.type);;
		this.frameNeedsUpdate = true;
	}.bind(this);

	function resetScroll() {
		document.body.scrollTop=0;
	}
	document.body.onorientationchange = function() {
		console.log("orientation change");
		document.body.scrollTop=0;
		// MenuController.closeInfo();
		// MenuController.hide();
		setTimeout(resetScroll, 100);
		setTimeout(resetScroll, 300);
		setTimeout(resetScroll, 600);
		setTimeout(resetScroll, 2000);

		this.landscapeMode = ((window.orientation==90||window.orientation==-90) && window.outerWidth>window.outerHeight) && !SETTINGS.EDIT_MODE; //SETTINGS.isMobile && window.screen && window.screen.orientation && /landscape/.test(window.screen.orientation.type);;
		this.frameNeedsUpdate = true;
	}.bind(this);



	this.innerWidth = function() {
		return !this.orientationFlip ? window.innerWidth : window.innerHeight;
	};
	this.innerHeight = function() {
		return !this.orientationFlip ? window.innerHeight : window.innerWidth;
	};

	this.pageX = function() {
		return !this.orientationFlip ? ["pageX"] : ["pageY"];
	};
	this.pageY = function() {
		return !this.orientationFlip ? ["pageY"] : ["pageX"];
	};
	

	this.replaceGender = function(text) {
		var x = text;	
		if (this.DOUBLE_FEMININ) {
			var y = /\[\[(.*?)\]\]/gi;
			while((z=y.exec(text)) != null) {
				x = x.replace(z[0], (z[1].split('//')[1]||'').trim());
			}
		}
		var y = /\#(.*?)\#/gi;
		while((z=y.exec(text)) != null) {
			x = x.replace(z[0], (z[1].split('//')[this.NARRATOR_GENDER]||'').trim());
		}

		var y = /\[(.*?)\]/gi;
		while((z=y.exec(text)) != null) {
			x = x.replace(z[0], (z[1].split('//')[this.SEPTEMBER_GENDER]||'').trim());
		}
		return x;
	};


	//cleanup all memory-heavy modules before reloading
	this.cleanedUp = false;
	this.cleanup = function(e) {
		console.log(e);
		if (window.AppStatus.cleanedUp) return;
		console.log("Cleaning up webassembly and workers");

		//
		// Dispose all memory pools
		//
		if (SpritesheetVideo) SpritesheetVideo.cleanupFboPool();
		if (FaceRenderer) FaceRenderer.cleanupFboPool();
		if (MpegDecodeController) MpegDecodeController.disposeAll();
		if (RendererController) RendererController.dispose();

		if (FrameRecorder) {
			if (FrameRecorder.fbos) {
				for (var i=0; i<FrameRecorder.fbos.length; i++) {
					FrameRecorder.fbos[i].dispose();
					FrameRecorder.fbos[i] = null;
				}
				if (FrameRecorder.spritesheetVideo) FrameRecorder.spritesheetVideo.dispose();
				if (FrameRecorder.exportSpritesheet) FrameRecorder.exportSpritesheet.dispose();
				FrameRecorder.fbos = [];
			}
		}
		UserVideoController.dispose();

		CameraController.dispose();


		window.AppStatus.cleanedUp = true;
		if (window.cv) {
			window.cv.pauseMainLoop();
			window.cv.wasmMemory.buffer = undefined;
			window.cv.wasmMemory = undefined;
			window.cv.wasmTable = undefined;
			window.cv = null;
		}
		if (window.Module) {
			if (window.Module.wasmMemory) window.Module.wasmMemory.buffer = undefined;
			window.Module.wasmMemory = undefined;
			window.Module.wasmTable = undefined;
			window.Module = null;
		}

		if (window.FFMpegModule) {
			if (window.FFMpegModule.wasmMemory) window.FFMpegModule.wasmMemory.buffer = undefined;
			window.FFMpegModule.wasmMemory = undefined;
			window.FFMpegModule.wasmTable = undefined;
			window.FFMpegModule = null;
		}

		if (FrameRecorder.encodeWorker) {
			try {
				FrameRecorder.encodeWorker.postMessage({"cleanup": true});
				FrameRecorder.encodeWorker.terminate();
				FrameRecorder.encodeWorker = null;
			} catch(er){}
		}

		if (window.CvFlowModule) {
			window.CvFlowModule.dispose();
			if (window.CvFlowModule.wasmMemory) window.CvFlowModule.wasmMemory.buffer = undefined;
			window.CvFlowModule.wasmMemory = undefined;
			window.CvFlowModule.wasmTable = undefined;
			window.CvFlowModule = null;
		}

		if (window.MpegDecodeController) {
			if (MpegDecodeController.module) {
				try {
					MpegDecodeController.module.memory = null;
					MpegDecodeController.module.instance = null;
					MpegDecodeController.module = null;
					MpegDecodeController.wasmModule = null;
				} catch(er){}
			}
			window.MpegDecodeController = null;
		}
		if (window.Utils) {
			window.Utils.defaultPlane = window.Utils.defaultMaterial = null;
			window.Utils.whiteCanvas = null;
			window.Utils = undefined;
		}
		if (window.renderer) window.renderer.dispose();
		window.renderer = undefined;
		window.loader = window.Loader = null;
		window.MpegDecodeController = null;

		if (window.cleanup_tasks) window.cleanup_tasks(); else window.dispatchEvent(new Event('cleanup_tasks'));
	};

	//restart global state entirely but save language settings
	this.restart = function(target) {
		var globalState = MultiStorage.getGlobalState();
		var settings = globalState.settings;
		var language = SETTINGS.LANGUAGE;
		var cameraAllowedOnce = globalState.cameraAllowedOnce;
		globalState = {"settings":settings, "language": language, "cameraAllowedOnce":cameraAllowedOnce };
		globalState.lastAction = Date.now();
		globalState.manualChapterJump = true;
		globalState = Utils.clone(globalState);
		if (window.localStorage) window.localStorage.clear();
		MultiStorage.setGlobalState(globalState);
		// console.log("CLEARED local storage, added globalstate:",globalState);

		this.selectChapter('intro');
	};

	this.changeLanguage = function() {
		var globalState = MultiStorage.getGlobalState();
		SETTINGS.LANGUAGE = globalState.language = (SETTINGS.LANGUAGE == 'fr') ? 'en' : 'fr';
		globalState.lastAction = Date.now();
		globalState.manualChapterJump = true;
		MultiStorage.setGlobalState(globalState);
		document.cookie = ("language=" + globalState.language);

		this.selectChapter();

	}.bind(this);

	//reload page with a specific chapter
	this.selectChapter = function(target) {
		window.oneAlert = true;
		this.restarting = true;
		console.log("Select Chapter : ",target)

		window.AppStatus.cleanup();
		
		var queryParameters = window.location.search.substr(1).split('&').concat(window.location.hash.substr(1).split('&'));
		var params = {};
		var noParams = true;
		var found = false;
		var globalState = MultiStorage.getGlobalState();

		for (var i=0; i<queryParameters.length; i++) {
			var pair = queryParameters[i].split('=');
			if (pair[0] == 't') {
				queryParameters.splice(i,1); i--;
			} else if (queryParameters[i]) {
				noParams = false;
			}
		}

		if (!MultiStorage.localStorageEnabled) {
			noParams = false;
			
			if (globalState.language=='fr') {
				var gotlng = false;
				for (var i=0; i<queryParameters.length; i++) {
					if (/lng/.test(queryParameters)) {
						gotlng = true;
						queryParameters[i] = 'lng='+globalState.language;
					}
				}
				if (!gotlng) queryParameters.push('lng='+globalState.language)
			} else {
				for (var i=0; i<queryParameters.length; i++) {
					if (/lng/.test(queryParameters)) {
						queryParameters.splice(i,1); i--;
					}
				}
				if (queryParameters.length==0) noParams = true;
			}
		}

		var camera = false,
			lng = SETTINGS.LANGUAGE;
		var cameraAllowedOnce = false;
		if (target) {
			var globalState = MultiStorage.getGlobalState();
			globalState.taskId = target;
			globalState.reset = globalState.tasksFinished&&globalState.tasksFinished[globalState.taskId];
			globalState.lastAction = Date.now();
			cameraAllowedOnce = globalState.cameraAllowedOnce = globalState.cameraAllowedOnce;
			globalState.manualChapterJump = true;
			lng = globalState.language;
			camera =  globalState.cameraAllowedOnce;
			MultiStorage.setGlobalState(globalState);
		} else {
			var globalState = MultiStorage.getGlobalState();
			globalState.lastAction = Date.now();
			// globalState.manualChapterJump = true;
			cameraAllowedOnce = globalState.cameraAllowedOnce = globalState.cameraAllowedOnce;
			lng = globalState.language;
			camera =  globalState.cameraAllowedOnce;
			target = globalState.taskId;
			MultiStorage.setGlobalState(globalState);
		}


		// var ct = 0;
		// var ctb = 0;
		// if (!target || (target&&target!=='intro')) {
		// 	var localState = MultiStorage.getState();
		// 	ct = localState.currentTask;
		// 	ctb = localState.currentTaskInBranch;
		// }

		if (!noParams) {
			if (window.history && window.history.replaceState) window.history.replaceState({language:lng, camera:cameraAllowedOnce, currentTask:0, currentTaskInBranch:0, taskId:target}, 'Motto', window.location.pathname.replace('/','')+'?'+queryParameters.join('&'));
			window.location.search = queryParameters.join('&').replace('&&','&');
			setTimeout(function() {
				window.location.reload();
			},2000);
		} else {
			if (window.history && window.history.replaceState) window.history.replaceState({language:lng, camera:cameraAllowedOnce, currentTask:0, currentTaskInBranch:0, taskId:target}, 'Motto', window.location.pathname.replace('/',''));
			if (window.location.search) window.location.search = '';
			else if (/\?/.test(window.location.href)) window.location.href = window.location.href.replace('?','');
			else window.location.reload();
		}
	};

};

window.AppStatus = window.AppStatus || new AppStatus();
export default window.AppStatus;