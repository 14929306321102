import * as THREE from 'three';
import SETTINGS from '../controllers/Settings.js';

import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import RendererController from '../controllers/RendererController.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';

import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import SpritesheetVideo from './SpritesheetVideo.js';

// var CanvasTextWrapper = require('canvas-text-wrapper').CanvasTextWrapper;

//------------------------
class SpritesheetVideoBoxesSelect extends SpritesheetVideo {
	constructor(_info, batchName) {
		super(_info, batchName);
		this.boxesScene = new THREE.Scene();
		this.boxes = [];
		this.divs = [];

		//
		// Debug the class in the boxes
		//
		this.boxDebugPlanes = [];
		this.boxDebugCanvas = [];
		this.boxDebugContext = [];
		this.boxDebugTexture = [];

		this.selectedBox = false;
		this.selectedBoxId = 0;
	}

	preload(batchName) {
		super.preload(batchName);
	}

	prepare() {
		super.prepare();
	}

	onAnalysisLoaded(info) {
		super.onAnalysisLoaded(info);
	}

	stop() {
		for (var i=0; i<this.divs.length; i++) {
			if (this.divs[i]) {
				$(this.divs[i].container).remove();
				$(this.divs[i].div).remove();
			}
		}
		this.boxesScene = new THREE.Scene();
		this.boxes = [];
		this.divs = [];
		super.stop();
	}

	update(opt) {
		super.update(opt);

		if (this.isReady() && this.analysis && this.contentDiv) {

			//
			// Rank and select boxes
			//
			if (!this.selectedBox) {
				this.selectedBox = true;

				var minWidth = 10000;
				var minHeight = 10000;

				var sortableAnalysis = Utils.clone(this.analysis);
				sortableAnalysis.scores = [];
				var referenceRects = this.analysis.rectangles[Math.floor(this.analysis.rectangles.length/2)];
				for (var i=0; i<referenceRects.length; i++) {

					//add points for confidence
					sortableAnalysis.scores[i] = {s:this.analysis.confidences[0][i]*8.0, id:i, w:referenceRects[i][2], h:referenceRects[i][3]}

					if (this.params.effect.scores && this.params.effect.scores[this.analysis.classes[0][i]]) {
						console.log("SCORE", this.analysis.classes[0][i], this.params.effect.scores[this.analysis.classes[0][i]]);
						sortableAnalysis.scores[i].s += this.params.effect.scores[this.analysis.classes[0][i]];
					}
				}

				for (var cf=0; cf<this.analysis.rectangles.length; cf++) {
					var rects = this.analysis.rectangles[cf];

					for (var i=0; i<rects.length; i++) {
						//remove points for touching side
						var box = rects[i];
						if (!this.params.effect.sidesOff) {
							if (box[0]-box[2]*0.5 < 0.05) sortableAnalysis.scores[i].s -= 4.0 / this.analysis.rectangles.length;
							if (box[0]+box[2]*0.5 > 0.95) sortableAnalysis.scores[i].s -= 4.0 / this.analysis.rectangles.length;
							if (box[1]-box[3]*0.5 < 0.05) sortableAnalysis.scores[i].s -= 4.0 / this.analysis.rectangles.length;
							if (box[1]-box[3]*0.5 > 0.95) sortableAnalysis.scores[i].s -= 4.0 / this.analysis.rectangles.length;
				
						}
								
						//remove points for size of box, prefer smallest
						sortableAnalysis.scores[i].s -= box[2]*2.0 / this.analysis.rectangles.length;
						sortableAnalysis.scores[i].s -= box[3]*2.0 / this.analysis.rectangles.length;

						sortableAnalysis.scores[i].s -= Utils.ccmap(box[2],0.0,0.2,3.0,0.0) / this.analysis.rectangles.length;
						sortableAnalysis.scores[i].s -= Utils.ccmap(box[3],0.0,0.2,3.0,0.0) / this.analysis.rectangles.length;
					

						var ratio = (box[2]/box[3]);
						if (isNaN(ratio)) ratio = 0.5;
						sortableAnalysis.scores[i].s -= Math.min(Math.sqrt( Math.abs(1.0-ratio)), 1.0) * 1.0 / this.analysis.rectangles.length;
						// if (this.params.effect.preferCenter) {
							// var a = 0.5-box[0];
							// var b = 0.5-box[1];
							// sortableAnalysis.scores[i].s -= Math.sqrt(a*a+b*b)*10.0 / this.analysis.rectangles.length;
						// }
					}
				}

				// var sortedw = sortableAnalysis.scores.sort(function(a, b) { return b.w-a.w;})
				// var midw = sortedw[Math.floor(sortedw.length/2)].w;
				// var minw = sortedw[0].w;
				// var maxw = sortedw[sortedw.length-1].w;
				// midw = Utils.safecmap(midw, minw, maxw, 0.0, 1.0);
				// console.log(midw, sortedw[Math.floor(sortedw.length/2)].w, minw, maxw);
				// for (var i=0; i<sortedw.length; i++) {
				// 	sortedw[i].s -= Utils.safecmap(sortedw[i].w, minw+(maxw-minw)*0.5, maxw, 0.0, 2.0);
				// }


				// var sortedh = sortableAnalysis.scores.sort(function(a, b) { return b.w-a.w;})
				// var midh = sortedh[Math.floor(sortedh.length/2)].h;
				// var minh = sortedh[0].h;
				// var maxh = sortedh[sortedh.length-1].h;
				// midh = Utils.safecmap(midh, minh, maxh, 0.0, 1.0)
				// for (var i=0; i<sortedh.length; i++) {
				// 	sortedw[i].s -= Utils.safecmap(sortedw[i].h, minh+(maxh-minh)*0.5, maxh, 0.0, 2.0);
				// 	// sortedh[i].s -= (midh - Utils.safecmap(sortedh[i].h, midh, maxh, 0.0, 1.0))*5.0;
				// }

			
				var sorted = sortableAnalysis.scores.sort(function(a, b) { return b.s-a.s;})
				this.selectedBoxId = sorted[0].id;
				var maxScore = sorted[0].s;
				var minScore = sorted[sorted.length-1].s;

				this.analysis.scores = [];
				for (var i=0; i<sorted.length; i++) {
					this.analysis.scores[sorted[i].id] = Utils.cmap(sorted[i].s, minScore, maxScore, 0.0,1.0);
					// console.log(i,sorted[i].id, this.analysis.scores[sorted[i].id]);
				}

			}

			var currentFrame = this.lastFrame;

			var rects = this.analysis.rectangles[currentFrame+this.frameOffset];
			// console.log(this.analysis.rectangles[currentFrame+this.frameOffset], this.analysis.classes[currentFrame+this.frameOffset], this.analysis.ids[currentFrame+this.frameOffset], this.analysis.confidences[currentFrame+this.frameOffset])

			if (rects && rects.length) {
				for (var i=0; i<rects.length; i++) {
					var box = rects[i];

					if (!this.boxes[i]) {
						var mat = new THREE.LineBasicMaterial( { color: this.selectedBoxId==i?0xff0000:0x333333, linewidth: this.params.effect.lineWidth || 3, transparent: true, opacity: this.analysis.scores[i]*0.75+0.25, blending: THREE.NormalBlending } );
						var p = new THREE.LineSegments( new THREE.EdgesGeometry( Utils.planeGeometry ), mat );
						this.boxesScene.add(p);
						this.boxes[i] = p;

						if (this.params.effect.debug && this.selectedBoxId==i) {
							var dc = document.createElement('div');
							$(dc).addClass('spritesheet-overlay-text-container');
							$(dc).css('background', 'none');
							$(this.contentDiv).append(dc);

							var d = document.createElement('div');
							$(d).addClass('spritesheet-overlay-text');
							$(d).css('color', 'white');
							$(dc).append(d);

							// $(d).html(this.params.faces.text||'');
							this.divs[i] = {
								container: dc,
								div:d,
								classid: null,
								visible: false,
								fontSize: 0,
								text: null
							};
							// $(d).text(this.analysis.classes[currentFrame+this.frameOffset][i]);
						}
					}
					var p = this.boxes[i];
					p.scale.set(box[2], box[3],1.0);
					p.position.set(box[0]+box[2]/2,box[1]+box[3]/2, i==this.selectedBoxId?0.1:0.0);


					var scale = (this.params.effect.scale||1.0);
					// p.position.x -= box[2]*0.5*(scale-1.0);
					// p.position.y -= box[3]*0.5*(scale-1.0);

					p.scale.x *= scale;
					p.scale.y *= scale;

					p.scale.x = Math.max(p.scale.x,0.001);
					p.scale.y = Math.max(p.scale.y,0.001);


					// p.material.uniforms.tint.set(this.params.effect.color || '#ffffff');
					p.visible = true;
					if (this.params.effect.debug && this.divs[i]) {

						var classid = this.analysis.classes[currentFrame+this.frameOffset][i]||'class';
						if (this.divs[i].classid!==classid) {
							this.divs[i].classid = classid;
							$(this.divs[i].div).text(classid);
						}
						var renderWidth = renderer.getSize().width,
							renderHeight = renderer.getSize().height;
						if (SETTINGS.STORYBOARD_MODE) {
							renderWidth = 162;
							renderHeight = 288;
						}

						var w = (p.scale.x*renderWidth);
						var h = (Math.abs(p.scale.y)*renderHeight);

						var fontSize =  Utils.clamp((w / 15), '0.5', '1.1');
						$(this.divs[i].div).css('font-size', fontSize +'em');
						w = Math.max(w, $(this.divs[i].container).width());
						h = Math.max(h, $(this.divs[i].div).height());
						$(this.divs[i].container).css({
							'transform': 'translate('+((p.position.x)*renderWidth - w/2)+'px,'+((p.position.y)*renderHeight - h/2)+'px)',
							'width': w+'px',
							'height': h+'px'
						});

						if (!this.divs[i].visible) {
							$(this.divs[i].container).css('display', 'table');
							this.divs[i].visible = true;
						}
					}
				}

				for (var i=rects.length; i<this.boxes.length; i++) {
					this.boxes[i].visible = false;
					if (this.params.effect.debug) {
						if (this.boxDebugPlanes[i]) this.boxDebugPlanes[i].visible = false;
						if (this.divs[i] && this.divs[i].visible) {
							$(this.divs[i].container).hide();
							this.divs[i].visible = false;
						}
					}
				}	

			} else {
				for (var i=0; i<this.boxes.length; i++) {
					this.boxes[i].visible = false;
					if (this.params.effect.debug) {
						if (this.boxDebugPlanes[i]) this.boxDebugPlanes[i].visible = false;
						if (this.divs[i] && this.divs[i].visible) {
							$(this.divs[i].container).hide();
							this.divs[i].visible = false;
						}
					}
				}
			}
		}
	}

	dispose() {
		super.dispose();

	}

	render() {
		super.render();
		renderer.render(this.boxesScene, Utils.topLeftCamera, this.fbo.texture, false);

	}
};

export default SpritesheetVideoBoxesSelect;


