//
// Simple controller that helps updating textures on the gpu
// Distributes uploads on multiple frames with a maximum output speed
// Prevents lags and freezes as much as possible
//

import * as THREE from 'three';
import SETTINGS from './Settings.js';
import AppStatus from './AppStatus.js';

function TextureUpdateController() {

	var maximumPixelUploadRate = 1024 * 1024 * 1.5,
		lastTextureUpdateTime = 0,
		currentPixelUploadRate = 0,
		materialCompileFbo = null,

		totalNumPixels = 1,
		totalSpeed = 1,
		totalImagesUpdated = 0,
		totalImagesWaiting = [];

	this.setRate = function(rate) {
		maximumPixelUploadRate = rate;
	};

	this.shouldUpdateSpeed = function() {
		return totalImagesUpdated < 40;
	};

	this.getAverageUpdateSpeed = function() {
		return  (totalNumPixels / totalSpeed) / (1024);
	}
	this.canCalculateSpeed = function(callback) {
		// if (callback) callback.call();
		// return true;
		if (totalImagesUpdated>=40) {
			if (callback) callback.call();
			return true;
		}
		console.log('Waiting');
		if (callback) totalImagesWaiting.push(callback);
	};


	//
	//
	// Slowly update textures to the GPU with a fixed maximum rate
	//
	//
	this.update = function(texture, force) {
		if (!texture.image || (!texture.image.noDispose && !texture.image.src && !texture.image.mipmaps)) { return true;}
		var delta = (performance.now() - lastTextureUpdateTime) / 1000;
		lastTextureUpdateTime = performance.now();
		currentPixelUploadRate -= delta * maximumPixelUploadRate;
		currentPixelUploadRate = Math.max(currentPixelUploadRate,0);
		var pixelSize = texture.image.width * texture.image.height;
		pixelSize = pixelSize || (256*256);

		switch( texture.format) {
			case THREE.LuminanceFormat:
				pixelSize *= 1.0;
				break;

			case THREE.LuminanceAlphaFormat:
				pixelSize *= 2.0;
				break;

			case THREE.RGBFormat:
				pixelSize *= 3.0;
				break;

			case THREE.RGBAFormat:
				pixelSize *= 4.0;
				break;

			//case THREE.COMPRESSED_RGB_S3TC_DXT1_EXT:
			case THREE.RGB_S3TC_DXT1_Format:
				pixelSize *= 0.25;
				break;

			//case THREE.COMPRESSED_RGBA_S3TC_DXT3_EXT:
			case THREE.RGBA_S3TC_DXT3_Format:
				pixelSize *= 1.5;
				break;

			//case THREE.COMPRESSED_RGBA_S3TC_DXT5_EXT:
			case THREE.RGBA_S3TC_DXT5_Format:
				pixelSize *= 1.5;
				if (texture.image.mipmaps) {
					texture.mipmaps = texture.image.mipmaps;
					for (var i=0; i<texture.mipmaps.length; i++) {
						texture.mipmaps[i].format = THREE.RGBA_S3TC_DXT5_Format;
					}
				}
				texture.format = THREE.RGBA_S3TC_DXT5_Format;
				texture.magFilter = texture.minFilter = THREE.LinearFilter;
				texture.wrapS = texture.wrapT = THREE.ClampToEdgeWrapping;
				texture.generateMipmaps = false;
				break;

			case THREE.RGB_PVRTC_4BPPV1_Format:
				pixelSize *= 1.0;
				break;

			case THREE.RGBA_PVRTC_4BPPV1_Format:
				pixelSize *= 1.0;
				break;

			default:
				pixelSize *= 3.0;
				break;
		}

		if (currentPixelUploadRate <= maximumPixelUploadRate / 3 || force ) {

			if (!force) currentPixelUploadRate += Math.min(pixelSize,maximumPixelUploadRate*0.75);
			var t = performance.now();
			texture.needsUpdate = true;
			if (renderer.uploadTexture) renderer.uploadTexture(texture); else renderer.setTexture2D(texture,0);
			texture.needsUpdate = false;
			texture.wasUpdated = true;

			totalNumPixels += pixelSize;
			totalSpeed += (performance.now()-t);


			// if (SETTINGS.SHOULD_DISPOSE_MEMORY() && texture.image && texture.src && !texture.image.noDispose) {
			// 	texture.image.onload = texture.image.onerror = null;
			// 	var img = texture.image;
			// 	texture.image = {width:texture.image.width, height:texture.image.height, loaded: texture.image.loaded};
			// 	img.src = '';
			// }

			// totalImagesUpdated++;
			// if (totalImagesUpdated >= 40) {
			// 	while(totalImagesWaiting.length>0) {
			// 		console.log('waiting done');
			// 		totalImagesWaiting.pop().call();
			// 	}
			// }
			// this.getAverageUpdateSpeed();

			// console.log(performance.now()+' : Updating: ' + texture.image.path + " . NumPixels: "+pixelSize+". Took: "+(performance.now()-t));
			return true;
		}
		return false;
	};

	//
	// Add material creation to the update rate
	//
	this.updateMaterial = function(material, complexity, scene, camera) {

		if (!materialCompileFbo) {
			materialCompileFbo = new Fbo(1,1,{
				format:THREE.RGBAFormat,
				renderMaterial: material,
				type:THREE.UnsignedByteType,
				depthBuffer:false,
				stencilBuffer:false,
				premultiplyAlpha:false,
				generateMipmaps:false,
				renderer:renderer,
			});
		}
		
		var delta = (performance.now() - lastTextureUpdateTime) / 1000;
		lastTextureUpdateTime = performance.now();
		currentPixelUploadRate -= delta * maximumPixelUploadRate;
		currentPixelUploadRate = Math.max(currentPixelUploadRate,0);

		if (currentPixelUploadRate <= maximumPixelUploadRate / 3) {
			currentPixelUploadRate +=  maximumPixelUploadRate * complexity;
			var t = performance.now();
			material.needsUpdate = true;
			materialCompileFbo.setRenderMaterial(material);
			if (scene) {
				renderer.render(scene,camera,materialCompileFbo.texture,false);
			} else {
				materialCompileFbo.render();
			}
			// console.log(performance.now()+' : Updating material. Took: '+(performance.now()-t));
			return true;
		}
		return false;
	};

	//
	// Slowly create renderTargets
	//
	this.updateRenderTarget = function(fbo) {
		var delta = (performance.now() - lastTextureUpdateTime) / 1000;
		lastTextureUpdateTime = performance.now();
		currentPixelUploadRate -= delta * maximumPixelUploadRate;
		currentPixelUploadRate = Math.max(currentPixelUploadRate,0);
		var pixelSize = fbo.width * fbo.height * 0.5; //~~fbos are much faster to update than textures
		if (currentPixelUploadRate <= maximumPixelUploadRate / 3) {
			// console.log('fbo upload');
			currentPixelUploadRate += Math.min(pixelSize,maximumPixelUploadRate*0.75);
			renderer.setRenderTarget(fbo);
			return true;
		}
		return false;
	};


	this.setRate = function(rate) {
		maximumPixelUploadRate = rate;
	},


	//
	// Make faster/slower ~~~
	//
	this.setQuality = function(quality) {

		switch (quality) {
			case 0:
				maximumPixelUploadRate = 1024 * 1024 * 4 * 1;
				break;

			case 1:
				maximumPixelUploadRate = 1024 * 1024 * 4 * 2;
				break;

			case 2:
				maximumPixelUploadRate = 1024 * 1024 * 4 * 2.25;
				break;

			case 3:
				maximumPixelUploadRate = 1024 * 1024 * 4 * 2.5;
				break;

			case 4:
				maximumPixelUploadRate = 1024 * 1024 * 4 * 2.75;
				break;

			case 5:
				maximumPixelUploadRate = 1024 * 1024 * 4 * 3.0;
				break;

			case 6:
				maximumPixelUploadRate = 1024 * 1024 * 4 * 3.25;
				break;

			case 7:
				maximumPixelUploadRate = 1024 * 1024 * 4 * 3.5;
				break;
		}
	};
};

export default new TextureUpdateController();

