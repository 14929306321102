import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';
import Fbo from '../utils/Fbo.js'
import RendererController from '../controllers/RendererController.js';
import MultiStorage from '../utils/MultiStorage.js';
import CameraController from '../controllers/CameraController.js';
import OpticalFlowController from '../controllers/OpticalFlowController.js';

import MontageController from '../controllers/MontageController.js';
import Montage from '../objects/Montage.js';
import SpritesheetVideo from '../objects/SpritesheetVideo.js';
import SpritesheetLivefeed from '../objects/SpritesheetLivefeed.js';
import MaterialController from '../controllers/MaterialController.js';;


import MottoView from './MottoView.js';

var BLEND_MODES = {
	"normal": 0,
	"screen": 1,
	"multiply": 2,
	"add": 3,
	"difference": 4
};

//------------------------
// 
//  Base class for all views
//
//-------------------------
class MontageCameraCoverView extends MottoView {

	constructor(_info, batchName) {

		super(_info, batchName);

		this.textType = this.info["textType"] || 'top';
		this.uuid = Utils.generateUUID();
		// this.textBackground = (this.info.textBackground || (this.info.textBackground==undefined && this.info.textBackgroundColor)) ? (this.info.textBackgroundColor || ( this.info.dark ? '#000000' : '#ffffff')) : '#ffffff';
		this.textBackground = this.info.textBackground;
		this.pageNumberWhite = true;
		if (!this.info.textBackground) {
			this.textBackground = this.info.dark ? '#000000' : '#ffffff';
		}
		
		//
		// Add image to preloading queue
		//
		this.loaded = false;
		this.videoWidth = 288;
		this.videoHeight = 512;
		this.currentTime = 0;
		this.currentSpritesheet = 0;

		// Create all necessary spritesheets
		this.montage = new Montage(this.params, batchName); //MontageController.create(this.params);
		this.livefeed = new SpritesheetLivefeed(); //MontageController.create(this.params);
		this.livefeed.setup({"camera": "back"});
		this.hasLivefeed = true;
		this.livefeedDirection = 'back';
		if (this.montage.hasMotionDelay) this.pageNumberWhite = false;
	}


	//
	// When starting - decode image for rendering
	//
	start() {
		if (this.started) return;
		super.start();

		this.livefeedBlurMaterial = MaterialController.getMaterial('motionblur_trail');
		this.blendMaterial = MaterialController.getMaterial('cameracover_blend');

		
		this.blurFbo = new Fbo( 128, 128, {
			minFilter:THREE.LinearMipMapLinearFilter,
			magFilter:THREE.LinearFilter,
			format:THREE.RGBAFormat,
			type:THREE.UnsignedByteType,
			depthBuffer: false,
			stencilBuffer: false,
			premultiplyAlpha: false,
			generateMipmaps: true,
			forceClear: false,
			pingpong: false,
			renderer:renderer
		});

		//prepare spritesheets
		this.montage.replaceUserVideos();
		this.montage.prepare();
		this.livefeed.prepare();


		this.scene = new THREE.Scene();
		this.plane = new THREE.Mesh(Utils.planeGeometry, this.blendMaterial);
		this.scene.add(this.plane);
		this.camera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, -0.5, 0.5);


	}

	activate() {
		if (this.active) return;
		super.activate();

		//prepare spritesheets
		this.montage.replaceUserVideos();
		this.montage.prepare();
		this.montage.activate();
		this.livefeed.play();
		this.blendMaterial.uniforms.blend.value = 0.0;
		OpticalFlowController.activate();
		
	}

	//
	// When disposing - release image
	//
	stop() {
		if (!this.started) return;
		super.stop();

		this.livefeedBlurMaterial.dispose();
		this.blendMaterial.dispose();
		this.livefeed.stop();

		this.scene.remove(this.plane);
		this.plane = this.scene = this.camera = null;
		this.montage.dispose();

		this.blurFbo.dispose();
	}
	
	update() {
		if (!this.started) return;
		super.update();
		this.montage.contentDiv = this.contentDiv;
		this.montage.textDiv = this.textDiv;
		this.livefeed.contentDiv = this.contentDiv;

		//prepare spritesheets
		if (this.positionX > -0.001 || AppStatus.goingBackwards) {
			if (!this.active) {
				this.montage.replaceUserVideos();
			}
			this.montage.prepare();
		}
		this.ready = this.active && this.montage.isReady() && this.livefeed.isReady();


		//
		// Playback
		//
		if (this.active) {
			this.montage.update();
			OpticalFlowController.update({brightnessMode:true});
		}
		if (this.ready && this.active) {

			this.livefeed.update();
			AppStatus.frameNeedsUpdate = AppStatus.frameNeedsUpdate||this.montage.needsUpdate||this.livefeed.needsUpdate;

			this.plane.material = this.blendMaterial;
			this.blendMaterial.uniforms.tDiffuse.value = this.montage.getTexture();
			this.blendMaterial.uniforms.tCamera.value = this.livefeed.getTexture();
			this.blendMaterial.uniforms.tBlur.value = this.blurFbo.texture.texture;


			this.blendMaterial.uniforms.blend.value = this.blendMaterial.uniforms.blend.value * 0.9 + 0.1 * Utils.ccmap(OpticalFlowController.totalBrightness, 17, 22, 1.0, 0.0);
			this.blendMaterial.uniforms.blurpc.value = this.blendMaterial.uniforms.blurpc.value * 0.95 + 0.05 * (1.0-this.blendMaterial.uniforms.blend.value);
			if (this.blendMaterial.uniforms.blurpc.value < 1.0-this.blendMaterial.uniforms.blend.value) this.blendMaterial.uniforms.blurpc.value =  1.0-this.blendMaterial.uniforms.blend.value;

			var targetExposure = Utils.ccmap(this.blendMaterial.uniforms.blend.value, 0.0, 1.0, this.params.exposureMin!==undefined?this.params.exposureMin:0.0, this.params.exposureMax||1.1);
			if (isNaN(targetExposure)) targetExposure = 1.0;
			this.blendMaterial.uniforms.exposure.value = this.blendMaterial.uniforms.exposure.value * 0.95 + 0.05 * targetExposure;
			// $(this.textDiv).html(OpticalFlowController.totalBrightness.toPrecision(3));
			
			BranchController.cameraCoverDone = BranchController.cameraCoverDone || OpticalFlowController.totalBrightness <= 14;
			

			//update ratio
			var ratio = this.montage.getRatio();

			if (this.params.fill === 'center') {
		
				this.plane.scale.set(1, ratio * 1 * (this.renderWidth() / this.renderHeight()), 1.0);
				this.plane.scale.multiplyScalar(1.0 - (this.params.padding==undefined?0.1:this.params.padding));
				this.plane.material.uniforms.ratio.value.set(1,1);
				
			} else if (this.params.fill === 'square') {

				if (ratio > 1.0) {
					this.plane.material.uniforms.ratio.value.set(1, this.videoWidth/this.videoHeight);
				} else {
					this.plane.material.uniforms.ratio.value.set(this.videoHeight/this.videoWidth, 1);
				}
				if (this.renderWidth() > this.renderHeight()) {
					this.plane.scale.set(1 / (this.renderWidth() / this.renderHeight()), 1, 1);
				} else {
					this.plane.scale.set(1, 1 / (this.renderHeight()/this.renderWidth()), 1);
				}
				this.plane.scale.multiplyScalar(1.0 - (this.params.padding==undefined?0.1:this.params.padding));

			} else {

				this.plane.material.uniforms.ratio.value.set(1,1);
				if ( (1/ratio) > this.renderWidth() / this.renderHeight()) {
					this.plane.scale.set((1/ratio) / (this.renderWidth() / this.renderHeight()), 1, 1);
				} else {
					this.plane.scale.set(1, (ratio) / (this.renderHeight()/this.renderWidth()), 1);
				}
			}
		}
	}

	//
	// Render image to webgl??
	//
	render() {
		if (!this.active) return;
		super.render();

		this.livefeed.render();
		this.montage.render();
		Utils.renderTexture(this.montage.getTexture(),this.blurFbo.texture);
		renderer.render(this.scene, this.camera, this.renderTarget, false);
	}


	//outside access
	isReady() {
		return super.isReady() && this.ready;
	}
}

export default MontageCameraCoverView;	

