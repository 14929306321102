import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';
import Fbo from '../utils/Fbo.js'
import RendererController from '../controllers/RendererController.js';
import MultiStorage from '../utils/MultiStorage.js';

import MontageController from '../controllers/MontageController.js';
import Montage from '../objects/Montage.js';
import SpritesheetVideo from '../objects/SpritesheetVideo.js';
import MaterialController from '../controllers/MaterialController.js';;


import MottoView from './MottoView.js';
import MontageView from './MontageView.js'


//------------------------
// 
//  Base class for all views
//
//-------------------------
class MontageViewReference extends MontageView {

	constructor(_info, batchName) {

		super(_info, batchName);
		this.pageNumberWhite = true;


		if (this.params.reference) {
			this.referenceTexture = Loader.addTexture(batchName, SETTINGS.UI_IMAGES_URL+this.params.reference, {
				format: THREE.RGBAFormat,
				wrapping: THREE.ClampToEdgeWrapping,
				generateMipmaps: false,
				minFilter: THREE.LinearFilter
			});

		}
	}


	//
	// When starting - decode image for rendering
	//
	start() {
		if (this.started) return;
		super.start();

		if (this.params.mirror) {
			this.playbackMaterial = MaterialController.getMaterial('mirror');
			this.plane.material = this.playbackMaterial;
			this.playbackMaterial.uniforms.tDiffuse.value = Utils.whiteTexture;
		}
		
		if (this.params.reference) {
			this.referenceScene = new THREE.Scene();
			this.referenceMaterial = MaterialController.getMaterial('playback_transparent');
			this.referencePlane = new THREE.Mesh(Utils.planeGeometry, this.referenceMaterial);
			this.referenceMaterial.uniforms.tDiffuse.value = Utils.whiteTexture;
			this.referenceScene.add(this.referencePlane);
		}
	}


	//
	// When disposing - release image
	//
	stop() {
		if (!this.started) return;
		super.stop();

		if (this.referenceTexture) this.referenceTexture.dispose();
		if (this.params.reference) {
			this.referenceMaterial.dispose();
			this.referenceScene.remove(this.referencePlane);
			this.referenceScene = this.referencePlane = this.referenceMaterial = null;
		}
	}
	
	update() {
		if (!this.started) return;
		super.update();
		
		//
		// Playback
		//
		if (this.ready) {

			this.referenceMaterial.uniforms.tDiffuse.value = this.referenceTexture;

			//update ratio
			var ratio = this.referenceTexture.image.height/this.referenceTexture.image.width,
				fill = this.params.referenceFill || this.montage.overrideFill || this.params.fill,
				padding = this.montage.overridePadding !== undefined ? this.montage.overridePadding : this.params.padding;

			if (this.params.referencePadding!==undefined) {
				padding = this.params.referencePadding;
			}

			if (fill === 'center') {
		
				this.referencePlane.scale.set(1, ratio * 1 * (this.renderWidth() / this.renderHeight()), 1.0);
				this.referencePlane.scale.multiplyScalar(1.0 - (padding==undefined?0.1:padding));
				this.referencePlane.material.uniforms.ratio.value.set(1,1);
				console.log(1, ratio * 1 * (this.renderWidth() / this.renderHeight()), 1.0, 1.0 - (padding==undefined?0.1:padding));
				// console.log("WTFC", this.referencePlane.scale, padding, ratio, ratio * 1 * (this.renderWidth() / this.renderHeight()))

			} else if (fill === 'square') {

				if (ratio > 1.0) {
					this.referencePlane.material.uniforms.ratio.value.set(1, this.videoWidth/this.videoHeight);
				} else {
					this.referencePlane.material.uniforms.ratio.value.set(this.videoHeight/this.videoWidth, 1);
				}
				if (this.renderWidth() > this.renderHeight()) {
					this.referencePlane.scale.set(1 / (this.renderWidth() / this.renderHeight()), 1, 1);
				} else {
					this.referencePlane.scale.set(1, 1 / (this.renderHeight()/this.renderWidth()), 1);
				}
				this.referencePlane.scale.multiplyScalar(1.0 - (padding==undefined?0.1:padding));

			} else {

				this.referencePlane.material.uniforms.ratio.value.set(1,1);
				if ( (1/ratio) > this.renderWidth() / this.renderHeight()) {
					this.referencePlane.scale.set((1/ratio) / (this.renderWidth() / this.renderHeight()), 1, 1);
				} else {
					this.referencePlane.scale.set(1, (ratio) / (this.renderHeight()/this.renderWidth()), 1);
				}
				
			}

			// console.log(this.referencePlane.scale, padding, ratio,fill, this.referenceTexture.image.width, this.referenceTexture.image.height, this.renderHeight(), this.renderWidth());
		}
	}

	//
	// Render image to webgl??
	//
	render() {
		if (!this.active) return;
		super.render();
		renderer.render(this.referenceScene, this.camera, this.renderTarget, false);

	}



	//outside access
	isReady() {
		return super.isReady() && this.ready && this.referenceTexture.loaded;
	}
}

export default MontageViewReference;	

