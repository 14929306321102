import SETTINGS from '../controllers/Settings.js';
import * as THREE from 'three';

import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import RendererController from '../controllers/RendererController.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';

import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import SpritesheetVideo from './SpritesheetVideo.js';

var layerColors = ['#ff0000', '#00ff00', '#0000ff'];

//------------------------
class SpritesheetVideoMotionDelay extends SpritesheetVideo {
	constructor(_info, batchName) {
		super(_info, batchName);
		
		this.hasMotionDelay = true;
		this.currentMotionTime = 0.0;
		this.lastMotionTime = performance.now();
	}

	setParams(_params, _frames, _framesType) {
		super.setParams(_params, _frames, _framesType);
		if (this.plane) {

			this.plane.material = this.material = MaterialController.getMaterial('spritesheet_motion_delay', {
				TEXTURES_INVERT_MODE: this.framesType=='texture' ? 1 : 0,
				INVERT_COLORS: (this.params.effect.invert||this.params.invertColors) ? 1 : 0
			});

			if (this.params.effect.colors) {
				this.plane.material.uniforms.colorA.value.set(this.params.effect.colors[0]);
				this.plane.material.uniforms.colorB.value.set(this.params.effect.colors[1]);
				this.plane.material.uniforms.colorC.value.set(this.params.effect.colors[2]);
			} else {
				this.plane.material.uniforms.colorA.value.set(0xff00ff);
				this.plane.material.uniforms.colorB.value.set(0x00ffff);
				this.plane.material.uniforms.colorC.value.set(0xffff00);
			}
		}
		this.noFaces = !!this.params.effect.faces;
		this.motionFaces = !!this.params.effect.faces;
		this.facesInfo = this.params.facesInfo;	

		if (this.motionFaces) {
			this.facePlanes = [];
			this.faceScene = new THREE.Scene();
		}
	}

	preload(batchName) {
		super.preload(batchName);
	}
	
	prepare() {
		super.prepare();
		if (!this.effectFbo) this.effectFbo = SpritesheetVideo.getFbo(this.videoWidth, this.videoHeight);
	}

	play(contentDiv, textDiv) {
		super.play(contentDiv, textDiv);
		this.lastMotionTime = performance.now();
	}

	pause() {
		super.pause();
		this.lastMotionTime = performance.now();
	}

	stop() {
		// this.effectMaterial = null;
		this.animationStarted = false;
		this.frameOffset2 = 0;
		this.frameOffset3 = 0;
		this.animationStartTime = 0.0;
		this.animationPc = 0.0;
		this.currentMotionTime = 0.0;
		super.stop();
	}
	
	update(opt) {
		super.update(opt);

		if (this.isReady()) {
			this.needsUpdate = true;
				
			if (this.isShowing && !this.animationStarted) {
				this.animationStarted = true;
				this.animationPc = 0.0;
				this.animationStartTime = performance.now()+(this.params.effect.animationDelay||0)*1000;
			}
			this.animationPc = !this.params.effect.animation ? 1.0 : Math.pow(Utils.cmap(performance.now(), this.animationStartTime+this.params.effect.animation*1000*0.25, this.animationStartTime+this.params.effect.animation*1000, 0.0, 1.0),2.0);
			if (isNaN(this.animationPc)) this.animationPc = 1.0;
	


			//--------------
			//
			// frameOffset
			//
			//--------------
			var numFramesTotal = Math.floor(this.numFrames); //this.frameLimit
			var divw = this.numFramesX;
			var divh = this.numFramesY;

			if (this.playing && this.isShowing) this.currentMotionTime += (performance.now()-this.lastMotionTime) / 1000 * this.videoSpeed;
			this.lastMotionTime = performance.now();


			var currentFrame = 0;
			if (this.palindrome) {
				var absoluteFrame = this.currentMotionTime * this.frameRate;
				currentFrame = absoluteFrame % numFramesTotal;
				if (Math.floor(absoluteFrame/numFramesTotal) % 2 == 1) currentFrame = (numFramesTotal-currentFrame);
			} else {
				currentFrame = (this.currentMotionTime * this.frameRate) % numFramesTotal;
			}

			if (this.params.reverse) {
				currentFrame = Utils.clamp(numFramesTotal - 1 - currentFrame, 0, numFramesTotal-1);
			}

			var cf = Math.floor(currentFrame);
			var frameInSheet = cf % this.numFramesPerSpritesheet;
			var frameX = frameInSheet%divw;
			var frameY = Math.floor( frameInSheet / divw );
			this.plane.material.uniforms.tDiffuse.value = this.textures[ Math.floor( cf / this.numFramesPerSpritesheet ) ];	

			this.plane.material.uniforms.spritesheetOffset.value.x = (1.0/divw * frameX);
			this.plane.material.uniforms.spritesheetOffset.value.y = 1.0-(1.0/divh * frameY) - 1.0/divh;


			//--------------
			//
			// frameOffset 2
			//
			//--------------
			var frameOffset2 = Utils.ccmap(this.animationPc,0.0,0.5,0.0,(this.params.effect.delay/2||(this.duration/3)));
			var currentFrame2 = 0;

			if (this.palindrome) {
				var absoluteFrame = Math.max((this.currentMotionTime - frameOffset2)*this.frameRate, 0);
				currentFrame2 = absoluteFrame % numFramesTotal;
				if (Math.floor(absoluteFrame/numFramesTotal) % 2 == 1) currentFrame2 = (numFramesTotal-currentFrame2);
			} else {
				currentFrame2 = Math.max((this.currentMotionTime - frameOffset2)*this.frameRate, 0) % numFramesTotal;
			}
			var cf = Math.floor(currentFrame2);
			var frameInSheet = cf % this.numFramesPerSpritesheet;
			var frameX = frameInSheet%divw;
			var frameY = Math.floor( frameInSheet / divw );
			this.plane.material.uniforms.tDiffuse2.value = this.textures[ Math.floor( cf / this.numFramesPerSpritesheet ) ];	

			this.plane.material.uniforms.spritesheetOffset2.value.x = (1.0/divw * frameX);
			this.plane.material.uniforms.spritesheetOffset2.value.y = 1.0-(1.0/divh * frameY) - 1.0/divh;
			

			//--------------
			//
			// frameOffset 3
			//
			//--------------
			var frameOffset3 = Utils.ccmap(this.animationPc,0.0,1.0,0.0,(this.params.effect.delay||(this.duration*2/3)));
			var currentFrame3 = 0;
			frameOffset3 = Math.max(frameOffset2, frameOffset3);

			if (this.palindrome) {
				var absoluteFrame = Math.max((this.currentMotionTime - frameOffset3)*this.frameRate, 0);
				currentFrame3 = absoluteFrame % numFramesTotal;
				if (Math.floor(absoluteFrame/numFramesTotal) % 2 == 1) currentFrame3 = (numFramesTotal-currentFrame3);
			} else {
				currentFrame3 = Math.max((this.currentMotionTime - frameOffset3)*this.frameRate, 0) % numFramesTotal;
			}
			var cf = Math.floor(currentFrame3);
			var frameInSheet = cf % this.numFramesPerSpritesheet;
			var frameX = frameInSheet%divw;
			var frameY = Math.floor( frameInSheet / divw );
			this.plane.material.uniforms.tDiffuse3.value = this.textures[ Math.floor( cf / this.numFramesPerSpritesheet ) ];	

			this.plane.material.uniforms.spritesheetOffset3.value.x = (1.0/divw * frameX);
			this.plane.material.uniforms.spritesheetOffset3.value.y = 1.0-(1.0/divh * frameY) - 1.0/divh;


			this.plane.material.uniforms.tFaces.value = Utils.blackTexture;
			// console.log(currentFrame, currentFrame2, currentFrame3);


			//==========================
			//
			// Update Faces
			//
			//===========================
			// return;
			if (this.motionFaces) {
				var nf = !!(this.params.noFaces || (this.params.faces && this.params.faces.disabled));
				if (!this.facesInfo || nf) return;
				if (!this.facesInfo.faces) return;
				this.firstFace = false;
				this.updateFaceIndividual(Math.floor(currentFrame), 0);
				this.updateFaceIndividual(Math.floor(currentFrame2), 1);
				this.updateFaceIndividual(Math.floor(currentFrame3), 2);
				this.plane.material.uniforms.tFaces.value = this.effectFbo.texture.texture;
			}
			

		}
	}

	updateFaceIndividual(currentFrame, layer) {
		var info = this.facesInfo;
		this.facePlanes[layer] = this.facePlanes[layer]||[];
		var fp = this.facePlanes[layer];
		var faces = info.faces[currentFrame+this.frameOffset];
		if (faces && faces.length) {

			for (var i=0; i<faces.length; i++) {
				var box = faces[i].face;
				if (!fp[i]) {
					var p = new THREE.Mesh(Utils.planeGeometryFace, new THREE.MeshBasicMaterial({
						color:layerColors[layer],
						blending:THREE.AdditiveBlending,
						side: THREE.DoubleSide,
						depthTest: false,
						depthWrite: false,
						transparent: true
					}));
					// p.material.uniforms.tint.value = new THREE.Color('#ff0000');
					// p.material.blending = THREE.AdditiveBlending;
					// p.material.depthTest = false;
					// p.material.needsUpdate = true;
					p.visible = false;
					this.faceScene.add(p);
					fp[i] = p;
					this.hasFaces = true;
				}
				
				var p = fp[i];
				p.scale.set(box[2],-box[3] * 0.5,1.0);
				p.scale.multiplyScalar( 0.9 * (this.params.faces.scale||1.0));
				p.position.set(box[0],box[1], 0);
				// p.material.uniforms.tint.value.set(layerColors[layer]);
				p.visible = this.params.faces.type !== 'text';
			}
		}
		for (var i=faces?(faces.length||0):0; i<fp.length; i++) {
			fp[i].visible = false;
		}
	}

	prepare() {
		super.prepare();
		if (!this.effectFbo) this.effectFbo = SpritesheetVideo.getFbo(this.videoWidth, this.videoHeight);
	}

	dispose() {
		super.dispose();

		if (this.effectFbo) {
			SpritesheetVideo.disposeFbo(this.effectFbo);
			this.effectFbo = null;
		}
		if (this.effectMaterial) {
			this.effectMaterial.dispose();
			this.effectMaterial = null;
		}
	}

	render() {
		if (this.effectFbo && this.faceScene) renderer.render(this.faceScene, Utils.topLeftCamera, this.effectFbo.texture, true);
		super.render();
	}


	// getTexture() {
	// 	if (!this.effectFbo || !this.fbo || !this.isReady()) return Utils.blackTexture;
	// 	return this.effectFbo.texture.texture;
	// }
};

export default SpritesheetVideoMotionDelay;


