//import libraries
import $ from 'jquery';
const THREE = require('three');
import JSON5 from 'json5';
// import NoSleep from 'nosleep';
import './utils/polyfills.js'
window.audioContext = window.audioContext || new AudioContext();

//import utils
import Utils from './utils/Utils.js'
import MultiStorage from './utils/MultiStorage.js'

//import global controllers
import SETTINGS from './controllers/Settings.js';
SETTINGS.init();

import AppStatus from './controllers/AppStatus.js';
import RendererController from './controllers/RendererController.js';
import MaterialController from './controllers/MaterialController.js';
import CameraController from './controllers/CameraController.js';
import MontageController from './controllers/MontageController.js';
import AnalysisController from './controllers/AnalysisController.js';
import FrameRecorder from './controllers/FrameRecorder.js';
import MpegDecodeController from './controllers/MpegDecodeController.js';
import OpticalFlowController from './controllers/OpticalFlowController.js';
import MenuController from './controllers/MenuController.js';
import MotionController from './controllers/MotionController.js';
import EmbeddedText from './controllers/EmbeddedText.js';
import AnalyticsController from './controllers/AnalyticsController.js';

import SpritesheetVideoCreate from './objects/SpritesheetVideoCreate.js';
import SpritesheetVideo from './objects/SpritesheetVideo.js';
SpritesheetVideo.create = SpritesheetVideoCreate;

import MottoView from './views/MottoView.js'
import MottoViewCreate from './views/MottoViewCreate.js'
import AllowCameraView from './views/AllowCameraView.js'
import MontageView from './views/MontageView.js'

import Montage from './objects/Montage.js';
import Loader from './loading/Loader.js';
import PageLoader from './loading/PageLoader.js';

import FastClick from 'fastclick';


//expose some libraries to window
window.jQuery = window.$ = $;
(function() {


	var logosShown = false;
	if (SETTINGS.SKIP_LOGOS) {
		$('#loader').show();
		$('#logos-container').remove();
		$('#logos-background').remove();
		logosShown = false;
	} else {
		logosShown = true;

		if (SETTINGS.LANGUAGE == 'fr') {
			$('#produced-by').html('Une production de');
			$('#created-by').html('Une création de');
		}

		$('#loader').toggleClass('fadein', true);
		$('#logos-container').toggleClass('shown', true);
		$('#logos-background').toggleClass('shown', true);
		$('#logos-container').bind('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function(e) { $(this).remove(); logosShown = false;});
		$('#logos-background').bind('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function(e) { $(this).remove(); logosShown = false;});
	}

	if (!SETTINGS.isMobile && navigator.userAgent.match('Safari') || (window.location.protocol !== 'https:' && !(/127.0.0|localhost|192.168./.test(window.location.host)))) {
		navigator.getUserMedia = null;
	}
	if (!window.location.protocol.startsWith('https') && window.location.port !== "8080") {
		window.location.protocol = 'https:';
		return;
	}
	if (SETTINGS.BETA_MODE && !SETTINGS.isMobile) {
		$('#loader-txt').show();
		$('#loader-txt').text('Please load this page on your mobile phone.');
		return;
	}
	if (SETTINGS.NO_TASK || SETTINGS.BLOCK_ACCESS) {
		$('#loader-txt').show();
		$('#loader-txt').text('Error : wrong URL');
		window.alert("Error: this is not the right URL");
		return;
	}

	//-----------------------
	//  
	//  Main variables
	//  
	//-----------------------
	var INTRO_NUM_TASKS = 16,
		INTRO_ALLOW_CAMERA = 29,
		// INTRO_NUM_TASKS = 29,
		waitingForAudioUnlock = false,
		audioUnlocked = !SETTINGS.isMobile,
		currentTask = 0,
		currentTaskInBranch = 0,
		taskInfo,
		taskInfoIn,
		taskInfoOut,
		lockedRight = true,
		lockedLeft = true,
		disposed = false,
		tappingMode = true,
		mainLoopStarted = false,
		lastTask = -1,
		lastTaskInBranch = -1,
		manualChapterJump = false,
		lastDragTime = 0,

		shouldAllowCameraIdInBranch = 1,
		shouldAllowCameraId = 1,
		shouldAllowCamera = false,
		shouldAllowCameraNext = false,
		shouldAllowCameraPrev = 0,
		shouldAllowCameraDirection = 1,
		shouldWelcomeBack = false,
		allowCameraTask = null,
		welcomeBackTask = null,
		allowCameraAdded = false,
		allowCameraId = 1,
		allowCameraNext = 'back',
		firstView = 0,

		errorSent = false,
		returnSessionSent = false,

		isOnFirstTask = true,
		branches = [],
		allViews = [],
		loaderViewId = 999999,
		tasks = [],
		branchInfos = {},
		videolistXHR,
		orientationWarningShown = false,
		orientationTimeout = -1,

		//dragging & interaction
		touchDownX = 0,
		dragOffset = 0.0,
		lastDragOffset = 0.0,

		realTouchDownX = 0,
		realDragOffset = 0,

		dragOffsetOffset = 0.0,
		positionOffset = 0.0,
		lastPositionOffset = 0.0,
		dragSpeed = 0.9,

		prevAnimationPc = 0.0,
		prevAnimationStarted = false,
		prevQueued = false,
		lastTouchStart = 0,
		currentView = null,
		lastCurrentView = null,
		sessionXHR,
		firstSetView = true,

		numPages = 0,
		currentBranch = null,
		recalculateNumPages = true,
		currentPageNumber = 0,
		currentPageNumberView = 0,
		currentBranchForPage = null,
		lastBranchForPage = null,


		activeViews = window.Map ? new Map() : null,
		isDragging = false,
		noSleep = null,
		isSnapping = false,
		snapSpeed = 0.0;

	var beginSent= false;


	//-----------------------
	//
	// Setup
	//
	//-----------------------		
	function start() {
		var error = errorCheck();
		if (errorCheck()) {
			SETTINGS.ERROR_MODE = error;
			if (mainError(error)) {
				return;
			}
		}
		resize();


		// if (window.history && window.history.state && window.history.state.currentTask) { //} && SETTINGS.tasklist_id.replace(/\.json|tasks_/gi, '')==window.history.state.currentTaskId) {
		// 	console.log("Setting currentTask from history state");
		// 	currentTask = window.history.state.currentTask||0;
		// 	currentTaskInBranch = window.history.state.currentTaskInBranch||0;
		// }
		if (!SETTINGS.isIOS) $('#usermedia').hide().remove();

		//fullscreen on small androids
		if (SETTINGS.FULLSCREEN || (SETTINGS.isMobile && SETTINGS.isAndroid && Math.max(AppStatus.innerWidth(), AppStatus.innerHeight()) < 460 && (SETTINGS.BETA_MODE || SETTINGS.RELEASE_MODE))) {
			$(document).one('click', function (event) {
				try {
					if (document.body.requestFullscreen) document.body.requestFullscreen();
				} catch (er) {console.log(er);}
				if (window.screen && window.screen.orientation) {
					try {
			    		window.screen.orientation.lock('portrait-primary').catch(function(){window.screen.orientation.lock('portrait').catch(function(){})});
					} catch(er) {}
				}
			});
		}
		if (window.screen && window.screen.orientation) {
			try {
	    		window.screen.orientation.lock('portrait-primary').catch(function(){window.screen.orientation.lock('portrait').catch(function(){})});
			} catch(er) {}
		}

		//-------------------
		//
		// Init controllers
		//
		//-------------------
		RendererController.init();
		MaterialController.init();
		CameraController.init();
		SpritesheetVideo.init();
		MontageController.init();
		BranchController.init();
		
		var tasksState = MultiStorage.getGlobalState();
		for (var id in tasksState.analysis) {
			AnalysisController.addAnalysis(id, tasksState.analysis[id]);
		}

		//preload info
		if (SETTINGS.ERROR_MODE) {

			loadInfo();

		} else {

			Loader.createBatch('info');

			if (SETTINGS.BETA_ID && SETTINGS.BETA_MODE) {
				Loader.addScript('info', SETTINGS.USERS_URL);
			}

			if (!SETTINGS.INTRO_MODE) {
				taskInfo = Loader.addXHR('info', SETTINGS.TASKLIST_URL+SETTINGS.tasklist_id_json, 'json5', !SETTINGS.RELEASE_MODE || /dev/.test(window.location.href)); //SETTINGS.DATA_URL+''+SETTINGS.tasklist_id
			} else {
				taskInfo = taskInfoIn = Loader.addXHR('info', SETTINGS.TASKLIST_URL+(SETTINGS.LANGUAGE=='fr'?"tasks_in1_fr.json":"tasks_in1.json"), 'json5', !SETTINGS.RELEASE_MODE || /dev/.test(window.location.href)); //SETTINGS.DATA_URL+''+SETTINGS.tasklist_id
				taskInfoOut = Loader.addXHR('info', SETTINGS.TASKLIST_URL+(SETTINGS.LANGUAGE=='fr'?"tasks_out1_fr.json":"tasks_out1.json"), 'json5', !SETTINGS.RELEASE_MODE || /dev/.test(window.location.href)); //SETTINGS.DATA_URL+''+SETTINGS.tasklist_id
			}

			Loader.setBatchCompleteCallback("info", loadInfo); 
			Loader.start();
		
		}
	};



	//-----------------------
	//
	// An error has blocked the loading of the project. Display it in fullscreen.
	//
	//-----------------------
	function webglCheck() {
		try {
			var canvas = document.createElement( 'canvas' );
			if (!( window.WebGLRenderingContext && ( canvas.getContext( 'webgl' ) || canvas.getContext( 'experimental-webgl' ) ) )) {return false;}
			RendererController.init();
			return true;
		} catch ( e ) {
			return false;
		}
	};

	//
	// Check for major errors that block site access
	//
	function errorCheck() {
		if (SETTINGS.FORCE_SUPPORT) return 0;
		if (SETTINGS.FORCE_ERROR) return SETTINGS.FORCE_ERROR;

		//error 1 : desktop mode : display error on fullscreen
		if (!SETTINGS.isMobile || SETTINGS.isTablet) {
			if (!errorSent) {AnalyticsController.trackEvent('error', SETTINGS.isTablet?'tablet':'desktop'); errorSent = true;}
			return 1;
		}

		//error 2 : no webgl : display error on fullscreen
		if (!webglCheck() || !window.WebAssembly || (!SETTINGS.isIOS && !navigator.mediaDevices)) {
			if (!errorSent) {AnalyticsController.trackEvent('error', 'capabilities');errorSent = true;}
			return 2;
		}

		//error 3 : device is too old but webgl works : display error, load video
		if (!window.WebAssembly || window.devicePixelRatio<2) {
			if (!errorSent) {AnalyticsController.trackEvent('error', 'too_old');errorSent = true;}
			return 3;
		}

		//error 6 : wrong browser on ios
		if (SETTINGS.isIOS && (/crios/.test(navigator.userAgent.toLowerCase()) || !navigator.mediaDevices)) {
			if (!errorSent) {AnalyticsController.trackEvent('error', 'chrome_ios');errorSent = true;}
			return 6;
		}
		if (SETTINGS.isMobile && (SETTINGS.isFirefox || navigator.userAgent.match(/OPT|Mint|UCBrowser/gi) || !navigator.mediaDevices)) {
			if (!errorSent) {AnalyticsController.trackEvent('error', (SETTINGS.isFirefox||navigator.userAgent.match(/OPT|Mint|UCBrowser/gi))?'browser':'no_camera');errorSent = true;}
			return 6;
		}

		//error 3 : browser VERSION is too old but webgl and everything works : display error, load video
		if (false) {
			return 4;
		}

	
		return 0;
	};


	//
	// Display errors that block site access
	//
	function mainError(error) {
		console.log("ERROR, Project Stopped @: ",error);

		//fullscreen header, no task
		MenuController.init();
		$('#loader').remove();

		$('body').append($('#menu-header-container').remove());
		$('#menu-header-container').css('z-index', 11);
		MenuController.init();


		var waterImage = SETTINGS.VIDEO_ASSETS_URL + 'spritesheets/converted/q24/'+Utils.randoma(EmbeddedText.waterVideos)+'/frame_n1_0.jpg';

		if (error!=1) {
			$('#error').css('font-size','0.83em');
		}

		if (error==1) {

			$('#error').css('display', 'flex');
			$('#background').toggleClass('error', true);
			$('#error-txt').html(
				EmbeddedText[SETTINGS.LANGUAGE].errors.desktop.text.replace('{device}', EmbeddedText[SETTINGS.LANGUAGE].errors.desktop[SETTINGS.isTablet ? 'tablet' : 'desktop'])).show();

			return true;
		
		} else if (error==2 || error==3 || error==5) {
			
			var errorText = EmbeddedText[SETTINGS.LANGUAGE].errors[
				error==2?'webgl':
				error==3?'device':
				'loading'
			].text;

			$('#error').css('display', 'flex');
			$('#error-txt').html(errorText).show();

			$('#background').css('background-image', 'url('+waterImage+')');
			$('#background').css('background-position', 'right');
			$('#background').css('background-size', '100% 100%');

			$('#background-flipped').show().css('background-image', 'url('+waterImage+')');

			function resizebg() {
				var ratio = window.innerWidth / window.innerHeight;

				if (ratio <= 9/16) {
					$('#background').css('background-size', Math.floor( ((9/16)/ratio) *100)+'% 100%');
					$('#background-flipped').css('background-size', Math.floor(((9/16)/ratio)*200)+'% 100%');
				} else {
					$('#background').css('background-size', '100% '+Math.floor( (ratio/(9/16)) *100)+'%');
					$('#background-flipped').css('background-size', '200% '+Math.floor( (ratio/(9/16)) *100)+'%');
				}
			}
			$('#background').empty();
			$(window).resize(resizebg);
			$(window).bind('orientationchange',resizebg);
			resizebg();
			currentTask = AppStatus.currentPage = 0;
		
			// $('#error').css('display', 'flex');
			// $('#error-txt').html(EmbeddedText[SETTINGS.LANGUAGE].errors.device.text).show();
			// // taskInfo = {value:{tasks:[EmbeddedText[SETTINGS.LANGUAGE].errors.device]}};

			// $('#background').css('background-image', 'url('+waterImage+')');
			// $('#background').css('background-position', '50% 50%');
			// $('#background').css('background-size', '100% 100%');
			// $('#background-flipped').show().css('background-image', 'url('+waterImage+')');
			return true;

		} else if (error==4) {
			// $('#error-txt').remove(); 

			// 	$('#error').css('display', 'flex');
			// $('#error-txt').html(EmbeddedText[SETTINGS.LANGUAGE].errors.browser.text).show();


			// $('#error-txt').html(EmbeddedText[SETTINGS.LANGUAGE].errors.browser.text).show();
			taskInfo = {value:{tasks:[EmbeddedText[SETTINGS.LANGUAGE].errors.browser]}};
			return false;
		}  else if (error==6) {
			// $('#error-txt').remove(); 

			// 	$('#error').css('display', 'flex');
			// $('#error-txt').html(EmbeddedText[SETTINGS.LANGUAGE].errors.browser.text).show();


			// $('#error-txt').html(EmbeddedText[SETTINGS.LANGUAGE].errors.browser.text).show();
			// EmbeddedText[SETTINGS.LANGUAGE].errors["safari"].text.replace("{browser}", SETTINGS.isIOS ? 'Safari' : 'Chrome');
			// EmbeddedText[SETTINGS.LANGUAGE].errors["safari_inapp"].text.replace("{browser}", SETTINGS.isIOS ? 'Safari' : 'Chrome');
			taskInfo = {value:{tasks:[EmbeddedText[SETTINGS.LANGUAGE].errors[
				// (!navigator.mediaDevices || SETTINGS.FORCE_ERROR) ? "safari_inapp" : "safari"]
				((/crios/.test(navigator.userAgent.toLowerCase()) || SETTINGS.isFirefox || SETTINGS.FORCE_ERROR) ? 'safari' : 'safari_inapp')
			]]}};
			taskInfo.value.tasks[0].text = taskInfo.value.tasks[0].text.replace("{browser}", SETTINGS.isIOS ? 'Safari' : 'Chrome');
			return false;
		}

		//load errors.json

	};


	//-----------------------
	//
	// Get all necessary spritesheet info
	//
	//-----------------------
	var infoLoaded = false;
	function loadInfo() {
		if (infoLoaded) return;
		infoLoaded = true;
		if (SETTINGS.BETA_ID && SETTINGS.BETA_MODE && !SETTINGS.MECHANICAL_TURK_MODE) {
			if (window.allowed_users && !window.allowed_users[SETTINGS.BETA_ID]) {
				SETTINGS.BLOCK_ACCESS = true;
				$('#loader').show();
				$('#loader-txt').show();
				$('#loader-txt').text('Error : wrong URL');
				window.alert("Error: this is not the right URL");
				return;
			}
		}

		if (!taskInfo || !taskInfo.value || !taskInfo.value.tasks) {
			mainError(5);
			// $('#loader-txt').text('Loading Error');
			// setTimeout(function() {if (!window.oneAlert) alert("Loading error for task : "+SETTINGS.tasklist_id);},50);
			return;
		}

		if (taskInfo.value.taskId) {
			AppStatus.currentTaskId = taskInfo.value.taskId;
		} else {
			AppStatus.currentTaskId = SETTINGS.tasklist_id.replace(/\.json|tasks_/gi, '').replace('_fr','');
		}
		var globalState = MultiStorage.getGlobalState();
		globalState.taskId = AppStatus.currentTaskId;
		MultiStorage.setGlobalState(globalState);

		if (!SETTINGS.INTRO_MODE) {
			var globalState = MultiStorage.getGlobalState();
			globalState.tasksDone[AppStatus.currentTaskId] = true;
			globalState.tasksFinished[AppStatus.currentTaskId] = false; //globalState.tasksFinished
			globalState.tasksDoneOrder = globalState.tasksDoneOrder||{};
			if (globalState.tasksDoneOrder[AppStatus.currentTaskId]==undefined) globalState.tasksDoneOrder[AppStatus.currentTaskId] = Object.keys(MultiStorage.getGlobalState().tasksDone||{}).length;
			MultiStorage.setGlobalState(globalState);
		}


		tasks = taskInfo.value.tasks;
		if (SETTINGS.INTRO_MODE) {
			tasks = tasks.slice(0,INTRO_NUM_TASKS);
			// tasks[0].noMenu = true;
			// tasks[1].noMenu = true;
		}
		
		Loader.createBatch('task_info');
		Loader.setBatchCompleteCallback("task_info", setup); 
		SpritesheetVideo.preloadMain('task_info');
		MontageController.preload(tasks, 'task_info');
		if (!SETTINGS.INTRO_MODE) FrameRecorder.preload('task_info');
		MpegDecodeController.preload('task_info');
		
		
		if (!globalState.lastSession || (globalState.lastSession && Date.now()-globalState.lastSession >= 1000 * 60 * 60 * 24 )) {
			sessionXHR = Loader.addXHR('task_info', SETTINGS.API_SERVER_URL+'/start_session', 'text');
			sessionXHR.protocol = 'post';
			sessionXHR.params = new FormData();
			sessionXHR.params.append('user_uuid', SETTINGS.uuid);
			sessionXHR.params.append('update', (globalState.lastSession && Date.now()-globalState.lastSession >= 1000 * 60 * 60 * 24 )?1:0);
		} else {
			sessionXHR = {value:"ok"};
		}
		
		var globalState = MultiStorage.getGlobalState();
		globalState.sessionStart = Date.now();
		MultiStorage.setGlobalState(globalState);

		Loader.start();
	};



	//-----------------------
	//
	// Finished first phase of loading. Setup.
	//
	//-----------------------
	function setup() {
		console.log("setup");
		var globalState = MultiStorage.getGlobalState();

		//catch loading error for server
		if (!sessionXHR || !sessionXHR.value) {
			mainError(5);
			return;
		}
		globalState.lastSession = Date.now();
		MultiStorage.setGlobalState(globalState);
		if (!SETTINGS.INTRO_MODE) AppStatus.MAIN_LOADED = true;

		//start recorder
		FrameRecorder.init();
		FrameRecorder.prepareAll();
		

		firstView = currentTask;


		//-----------------
		//				   
		//  Parse all tasks and create related content & record views
		//				   
		//-----------------
		MontageController.setup('task_info');
		Loader.createBatch('main');

		var t;
		var nrc = 0;
		var foundNextRec = false;

		//
		// Set and create welcomeback and allow camera tasks
		//
		shouldWelcomeBack = ((Date.now()-(globalState.lastAction||0)) > 5 * 60 * 1000 || !!SETTINGS.FORCE_WELCOME_BACK) && !manualChapterJump && currentTask > 1 && currentTask < tasks.length-1;
		if ((globalState.cameraAttempts||0)>0) shouldWelcomeBack = false;
		if (!manualChapterJump && (Date.now()-(globalState.lastAction||0)) > 60 * 1000 || !!SETTINGS.FORCE_WELCOME_BACK) AnalyticsController.trackEvent('returnSession', AppStatus.currentTaskId);

		//also welcome back on visibility change
		document.addEventListener("visibilitychange", function() {
			if (!shouldAllowCamera  && currentTask > 1 && currentTask < allViews.length-1 && ((Date.now()-(MultiStorage.getGlobalState().lastAction||0) > 30 * 60 * 1000) || SETTINGS.FORCE_WELCOME_BACK)) {
				shouldWelcomeBack = true;
				welcomeBackTask.tmpMenu = true;
				if (currentView) welcomeBackTask.page = currentView.page;
				AnalyticsController.trackEvent('returnSession', AppStatus.currentTaskId);
			}
		}, false);

		AppStatus.forceMainLoader = true;
		allowCameraTask = MottoViewCreate(
			!CameraController.allowedOnce ? EmbeddedText[SETTINGS.LANGUAGE].views.allowCameraFirst : 
			currentTask < 2 ? EmbeddedText[SETTINGS.LANGUAGE].views.allowCameraChapter : 
			(manualChapterJump||shouldWelcomeBack||((globalState.cameraAttempts||0)>0)) ? EmbeddedText[SETTINGS.LANGUAGE].views.allowCameraReturnNoWelcomeBack :
			EmbeddedText[SETTINGS.LANGUAGE].views.allowCameraReturn, skipNext);
		AppStatus.forceMainLoader = false;


		var hasAllowCamera = false;
		allowCameraId = 1;

		numPages = SETTINGS.INTRO_MODE ? 216 : tasks.length;

		for (var ni=0; ni<tasks.length; ni++) {
			var i = ni; //(ni + currentTask) % tasks.length;
			t = MottoViewCreate(tasks[i], skipNext, 'main');
			// t.setPage( i+1, numPages );
			if (i < currentTask) t.unlockRight();
			allViews[i] = t;

			if (tasks[i].isAllowCamera) {}

			if ((t.isAllowCamera || tasks[i].isAllowCamera) && i > currentTask) {
				hasAllowCamera = true;
				allowCameraId = i;
			}
			if (!foundNextRec  && t.params && t.params.camera) {
				foundNextRec = true;
				allowCameraTask.nextCamera = t.params.camera;
			}
		}
		recalculateNumPages = true;
		AppStatus.numPages = AppStatus.numPagesDisplay = 0;
		setPageNumbers();

		allViews[0].lockLeft = true;
		allViews[0].info.menu = true;
		allViews[allViews.length-1].lockRight = true;
		for (var i=1; i<allViews.length; i++) {
			if (allViews[i].linkLast) allViews[i].linkLast(allViews[i-1]);
		}
		currentView = allViews[currentTask] || allViews[0];
		if (currentView && currentView.isBranch) {
			var views = currentView.getViews();
			currentView = views[currentTaskInBranch]||currentView;
		}

		shouldAllowCameraNext = !hasAllowCamera || (hasAllowCamera && allowCameraId < currentTask);

		if (shouldAllowCameraNext && (manualChapterJump || (globalState.cameraAttempts||0)>0 || (currentView && currentView.hasLivefeed))) {
			shouldAllowCamera = true;
			shouldAllowCameraNext = false;
		}

		if (shouldWelcomeBack && shouldAllowCamera) {
			shouldAllowCamera = false;
			shouldAllowCameraNext = true;
		}

		if (SETTINGS.INTRO_MODE) shouldAllowCameraNext = shouldAllowCamera = shouldWelcomeBack = false;

		// console.log("should allow:", shouldWelcomeBack, shouldAllowCamera, shouldAllowCameraNext, allViews[currentTask]);
		if (currentView) console.log("show allow currentView:", currentView.hasLivefeed,);

		// if (shouldWelcomeBack) {
		if (!SETTINGS.INTRO_MODE) {
			AppStatus.forceMainLoader = true;
			welcomeBackTask = new MontageView(EmbeddedText[SETTINGS.LANGUAGE].views.welcomeBack, 'main');
			welcomeBackTask.noPages = true;
			welcomeBackTask.tmpMenu = true;
			if (currentView) welcomeBackTask.page = currentView.page;
			AppStatus.forceMainLoader = false;

			if (!SETTINGS.ERROR_MODE) OpticalFlowController.preload('main');
		}

		setViews();


		//-----------------
		//
		//  Parse all tasks and create/preload all the necessary spritesheets for montages (&videos?)
		//  Flag list of user/bin to keep in memory & not dispose
		//  Parse all tasks and get all necessary videos/images for prebaked
		//
		//-----------------
		// for (var i=0; i<tasks.length; i++) {}

		//-----------------
		//
		// Start first 3 tasks and activate task 0
		//
		//-----------------

		Loader.setBatchCompleteCallback("main", function() {});

		Loader.start();
		PageLoader.start();
		MpegDecodeController.init();
		MenuController.init();

		// var gl = renderer.getContext();
		// 	$(document.body).html(
		// 			JSON.stringify({
		// 				// capabilities: renderer.capabilities,
		// 				// "renderer.capabilities.getMaxAnisotropy":renderer.capabilities.getMaxAnisotropy(),
		// 				// "renderer.capabilities.getMaxPrecision":renderer.capabilities.getMaxPrecision(),
		// 				// "gl.getParameter(gl.MAX_VERTEX_TEXTURE_IMAGE_UNITS)": gl.getParameter(gl.MAX_VERTEX_TEXTURE_IMAGE_UNITS),
		// 				// "gl.getParameter(gl.ALIASED_POINT_SIZE_RANGE)": gl.getParameter(gl.ALIASED_POINT_SIZE_RANGE),
		// 				// "renderer.extensions.get('EXT_frag_depth')": renderer.extensions.get('EXT_frag_depth'),
		// 				// "renderer.extensions.get('EXT_sRGB')": renderer.extensions.get('EXT_sRGB'),
		// 				// "gl.getExtension('EXT_texture_filter_anisotropic')":gl.getExtension('EXT_texture_filter_anisotropic'),
		// 				// "gl.getExtension('WEBGL_depth_texture')": gl.getExtension('WEBGL_depth_texture'),
		// 				// "gl.getExtension('OES_texture_float')": gl.getExtension('OES_texture_float'),
		// 				// "document.createElement('canvas').getContext('webgl2')": document.createElement('canvas').getContext('webgl2'),
		// 				// "window.devicePixelRatio": window.devicePixelRatio
		// 			}
		// 			,null,'\t<br>')
		// 		).css('font-size', '0.5em');

		if (!AppStatus.started) {
			AppStatus.started = true;
			window.requestAnimationFrame(update);
		}
		

		if (SETTINGS.MODE_AUTO) {
			setTimeout(function() {setInterval(skipNext, 350);},4000);	
		}
	};


	//-----------------------
	//
	//  Append rest of json to the tasks once in/out is selected
	//
	//-----------------------
	window.introSelectInside = function() {
		AnalyticsController.trackEvent('intro_inside');
		AnalyticsController.trackEvent('chapter', 'in1');

		var prevState = MultiStorage.getState();

		AppStatus.currentTaskId = 'in1';
		SETTINGS.tasklist_id_json = SETTINGS.tasklist_id = 'tasks_in1.json';
		if (SETTINGS.LANGUAGE == 'fr') SETTINGS.tasklist_id_json = 'tasks_in1_fr.json';
		tasks = taskInfoIn.value.tasks;

		MultiStorage.setState(prevState);

		introDoneState();
	};

	window.introSelectOutside = function() {
		AnalyticsController.trackEvent('intro_outside');
		AnalyticsController.trackEvent('chapter', 'out1')

		var prevState = MultiStorage.getState();

		AppStatus.currentTaskId = 'out1';
		SETTINGS.tasklist_id_json = SETTINGS.tasklist_id = 'tasks_out1.json';
		if (SETTINGS.LANGUAGE == 'fr') SETTINGS.tasklist_id_json = 'tasks_out1_fr.json';
		tasks = taskInfoOut.value.tasks;
		taskInfoIn = taskInfoOut = null;

		MultiStorage.setState(prevState);

		introDoneState();
	};

	function introDoneState() {
		if (!beginSent) {
			AnalyticsController.trackEvent('begin');
			beginSent = true;
			console.log("Begin");
		}
		SETTINGS.INTRO_MODE = false;

		var tasksState = MultiStorage.getState();
		MultiStorage.tasklist_id = SETTINGS.tasklist_id;
		MultiStorage.setState(tasksState);

		var globalState = MultiStorage.getGlobalState();
		globalState.taskId = AppStatus.currentTaskId;
		MultiStorage.setGlobalState(globalState);
		
		if (window.history && window.history.replaceState) window.history.replaceState({currentTask:currentTask, currentTaskInBranch:currentTaskInBranch, taskId:AppStatus.currentTaskId}, 'Motto', window.location.pathname.replace('/','')+window.location.search);

		var globalState = MultiStorage.getGlobalState();
		globalState.tasksDone = globalState.tasksDone||{};
		globalState.tasksDone[AppStatus.currentTaskId] = true;
		globalState.tasksDoneOrder = globalState.tasksDoneOrder||{};
		if (!globalState.tasksDoneOrder[AppStatus.currentTaskId]) globalState.tasksDoneOrder[AppStatus.currentTaskId] = Object.keys(MultiStorage.getGlobalState().tasksDone||{}).length;
		MultiStorage.setGlobalState(globalState);

		// recalculateNumPages = true;
		// AppStatus.numPages = AppStatus.numPagesDisplay = 0;
		// setPageNumbers();

		skipNext();
		loadInfoAfterIntro();
	};

	var taskInfoAppended = null;
	function loadInfoAfterIntro() {
		
		Loader.createBatch('task_info_selected');

		var branchParams = {
			"type": "simple-branch",
			"main_loading/intro": {
				"text": "<span class=\"respiration\">M O T T O</span>",
				"textType": "center",
				"type": "loader",
				"background": "white"
			},
			"default": "skip"
		};
		allViews[allViews.length-1].lockRight = false;
		var t = MottoViewCreate(branchParams, skipNext, 'task_info_selected');
		t.setPage( INTRO_NUM_TASKS+1, tasks.length );
		allViews.push(t);
		loaderViewId = INTRO_NUM_TASKS+1;
		t.lockRight = true;
		setViews();

		Loader.setBatchCompleteCallback("task_info_selected", setupAfterIntro); 
		MontageController.preload(tasks.slice(INTRO_NUM_TASKS), "task_info_selected");

		// if (!SETTINGS.INTRO_MODE) {
			AppStatus.forceMainLoader = true;
			welcomeBackTask = new MontageView(EmbeddedText[SETTINGS.LANGUAGE].views.welcomeBack, 'task_info_selected');
			welcomeBackTask.noPages = true;
			welcomeBackTask.tmpMenu = true;
			if (currentView) welcomeBackTask.page = currentView.page;
			AppStatus.forceMainLoader = false;

			if (!SETTINGS.ERROR_MODE) OpticalFlowController.preload('task_info_selected');
			FrameRecorder.preload('task_info_selected');
		// }


		Loader.start();
	}
	function setupAfterIntro() {

		console.log("Setup2");
		MontageController.setup('task_info_selected');

		FrameRecorder.init();
		FrameRecorder.prepareAll();

		allViews[allViews.length-1].lockRight = false;
		for (var ni=INTRO_NUM_TASKS; ni<tasks.length; ni++) {
			var i = ni;
			var t = MottoViewCreate(tasks[i], skipNext, 'main_post');
			// t.setPage( i+1, tasks.length );
			if (ni + currentTask >= tasks.length) t.unlockRight();
			allViews[i+1] = t;
		}
		allViews[allViews.length-1].lockRight = true;
		recalculateNumPages = true;
		AppStatus.numPages = AppStatus.numPagesDisplay = 0;
		setPageNumbers();

		AppStatus.MAIN_LOADED = true;

		for (var i=1; i<allViews.length; i++) {
			if (allViews[i].linkLast) allViews[i].linkLast(allViews[i-1]);
		}
		Loader.start();
	}


	//-----------------------
	//
	//  Update every frame
	//  Add webgl effects, sound handling, etc
	//
	//-----------------------
	function update() {
		window.requestAnimationFrame(update);
		if (disposed) {
			if (!AppStatus.restarting) AppStatus.selectChapter();
			return;
		}

		AppStatus.currentFrame++;
		if (!SETTINGS.ERROR_MODE) FrameRecorder.prepare();
		// MotionController.update();


		//clear orientation
		if (orientationWarningShown && !AppStatus.isLandscapeMode()) {
			if (orientationTimeout>=0) clearTimeout(orientationTimeout);
			orientationTimeout = -1;
			$('#orientation-warning').hide();
			$('#orientation-warning-inner').hide();
			orientationWarningShown = false;
			if (CameraController.waitingForPortraitMode) CameraController.start(CameraController.waitingForPortraitMode); 
			AppStatus.landscapeMode = AppStatus.isLandscapeMode();
			resize();
		}
		if (!SETTINGS.isTablet && AppStatus.landscapeMode != AppStatus.isLandscapeMode()) {//(window.outerWidth > window.outerHeight)) {
			resize();
		}
		MpegDecodeController.update();
		CameraController.updateUI();

		if (firstView >=0) {
			if (shouldWelcomeBack && welcomeBackTask) {
				if (welcomeBackTask.isReady()) {
					console.log("IS READY welcomeback");
					$('#loader').remove();
					firstView = -1;
				}
			} else if (currentTask == firstView && allViews[currentTask]) {
				if (allViews[currentTask].isReady() || shouldAllowCamera) {
					console.log("IS READY! currentTask",currentTask,firstView);
					allViews[currentTask].tmpMenu = !shouldWelcomeBack;
					if (allViews[currentTask].isBranch && allViews[currentTask].tmpMenu) {
						if (allViews[currentTask].getViews()[currentTaskInBranch]) {
							allViews[currentTask].getViews()[currentTaskInBranch].tmpMenu = true;
						}
					}
					$('#loader').remove();
					firstView = -1;
				}
			} else {
				allViews[currentTask].tmpMenu = !shouldWelcomeBack;
				if (allViews[currentTask].isBranch && allViews[currentTask].tmpMenu) {
					if (allViews[currentTask].getViews()[currentTaskInBranch]) {
						allViews[currentTask].getViews()[currentTaskInBranch].tmpMenu = true;
					}
				}
				$('#loader').remove();
				firstView = -1;
			}
		}
		window.currentView = allViews[currentTask];

		//snap currentTask if >= 1
		if (Math.abs(Math.round(positionOffset + dragOffset)) > 1) {
			if (positionOffset + dragOffset > 0) {
				AppStatus.goingBackwards = false;
				incrementPage(1);
				positionOffset -= 1;
				dragOffset -= 1;
				touchDownX -= 1;
			} else if (currentTask > 0) {
				AppStatus.goingBackwards = true;
				incrementPage(-1);
				positionOffset += 1;
				dragOffset += 1;
				touchDownX += 1;
			}
		}

		//drag views
		if (isSnapping) {
			if (Math.abs(positionOffset - dragOffset) < 0.001) {
				positionOffset = 0.0;
				isSnapping = false;
				dragOffset = 0.0;
			}
		}
		if (isDragging || isSnapping) {
			positionOffset = Utils.lerpSnap( positionOffset, dragOffset, dragSpeed );
			lastDragTime = performance.now();
		} else if (performance.now() - lastDragTime >= 5000) {
			tappingMode = true;
		}
		AppStatus.frameNeedsUpdate = AppStatus.frameNeedsUpdate || Math.abs(lastPositionOffset - positionOffset) > 0.5/AppStatus.innerWidth();

		//try to append next branch to current view
		setViews();
		var prevActive = new Map(activeViews);
		//
		// build currentViews array
		//
		//prev views
		var currentViews = [];
		var currentViewInArray = 0;
		for (var i=Math.max(0, currentTask-3); i<currentTask; i++) {
			if (allViews[i].isBranch) {
				var tt = allViews[i].getViews();
				currentViewInArray+=tt.length;
				currentViews = currentViews.concat(tt);
			} else {
				currentViewInArray++;
				currentViews.push(allViews[i]);
			}
		}

		//should allow camera
		var numPrev = currentViews.length-1;

		//current view
		if (allViews[currentTask].isBranch) {
			if (isOnFirstTask) {
				allViews[currentTask].activateView('first');
			}
			currentViews = currentViews.concat( allViews[currentTask].getViews());
			currentViewInArray+=currentTaskInBranch;

		} else {
			currentViews.push(allViews[currentTask]);
		}

		//next views
		if (currentTask<allViews.length-1) {
			for (var i=Math.min(currentTask+1,allViews.length-1); i<Math.min(currentTask+3, allViews.length); i++) {
				if (allViews[i].isBranch) {
					var tt = allViews[i].getViews();
					currentViews = currentViews.concat(tt);
				} else {
					currentViews.push(allViews[i]);
				}
			}
		}
		currentView = currentViews[currentViewInArray];
		if (!currentView) return;


		//add allowCamera on next views
		if (!SETTINGS.ERROR_MODE) {
			if (shouldAllowCamera || shouldAllowCamera) {
				if (currentView && currentView.nextCamera||currentView.livefeedDirection) {
					allowCameraTask.nextCamera = currentView.nextCamera||currentView.livefeedDirection;
				}
			}


			if (shouldAllowCameraNext && CameraController.cameraAllowed) {

				if (!isDragging && !isSnapping) {
					shouldAllowCameraNext = false;
				} else {
					shouldAllowCameraNext = false;
					if (AppStatus.goingBackwards) incrementPage(-1);
				}
			}

			if (shouldWelcomeBack && welcomeBackTask) {
				currentViews.splice(currentViewInArray, 0, welcomeBackTask);
				currentView = welcomeBackTask;
			}


			if (CameraController.cameraRefused && (!currentView || (currentView && !currentView.isAllowCamera))) {
				shouldAllowCameraNext = false;
				shouldAllowCamera = true;
			}
			if (shouldAllowCameraNext) {
				if (dragOffset < 0) {currentViewInArray++; currentViews.splice(Math.max(currentViewInArray-1,0), 0, allowCameraTask);}
				else if (currentTask+1 < allViews.length) {currentViews.splice(currentViewInArray+1, 0, allowCameraTask);}
			} else if (shouldAllowCamera) {
				currentViews.splice(currentViewInArray, 0, allowCameraTask);
				currentView = allowCameraTask;
			} else if (shouldAllowCameraPrev != 0) {
				currentViews.splice(Math.max(currentViewInArray+shouldAllowCameraPrev,0), 0, allowCameraTask);
				if (!allowCameraTask.active) {shouldAllowCameraPrev = 0;}
				// else {
				// 	if (dragOffset > 0) {currentViewInArray++; currentViews.splice(Math.max(currentViewInArray-1,0), 0, allowCameraTask);}
				// 	else if (currentTask+1 < allTa.length) {currentViews.splice(currentViewInArray+1, 0, allowCameraTask);}
				// }	
			}
		}


		lockedRight = currentView.lockRight;
		lockedLeft = currentView.lockLeft;
		if (dragOffset < 0.0 && lockedLeft) {
			dragOffset = 0.0;
			lastTouchStart = performance.now();
		} else if (dragOffset > 0.0 && lockedRight) {
			dragOffset = 0.0;
			lastTouchStart = performance.now();
		}
		AppStatus.frameNeedsUpdate = AppStatus.frameNeedsUpdate || Math.abs(lastPositionOffset - positionOffset) > 0.000001;
		lastCurrentView = currentView;
		// AppStatus.frameNeedsUpdate = true;

		//update display
		if (currentViews[currentViewInArray]) {
			AppStatus.currentPageDisplay = currentViews[currentViewInArray].page;
			// AppStatus.numPagesDisplay = currentViews[currentViewInArray].numPages;
		}

		//
		// update current active views & their positions
		//
		for (var i=Math.min(currentViews.length-1, currentViewInArray+3); i>Math.max(-1, currentViewInArray-3); i--) {
			if (currentViews[i]) {
				if (i-1 == currentViewInArray && lockedRight) {
					currentViews[i].locked = true;
				} else {
					currentViews[i].locked = false;
				}
				currentViews[i].setPosition(i-currentViewInArray - positionOffset);
				currentViews[i].update();
			}
		}

		//update and render
		if (document.hidden) AppStatus.frameNeedsUpdate = false;
		// AppStatus.frameNeedsUpdate = true;

		if (AppStatus.frameNeedsUpdate && (currentView && (lockedLeft || lockedRight))) RendererController.clear(currentView.backgroundColor);
		if (AppStatus.frameNeedsUpdate) {
			lastPositionOffset = positionOffset;
		}
		for (var i=Math.min(currentViews.length-1, currentViewInArray+3); i>Math.max(-1, currentViewInArray-3); i--) {
			if (AppStatus.frameNeedsUpdate) currentViews[i].render();

			prevActive.delete(currentViews[i]);
			if (activeViews.has(currentViews[i])) {
				if (!currentViews[i].started) activeViews.delete(currentViews[i]);
			} else {
				if (currentViews[i].started) activeViews.set(currentViews[i], true);
			}
		}

		//catch views that weren't updated
		for (let shouldntBeActive of prevActive.keys()) {
			shouldntBeActive.setPosition(-10);
			shouldntBeActive.locked = false;
			activeViews.delete(shouldntBeActive);
			// console.warn("View is active but shouldn't be", shouldntBeActive);
		}


		// if (!AppStatus.frameNeedsUpdate) console.log("skipped frame");
		AppStatus.frameNeedsUpdate = !!document.hidden;
	};


	//--------------------
	//
	// Increment current task
	//
	//--------------------
	function incrementPage(direction) {

		if ((SETTINGS.INTRO_MODE||SETTINGS.WAS_INTRO_MODE) && !beginSent) {
			AnalyticsController.trackEvent('begin');
			beginSent = true;
			console.log("Begin");
		}

		AppStatus.goingBackwards = (direction < 0);
		currentTaskInBranch += direction;

		if (shouldAllowCamera) {
			shouldAllowCamera = false;
			if (direction > 0) currentTaskInBranch -= direction;
			if (!isDragging && !isSnapping && shouldAllowCameraDirection<0) currentTaskInBranch--;

		} else if (shouldWelcomeBack && welcomeBackTask) {

			shouldWelcomeBack = false;
			welcomeBackTask.deactivate();
			welcomeBackTask.stop();
			if (direction > 0) currentTaskInBranch -= direction;
			if (!isDragging && !isSnapping && shouldAllowCameraDirection<0) currentTaskInBranch--;

		}
		if (shouldAllowCameraNext) {
			shouldAllowCameraNext = false;
			shouldAllowCamera = !CameraController.cameraAllowed;
			// currentTaskInBranch -= direction;
			if (direction < 0) currentTaskInBranch += 1;
			shouldAllowCameraDirection = direction;

		}


		setPageNumbers();
	}

	//--------------------
	//
	// Set current page (currenTask) based on where we are in allViews+branches
	//
	//--------------------
	function setViews() {
		if (disposed) return;
		//if dragging from menu
		if (AppStatus.draggedPage && !MenuController.dragging) {
			AppStatus.draggedPage = false;
			recalculateNumPages = true;
			isOnFirstTask = false;
			// var tp = AppStatus.targetPage;
			// currentTaskInBranch = 0;
			// while (allViews[tp].parentBranch && tp > 0) {
			// 	currentTaskInBranch++;
			// 	tp--;
			// }

			var views = [];
			var actualCurrentTask = [];
			var actualTaskInBranch = [];
			for (var i=0; i<allViews.length; i++) {
				if (allViews[i].isBranch) {
					if (!allViews[i].isAllowCamera)  {
						var bviews = allViews[i].getViews();
						views = views.concat(bviews);
						for (var j=0; j<bviews.length; j++) {
							actualTaskInBranch.push(j);
							actualCurrentTask.push(i);
						}
					}
				} else {
					actualTaskInBranch.push(0);
					actualCurrentTask.push(i);
				}
			}
			currentTask = actualCurrentTask[AppStatus.targetPage]||0;
			currentTaskInBranch = actualTaskInBranch[AppStatus.targetPage]||0;

			if (shouldWelcomeBack && welcomeBackTask) {
				shouldWelcomeBack = false;
				welcomeBackTask.deactivate();
				welcomeBackTask.stop();
			}

			//force allow camera
			if (!CameraController.cameraAllowed) {
				shouldAllowCamera = true;
				shouldAllowCameraNext = false;
			}

			setViews();
			return;
		}


		//current page + clamp
		AppStatus.currentPage = currentTask;
		
		if (currentTask >= allViews.length) currentTask = allViews.length-1;
		if (currentTask < 0) currentTask = 0;
		if (!allViews[currentTask]) currentTask = allViews.length-1;

		//concatenate all branch views, update currentTask if it has changed
		if (allViews[currentTask].isBranch && isOnFirstTask) allViews[currentTask].activateView('first');
		if ((!allViews[currentTask].isBranch && currentTaskInBranch!==0)||(allViews[currentTask].isBranch && (currentTaskInBranch>=allViews[currentTask].getViews().length||currentTaskInBranch<0))) {
			if (isOnFirstTask && allViews[currentTask].isBranch) allViews[currentTask].deactivateView('first');
			isOnFirstTask = false;
			
			//increment
			// if (!shouldAllowCamera)
			currentTask += Math.sign(currentTaskInBranch)||1;

			//clamp
			if (currentTask >= allViews.length) currentTask = allViews.length-1;
			if (currentTask < 0) currentTask = 0;
			if (!allViews[currentTask]) currentTask = allViews.length-1;
			
			//concatenate branches
			var views = [];
			while (views.length==0 && allViews[currentTask].isBranch) {
				views = allViews[currentTask].getViews();
				if (views.length <= 0) {
					console.log("skipped branch!",currentTask,currentTask + Math.sign(currentTaskInBranch));
					currentTask += Math.sign(currentTaskInBranch)||1;
					views = [];
				}
				if (currentTask<0) {
					currentTask = 1;
					allViews[1].lockLeft = true;
				} else if (currentTask>=allViews.length) {
					currentTask = allViews.length-2;
					allViews[allViews.length-2].lockRight = true;
				}
			}
			if (allViews[currentTask].isBranch && currentTaskInBranch < 0) {
				currentTaskInBranch = views.length-1;
			} else {
				currentTaskInBranch = 0;
			}
		}
		if (allViews[currentTask].isBranch && isOnFirstTask) allViews[currentTask].activateView('first');

		//called on every page/view change
		if (lastTaskInBranch !== currentTaskInBranch || lastTask !== currentTask) {

			//update allowCamera
			if (!firstSetView) {
				// if (shouldAllowCamera) {
				// 	shouldAllowCamera = false;
				// } else if (shouldAllowCameraNext) {
				// 	shouldAllowCameraNext = false;
				// 	shouldAllowCamera = !CameraController.cameraAllowed;
				// }
			}
			firstSetView = false;

			//save page for reloading
			var tasksState = MultiStorage.getState();
			if (!shouldWelcomeBack) lastTask = tasksState.currentTask = currentTask;
			if (currentView && !shouldWelcomeBack && !SETTINGS.INTRO_MODE) { //} && (currentView.isAllowCamera)) {
				tasksState.currentTask = Math.max(currentTask,0);
			}
			if (SETTINGS.WAS_INTRO_MODE && currentTask > INTRO_NUM_TASKS && currentTask < INTRO_ALLOW_CAMERA) {
				tasksState.currentTask = tasksState.currentTask - 1;
			}

			//allow camera
			if (CameraController.cameraAllowed && currentTask > allowCameraId+1 && !AppStatus.goingBackwards && !SETTINGS.INTRO_MODE) {
				if (currentView && !currentView.hasLivefeed && currentView.nextCamera) {
					if (allowCameraTask) allowCameraTask.nextCamera = currentView.nextCamera
					if (currentView.nextCameraDistance && currentView.nextCameraDistance>3 && currentView.prevCameraDistance>3) {
						if (CameraController.isReady() && CameraController.facingMode !== currentView.nextCamera) {
							console.log("Pre-Starting camera", currentView.nextCamera);
							CameraController.start(currentView.nextCamera);
						}
					}
				}
			}
			if (currentView && currentView.nextCamera && allowCameraTask) allowCameraTask.nextCamera = currentView.nextCamera; 


			//
			// When  a new page, set pages & numPages
			//
			// currentPageNumberView = 0;
			// currentPageNumber = 0;
			// currentPageNumberViewInBranch = 0;
			// for (var i=currentPageNumberView; i<currentTask; i++) {
			// 	if (allViews[i] && allViews[i].isBranch) {
			// 		currentPageNumber += allViews[i].getViews().length;
			// 	} else {
			// 		currentPageNumber++;
			// 	}
			// 	currentPageNumberView = i;
			// }
			// if (allViews[currentTask].isBranch) {
			// 	var views =allViews[currentTask].getViews().length;
			// 	for (var i=currentPageNumberViewInBranch; i<currentTaskInBranch; i++) {
			// 		currentPageNumberViewInBranch = i;
			// 		currentPageNumber++;
			// 		views[i].setPage(currentPageNumber, AppStatus.numPagesDisplay)
			// 	}
			// } else {
			// 	console.log("Plus plus");
			// 	currentPageNumber++;
			// 	currentPageNumberViewInBranch = -1;
			// 	allViews[currentTask].setPage(currentPageNumber, AppStatus.numPagesDisplay);
			// }
			setPageNumbers();




			// if (SETTINGS.WAS_INTRO_MODE && !SETTINGS.INTRO_MODE) tasksState.currentTask = Math.max(currentTask-1);
			lastTaskInBranch = tasksState.currentTaskInBranch = currentTaskInBranch;
			MultiStorage.setState(tasksState);

			var globalState = MultiStorage.getGlobalState();
			globalState.lastAction = Date.now();
			MultiStorage.setGlobalState(globalState);


	
			// if (window.history) window.history.replaceState({currentTask:currentTask, currentTaskInBranch:currentTaskInBranch, taskId:AppStatus.currentTaskId}, 'Motto', window.location.pathname.replace('/','')+window.location.search);
		}
		
		allViews[0].lockLeft = true;




		currentView = allViews[currentTask];
		if (currentView.isBranch) currentView = allViews[currentTask].getViews()[currentTaskInBranch];

		// AppStatus.numPages = allViews.length;

	}


	//-----------------------
	//
	//  Handle interaction : click for next & drag to return, unless current task overrides it
	//
	//-----------------------
	function skipNext(view) {
		if ((shouldAllowCamera||shouldWelcomeBack) && !isDragging && !isSnapping && shouldAllowCameraDirection<0) currentTaskInBranch--;
		if (view == shouldWelcomeBack && welcomeBackTask) {
			shouldWelcomeBack = false;
			welcomeBackTask.deactivate();
			welcomeBackTask.stop();
			if (AppStatus.goingBackwards && (!isDragging || !isSnapping)) currentTaskInBranch--;
		}
		if (view == allowCameraTask) {
			shouldAllowCamera = shouldAllowCameraNext = false;
			if (AppStatus.goingBackwards && (!isDragging || !isSnapping)) currentTaskInBranch--;
		}
		else if (view && view.isAllowCamera) currentTaskInBranch+= (dragOffset < 0 || AppStatus.goingBackwards)?-1:1;
		else if (view && view.isLoaderView) currentTaskInBranch--;
		else if (currentView&&view&&view!==currentView) {}
		else currentTaskInBranch++;
		AppStatus.goingBackwards = false;

		setViews();

		isDragging = false;
		isSnapping = false;
		positionOffset = 0;
		dragOffset = 0;
		var tasksState = MultiStorage.getState();
		if (!shouldWelcomeBack) tasksState.currentTask = currentTask;
		if (currentView && (currentView.isCameraRefused||currentView.isAllowCamera)) {
			tasksState.currentTask = currentTask; //Math.max(currentTask-1);
		}
		else if (SETTINGS.WAS_INTRO_MODE && !SETTINGS.INTRO_MODE && !shouldWelcomeBack) tasksState.currentTask = currentTask; //Math.max(currentTask-1);
		// if (SETTINGS.WAS_INTRO_MODE && currentTask >= loaderViewId) {
		// 	tasksState.currentTask--;
		// 	console.log("currentask-1");
		// }
		if (SETTINGS.WAS_INTRO_MODE && currentTask > INTRO_NUM_TASKS && currentTask < INTRO_ALLOW_CAMERA) {
			tasksState.currentTask = tasksState.currentTask - 1;
		}

		tasksState.currentTaskInBranch = currentTaskInBranch;
		MultiStorage.setState(tasksState);


		var globalState = MultiStorage.getGlobalState();
		globalState.lastAction = Date.now();
		MultiStorage.setGlobalState(globalState);
	};


	window.forcePages = function() {
		recalculateNumPages = true;
		setPageNumbers();
	}

	function setPageNumbers() {
		if (disposed) return;
		// if (allViews[currentTask] && allViews[currentTask].isBranch || (allViews[currentTask-1] && allViews[currentTask-1].isBranch && lastBranchForPage!=allViews[currentTask-1].getBranchUID())) {
		// 	currentBranchForPage = allViews[currentTask].getBranchUID?allViews[currentTask].getBranchUID():null;
		// 	lastBranchForPage = allViews[currentTask-1].getBranchUID?allViews[currentTask-1].getBranchUID():null;
		// 	recalculateNumPages = true;
		// }

		if (recalculateNumPages || AppStatus.recalculateNumPages) {
			var lp = AppStatus.numPages;
			var currentPageNumber = 0;

			numPages = 0;
			for (var i=0; i<allViews.length; i++) {
				if (allViews[i].isBranch) {
					if (!allViews[i].isAllowCamera) numPages += allViews[i].getViews().length;
				} else {
					numPages++;
				}
			}
			numPages = AppStatus.numPages = AppStatus.numPagesDisplay = (SETTINGS.WAS_INTRO_MODE && !AppStatus.MAIN_LOADED) ? 216 : numPages;

			// if (lp != numPages || (allViews[currentTask] && allViews[currentTask].numPages != numPages)) {
			var viewsArray = [];
			for (var i=0; i<allViews.length; i++) {
				if (allViews[i] && !allViews[i].isAllowCamera) {
					if (allViews[i].isBranch) {
						var subviews = allViews[i].getViews()
						for (var j=0; j<subviews.length; j++) {
							currentPageNumber++;
							if (subviews[j]) subviews[j].setPage(currentPageNumber, numPages);
							viewsArray.push(subviews[j]);
						}
						if (subviews.length == 0 && allViews[i].introModeBranch && SETTINGS.WAS_INTRO_MODE) currentPageNumber++;
					} else {
						currentPageNumber++;
						allViews[i].setPage(currentPageNumber, numPages);
						viewsArray.push(allViews[i]);
					}
				}
			}

			//next camera backwards
			var nextCamera = null;
			var livefeedDistance = 0;
			for (var i=viewsArray.length-1; i>-1; i--) {
				if (viewsArray[i] && !viewsArray[i].isAllowCamera) {
					if (viewsArray[i].hasLivefeed) {
						livefeedDistance = 0;
						viewsArray[i].prevCameraDistance = viewsArray[i].prevCameraDistance = 0;
						nextCamera = viewsArray[i].livefeedDirection || 'back';
					} else if (!viewsArray[i].hasLivefeed) {
						livefeedDistance++;
						viewsArray[i].nextCamera = nextCamera;
						viewsArray[i].nextCameraDistance = livefeedDistance;
					}
				}
			}

			//camera distance forwards
			livefeedDistance = 0;
			for (var i=0; i<viewsArray.length; i++) {
				if (viewsArray[i] && !viewsArray[i].isAllowCamera) {
					if (viewsArray[i].hasLivefeed) {
						livefeedDistance = 0;
					} else if (!viewsArray[i].hasLivefeed) {
						livefeedDistance++;
						viewsArray[i].prevCameraDistance = livefeedDistance;
					}
				}
			}


			//tmp menu
			if (currentTask == firstView && allViews[currentTask]) {
				allViews[currentTask].tmpMenu = !shouldWelcomeBack;
				if (allViews[currentTask].isBranch && allViews[currentTask].tmpMenu) {
					if (allViews[currentTask].getViews()[currentTaskInBranch]) {
						allViews[currentTask].getViews()[currentTaskInBranch].tmpMenu = true;
					}
				}
			}

			AppStatus.recalculateNumPages = recalculateNumPages = false;
		}		
	}



	var lc = performance.now();;
	$('#main').on('touchstart', function(e) {
		if (window.AppStatus.cleanedUp) {window.AppStatus.selectChapter(); return;}

		lc = performance.now();;

		// if (isSnapping) return;
		if (AppStatus.isLandscapeMode() || logosShown || SETTINGS.ERROR_MODE) return;
		if (MenuController.nfbHeaderOpen || (MenuController.nfbHeaderShown && e.originalEvent.touches[0][AppStatus.pageY()] < 49)) {
			return;
		}
		if (MenuController.shown || MenuController.infoPageOpen) {
			// MenuController.touchDown(e);
			return;
		}
		var py = e.originalEvent.touches[0][AppStatus.pageY()];
		if (MenuController.addedToPage && py < 49) return;

		var px = e.originalEvent.touches[0][AppStatus.pageX()] / AppStatus.innerWidth();
		realTouchDownX = px;
		touchDownX = px;
		isDragging = false;
		realDragOffset = 0;
		lastTouchStart = performance.now();
	});

	$('#main').on('touchmove', function(e) {
		if (AppStatus.isLandscapeMode() || logosShown || SETTINGS.ERROR_MODE) return;

		if (MenuController.nfbHeaderOpen) {
			return;
		}
		if (MenuController.shown || MenuController.infoPageOpen) {
			// MenuController.touchMove(e);
			return;
		}
		var py = e.originalEvent.touches[0][AppStatus.pageY()];
		if (MenuController.addedToPage && py < 49) return;

		var px = e.originalEvent.touches[0][AppStatus.pageX()] / AppStatus.innerWidth();
		if (!isDragging) {
			if (!FrameRecorder.recording && (Math.abs(touchDownX-px) > tappingMode?0.04:0.01 || performance.now()-lastTouchStart>=1000)) { // &&  !currentView.lockRight
				isDragging = true;
				tappingMode = false;
				dragSpeed = 0.9;
				isSnapping = false;
				
				dragOffset = touchDownX-px;

				touchDownX += positionOffset;
				if (Math.abs(positionOffset) > 0.005) {
					isDragging = true;
					dragSpeed = 0.9;
				}
				//cancel backwards animation
			}
		}
		if (!isSnapping && isDragging) {
			dragOffset = touchDownX-px;
			realDragOffset = realTouchDownX - px;
			if (isDragging) {
				dragSpeed = 0.9;
				if (dragOffset < 0.0 && lockedLeft) {
					dragSpeed = Utils.ccmap(dragOffset, -0.5, 0.0, 0.0, 0.9);
					dragOffset = Utils.clamp(dragOffset, -0.5, 0.0);
				} else if (dragOffset > 0.0 && lockedRight) {
					dragSpeed = Utils.ccmap(dragOffset, 0, 0.5, 0.9, 0.0);
					dragOffset = Utils.clamp(dragOffset, 0.0, 0.5);
				}
			}
		}
	});

	$('#main').on(SETTINGS.isMobile ? 'touchend' : 'click', function(e) {
		if (AppStatus.isLandscapeMode() || logosShown || SETTINGS.ERROR_MODE) return;
		// if (isSnapping) return;
		// dragOffset = dragOffset;
		// prevAnimationPc = 0.0,
		// prevAnimationStarted = false,
		// prevQueued = false,
		var px = SETTINGS.isMobile ? e.originalEvent.changedTouches[0][AppStatus.pageX()] / AppStatus.innerWidth() : e[AppStatus.pageX()] / AppStatus.innerWidth();
		var py = SETTINGS.isMobile ? e.originalEvent.changedTouches[0][AppStatus.pageY()] / AppStatus.innerHeight() : e[AppStatus.pageY()] / AppStatus.innerHeight();

		//click left of screen
		if (MenuController.nfbHeaderOpen || ((MenuController.nfbHeaderShown||MenuController.addedToPage) && py*AppStatus.innerHeight() < 49)) {
			return;
		
		} else if (MenuController.shown || MenuController.infoPageOpen) {

			// MenuController.touchUp(e);
			return

		} else if (!isDragging && !isSnapping && px * AppStatus.innerWidth() < 95 && py * AppStatus.innerHeight() > AppStatus.innerHeight() - 52 && (!currentView || (currentView && !currentView.noMenu))) {

			MenuController.toggle();

		} else if (px < 0.15 && !isDragging) {

			tappingMode = true;

			//if click on left of screen, go back
			dragSpeed = 0.15;

			if (isSnapping) {dragOffset -= 1.0; dragSpeed=0.15;} else dragOffset =  -1.0;
			dragOffset = Utils.clamp(dragOffset,-2.0,2.0);

			//start snapping!
			isDragging = false;
			isSnapping = true;


			//if target < 0 or is locked (record mode), stick to it
			if (dragOffset < 0.0 && lockedLeft) {
				dragOffset = 0.0;
			} else if (dragOffset > 0.0 && lockedRight) {
				dragOffset = 0.0;
			}


		//jump to next directly
		} else if (!isDragging && !lockedRight && (!isSnapping||(isSnapping &&Math.abs(positionOffset - dragOffset) < 0.035)) ) {// && (!currentView || (currentView && !currentView.doneCallback))) {
			
			//jump!
			isSnapping = false;
			isDragging = false;
			dragSpeed = 0.9;

			positionOffset = 0.0;
			incrementPage(1);
			AppStatus.goingBackwards = false;
			dragOffset = 0.0;
			setViews();

		} else {

			 if(lockedRight && !isDragging && currentView && currentView.onClick) currentView.onClick();

			var continueDirection = Math.sign(realDragOffset);
			var resnap = false;
			if (isSnapping && !isDragging) {realDragOffset = continueDirection;resnap = true;}

			//start snapping!
			isDragging = false;
			isSnapping = true;
			dragSpeed = 0.2;


			//if offset or speed is too small, stick to currentTask
			if (Math.abs(realDragOffset) < 0.15 && !resnap) {
				dragOffset = 0.0;

			//if offset or speed is large, snap direction
			} else {

				dragOffset = realDragOffset < 0.0 ? -1.0 : 1.0;

				//if target < 0 or is locked (record mode), stick to it
				if (dragOffset < 0.0 && lockedLeft) {
					dragOffset = 0.0;
				} else if (dragOffset > 0.0 && lockedRight) {
					dragOffset = 0.0;
				}
			}
		}
	});


	//
	//
	// No sleep enabling function
	//
	//
	var enableNoSleep = function() {
	    // window.nosleep.enable();
	    if (SETTINGS.isMobile && !SETTINGS.isTablet && window.screen && window.screen.orientation && window.screen.orientation.lock) {
	    	window.screen.orientation.lock('portrait-primary').catch(function(){window.screen.orientation.lock('portrait').catch(function(){})});
	    }
	    document.removeEventListener('click', enableNoSleep);
	    document.removeEventListener('touchend', enableNoSleep);
	}

	// Add click and touchend events
	document.addEventListener('click', enableNoSleep, false);
	document.addEventListener('touchend', enableNoSleep, false);

	//
	// Prevent zoom
	//
	if (SETTINGS.isMobile && SETTINGS.isIOS) {
		
		//disable pinch to zoom!
		//doubletap to zoom disabled by css touch:manipulation

		function cancelZoom(event) {
		  if ((event.scale!==undefined && event.scale!=1)  || event.touches.length>1) {
		  	console.log("cancel!");
		  	event.preventDefault(); return false; 
		  }
		}
		document.addEventListener('touchmove', cancelZoom,{passive: false, capture:true}, true);
		document.addEventListener('touchstart', cancelZoom,{passive: false, capture:true}, true);
		window.addEventListener('touchmove', cancelZoom,{passive: false, capture:true}, true);
		window.addEventListener('touchstart', cancelZoom,{passive: false, capture:true}, true);

		var lostFocusTime = 0;
		window.addEventListener("blur", function(event) {
			lostFocusTime = Date.now();
		});
		window.addEventListener("focus", function(event) {
			// if (Date.now() - lostFocusTime >= 60000 && !CameraController.cameraRefused)  {
			// 	CameraController.cameraAllowed = false;
			// 	allowCameraTask.returnFocus();
			// 	allowCameraTask.doneCallback = skipNext;
			// 	shouldAllowCamera = true;
			// }

			if (currentTask && allViews && allViews[currentTask] && allViews[currentTask].refocus) {
				allViews[currentTask].refocus();
			}
		}, false);
	}

		


	//
	// Resize handling
	//
	function resize() {
		AppStatus.resize();
		RendererController.resize();

		if (!orientationWarningShown && AppStatus.isLandscapeMode() && !SETTINGS.ERROR_MODE) {
			AnalyticsController.trackEvent('landscape');
			orientationWarningShown = true;
			$('#orientation-warning').fadeIn(300);
			orientationTimeout = setTimeout(function(){$('#orientation-warning-inner').fadeIn(300); orientationWarningShown = true;}, 330);
		} else if (orientationWarningShown && !AppStatus.isLandscapeMode()) {
			if (orientationTimeout>=0) clearTimeout(orientationTimeout);
			orientationTimeout = -1;
			$('#orientation-warning').hide();
			$('#orientation-warning-inner').hide();
			orientationWarningShown = false;
			if (CameraController.waitingForPortraitMode) CameraController.start(CameraController.waitingForPortraitMode); 
		}
	};
	document.addEventListener("focus", function() {
		console.log("focus");
		resize();
		if (window.AppStatus.cleanedUp) {
			window.AppStatus.selectChapter();
			setTimeout(window.AppStatus.selectChapter, 2000);
		}
	});
	$(window).resize(resize);
	$(window).bind('orientationchange',resize);
	$(document.body).on('orientationchange', resize);
	$(document).on('orientationchange', resize);
	document.addEventListener("visibilitychange", function(e) {
		resize();

		var globalState = MultiStorage.getGlobalState();

		if (!globalState.lastSession || (globalState.lastSession && Date.now()-globalState.lastSession >= 1000 * 60 * 60 * 24 )) {
			var sessionXHR = new LoaderXHR(SETTINGS.API_SERVER_URL+'/start_session', 'text');
			sessionXHR.data.noErrorLoop = true;
			sessionXHR.data.protocol = 'post';
			sessionXHR.data.params = new FormData();
			sessionXHR.data.params.append('user_uuid', SETTINGS.uuid);
			sessionXHR.data.params.append('update', (globalState.lastSession && Date.now()-globalState.lastSession < 1000 * 60 * 60 * 24 * 7 )?1:0);
			sessionXHR.start();

			MultiStorage.setGlobalState(tasksState);
		}
		if (window.AppStatus.cleanedUp) {
			window.AppStatus.selectChapter();
			setTimeout(function() {
				window.location.reload();
			}, 2000);
		}

	}, false);

	//
	// Cleanup upon reloading/changing page
	//
	window.cleanup_tasks = function() {
		disposed = true;

		currentView = null;
		AppStatus.mainView = null;
		// AppStatus.mainView = null;
		for (var i=0; i<allViews.length; i++) {
			try {
				if (allViews[i].isBranch) {
					var views = allViews[i].getViews();
					for (var j=0; j<views.length; j++) {
						views[j].deactivate();
						views[j].stop();
					}
				} else {
					allViews[i].deactivate();
					allViews[i].stop();
				}
			} catch (er) {};
		}
		allViews = [];
	};
	window.addEventListener('cleanup_tasks', window.cleanup_tasks);

	window.onunload = window.onbeforeunload = AppStatus.cleanup;
	window.addEventListener("beforeunload", AppStatus.cleanup, false);






	//-------------------------
	//
	// on ready
	//
	//-------------------------
	$(document).ready(function() {
		//fadeout logo + safety check
		if (SETTINGS.SKIP_LOGOS) {
			$('#logos-container').remove();
			$('#logos-background').remove();
			logosShown = false;
		}
		$('#logos-container').bind('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function(e) { $(this).remove(); logosShown = false;});
		$('#logos-background').bind('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function(e) { $(this).remove(); logosShown = false;});
		setTimeout(function() {
			$('#logos-container').fadeOut(1000);
			$('#logos-background').fadeOut(1000);
			logosShown = false;
		},6000);

		$('#orientation-warning-inner').html(EmbeddedText[SETTINGS.LANGUAGE].system.orientationwarning);

		if (SETTINGS.isIOS) {
			$('#manifest').attr('href', SETTINGS.OTHER_ASSETS_URL+'manifest_ios.webmanifest');
		}

		AppStatus.currentTaskId = SETTINGS.tasklist_id.replace(/\.json|tasks_/gi, '').replace('_fr','');
		if (!SETTINGS.ERROR_MODE && SETTINGS.isMobile) AnalyticsController.trackEvent('chapter', SETTINGS.INTRO_MODE ? 'intro' : AppStatus.currentTaskId);
		if (!SETTINGS.isMobile) AnalyticsController.trackEvent('desktop');

		MultiStorage.setup(SETTINGS.tasklist_id_json, SETTINGS.VERSION);

		//----------------------
		//
		// Automatic redirect on FB/INSTA
		//
		//----------------------
		if (SETTINGS.ENABLE_AUTO_INTENT) {
			AnalyticsController.trackEvent('INTENT_redirect');
			var globalState = MultiStorage.getGlobalState();
			globalState.intentReloaded = true;
			MultiStorage.setGlobalState(globalState);
			window.location.href = SETTINGS.INTENT_LINK;
			window.close();
			// document.body.addEventListener('touchstart', function() {
			// 	window.location.href = SETTINGS.INTENT_LINK;
			// });
			
		}
		if (SETTINGS.ENABLE_AUTO_FTP) {
			// window.open(SETTINGS.FTP_LINK, '_BLANK');
			AnalyticsController.trackEvent('FTP_redirect');
			window.location.href = SETTINGS.FTP_LINK;
			document.body.addEventListener('touchstart', function() {
				// window.open(SETTINGS.FTP_LINK, '_BLANK');
				window.location.href = SETTINGS.FTP_LINK;
			});
		}

		//----------------------
		//
		// Set language for UI divs
		//
		//----------------------
		var tasksState = MultiStorage.getState();
		var globalState = MultiStorage.getGlobalState();
		globalState.language = SETTINGS.LANGUAGE;
		if (globalState.reset) tasksState = {};

		globalState.reset = false;

		SETTINGS.uuid = globalState.uuid || Utils.generateUUID();
		window.tasksState = tasksState;
		tasksState.userVideos = tasksState.userVideos||{};
		tasksState.userVideosByBin = tasksState.userVideosByBin||{};
		tasksState.analysis = tasksState.analysis||{};
		tasksState.uuid = SETTINGS.uuid;
		if (tasksState.currentTask) {
			currentTask = tasksState.currentTask;
			if (tasksState.isOnAllowCamera ) currentTask--;
		}
		if (tasksState.currentTaskInBranch) {
			currentTaskInBranch = tasksState.currentTaskInBranch;
		}
		MultiStorage.setState(tasksState);

		// if (SETTINGS.isIOS) {
			FastClick.attach(document.getElementById('main'));
		// }
		
		manualChapterJump = globalState.manualChapterJump;
		globalState.manualChapterJump = false;
		globalState.savedTimestamp = globalState.savedTimestamp||{};
		globalState.savedTimestamp["first_page_load"] = new Date().getTime();
		globalState.userVideos = globalState.userVideos||{};
		globalState.userVideosByBin = globalState.userVideosByBin||{};
		globalState.userVideosByName = globalState.userVideosByName||{};
		globalState.analysis = globalState.analysis||{};
		globalState.tasksDone = globalState.tasksDone||{};
		globalState.tasksDoneOrder = globalState.tasksDoneOrder||{};
		globalState.tasksFinished = globalState.tasksFinished||{};
		globalState.uuid = SETTINGS.uuid;
		if (globalState.september_gender == undefined) globalState.september_gender = Math.round(Math.random());
		if (SETTINGS.FORCE_SEPTEMBER_GENDER) globalState.september_gender = (SETTINGS.FORCE_SEPTEMBER_GENDER=="m"||SETTINGS.FORCE_SEPTEMBER_GENDER=="male"||SETTINGS.FORCE_SEPTEMBER_GENDER==0)?0:1;
		AppStatus.SEPTEMBER_GENDER = globalState.september_gender||0;
		MultiStorage.setGlobalState(globalState);
		start();
				
	});
})();

