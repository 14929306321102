import SETTINGS from '../controllers/Settings.js';
import * as THREE from 'three';

import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import RendererController from '../controllers/RendererController.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';

import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import SpritesheetVideo from './SpritesheetVideo.js';

//------------------------
class SpritesheetVideoText extends SpritesheetVideo {
	constructor(_info, batchName) {
		super(_info, batchName);

		this.textDebugShown = false;
		this.textDebugScene = new THREE.Scene();
		this.textDebugPlane = null;
		this.textDebugCanvas = null;
		this.textDebugContext = null;
		this.textDebugTexture = null;
	}

	preload(batchName) {
		super.preload(batchName);
	}

	prepare() {
		super.prepare();
	}

	update(opt) {
		super.update(opt);
		if (this.isReady() && this.analysis && !this.textDebugShown && this.contentDiv) {
			this.textDebugShown = true;

			var classesText = this.params.effect.text;
			if (this.params.effect["default-class"] && !(this.analysis.categories[0]||'').replace('_',' ')) {
				classesText = classesText.replace('{class}', this.params.effect["default-class"]);
			} else {
				classesText = classesText.replace('{class}', (this.analysis.categories[0]||'').replace('_',' '));
			}

			for (var i=0; i<5; i++) {
				if (this.params.effect["default-class-"+i] && !(this.analysis.categories[i]||'').replace('_',' ')) {
					classesText = classesText.replace('{class-'+i+'}', this.params.effect["default-class-"+i]);
				} else {
					classesText = classesText.replace('{class-'+i+'}', (this.analysis.categories[i]||'').replace('_',' '));
				}
			}		


			this.textType = this.params.effect["textType"] || 'centered';
			this.textColor = this.params.effect.textColor || (this.params.effect.dark ? '#ffffff' : '#000000');
			this.textBackground = this.params.effect.textBackground || '#ffffff'; //(this.params.effect.textBackground || ( this.params.effect.dark ? '#000000' : false));
			if (!this.params.effect.textColor && !this.textBackground && (this.params.effect.dark || this.params.effect.background == 'black' || this.params.effect.background == '#000000') && this.params.effect.type=='text')
				this.textColor = '#ffffff';
			this.backgroundColor = this.params.effect.background || (this.params.effect.dark?0x000000:0xffffff);

			console.log(this.textBackground);

			var dc = document.createElement('div');
			$(dc).addClass('text-container').addClass(this.textType);
			// $(dc).css('background', 'none');
			$(this.contentDiv).append(dc);

			var d = document.createElement('div');
			$(d).addClass('text').addClass(this.textType);
			$(dc).append(d);


			if (this.params.effect.textSize) $(d).css('font-size', this.params.effect.textSize);
			if (this.params.effect.font) $(d).css('font-family', this.params.effect.font);
			$(d).css('color', this.textColor);
			if (this.textBackground) $(d).css('background-color', this.textBackground);


			// $(d).html(this.params.faces.text||'');
			this.debugDivs = {
				container: dc,
				div:d,
				classid: null,
				visible: false,
				fontSize: 0,
				text: null
			};

			$(this.debugDivs.div).html(classesText);
			// $(this.debugDivs.div).css('font-size', '1em');
		}
	}

	dispose() {
		super.dispose();
		this.debugDivShown = false;

	}
	stop() {
		super.stop();
		if (this.debugDivs) {
			$(this.debugDivs.container).remove();
			$(this.debugDivs.div).remove();
		}
		this.debugDivShown = false;
		this.debugDivs = null;
	}


	render() {
		super.render();
		renderer.render(this.textDebugScene, Utils.topLeftCamera, this.fbo.texture, false);
	}
};

export default SpritesheetVideoText;


