import $ from 'jquery';
import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import LoaderXHR from './LoaderXHR.js';
import JSON5 from 'json5';

function LoaderPrivateXHR(url, type) {
	var self = this,
		completeCallback,
		errorCallback;
	this.data = {loaded:false, doneCallbacks:[]};
	this.url = url;
	this.loaded = false;
	this.loading = false;
	this.progress = 0.0;
	this.weight = 1;
	this.loadType = 'private';
	this.xhr = null;
	this.multipleBatches = false;

	this.data.loaded = false;
	this.doneCallbacks = [];

	this.hasPrivateInfo = false;
	this.privateUrl = null;
	this.privateInfoTime = Date.now();
	self.infoXhr = null;
	this.lastErrorReset = 0;

	//
	// callbacks for Arraybuffer image data
	//
	function xhrDoneInfo() {
		self.hasPrivateInfo = true;
		self.privateUrl = (self.infoXhr.data.value).url;
		self.privateInfoTime = Date.now();
		self.startAb();
	};

	//
	// Callbacks for signed url
	//
	function xhrDone() {
		// self.data.value = new AudioBinaryBuffer(self.xhr.data.value, url);
		// self.data.value.isShared = self.isShared;
		// Utils.clone(self.xhr.data.value, self.data);
		// self.xhr.dispose();
		// self.xhr = null;
		// self.progress = 1.0;
		// self.loaded = true;
		// self.loading = false;
		// console.log("private done blob decoding",self.data.info.video_name);

		self.data.value = self.xhr.data.value;
		self.data.loaded = self.xhr.data.loaded;
		// self.data.loaded = self.xhr.data.loaded;
		// self.data.loadError = self.xhr.data.loadError;

		if (completeCallback) completeCallback(self);
        for (var i=0; i<self.doneCallbacks.length; i++) {
			self.doneCallbacks[i](self);
		}
		self.doneCallbacks = [];
		for (var i=0; i<self.data.doneCallbacks.length; i++) {
			self.data.doneCallbacks[i](self);
		}
		self.data.doneCallbacks = [];
		
	};

	this.startAb = function() {
		// console.log("private starting blob loading",self.data.info.video_name);
		self.xhr = new LoaderXHR(self.privateUrl, 'blob');
		self.xhr.data.noErrorLoop = true;
		self.xhr.start(xhrDone,xhrError);
	};


	//
	// Errors
	//
	function xhrError() {
		console.log("XHR ERROR!",self);
		self.loadError = true;
		self.data.loadError = true;
		if (self.errorCallback) self.errorCallback(self);
		self.errorCallback = null;
	}

	this.start = function(_completeCallback, _errorCallback) {
		if (self.loading || self.loaded) console.error('Already loading: ',url);
		self.loading = true;
		completeCallback = _completeCallback;
		errorCallback = _errorCallback;
        
		// console.log("STARTING PRIVATE XHR",this,self.data.info.video_name);
        if (this.hasPrivateInfo && Date.now()-this.privateInfoTime < (1000*60*60*23)) {
			// console.log("private got recent info", self.data.info.video_name);
        	this.startAb();
        } else {
        	// console.log("getting private info", self.data.info.video_name);
  			// self.infoXhr = new LoaderXHR(SETTINGS.API_SERVER_URL+'/get_private_read_url', 'json');
			// self.infoXhr.protocol = 'post';
			// self.infoXhr.params = new FormData();
			// self.infoXhr.params.append('url', url);
			// self.infoXhr.start(xhrDoneInfo,xhrError);
			//start xhr
			var xhr = new XMLHttpRequest();
	        xhr.onreadystatechange = function() {
	        	if (self.loaded || !xhr.status || self.hasPrivateInfo) return;
	            if ( xhr.readyState === 4 || xhr.status>=400) {
                    self.hasPrivateInfo = true;
	                if ( xhr.status >= 200 && xhr.status <= 299 ) {

	                	self.hasPrivateInfo = true;
						self.privateUrl = JSON.parse(xhr.response).url;
						self.privateInfoTime = Date.now();
						self.startAb();
	                	
	                    //finish loading
	                    xhr.onreadystatechange = null;
	                    xhr.onprogress = null;
	                    xhr.onerror = null;
	                    xhr = null;
	                   
	                //handle errors
	                } else if (xhr.status>=400) {
	                	self.loadError = true;
	                	self.data.loadError = true;
	                	xhr.onreadystatechange = null;
	                    xhr.onprogress = null;
	                    xhr.onerror = null;
	                    xhr = null;
	                    if (completeCallback) completeCallback(self);
	                    for (var i=0; i<self.doneCallbacks.length; i++) {
							self.doneCallbacks[i](self);
						}
						self.doneCallbacks = [];
						if (self.data) {
							for (var i=0; i<self.data.doneCallbacks.length; i++) {
								self.data.doneCallbacks[i](self);
							}
							self.data.doneCallbacks = [];
						}
	                }
	            }
	        };
	        xhr.onerror = self.xhrError;
	        xhr.timeout = 30000;
        	xhr.ontimeout = self.sendError;
	        xhr.open("POST", SETTINGS.API_SERVER_URL+'/get_private_read_url', true);

	        var fd = new FormData();
			fd.append('url', url);
	       	xhr.send(fd);
        }
	};

	this.getProgress = function() { 
		if (self.xhr) return self.xhr.getProgress();
		return self.progress;
	};

	this.dispose = function() {
		if (self.xhr) self.xhr.dispose();
		if (self.infoXhr) self.infoXhr.dispose();
		completeCallback = errorCallback = self.url = self.loadError = self.start = self.getProgress = self.dispose = self.data = null;
	};
	this.reset = function() {
		self.data.loadError = self.loadError = false;
		self.data.loaded = false;
		self.data.value = self.data.response = null;
		self.loading = self.loaded = false;
	};
	this.getWeight = function() {
		return this.weight;
	};
}

//external ref
export default LoaderPrivateXHR;

