import SETTINGS from './Settings.js';
import PageLoader from '../loading/PageLoader.js'
import MultiStorage from '../utils/MultiStorage.js'

class AnalysisController {

	constructor() {
		//constants
		this.ANALYSIS_URL = {
			// "faces_info": "/faces_info",
			"info_places": "/analysis_places",
			"info_closeobject": "/analysis_resnet",
			"info_closeobject_precise": "/analysis_resnet_21k",
			"info_objects": "/analysis_yoloinfo",

			"faces": "/analysis_faces",
			"poses": "/analysis_poses",
			"objects": "/analysis_boxes_extreme_flow",
			"all_objects": "/analysis_allboxes",
			"brightest": "/analysis_brightest",
			"objects_mask": "/analysis_extremenet_mask",

			"hands": "/analysis_hands",
			"all_objects_precise": "/analysis_allboxes_precise",

			"optical_flow_center": "/analysis_optical_flow_center",

			// "shape_mask": "/analysis_shape",
			"point_all": "/analysis_allboxes_nms",
			// "all_objects_class": "/analysis_allboxes_class",
			// "all_faces": "/analysis_all_faces",
			// "boxes_a": "/analysis_boxes_a",
		};
		this.ANALYSIS_FORCE_FRAMES = {
			"info_places": {numFrames: 8, width: 224, height: 398},
			"info_closeobject": {numFrames: 4, width: 299, height: 532},
			"info_closeobject_precise": {numFrames: 4, width: 299, height: 532},
			"info_objects": {numFrames: 3, width: 608, height: 1081},
		};
		this.ANALYSIS_IS_BINARY = {
			"objects_mask": true,
			"mask_faces": true,
			"shape_mask": true
		};
		this.ANALYSIS_DEBUG_DEFAULTS = {
			"hands": {
				"type": "debugHands"
			},
			"optical_flow_center": {
				"type": "boxes",
				"scale": 1.0,
				"lineWidth": 4.0,
				"color": "#ff0000",
				"debug": true
			},
			"objects": {
				"type": "boxes",
				"scale": 1.0,
				"lineWidth": 4.0,
				"color": "#ff0000",
				"debug": true
			},
			"all_objects_precise":{
				"type": "boxes",
				"scale": 1.0,
				"lineWidth": 4.0,
				"color": "#ff0000",
				"debug": true
			},
			"boxes_a": {
				"type": "boxes",
				"scale": 1.0,
				"lineWidth": 4.0,
				"color": "#ff0000",
				"debug": true
			},
			"brightest": {
				"type": "boxes",
				"scale": 1.0,
				"lineWidth": 4.0,
				"color": "#ff0000",
				"debug": true
			},
			"all_objects": {
				"type": "boxesSelect",
				"scale": 1.0,
				"lineWidth": 4.0,
				"color": "#ff0000",
				"debug": true
			},
			"point_all": {
				"type": "point_all",
				"scale": 1.0,
				"lineWidth": 4.0,
				"color": "#ff0000",
				"debug": true
			},
			"all_objects_class": {
				"type": "boxesSelect",
				"scale": 1.0,
				"lineWidth": 4.0,
				"color": "#ff0000",
				"debug": true
			},
			"all_faces":{
				"type": "boxesSelect",
				"scale": 1.0,
				"lineWidth": 4.0,
				"color": "#ff0000",
				"debug": true
			},
			// "all_objects_places": {
			// 	"type": "boxesSelect",
			// 	"scale": 1.0,
			// 	"lineWidth": 4.0,
			// 	"color": "#ff0000",
			// 	"debug": true
			// },
			"info_places": {
				"type": "debugText"
			},
			"info_closeobject": {
				"type": "debugText"
			},
			"info_closeobject_precise": {
				"type": "debugText"
			},
			"info_objects": {
				"type": "debugText"
			},
			"poses": {
				"type": "debugPoses"
			},
			"objects_mask": {
				"type": "debugMask"
			},
			"shape_mask": {
				"type": "debugMask"
			}
		}
		this.ANALYSIS_DEBUG_FORCE_DURATION = {
			"objects_mask": 2.0,
			"shape_mask": 2.0
			// "info_places": 4/24,
			// "info_closeobject": 4/24,
			// "info_objects": 4/24
		};

		//for loading status
		this.analysisById = {};
		this.statusById = {};
		this.expectedUUID = {};
		this.callbacksById = {};
		this.xhrById = {};
	}

	setLoading(id) {
		this.statusById[id] = 1;
	}


	addAnalysis(id, response) {
		this.analysisById[id] = response;
		this.statusById[id] = 2;

		//setting storage
		var tasksState = MultiStorage.getGlobalState();
		tasksState.analysis[id] = response;
		MultiStorage.setGlobalState(tasksState);
			
		//add to spritesheets
		if (this.callbacksById[id]) {
			for (var i=0; i<this.callbacksById[id].length; i++) {
				this.callbacksById[id][i](response);
			}
		}
		this.callbacksById[id] = null;
	}

	getAnalysis(uuid, video_id, isUser, analysis, analysisParams, batchName) {

		if (isUser) {
			// if (!isReloaded) {
				return new Promise(function(resolve) {
					if (this.analysisById[uuid]) {
						resolve(this.analysisById[uuid]);
					} else {
						this.callbacksById[uuid] = this.callbacksById[uuid]||[];
						this.callbacksById[uuid].push(resolve);
					}
				}.bind(this));
			// } else { //load from localstorage
			// 	return new Promise(function(resolve) {
			// 		if (this.analysisById[uuid]) {
			// 			resolve(this.analysisById[uuid]);
			// 		} else {
			// 			this.callbacksById[uuid] = this.callbacksById[uuid]||[];
			// 			this.callbacksById[uuid].push(resolve);
			// 		}
			// 	}.bind(this));
			// }
		} else {
			return new Promise((resolve) => {
				if (this.analysisById[uuid] && !SETTINGS.EDITOR_MODE && (!SETTINGS.BINS_APP_MODE&& !SETTINGS.MODERATION_MODE) ) {
					console.log("already got analysis");
					resolve(this.analysisById[uuid]);
				} else {
					// var xhr = this.xhrById[uuid] ;
					// if (!xhr || SETTINGS.EDITOR_MODE || SETTINGS.BINS_APP_MODE) {
					// 	console.log("doing analysis")
					var xhr = PageLoader.addXHR(batchName, SETTINGS.API_SERVER_URL+this.ANALYSIS_URL[analysis], this.ANALYSIS_IS_BINARY[analysis]?'arraybuffer':'json', true);
					xhr.noErrorLoop = true;
					xhr.protocol = 'post';
					xhr.params = new FormData();
					xhr.params.append('video_id', video_id);
					xhr.params.append('video_name', uuid);
					xhr.params.append('uuid', uuid);
					xhr.params.append('others', true);
					xhr.params.append('params', JSON.stringify(analysisParams?analysisParams:{}));
					xhr.doneCallbacks.push((e) => {
						this.analysisById[uuid] = e.data.value;
						resolve(e.data.value);
					});

					xhr.errorCallbacks = [];
					xhr.errorCallbacks.push((e) => {
						console.log("Analysis Error")
						this.analysisById[uuid] = {};
						resolve({});
					});
				}
			});
		}

		//load info from server
		return new Promise(function(resolve) {
			// resolve(this.analysisById[uuid]);
		}.bind(this));
	}
};

export default new AnalysisController();