import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import Utils from '../utils/Utils.js';
import MultiStorage from '../utils/MultiStorage.js';
import RendererController from '../controllers/RendererController.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';
import TextureUpdateController from '../controllers/TextureUpdateController.js'
import OpticalFlowController from '../controllers/OpticalFlowController.js';

import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import SpritesheetVideo from './SpritesheetVideo.js';

//--------------------------
//
// var CanvasTextWrapper = require('canvas-text-wrapper').CanvasTextWrapper;
//
//------------------------
class SpritesheetVideoOpticalFlow extends SpritesheetVideo {
	
	constructor() {
		super();
		this.boxesScene = new THREE.Scene();
		this.boxes = [];
		this.arrowDiv = null;

		//
		// Debug the class in the boxes
		//
		this.boxDebugPlanes = [];
		this.boxDebugCanvas = [];
		this.boxDebugContext = [];
		this.trackingEnabled = true;
		this.trackingCenter = new THREE.Vector2(0.5,0.5);
		this.boxDebugTexture = [];

		this.selectedBox = false;
		this.selectedBoxId = 0;
		console.log("NEW ARROW");

	}

	preload(batchName) {
		super.preload(batchName);
		this.arrowTexture = Loader.addTexture(batchName, SETTINGS.IMAGES_URL+"images/arrow.png", {
			format: THREE.RGBAFormat,
			wrapping: THREE.ClampToEdgeWrapping,
			minFilter: THREE.LinearFilter,
			magFilter: THREE.LinearFilter,
			generateMipmaps: false,
			upload: true,
			noCompression: true
		});
		// this.highlightTexture = Loader.addTexture(batchName, SETTINGS.IMAGES_URL+"images/circle_tmp.png", {
		// 	format: THREE.RGBAFormat,
		// 	wrapping: THREE.ClampToEdgeWrapping,
		// 	minFilter: THREE.LinearFilter,
		// 	magFilter: THREE.LinearFilter,
		// 	generateMipmaps: false,
		// 	upload: true,
		// 	noCompression: true
		// });
	}

	play(contentDiv, textDiv) {
		super.play(contentDiv, textDiv);
		OpticalFlowController.setupCV();
		OpticalFlowController.activate(this);

	}
	
	prepare() {
		super.prepare();
		OpticalFlowController.setupCV();
	}
	
	onAnalysisLoaded(info) {
		super.onAnalysisLoaded(info);
	}
	
	stop() {
		// for (var i in this.arrowDiv) {
		this.selectedBox = false;
		if (this.arrowDiv) {
			$(this.arrowDiv.container).remove();
			$(this.arrowDiv.div).remove();
		}
		// }
		this.boxesScene = new THREE.Scene();
		this.arrowPlane = null;
		this.highlightPlane = null;
		this.arrowDiv = null;
		super.stop();
		this.highlightCanvas = null;
		if (this.highlightTexture) this.highlightTexture.dispose();
	}
	
	update(opt) {
		super.update(opt);

		if (this.isReady()) { //} && this.analysis && this.contentDiv) {
			
			OpticalFlowController.update();

			var box = [OpticalFlowController.getCenterPoint().x, OpticalFlowController.getCenterPoint().y, 0.05, 0.05];
			this.trackingCenter.set(box[0]+box[2]/2, box[1]-box[3]);

			//------------------------
			//
			// Rank and select boxes
			//
			//------------------------
			if (!this.selectedBox) {
				this.selectedBox = true;

				if (!this.arrowTexture) {
					this.preload(Utils.generateUUID());
				}
				if (!this.arrowPlane) {
					// console.log(this.arrowTexture, this.arrowTexture.image, this.arrowTexture.image.loaded);
					// TextureUpdateController.update(this.arrowTexture);
					var mat = new THREE.MeshBasicMaterial( { color: 0xffffff, depthTest: false, depthWrite: false, map: this.arrowTexture, side: THREE.DoubleSide, transparent: true, opacity: 1.0, blending: THREE.NormalBlending } );
					var p = new THREE.Mesh( Utils.planeGeometry, mat );
					p.position.set(0.5, 0.5, 0.0);
					this.boxesScene.add(p);
					this.arrowPlane = p;

					this.highlightCanvas = document.createElement('canvas');
					var tex = new THREE.Texture(this.highlightCanvas);
					tex.format = THREE.RGBAFormat;
					tex.type = THREE.UnsignedByteType;
					tex.wrapT = tex.wrapS = THREE.ClampToEdgeWrapping;
					tex.magFilter = THREE.LinearFilter;
					tex.minFilter =THREE.LinearFilter;
					tex.generateMipmaps = false;
					tex.anisotropy = 1;
					tex.needsUpdate = true;
					this.highlightTexture = tex;
					// this.highlightTexture.image = this.highlightCanvas;

					this.highlightPlane = new THREE.Mesh(Utils.planeGeometry, new THREE.MeshBasicMaterial( {
						 color: 0xffffff, 
						 side: THREE.DoubleSide,
						 transparent: true,
						 opacity: 1.0,
						 blending: THREE.NormalBlending,
						 depthTest: false,
						 depthWrite: false,
						 map: this.highlightTexture
					}));
					this.boxesScene.add(this.highlightPlane);
					this.highlightPlane.visible = !!this.params.effect.highlight;


					// var dc = document.createElement('div');
					// $(dc).addClass('spritesheet-overlay-text-container');
					// // $(dc).css('background', 'none');
					// $(this.contentDiv).append(dc);

					// var d = document.createElement('div');
					// $(d).addClass('spritesheet-overlay-text');
					// $(d).css('color', 'black');
					// $(dc).append(d);

					var dc = document.createElement('div');
					$(dc).addClass( (this.params.effect.textType=='top' || this.params.effect.textType=='center') ? 'text-container' : 'spritesheet-overlay-text-container');
					if ((this.params.effect.textType=='top' || this.params.effect.textType=='center')) $(dc).addClass(this.params.effect.textType);					
					$(this.contentDiv).append(dc);

					var d = document.createElement('div');
					$(d).addClass( (this.params.effect.textType=='top' || this.params.effect.textType=='center') ? 'text' : 'spritesheet-overlay-text');
					$(d).css('color', 'black');
					if (this.params.effect.textType=='top' || this.params.effect.textType=='center') $(d).addClass(this.params.effect.textType);
					$(d).css('background-color', 'white');
					$(d).css('transform', 'None');

					$(dc).append(d);


					// $(d).html(this.params.faces.text||'');
					this.arrowDiv = {
						container: dc,
						div:d,
						classid: null,
						visible: false,
						fontSize: 0,
						text: null
					};

					var classesText = this.params.effect.text||'';
					// if (this.params.effect["default-class"] && !this.analysis.classes[0][this.selectedBoxId]) {
					// 	classesText = classesText.replace('{class}', this.params.effect["default-class"]);
					// } else {
					// 	classesText = classesText.replace('{class}', this.analysis.classes[0][this.selectedBoxId]);
					// }
					this.arrowDiv.classid = classesText;
					$(this.arrowDiv.div).html(classesText);

					// var rects = this.analysis.rectangles[0+this.frameOffset];
					// if (rects) {
					// 	var box = rects[this.selectedBoxId];
					// 	if (box) p.position.set(box[0]+box[2]/2,box[1]+box[3]/2, 0.0);
					// 	if (box) this.invdir = ((box[1]-box[3]/2) < 0.15 && (box[1]+box[3]) < 0.95) ? true : false;
					// 	console.log(">>>",this.invdir,p.position);
					// }
				}


				//
				// Crop start/end time
				//
				// var startFrame = this.numFrames-1;
				// var endFrame = 0;
				// for (var i=0; i<this.analysis.rectangles.length; i++) {
				// 	var rects = this.analysis.rectangles[i+this.frameOffset];
				// 	if (rects && rects.length && rects[this.selectedBoxId] && this.selectedBox) {
				// 		var box = rects[this.selectedBoxId];	
				// 		if (box) {
				// 			if (box[0] < 0.97 && box[0]+box[2] > 0.03 && box[1] < 0.97 && box[1]+box[3] > 0.03) {
				// 				startFrame = Math.min(startFrame, i);
				// 				endFrame = Math.max(endFrame, i);
				// 			}
				// 		} else {
				// 			// startFrame = Math.min(startFrame, i);
				// 			// endFrame = Math.max(endFrame, i);
				// 		}

				// 	} else {
				// 		// startFrame = Math.min(startFrame, i);
				// 		// endFrame = Math.max(endFrame, i);
				// 	}

				// }	

				// if (this.params.effect.cropDuration) {
				// 	this.numFrames = Math.max(endFrame-startFrame,1);
				// 	this.params.duration = Math.max(endFrame-startFrame-1, 1) / 24;
				// 	this.startOffset = startFrame;
				// 	// if (this.params.palindrome) {
				// 	// 	this.params.duration *= 2.0;
				// 	// }
				// 	console.log(startFrame, endFrame, this.params.duration);
				// }

			}

			var currentFrame = this.lastFrame;

			//--------------------
			//
			// Update arrow & text
			//
			//-------------------
			// var rects = this.analysis.rectangles[currentFrame+this.frameOffset];
			if (true) {
				// for (var i=0; i<rects.length; i++) {

				
				// var box = rects[this.selectedBoxId];

				var box = [OpticalFlowController.getCenterPoint().x, OpticalFlowController.getCenterPoint().y, 0.05, 0.05];


				// this.invdir = false;
				var invdirmult = this.invdir ? -1 : 1;
				var invdirmult2 = this.invdir ? -1 : 0;

				var p = this.arrowPlane;
				p.scale.set(0.1*16/9,0.1*invdirmult,0.1).multiplyScalar(0.6); //box[2], box[3], 1.0);
				p.position.lerp(new THREE.Vector3(box[0]+box[2]/2,box[1]-box[3]*invdirmult2 - 0.055*invdirmult, 0.1), 0.5);

				this.highlightPlane.position.set(box[0]+box[2]/2,box[1]+box[3]/2,0);
				this.highlightPlane.scale.set(box[2], box[3], 1.0).multiplyScalar(1.3);
				this.highlightPlane.visible = this.params.effect.highlight||false;

				p.position.y = Utils.clamp(p.position.y, 0.1, 0.8);

				var scale = (this.params.effect.scale||1.0);

				p.scale.x *= scale;
				p.scale.y *= scale;
				// p.scale.x = Math.max(p.scale.x,0.001);
				// p.scale.y = Math.max(p.scale.x,0.001);
				// p.material.uniforms.tint.set(this.params.effect.color || '#ffffff');
				p.visible = (this.params.effect.arrow===true);
				if (this.arrowDiv) {

					var renderWidth = renderer.getSize().width,
						renderHeight = renderer.getSize().height;
					if (SETTINGS.STORYBOARD_MODE) {
						renderWidth = 162;
						renderHeight = 288;
					}
					var w = (p.scale.x*renderWidth);
					var h = (Math.abs(p.scale.y)*renderHeight);
					var fontSize =  Utils.clamp((w / 15), '0.5', '1.1');
					$(this.arrowDiv.div).css('font-size', fontSize +'em');
					w = Math.max(w, $(this.arrowDiv.container).width());
					h = Math.max(h, $(this.arrowDiv.div).height());

					var nx = Utils.clamp(p.position.x*renderWidth -w/2, 0, renderWidth-w);
					var ny = Utils.clamp((p.position.y-0.075*invdirmult)*renderHeight - h/2, 0, renderHeight-h);

					if (this.params.effect.textType!=='top' && this.params.effect.textType!=='center') {
						$(this.arrowDiv.container).css({
							'transform': 'translate('+(nx)+'px,'+(ny )+'px)',
							'width': w+'px',
							'height': h+'px'
						});
					}


					this.highlightCanvas.width = Math.max(Math.round(this.highlightPlane.scale.x * renderWidth*2),6);
					this.highlightCanvas.height = Math.max(Math.round(Math.abs(this.highlightPlane.scale.y) * renderHeight*2),6);

					var ctx = this.highlightCanvas.getContext('2d');
					ctx.clearRect(0,0,this.highlightCanvas.width,this.highlightCanvas.height);
					
					
					for (var j=5; j>-1; j--) {
						ctx.beginPath();
						ctx.fillStyle = ctx.strokeStyle = '#ffffff';
						ctx.lineWidth = 2.5+j*0.6;
						ctx.globalAlpha = 0.15;

						ctx.ellipse(this.highlightCanvas.width/2, this.highlightCanvas.height/2, this.highlightCanvas.width/2-5, this.highlightCanvas.height/2-5, 2 * Math.PI, 0, 2 * Math.PI, false);
						ctx.closePath();
						ctx.stroke();
					}
					

					ctx.beginPath();
					ctx.lineWidth = 2.5;
					ctx.globalAlpha = 1.0;
					ctx.fillStyle = 'white';
					ctx.strokeStyle = 'white';
					ctx.ellipse(this.highlightCanvas.width/2, this.highlightCanvas.height/2, this.highlightCanvas.width/2-5, this.highlightCanvas.height/2-5, 2 * Math.PI, 0, 2 * Math.PI, false);
					ctx.closePath();
					ctx.stroke();

					// ctx.fill();
					this.highlightTexture.image = this.highlightCanvas;
					this.highlightTexture.needsUpdate = true;

					if (!this.arrowDiv.visible) {
						$(this.arrowDiv.container).css('display', 'table');
						this.arrowDiv.visible = true;
					}
				}
			}

		} else {
			
			if (this.selectedBox && this.arrowPlane) {
				this.arrowPlane.visible = false;
				this.highlightPlane.visible = false;
				// if (this.params.effect.debug) {
					if (this.boxDebugPlanes[this.selectedBoxId]) this.boxDebugPlanes[this.selectedBoxId].visible = false;
					if (this.arrowDiv && this.arrowDiv.visible) {
						$(this.arrowDiv.container).hide();
						this.arrowDiv.visible = false;
						this.highlightPlane.visible = false;
					}
				// }
			}
		}
		// }
	}

	dispose() {
		super.dispose();

	}

	render() {
		super.render();
		// renderer.render(this.boxesScene, Utils.topLeftCamera, this.fbo.texture, false);

	}
};

export default SpritesheetVideoOpticalFlow;


