import $ from 'jquery';

import SETTINGS from './Settings.js';
import AppStatus from './AppStatus.js';
import EmbeddedText from './EmbeddedText.js';
import SoundController from './SoundController.js';
import AnalyticsController from './AnalyticsController.js';
import ClipboardJS from 'clipboard';

window.ClipboardJS = ClipboardJS;

// var load_framework = (function() {
//     var indexOptions = "";
//     function init(indexOptions, statics_root_url) {
//         if (!statics_root_url){
//             statics_root_url = "https://interactive-pip.nfb.ca/static/";
//         }
//         load_framework.indexOptions = indexOptions;
//         $('head').append( $('<link rel="stylesheet" type="text/css" />').attr('href', statics_root_url + 'css/interactive-framework-header.min.css') );
//         var listener = setInterval(function(){
//             if($("body").css("page-break-after")=== "always"){
//                 clearInterval(listener);
//                 $("body").css("page-break-after","auto");
                
//                 $.when(
//                     $.ajax({crossDomain: true, type: "GET", url: statics_root_url + "../templates/header_iframe_parent.html", dataType: "html"}),
//                     $.ajax({crossDomain: true, type: "GET", url: statics_root_url + "js/lib/interactive_framework_lib.min.js", dataType: "script"}),
//                     $.ajax({crossDomain: true, type: "GET", url: statics_root_url + "js/header.js", dataType: "script"}),
//                     $.ajax({crossDomain: true, type: "GET", url: statics_root_url + "js/nfb_ga.js", dataType: "script"}))
//                 .then(function(htmlReturned, cssReturned) {
//                     $('body').prepend(htmlReturned[0]);
//                     header.init(indexOptions, statics_root_url);
//                     $('#containerOverlay').append('<iframe  width="100%" height="100%" id="overlayIframe" name="overlayIframe"></iframe>'); //add iframe src
//                     $('#overlayIframe').attr('src', statics_root_url + '../templates/iframe_child.html');
//                     $(document).trigger( "ifw_headerLoaded");
//                 });
//             }
//         },50);
//     }


//     return {
//         init: init,
//         load_framework: load_framework
//     };
// })();


function MenuController() {
	this.shown = false;
	this.dragging = false;
	this.animationTimeout = -1;
	this.infoAnimationTimeout = -1;

	this.nfbHeaderShown = false;
	this.infoPageOpen = false;
	this.aboutPageOpen = false;
	this.creditsPageOpen = false;
	this.sharePageOpen = false;
	this.initialized = false;

	this.chapterNumber = {};
	this.chapterPartOneTwo = '';

	this.addedToPageMode = false;


	// this.drag
	this.init = function() {
		this.initialized = true;
		if (SETTINGS.MENU_DISABLED) return;


		$('#menu').on('touchstart', this.touchDown);
		$('#menu').on('touchend', this.touchUp);
		$('#menu').on('touchmove', this.touchMove);
		// $('#menu').toggleClass('animate-up', true);

		//
		// Language SETTINGS
		//
		if (SETTINGS.LANGUAGE == 'fr') {
			$('#menu-header-language').text('EN');
			$('#menu-text-title').html(EmbeddedText[SETTINGS.LANGUAGE].menu.allchapters);
			$('#restart-motto').html(EmbeddedText[SETTINGS.LANGUAGE].menu.restartmotto);
			$('#menu-text-chapter1').html(EmbeddedText[SETTINGS.LANGUAGE].menu["chapter1"]);
			$('#menu-text-chapter3').html(EmbeddedText[SETTINGS.LANGUAGE].menu["chapter3"]);
			$('#menu-text-chapter4').html(EmbeddedText[SETTINGS.LANGUAGE].menu["chapter4"]);
			$('#menu-text-chapter5').html(EmbeddedText[SETTINGS.LANGUAGE].menu["chapter5"]);
			$('#menu-text-chapter6').html(EmbeddedText[SETTINGS.LANGUAGE].menu["chapter6"]);
			$('#menu-text-in2').html(EmbeddedText[SETTINGS.LANGUAGE].menu["in2"]);
			$('#menu-text-out2').html(EmbeddedText[SETTINGS.LANGUAGE].menu["in2"]);
		}
		$('#about-page').html(EmbeddedText[SETTINGS.LANGUAGE].pages["about"]);
		$('#credits-page').html(EmbeddedText[SETTINGS.LANGUAGE].pages["credits"]);
		$('#share-page').html(EmbeddedText[SETTINGS.LANGUAGE].pages["share"]);
		// if (SETTINGS.isMobile) {
		// 	$('.about-link').toggleClass('disabled', true);
		// 	$('.about-link').attr('href','');
		// }
		/*
		<div class="share-large">
					<div class="share-btn" id="share-facebook">
						<div id="facebook-logo"></div>
						<div class="share-text">Facebook</div>
					</div>
				</div>
				<div class="share-large">
					<div class="share-btn" id="share-twitter">
						<div id="twitter-logo"></div>
						<div class="share-text">Twitter</div>
					</div>
				</div>
				<div class="share-large" id="share-link-container">
					<div class="share-btn" id="share-btn-link">
						<div id="sharelink-logo" id="share-link"></div>
						<div class="share-text">Copy Link</div>
					</div>
				</div>
				*/

		//share page events
		if (!ClipboardJS.isSupported()) {
			$('#share-link-container').hide();
		} else {
			$('#share-btn-link').on('touchstart',function() {
				$('#sharelink-logo').toggleClass('active',true);
				$('#share-btn-link').find('.share-text').toggleClass('active',true);
			}).on('touchend', function() {
				$('#sharelink-logo').toggleClass('active',false);
				$('#share-btn-link').find('.share-text').toggleClass('active',false);
			})
				
			$('#share-facebook').on('touchstart',function() {
				$('#facebook-logo').toggleClass('active',true);
				$('#share-facebook').find('.share-text').toggleClass('active',true);
			}).on('touchend', function() {
				$('#facebook-logo').toggleClass('active',false);
				$('#share-facebook').find('.share-text').toggleClass('active',false);
			}).on('click', function(e) {
				window.open('http://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent(EmbeddedText[SETTINGS.LANGUAGE].meta.url),'_blank');
			});

			$('#share-twitter').on('touchstart',function() {
				$('#twitter-logo').toggleClass('active',true);
				$('#share-twitter').find('.share-text').toggleClass('active',true);
			}).on('touchend', function() {
				$('#twitter-logo').toggleClass('active',false);
				$('#share-twitter').find('.share-text').toggleClass('active',false);
			}).on('click',function(e) {
				if (SETTINGS.isMobile) {
					window.open('https://twitter.com/intent/tweet?text=' + encodeURIComponent(EmbeddedText[SETTINGS.LANGUAGE].meta.share_twitter) + '&url='+encodeURIComponent('www.motto.io'+(SETTINGS.LANGUAGE=='fr'?'/fr':'')),'_blank');
				} else { //must add https on desktop for some reason
					window.open('https://twitter.com/intent/tweet?text=' + encodeURIComponent(EmbeddedText[SETTINGS.LANGUAGE].meta.share_twitter) + '&url='+encodeURIComponent('https://www.motto.io'+(SETTINGS.LANGUAGE=='fr'?'/fr':'')),'_blank');
				}
			});

			this.clip = new ClipboardJS(document.getElementById('share-btn-link'), {
			    text: function(trigger) {
			        return 'https://www.motto.io'
			        // EmbeddedText[SETTINGS.LANGUAGE].meta.url
			    }
			});

			this.clip.on('success', function(e) {
				$('#share-btn-link').find('.share-text').html(SETTINGS.LANGUAGE=='fr'?'Copié':'Copied')
				$('#share-btn-link').toggleClass('copied', true);
				setTimeout(function() {
					$('#share-btn-link').toggleClass('copied', false);
				},200);
			    e.clearSelection();
			});

			this.clip.on('error', function(e) {
			});
		}


		//
		// NFB Header
		//
		if (!SETTINGS.BETA_MODE) {
			$(document).on('ifw_openOverlay', ()=>{this.infoPageOpen=true;});
			$(document).on('ifw_closeOverlay', ()=>{if (this.shown && this.infoPageOpen) { this.hide(); }this.infoPageOpen=false;});
			var indexOptions = {
		        currentProjectId: 117,
		        displaySoundToggle: false,
		        base_url: {
		            fr: "http://grasslands.nfb.ca/",
		            en: "http://laplaine.onf.ca/"
		        }
		    };
		    $(document).on('ifw_headerLoaded', ()=>{
		    	this.nfbHeaderShown = true;
		    	$('#interactive-header').toggleClass('animate-up', 'true');
		    	$('#interactive-header').css('transition','transform 0.2s ease-out');
		    	$('#interactive-header').css('pointer-events', 'none');
		    	$('#interactive-header').hide();
		  //   	document.getElementById('interactive-header').addEventListener(SETTINGS.isMobile ? 'touchend' : 'click', function(e) {
			 //    	if (!this.shown) {
			 //    		e.preventDefault();
			 //    		return false;
			 //    	}
				// }.bind(this),{passive: false});
				// document.getElementById('interactive-header').addEventListener(SETTINGS.isMobile ? 'touchstart' : 'mousedown', function(e) {
			 //    	if (!this.shown) {
			 //    		e.preventDefault();
			 //    		return false;
			 //    	}
				// }.bind(this),{passive: false});
		    });
		    // if (window.load_framework) load_framework.init(indexOptions);

		    
		}
		
		if (SETTINGS.INTRO_MODE || !AppStatus.MAIN_LOADED) {
	    	$('#menu-bar-container').hide();
	    	$('#menu-pages').hide();
	    }

		$('#menu-header-info-container').on('click',this.openAbout);
		$('#menu-header-share-container').on('click',this.openShare);

		$('#menu-header-language').on('click',AppStatus.changeLanguage);

		$('#menu-header-back').on('click',this.closeInfoDelayed);
		$('#info-back-button').on('click',this.closeInfo);



	    //menu items
		var globalState = MultiStorage.getGlobalState();
		var tasksDone = (globalState.tasksDone||{});
		var tasksDoneOrder = (globalState.tasksDoneOrder||{});

		var isInside = true;
		if (tasksDone['in1']) isInside = true;
		if (tasksDone['out1']) isInside = false;

		this.chapterNumber['in1'] = 1;
		this.chapterNumber['out1'] = 1;
		this.chapterNumber['in2'] = 2;
		this.chapterNumber['out2'] = 2;
		this.chapterNumber['in3'] = 3;
		this.chapterNumber['out3'] = 3;
		this.chapterNumber['in4'] = 4;
		this.chapterNumber['out4'] = 4;
		this.chapterNumber['ghost5'] = 5;
		this.chapterNumber['end6'] = 6;


		/*
		2 1/2 : Such Nice Garbage

if you do in2 next:
2 1/2 : Chairs of the World
*/
		//
		// Add proper classes to menu items
		//
		if (tasksDone['in1'] || tasksDone['out1'] || SETTINGS.INTRO_MODE || !AppStatus.MAIN_LOADED) $('#menu-chapter-1').addClass('read');


		if (window.innerHeight<=555) {
			$('.menu-chapter').toggleClass('smaller',true);
			$('#menu-chapter-1').toggleClass('smaller',true);
		}

		//did both in2 and out2
		this.chapterPartOneTwo = EmbeddedText[SETTINGS.LANGUAGE].menu["in2"];
		if (tasksDone['in2'] && tasksDone['out2']) {
			$('#menu-chapter-in2').addClass('read');
			$('#menu-chapter-out2').addClass('read');
			$('#menu-text-chapter5').html(EmbeddedText[SETTINGS.LANGUAGE].menu["chapter5_alt"]);

			$('.menu-chapter').toggleClass('smaller',true);
			$('#menu-chapter-1').toggleClass('smaller',true);

			// if (window.innerHeight <= 590) {

			// 	$('.menu-chapter').toggleClass('smallest',true);
			// 	$('#menu-chapter-1').css('padding-top', '0px');

			// } else if (window.innerHeight <= 660) {
			// 	$('.menu-chapter').toggleClass('smaller',true);
			// 	$('#menu-chapter-1').css('padding-top', '0px');
			// }


			//part # display
			if (tasksDoneOrder['in2'] > tasksDoneOrder['out2']) {
				// if (SETTINGS.LANGUAGE=='en') {
					this.chapterPartOneTwo =
						(AppStatus.currentTaskId == 'out2') ? EmbeddedText[SETTINGS.LANGUAGE].menu["in2"] :
						(AppStatus.currentTaskId == 'in2') ? EmbeddedText[SETTINGS.LANGUAGE].menu["in2_alt"] :
						'';
					$('#menu-text-out2').html(EmbeddedText[SETTINGS.LANGUAGE].menu["in2"]);
					$('#menu-text-in2').html(EmbeddedText[SETTINGS.LANGUAGE].menu["in2_alt"]);

				// } else {
				// 	this.chapterPartOneTwo =
				// 		(AppStatus.currentTaskId == 'out2') ? EmbeddedText[SETTINGS.LANGUAGE].menu["out2"] :
				// 		(AppStatus.currentTaskId == 'in2') ? EmbeddedText[SETTINGS.LANGUAGE].menu["in2_alt"] :
				// 		'';
				// 	$('#menu-text-out2').html(EmbeddedText[SETTINGS.LANGUAGE].menu["out2"]);
				// 	$('#menu-text-in2').html(EmbeddedText[SETTINGS.LANGUAGE].menu["in2_alt"]);

				// }
				
			} else {

				$('#menu-text-in2').html(EmbeddedText[SETTINGS.LANGUAGE].menu["in2"]);
				$('#menu-text-out2').html(EmbeddedText[SETTINGS.LANGUAGE].menu["out2"]);
				this.chapterPartOneTwo =
					(AppStatus.currentTaskId == 'in2') ? EmbeddedText[SETTINGS.LANGUAGE].menu["in2"] :
					(AppStatus.currentTaskId == 'out2') ? EmbeddedText[SETTINGS.LANGUAGE].menu["out2"] :
					'';
			}


			//increment all chapter numbers
			var done3 = tasksDone["in3"] || tasksDone['out3'];
			var max3 = done3 ? Math.max(tasksDoneOrder['in3']||0,tasksDoneOrder['out3']||0) : 99;
			if (done3 && max3 < tasksDoneOrder['in2']) {
				$('#menu-chapter-3').after($('#menu-chapter-in2').remove());
				$('#menu-chapter-out2').show();


				this.chapterNumber['out2'] = 2;
				this.chapterNumber['in3'] = 3;
				this.chapterNumber['out3'] = 3;
				this.chapterNumber['in2'] = 4;

				this.chapterNumber['in4'] = 5;
				this.chapterNumber['out4'] = 5;
				this.chapterNumber['ghost5'] = 6;
				this.chapterNumber['end6'] = 7;

			} else if (done3 && max3 < tasksDoneOrder['out2']) {

				$('#menu-chapter-3').after($('#menu-chapter-out2').remove().show());
				$('#menu-chapter-in2').show();
				this.chapterNumber['in2'] = 2;
				this.chapterNumber['in3'] = 3;
				this.chapterNumber['out3'] = 3;
				this.chapterNumber['out2'] = 4;

				this.chapterNumber['in4'] = 5;
				this.chapterNumber['out4'] = 5;
				this.chapterNumber['ghost5'] = 6;
				this.chapterNumber['end6'] = 7;

			} else if (tasksDoneOrder['in2'] > tasksDoneOrder['out2']) {
				$('#menu-chapter-in2').before($('#menu-chapter-out2').remove().show());
				
				this.chapterNumber['in2'] = '2 &#189;';
				this.chapterNumber['out2'] = 2;
				this.chapterNumber['in3'] = 3;
				this.chapterNumber['out3'] = 3;

				this.chapterNumber['in4'] = 4;
				this.chapterNumber['out4'] = 4;
				this.chapterNumber['ghost5'] = 5;
				this.chapterNumber['end6'] = 6;
				isInside = true;

			} else {

				this.chapterNumber['in2'] = 2;
				this.chapterNumber['out2'] = '2 &#189;';
				this.chapterNumber['in3'] = 3;
				this.chapterNumber['out3'] = 3;

				this.chapterNumber['in4'] = 4;
				this.chapterNumber['out4'] = 4;
				this.chapterNumber['ghost5'] = 5;
				this.chapterNumber['end6'] = 6;
				isInside = false;
			}

			$('#menu-chapter-in2').find('.menu-number').html(this.chapterNumber['in2']);
			$('#menu-chapter-out2').find('.menu-number').html(this.chapterNumber['out2']);
			$('#menu-chapter-3').find('.menu-number').html(this.chapterNumber['in3']);
			$('#menu-chapter-4').find('.menu-number').html(this.chapterNumber['in4']);
			$('#menu-chapter-5').find('.menu-number').html(this.chapterNumber['ghost5']);
			$('#menu-chapter-6').find('.menu-number').html(this.chapterNumber['end6']);


		} else if (tasksDone['in2']) {
			this.chapterNumber['in2'] = 2;
			this.chapterNumber['out2'] = 2;
			$('#menu-chapter-in2').find('.menu-number').html(this.chapterNumber['in2']);
			$('#menu-chapter-out2').find('.menu-number').html(this.chapterNumber['out2']);

			$('#menu-chapter-in2').addClass('read');
			$('#menu-chapter-out2').hide();	
			isInside = true;		
		} else if (tasksDone['out2']) {
			this.chapterNumber['in2'] = 2;
			this.chapterNumber['out2'] = 2;
			$('#menu-chapter-in2').find('.menu-number').html(this.chapterNumber['in2']);
			$('#menu-chapter-out2').find('.menu-number').html(this.chapterNumber['out2']);
			$('#menu-chapter-out2').addClass('read');
			$('#menu-chapter-in2').hide();	
			isInside = false;
		} else if (!tasksDone['in2'] && !tasksDone['out2']) {
			$('#menu-chapter-out2').hide();
			$('#menu-chapter-in2').show();
		}


		if (tasksDone['in3']) isInside = true; else if (tasksDone['out3']) isInside = false;
		if (tasksDone['in4']) isInside = true; else if (tasksDone['out4']) isInside = false;

		if (tasksDone['in3'] || tasksDone['out3']) $('#menu-chapter-3').addClass('read');
		if (tasksDone['in4'] || tasksDone['out4']) $('#menu-chapter-4').addClass('read');
		if (tasksDone['ghost5']) $('#menu-chapter-5').addClass('read');
		if (tasksDone['end6']) $('#menu-chapter-6').addClass('read');



		//
		// Select current chapter
		//
		if (AppStatus.currentTaskId == 'intro' || AppStatus.currentTaskId == 'in1' || AppStatus.currentTaskId == 'out1') {
			$('#menu-chapter-1').find('.menu-text').addClass('selected');
		}
		if (AppStatus.currentTaskId == 'in2') $('#menu-chapter-in2').find('.menu-text').addClass('selected');
		if (AppStatus.currentTaskId == 'out2') $('#menu-chapter-out2').find('.menu-text').addClass('selected');
		if (AppStatus.currentTaskId == 'in3' || AppStatus.currentTaskId == 'out3') $('#menu-chapter-3').find('.menu-text').addClass('selected');
		if (AppStatus.currentTaskId == 'in4' || AppStatus.currentTaskId == 'out4') $('#menu-chapter-4').find('.menu-text').addClass('selected');
		if (AppStatus.currentTaskId == 'ghost5') $('#menu-chapter-5').find('.menu-text').addClass('selected');
		if (AppStatus.currentTaskId == 'end6') $('#menu-chapter-6').find('.menu-text').addClass('selected');


		//
		// Add/Change chapter links
		//
		// if (AppStatus.currentTaskId !== 'in1' && AppStatus.currentTaskId !== 'out1' && AppStatus.currentTaskId !== 'intro') {
			$('#menu-chapter-1').find('.menu-chapter-inner').on('click', function(){this.selectChapter(tasksDone['out1']?'out1':'in1',this.chapterNumber, 'Motto', 1)}.bind(this));
		// }

		// if (AppStatus.currentTaskId !== 'in2') 	
			$('#menu-chapter-in2').find('.menu-chapter-inner').on('click', function(){this.selectChapter('in2',this.chapterNumber, 'Fallen Star', 2)}.bind(this));
		// if (AppStatus.currentTaskId !== 'out2') 
			$('#menu-chapter-out2').find('.menu-chapter-inner').on('click', function(){this.selectChapter('out2',this.chapterNumber, 'Fallen Star', 2)}.bind(this));

		// if (AppStatus.currentTaskId !== 'in3') 	
			$('#menu-chapter-3').find('.menu-chapter-inner').on('click', function(){this.selectChapter('in3',this.chapterNumber, 'Unusual Friends', 3)}.bind(this));
		// if (AppStatus.currentTaskId !== 'out3') 	
			// $('#menu-chapter-out3').on('click', function(){this.selectChapter('out3',this.chapterNumber, 'Body Language', 3)}.bind(this));

		// if (AppStatus.currentTaskId !== 'in4' && AppStatus.currentTaskId !== 'out4' && (tasksDone['in4'] || isInside)) 	
			$('#menu-chapter-4').find('.menu-chapter-inner').on('click.chapter', function(){this.selectChapter('in4',this.chapterNumber, 'Sourdough', 4)}.bind(this));
		// if (AppStatus.currentTaskId !== 'out4' && AppStatus.currentTaskId !== 'in4' && (tasksDone['out4'] || !isInside)) 	
			// $('#menu-chapter-4').on('click.chapter', function(){this.selectChapter('out4',this.chapterNumber, 'Sourdough', 4)}.bind(this));

		// if (AppStatus.currentTaskId !== 'ghost5') 	
			$('#menu-chapter-5').find('.menu-chapter-inner').on('click', function(){this.selectChapter('ghost5',this.chapterNumber,'The Mind of September', 5)}.bind(this));
		// if (AppStatus.currentTaskId !== 'end6') 	
			$('#menu-chapter-6').find('.menu-chapter-inner').on('click', function(){this.selectChapter('end6',this.chapterNumber,'All Saxophones', 6)}.bind(this));
	

		$('#menu-chapter-inout-cancel').on('click',this.hide.bind(this));

		$('#menu-restart').find('.menu-chapter-inner').on('click', this.selectRestart.bind(this));


		if (SETTINGS.isIOS) {
			(function() {
				var lastTap = 0;
				document.getElementById('menu').addEventListener('touchstart', function(e) {
					var lt  = lastTap;
					lastTap = performance.now();
					if (performance.now()-lt < 500) {
						e.preventDefault();
						return false;
					}
				},{passive: false});
			})();
		}
		
		

		document.getElementById('menu').addEventListener(SETTINGS.isMobile ? 'touchend' : 'click', function(e) {
			var py = SETTINGS.isMobile ? e.changedTouches[0][AppStatus.pageY()] : e[AppStatus.pageY()];
			// console.log(e.path, e.target);
			// window.d = e.path[0];


			var targetId = 'menu';
			var target = null;
			if (e.path && e.path[0]) {targetId = $(e.path[0]).attr('id')||targetId; target = e.path[0];}
			if (SETTINGS.isIOS && e.target) {targetId = $(e.target).attr('id')||targetId; target=e.target;}
			if (py > 48 && py < AppStatus.innerHeight()-70 && this.shown && !this.infoPageOpen) {
				if (!target || $(target).attr('id')=='menu' || $(target).attr('id')=='menu-text-container' || $(target).attr('id')=='menu-chapter-container' || $(target).attr('id')=='menu-chapter-inout-title' || $(target).hasClass('menu-separator') || $(target).hasClass('menu-chapter'))  {
					setTimeout(this.hide, 16);
					e.preventDefault();
					return false;
				}
			}
		}.bind(this),{passive: false});


	};

	this.selectInsideOutside = function(isInside) {
		if (SETTINGS.MENU_DISABLED) return;
		if (isInside) {
			$('#menu-chapter-out2').hide();	
			$('#menu-chapter-in2').show();	

			$('#menu-chapter-out3').hide();	
			$('#menu-chapter-in3').show();

			// $('#menu-chapter-4').off('click.chapter');
			// $('#menu-chapter-4').on('click.chapter', function(){this.selectChapter('in4',this.chapterNumber)}.bind(this));

		} else {
			$('#menu-chapter-out2').show();	
			$('#menu-chapter-in2').hide();	

			$('#menu-chapter-out3').show();	
			$('#menu-chapter-in3').hide();	

			// $('#menu-chapter-4').off('click.chapter');
			// $('#menu-chapter-4').on('click.chapter', function(){this.selectChapter('out4',this.chapterNumber)}.bind(this));
		}
	};


	//Show chapter screen
	this.selectChapter = function(chapterId, chapterNumber, chapterName, actualEpisode) {
		if (SETTINGS.MENU_DISABLED) return;

		AnalyticsController.trackEvent('chapter_menu_select', chapterId);

		var globalState = MultiStorage.getGlobalState();
		var tasksDone = (globalState.tasksDone||{});
		var tasksFinished = (globalState.tasksFinished||{});

		if ( (chapterId == 'in2' || chapterId == 'out2') && (tasksDone['in2'] && tasksDone['out2'])) {
			AppStatus.selectChapter( chapterId );
			return;
		} else if (chapterId == 'ghost5') {
			AppStatus.selectChapter('ghost5');
			return;
		} else if (chapterId == 'end6') {
			AppStatus.selectChapter('end6');
			return;
		}

		if (!SETTINGS.OUTSIDE_ALLOWED) {
			AppStatus.selectChapter('in'+actualEpisode);
			return;
		}

		$('#menu-chapter-inout-title').toggleClass('menu-select-restart-text', false);
		$('#menu-chapter-inout-inside').show();
		$('#menu-chapter-inout-outside').show();
		$('#menu-chapter-inout-restart').hide();
		$('#menu-chapter-inout-restart-cancel').hide();

		var episode = this.chapterNumber[chapterId];
		console.log("SELECTING EPISODE", chapterId, episode,!!tasksDone['in'+actualEpisode],!!tasksDone['out'+actualEpisode]);
		$('#menu-chapter-inout-inside').toggleClass('read', true); //!!tasksDone['in'+actualEpisode]);
		$('#menu-chapter-inout-outside').toggleClass('read', true); //!!tasksDone['out'+actualEpisode]);

		//select action
		if (tasksFinished['in'+actualEpisode]) $('#menu-chapter-inout-inside').find('.inout-action').html(SETTINGS.LANGUAGE=='fr'?"Recommencer à l'intérieur":"Restart inside");
		else if (tasksDone['in'+actualEpisode]) $('#menu-chapter-inout-inside').find('.inout-action').html(SETTINGS.LANGUAGE=='fr'?"Continuer à l'intérieur":"Continue inside");
		else if (AppStatus.currentTaskId == 'out'+actualEpisode && AppStatus.currentTaskId !=='out2') $('#menu-chapter-inout-inside').find('.inout-action').html(SETTINGS.LANGUAGE=='fr'?"Recommencer à l'intérieur":"Restart inside");
		else $('#menu-chapter-inout-inside').find('.inout-action').html(SETTINGS.LANGUAGE=='fr'?"Lire à l'intérieur":"Read inside");

		if (tasksFinished['out'+actualEpisode]) $('#menu-chapter-inout-outside').find('.inout-action').html(SETTINGS.LANGUAGE=='fr'?"Recommencer à l'extérieur":"Restart outside");
		else if (tasksDone['out'+actualEpisode]) $('#menu-chapter-inout-outside').find('.inout-action').html(SETTINGS.LANGUAGE=='fr'?"Continuer à l'extérieur":"Continue outside");
		else if (AppStatus.currentTaskId == 'in'+actualEpisode && AppStatus.currentTaskId !=='in2') $('#menu-chapter-inout-outside').find('.inout-action').html(SETTINGS.LANGUAGE=='fr'?"Recommencer à l'extérieur":"Restart outside");
		else $('#menu-chapter-inout-outside').find('.inout-action').html(SETTINGS.LANGUAGE=='fr'?"Lire à l'extérieur":"Read outside");


		//add events
		$('#menu-chapter-inout-inside').find('.menu-chapter-inner').off('click');
		if (AppStatus.currentTaskId == 'in'+actualEpisode && !tasksFinished['in'+actualEpisode]) {
			// $('#menu-chapter-inout-inside').find('.inout-action').html('Continue');
			$('#menu-chapter-inout-inside').find('.menu-chapter-inner').on('click', this.hide.bind(this));
		} else {
			$('#menu-chapter-inout-inside').find('.menu-chapter-inner').on('click', function() {AppStatus.selectChapter('in'+actualEpisode);});
		}

		$('#menu-chapter-inout-outside').find('.menu-chapter-inner').off('click');
		if (AppStatus.currentTaskId == 'out'+actualEpisode) {
			// $('#menu-chapter-inout-outside').find('.inout-action').html('Continue');
			$('#menu-chapter-inout-outside').find('.menu-chapter-inner').on('click', this.hide.bind(this));
		} else {
			$('#menu-chapter-inout-outside').find('.menu-chapter-inner').on('click', function() {AppStatus.selectChapter('out'+actualEpisode);});
		}

		$('#menu-chapter-inout-title').html((SETTINGS.LANGUAGE=='fr'?'Chapitre ':'Chapter ')+episode);
		$('#menu-text-container').hide();
		$('#menu-chapter-inout-container').css('display', 'flex');
	};


	//restart all of motto from menu (or final page of final chapter)
	this.selectRestart = function() {
		AnalyticsController.trackEvent('restart_all');

		$('#menu-chapter-inout-title').html(EmbeddedText[SETTINGS.LANGUAGE].menu.reset);
		$('#menu-chapter-inout-restart').html();
		$('#menu-chapter-inout-restart-cancel').html();
		$('#menu-text-restart').html(SETTINGS.LANGUAGE=='fr'?"Recommencer":'Restart');
		$('#menu-text-cancel').html(SETTINGS.LANGUAGE=='fr'?"Annuler":'Cancel');

		$('#menu-text-container').hide();
		$('#menu-chapter-inout-container').css('display', 'flex');

		$('#menu-chapter-inout-title').toggleClass('menu-select-restart-text', true);

		$('#menu-chapter-inout-inside').hide();
		$('#menu-chapter-inout-outside').hide();
		$('#menu-chapter-inout-restart').show();
		$('#menu-chapter-inout-restart-cancel').show();
		$('#menu-chapter-inout-restart-cancel').on('click',this.hide.bind(this));
		$('#menu-chapter-inout-restart').on('click',function() {
			AppStatus.restart();
		});
	};



	this.openAbout = function(e) {

		if (this.infoAnimationTimeout>-1) return;
		if (this.aboutPageOpen) {
			this.aboutPageOpen = false;
			$('#menu-header-info-container').toggleClass('active', false);
			this.hide();
			this.closeInfoDelayed();
			return;
		}
		this.aboutPageOpen = true;
		this.sharePageOpen = false;
		this.creditsPageOpen = false;
		AnalyticsController.pageview('/about');

		$('#info-page-header-text').text(EmbeddedText[SETTINGS.LANGUAGE].pages["about-title"]);


		$('#share-page').hide();
		$('#credits-page').hide();
		$('#about-page').show();
		$('#menu-header-info-container').toggleClass('active', true);
		$('#menu-header-share-container').toggleClass('active', false);

		this.openInfo();
		$('#info-page').scrollTop(0);

	}.bind(this);


	this.openCredits = function(e) {

		if (this.infoAnimationTimeout>-1) return;
		if (this.creditsPageOpen) {
			this.creditsPageOpen = false;
			$('#menu-header-info-container').toggleClass('active', false);
			$('#menu-header-share-container').toggleClass('active', false);
			this.hide();
			this.closeInfoDelayed();
			return;
		}
		this.creditsPageOpen = true;
		this.aboutPageOpen = false;
		this.sharePageOpen = false;
		AnalyticsController.pageview('/credits');

		$('#info-page-header-text').text(EmbeddedText[SETTINGS.LANGUAGE].pages["credits-title"]);

		$('#credits-page').show();
		$('#share-page').hide();
		$('#about-page').hide();
		$('#menu-header-info-container').toggleClass('active', false);
		$('#menu-header-share-container').toggleClass('active', false);

		this.openInfo();
		$('#info-page').scrollTop(0);

	}.bind(this);





	this.openShare = function(e) {

		if (this.infoAnimationTimeout>-1) return;
		if (this.sharePageOpen) {
			this.sharePageOpen = false;
			$('#menu-header-share-container').toggleClass('active', false);
			this.hide();
			this.closeInfoDelayed();
			return;
		}
		this.creditsPageOpen = false;
		this.aboutPageOpen = false;
		this.sharePageOpen = true;
		AnalyticsController.pageview('/share');

		$('#info-page-header-text').text(EmbeddedText[SETTINGS.LANGUAGE].pages["share-title"]);
		$('#share-btn-link').find('.share-text').html(SETTINGS.LANGUAGE=='fr'?'Copier le lien':'Copy link')

		$('#share-page').show();
		$('#about-page').hide();
		$('#credits-page').hide();
		$('#menu-header-info-container').toggleClass('active', false);
		$('#menu-header-share-container').toggleClass('active', true);

		this.openInfo();

	}.bind(this);

	this.openInfo = function() {
		if (this.infoPageOpen) return;
		this.infoPageOpen = true;
		AnalyticsController.pageview('/info');

		$('#info-page').scrollTop(0);
		$('#info-page').toggleClass('hide', false);
		$('#info-page').show();
		$('#info-page').toggleClass('animate-up', false);
		if (this.infoAnimationTimeout>=0) window.clearTimeout(this.infoAnimationTimeout)
		this.infoAnimationTimeout = setTimeout(function() {
			$('#info-page').toggleClass('animate-up', true);
			setTimeout(function(){if (!this.shown) this.show();}.bind(this),200);
			// this.infoAnimationTimeout = -1;
			this.infoAnimationTimeout = setTimeout(()=>this.infoAnimationTimeout=-1,200);
		}.bind(this),16);
	}.bind(this);

	// this.closeAbout = function(e) {
	// 	if (e && this.infoPageOpen) {this.hide();}
	// 	this.closeInfo();
	// }.bind(this);
	
	// this.closeShare = function(e) {
	// 	if (e && !this.infoPageOpen) {this.hide();}
	// 	this.closeInfo();
	// }.bind(this);

	this.closeInfo = function() {
		if (!this.infoPageOpen) return;
		this.sharePageOpen = false;
		$('#menu-header-share-container').toggleClass('active', false);
		this.aboutPageOpen = false;
		$('#menu-header-about-container').toggleClass('active', false);
		this.creditsPageOpen = false;
		this.hide();
		this.closeInfoDelayed();


	}.bind(this);

	this.closeInfoDelayed = function() {
		if (!this.infoPageOpen) return;
		this.infoPageOpen = false;
		
		$('#info-page').toggleClass('animate-up', false);
		$('#info-page').toggleClass('hide', true);
		$('#menu-header-info-container').toggleClass('active', false);
		$('#menu-header-share-container').toggleClass('active', false);
		if (this.infoAnimationTimeout>=0) window.clearTimeout(this.infoAnimationTimeout)
		this.infoAnimationTimeout = setTimeout(function() {
			$('#info-page').toggleClass('hide', false);
			$('#info-page').hide();
			$('#menu').css('display','none');
			$('#menu-button').css('display','none');
			$('#interactive-header').hide();
			this.infoAnimationTimeout = -1;
		}.bind(this),210);
	}.bind(this);


	this.openRestart = function() {
		this.show();
		this.selectRestart();
	}.bind(this);

	this.toggle = function() {
		if (SETTINGS.MENU_DISABLED) return;
		if (!this.initialized) return;
		if (this.shown) this.hide(); else this.show();
		if (this.infoPageOpen) {
			if (window.header) window.header.closeOverlay();
			this.closeInfoDelayed();
		}
	}.bind(this);

	this.show = function() {
		if (SETTINGS.MENU_DISABLED) return;
		if (SETTINGS.ERROR_MODE) return;
		if (!SETTINGS.INTRO_MODE &&  AppStatus.MAIN_LOADED && SETTINGS.WAS_INTRO_MODE) {
			$('#menu-bar-container').show();
			$('#menu-pages').show();
		}

		//update pages
		$('#menu-pages').html(AppStatus.currentPageDisplay + ' / ' +  AppStatus.numPagesDisplay);

		var pc = (AppStatus.currentPage) / (AppStatus.numPages-1);
		$('#menu-bar').css('transform', 'scale('+pc+', 1.0)');


		// if (this.animationTimeout > -1) {
		// 	window.clearTimeout(this.animationTimeout);
		// 	this.animationTimeout = -1;
		// }
		//show
		if (this.addedToPage) {
			$('body').append($('#menu-header-container').detach());
		}

		$('#menu').css('display','flex');
		$('#menu').toggleClass('animate-up', false);
		$('#menu-button').css('display','block');
		$('#interactive-header').css('pointer-events', 'none');
		$('#interactive-header').show();
		$('#interactive-header').css('transition','transform 0.2s ease-out');
		if (this.animationTimeout>-1) {
			window.clearTimeout(this.animationTimeout);
		}
		setTimeout(function() {
			$('#menu').toggleClass('animate-up', true);
			$('#interactive-header').toggleClass('animate-up', false);
		    $('#interactive-header').css('pointer-events', 'auto');
		    this.animationTimeout =  -1;
		},16);

		this.shown = true;
	}.bind(this);

	this.hide = function() {
		if (SETTINGS.MENU_DISABLED) return;
		if (!this.shown) return;
		
		$('#menu-text-container').css('display', 'flex');
		$('#menu-chapter-inout-container').hide();

		$('#interactive-header').css('pointer-events', 'none');
		$('#menu').toggleClass('animate-up', false);
		$('#interactive-header').toggleClass('animate-up', true);
		if (this.animationTimeout>-1) window.clearTimeout(this.animationTimeout);
		this.animationTimeout = setTimeout(function() {
			$('#menu').css('display','none');
			$('#menu-button').css('display','none');
			$('#interactive-header').hide();
			this.animationTimeout = -1;
			if (this.addedToPage) {
				$(this.addedToPage).append($('#menu-header-container').detach());
			}
		}.bind(this),210);
		this.shown = false;
	}.bind(this);

	this.touchDown = function(e) {
		if (AppStatus.isLandscapeMode()) return;
		if (SETTINGS.MENU_DISABLED) return;
		if (this.infoPageOpen) return;


		var px = SETTINGS.isMobile ? e.originalEvent.changedTouches[0][AppStatus.pageX()] : e[AppStatus.pageX()];
		var pyi = SETTINGS.isMobile ? (AppStatus.innerHeight()-e.originalEvent.changedTouches[0][AppStatus.pageY()])  : (AppStatus.innerHeight()-e[AppStatus.pageY()]);

		if (pyi < 45 && px > 45 && px < AppStatus.innerWidth()-5 && !SETTINGS.INTRO_MODE && AppStatus.MAIN_LOADED) {
			this.dragging = true;
			AppStatus.draggedPage = true;
			AppStatus.targetPage = Math.floor(pc*(AppStatus.numPages-1));

			var pc = Utils.cmap(px, 48, AppStatus.innerWidth()-25, 0.0,1.0);
			pc = Math.floor(pc*(AppStatus.numPages)) / AppStatus.numPages;
			$('#menu-pages').html(Math.floor(pc*(AppStatus.numPages-1)+1) + ' / ' +  AppStatus.numPagesDisplay);
			$('#menu-bar').css('transform', 'scale('+(Math.floor(pc*(AppStatus.numPages)) / AppStatus.numPages)+', 1.0)');
		}

		if (SoundController.enabled) {
			SoundController.unlockAudioDirectly();
			SoundController.enableNextOnNextTap();
		}
	}.bind(this);

	this.touchMove = function(e) {
		if (AppStatus.isLandscapeMode()) return;
		if (SETTINGS.MENU_DISABLED) return;
		if (this.infoPageOpen) return;
		if (this.dragging && !SETTINGS.INTRO_MODE && AppStatus.MAIN_LOADED) {
			var px = SETTINGS.isMobile ? e.originalEvent.changedTouches[0][AppStatus.pageX()] : e[AppStatus.pageX()];
			var pc = Utils.cmap(px, 48, AppStatus.innerWidth()-25, 0.0,1.0);
			pc = Math.floor(pc*(AppStatus.numPages)) / AppStatus.numPages;
			$('#menu-pages').html(Math.floor(pc*(AppStatus.numPages-1)+1) + ' / ' +  AppStatus.numPages);
			$('#menu-bar').css('transform', 'scale('+(Math.floor(pc*(AppStatus.numPages)) / AppStatus.numPages)+', 1.0)');
			AppStatus.draggedPage = true;
			AppStatus.targetPage = Math.floor(pc*(AppStatus.numPages-1));
		}
	}.bind(this);

	this.touchUp = function(e) {
		if (AppStatus.isLandscapeMode()) return;
		if (SETTINGS.MENU_DISABLED) return;
		if (this.infoPageOpen) return;
		var px = SETTINGS.isMobile ? e.originalEvent.changedTouches[0][AppStatus.pageX()] : e[AppStatus.pageX()];
		var pyi = SETTINGS.isMobile ? (AppStatus.innerHeight()-e.originalEvent.changedTouches[0][AppStatus.pageY()])  : (AppStatus.innerHeight()-e[AppStatus.pageY()]);
		
		if (!this.dragging && px < 45 && pyi < 50) {
			// if (px < 95)
			this.hide();
			return;
		} else if ((this.dragging || (px > 45 && pyi < 52 && px < AppStatus.innerWidth()-5)) && !SETTINGS.INTRO_MODE && AppStatus.MAIN_LOADED) {

			this.dragging = false;
			var pc = Utils.cmap(px, 48, AppStatus.innerWidth()-25, 0.0,1.0);
			pc = Math.floor(pc*(AppStatus.numPages)) / AppStatus.numPages;
			$('#menu-pages').html(Math.floor(pc*(AppStatus.numPages-1)+1) + ' / ' +  AppStatus.numPages);
			$('#menu-bar').css('transform', 'scale('+(Math.floor(pc*(AppStatus.numPages)) / AppStatus.numPages)+', 1.0)');

			AppStatus.draggedPage = true;
			AppStatus.targetPage = Math.floor(pc*(AppStatus.numPages-1));


			setTimeout(this.hide, 100);

			if (SoundController.enabled) {
				SoundController.unlockAudioDirectly();
				SoundController.enableNextOnNextTap();
			}
		}
	}.bind(this);


	//
	// Add to page mode
	//
	this.addedToPage = null;
	this.addToPage = function(div) {
		// if (!this.shown) {
		$(div).append($('#menu-header-container').detach());
		$('#menu-header-container').toggleClass('page', true);
		// }
		if (this.addedToPage) $('body').append($('#menu-header-container').detach());
		this.addedToPage = div;
	};

	this.removeFromPage = function(div) {
		if (this.addedToPage !== div) return;
		$('#menu').append($('#menu-header-container').detach());
		$('#menu-header-container').toggleClass('page', false);
		this.addedToPage = null;
	};

}

window.MenuController = window.MenuController||new MenuController();
export default window.MenuController;


