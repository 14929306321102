import $ from 'jquery';
import SETTINGS from "../controllers/Settings.js";

function LoaderImage(url, dataType) {
	var self = this;
	this.url = url;
	this.data = new Image();
	this.loaded = false;
	this.loading = false;
	this.progress = 0.0;
	this.weight = 1;
	this.loadType = 'image';

	this.start = function(completeCallback, errorCallback) {
		if (self.loading || self.loaded) console.error('Already loading: ',url);
		self.loading = true;
        var img = self.data;
        (function(img) {
        	img.onload = function() {	
        		img.loaded = true;
        		img.onload = img.onerror = img = undefined;
        		self.progress = 1.0;
        		self.loaded = true;
        		completeCallback(self);
			};
			img.onerror = function() {
				img.loaded = true;
				img.onload = img.onerror = img = undefined;
        		self.progress = 1.0;
        		self.loaded = true;
        		errorCallback(self);
			};
			img.loaded = false;
			img.crossorigin = 'anonymous';
			img.setAttribute('crossorigin','anonymous');
			img.path = url;
			img.src = url;
        })(self.data);
	};
	this.getProgress = function() { return self.progress; }
	this.dispose = function() {
		self.url = self.start = self.getProgress = self.dispose = self.data = null;
	};
	this.getWeight = function() {
		return this.weight;
	};
}

export default LoaderImage;

