import * as THREE from 'three';
import SETTINGS from '../controllers/Settings.js';

import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import RendererController from '../controllers/RendererController.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';

import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import SpritesheetVideo from './SpritesheetVideo.js';

// var CanvasTextWrapper = require('canvas-text-wrapper').CanvasTextWrapper;

//------------------------
class SpritesheetVideoIsolate extends SpritesheetVideo {
	constructor(_info, batchName) {
		super(_info, batchName);
		this.boxesScene = new THREE.Scene();
		this.boxes = [];
		this.effectDiv = null;

		//
		// Debug the class in the boxes
		//
		this.boxDebugPlanes = [];
		this.boxDebugCanvas = [];
		this.boxDebugContext = [];
		this.boxDebugTexture = [];

		this.animationPc = 0.0;

		this.selectedBox = false;
		this.selectedBoxId = 0;
	}

	preload(batchName) {
		super.preload(batchName);

	}
	
	prepare() {
		super.prepare();
		if (!this.effectFbo) this.effectFbo = SpritesheetVideo.getFbo(this.videoWidth, this.videoHeight);
	}
	
	onAnalysisLoaded(info) {
		super.onAnalysisLoaded(info);
	}
	

	stop() {
		// for (var i in this.effectDiv) {
		this.selectedBox = false;
		if (this.effectDiv) {
			$(this.effectDiv.container).remove();
			$(this.effectDiv.div).remove();
		}
		// }
		this.boxesScene = new THREE.Scene();
		this.effectPlane = null;
		this.effectDiv = null;

		this.animationStarted = false;

		super.stop();
	}

	selectBox() {
		this.selectedBox = true;

		var minWidth = 10000;
		var minHeight = 10000;

		var sortableAnalysis = Utils.clone(this.analysis);
		sortableAnalysis.scores = [];
		var referenceRects = this.analysis.rectangles[Math.floor(this.analysis.rectangles.length/2)];
		for (var i=0; i<referenceRects.length; i++) {

			//add points for confidence
			sortableAnalysis.scores[i] = {s:this.analysis.confidences[0][i]*8.0, id:i, w:referenceRects[i][2], h:referenceRects[i][3]}

			if (this.params.effect.scores && this.params.effect.scores[this.analysis.classes[0][i]]) {
				console.log("SCORE", this.analysis.classes[0][i], this.params.effect.scores[this.analysis.classes[0][i]]);
				sortableAnalysis.scores[i].s += this.params.effect.scores[this.analysis.classes[0][i]];
			}
		}

		for (var cf=0; cf<this.analysis.rectangles.length; cf++) {
			var rects = this.analysis.rectangles[cf];

			for (var i=0; i<rects.length; i++) {
				//remove points for touching side
				var box = rects[i];
				if (!this.params.effect.sidesOff) {
					if (box[0]-box[2]*0.5 < 0.05) sortableAnalysis.scores[i].s -= 4.0 / this.analysis.rectangles.length;
					if (box[0]+box[2]*0.5 > 0.95) sortableAnalysis.scores[i].s -= 4.0 / this.analysis.rectangles.length;
					if (box[1]-box[3]*0.5 < 0.05) sortableAnalysis.scores[i].s -= 4.0 / this.analysis.rectangles.length;
					if (box[1]-box[3]*0.5 > 0.95) sortableAnalysis.scores[i].s -= 4.0 / this.analysis.rectangles.length;
				}
						
				//remove points for size of box, prefer smallest
				sortableAnalysis.scores[i].s -= box[2]*2.0 / this.analysis.rectangles.length;
				sortableAnalysis.scores[i].s -= box[3]*2.0 / this.analysis.rectangles.length;

				sortableAnalysis.scores[i].s -= Utils.ccmap(box[2],0.0,0.2,3.0,0.0) / this.analysis.rectangles.length;
				sortableAnalysis.scores[i].s -= Utils.ccmap(box[3],0.0,0.2,3.0,0.0) / this.analysis.rectangles.length;
			

				var ratio = (box[2]/box[3]);
				if (isNaN(ratio)) ratio = 0.5;
				sortableAnalysis.scores[i].s -= Math.min(Math.sqrt( Math.abs(1.0-ratio)), 1.0) * 1.0 / this.analysis.rectangles.length;
			}
		}
	
		var sorted = sortableAnalysis.scores.sort(function(a, b) { return b.s-a.s;})
		this.selectedBoxId = sorted[0].id;
		var maxScore = sorted[0].s;
		var minScore = sorted[sorted.length-1].s;

		this.analysis.scores = [];
		for (var i=0; i<sorted.length; i++) {
			this.analysis.scores[sorted[i].id] = Utils.cmap(sorted[i].s, minScore, maxScore, 0.0,1.0);
		}


		//
		// Crop start/end time
		//
		var startFrame = this.numFrames-1;
		var endFrame = 0;
		for (var i=0; i<this.analysis.rectangles.length; i++) {
			var rects = this.analysis.rectangles[i+this.frameOffset];
			if (rects && rects.length && rects[this.selectedBoxId] && this.selectedBox) {
				var box = rects[this.selectedBoxId];	
				if (box) {
					if (box[0] < 0.97 && box[0]+box[2] > 0.03 && box[1] < 0.97 && box[1]+box[3] > 0.03) {
						startFrame = Math.min(startFrame, i);
						endFrame = Math.max(endFrame, i);
					}
				} else {
					// startFrame = Math.min(startFrame, i);
					// endFrame = Math.max(endFrame, i);
				}

			} else {
				// startFrame = Math.min(startFrame, i);
				// endFrame = Math.max(endFrame, i);
			}

		}	

		if (this.params.effect.cropDuration) {
			this.numFrames = Math.max(endFrame-startFrame,1);
			this.params.duration = Math.max(endFrame-startFrame-1, 1) / 24;
			this.startOffset = startFrame;
			console.log(startFrame, endFrame, this.params.duration);
		}
	}
	
	update(opt) {
		super.update(opt);

		if (this.isReady() && this.analysis && this.contentDiv) {
				
			//------------------------
			//
			// Rank and select boxes
			//
			//------------------------
			if (!this.selectedBox) {
				this.selectBox();
				if (!this.effectDiv) {
					MaterialController.addMaterial('isolate', new THREE.RawShaderMaterial({
						defines: {
							CROP_MODE: 0
						},
						vertexShader:`
							precision mediump float;
							precision mediump int;

							attribute mediump vec3 position;
							attribute mediump vec2 uv;

							uniform mediump mat4 modelViewMatrix; // optional
							uniform mediump mat4 projectionMatrix; // optional
							// uniform mediump vec2 plane;
							varying mediump vec2 vUv;
							varying mediump float ny;

							#define mapf(value,si,sa,mi,ma) (((value-si) / (sa-si)) * (ma-mi) + mi)
							#define cmap(value,si,sa,mi,ma) clamp(mapf(value,si,sa,mi,ma),mi,ma)
							#define cimap(value,si,sa,mi,ma) clamp(mapf(value,si,sa,mi,ma),ma,mi)
							#define safepow(value,expv) pow(abs(value), expv)

							uniform mediump vec2 targetCenter;

							uniform mediump vec2 targetMax;


							void main() {
								#if CROP_MODE == 3

									float mx = max(targetMax.x, targetMax.y);
									vUv = (vec2(uv.x,1.0-uv.y)-targetCenter) * (vec2(mx,mx)) * 1.0 + targetCenter;
									vUv.y = 1.0-vUv.y;

								#else
									vUv = uv;
								#endif
								
								ny = 1.0-uv.y;


								gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
							}
						`,
						fragmentShader:`
							precision mediump float;
							precision mediump int;

							varying mediump vec2 vUv;
							varying mediump float ny;

							#define mapf(value,si,sa,mi,ma) (((value-si) / (sa-si)) * (ma-mi) + mi)
							#define cmap(value,si,sa,mi,ma) clamp(mapf(value,si,sa,mi,ma),mi,ma)
							#define cimap(value,si,sa,mi,ma) clamp(mapf(value,si,sa,mi,ma),ma,mi)
							#define safepow(value,expv) pow(abs(value), expv)

							uniform mediump sampler2D tDiffuse;
							uniform mediump vec2 targetCenter;
							uniform mediump vec2 targetRadius;
							uniform mediump vec2 targetMin;
							uniform mediump vec2 targetMax;
							uniform mediump float targetSharpness;
							uniform mediump vec3 tint;

							#define vignetteCurve 2.1

							void main() {
								vec3 col = texture2D(tDiffuse, vUv).rgb;

								#if CROP_MODE == 0
									if (vUv.x < targetCenter.x - targetMax.x/2.0) col = tint;
									if (vUv.x > targetCenter.x + targetMax.x/2.0) col = tint;
									if (ny > targetCenter.y + targetMax.y/2.0) col = tint;
									if (ny < targetCenter.y - targetMax.y/2.0) col = tint;
									// if (col.r < 0.001) col *= 0.0;

								#elif CROP_MODE == 1
									float dst = 1.0;

									// if (vUv.x < targetCenter.x) dst *= pow(cmap(vUv.x, (targetCenter.x-targetMax.x/2.0), (targetCenter.x), 0.0, 1.0),1.0);
									// if (vUv.x >= targetCenter.x) dst *= pow(1.0-cmap(vUv.x, (targetCenter.x), (targetCenter.x+targetMax.x/2.0), 0.0, 1.0),1.0);
									
									// if (ny < targetCenter.y) dst *= pow(cmap(ny, (targetCenter.y-targetMax.y/2.0), (targetCenter.y), 0.0, 1.0),1.0);
									// if (ny >= targetCenter.y) dst *= pow(1.0-cmap(ny, (targetCenter.y), (targetCenter.y+targetMax.y/2.0), 0.0, 1.0),1.0);

									// dst = clamp(dst* 10.0, 0.0, 1.0);

							      	lowp float vignettev = (1.0 - safepow( min(abs(targetCenter.x-vUv.x) / targetMax.x*2.0, 1.0), vignetteCurve*targetSharpness)) *  (1.0 - safepow( min(abs(targetCenter.y-ny) / targetMax.y * 2.0, 1.0), vignetteCurve*targetSharpness));

									//dst *= cimap(vUv.x, 1.0, targetCenter.x, 1.0, 0.0);
									//dst *= cmap(vUv.x, (targetCenter.x+targetMax.x/2.0), (targetCenter.x), 0.0, 1.0);

									// dst *= cimap(vUv.x, (targetCenter.x-targetMax.x/2.0), targetCenter.x, 1.0, 0.0);


									col = mix(col, tint, 1.0-vignettev);
									// col = vec3(vignettev);

									// float dst = 
								#elif CROP_MODE == 2
									float dst = distance(targetCenter, (vec2((vUv.x-targetCenter.x)/1.777777+targetCenter.x, ny)) );
									dst = cmap(dst, 0.0, (targetMax.y*0.25+targetMax.x*0.25), 0.0, 1.0); //,
									dst = pow(dst, 10.0*targetSharpness);
									col = mix(col, tint, dst);
								#endif


								// col.gb = vUv;

								gl_FragColor = vec4(col, 1.0);
							}
						`,
						uniforms: {
							tDiffuse: {type:'t', value: Utils.whiteTexture},
							targetCenter: {type:'v2', value: new THREE.Vector2(0.5, 0.5)},
							targetRadius: {type:'v2', value: new THREE.Vector2(0.5, 0.5)},
							targetMin: {type:'v2', value: new THREE.Vector2(0.5, 0.5)},
							targetMax: {type:'v2', value: new THREE.Vector2(0.5, 0.5)},
							targetSharpness:  {type:'f', value: 1.0},
							tint: {type:'c', value: new THREE.Color(0xffffff)}
						},
						transparent: false,
						blending: THREE.NoBlending,
						depthTest: false,
						depthWrite: false,
						side: THREE.DoubleSide
					}));
					this.effectMaterial = MaterialController.getMaterial('isolate', {
							CROP_MODE: 
								this.params.effect.mode == 'zoom' ? 3 : 
								// this.params.effect.mode == 'ellipse' ? 3 : 
								this.params.effect.mode == 'circle' ? 2 : 
								this.params.effect.mode == 'fade' ? 1 : 
								0
								// this.params.effect.mode == 'square' ? 0;
						});
					this.effectMaterial.uniforms.tDiffuse.value = this.fbo.texture.texture;

					this.effectPlane = new THREE.Object3D();

					var dc = document.createElement('div');
					$(dc).addClass( (this.params.effect.textType=='top' || this.params.effect.textType=='center') ? 'text-container' : 'spritesheet-overlay-text-container');
					if ((this.params.effect.textType=='top' || this.params.effect.textType=='center')) $(dc).addClass(this.params.effect.textType);					
					$(this.contentDiv).append(dc);

					var d = document.createElement('div');
					$(d).addClass( (this.params.effect.textType=='top' || this.params.effect.textType=='center') ? 'text' : 'spritesheet-overlay-text');
					$(d).css('color', 'black');
					if (this.params.effect.textType=='top' || this.params.effect.textType=='center') $(d).addClass(this.params.effect.textType);
					$(d).css('background-color', 'white');
					$(dc).append(d);

					this.effectDiv = {
						container: dc,
						div:d,
						classid: null,
						visible: false,
						fontSize: 0,
						text: null
					};

					var classesText = this.params.effect.text||'';
					if (this.params.effect["default-class"] && !this.analysis.classes[0][this.selectedBoxId]) {
						classesText = classesText.replace('{class}', this.params.effect["default-class"]);
					} else {
						classesText = classesText.replace('{class}', this.analysis.classes[0][this.selectedBoxId]);
					}
					this.effectDiv.classid = classesText;
					$(this.effectDiv.div).html(classesText);

					var rects = this.analysis.rectangles[0+this.frameOffset];
					if (rects) {
						var box = rects[this.selectedBoxId];
						if (box) this.effectPlane.position.set(box[0]+box[2]/2,box[1]+box[3]/2, 0.0);
						if (box) this.invdir = ((box[1]-box[3]/2) < 0.15 && (box[1]+box[3]) < 0.95) ? true : false;
					}
				}
			}

			var currentFrame = this.lastFrame;

			if (this.isShowing && !this.animationStarted) {
				this.animationStarted = true;
				this.animationPc = 0.0;
				this.animationStartTime = performance.now()+(this.params.effect.animationDelay||0)*1000;
			}
			this.animationPc = Utils.cmap(performance.now(), this.animationStartTime, this.animationStartTime+(this.params.effect.animation||0)*1000, 0.0, 1.0);
			if (isNaN(this.animationPc)) this.animationPc = 1.0;

			//--------------------
			//
			// Update boxes & text
			//
			//-------------------
			var rects = this.analysis.rectangles[currentFrame+this.frameOffset];
			if (rects && rects.length && rects[this.selectedBoxId] && this.selectedBox) {
				// for (var i=0; i<rects.length; i++) {

				var box = rects[this.selectedBoxId];

				// this.invdir = false;
				// this.invdir = true;
				var invdirmult = this.invdir ? -0.75 : 1;
				var invdirmult2 = this.invdir ? -1 : 0;

				// console.log(this.invdir);

				var p = this.effectPlane;
				p.scale.set(0.1*16/9,0.1,0.1).multiplyScalar(0.6); //box[2], box[3], 1.0); //*16/9
				p.position.lerp(new THREE.Vector3(box[0]+box[2]/2,box[1]+box[3]/2, 0.1), 0.5);
				p.position.y = Utils.clamp(p.position.y, 0.1, 0.8);

				var scale = (this.params.effect.scale||1.0);
				p.scale.x *= scale;
				p.scale.y *= scale;

				this.effectMaterial.uniforms.targetSharpness.value = this.params.effect.sharpness||1.0;
				this.effectMaterial.uniforms.targetCenter.value.set(p.position.x, p.position.y);
				this.effectMaterial.uniforms.targetRadius.value.set(p.scale.x, Math.abs(p.scale.y));
				this.effectMaterial.uniforms.targetMin.value.set(p.position.x-p.scale.x, (p.position.y));
				this.effectMaterial.uniforms.targetMax.value.set(box[2]*scale, Math.abs(box[3]*scale));
				this.effectMaterial.uniforms.tint.value.set(this.params.effect.color!==undefined?this.params.effect.color:0xffffff);

				var startAn = 1.5;
				if (this.params.effect.mode === "zoom") {
					startAn = 1.0
					this.effectMaterial.uniforms.targetMax.value.x = Math.max(this.effectMaterial.uniforms.targetMax.value.x, 0.1);
					this.effectMaterial.uniforms.targetMax.value.y = Math.max(this.effectMaterial.uniforms.targetMax.value.y, 0.1);
				}

				this.effectMaterial.uniforms.targetMax.value.x = Utils.ccmap(this.animationPc, 0.0, 1.0, startAn, this.effectMaterial.uniforms.targetMax.value.x)
				this.effectMaterial.uniforms.targetMax.value.y = Utils.ccmap(this.animationPc, 0.0, 1.0, startAn, this.effectMaterial.uniforms.targetMax.value.y);

				// Utils.autoReloadShaderManual(this.effectMaterial, 'shaders/isolate.vert', 'shaders/isolate.frag');
				// console.log(p.position, p.scale, this.effectMaterial.uniforms.targetMin, this.effectMaterial.uniforms.targetMax)
				// this.effectMaterial.uniforms.targetCenter.value.set(p.position.x*2.0-1.0, p.position.y*2.0-1.0);

				if (this.effectDiv) {
					var renderWidth = renderer.getSize().width,
						renderHeight = renderer.getSize().height;
					if (SETTINGS.STORYBOARD_MODE) {
						renderWidth = 162;
						renderHeight = 288;
					}
					var w = (p.scale.x*renderWidth);
					var h = (Math.abs(box[3])*renderHeight);
					var fontSize =  Utils.clamp((w / 15), '0.5', '1.1');
					$(this.effectDiv.div).css('font-size', fontSize +'em');
					w = Math.max(w, $(this.effectDiv.container).width());
					h = Math.max(h, $(this.effectDiv.div).height());

					var nx = Utils.clamp(p.position.x*renderWidth -w/2, 0, renderWidth-w);
					var ny = Utils.clamp((p.position.y - 0.125*invdirmult)*renderHeight - h/2 * invdirmult, 0, renderHeight-h);

					if (this.params.effect.textType!=='top' && this.params.effect.textType!=='center') {
						$(this.effectDiv.container).css({
							'transform': 'translate('+(nx)+'px,'+(ny )+'px)',
							'width': w+'px'
							// 'height': (h)+'px'
						});
					}

					if (!this.effectDiv.visible) {
						$(this.effectDiv.container).css('display', 'table');
						this.effectDiv.visible = true;
					}
				}
			}
		} else {
			if (this.selectedBox ) {
				if (this.boxDebugPlanes[this.selectedBoxId]) this.boxDebugPlanes[this.selectedBoxId].visible = false;
				if (this.effectDiv && this.effectDiv.visible) {
					$(this.effectDiv.container).hide();
					this.effectDiv.visible = false;
				}
			}
		}
	}

	dispose() {
		super.dispose();

		if (this.effectFbo) {
			SpritesheetVideo.disposeFbo(this.effectFbo);
			this.effectFbo = null;
		}
		if (this.effectMaterial) {
			this.effectMaterial.dispose();
			this.effectMaterial = null;
		}

	}

	render() {
		super.render();
		if (this.effectMaterial) Utils.renderMaterial(this.effectMaterial, this.effectFbo.texture, true);
		// renderer.render(this.boxesScene, Utils.topLeftCamera, this.fbo.texture, false);
	}


	getTexture() {
		if (!this.effectFbo || !this.fbo || !this.isReady()) return Utils.blackTexture;
		return this.effectFbo.texture.texture;
	}
};

export default SpritesheetVideoIsolate;


