import * as THREE from 'three';
import SETTINGS from '../controllers/Settings.js';

import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import RendererController from '../controllers/RendererController.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';

import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import SpritesheetVideo from './SpritesheetVideo.js';

//------------------------
class SpritesheetVideoDebugMask extends SpritesheetVideo {
	
	constructor(_info, batchName) {
		super(_info, batchName);
		this.arrowsScene = new THREE.Scene();
		this.arrows = [];
	}
	
	preload(batchName) {
		super.preload(batchName);
	}
	
	setParams(_params, _frames, _framesType) {
		super.setParams(_params, _frames, _framesType);
		if (this.maskFbo) this.maskFbo.resize(this.videoWidth,this.videoHeight);
	}
	
	prepare() {
		super.prepare();
		
		if (!this.maskFbo) {
			this.maskFbo = SpritesheetVideo.getFbo(this.videoWidth, this.videoHeight);
			this.maskMaterial = MaterialController.getMaterial('spritesheet', {
				TEXTURES_INVERT_MODE: 0,
				INVERT_COLORS: 0
			});
		}
	}
	
	onAnalysisLoaded(info) {
		if (!/mask/.test(this.params.analysis)) {
			console.warn("DEBUG MASK WITH NO MASK!");
			super.onAnalysisLoaded(info);
			return;
		}
		// window.bson = window.bson||new BSON();
		this.analysis = info.constructor === ArrayBuffer ? window.BSON.deserialize(Buffer.Buffer.from(new Uint8Array(info))) : info;
		console.log("Got info - Loading mask", info, this.analysis);

		this.maskImage = new Image();
		this.maskImage.onload = this.onAnalysisImageLoaded.bind(this);
		this.maskImage.onerror = function() {
			console.warn("Mask Image loading error");
		}

		//load image
		if (this.analysis.maskURL) {
			console.log("image from url",this.maskURL);
			this.maskImage.src = SETTINGS.IMAGES_URL+this.analysis.maskURL;

		//load image from AB
		} else if (this.analysis.mask) {
			console.log("image from AB",this.analysis.mask.buffer);
			var maskBlob = new Blob([this.analysis.mask.buffer],{'type':'image/jpeg'});
			this.maskURL = window.URL.createObjectURL(maskBlob);
			this.maskImage.src = this.maskURL;
		}
	}

	onAnalysisImageLoaded() {
		console.log("LOADED IMAGE!");
		this.analysisLoaded = true;

		var t = new THREE.Texture(this.maskImage);
		t.format = THREE.RGBFormat;
		t.type = THREE.UnsignedByteType;
		t.wrapT = t.wrapS = THREE.ClampToEdgeWrapping;
		t.magFilter = THREE.LinearFilter;
		t.minFilter = THREE.LinearFilter;
		t.generateMipmaps = false;
		t.anisotropy = 1;
		t.needsUpdate = true;
		t.wasUpdated = false;
		t.loaded = false;
		this.maskTexture = t;
	}

	update(opt) {
		super.update(opt);

		if (this.isReady() && this.maskTexture) {

			var cf = this.lastFrame;
			var divw = 8;
			var divh = 6;
			var frameInSheet = cf;

			var frameX = frameInSheet%divw;
			var frameY = Math.floor( frameInSheet / divw ); //divh-1-

			this.maskMaterial.uniforms.tDiffuse.value = this.maskTexture;	
			this.maskMaterial.uniforms.spritesheetOffset.value.x = (1.0/divw * frameX);
			this.maskMaterial.uniforms.spritesheetOffset.value.y = 1.0-(1.0/divh * frameY) - 1.0/divh;

			this.maskMaterial.uniforms.spritesheetScale.value.x = 1.0 / divw;
			this.maskMaterial.uniforms.spritesheetScale.value.y = 1.0 / divh;

			this.maskMaterial.uniforms.noisepc.value = 0.0;
			this.maskMaterial.uniforms.tNoise.value = Utils.emptyTexture;
		}
	}

	render() {
		super.render();
		if (this.isReady() && this.maskTexture) {
			Utils.renderMaterial(this.maskMaterial, this.fbo.texture, false);
		}
	}

	getTexture() {
		if (!this.fbo || !this.isReady()) return Utils.blackTexture;
		return this.fbo.texture.texture;
	}

	dispose() {
		super.dispose();

		if (this.maskImage) null;
		if (this.maskTexture) {
			this.maskTexture.dispose();
			this.maskTexture = null;
		}
		if (this.maskFbo) {
			this.maskMaterial.dispose();
			SpritesheetVideo.disposeFbo(this.maskFbo);
			this.maskFbo = null;
		}
		if (this.maskURL) window.URL.revokeObjectURL(this.maskURL);
		this.maskURL = null;
	}
};

export default SpritesheetVideoDebugMask;


