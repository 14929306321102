import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import Utils from '../utils/Utils.js';
import MultiStorage from '../utils/MultiStorage.js';
import RendererController from '../controllers/RendererController.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';
import TextureUpdateController from '../controllers/TextureUpdateController.js'
import OpticalFlowController from '../controllers/OpticalFlowController.js';

import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import SpritesheetVideo from './SpritesheetVideo.js';

//--------------------------
//
// var CanvasTextWrapper = require('canvas-text-wrapper').CanvasTextWrapper;
//
//------------------------
class SpritesheetVideoOpticalFlowBlend extends SpritesheetVideo {
	
	constructor() {
		super();

		this.trackingCenter = new THREE.Vector2(0.5,0.5);

		this.trackingEnabled = true;

	}

	preload(batchName) {
		super.preload(batchName);
		// this.highlightTexture = Loader.addTexture(batchName, SETTINGS.IMAGES_URL+"images/circle_tmp.png", {
		// 	format: THREE.RGBAFormat,
		// 	wrapping: THREE.ClampToEdgeWrapping,
		// 	minFilter: THREE.LinearFilter,
		// 	magFilter: THREE.LinearFilter,
		// 	generateMipmaps: false,
		// 	upload: true,
		// 	noCompression: true
		// });
	}

	play(contentDiv, textDiv) {
		super.play(contentDiv, textDiv);
		OpticalFlowController.setupCV();
		OpticalFlowController.activate(this, -0.1);

	}
	
	prepare() {
		super.prepare();
		OpticalFlowController.setupCV();
		OpticalFlowController.activate(this, -0.1);
	}
	
	onAnalysisLoaded(info) {
		super.onAnalysisLoaded(info);
	}
	
	stop() {

		super.stop();
	}
	
	update(opt) {
		super.update(opt);
		// console.log("update");
		if (this.isReady()) { //} && this.analysis && this.contentDiv)

			OpticalFlowController.update();

			var box = [OpticalFlowController.getCenterPoint().x, OpticalFlowController.getCenterPoint().y, 0.05, 0.05];
			this.trackingCenter.set(box[0]+box[2]/2, box[1]-box[3]);

			// this.trackingCenter.set(1.0, 1.0);
		}
	}

	dispose() {
		super.dispose();

	}

	render() {
		super.render();
		// renderer.render(this.boxesScene, Utils.topLeftCamera, this.fbo.texture, false);

	}
};

export default SpritesheetVideoOpticalFlowBlend;


