
import * as THREE from 'three';

import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import RendererController from '../controllers/RendererController.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';
import AnalysisController from '../controllers/AnalysisController.js'

import SpritesheetVideo from './SpritesheetVideo.js';
import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import SpritesheetLivefeed from "./SpritesheetLivefeed.js";

//
// Handles spritesheet playback, material and rendering
//
class Montage {

	constructor(params, batchName) {

		batchName = batchName||'main';

		// Montage spritesheets info
		// Spritesheet info
		this.spritesheets = [];
		this.isUser = [];
		this.isPhoto = [];
		this.isId = [];
		this.bins = [];
		this.isActive = true;
		
		this.videoWidth = 288;
		this.videoHeight = 512;
		this.currentTime = 0;
		this.currentSpritesheet = 0;
		this.lastTime = performance.now();
		this.nextSpritesheetTime = 0;
		this.ready = false;
		this.duration = 1.0;
		this.spritesheetTime = 0.0;
		this.trackingEnabled = false;
		this.trackingCenter = new THREE.Vector2(0.5,0.5);
		this.params = params;
		this.frozenId = this.params.frozenId || this.frozenId;
		this.hasLivefeed = false;
		this.hasMotionDelay = false;

		// if (this.params.freezeId) {
		// 	var globalState = MultiStorage.getGlobalState();
		// 	if (globalState.frozenId && globalState.frozenId[this.params.freezeId]) {
		// 		this.spritesheetTime = globalState.frozenId[this.params.freezeId][1];
		// 		this.currentSpritesheet = globalState.frozenId[this.params.freezeId][0];
		// 		console.log("FreezeMontage frozen reset from:",globalState.frozenId[this.params.freezeId]);
		// 	}
		// }

		if (!this.params) {
			console.error("No params in montage",this);;
		}
			//parse list
		this.freezeLast = params.freezeLast;
		this.montageList = params.montage || [];


		if (this.params.frozenId) console.log("frozen Loading", this.montageList, this.spritesheets);
		// if (params.freezeId) MontageController.montageByFreezeId[params.freezeId] = this;

		// if ((params.freezeLast||this.frozenId) && !SETTINGS.EDIT_MODE) {
		// 	if (MontageController.montageByFreezeId[this.frozenId]) {
		// 		PageLoader.loadFreezeList(MontageController.montageByFreezeId[this.frozenId].loadItems);
		// 	}
		// 	return;
		// }
		// if (this.params.freezeId) this.loadItems = PageLoader.freezeList();
		this.alternativeSpritesheets = [];

		for (var i=0; i<this.montageList.length; i++) {

			var vidParams = params;
			var targetVid = this.montageList[i];
			var isId = false;

			var isReloaded = MontageController.isReloadedUserVideo(targetVid);
			var reuseId = null;

			//check for reloaded
			var hasDefault = false;
			if (targetVid.constructor === Object) {
				vidParams = SpritesheetVideo.mergeParams(params, targetVid);
				if (targetVid.id.startsWith('id/')) {
					var vId = targetVid.id.split('/').pop();
					this.isId.push(vId);
					isId = true;
					if (isReloaded) {
						targetVid = targetVid.id;
					} else {
						if (targetVid.reuseId) reuseId = targetVid.reuseId;
						targetVid = targetVid.defaultVideo||'';
					}
				} else if (targetVid.defaultVideo && !isReloaded) {
					hasDefault = true;
					if (targetVid.reuseId) reuseId = targetVid.reuseId;
					targetVid = targetVid.defaultVideo;
					this.isId.push(false);
				} else {
					if (targetVid.reuseId) reuseId = targetVid.reuseId;
					targetVid = targetVid.id;
					this.isId.push(false);
				}
			}

			var source = targetVid.split('/').shift();
			var bin = targetVid.split('/').pop();

			var extension = targetVid.split('.').pop();
			if (extension) extension = extension.toLowerCase();
			if (source=='name') targetVid = targetVid.split('/').splice(1).join('/');

			if (source=="user" && !reuseId) reuseId = targetVid;
				
			var vidIsUser = (source === 'user' || isId || hasDefault) ? vidParams : false;
			this.isUser.push(vidIsUser);
			this.bins.push(bin);
			this.isPhoto.push(false);


			if (isReloaded) {
				
				var videoname = isId ? bin : (MontageController.localReloadedUserVideosByBin[bin]||MontageController.reloadedUserVideosByBin[bin]);
				this.spritesheets.push(MontageController.createSpritesheetFromReloadedUserVideoBin(videoname, vidParams, videoname, batchName));

				PageLoader.pushAltState();
				this.alternativeSpritesheets.push(MontageController.createSpritesheetFromBin(bin, source, vidParams, reuseId, batchName));
				PageLoader.popAltState();

			} else if (/mp4$|mov$|avi$|webm$/.test(extension) || targetVid.startsWith('videos/') || (!targetVid.startsWith('photos/') && /\*/.test(targetVid)) || source=='name') { //add spritesheet video source

				if (!targetVid.startsWith('videos/') && source!=='name') targetVid = 'videos/'+targetVid;
				this.spritesheets.push(MontageController.createSpritesheetFromPrebaked(targetVid, vidParams, reuseId, batchName));
				
			} else if (/jpeg$|jpg$|png$|webp$|gif$/.test(extension) || targetVid.startsWith('photos/')) { //add spritesheet photo source
				
				if (!targetVid.startsWith('photos/')) targetVid = 'photos/'+targetVid;
				var sp = MontageController.createSpritesheetFromPrebaked(targetVid, vidParams, reuseId, batchName);
				this.spritesheets.push(sp);
				// this.spritesheets.push(MontageController.createSpritesheetPhoto(targetVid, vidParams, batchName));
				this.isPhoto[this.isPhoto.length-1] = true;

			} else if (targetVid.split('/').length == 2 && (source === 'user' || source === 'others')) {
				
				this.spritesheets.push(MontageController.createSpritesheetFromBin(bin, source, vidParams, reuseId, batchName));

			} else if (source=="livefeed") {

				var sp = new SpritesheetLivefeed();
				sp.setup(vidParams, null, 'blob');
				sp.preload(batchName);
				this.spritesheets.push(sp);
				this.hasLivefeed = true;
				this.livefeedDirection = vidParams.camera || this.params.camera || "back";
			}

			if (vidIsUser && !isReloaded) {
				this.alternativeSpritesheets.push(this.spritesheets[this.spritesheets.length - 1]);
			} else if (!isReloaded) {
				this.alternativeSpritesheets.push(null);
			}

		}
		// if (this.params.freezeId) PageLoader.unfreezeList();

		for (var i=0; i<this.spritesheets.length; i++) {
			if (this.spritesheets[i] && this.spritesheets[i].hasMotionDelay) {
				this.hasMotionDelay = true;
			}
		}
		if (this.params.frozenId) console.log("frozen Loading", this.montageList, this.spritesheets);
		// this.originalSpritesheets = [].concat(this.spritesheet);
		// this.alternativeSpritesheets = [].concat(this.spritesheet);
		// 	if (!this.spritesheets[i] && !this.isUser[i]) {
		// 		this.spritesheets.splice(i,1);
		// 		this.isUser.splice(i,1);
		// 		this.bins.splice(i,1);
		// 		this.isPhoto.splice(i,1);
		// 		this.isId.splice(i,1);
		// 		i--;
		// 	}
		// }
	}
	
	//wait for spritesheets
	isReady() {
		var numsp = 0;
		this.ready = true;
		for (var i=0; i<this.spritesheets.length; i++) {
			if (this.spritesheets[i]) numsp++;
			if (this.spritesheets[i] && !this.spritesheets[i].isReady()) {
				// this.spritesheets[i].prepare();
				this.ready = false;
			}
			// console.log(i, this.spritesheets[i].isReady());
		}
		return this.ready && numsp>0;
	}

	//replace prebaked alt spritesheets or temporary video with user videos
	replaceUserVideos() {
		// if (this.params.videoSpeed == 0.6) window.m = this;
		if (this.freezeLast) return;
		for (var i=0; i<this.spritesheets.length; i++) {
			var sp = this.spritesheets[i];
			if (this.isUser[i] && (!sp || !sp.isUser || sp.isTemporary || (sp && !sp.isLocal && UserVideoController.preferLocal(this.bins[i], this.isId[i])))) {
				sp = UserVideoController.getVideoForBin(this.bins[i], this.isUser[i], this.isId[i], sp)||sp;
			}
			if (sp && !sp.hasErrors() && this.spritesheets[i] && this.spritesheets[i].name != sp.name) {
				console.log("Switched User Video")
				this.spritesheets[i].stop();
				this.spritesheets[i].dispose();
				this.spritesheets[i] = sp||this.spritesheets[i];
			}

			//switch back to others if user has errors
			if (this.spritesheets[i] && this.spritesheets[i].isUser && this.spritesheets[i].hasErrors()) {
				if (this.alternativeSpritesheets[i] && !this.alternativeSpritesheets[i].isUser && !this.alternativeSpritesheets[i].hasErrors()) {
					console.log("Switched back User Video to others", this.alternativeSpritesheets[i], this.alternativeSpritesheets[i].frames)
					this.spritesheets[i].stop();
					this.spritesheets[i].dispose();
					this.spritesheets[i] = this.alternativeSpritesheets[i];
					this.alternativeSpritesheets[i].prepare();
					this.alternativeSpritesheets[i].prepareTextures();
					this.alternativeSpritesheets[i].manualStart();
					this.spritesheets[i] = this.alternativeSpritesheets[i];
				}
			}

			this.spritesheets[i] = sp||this.spritesheets[i];
		}
	}

	prepare() {
		for (var i=0; i<this.spritesheets.length; i++) {
			if (this.spritesheets[i]) {
				this.spritesheets[i].prepare();
				this.spritesheets[i].prepareTextures();

				if (this.spritesheets[i].isUser && this.spritesheets[i].hasErrors() && !this.freezeLast) {
					if (this.alternativeSpritesheets[i] && !this.alternativeSpritesheets[i].isUser && !this.alternativeSpritesheets[i].hasErrors()) {
						this.spritesheets[i].stop();
						this.spritesheets[i].dispose();
						this.spritesheets[i] = this.alternativeSpritesheets[i];
						this.alternativeSpritesheets[i].prepare();
						this.alternativeSpritesheets[i].prepareTextures();
						this.alternativeSpritesheets[i].manualStart();
						this.spritesheets[i] = this.alternativeSpritesheets[i];
					}
				}
			}
		}
	}

	dispose() {

		var skipi = -1;
		if (this.params.frozenId || this.params.freezeId) {
			skipi = this.currentSpritesheet;
			if (this.spritesheets[skipi]) this.spritesheets[skipi].noDispose = true;
		}
		for (var i=0; i<this.spritesheets.length; i++) {
			if (this.spritesheets[i] && i!==skipi && !this.spritesheets[i].noDispose) {
				this.spritesheets[i].stop();
				this.spritesheets[i].dispose();
				this.spritesheets[i].playing = false;
			}
		}
	}

	activate() {
		window.m = this;
		this.overrideFill = undefined;
		this.overridePadding = undefined;

		this.isActive = true;
		this.lastTime = performance.now();

		if (this.params.freezeId) {
			var globalState = MultiStorage.getGlobalState();
			if (globalState.frozenId && globalState.frozenId[this.params.freezeId]) {
				// this.spritesheetTime = globalState.frozenId[this.params.freezeId][1];
				// this.currentSpritesheet = globalState.frozenId[this.params.freezeId][0];
			}
			MontageController.montageByFreezeId[this.params.freezeId] = this;
		}
		
		if (this.frozenId && MontageController.montageByFreezeId[this.params.frozenId]) {
		
			var fm = MontageController.montageByFreezeId[this.params.frozenId];

			this.freezeMontage = fm;
			this.currentSpritesheet = this.freezeMontage.currentSpritesheet;


			this.spritesheets = [fm.spritesheets[this.currentSpritesheet]];
			this.isUser = [fm.isUser[this.currentSpritesheet]];
			this.isPhoto = [fm.isPhoto[this.currentSpritesheet]];
			this.bins = [fm.bins[this.currentSpritesheet]];
			this.freezeLast = true;

			this.currentSpritesheet = 0;

			for (var i=0; i<this.spritesheets.length; i++) {
				if (this.spritesheets[i]) this.spritesheets[i].prepare();
			}

			this.spritesheetTime = 0; //this.freezeMontage.spritesheetTime;

			console.log("ACTIVATE FROZEN MONTAGE", this.spritesheetTime, this.currentSpritesheet);

			var globalState = MultiStorage.getGlobalState();
			globalState.frozenId = globalState.frozenId||{};
			// globalState.frozenId[this.params.frozenId] = [this.currentSpritesheet, this.spritesheetTime];
			MultiStorage.setGlobalState(globalState);

		} else if (this.params.frozenSelection) {
		
			this.spritesheetTime = 0;
			this.currentSpritesheet = 0;
			console.log("ACTIVATE FROZEN SELECTION!", this.spritesheets, this.currentSpritesheet);
		
		
		} else if (this.params.frozenId && !MontageController.montageByFreezeId[this.frozenId]) {
			console.log("ACTIVATE FROZEN MONTAGE");

			var globalState = MultiStorage.getGlobalState();

			if (globalState.frozenId && globalState.frozenId[this.frozenId]) {
				this.spritesheetTime = globalState.frozenId[this.frozenId][1];
				this.currentSpritesheet = globalState.frozenId[this.frozenId][0];
			} else {
				this.spritesheetTime = 0.0;
				this.currentSpritesheet = 0;
			}
		

		} else if (this.freezeLast && !SETTINGS.EDIT_MODE && this.freezeMontage) {
		
			this.spritesheetTime = this.freezeMontage.spritesheetTime;
			this.currentSpritesheet = this.freezeMontage.currentSpritesheet;
		
		}
	}
	deactivate() {
		this.isActive = false;
		for (var i=0; i<this.spritesheets.length; i++) {
			if (this.spritesheets[i]) {
				this.spritesheets[i].pause();
			}
		}

		if (this.params.freezeId && this.spritesheets && this.spritesheets[this.currentSpritesheet]) {
			var globalState = MultiStorage.getGlobalState();
			globalState.frozenSelection = globalState.frozenSelection || {}
			var spname = this.spritesheets[this.currentSpritesheet].name;
			globalState.frozenSelection[this.params.freezeId] = ((/videos|photos/.test(spname)) ? '' : 'name/')+spname;
			MultiStorage.setGlobalState(globalState);
			console.log("setting frozen selection", globalState.frozenSelection[this.params.freezeId])
		}
	}

	//
	// Update time and playback
	//
	update(opt) {
		var looped = false;

		if (this.isReady() && this.isActive) this.replaceUserVideos();
		
		if (this.isReady() && this.isActive) {

			//frozen
			if (this.freezeLast || this.frozenId || this.params.frozenSelection) {
				if (this.spritesheets[this.currentSpritesheet]) {
						if (!this.spritesheets[this.currentSpritesheet].playing) {
						this.spritesheets[this.currentSpritesheet].play(this.contentDiv);
					}
					this.spritesheets[this.currentSpritesheet].update({
						currentTime: Utils.clamp(this.spritesheetTime,0,this.spritesheets[this.currentSpritesheet].getDuration())
					});
					this.needsUpdate = this.spritesheets[this.currentSpritesheet].needsUpdate;
					this.overrideFill = this.spritesheets[this.currentSpritesheet].params.fill;
					this.overridePadding = this.spritesheets[this.currentSpritesheet].params.padding;
				}
				return;
			}

			//set spritesheets
			if (!this.spritesheets[this.currentSpritesheet]) this.currentSpritesheet++;

			//update playback
			var videoSpeed = this.spritesheets[this.currentSpritesheet] ? this.spritesheets[this.currentSpritesheet].params.videoSpeed : 1.0;
			if (opt && opt.videoSpeed!==undefined) videoSpeed = opt.videoSpeed;
			this.currentTime += (performance.now()-this.lastTime) / 1000 * videoSpeed;
			if (opt && opt.sync === true && opt.currentTime) {
				this.currentTime = opt.currentTime;
			}

			// Get next spritesheet
			var nextSpritesheet = this.currentSpritesheet, tries = 0;
			var looping = false;
			while ((nextSpritesheet>=this.spritesheets.length || !this.spritesheets[nextSpritesheet] || tries==0) && tries<50) {
				nextSpritesheet++;
				if (nextSpritesheet >= this.spritesheets.length) {nextSpritesheet = 0; looping=true;}
				tries++;
			}

			//
			// Get next spritesheet time
			//
			var totalTime = 0,
				timeUpToLastSpritesheet = 0,
				timeUpToNextSpritesheet = 0,
				numSpritesheets = 0;
			for (var i=0; i<this.spritesheets.length; i++) {
				if (this.spritesheets[i]) {
					numSpritesheets++;
					var duration =  this.spritesheets[i].getDuration();
					totalTime += duration;
					if (i < this.currentSpritesheet) timeUpToLastSpritesheet += duration;
					if (i < nextSpritesheet) timeUpToNextSpritesheet += duration;
				}
			}
			this.duration = totalTime;

			if (looping) timeUpToNextSpritesheet = totalTime;
			while (this.currentTime > totalTime) {this.currentTime -= totalTime; looped = true;}
			if (this.currentTime < 0) this.currentTime = 0;

			// console.log(this.currentSpritesheet, nextSpritesheet, this.currentTime, timeUpToNextSpritesheet, totalTime);
			if (opt&&opt.sync&&opt.looped) looped = true;

			// console.log((this.currentTime+0.00001 >= timeUpToNextSpritesheet-0.0001 || looped), this.currentSpritesheet != nextSpritesheet, numSpritesheets>1);
			if ((this.currentTime+0.00001 >= timeUpToNextSpritesheet-0.0001 || looped) && this.currentSpritesheet != nextSpritesheet && numSpritesheets>1) {
				if (this.spritesheets[this.currentSpritesheet]) this.spritesheets[this.currentSpritesheet].stop();
				this.currentSpritesheet = nextSpritesheet;
				if (this.spritesheets[this.currentSpritesheet]) {
					// this.spritesheets[this.currentSpritesheet].currentTime = this.currentTime - timeUpToNextSpritesheet; //skip ahead
					this.spritesheets[this.currentSpritesheet].play(this.contentDiv);
				}
				timeUpToLastSpritesheet = 0;
				for (var i=0; i<this.spritesheets.length; i++) {
					if (this.spritesheets[i]) {
						if (i <= this.currentSpritesheet) timeUpToLastSpritesheet += this.spritesheets[i].getDuration();
					}
				}
			}

			//
			// play & update current spritesheet
			//
			if (this.spritesheets[this.currentSpritesheet]) {
				if (!this.spritesheets[this.currentSpritesheet].playing) {
					this.spritesheets[this.currentSpritesheet].play(this.contentDiv);
					this.needsUpdate = true;
				}
				this.spritesheetTime = this.currentTime-timeUpToLastSpritesheet;
				// if (this.spritesheetTime < 0) {this.spritesheetTime=0.0; this.currentTime+=timeUpToLastSpritesheet;}
				// console.log(this.spritesheetTime, this.currentTime, timeUpToNextSpritesheet,timeUpToLastSpritesheet)

				// console.log(this.spritesheetTime, this.currentTime,  timeUpToLastSpritesheet);
				this.spritesheets[this.currentSpritesheet].update({
					currentTime: Utils.clamp(this.spritesheetTime,0,this.spritesheets[this.currentSpritesheet].getDuration())
				});
				this.needsUpdate = this.needsUpdate||this.spritesheets[this.currentSpritesheet].needsUpdate;
			
				this.overrideFill = this.spritesheets[this.currentSpritesheet].params.fill;
				this.overridePadding = this.spritesheets[this.currentSpritesheet].params.padding;

				if (this.spritesheets[this.currentSpritesheet].trackingEnabled) {
					this.trackingEnabled = true;
					this.shouldFindTracking = this.spritesheets[this.currentSpritesheet].shouldFindTracking;
					this.trackingFound = this.spritesheets[this.currentSpritesheet].trackingFound;
					this.trackingCenter.copy(this.spritesheets[this.currentSpritesheet].trackingCenter);
				}

			} else {
				this.overrideFill = undefined;
				this.overridePadding = undefined;
			}
		}
		this.lastTime = performance.now();
		return looped;
	}

	render() {
		if (this.ready && this.spritesheets[this.currentSpritesheet]) { //} && !this.isPhoto[this.currentSpritesheet]) {
			this.spritesheets[this.currentSpritesheet].render();
			this.needsUpdate = false;
		}
	}

	getTexture() {
		if (this.ready) {
			return this.spritesheets[this.currentSpritesheet] ? this.spritesheets[this.currentSpritesheet].getTexture() : Utils.whiteTexture;
		} else {
			return Utils.whiteTexture;
		}
	}

	getRatio() {
		if (this.ready && this.spritesheets[this.currentSpritesheet]) {
			return this.spritesheets[this.currentSpritesheet].ratio;
		} else {
			return 1.0;
		}
	}

}
export default Montage;

