import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';
import Fbo from '../utils/Fbo.js'
import RendererController from '../controllers/RendererController.js';
import MultiStorage from '../utils/MultiStorage.js';
import MotionController from '../controllers/MotionController.js';

import MontageController from '../controllers/MontageController.js';
import Montage from '../objects/Montage.js';
import SpritesheetVideo from '../objects/SpritesheetVideo.js';
import MaterialController from '../controllers/MaterialController.js';;

import MottoView from './MottoView.js';

//------------------------
// 
//  Base class for all views
//
//-------------------------
class MontageView extends MottoView {

	constructor(_info, batchName) {

		super(_info, batchName);

		this.textType = this.info["textType"] || 'top';
		this.uuid = Utils.generateUUID();
		// this.textBackground = (this.info.textBackground || (this.info.textBackground==undefined && this.info.textBackgroundColor)) ? (this.info.textBackgroundColor || ( this.info.dark ? '#000000' : '#ffffff')) : '#ffffff';
		this.textBackground = this.info.textBackground;
		this.pageNumberWhite = true;
		if (!this.info.textBackground) {
			this.textBackground = this.info.dark ? '#000000' : '#ffffff';
		}
		
		//
		// Add image to preloading queue
		//
		this.loaded = false;
		this.videoWidth = 288;
		this.videoHeight = 512;
		this.currentTime = 0;
		this.currentSpritesheet = 0;


		// Create all necessary spritesheets
		this.montage = new Montage(this.params, batchName); //MontageController.create(this.params);
		this.hasLivefeed = this.montage.hasLivefeed;
		this.livefeedDirection = this.montage.livefeedDirection;
		if (this.montage.hasMotionDelay) this.pageNumberWhite = false;
	}

	linkLast(lastView) {
		// if (this.params.freezeLast && lastView && lastView.montage) {
		// 	this.montage.freezeMontage = lastView.montage;
		// 	this.montage.spritesheets = lastView.montage.spritesheets;
		// 	this.montage.isUser = lastView.montage.isUser;
		// 	this.montage.isPhoto = lastView.montage.isPhoto;
		// 	this.montage.bins = lastView.montage.bins;
		// 	this.montage.freezeLast = true;
		// }
	}

	//
	// When starting - decode image for rendering
	//
	start() {
		if (this.started) return;
		super.start();

		this.scene = new THREE.Scene();
		this.playbackMaterial = MaterialController.getMaterial('playback');
		this.plane = new THREE.Mesh(Utils.planeGeometry, this.playbackMaterial);
		this.playbackMaterial.uniforms.tDiffuse.value = Utils.blackTexture;
		this.scene.add(this.plane);
		this.camera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, -0.5, 0.5);

		//prepare spritesheets
		this.montage.contentDiv = this.contentDiv;
		this.montage.textDiv = this.textDiv;
		this.montage.replaceUserVideos();
		this.montage.prepare();

		// if (this.params.freezeId) MontageController.montageByFreezeId[this.params.freezeId] = this.montage;
	}

	activate() {
		if (this.active) return;
		super.activate();

		//freezeLast
		// if (this.params.freezeId) MontageController.montageByFreezeId[this.params.freezeId] = this.montage;


		//prepare spritesheets
		this.montage.contentDiv = this.contentDiv;
		this.montage.textDiv = this.textDiv;
		this.montage.replaceUserVideos();
		this.montage.prepare();
		this.montage.activate();
		MotionController.referenceOrientation = MotionController.horizontalOrientation;
	}
	deactivate() {
		if (!this.active) return;
		super.deactivate();
		this.montage.deactivate();
	}
	

	//
	// When disposing - release image
	//
	stop() {
		if (!this.started) return;
		super.stop();
		this.playbackMaterial.dispose();
		this.scene.remove(this.plane);
		this.plane = this.scene = this.camera = null;
		this.montage.dispose();
	}
	
	update() {
		if (!this.started) return;
		super.update();
		this.montage.contentDiv = this.contentDiv;

		//prepare spritesheets
		if (this.positionX > -0.001 || AppStatus.goingBackwards) {
			if (!this.active) {
				this.montage.replaceUserVideos();
			}
			this.montage.prepare();
		}
		this.ready = this.active && this.montage.isReady();

		//
		// Playback
		//
		if (this.active) {
			this.montage.update();
			AppStatus.frameNeedsUpdate = AppStatus.frameNeedsUpdate||this.montage.needsUpdate;
		}
		if (this.ready) {

			this.playbackMaterial.uniforms.tDiffuse.value = this.montage.getTexture();

			//update ratio
			var ratio = this.montage.getRatio(),
				fill = this.montage.overrideFill || this.params.fill,
				padding = this.montage.overridePadding !== undefined ? this.montage.overridePadding : this.params.padding;


			if (fill === 'center') {
		
				this.plane.scale.set(1, ratio * 1 * (this.renderWidth() / this.renderHeight()), 1.0);
				this.plane.scale.multiplyScalar(1.0 - (padding==undefined?0.1:padding));
				this.plane.material.uniforms.ratio.value.set(1,1);
				
			} else if (fill === 'square') {

				if (ratio > 1.0) {
					this.plane.material.uniforms.ratio.value.set(1, this.videoWidth/this.videoHeight);
				} else {
					this.plane.material.uniforms.ratio.value.set(this.videoHeight/this.videoWidth, 1);
				}
				if (this.renderWidth() > this.renderHeight()) {
					this.plane.scale.set(1 / (this.renderWidth() / this.renderHeight()), 1, 1);
				} else {
					this.plane.scale.set(1, 1 / (this.renderHeight()/this.renderWidth()), 1);
				}
				this.plane.scale.multiplyScalar(1.0 - (padding==undefined?0.1:padding));

			} else {

				this.plane.material.uniforms.ratio.value.set(1,1);
				if ( (1/ratio) > this.renderWidth() / this.renderHeight()) {
					this.plane.scale.set((1/ratio) / (this.renderWidth() / this.renderHeight()), 1, 1);
				} else {
					this.plane.scale.set(1, (ratio) / (this.renderHeight()/this.renderWidth()), 1);
				}
				
			}
		}
	}

	//
	// Render image to webgl
	//
	render() {
		if (!this.active) return;
		super.render();
		if (this.montage.isReady()) {
			this.montage.render();
			renderer.render(this.scene, this.camera, this.renderTarget, false);
		}
		

	}

	//outside access
	isReady() {
		return super.isReady() && this.ready;
	}
}

export default MontageView;	

