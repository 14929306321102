import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';
import Fbo from '../utils/Fbo.js'
import RendererController from '../controllers/RendererController.js';
import MaterialController from '../controllers/MaterialController.js';
import MenuController from '../controllers/MenuController.js';
import EmbeddedText from "../controllers/EmbeddedText.js";
import AnalyticsController from '../controllers/AnalyticsController.js';

import MultiStorage from '../utils/MultiStorage.js';

import MontageController from '../controllers/MontageController.js';
import Montage from '../objects/Montage.js';
import SpritesheetVideo from '../objects/SpritesheetVideo.js';

import MottoView from './MottoView.js';
import MirrorMontageView from './MirrorMontageView.js'

//------------------------
// 
//  Base class for all views
//
//-------------------------
class EndView extends MirrorMontageView {

	constructor(_info, batchName, doneCallback) {
		super(_info, batchName);
		this.info.menu = true;
		this.doneCallback = doneCallback;
		this.lockRight = true;
		this.audioStarted = false;
		this.continueLaterActivated = false;
		this.visiblityChangedAdded = false;
		this.isReload = false;
		this.sentEvent = false;
		this.debounceWait = performance.now();

		//to change video, make sur
		this.info.continueLaterMontage = this.info.continueLaterMontage || EmbeddedText[SETTINGS.LANGUAGE].views.continueLaterMontage;
		this.info.returnText = (SETTINGS.OUTSIDE_ALLOWED ? EmbeddedText[SETTINGS.LANGUAGE].views.continueLaterReturn : EmbeddedText[SETTINGS.LANGUAGE].views.continueLaterReturnCovid);
		if (AppStatus.currentTaskId == 'out4' || AppStatus.currentTaskId == 'in4') this.info.returnText = EmbeddedText[SETTINGS.LANGUAGE].views.continueLaterReturn4;
		if (AppStatus.currentTaskId == 'ghost5') this.info.returnText = EmbeddedText[SETTINGS.LANGUAGE].views.continueLaterReturnGhost5;

		this.info.continueLaterText = this.info.continueLaterText || EmbeddedText[SETTINGS.LANGUAGE].views.continueLater;

		// Create all necessary spritesheets
		if (this.info.continueLaterMontage) {
			this.continueLaterMontage = new Montage(this.info.continueLaterMontage, batchName); //MontageController.create(this.params);
			this.hasLivefeed = this.hasLivefeed||this.continueLaterMontage.hasLivefeed;
		}
		this.clickFrame = -1;

	}
	
	//
	// When starting - decode image for rendering
	//
	start() {
		if (this.started) return;
		super.start();

		if (this.continueLaterMontage) {
			this.originalMaterial = this.playbackMaterial;
			this.originalMontage = this.montage;

			this.clplaybackMaterial = MaterialController.getMaterial('playback');
			this.clplaybackMaterial.uniforms.tDiffuse.value = Utils.blackTexture;
			this.continueLaterMontage.prepare();
		}

		if (MultiStorage.getState().continueLaterClicked) {
			this.isReload = true;
			this.startContinueLater( false );
		}
		this.enableLinks();
	}


	startContinueLater(fromClick) {
		if (this.info.finalPage) return;
		if (fromClick && performance.now()-this.debounceWait < 500) return;
		this.debounceWait = performance.now(); 
		if (fromClick) {
			AnalyticsController.trackEvent('continue_later', AppStatus.currentTaskId);
		}
		console.log("startContinueLater", fromClick);

		this.playbackMaterial = this.clplaybackMaterial
		this.plane.material = this.playbackMaterial;
		this.montage = this.continueLaterMontage;
		this.continueLaterActivated = true;
		this.continueLaterMontage.activate();
		this.originalMontage.deactivate();
		$(this.textDiv).html( fromClick ? this.info.continueLaterText : this.info.returnText );
		this.enableLinks();

		var state = MultiStorage.getState();
		state.continueLaterClicked = true;
		MultiStorage.setState(state);
		
		console.log("Playing continue later montage", this.montage);
	}

	enableLinks() {
		$(this.textDiv).css('pointer-events', 'auto');
		var fn = (function(textDiv, in_id, inout) {
			var link = $(textDiv).find('#link_'+inout+in_id.toString());
			if (link[0]) {
				$(link[0]).on('click', function(e) {
					var queryParameters = window.location.search.substr(1).split('&').concat(window.location.hash.substr(1).split('&'));
					var params = {};
					var found = false;
					for (var i=0; i<queryParameters.length; i++) {
						var pair = queryParameters[i].split('=');
						if (pair[0] == 't') {
							found = true;
							queryParameters[i] = 't='+inout+in_id.toString();
						}
						if (!queryParameters[i]) {
							queryParameters.splice(i,1); i--;
						}
					}
					console.log(queryParameters, queryParameters.join('&'));
					if (!found) queryParameters.push('t='+inout+in_id.toString());
					window.location.search = queryParameters.join('&');
				});
			}
		});
		for (var i=0; i<8; i++) {
			fn(this.textDiv, i,'in');
			fn(this.textDiv, i,'out');
		}

		$(this.textDiv).find('#link-credits').on('click', MenuController.openCredits);
		$(this.textDiv).find('#link-share').on('click', MenuController.openShare);
		$(this.textDiv).find('#link-restart').on('click', MenuController.openRestart);


		var link_in = $(this.textDiv).find('#link_in_next');
		var link_out = $(this.textDiv).find('#link_out_next');
		var target_in = 'in1';
		var target_out = 'out1';

		var tasksDone = MultiStorage.getGlobalState().tasksDone||{};


		// if (link_in[0]) {
		switch (AppStatus.currentTaskId) {
			case 'in1':
				target_in = 'in2';
				target_out = 'out2';
				break;

			case 'in2':
				target_in = 'in3';
				if (tasksDone['in4'] || tasksDone['out4']) target_in = 'in3';  //return from menu
				else if (tasksDone['out3'] || tasksDone['in3']) target_in = 'in4';

				target_out = 'out2';
				if (tasksDone['out2']) {
					target_out = 'out3';

					if (tasksDone['out3'] || tasksDone['in3']) {
						target_out = 'out4';
					}
					if (tasksDone['in4'] || tasksDone['out4']) target_out = 'out3';  //return from menu
				}
				break;

			case 'in3':
				target_in = 'in4';
				target_out = 'out2';
				if (tasksDone['out2']) target_out = 'out4';
				break;

			case 'in4':
				target_in = 'ghost5';
				target_out = 'ghost5';
				break;

			case 'out1':
				target_in = 'in2';
				target_out = 'out2';
				break;

			case 'out2':
				target_in = 'in2';
				target_out = 'out3';
					
				if (tasksDone['in4']) { //return from menu

					target_in = 'in3';
					target_out = 'out3';

				} else if (tasksDone['in3']) {

					target_in = 'in4';
					target_out = 'out4';

				} else if (tasksDone['in2']) {

					target_in = 'in3';
					target_out = 'out3';

				}
				break;

			case 'out3':
				target_in = 'in4';
				target_out = 'out4';
				break;

			case 'out4':
				target_in = 'ghost5';
				target_out = 'ghost5';
				break;

			case 'ghost5':
				target_in = 'end6';
				target_out = 'end6';
				break;

			case 'test':
				target_in = 'testf';
				target_out = 'testf';
				break;
		}

		
		setTimeout(function() {
			var link_continue= $(this.textDiv).find('#link_continue_later');
			$(link_continue).on('click', ()=>{
				this.startContinueLater(true);
				this.enableLinks();
			});
			var fn2 = (function(link, target) {
				$(link).on('click', function(e) {
					AppStatus.selectChapter(target);
				});
			});

			if (link_in[0]) fn2(link_in[0], target_in);
			if (link_out[0]) fn2(link_out[0], target_out);
		}.bind(this), 16);
	}


	activate() {
		if (this.active) return;
		super.activate();
		this.lockRight = true;
		this.debounceWait = performance.now();


		if (!this.sentEvent && !this.isReload) {
			this.sentEvent = true;
			AnalyticsController.trackEvent('finished_chapter', AppStatus.currentTaskId);
		}

		var globalState = MultiStorage.getGlobalState();
		globalState.tasksFinished = globalState.tasksFinished||{};
		globalState.tasksFinished[AppStatus.currentTaskId] = true;
		MultiStorage.setGlobalState(globalState);

		if (this.continueLaterMontage) {
			this.continueLaterMontage.contentDiv = this.contentDiv;
			this.continueLaterMontage.textDiv = this.textDiv;
			this.continueLaterMontage.replaceUserVideos();
			this.continueLaterMontage.prepare();
		}

		if (!this.visiblityChangedAdded) {
			this.visiblityChangedAdded = true;
			document.addEventListener("visibilitychange", this.handleVisibilityChange.bind(this), false);
		}

		if (this.info.finalPage) {
			var globalState = MultiStorage.getGlobalState();
			globalState.taskId = 'intro';
			globalState.reset = true;
			globalState.lastAction = Date.now();
			MultiStorage.setGlobalState(globalState);
		}
	}

	onClick() {
		if (!this.active || !this.continueLaterActivated || this.isReload) return;
		if (performance.now()-this.debounceWait < 500) return;
		this.debounceWait = performance.now(); 
		this.continueLaterActivated = false;
		$(this.textDiv).html( this.info.text );
		this.enableLinks();
	}

	deactivate() {
		if (!this.active) return;
		super.deactivate();
		if (this.continueLaterMontage) this.continueLaterMontage.deactivate();
		if (this.originalMontage) this.originalMontage.deactivate();
		this.isReload = false;

		// if (this.originalMaterial) this.playbackMaterial = this.originalMaterial;
		// this.plane.material = this.playbackMaterial;
		// if (this.originalMontage) this.montage = this.originalMontage;
		this.continueLaterActivated = false;
		$(this.textDiv).html( this.info.text );
		this.enableLinks();


		if (this.info.finalPage && !window.AppStatus.cleanedUp) {
			var globalState = MultiStorage.getGlobalState();
			globalState.taskId = AppStatus.currentTaskId;
			globalState.reset = false;
			globalState.lastAction = Date.now();
			MultiStorage.setGlobalState(globalState);
		}

	}

	handleVisibilityChange() {
		this.continueLaterActivated = false;

		// this.playbackMaterial = this.originalMaterial;
		// this.plane.material = this.playbackMaterial;
		// this.montage = this.originalMontage;

		// this.originalMontage.prepare();
		// this.originalMontage.activate();
		this.continueLaterActivated = false;
		if (!this.info.finalPage) {
			$(this.textDiv).html( this.info.returnText );
		}
		this.enableLinks();
	}


	update() {
		if (!this.started) return;
		super.update();

		
		this.ready = this.active && this.montage.isReady();
		if (this.continueLaterMontage && this.continueLaterActivated) this.ready = this.isReady && this.continueLaterMontage.isReady();

		//
		// Playback
		//
		if (this.active && this.continueLaterMontage && !this.continueLaterActivated) {
			this.continueLaterMontage.prepare();
		}	
	}

	render() {
		if (!this.active) return;
		super.render();

	}


//
	// When disposing - release image
	//
	stop() {
		if (!this.started) return;
		super.stop();
		this.playbackMaterial.dispose();
		if (this.scene) this.scene.remove(this.plane);
		this.plane = this.scene = this.camera = null;
		this.montage.dispose();

		if (this.continueLaterMontage) {
			this.continueLaterMontage.dispose();
			this.clplaybackMaterial.dispose();

			this.originalMontage.dispose();
			this.originalMaterial.dispose();
		}
	}


	// deactivate() {
	// 	if (!this.active) return;

	// }

	//
	// When disposing - release image
	//
	// stop() {
	// 	if (!this.started) return;
	// 	super.stop();
	// }

	// update() {
	// 	if (!this.started) return;
	// 	super.update();
	// }

	//
	// Render image to webgl
	//
	// render() {
	// 	if (!this.active) return;
	// 	super.render();
	// }
}

export default EndView;	

