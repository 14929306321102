import * as THREE from 'three';
import Utils3D from './3DUtils.js';

var Utils = Utils3D;

Utils.map = function(value, istart, istop, ostart, ostop) {
	return ostart + (ostop - ostart) * ((value - istart) / (istop - istart));
};
Utils.safecmap = function(value, istart, istop, ostart, ostop) {
	var v = Math.max(Math.min(ostart + (ostop - ostart) * ((value - istart) / (istop - istart)),ostop),ostart);
	if (isNaN(v)) return ostart;
	return v;
};
Utils.cmap = function(value, istart, istop, ostart, ostop) {
	return Math.max(Math.min(ostart + (ostop - ostart) * ((value - istart) / (istop - istart)),ostop),ostart);
};
Utils.ccmap = function(value, istart, istop, ostart, ostop) {
	return Math.max(Math.min(ostart + (ostop - ostart) * ((value - istart) / (istop - istart)),Math.max(ostop,ostart)),Math.min(ostop,ostart));
};
Utils.clamp = function(value, ostart, ostop) {
	return Math.max(Math.min(value,ostop),ostart);
};
Utils.randoma = function(arr) {
	return arr[Math.floor(Math.random()*arr.length)];
};
Utils.capitalizeFirstLetter = function(string) {
	if (!string || (string && !string.length)) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};
Utils.shuffle = function(arr) {
	var na = [];
	while (arr.length > 0) {
		var i = Math.floor(Math.random()*arr.length);
		na.push(arr[i]);
		arr.splice(i,1);
	}
	return na;
};
Utils.clone = function(obj) {
	return JSON.parse(JSON.stringify(obj));
};

Utils.cloneInto = function(source, target) {
	jQuery.extend(true, target, source);
	return Utils.clone(target);
};
Utils.cloneIntoAdd = function(source, target) {
	for (var prop in source) {
		if (target[prop] && target[prop].constructor == Array) {
			target[prop] = target[prop].concat(source[prop]);
		} else {
			target[prop] = source[prop];
		}
	}
	// jQuery.extend(true, target, source);
	return Utils.clone(target);
};



Utils.fastConcat = function(a,b) {
	Array.prototype.push.apply(a, b);
	return a;
};

Utils.shuffleClone = function(arr) {
	var na = [];
	var source = [];
	for (var i=0; i<arr.length; i++) {
		source[i] = i;
	}
	while (source.length > 0) {
		var n = Math.floor(Math.random()*source.length);
		na.push(arr[source[n]]);
		source.splice(n,1);
	}
	return na;
};
Utils.zeroPad = function(num, numzeros) {
	var s = parseInt(num,10).toString();
	while(s.length<numzeros) s = '0'+s;
	return s;
};


Utils.generateUUID = function() {
	if (window.SETTINGS && window.SETTINGS.MECHANICAL_TURK_MODE) return 'mec'+(Math.floor(Math.random()*999999));
	return Date.now().toString()+'_'+Math.random().toString().split('.').pop()
};

Utils.lerp = function lerp(a, b, pc) {
	return a * (1.0-pc) + b * pc;
};

Utils.lerpSnap = function(a, b, pc) {
	// bias = bias || 0.001;
	if (Math.abs(a-b) <= 0.001) return b;
	return a * (1.0-pc) + b * pc;
};

Utils.deltaSmoothing = function deltaSmoothing(src, target, smoothing, delta) {
	return Utils.lerp(target, src, Math.max(Math.min(Math.pow(smoothing, delta),1.0),0.0)  );
};

Utils.deltaSmoothingSnap = function(src, target, smoothing, delta) {
	return Utils.lerpSnap(src, target, Math.max(Math.min(Math.pow(smoothing, delta),1.0),0.0));
};

Utils.deltaSmoothingSnap2 = function(src, target, smoothing, delta) {
	return Utils.lerpSnap(src, target, Math.max(Math.min(1.0-Math.pow(1.0-smoothing, delta),1.0),0.0));
	// return Utils.lerpSnap(src, target, Math.max(Math.min(smoothing * delta,1.0),0.0));
};


Utils.normalizeAngle = function(a) {
	while (a<0) a += Math.PI*2;
	while (a>Math.PI*2) a-= Math.PI*2;
	return a;
};
Utils.normalizeAngleDegree = function(a) {
	while (a<0) a += 360;
	while (a>360) a-= 360;
	return a;
};
Utils.distance = function(xa,ya,xb,yb) {
	return Math.sqrt(Math.pow(xa-xb,2.0) + Math.pow(ya-yb,2.0));
}

Utils.distanceBetweenAngles = function(a,b) {
	a = Utils.normalizeAngle(a);
	b = Utils.normalizeAngle(b);
	var abs = Math.abs(a-b);
	if (abs < Math.PI) return abs;

	if (a >= Math.PI*1.0 && b <= Math.PI*1.0) return Math.abs(a - (b+Math.PI*2));
	if (b >= Math.PI*1.0 && a <= Math.PI*1.0) return Math.abs((a+Math.PI*2) - b);
	return abs;
};

Utils.directionBetweenAngle = Utils.directionBetweenAngles = function(a,b) {
	var abs = Math.abs(a-b);
	if (abs <= Math.PI) return (a < b) ? 1 : -1;

	if (a >= Math.PI*1.0 && b <= Math.PI*1.0) return 1;
	if (b >= Math.PI*1.0 && a <= Math.PI*1.0) return -1;
	return 1;
};
Utils.lerpAngle = function(a, b, pc) {

	a = Utils.normalizeAngle(a);
	b = Utils.normalizeAngle(b);

	var dir = Utils.directionBetweenAngles(a,b),
		dist = Utils.distanceBetweenAngles(a,b);

	return a + dir * dist * pc;
};
Utils.lerpAngleSnap = function(a, b, pc) {

	a = Utils.normalizeAngle(a);
	b = Utils.normalizeAngle(b);

	var dir = Utils.directionBetweenAngles(a,b),
		dist = Utils.distanceBetweenAngles(a,b);
	
	if (dist <= 0.0001) return a + dir * dist;
	return a + dir * (dist * pc);
};

Utils.lerpAngleSnapDelta = function(a, b, smoothing, delta) {

	a = Utils.normalizeAngle(a);
	b = Utils.normalizeAngle(b);

	var dir = Utils.directionBetweenAngles(a,b),
		dist = Utils.distanceBetweenAngles(a,b);
	
	//if (dist <= 0.00001) return a + dir * dist;
	return Utils.deltaSmoothingSnap(a,  a + dir *  dist, smoothing, delta);
};

Utils.emptyCanvas = document.createElement('canvas');
if (Utils.emptyCanvas) {
	Utils.emptyCanvas.width = Utils.emptyCanvas.height = 4;
}
Utils.emptyTexture = Utils.createTexture(Utils.emptyCanvas, THREE.LuminanceFormat, false, false);
Utils.emptyTexture.needsUpdate = !!Utils.emptyCanvas;


Utils.replaceGender = function(text, gender) {
	var x = text;
	var y = /\[(.*?)\]/gi;
	var z = null;
	while((z=y.exec(text)) != null) {
		x = x.replace(z[0], (z[1].split('//')[gender||0]||'').trim());
	}
	return x;
};


(function(Utils) {
	var timeStarts = {},
		averages = {},
		lastLog = {};

	Utils.time = function(name) {
		timeStarts[name] = performance.now();
	};

	Utils.timeEnd = function(name, printEvery) {
		var now = performance.now();
		if (averages[name] === undefined) {
			averages[name] = (now-timeStarts[name]);
			lastLog[name] = -100000;
		}
		averages[name] = averages[name] * 0.95 + 0.05 * (now-timeStarts[name]);
		if (!printEvery) console.log(name, (now-timeStarts[name]));

		if (now - lastLog[name] > (printEvery||5000)) {
			console.log('Average time for '+name+': '+averages[name]);
			lastLog[name] = now;
		} 

	};
})(Utils);

//extra string capitalize
Utils.capitalize = function(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

window.Utils = Utils;
export default Utils