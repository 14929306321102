import SETTINGS from './Settings.js';
import AppStatus from './AppStatus.js';
import EmbeddedText from './EmbeddedText.js'
import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import getBranchTasks from '../views/BranchViewUtils.js'


// import SpritesheetVideoCreate from '../objects/SpritesheetVideoCreate.js'
import SpritesheetVideo from '../objects/SpritesheetVideo.js'
import Montage from '../objects/Montage.js';

import Loader from '../loading/Loader.js'
import PageLoader from '../loading/PageLoader.js'
import LoaderXHR from '../loading/LoaderXHR.js'
import LoaderPrivateXHR from '../loading/LoaderPrivateXHR.js'
import UserVideoController from './UserVideoController.js';

class MontageController {

	constructor() {
		this.reset();
	}
	reset() {
		this.bins = {};
		this.idInfo = {};
		this.analysisById = {};
		this.reloadedUserVideosByBin = {};
		this.localReloadedUserVideosByBin = {};

		this.reuseIds = {};

		this.montageByFreezeId = {};
		

		this.sourceBins = {};
		this.sourcePrebakedInfo = {};
		this.prebakedInfo = {};
		this.userVideos = {};
		this.idVideos = {};

		this.xhrById = {};
	}

	init() {
		this.reuseVideosById = Utils.clone(MultiStorage.getGlobalState().reuseVideosById || {});
	}


	preload(tasks, batchName, _info) {
		//parse all montages for others/ || aatoaa/  || .mp4 || .jpg|.png|.webm|.jpeg
		var info = _info || {
			user_uuid: SETTINGS.uuid,
			bins: {},
			videos: (SETTINGS.EDIT_MODE?{}:{'videos/bookmark/book_close.mov':1, 'videos/bookmark/book_open.mov':1}),
			userVideos: Object.values(MultiStorage.getGlobalState().userVideos)
		};

		for (var i=0; i<tasks.length; i++) {

			tasks[i].params = tasks[i].params||{};

			if (tasks[i].params.frozenId) {
				var globalState = MultiStorage.getGlobalState();
				if (globalState.frozenSelection && globalState.frozenSelection[tasks[i].params.frozenId]) {
					console.log("GETTING FROZEN SELECTION", globalState.frozenSelection[tasks[i].params.frozenId]);
					tasks[i].params.montage = [globalState.frozenSelection[tasks[i].params.frozenId]]; // this.preloadMontageList([globalState.frozenSelection[tasks[i].params.frozenId]], info);
					console.log("frozen montage:", tasks[i].params.montage);
					tasks[i].params.frozenSelection = true;
					// tasks[i].params.frozenId = false;
				}
			}

			if (tasks[i].type == 'prebaked' && tasks[i].params.video && (tasks[i].params.videoDuration < 5.0|| SETTINGS.EDIT_MODE || SETTINGS.isIOS)) {
				tasks[i].type = 'montage';
				tasks[i].params.montage = [tasks[i].params.video];
			}	

			if (tasks[i].params.referenceMontage) tasks[i].params.referenceMontage.montage = this.preloadMontageList(tasks[i].params.referenceMontage.montage, info);
			if (tasks[i].params.montage) tasks[i].params.montage = this.preloadMontageList(tasks[i].params.montage, info);
			if (tasks[i].params.montageA) tasks[i].params.montageA.montage = this.preloadMontageList(tasks[i].params.montageA.montage, info);
			if (tasks[i].params.montageB) tasks[i].params.montageB.montage = this.preloadMontageList(tasks[i].params.montageB.montage, info);
			if (tasks[i].params.montageC) tasks[i].params.montageC.montage = this.preloadMontageList(tasks[i].params.montageC.montage, info);
			if (tasks[i].params.montageBlend) tasks[i].params.montageBlend.montage = this.preloadMontageList(tasks[i].params.montageBlend.montage, info);
			if (tasks[i].continueLaterMontage) tasks[i].continueLaterMontage.montage = this.preloadMontageList(tasks[i].continueLaterMontage.montage, info);


			if (tasks[i].theEnd) this.preloadMontageList(EmbeddedText[SETTINGS.LANGUAGE].views.continueLaterMontage.montage, info);

			if (tasks[i].params.faces && tasks[i].params.faces.type == "montage" && tasks[i].params.faces.montageParams && tasks[i].params.faces.montageParams.montage) tasks[i].params.faces.montageParams.montage = this.preloadMontageList(tasks[i].params.faces.montageParams.montage, info);
			
			if (tasks[i].type == 'montage-orientation') {
				var orientations = ['up', 'down', 'left', 'right', 'forward', 'static', 'middle', 'front', 'straight', 'north', 'south', 'northsouth', 'opposite'];
				for (var on in orientations) {
					var o = orientations[on];
					if (tasks[i].params[o]) tasks[i].params[o].montage = this.preloadMontageList(tasks[i].params[o].montage, info);
				}
			}

			if (tasks[i].type=="branch") {
				for (var j=0; j<tasks[i].branches.length; j++) {
					if (tasks[i].branches[j].tasks) this.preload(tasks[i].branches[j].tasks, batchName, info);
				}
			}

			if (tasks[i].type=="simple-branch") {
				var branches = getBranchTasks(tasks[i])
				for (var j=0; j<branches.length; j++) {
					if (branches[j].tasks) this.preload(branches[j].tasks, batchName, info);
				}
			}
		}

		if (!_info) {
			this.videolistXHR = Loader.addXHR(batchName||'task_info', SETTINGS.API_SERVER_URL+(SETTINGS.VIDEOLIST_CACHED?'/videolist_cached':'/videolist'), 'json', true);
			this.videolistXHR.protocol = 'post';
			this.videolistXHR.params = new FormData();
			this.videolistXHR.params.append('videolist', JSON.stringify(info));
			this.videolistXHR.params.append('guid', Utils.generateUUID());
			this.videolistXHR.guid = Utils.generateUUID();
			this.xhrById[batchName||'task_info'] = this.videolistXHR;
		}

	}

	preprocessMontage(montageList) {
		montageList = montageList || [];
		if (montageList[0] === "one-random") {
			montageList.shift();
			montageList = [Utils.randoma(montageList)];
		}
		for (var i=0; i<montageList.length; i++) {
			var targetVid = montageList[i];
			if (targetVid.constructor === Array) {
				if (targetVid[0] === "one-random") {
					targetVid.shift();
					montageList[i] = Utils.randoma(targetVid); //.toLowerCase().replace('archives/', 'videos/');
				}
			}
			if (montageList[i].constructor === Object) {
				if (montageList[i].id) montageList[i].id = montageList[i].id.toLowerCase().replace('archives/', 'videos/');
				if (montageList[i].defaultVideo) montageList[i].defaultVideo = montageList[i].defaultVideo.toLowerCase().replace('archives/', 'videos/');
			} else {
				montageList[i] = montageList[i].toLowerCase().replace('archives/', 'videos/');
			}
		}
		return montageList;
	}
	

	preloadMontageList(montageList, info) {

		montageList = this.preprocessMontage(montageList);
		for (var j=0; j<montageList.length; j++) {
			var targetVid = montageList[j];
			var reuseId = null;
			if (targetVid.constructor === Object) {
				if (targetVid.faces && targetVid.faces.type == "montage" && targetVid.faces.montageParams && targetVid.faces.montageParams.montage) {
					targetVid.faces.montageParams.montage = this.preprocessMontage(targetVid.faces.montageParams.montage);
					this.preloadMontageList(targetVid.faces.montageParams.montage, info)
				}
				reuseId = targetVid.reuseId||null;
				if (targetVid.id.startsWith('id/') || targetVid.defaultVideo) {
					targetVid = targetVid.defaultVideo||'';
				} else {
					targetVid = targetVid.id;
				}
				// if (targetVid.geoloc) info.getGeoInfo = true;
			}
			targetVid = targetVid.toLowerCase().replace('archives/', 'videos/');
			var source = targetVid.split('/').shift();
			var bin = targetVid.split('/').pop();

			var extension = targetVid.split('.').pop();
			if (extension) extension = extension.toLowerCase();

			if (source=="user" && !reuseId) reuseId = targetVid;
			if (reuseId && this.reuseVideosById[reuseId]) {
				source = "name";
				targetVid = this.reuseVideosById[reuseId];
			}

			if (source === 'user') {
				if (!reuseId || (reuseId && !this.reuseIds[reuseId])) {
					info.bins[bin] = (info.bins[bin] || 0) + 1;
				}

			} else if (source === 'name') {
				if (source=='name' && !reuseId) targetVid = targetVid.split('/').splice(1).join('/');
				info.videos[targetVid] = (info.videos[targetVid]|| 0) + 1;

			} else if (source === 'others') {

				// if (!reuseId || (reuseId && !this.reuseIds[reuseId])) {
					info.bins[bin] = (info.bins[bin] || 0) + 1;
				// }

			} else if (source === 'aatoaa') {
				info.bins[bin] = (info.bins[bin] || 0) + 1;

			} else if (/mp4$|mov$|avi$|webm$/.test(extension) || (!targetVid.startsWith('photos/') && /\*/.test(targetVid))) { //add spritesheet video source
				
				if (!reuseId || (reuseId && !this.reuseIds[reuseId])) {
					if (!targetVid.startsWith('videos/')) targetVid = 'videos/'+targetVid;
					info.videos[targetVid] = (info.videos[targetVid]|| 0) + 1;
				}
			
			} else if (/jpeg$|jpg$|png$|webp$|gif$/.test(extension) || targetVid.startsWith('photos/')) { //add spritesheet photo source
				
				if (!reuseId || (reuseId && !this.reuseIds[reuseId])) {
					if (!targetVid.startsWith('photos/')) targetVid = 'photos/'+targetVid;
					info.videos[targetVid] = (info.videos[targetVid]|| 0) + 1;
				}
			}

			if (reuseId) this.reuseIds[reuseId] = true;
		}
		return montageList;
	}


	setup(batchName) {
		var xhr_info = (this.xhrById[batchName||'task_info']||this.videolistXHR).value;

		this.sourceBins = Utils.cloneIntoAdd(xhr_info.bins, this.sourceBins);
		this.sourcePrebakedInfo = Utils.cloneIntoAdd(this.sourcePrebakedInfo, xhr_info.videos);
		this.bins = Utils.cloneIntoAdd(xhr_info.bins, this.bins);
		this.prebakedInfo = Utils.cloneIntoAdd(xhr_info.videos, this.prebakedInfo);


		this.userVideos = Utils.clone( MultiStorage.getGlobalState().userVideosByName||{});
		if (xhr_info.userVideos) {
			for (var o in xhr_info.userVideos) {
				this.userVideos[o] = xhr_info.userVideos[o];
			}
		}
		

		// var userVideoSources = MultiStorage.getGlobalState().userVideos;

		// for (var recordId in userVideoSources) {
		// 	this.userVideos[recordId] = userVideoSources[recordId];
		// 	this.userVideos[recordId].use_source = true;
		// }
		// for (var o in xhr_info.userVideos) {

		// }

		// this.userVideos = Utils.cloneInto(keys, this.userVideos);
		// for (var o in keys) {
		// 	this.userVideos[o].use_source = true;
		// 	this.userVideos[o] = this.userVideos[keys[o]] || this.userVideos[o];
		// }

		this.reloadedUserVideosByBin = Utils.cloneIntoAdd(MultiStorage.getState().userVideosByBin||{}, MultiStorage.getGlobalState().userVideosByBin||{});
		this.localReloadedUserVideosByBin = this.localReloadedUserVideosByBin||{}; //MultiStorage.getState().userVideosByBin;
		// this.reuseVideosById = Utils.clone(MultiStorage.getGlobalState().reuseVideosById || {});


		this.videolistXHR = null;
		this.xhrById[batchName||'task_info'] = undefined;

		// for (var o in uvb) {
		// 	 this.reloadedUserVideosByBin[o] = this.userVideos[uvb[o]];
		// }
		// this.idVideos = Utils.cloneInto(this.idVideos, xhr_info.idVideos);
	}

	isReloadedUserVideo(targetVid) {
		if (targetVid.constructor === Object) {
			if (targetVid.id.startsWith('id/')) {
				var vId = targetVid.id.split('/').pop();
				return !!this.userVideos[vId];
			} else {
				targetVid = targetVid.id;
			}
		}
		var bin = targetVid.split('/').pop();
		var source = targetVid.split('/').shift();
		return source==="user" && (!!this.reloadedUserVideosByBin[bin] || this.localReloadedUserVideosByBin[bin]);	
	}


	// createSpritesheets(view, params) {
	// 	view.spritesheets = [];
	// 	view.isUser = [];
	// 	view.photos = [];
	// 	view.isPhoto = [];
	// 	view.isId = [];
	// 	view.bins = [];
	// 	var montageList = params.montage || [];
	// 	this.parseMontageList(montageList, view, params);
	// }
	// parseMontageList(montageList, view, params) {
	// 	for (var i=0; i<montageList.length; i++) {

	// 		var vidParams = params;
	// 		var targetVid = montageList[i];
	// 		var isId = false;

	// 		if (targetVid.constructor === Object) {
	// 			vidParams = SpritesheetVideo.mergeParams(params, targetVid);
	// 			if (targetVid.id.startsWith('id/')) {
	// 				view.isId.push(targetVid.id.split('/').pop());
	// 				isId = true;
	// 				targetVid = targetVid.defaultVideo;
	// 			} else {
	// 				targetVid = targetVid.id;
	// 				view.isId.push(false);
	// 			}

	// 		}

	// 		var source = targetVid.split('/').shift();
	// 		var bin = targetVid.split('/').pop();

	// 		var extension = targetVid.split('.').pop();
	// 		if (extension) extension = extension.toLowerCase();

	// 		view.isUser.push((source === 'user' || isId) ? vidParams : false);
	// 		view.bins.push(bin);
	// 		view.isPhoto.push(false);

	// 		if (/mp4|mov|avi|webm/.test(extension) || /\*/.test(targetVid)) { //add spritesheet video source

	// 			view.spritesheets.push(this.createSpritesheetFromPrebaked(targetVid, vidParams, 'main'));
				
	// 		} else if (/jpeg|jpg|png|webp|gif/.test(extension)) { //add spritesheet photo source
			
	// 			view.spritesheets.push(this.createSpritesheetPhoto(targetVid, vidParams, 'main'));
	// 			view.isPhoto[view.isPhoto.length-1] = true;

	// 		} else if (targetVid.split('/').length == 2 && (source === 'user' || source === 'others' || source === 'aatoaa' || source === 'archives')) {
				
	// 			view.spritesheets.push(this.createSpritesheetFromBin(bin, source, vidParams, 'main'));

	// 		}
	// 	}
	// }

	//
	// Use global loaded info to create spritesheet
	// Select randomly from bin
	//
	createSpritesheetFromBin(bin, source, montageParams, reuseId, batchName) {
		var info = null;

		if (!this.sourceBins[bin] && (!reuseId || (reuseId && !this.reuseVideosById[reuseId]))) {
			console.log("No loaded info for ",bin);
			return null;
		}

		//reuse id
		if (reuseId && this.reuseVideosById[reuseId]) {
			return this.createSpritesheetFromPrebaked(this.reuseVideosById[reuseId], montageParams, null, batchName);
		}

		//reshuffle
		if (this.sourceBins[bin] && this.sourceBins[bin].length > 0) {

			//select randomly in bin
			if (this.bins[bin].length <= 0) {
				this.bins[bin] = Utils.clone(this.sourceBins[bin]);
			}
			info = this.bins[bin].pop();

			//try again if already picked as user video
			if (!reuseId && (MultiStorage.getGlobalState().reuseVideosById||{})['user/'+bin]) {
				if (info.video_name === MultiStorage.getGlobalState().reuseVideosById['user/'+bin]) {
					if (this.bins[bin].length <= 0) {
						this.bins[bin] = Utils.clone(this.sourceBins[bin]);
					}
					info = this.bins[bin].pop();
				}
			}

			//save to reuse and remove from source
			if (reuseId) {
				this.reuseVideosById[reuseId] = info.video_name;
				this.sourcePrebakedInfo[info.video_name] = this.sourcePrebakedInfo[info.video_name]||[info];
				this.prebakedInfo[info.video_name] = this.prebakedInfo[info.video_name]||[info];

				var globalState = MultiStorage.getGlobalState();
				globalState.reuseVideosById = this.reuseVideosById;
				MultiStorage.setGlobalState(globalState);
				if (this.sourceBins[bin].length > 1) {
					for (var i=0; i<this.sourceBins[bin].length; i++) {
						if (this.sourceBins[bin][i].video_name == info.video_name) {
							this.sourceBins[bin].splice(i,1); i--;
						}
					}
				}
			}
			return this.createSpritesheetFromInfo(info, montageParams, batchName);
		}
		console.log("NO VIDEO IN BIN:",bin);
		return null;
	}

	//
	// Use global loaded info to create spritesheet
	//
	createSpritesheetFromPrebaked(bin, montageParams, reuseId, batchName) {

		// if (!bin.startsWith('videos/')) bin = 'videos/'+bin;
		if (/mp4$|mov$|avi$|webm$/i.test(bin)  || bin.startsWith('videos/') || (!bin.startsWith('photos/') && /\*/.test(bin))) { //add spritesheet video source
			if (!bin.startsWith('videos/')) bin = 'videos/'+bin;
		} else if (/jpeg$|jpg$|png$|webp$|gif$/i.test(bin) || bin.startsWith('photos/')) { //add spritesheet photo source
			if (!bin.startsWith('photos/')) bin = 'photos/'+bin;
		}


		if (reuseId && this.reuseVideosById[reuseId]) {
			return this.createSpritesheetFromPrebaked(this.reuseVideosById[reuseId], montageParams, null, batchName);
		}

		if (!this.prebakedInfo[bin]) {
			console.log("No loaded info for ",bin, this.sourcePrebakedInfo);
			return null;
		}

		//reshuffle
		if (this.sourcePrebakedInfo[bin] && this.sourcePrebakedInfo[bin].length > 0) {
			//select randomly in bin
			if (this.prebakedInfo[bin].length <= 0) {
				// console.log('used all for:',bin,' refreshing');
				this.prebakedInfo[bin] = Utils.clone(this.sourcePrebakedInfo[bin]);
			}
			var info = this.prebakedInfo[bin].pop();

			//save to reuse and remove from source
			if (reuseId) {
				this.reuseVideosById[reuseId] = info.video_name;
				var globalState = MultiStorage.getGlobalState();
				globalState.reuseVideosById = this.reuseVideosById;
				MultiStorage.setGlobalState(globalState);
				if (this.sourcePrebakedInfo[bin].length > 1) {
					for (var i=0; i<this.sourcePrebakedInfo[bin].length; i++) {
						if (this.sourcePrebakedInfo[bin][i].video_name == info.video_name) {
							this.sourcePrebakedInfo[bin].splice(i,1); i--;
						}
					}
				}
			}
			return this.createSpritesheetFromInfo(info, montageParams, batchName);
		}
		console.log("NO SOURCE FOR:",bin);
		return null; // this.createSpritesheetFromInfo(this.prebakedInfo[bin], montageParams, batchName);
	}

	createSpritesheetFromReloadedUserVideo(videoName, montageParams, id, batchName) {
		console.log("USER VIDEO", videoName, this.userVideos[videoName]);
		if (!this.userVideos[videoName]) {
			console.log("No loaded info for ",videoName);
			return null;
		}

		var info = this.userVideos[videoName];
		var duration = montageParams.duration==undefined ? 2.0 : montageParams.duration;
		if (montageParams.videoDuration!==undefined) duration = montageParams.videoDuration;
		if (duration <= 1/24) duration = 1/24;

		var params = {
			"videoDuration": duration,
			"palindrome": !!montageParams.palindrome,
			"reverse": !!montageParams.reverse,
			"videoSpeed": montageParams.videoSpeed||montageParams.speed||1.0,
			"effect": montageParams.effect,
			"analysis": montageParams.analysis,
			"faces": montageParams.faces,
			"startOffset": montageParams.startOffset,
			"jpeg": true
		};
		if (montageParams.videoSpeed!=undefined) params.videoSpeed = montageParams.videoSpeed;


		this.userVideos[videoName].source_numx = this.userVideos[videoName].numx;
		this.userVideos[videoName].source_numy = this.userVideos[videoName].numy;
		this.userVideos[videoName].source_num_spritesheets = this.userVideos[videoName].num_spritesheets;
		this.userVideos[videoName].source_video_width = this.userVideos[videoName].video_width;
		this.userVideos[videoName].source_video_height = this.userVideos[videoName].video_height;


		var sp = this.createSpritesheetFromInfo(this.userVideos[videoName], params, batchName);
		sp.reloaded = true;
		sp.isUser = true;
		// sp.mpeg = false;
		// sp.jpeg = true;
		if (montageParams.bin) UserVideoController.addSpritesheet(sp, [].concat(montageParams.bin));
		UserVideoController.addSpritesheetForId(sp, id);
		UserVideoController.addSpritesheetForId(sp, sp.name);
		return sp;
	}

	createSpritesheetFromReloadedUserVideoBin(bin, montageParams, id, batchName) {
		console.log("USER VIDEO", bin, montageParams, id, batchName, this.userVideos[bin]);
		if (!this.userVideos[bin]) {
			console.log("No loaded info for ",bin);
			return null;
		}

		var info = this.userVideos[bin];
		var duration = montageParams.duration==undefined ? 2.0 : montageParams.duration;
		if (montageParams.videoDuration!==undefined) duration = montageParams.videoDuration;
		if (duration <= 1/24) duration = 1/24;

		var params = {
			"videoDuration": duration,
			"palindrome": !!montageParams.palindrome,
			"reverse": !!montageParams.reverse,
			"videoSpeed": montageParams.videoSpeed||montageParams.speed||1.0,
			"effect": montageParams.effect,
			"analysis": montageParams.analysis,
			"faces": montageParams.faces,
			"startOffset": montageParams.startOffset,
			"jpeg": true
		};
		if (montageParams.videoSpeed!=undefined) params.videoSpeed = montageParams.videoSpeed;

		var sp = this.createSpritesheetFromInfo(this.userVideos[bin], params, batchName);
		sp.reloaded = true;
		sp.isUser = true;
		// sp.mpeg = false;
		// sp.jpeg = true;
		if (montageParams.bin) UserVideoController.addSpritesheet(sp, [].concat(montageParams.bin));
		UserVideoController.addSpritesheetForId(sp, id);
		UserVideoController.addSpritesheetForId(sp, sp.name);
		return sp;
	}

	createSpritesheetPhoto(url, montageParams, batchName) {
		if (!url.startsWith('photos/')) {url = 'photos/'+url;}
		url = url.replace('photos/', 'photos/q24/');
		batchName = batchName || 'batch_'+Utils.generateUUID();
		var sp =  new SpritesheetPhoto();
		var params = {
			name: url,
			numFrames: 1,
			duration: Math.max(montageParams.videoDuration, 1/24),
			palindrome: montageParams.palindrome,
			numX: 1,
			numY: 1,
			videoWidth: 512,
			videoHeight: 512, 
			resolutionWidth: 512,
			resolutionHeight: 512,
			videoSpeed: montageParams.videoSpeed,
			reverse: montageParams.reverse||montageParams.backward||montageParams.backwards||false
		};

		if (params.numFrames <= 1 && montageParams.photoDuration !== undefined) params.duration = Math.max(montageParams.photoDuration, 1/24);


		//load binary xhr
		var f = PageLoader.addXHR(batchName, SETTINGS.SPRITESHEET_URL+"spritesheets/"+url+"/frame_n1_0.jpg", 'arraybuffer');

		//setup sp
		sp.setup(params, f);
		return sp;
	}

	//
	// Load frames & return SpritesheetVideo
	//
	createSpritesheetFromInfo(info, montageParams, batchName) {
		batchName = batchName || 'batch_'+Utils.generateUUID();
		/*
		// video_name
		// num_frames
		// processed_path
		// processed_numx
		// processed_numy
		// processed_video_width
		// processed_video_height
		// processed_resolution_width
		// processed_resolfution_height
		// processed_num_spritesheets
		*/
		var sp  = SpritesheetVideo.create(montageParams);			
		var facesInfo = null;
		if (info.faces_info) {
			try {facesInfo = JSON.parse(info.faces_info)} catch(e) {
				console.warn("JSON ERROR",e,info.faces_info);
			}
		}
		info.processed_num_spritesheets = info.processed_num_spritesheets||info.source_num_spritesheets;
		// 
		var params = {
			video_id: info.video_id||0,
			name: info.video_name,
			numFrames: Math.max(Math.floor(Math.min(Math.min(montageParams.videoDuration*24, info.duration*24), info.num_frames)),1),
			duration: Math.max(Math.min(montageParams.videoDuration, info.duration), 1/24),
			palindrome: montageParams.palindrome,
			numX: info.processed_numx,
			numY: info.processed_numy,
			videoWidth: info.processed_video_width,
			videoHeight: info.processed_video_height, 
			resolutionWidth: info.processed_resolution_width,
			resolutionHeight: info.processed_resolution_height,
			videoSpeed: montageParams.videoSpeed != undefined ? montageParams.videoSpeed : 1.0,
			reverse: montageParams.reverse||montageParams.backward||montageParams.backwards||false,
			facesInfo: facesInfo,
			faces: montageParams.faces,
			noFaces: montageParams.noFaces||(!!info.no_faces),
			invertColors: montageParams.invertColors,
			beginAtZero: montageParams.beginAtZero,
			jpeg: montageParams.jpeg,
			mpeg: montageParams.mpeg,
			startOffset: Math.floor( (montageParams.startOffset||0)*24 ),

			//effects
			fill: montageParams.fill,
			padding: montageParams.padding,
			upsideDown: montageParams.upsideDown,
			noise: montageParams.noise,
			analysis: montageParams.analysis,
			analysis_class: montageParams.analysis_class,
			effect: montageParams.effect
		};

		var privateVideo = false;
		if ((!info.processed_mpeg || info.processed_mpeg=='0') && info.use_source) {
			privateVideo = true;
			// params.startOffset = 0;
		}

		if (params.numFrames <= 1 && montageParams.photoDuration !== undefined) params.duration = Math.max(montageParams.photoDuration, 1/24);
		// if (params.numFrames > 1 && params.palindrome) params.duration *= 2.0; 


		var useMpeg = (!!info.processed_mpeg && info.processed_mpeg!=='0' && params.numFrames >= 6);
		if (params.jpeg) useMpeg = false;
		if (params.mpeg !== undefined) useMpeg = params.mpeg&&(!!info.processed_mpeg && info.processed_mpeg!=='0' && params.numFrames >= 6);
		if (params.effect && (params.effect.type=='motionDelay'|| params.effect.type=='orientation')) useMpeg = false;
		if (params.reverse) useMpeg = false;
		if (!SETTINGS.MPEG_ENABLED) useMpeg = false;

		params.liveMpeg = !!(useMpeg && (params.videoSpeed||0) < 2.0 && !params.palindrome && !params.reverse && !SETTINGS.STORYBOARD_MODE); //  && (!params.effect || (params.effect && params.effect.type!=='motionDelay' && params.effect.type!=='orientation')));

		var useMpeg2 = false;
		if (useMpeg && SETTINGS.MPEG2_ENABLED && parseInt(info.processed_mpeg,10) > 0 && !montageParams.mpeg1) {
			useMpeg = false;
			useMpeg2 = true;
		} else if (SETTINGS.MPEG2_ONLY) useMpeg = false;
		
		// if (params.numFrames >= 12) console.log(info.video_name, useMpeg, useMpeg2, info.processed_mpeg);
		// useMpeg = useMpeg2 = false;
		
		//
		// Load all Frames
		// Select which spritesheets to load. Optimization for short durations < 12 frames
		//
		var allFrames = [];
		var numSpritesheets = 1;
		var spMin = 0;
		var spMax = 0;
		var spn = 1;
		params.numX = 1;
		params.numY = 1;
		if (params.numFrames == 1) {
			spn = 1;
			params.numX = 1;
			params.numY = 1;
		} else if (params.numFrames == 2) {
			spn = 2;
			params.numX = 1;
			params.numY = 2;
		} else if (params.numFrames <= 4) {
			spn = 4;
			params.numX = 1;
			params.numY = 4;
		} else if (params.numFrames <= 8) {
			spn = 8;
			params.numX = 2;
			params.numY = 4;
		} else if (params.numFrames <= 12) {
			spn = 12;
			params.numX = 3;
			params.numY = 4;
		} else if (params.numFrames <= 24) {
			spn = 24;
			params.numX = 6;
			params.numY = 4;
			spMax = spMin = Math.floor(info.processed_num_spritesheets/2);
			if (info.num_frames < 48) spMax = spMin = 0;
			numSpritesheets = 1;
		} else if (params.numFrames <= 48) {
			params.numX = 6;
			params.numY = 4;
			spn = 24;
			spMin = Math.floor((info.processed_num_spritesheets-0.5)/2);
			if (info.num_frames < 72) spMax = spMin = 0;
			spMax = spMin + 1;
			numSpritesheets = 2;
		} else if (params.numFrames <= 72) {
			params.numX = 6;
			params.numY = 4;
			spn = 24;
			spMin = Math.max(Math.floor((info.processed_num_spritesheets-0.5)/2)-1,0);
			if (info.num_frames < (spMin+2)*24) spMin = Math.max(spMin-1,0);
			spMax = spMin+2;
			if (spMax >= params.processed_num_spritesheets || info.num_frames<=72) {spMin = 0; spMax = 2} 
			numSpritesheets = 3;
		} else {
			params.numX = 6;
			params.numY = 4;
			spn = 24;
			numSpritesheets = Math.min(Math.ceil(params.numFrames / 24), info.processed_num_spritesheets);
			spMin = 0;
			spMax = numSpritesheets-1;
		}
		
		if (params.startFrames || params.beginAtZero ) {
			params.numX = 6;
			params.numY = 4;
			spn = 24;
			numSpritesheets = Math.min(Math.ceil(params.numFrames / 24), info.processed_num_spritesheets);
			spMin = 0;
			spMax = numSpritesheets-1;
		}

		if (params.numFrames > 12) {
			sp.frameOffset = spMin * 24;
		} else {
			sp.frameOffset =  Math.floor(Math.max(info.num_frames - spn,0) / 2);
		}


		
		// if (useMpeg && false) {
		// 	// if (numSpritesheets >= 3) {
		// 	// 	sp.frameOffset = 0;
		// 	// } else {
		// 	// 	// var mx = Math.floor(info.num_frames / 2)
		// 	// 	// var minx = Math.max(Math.floor(info.num_frames / 2) - Math.ceil(numSpritesheets*24/2), 0)
		// 	// 	// var maxx = Math.min(Math.floor(info.num_frames / 2) + Math.ceil(numSpritesheets*24/2), info.num_frames)
		// 	// 	// while (maxx-minx < numSpritesheets*24) {
		// 	// 	// 	maxx = Math.min(maxx+1, info.num_frames)
		// 	// 	// 	if (maxx-minx < numSpritesheets*24) {
		// 	// 	// 		minx = Math.max(minx-1, 0)
		// 	// 	// 	}
		// 	// 	// }
		// 	// 	sp.frameOffset = Math.floor(Math.max(info.num_frames -  Math.ceil(numSpritesheets*24),0) / 2);
		// 	// }
		// 	if (numSpritesheets >= 3) {
		// 		sp.frameOffset = 0;
		// 	} else {
		// 		if (params.numFrames > 12) {
		// 			sp.frameOffset = Math.floor(Math.max(info.num_frames -  Math.ceil(numSpritesheets*24),0) / 2);
		// 		} else {
		// 			sp.frameOffset = Math.floor(Math.max(info.num_frames - spn,0) / 2);
		// 		}
		// 	}

		// 	allFrames = PageLoader.addXHR(batchName, SETTINGS.SPRITESHEET_URL+info.processed_mpeg_path.replace('{quality}', SETTINGS.SPRITESHEET_QUALITY).replace('{limit}', spn).replace('%d', params.numFrames==12?12:Math.ceil(numSpritesheets*24)), 'arraybuffer');

		// } else 

		if (useMpeg2) {

			var video_max_id = Math.ceil(numSpritesheets*24);
			if (numSpritesheets >= 3) {
				sp.frameOffset = 0;
			} else {

				if (params.numFrames > 12) {
					sp.frameOffset = Math.floor(Math.max(info.num_frames -  Math.ceil(numSpritesheets*24),0) / 2);
				} else {
					spn = params.numFrames <= 6 ? 6 : 12;
					sp.frameOffset = Math.floor(Math.max(info.num_frames - spn,0) / 2);
					video_max_id = params.numFrames <= 6 ? 6 : 12;
				}
			}
			if (montageParams.debugError) info.processed_mpeg_path = 'error';
			allFrames = PageLoader.addXHR(batchName, SETTINGS.SPRITESHEET_URL+info.processed_mpeg_path.replace('\.ts','\.m2v').replace('{quality}', SETTINGS.SPRITESHEET_QUALITY).replace('{limit}', spn).replace('%d', video_max_id), 'arraybuffer');
			
		} else {
			if (!privateVideo) {
				for (var i=spMin; i<=spMax; i++) {
					var f = PageLoader.addXHR(batchName, SETTINGS.SPRITESHEET_URL+info.processed_path.replace('{quality}', SETTINGS.SPRITESHEET_QUALITY).replace('{limit}', spn).replace('%d', i), 'blob');
					allFrames.push(f);
				}
			} else {

				params.numX = info.source_numx;
				params.numY = info.source_numy;
				params.videoWidth = info.source_video_width;
				params.videoHeight = info.source_video_height;

				params.beginAtZero = true;
				numSpritesheets = Math.min(Math.ceil(params.numFrames / (info.source_numx*info.source_numy)), info.source_num_spritesheets||1);
				spMin = 0;
				spMax = numSpritesheets-1;
				sp.frameOffset = 0;

				// spMin = spMax = 2;
				if (params.startOffset) {
					params.beginAtZero = false;
					var inc = Math.floor( params.startOffset / (params.numX*params.numY));;
					spMin += inc;
					spMax += inc;
					sp.frameOffset += inc * (params.numX*params.numY);
				}
				params.startOffset = 0;

				// //private video with less frames : load middle, sort of
				// if (info.num_frames >= 48 && params.numFrames <= 12) {
				// 	spMin = 2;
				// 	spMax = 2;
				// 	params.frameOffset = 24;
				// } else if (info.num_frames >= 48 && params.numFrames <= 24) {
				// 	spMin = 1;
				// 	spMax = 2;
				// 	params.frameOffset = 12;
				// }
				// console.log("reload offset...",spMin,spMax, info.num_frames, params.numFrames, params.frameOffset,montageParams.videoDuration, info.duration);

				for (var i=spMin; i<=spMax; i++) {
					var f = PageLoader.addXHR(batchName, info.source_path.replace('{quality}', SETTINGS.SPRITESHEET_QUALITY).replace('{limit}', spn).replace('%d', i), 'private');
					f.name = info.video_name;
					f.info = info;
					allFrames.push(f);
				}
			}
		}



		//load binary xhr
		// console.log(info.processed_path);
		// console.log("creating spritesheet",sp, info);
		//load analysis as json
		//if (montageParams.analysis) {
		//	var videolistXHR = Loader.addXHR(batchName, SETTINGS.API_SERVER_URL+'/db_'+montageParams.analysis, 'json', false);
		// 	videolistXHR.protocol = 'post';
		//}

		//setup sp
		sp.setup(params, allFrames, useMpeg2?'mpeg2':(useMpeg?'mpeg':'blob'));
		sp.preload(batchName);
		return sp;
	}

	getArchive(info) {
			
	}
};

window.MontageController = window.MontageController || new MontageController();
export default window.MontageController;

