import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'

import RendererController from '../controllers/RendererController.js';
import TextureUpdateController from '../controllers/TextureUpdateController.js'
import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';
import AnalysisController from '../controllers/AnalysisController.js'

import Loader from '../loading/Loader.js'
import PageLoader from '../loading/PageLoader.js'
import Fbo from '../utils/Fbo.js'

import FaceRenderer from './FaceRenderer.js'

//
// Handles spritesheet playback, material and rendering
//
class SpritesheetVideo {

	constructor() {
		this.currentTime = 0;
		this.lastTime = 0;
		this.currentFrame = 0;
		this.playing = false;
		this.videoSpeed = 1.0;
		this.lastFrame = -1;
		this.ratio = 16/9;

		this.numFrames = 0;
		this.numFramesPerSpritesheet = 0;
		this.numSpritesheet = 0;
		this.numFramesX = 1;
		this.numFramesY = 1;
		this.frameRate = 24;
		this.palindrome = false;

		this.frames = [];
		this.framesType = 'binary'; //texture, blob, binary

		this.binaryData = [];
		this.decodedImages = [];
		this.textures = [];
		this.blobURLs = [];
		this.childs = [];

		this.startOffset = 0;
		this.video_id = 0;

		this.frameOffset = 0;
		this.loading = false;
		this.loaded = false;

		this.numDecoded = 0;
		this.decoded = false;
		this.decoding = false;
		this.isUser = false;
		this.isShowing = false;
		this.uuid = Utils.generateUUID();
		this.isPhoto = false;
		this.needsUpdate = false;
		this.disposed = false;

		//
		// Setup faces
		//
		this.textCanvas = [];
		this.textContext = [];
		this.textTexture = [];

		this.analysisLoading = false;
		this.analysisLoaded = false;

	}

	//setup frames & variables for playback
	setup(_params, _frames, _framesType) {
		//setup the webgl scene/shader
		// this.material = MaterialController.getMaterial('spritesheet');
		// this.materialTexture = MaterialController.getMaterial('spritesheet_texture');
		this.scene = new THREE.Scene();
		this.plane = new THREE.Mesh(Utils.planeGeometry, Utils.emptyMaterial);
		this.scene.add(this.plane);

		this.setParams(_params, _frames, _framesType);
	}


	preload(batchName) {
		// console.log(this.params.analysis, this.facesInfo);
		// AnalysisController.loadFaces(this.name, batchName);
		if (this.params.analysis == 'hands') this.params.analysis = null;
		if (this.params.analysis && !this.analysisLoading && !this.isUser && !this.analysisLoaded) {
			if (this.params.analysis!=='faces' || (this.params.analysis=='faces' && !this.facesInfo)) {
				this.analysisLoading = true;

				console.log("GET ANALYSIS:", this.params.analysis, this.facesInfo);
				AnalysisController.getAnalysis(this.name, this.video_id, this.isUser, this.params.analysis, this.params, batchName).then(
					this.onAnalysisLoaded.bind(this)
				);
			}
		}
		if (this.faceRenderer) this.faceRenderer.preload(batchName);
	}


	//reset params of existing spritesheet
	setParams(_params, _frames, _framesType) {
		this.params = _params;
		this.frames = _frames;
		this.framesType = _framesType;
		if (this.framesType === 'texture') this.textures = _frames;
		this.loaded = false;

		if (this.params.analysis==="faces") this.params.analysis = undefined;

		//set params
		this.video_id = this.params.video_id;
		this.name = this.params.name || "";
		this.numFrames = this.params.numFrames;
		this.numFramesX = this.params.numX;
		this.numFramesY = this.params.numY;

		this.numFramesPerSpritesheet = this.numFramesX * this.numFramesY;
		this.numSpritesheet = Math.ceil(this.params.numFrames / this.numFramesPerSpritesheet);

		this.videoSpeed = this.params.videoSpeed;
		this.frameRate = 24; //(this.params.numFrames/this.params.duration);
		this.palindrome = this.params.palindrome;

		this.videoWidth = this.params.videoWidth || 288; // ? Math.floor(this.params.resolutionWidth/this.numFramesX) : 512;
		this.videoHeight = this.params.videoHeight || 512;// ? Math.floor(this.params.resolutionHeight/this.numFramesY) : 910;
		if (this.fbo) this.fbo.resize(this.videoWidth, this.videoHeight);
		// if (this.faceFbo) this.faceFbo.resize(36, 64);

		if (this.numFrames == 1) {
			this.videoWidth = 432;
			this.videoHeight = 768;
		}
		
		if (this.plane) {
			this.plane.material = this.material = MaterialController.getMaterial('spritesheet', {
				TEXTURES_INVERT_MODE: ((this.framesType=='texture'||this.framesType=='mpeg'||this.framesType=='mpeg2')&&!this.dontInvertTextures) ? 1 : 0,
				INVERT_COLORS: this.params.invertColors ? 1 : 0
			});
			////this.framesType=='texture' ? this.materialTexture : this.material;
		}


		if (!this.isUser) this.startOffset = this.params.startOffset = 0;

		//faces preloading
		this.noFaces = !!(this.params.noFaces || (this.params.faces && this.params.faces.disabled));
		this.facesInfo = this.params.facesInfo;
		if (!this.noFaces) {
			if (!this.faceRenderer) this.faceRenderer = new FaceRenderer();
		} else if (this.faceRenderer) {
			this.faceRenderer = null;
		}
		if (this.faceRenderer) this.faceRenderer.setup(this.params);
		// this.params.faces = this.params.faces||{};


		// if (this.isUser && this.name) {
		// 	var globalState = MultiStorage.getGlobalState();
		// 	if (globalState.facesInfoByName && globalState.facesInfoByName[this.name]) {
		// 		console.log("FACES INFOOOO",this.facesInfo);
		// 	}
		// }
	}

	//FrameRecorder exporting spritesheets as high-res jpeg
	waitForExport() {
		this.waitingForExport = true;
	}

	exportDone(frames) {
		this.frames = frames;
		this.waitingForExport = false;
		if (this.waitingToPrepare) {
			this.waitingToPrepare = false;
			this.prepare();
		}
		for (var i=0; i<this.childs.length; i++) {
			this.childs[i].exportDone(frames);
		}
		this.childs = [];
	}
	
	//decode images if necessary
	prepare() {
		// if (!this.loadingFaceInfo && !this.facesInfo && !this.noFaces) {
		// 	this.firstFace = true;
		// 	this.loadingFaceInfo = true;
		// 	this.loadedInfo = false;
		// 	AnalysisController.getAnalysis(this.name, this.isUser).then(function(info) {
		// 		this.loadingFaceInfo = false;
		// 		this.facesInfo = info;
		// 		this.loadedInfo = true;
		// 		if (this.isUser) this.facesInfo.loaded = true;
		// 	}.bind(this));
		// }
		if (this.mpegDecoder && !this.decoded) {
			this.mpegDecoder.prepare();
			if (this.mpegDecoder.ready) {
				this.decoding = false;
				this.decoded = true;
				this.mpegDecoder.disposeDecoder();
			}
		}


		if (this.faceRenderer) this.faceRenderer.prepare();

		if (this.params.analysis && !this.analysisLoading && this.isUser && !this.analysisLoaded) {
			this.analysisLoading = true;
			AnalysisController.getAnalysis(this.name, this.isUser, this.params.analysis, this.params.params).then(this.onAnalysisLoaded.bind(this));
		}

		if (!this.fbo) this.fbo = SpritesheetVideo.getFbo(this.videoWidth, this.videoHeight);

		if (this.waitingForExport) {this.waitingToPrepare = true;} //console.log("WAITING FOR EXPORT"); 
		if (this.decoding || this.decoded || this.framesType=="texture" || this.waitingForExport) return;


		if (this.framesType == 'mpeg' || this.framesType == 'mpeg2') {
			if (this.decoding||this.decoded) return false;
			this.loaded = this.frames.loaded;
			if (this.loaded) {
				this.decoded = false;
				this.decoding = true;
				this.loaded = true;

				if (this.framesType == 'mpeg') {
					this.mpegDecoder = MpegDecodeController.createDecoder(this.frames.value, this.videoWidth, this.videoHeight, this.params.liveMpeg);
					this.mpegDecoder.prepare();
				} else {
					this.mpegDecoder = MpegDecodeController.createDecoder2(this.frames.value, this.videoWidth, this.videoHeight, this.params.liveMpeg, Math.floor(this.numFrames));
					this.mpegDecoder.prepare();
				}
				
				this.textures = this.mpegDecoder.textures;
			}
			return false;
		}

		this.loaded = true;
		if (this.framesType == 'binary' || this.framesType == 'blob') {
			for (var i=0; i<this.frames.length; i++) {
				if (!this.frames[i].loaded || this.frames[i].loadError) {
					this.loaded = false;
					return false;
				}
			}
		}
		this.decoding = true;
		this.numDecoded = 0;
		this.playing = false;
		this.currentTime = 0;
		this.lastFrame = -1;


		if (this.framesType === 'blob' && this.frames.length && this.frames[0].value && this.frames[0].value.constructor == ArrayBuffer) {
			this.framesType = 'binary';
			console.warn("Spritesheet is created as type 'blob' with binary frames.",this);
		}

		for (var i=0; i<this.frames.length; i++) {
			var blob = this.framesType == 'binary' ? new Blob([new Uint8Array(this.frames[i].value)],{'type':'image/jpeg'}) : this.frames[i].value;
			
			var url = window.URL.createObjectURL(blob);
			this.blobURLs.push(url);

			var img = new Image();
			// var t = new THREE.Texture(img);
			// t.format = THREE.RGBFormat;
			// t.type = THREE.UnsignedByteType;
			// t.wrapT = t.wrapS = THREE.ClampToEdgeWrapping;
			// t.magFilter = THREE.LinearFilter;
			// t.minFilter = THREE.LinearFilter;
			// t.generateMipmaps = false;
			// t.anisotropy = 1;
			// t.needsUpdate = false;
			// t.wasUpdated = false;
			// t.loaded = false;
			var t = SpritesheetVideo.getTexture(img, this.numFramesX, this.numFramesY);
			t.image = img;
			this.textures.push(t);
			img.texture = t;
			img.onload = function(e) {
				// img.onload = null;
				this.numDecoded++;
				if (this.numDecoded >= this.frames.length) {
					// this.videoWidth = e.target.width;
					// this.videoHeight  = e.target.height;
					this.decoded = true;
					this.decoding = false;
				}
				e.target.texture.loaded = true;
				e.target.texture.needsUpdate = true;
			}.bind(this);
			// img.onerror = function(e) {
			// 	this.loaded = false;
			// 	if (this.frames[e.target.fi]) this.frames[e.target.fi].loadError = true;
			// }.bind(this);
			// img.fi = i;
			img.src = url;
		}
	};

	onAnalysisLoaded(info) {
		console.log("GOT RETURN", info, this);
		this.analysisLoaded = true;
		this.analysis = info;
		if (this.params.analysis == 'faces') {
			this.facesInfo = info;
			if (this.faceRenderer) this.faceRenderer.setInfo(info);
		}
	};
	setLiveInfo(info) {
		this.facesInfo = info;
		if (this.faceRenderer && info) this.faceRenderer.setInfo(info);
	}

	//check for errors
	hasErrors() {
		for (var i=0; i<this.frames.length; i++) {
			if (this.frames[i] && this.frames[i].loadError) return true;
		}
		if (this.frames.loadError) return true;
		return false;
	}
	manualStart() {
		if (this.isReady()) return;
		if (this.frames) {
			if (this.frames.manualStart && !this.frames.loaded && !this.frames.loading && !this.frames.loadError) {
				this.frames.manualStart();
			}
			if (this.frames.length) {
				for (var j=0; j<this.frames.length; j++) {
					if (this.frames[j].manualStart && !this.frames[j].loaded && !this.frames[j].loading && !this.frames[j].loadError) {
						this.frames[j].manualStart();
					}
				}
			}
		}
	}

	//waiting for loading & decoding
	isReady() {
		return (this.framesType==='texture' || this.decoded) && (!this.analysisLoading || this.analysisLoaded) && !this.waitingForExport; // && (!this.loadingFaceInfo && !!this.facesInfo && this.facesInfo.loaded);
	};

	//dispose when done
	dispose() {
		if (this.framesType === 'blob' || this.framesType=='binary') {
			for (var i=0; i<this.frames.length; i++) {
				if (this.blobURLs[i]) window.URL.revokeObjectURL(this.blobURLs[i]);
				if (this.textures[i]) {
					if (this.textures[i].image) this.textures[i].image.onload = null;
					// this.textures[i].dispose();
					this.textures[i].image = null;
					SpritesheetVideo.disposeTexture(this.textures[i], this.numFramesX, this.numFramesY);
				}
			}
		}

		if (this.fbo) {
			SpritesheetVideo.disposeFbo(this.fbo);
			this.fbo = null;
		}

		if (this.faceRenderer) {
			this.faceRenderer.dispose();
		}

		if (this.mpegDecoder) {
			this.textures = [];
			this.mpegDecoder.dispose();
			this.mpegDecoder = null;	
		}

		// this.frames = [];
		this.blobURLs = [];
		this.textures = [];
		this.decoding = false;
		this.decoded = false;
		this.waitingToPrepare = false;
		this.playing = false;
		this.currentTime = 0;
		this.lastFrame = -1;
		this.contentDiv = null;
		this.textDiv = null;
		this.disposed = true;

	};

	//playback
	play(contentDiv, textDiv) {
		this.lastTime = performance.now();
		this.currentTime = 0.0;
		this.playing = true;
		this.lastFrame = -1;
		this.contentDiv = contentDiv;
		this.textDiv = textDiv;
		if (this.faceRenderer) this.faceRenderer.activate(contentDiv);

		// window.playingVideos = window.playingVideos||[];
		// window.playingVideos.push(this);
	};
	pause() {
		this.lastTime = performance.now();
		this.playing = false;
	};
	stop() {
		this.lastTime = performance.now();
		this.playing = false;
		this.currentTime = 0;
		this.lastFrame = -1;

		if (this.faceRenderer) this.faceRenderer.deactivate();
		this.textCanvas = [];
		this.textContext = [];
		this.textTexture = [];
		// window.playingVideos.splice(window.playingVideos.indexOf(this),1);
	};
	prepareTextures() {
		if (SETTINGS.EDIT_MODE) return;
		for (var i=0; i<this.textures.length; i++) {
			if (!this.textures[i].wasUpdated && this.textures[i].loaded) {
				TextureUpdateController.update(this.textures[i]);
			}
		}
	}

	//
	// Update playback every frame
	//
	update(opt) {

		if (!this.isReady()) this.lastTime = performance.now();

		if (this.isReady()) {
			// console.log(this.framesType, this.frames, this.textures, this.decoded, this.temporary);
		}


		window.sp = this;


		//---------------
		//  
		//  Update frame
		//  
		//---------------
		var numFramesTotal = Math.floor(this.numFrames); //this.frameLimit
		if (this.playing) this.currentTime += (performance.now()-this.lastTime) / 1000 * this.videoSpeed;
		this.lastTime = performance.now();
		if (opt && opt.currentTime !== undefined) {
			if (this.params.palindrome) this.currentTime = Utils.clamp(opt.currentTime * this.frameRate, 0, numFramesTotal*2.0-0.0001) / this.frameRate;
			else this.currentTime = Utils.clamp(opt.currentTime * this.frameRate, 0, numFramesTotal-0.0001) / this.frameRate;
		}

		var currentFrame = 0;
		if (this.palindrome) {
			var absoluteFrame = this.currentTime * this.frameRate;
			currentFrame = absoluteFrame % numFramesTotal;
			if (Math.floor(absoluteFrame/numFramesTotal) % 2 == 1) currentFrame = (numFramesTotal-currentFrame);
		} else {
			currentFrame = (this.currentTime * this.frameRate) % numFramesTotal;
		}
		if (this.params.reverse) {
			currentFrame = Utils.clamp(numFramesTotal - 1 - currentFrame, 0, numFramesTotal-1);
		}
		

		if (opt && opt.forceTime !== undefined) {
			this.currentTime = Utils.clamp(opt.forceTime * this.frameRate, 0, numFramesTotal-0.0001) / this.frameRate;
			currentFrame = (this.currentTime * this.frameRate) % numFramesTotal;
		}


		var cf = Math.floor(currentFrame) + (this.startOffset) + (this.params.startOffset||0);
		if (this.lastFrame !== cf) {
			this.needsUpdate = true;
			
			var divw = this.numFramesX;
			var divh = this.numFramesY;
			var frameInSheet = cf % this.numFramesPerSpritesheet;

			var frameX = frameInSheet%divw;
			var frameY = Math.floor( frameInSheet / divw ); //divh-1-

			this.plane.material.uniforms.tDiffuse.value = this.textures[ Math.floor( cf / this.numFramesPerSpritesheet ) ];	
			if (this.framesType == 'mpeg' || this.framesType == 'mpeg2') {
				if (!this.mpegDecoder)
					this.plane.material.uniforms.tDiffuse.value = Utils.whiteTexture;
				else
					this.plane.material.uniforms.tDiffuse.value = this.params.liveMpeg ? this.mpegDecoder.getFrameLive(cf) : this.mpegDecoder.getFrame(cf);
			}

			// console.log(Math.floor( cf / this.numFramesPerSpritesheet ), frameInSheet, numFramesTotal);

			this.plane.material.uniforms.spritesheetOffset.value.x = (1.0/divw * frameX);
			this.plane.material.uniforms.spritesheetOffset.value.y = 1.0-(1.0/divh * frameY) - 1.0/divh;

			this.plane.material.uniforms.spritesheetScale.value.x = 1.0 / divw;
			this.plane.material.uniforms.spritesheetScale.value.y = 1.0 / divh;

			if (this.framesType == 'mpeg' || this.framesType == 'mpeg2') {
				this.plane.material.uniforms.spritesheetOffset.value.set(0,0);
				this.plane.material.uniforms.spritesheetScale.value.set(1,1);
			}

			this.plane.material.uniforms.noiseScale.value.set(1,16/9).multiplyScalar(5 * AppStatus.innerWidth()/1024).multiplyScalar(0.98 + Math.random()*0.02)
			this.plane.material.uniforms.noiseOffset.value.set(Math.random()*10, Math.random()*10);
			this.plane.material.uniforms.noisepc.value = this.params.noise==undefined?1.0:(this.params.noise||0);
			this.plane.material.uniforms.tNoise.value = SpritesheetVideo.noiseTexture;

			var randomRot = Math.random()*Math.PI*2.0;
			this.plane.material.uniforms.noiseRotation.value.elements.set([Math.cos(randomRot),-Math.sin(randomRot),Math.sin(randomRot),Math.cos(randomRot)]);


			this.plane.scale.y = this.params.upsideDown ? -1 : 1;

			this.isShowing = true;
		}

		this.updateFaces(cf);
		// if (this.lastFrame !== cf || this.firstFace) 

		this.lastFrame = cf;
		
	};


	updateFaces(currentFrame) {
		//update frame
		if (!this.facesInfo || this.noFaces) return;

		if (this.faceRenderer) {
			this.faceRenderer.setCurrentFrame(currentFrame + this.frameOffset);
			this.faceRenderer.update();
		}


	}


	getTexture() {
		if (!this.fbo || !this.isReady()) return Utils.whiteTexture;
		return this.fbo.texture.texture;
	}

	getDuration() {
		var d = this.params.duration;
		if (this.params.palindrome) d = (this.params.duration * 2);
		return d;
	}

	// this.userBin = null;
	// this.getSelf = function() {
	// 	if (!this.userBin) return this;
	// 	return UserVideoController.getUserVideo(bin) || this
	// };

	//
	// Render the texture into a RenderTarget or into its own fbo
	//
	render() {
		if (!this.isReady() || !this.fbo) return;
		if (this.isShowing && this.material.uniforms.tDiffuse.value && !this.material.uniforms.tDiffuse.value.wasUpdated && this.material.uniforms.tDiffuse.value.loaded) {
			TextureUpdateController.update(this.material.uniforms.tDiffuse.value, true);
		}
		renderer.render(this.scene, Utils.orthographicCamera, this.fbo.texture, false);

		if (!this.noFaces && this.faceRenderer) {
			if (this.params.faces && this.faceRenderer.faceType === 'average' && this.faceRenderer.faceFbo) renderer.render(this.scene, Utils.orthographicCamera, this.faceRenderer.faceFbo.texture, false);
			this.faceRenderer.render(this.fbo.texture);
		}
		this.needsUpdate = false;
	}

	clone() {
		var sp = SpritesheetVideo.create(this.params);
		sp.name = this.name;
		sp.isUser = this.isUser;
		sp.reloaded = this.reloaded;
		sp.temporary = this.temporary;
		sp.setParams(Utils.clone(this.params), this.frames, this.framesType);
		return sp;
	}
};


//==================
//
// Static functions
//
//==================
SpritesheetVideo.preloadMain = function(batchName) {

	SpritesheetVideo.noiseTexture = Loader.addTexture(batchName, SETTINGS.UI_IMAGES_URL+'noise_0.jpg', {
		format: THREE.LuminanceFormat,
		wrapping: THREE.RepeatWrapping,
		generateMipmaps: true,
		minFilter: THREE.LinearMipMapLinearFilter
	});
}



SpritesheetVideo.init = function(batchName) {

	


	// MaterialController.addMaterial('spritesheet_texture', new THREE.RawShaderMaterial({
	// 	vertexShader:`
	// 		precision mediump float;
	// 		precision mediump int;

	// 		uniform mediump mat4 modelViewMatrix; // optional
	// 		uniform mediump mat4 projectionMatrix; // optional

	// 		attribute mediump vec3 position;
	// 		attribute mediump vec2 uv;

	// 		// varying mediump vec2 vUv;
	// 		varying mediump vec2 vUv_spritesheet;

	// 		uniform mediump vec2 spritesheetScale;
	// 		uniform mediump vec2 spritesheetOffset;

	// 		varying mediump vec2 noiseUv;
	// 		uniform mediump vec2 noiseScale;
	// 		uniform mediump vec2 noiseOffset;
	// 		uniform mediump mat2 noiseRotation;

	// 		void main() {
	// 			// vUv = vec2( uv.x, uv.y);
	// 			vUv_spritesheet =  vec2(uv.x, uv.y) * spritesheetScale + spritesheetOffset;
	// 			vUv_spritesheet.y = 1.0-vUv_spritesheet.y;
	// 			noiseUv = uv * noiseRotation * noiseScale + noiseOffset;
	// 			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
	// 		}
	// 	`,
	// 	fragmentShader:`
	// 		precision mediump float;
	// 		precision mediump int;

	// 		// varying mediump vec2 vUv;
	// 		varying mediump vec2 vUv_spritesheet;

	// 		uniform mediump sampler2D tDiffuse;

	// 		// uniform mediump vec2 spritesheetScale;
	// 		// uniform mediump vec2 spritesheetOffset;

	// 		varying mediump vec2 noiseUv;
	// 		uniform mediump sampler2D tNoise;
	// 		uniform lowp float noisepc;

	// 		void main() {
	// 			// mediump vec3 cam = texture2D(tDiffuse, vUv_spritesheet).rgb;

	// 			// float v = 0.0;
	// 			// if (vUv.x >= spritesheetOffset.x && vUv.x <= spritesheetOffset.x+spritesheetScale.x && vUv.y >= spritesheetOffset.y && vUv.y <= spritesheetOffset.y+spritesheetScale.y) {v = 1.0;}
	// 			gl_FragColor = vec4(texture2D(tDiffuse, vUv_spritesheet).rgb + (texture2D(tNoise, noiseUv).r*2.0-1.0) * 0.11 * noisepc, 1.0);
	// 			// gl_FragColor.r = 1.0;
	// 		}
	// 	`,
	// 	uniforms: {
	// 		tDiffuse: {type:'t', value: Utils.whiteTexture},
	// 		playbackRatio: {type:'v2', value: new THREE.Vector2(1,1)},
	// 		playbackCenter: {type:'v2', value: new THREE.Vector2(1,1)},
	// 		spritesheetOffset: {type:'v2', value: new THREE.Vector2(0,0)},
	// 		spritesheetScale: {type:'v2', value: new THREE.Vector2(1/5, 1/5)},

	// 		tNoise: {type:'t', value: Utils.whiteTexture},
	// 		noisepc: {type:'f', value:1.0},
	// 		noiseOffset: {type:'v2', value: new THREE.Vector2(0,0)},
	// 		noiseScale: {type:'v2', value: new THREE.Vector2(1, 1)},
	// 		noiseRotation: {type:'m2', value: {elements: new Float32Array(4)}}
	// 	},
	// 	transparent: false,
	// 	depthTest: false,
	// 	depthWrite: false,
	// 	blending: THREE.NoBlending,
	// 	side: THREE.DoubleSide
	// }));
}


SpritesheetVideo.fboPool = [];
SpritesheetVideo.getFbo = function(videoWidth, videoHeight) {
	if (SpritesheetVideo.fboPool.length > 0) {
		var pooled = null;

		for (var i=0; i<SpritesheetVideo.fboPool.length; i++) {
			if (SpritesheetVideo.fboPool[i].width == videoWidth && SpritesheetVideo.fboPool.height == videoHeight) {
				pooled = SpritesheetVideo.fboPool.splice(i,1)[0];
				i = SpritesheetVideo.fboPool.length+1;
			}
		}
		if (!pooled) pooled = SpritesheetVideo.fboPool.pop();
		pooled.resize(videoWidth, videoHeight);
		return pooled;
	}
	var fbo = new Fbo( videoWidth, videoHeight, {
		minFilter:THREE.LinearFilter,
		magFilter:THREE.LinearFilter,
		format:THREE.RGBAFormat,
		type:THREE.UnsignedByteType,
		depthBuffer: false,
		stencilBuffer: false,
		premultiplyAlpha: false,
		generateMipmaps: false,
		forceClear: false,
		pingpong: false,
		renderer:renderer
	});
	return fbo;
};
SpritesheetVideo.disposeFbo = function(fbo) {
	if (SpritesheetVideo.fboPool.length < 24) {
		SpritesheetVideo.fboPool.push(fbo);
	} else {
		fbo.dispose();
	}
};


SpritesheetVideo.cleanupFboPool = function() {
	for (var i=0; i<SpritesheetVideo.fboPool.length; i++) {
		SpritesheetVideo.fboPool[i].dispose();
		SpritesheetVideo.fboPool[i] = null;
	}
	SpritesheetVideo.fboPool = [];
};


SpritesheetVideo.texturePool = {};
SpritesheetVideo.disposeTexture = function(texture, numFramesX, numFramesY) {
	texture.dispose();
	// var poolId = numFramesX.toString()+'_'+numFramesY.toString();
	// SpritesheetVideo.texturePool[poolId] = SpritesheetVideo.texturePool[poolId] || [];
	// texture.image = null;
	// if (SpritesheetVideo.texturePool[poolId].length < 20) {
	// 	SpritesheetVideo.texturePool[poolId].push(texture);
	// } else {
	// 	texture.dispose();
	// }
}
SpritesheetVideo.getTexture = function(img, numFramesX, numFramesY) {

	var t = null;
	var poolId = numFramesX.toString()+'_'+numFramesY.toString();
	SpritesheetVideo.texturePool[poolId] = SpritesheetVideo.texturePool[poolId] || [];
	if (SpritesheetVideo.texturePool[poolId].length > 0) {
		// console.log("Got texture from pool", poolId);
		t = SpritesheetVideo.texturePool[poolId].pop();
		t.image = img;
	} else {
		// console.log("none in pool", poolId);
		t = new THREE.Texture(img);
		t.format = THREE.RGBFormat;
		t.type = THREE.UnsignedByteType;
		t.wrapT = t.wrapS = THREE.ClampToEdgeWrapping;
		t.magFilter = THREE.LinearFilter;
		t.minFilter = THREE.LinearFilter;
		t.generateMipmaps = false;
		t.anisotropy = 1;
	}
	t.needsUpdate = false;
	t.wasUpdated = false;
	t.loaded = false;
	return t;
};

SpritesheetVideo.mergeParams = function(montageParams, vidParams) {
	var params = Utils.clone(montageParams);
	for (var o in vidParams) {
		params[o] = vidParams[o];
	}
	return params;
};


window.SpritesheetVideo = window.SpritesheetVideo||SpritesheetVideo;
export default window.SpritesheetVideo;

