import SETTINGS from '../controllers/Settings.js';
import * as THREE from 'three';

import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import RendererController from '../controllers/RendererController.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';

import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import SpritesheetVideo from './SpritesheetVideo.js';

// var CanvasTextWrapper = require('canvas-text-wrapper').CanvasTextWrapper;

//------------------------
class SpritesheetVideoPointAll extends SpritesheetVideo {
	constructor(_info, batchName) {
		super(_info, batchName);
		this.boxesScene = new THREE.Scene();
		this.boxes = [];
		this.divs = [];

		//
		// Debug the class in the boxes
		//
		this.boxDebugPlanes = [];
		this.boxDebugCanvas = [];
		this.boxDebugContext = [];
		this.boxDebugTexture = [];

		this.selectedBox = false;
		this.selectedBoxId = 0;
	}

	preload(batchName) {
		super.preload(batchName);
		this.arrowTexture = Loader.addTexture(batchName, SETTINGS.IMAGES_URL+"images/arrow.png", {
			format: THREE.RGBAFormat,
			wrapping: THREE.ClampToEdgeWrapping,
			minFilter: THREE.LinearFilter,
			magFilter: THREE.LinearFilter,
			generateMipmaps: false,
			upload: true,
			noCompression: true
		});
	}

	prepare() {
		super.prepare();
	}

	onAnalysisLoaded(info) {
		super.onAnalysisLoaded(info);
	}

	stop() {
		for (var i=0; i<this.divs.length; i++) {
			if (this.divs[i]) {
				$(this.divs[i].container).remove();
				$(this.divs[i].div).remove();
			}
		}
		this.boxesScene = new THREE.Scene();
		this.boxes = [];
		this.divs = [];
		super.stop();
	}

	update(opt) {
		super.update(opt);

		if (this.isReady() && this.analysis && this.contentDiv) {

			var currentFrame = this.lastFrame;

			var rects = this.analysis.rectangles[currentFrame+this.frameOffset];
			// console.log(this.analysis.rectangles[currentFrame+this.frameOffset], this.analysis.classes[currentFrame+this.frameOffset], this.analysis.ids[currentFrame+this.frameOffset], this.analysis.confidences[currentFrame+this.frameOffset])

			if (rects && rects.length) {
				for (var i=0; i<rects.length; i++) {
					var box = rects[i];

					if (!this.boxes[i]) {
						var mat = new THREE.MeshBasicMaterial( { color: 0xffffff, depthTest: false, depthWrite: false, map: this.arrowTexture, side: THREE.DoubleSide, transparent: true, opacity: 1.0, blending: THREE.NormalBlending } );
						var p = new THREE.Mesh( Utils.planeGeometry, mat );
						this.boxesScene.add(p);
						this.boxes[i] = p;

						if (this.params.effect.debug && this.selectedBoxId==i) {
							var dc = document.createElement('div');
							$(dc).addClass('spritesheet-overlay-text-container');
							$(dc).css('background', 'none');
							$(this.contentDiv).append(dc);

							var d = document.createElement('div');
							$(d).addClass('spritesheet-overlay-text');
							$(d).css('color', 'white');
							$(dc).append(d);

							this.divs[i] = {
								container: dc,
								div:d,
								classid: null,
								visible: false,
								fontSize: 0,
								text: null
							};
						}
					}
					var p = this.boxes[i];
					p.scale.set(0.1*16/9,0.1,0.1).multiplyScalar(0.6);
					p.position.lerp(new THREE.Vector3(box[0]+box[2]/2,box[1]-box[3]/2, 0.1), 0.9);

					var scale = (this.params.effect.scale||1.0);

					p.scale.x *= scale;
					p.scale.y *= scale;

					p.scale.x = Math.max(p.scale.x,0.001);
					p.scale.y = Math.max(p.scale.y,0.001);

					p.visible = true;
					if (this.params.effect.debug && this.divs[i]) {

						var classid = this.analysis.classes[currentFrame+this.frameOffset][i]||'class';
						if (this.divs[i].classid!==classid) {
							this.divs[i].classid = classid;
							$(this.divs[i].div).text(classid);
						}
						var renderWidth = renderer.getSize().width,
							renderHeight = renderer.getSize().height;
						if (SETTINGS.STORYBOARD_MODE) {
							renderWidth = 162;
							renderHeight = 288;
						}

						var w = (p.scale.x*renderWidth);
						var h = (Math.abs(p.scale.y)*renderHeight);

						var fontSize =  Utils.clamp((w / 15), '0.5', '1.1');
						$(this.divs[i].div).css('font-size', fontSize +'em');
						w = Math.max(w, $(this.divs[i].container).width());
						h = Math.max(h, $(this.divs[i].div).height());
						$(this.divs[i].container).css({
							'transform': 'translate('+((p.position.x)*renderWidth - w/2)+'px,'+((p.position.y)*renderHeight - h/2)+'px)',
							'width': w+'px',
							'height': h+'px'
						});

						if (!this.divs[i].visible) {
							$(this.divs[i].container).css('display', 'table');
							this.divs[i].visible = true;
						}
					}
				}

				for (var i=rects.length; i<this.boxes.length; i++) {
					this.boxes[i].visible = false;
					if (this.params.effect.debug) {
						if (this.boxDebugPlanes[i]) this.boxDebugPlanes[i].visible = false;
						if (this.divs[i] && this.divs[i].visible) {
							$(this.divs[i].container).hide();
							this.divs[i].visible = false;
						}
					}
				}	

			} else {
				for (var i=0; i<this.boxes.length; i++) {
					this.boxes[i].visible = false;
					if (this.params.effect.debug) {
						if (this.boxDebugPlanes[i]) this.boxDebugPlanes[i].visible = false;
						if (this.divs[i] && this.divs[i].visible) {
							$(this.divs[i].container).hide();
							this.divs[i].visible = false;
						}
					}
				}
			}
		}
	}

	dispose() {
		super.dispose();

	}

	render() {
		super.render();
		renderer.render(this.boxesScene, Utils.topLeftCamera, this.fbo.texture, false);

	}
};

export default SpritesheetVideoPointAll;


