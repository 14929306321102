import SETTINGS from './Settings.js';
import AppStatus from './AppStatus.js';
import * as THREE from 'three';

import RendererController from './RendererController.js'
import SpritesheetVideo from '../objects/SpritesheetVideo.js';
import Utils from "../utils/Utils.js";


function MaterialController() {
	
	this.materials = {};
	this.materialDefines = {};
	this.useCount = {};


	this.init = function() {

		

		this.addMaterial('playback', new THREE.RawShaderMaterial({
			vertexShader:`
				precision mediump float;
				precision mediump int;

				uniform mediump mat4 modelViewMatrix; // optional
				uniform mediump mat4 projectionMatrix; // optional

				attribute mediump vec3 position;
				attribute mediump vec2 uv;

				varying mediump vec2 vUv;

				uniform mediump vec2 ratio;

				void main() {
					vUv = (vec2(uv.x, uv.y)-0.5) * ratio + 0.5;
					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}
			`,
			fragmentShader:`
				precision mediump float;
				precision mediump int;

				varying mediump vec2 vUv;
				uniform mediump sampler2D tDiffuse;

				void main() {
					gl_FragColor = vec4(texture2D(tDiffuse, vUv).rgb, 1.0);
				}
			`,
			uniforms: {
				tDiffuse: {type:'t', value: Utils.blackTexture},
				ratio: {type:'v2', value: new THREE.Vector2(1, 1)}
			},
			transparent: false,
			blending: THREE.NoBlending,
			side: THREE.DoubleSide,
			depthTest: false,
			depthWrite: false
		}));



	this.addMaterial('spritesheet', new THREE.RawShaderMaterial({
		defines: {
			"INVERT_COLORS": 0,
			"TEXTURES_INVERT_MODE": 0 
		},
		vertexShader:`
			precision mediump float;
			precision mediump int;
			uniform mediump mat4 modelViewMatrix; // optional
			uniform mediump mat4 projectionMatrix; // optional
			attribute mediump vec3 position;
			attribute mediump vec2 uv;
			// varying mediump vec2 vUv;
			varying mediump vec2 vUv_spritesheet;
			uniform mediump vec2 spritesheetScale;
			uniform mediump vec2 spritesheetOffset;
			varying mediump vec2 noiseUv;
			uniform mediump vec2 noiseScale;
			uniform mediump vec2 noiseOffset;
			uniform mediump mat2 noiseRotation;
			void main() {
				// vUv = vec2( uv.x, uv.y);
				vUv_spritesheet =  vec2(uv.x, uv.y) * spritesheetScale + spritesheetOffset;
				#if TEXTURES_INVERT_MODE
					vUv_spritesheet.y = 1.0-vUv_spritesheet.y;
				#endif
				noiseUv = uv * noiseRotation * noiseScale + noiseOffset;
				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
			}
		`,
		fragmentShader:`
			precision mediump float;
			precision mediump int;
			// varying mediump vec2 vUv;
			varying mediump vec2 vUv_spritesheet;
			uniform mediump sampler2D tDiffuse;
			varying mediump vec2 noiseUv;
			uniform mediump sampler2D tNoise;
			uniform lowp float noisepc;
			// uniform mediump vec2 spritesheetScale;
			// uniform mediump vec2 spritesheetOffset;
			void main() {
				mediump vec3 col = texture2D(tDiffuse, vUv_spritesheet).rgb;
				#if INVERT_COLORS
					col = 1.0-col;
				#endif
				gl_FragColor = vec4(col + (texture2D(tNoise, noiseUv).r*2.0-1.0) * 0.05 * noisepc, 1.0);
			}
		`,
		uniforms: {
			tDiffuse: {type:'t', value: Utils.whiteTexture},
			playbackRatio: {type:'v2', value: new THREE.Vector2(1,1)},
			playbackCenter: {type:'v2', value: new THREE.Vector2(1,1)},
			spritesheetOffset: {type:'v2', value: new THREE.Vector2(0,0)},
			spritesheetScale: {type:'v2', value: new THREE.Vector2(1/5, 1/5)},

			tNoise: {type:'t', value: Utils.whiteTexture},
			noisepc: {type:'f', value:1.0},
			noiseOffset: {type:'v2', value: new THREE.Vector2(0,0)},
			noiseScale: {type:'v2', value: new THREE.Vector2(1, 1)},
			noiseRotation: {type:'m2', value: {elements: new Float32Array(4)}}
		},
		transparent: false,
		depthTest: false,
		depthWrite: false,
		blending: THREE.NoBlending,
		side: THREE.DoubleSide
	}));

	this.addMaterial('faceMaterial', new THREE.RawShaderMaterial({
		vertexShader:`
			precision mediump float;
			precision mediump int;

			attribute mediump vec3 position;
			attribute mediump vec2 uv;

			uniform mediump mat4 modelViewMatrix; // optional
			uniform mediump mat4 projectionMatrix; // optional
			uniform mediump vec2 uvScale;
			varying mediump vec2 vUv;

			void main() {
				vUv = vec2((uv.x - 0.5) * uvScale.x + 0.5, (-uv.y + 0.5) * uvScale.y + 0.5);
				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
			}
		`,
		fragmentShader:`
			precision mediump float;
			precision mediump int;

			varying mediump vec2 vUv;

			uniform mediump sampler2D tDiffuse;
			uniform mediump vec3 tint;

			void main() {
				vec4 col = texture2D(tDiffuse, vUv);
				gl_FragColor = vec4(col.rgb*tint, col.a);
			}
		`,
		uniforms: {
			tDiffuse: {type:'t', value: Utils.whiteTexture},
			tint: {type:'c', value: new THREE.Color(0xffffff)},
			uvScale: {type:'v2', value: new THREE.Vector2(1,1)}
		},
		transparent: false,
		blending: THREE.NoBlending,
		depthTest: false,
		depthWrite: false,
		side: THREE.DoubleSide
	}));

	this.addMaterial('faceMaterialCamera', new THREE.RawShaderMaterial({
		vertexShader:`
			precision mediump float;
			precision mediump int;

			attribute mediump vec3 position;
			attribute mediump vec2 uv;

			uniform mediump mat4 modelViewMatrix; // optional
			uniform mediump mat4 projectionMatrix; // optional
			uniform mediump vec2 uvScale;
			varying mediump vec2 vUv;

			varying mediump vec2 vUvA;
			varying mediump vec2 vUvB;
			varying mediump vec2 vUvC;
			varying mediump vec2 vUvD;

			void main() {
				vUv = (uv - 0.5) * uvScale * 0.0 + 0.5;

				vUvA = (uv - 0.5) * uvScale * vec2(0.0,0.01) + vec2(0.05,0.05)/ uvScale + 0.5;
				vUvB = (uv - 0.5) * uvScale * vec2(0.0,0.01) + vec2(-0.05,0.05)/ uvScale + 0.5;
				vUvC = (uv - 0.5) * uvScale * vec2(0.0,0.01) + vec2(0.05,-0.05)/ uvScale + 0.5;
				vUvD = (uv - 0.5) * uvScale * vec2(0.0,0.01) + vec2(-0.05,-0.05)/ uvScale + 0.5;

				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
			}
		`,
		fragmentShader:`
			precision mediump float;
			precision mediump int;

			varying mediump vec2 vUv;
			varying mediump vec2 vUvA;
			varying mediump vec2 vUvB;
			varying mediump vec2 vUvC;
			varying mediump vec2 vUvD;


			uniform mediump sampler2D tDiffuse;
			uniform mediump vec3 tint;

			void main() {
				vec3 colE = texture2D(tDiffuse, vUv).rgb;
				vec3 colA = texture2D(tDiffuse, vUvA).rgb;
				vec3 colB = texture2D(tDiffuse, vUvB).rgb;
				vec3 colC = texture2D(tDiffuse, vUvC).rgb;
				vec3 colD = texture2D(tDiffuse, vUvD).rgb;
				vec3 col = max(max(max(max(colA, colB),colC),colD),colE) * 0.5 + colA*0.1+colB*0.1+colC*0.1+colD*0.1+colE*0.1;
					

				col = max(col, vec3(0.25));

				// float bw = dot(col, vec3(0.333));
				// vec3 dst = ( (col-bw)  );
				// col.rgb = vec3(bw) + dst * 1.25 + 0.25;

				gl_FragColor = vec4(col*tint, 1.0);
			}
		`,
		uniforms: {
			tDiffuse: {type:'t', value: Utils.whiteTexture},
			tint: {type:'c', value: new THREE.Color(0xffffff)},
			uvScale: {type:'v2', value: new THREE.Vector2(1,1)}
		},
		transparent: false,
		blending: THREE.NoBlending,
		depthTest: false,
		depthWrite: false,
		side: THREE.DoubleSide
	}));


	this.addMaterial('faceMaterialAverage_center', new THREE.RawShaderMaterial({
		vertexShader:`
			precision mediump float;
			precision mediump int;

			attribute mediump vec3 position;
			attribute mediump vec2 uv;

			uniform mediump mat4 modelViewMatrix; // optional
			uniform mediump mat4 projectionMatrix; // optional
			varying mediump vec2 vUv;


			uniform mediump vec2 uvCenter;
			uniform mediump vec2 faceScale;

			#define uvScale vec2(1.0,0.56)

			void main() {
				vec2 center = uvCenter;
				center.y = (0.5-center.y) * 2.0 + center.y;
				// center.y -= faceScale.y * 0.0 / uvScale.y;
				vUv = (vec2(0.5,0.5) - center) * uvScale * faceScale * 0.0 +  center;

				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
			}
		`,
		fragmentShader:`
			precision mediump float;
			precision mediump int;

			varying mediump vec2 vUv;
			varying mediump vec2 vUvA;
			varying mediump vec2 vUvB;
			varying mediump vec2 vUvC;
			varying mediump vec2 vUvD;

			uniform mediump sampler2D tDiffuse;
			uniform mediump vec3 tint;

			void main() {
				vec3 col = texture2D(tDiffuse, vUv).rgb;
				//max(max(max(max(colA, colB),colC),colD),colE) * 0.5 + colA*0.1+colB*0.1+colC*0.1+colD*0.1+colE*0.1;
					

				// col = max(col, vec3(0.33));

				float brightness = dot(col,vec3(0.33333));
				// vec3 colsat = col/brightness;
				// brightness = max(brightness, 0.33);
				//col = max(col, vec3(0.33));
				if (brightness<0.15) col = (col / brightness) * max(brightness, 0.15);

				// float bw = dot(col, vec3(0.333));
				// vec3 dst = ( (col-bw)  );
				// col.rgb = vec3(bw) + dst * 1.25 + 0.25;

				gl_FragColor = vec4(col,1.0);
			}
		`,
		uniforms: {
			tDiffuse: {type:'t', value: Utils.whiteTexture},
			tint: {type:'c', value: new THREE.Color(0xffffff)},
			uvScale: {type:'v2', value: new THREE.Vector2(1,1)},
			faceScale: {type:'v2', value: new THREE.Vector2(1,1)},
			uvCenter: {type:'v2', value: new THREE.Vector2(0.5, 0.5)}
		},
		transparent: false,
		blending: THREE.NoBlending,
		depthTest: false,
		depthWrite: false,
		side: THREE.DoubleSide
	}));



	this.addMaterial('faceMaterialAverage', new THREE.RawShaderMaterial({
		vertexShader:`
			precision mediump float;
			precision mediump int;

			attribute mediump vec3 position;
			attribute mediump vec2 uv;

			uniform mediump mat4 modelViewMatrix; // optional
			uniform mediump mat4 projectionMatrix; // optional
			// uniform mediump vec2 uvScale;
			varying mediump vec2 vUv;

			varying mediump vec2 vUvA;
			varying mediump vec2 vUvB;
			varying mediump vec2 vUvC;
			varying mediump vec2 vUvD;

			uniform mediump vec2 uvCenter;
			uniform mediump vec2 faceScale;

			#define uvScale vec2(1.0,0.56)

			void main() {
				vec2 center = uvCenter;
				center.y = (0.5-center.y) * 2.0 + center.y;
				// center.y -= faceScale.y * 0.0 / uvScale.y;
				vUv = (vec2(0.5,0.5) - center) * uvScale * faceScale * 0.0 +  center; //(uv - center) * faceScale * 0.25 + center + faceScale*0.25;

				vUvA = faceScale * vec2(0.05,0.05) + vUv;
				vUvB = faceScale * vec2(-0.05,0.05) + vUv;
				vUvC = faceScale * vec2(0.05,-0.05) + vUv;
				vUvD = faceScale * vec2(-0.05,-0.05) + vUv;

				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
			}
		`,
		fragmentShader:`
			precision mediump float;
			precision mediump int;

			varying mediump vec2 vUv;
			varying mediump vec2 vUvA;
			varying mediump vec2 vUvB;
			varying mediump vec2 vUvC;
			varying mediump vec2 vUvD;

			uniform mediump sampler2D tDiffuse;
			uniform mediump vec3 tint;

			void main() {
				vec3 colE = texture2D(tDiffuse, vUv).rgb;
				vec3 colA = texture2D(tDiffuse, vUvA).rgb;
				vec3 colB = texture2D(tDiffuse, vUvB).rgb;
				vec3 colC = texture2D(tDiffuse, vUvC).rgb;
				vec3 colD = texture2D(tDiffuse, vUvD).rgb;
				vec3 col = colA*0.2+colB*0.2+colC*0.2+colD*0.2+colE*0.2;
				//max(max(max(max(colA, colB),colC),colD),colE) * 0.5 + colA*0.1+colB*0.1+colC*0.1+colD*0.1+colE*0.1;
					

				// col = max(col, vec3(0.33));

				float brightness = dot(col,vec3(0.33333));
				// vec3 colsat = col/brightness;
				// brightness = max(brightness, 0.33);
				col = (col / brightness) * max(brightness, 0.33);


				// float bw = dot(col, vec3(0.333));
				// vec3 dst = ( (col-bw)  );
				// col.rgb = vec3(bw) + dst * 1.25 + 0.25;

				gl_FragColor = vec4(col,1.0);
			}
		`,
		uniforms: {
			tDiffuse: {type:'t', value: Utils.whiteTexture},
			tint: {type:'c', value: new THREE.Color(0xffffff)},
			uvScale: {type:'v2', value: new THREE.Vector2(1,1)},
			faceScale: {type:'v2', value: new THREE.Vector2(1,1)},
			uvCenter: {type:'v2', value: new THREE.Vector2(0.5, 0.5)}
		},
		transparent: false,
		blending: THREE.NoBlending,
		depthTest: false,
		depthWrite: false,
		side: THREE.DoubleSide
	}));


	this.addMaterial('whiteFaceMaterial', new THREE.RawShaderMaterial({
		vertexShader:`
			precision mediump float;
			precision mediump int;

			attribute mediump vec3 position;

			uniform mediump mat4 modelViewMatrix; // optional
			uniform mediump mat4 projectionMatrix; // optional

			void main() {
				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
			}
		`,
		fragmentShader:`
			precision mediump float;
			precision mediump int;

			varying mediump vec2 vUv;

			uniform mediump vec3 tint;

			void main() {
				gl_FragColor = vec4(tint, 1.0);
			}
		`,
		uniforms: {
			tint: {type:'c', value: new THREE.Color(0xffffff)}
		},
		transparent: false,
		blending: THREE.NoBlending,
		depthTest: false,
		depthWrite: false,
		side: THREE.DoubleSide
	}));


	this.addMaterial('invertedTextureTransparent', new THREE.RawShaderMaterial({
		vertexShader:`
			precision mediump float;
			precision mediump int;

			attribute mediump vec3 position;
			attribute mediump vec2 uv;

			uniform mediump mat4 modelViewMatrix; // optional
			uniform mediump mat4 projectionMatrix; // optional
			varying mediump vec2 vUv;

			void main() {
				vUv = vec2(uv.x, 1.0-uv.y);
				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
			}
		`,
		fragmentShader:`
			precision mediump float;
			precision mediump int;

			varying mediump vec2 vUv;

			uniform mediump sampler2D tDiffuse;
			uniform mediump vec3 tint;

			void main() {
				vec4 col = texture2D(tDiffuse, vUv);
				gl_FragColor = vec4(col.rgb*tint, col.a);
			}
		`,
		uniforms: {
			tDiffuse: {type:'t', value: Utils.whiteTexture},
			tint: {type:'c', value: new THREE.Color(0xffffff)}
		},
		transparent: true,
		blending: THREE.NormalBlending,
		depthTest: false,
		depthWrite: false,
		side: THREE.DoubleSide
	}));

	this.addMaterial('spritesheet', new THREE.RawShaderMaterial({
		defines: {
			"INVERT_COLORS": 0,
			"TEXTURES_INVERT_MODE": 0 
		},
		vertexShader:`
			precision mediump float;
			precision mediump int;

			uniform mediump mat4 modelViewMatrix; // optional
			uniform mediump mat4 projectionMatrix; // optional

			attribute mediump vec3 position;
			attribute mediump vec2 uv;

			// varying mediump vec2 vUv;
			varying mediump vec2 vUv_spritesheet;

			uniform mediump vec2 spritesheetScale;
			uniform mediump vec2 spritesheetOffset;


			varying mediump vec2 noiseUv;
			uniform mediump vec2 noiseScale;
			uniform mediump vec2 noiseOffset;
			uniform mediump mat2 noiseRotation;

			void main() {
				// vUv = vec2( uv.x, uv.y);
				vUv_spritesheet =  vec2(uv.x, uv.y) * spritesheetScale + spritesheetOffset;
				#if TEXTURES_INVERT_MODE
					vUv_spritesheet.y = 1.0-vUv_spritesheet.y;
				#endif
				noiseUv = uv * noiseRotation * noiseScale + noiseOffset;
				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
			}
		`,
		fragmentShader:`
			precision mediump float;
			precision mediump int;

			// varying mediump vec2 vUv;
			varying mediump vec2 vUv_spritesheet;

			uniform mediump sampler2D tDiffuse;


			varying mediump vec2 noiseUv;
			uniform mediump sampler2D tNoise;
			uniform lowp float noisepc;

			// uniform mediump vec2 spritesheetScale;
			// uniform mediump vec2 spritesheetOffset;

			void main() {
				mediump vec3 col = texture2D(tDiffuse, vUv_spritesheet).rgb;
				#if INVERT_COLORS
					col = 1.0-col;
				#endif
				gl_FragColor = vec4(col + (texture2D(tNoise, noiseUv).r*2.0-1.0) * 0.11 * noisepc, 1.0);
			}
		`,
		uniforms: {
			tDiffuse: {type:'t', value: Utils.whiteTexture},
			playbackRatio: {type:'v2', value: new THREE.Vector2(1,1)},
			playbackCenter: {type:'v2', value: new THREE.Vector2(1,1)},
			spritesheetOffset: {type:'v2', value: new THREE.Vector2(0,0)},
			spritesheetScale: {type:'v2', value: new THREE.Vector2(1/5, 1/5)},

			tNoise: {type:'t', value: Utils.whiteTexture},
			noisepc: {type:'f', value:1.0},
			noiseOffset: {type:'v2', value: new THREE.Vector2(0,0)},
			noiseScale: {type:'v2', value: new THREE.Vector2(1, 1)},
			noiseRotation: {type:'m2', value: {elements: new Float32Array(4)}}
		},
		transparent: false,
		depthTest: false,
		depthWrite: false,
		blending: THREE.NoBlending,
		side: THREE.DoubleSide
	}));

	//init material
	this.addMaterial('mirror', new THREE.RawShaderMaterial({
		vertexShader:`
			precision mediump float;
			precision mediump int;

			uniform mediump mat4 modelViewMatrix; // optional
			uniform mediump mat4 projectionMatrix; // optional

			attribute mediump vec3 position;
			attribute mediump vec2 uv;

			varying mediump vec2 vUv;

			uniform mediump vec2 ratio;

			void main() {
				vUv = (vec2(uv.x, uv.y)-0.5) * ratio + 0.5;
				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
			}
		`,
		fragmentShader:`
			precision mediump float;
			precision mediump int;

			varying mediump vec2 vUv;
			uniform mediump sampler2D tDiffuse;

			void main() {
				vec2 nuv = vUv;
				if (nuv.x > 0.5) nuv.x = 1.0-vUv.x;
				gl_FragColor = vec4(texture2D(tDiffuse, nuv).rgb, 1.0);
			}
		`,
		uniforms: {
			tDiffuse: {type:'t', value: Utils.blackTexture},
			ratio: {type:'v2', value: new THREE.Vector2(1, 1)}
		},
		transparent: false,
		blending: THREE.NoBlending,
		side: THREE.DoubleSide,
		depthTest: false,
		depthWrite: false
	}));





		this.addMaterial('montage-blend', new THREE.RawShaderMaterial({
			defines: {
				TRACKING_ENABLED: 0,
				BLEND_MODE: 0
			},
			vertexShader:`
				precision mediump float;
				precision mediump int;

				uniform mediump mat4 modelViewMatrix; // optional
				uniform mediump mat4 projectionMatrix; // optional

				attribute mediump vec3 position;
				attribute mediump vec2 uv;

				varying mediump vec2 vUv;
				#if TRACKING_ENABLED
					varying mediump vec2 blendUv;
					uniform mediump float blendScale;
					uniform mediump vec2 blendCenter;
				#endif

				uniform mediump vec2 ratio;

				void main() {
					vUv = (vec2(uv.x, uv.y)-0.5) * ratio + 0.5;
					#if TRACKING_ENABLED
						blendUv = (vec2(uv.x, uv.y)-0.5) * ratio * blendScale+ 0.5 - blendCenter * blendScale;
						//(vec2(uv.x, uv.y)-0.5) * ratio * blendScale + 0.5 + blendCenter;
					#endif
					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}
			`,
			fragmentShader:`
				precision mediump float;
				precision mediump int;

				varying mediump vec2 vUv;
				#if TRACKING_ENABLED
					varying mediump vec2 blendUv;
				#else
					#define blendUv vUv
				#endif


				uniform mediump sampler2D tDiffuse;
				uniform mediump sampler2D tBlend;
				uniform lowp float blendAlpha;

				#define BLEND_NORMAL 0
				#define BLEND_SCREEN 1
				#define BLEND_MULTIPLY 2
				#define BLEND_ADD 3
				#define BLEND_DIFFERENCE 4

				#define BlendScreenf(base, blend) 		(1.0 - ((1.0 - base) * (1.0 - blend)))
				#define Blend(base, blend, funcf) 		vec3(funcf(base.r, blend.r), funcf(base.g, blend.g), funcf(base.b, blend.b))
				#define BlendScreen(base, blend) 		Blend(base, blend, BlendScreenf)

				#if TRACKING_ENABLED == 0
					#define vignette 1.0
				#endif

				void main() {

					#if TRACKING_ENABLED
						mediump float vignette = 1.0-clamp(pow(distance(blendUv, vec2(0.5))*2.0,8.0),0.0,1.0);						
					#endif

					#if BLEND_MODE == BLEND_NORMAL

						gl_FragColor = vec4(mix(texture2D(tDiffuse, vUv).rgb, texture2D(tBlend, blendUv).rgb, blendAlpha*vignette), 1.0);

					#elif BLEND_MODE == BLEND_SCREEN

						vec3 col = texture2D(tDiffuse, vUv).rgb;
						vec3 bcol = texture2D(tBlend, blendUv).rgb;
						vec3 scol = BlendScreen(col, bcol);
						gl_FragColor = vec4(mix(col, scol, blendAlpha*vignette), 1.0);

					#elif BLEND_MODE == BLEND_MULTIPLY

						vec3 col = texture2D(tDiffuse, vUv).rgb;
						gl_FragColor = vec4(mix(col, col*texture2D(tBlend, blendUv).rgb, blendAlpha*vignette), 1.0);

					#elif BLEND_MODE == BLEND_ADD

						vec3 col = texture2D(tDiffuse, vUv).rgb;
						gl_FragColor = vec4(col+texture2D(tBlend, blendUv).rgb * blendAlpha*vignette, 1.0);

					#elif BLEND_MODE == BLEND_DIFFERENCE

						vec3 col = texture2D(tDiffuse, vUv).rgb;
						gl_FragColor = vec4(mix(col, abs(col-texture2D(tBlend, blendUv).rgb), blendAlpha*vignette), 1.0);

					#else //normal

						gl_FragColor = vec4(mix(texture2D(tDiffuse, vUv).rgb, texture2D(tBlend, blendUv).rgb, blendAlpha*vignette), 1.0);

					#endif
				}
			`,
			uniforms: {
				tDiffuse: {type:'t', value: Utils.blackTexture},
				ratio: {type:'v2', value: new THREE.Vector2(1, 1)},

				tBlend: {type:'t', value: Utils.blackTexture},
				blendAlpha: {type:'f', value: 0.0},

				blendScale: {type:'f', value: 1.0},
				blendCenter: {type:'v2', value: new THREE.Vector2(0.5,0.5)},

			},
			transparent: false,
			blending: THREE.NoBlending,
			side: THREE.DoubleSide,
			depthTest: false,
			depthWrite: false
		}));



		
		this.addMaterial('playback_transparent', new THREE.RawShaderMaterial({
			vertexShader:`
				precision mediump float;
				precision mediump int;

				uniform mediump mat4 modelViewMatrix; // optional
				uniform mediump mat4 projectionMatrix; // optional

				attribute mediump vec3 position;
				attribute mediump vec2 uv;

				varying mediump vec2 vUv;

				uniform mediump vec2 ratio;

				void main() {
					vUv = (vec2(uv.x, uv.y)-0.5) * ratio + 0.5;
					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}
			`,
			fragmentShader:`
				precision mediump float;
				precision mediump int;

				varying mediump vec2 vUv;
				uniform mediump sampler2D tDiffuse;

				void main() {
					gl_FragColor = texture2D(tDiffuse, vUv);
				}
			`,
			uniforms: {
				tDiffuse: {type:'t', value: Utils.blackTexture},
				ratio: {type:'v2', value: new THREE.Vector2(1, 1)}
			},
			transparent: true,
			blending: THREE.NormalBlending,
			side: THREE.DoubleSide,
			depthTest: false,
			depthWrite: false
		}));

		
		this.addMaterial('playback_rgba', new THREE.RawShaderMaterial({
			vertexShader:`
				precision mediump float;
				precision mediump int;

				uniform mediump mat4 modelViewMatrix; // optional
				uniform mediump mat4 projectionMatrix; // optional

				attribute mediump vec3 position;
				attribute mediump vec2 uv;

				varying mediump vec2 vUv;

				uniform mediump vec2 ratio;

				void main() {
					vUv = (vec2(uv.x, uv.y)-0.5) * ratio + 0.5;
					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}
			`,
			fragmentShader:`
				precision mediump float;
				precision mediump int;

				varying mediump vec2 vUv;
				uniform mediump sampler2D tDiffuse;

				void main() {
					gl_FragColor = texture2D(tDiffuse, vUv);
				}
			`,
			uniforms: {
				tDiffuse: {type:'t', value: Utils.blackTexture},
				ratio: {type:'v2', value: new THREE.Vector2(1, 1)}
			},
			transparent: true,
			blending: THREE.NormalBlending,
			side: THREE.DoubleSide,
			depthTest: false,
			depthWrite: false
		}));


		this.addMaterial('playback_mirror', new THREE.RawShaderMaterial({
			vertexShader:`
				precision mediump float;
				precision mediump int;

				uniform mediump mat4 modelViewMatrix; // optional
				uniform mediump mat4 projectionMatrix; // optional

				attribute mediump vec3 position;
				attribute mediump vec2 uv;

				varying mediump vec2 vUv;

				uniform mediump vec2 ratio;

				void main() {
					vUv = (uv - 0.5) * ratio + 0.5;
					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}
			`,
			fragmentShader:`
				precision mediump float;
				precision mediump int;

				varying mediump vec2 vUv;
				uniform mediump sampler2D tDiffuse;

				void main() {
					gl_FragColor = vec4(texture2D(tDiffuse, vec2(abs(0.5-vUv.x)+0.5, vUv.y)).rgb, 1.0);
				}
			`,
			uniforms: {
				tDiffuse: {type:'t', value: Utils.blackTexture},
				ratio: {type:'v2', value: new THREE.Vector2(1, 1)}
			},
			transparent: false,
			blending: THREE.NoBlending,
			side: THREE.DoubleSide,
			depthTest: false,
			depthWrite: false
		}));

		this.addMaterial('playback_motion_delay', new THREE.RawShaderMaterial({
			defines: {
				"INVERT_COLORS": 0,
			},
			vertexShader:`
				precision mediump float;
				precision mediump int;

				uniform mediump mat4 modelViewMatrix; // optional
				uniform mediump mat4 projectionMatrix; // optional

				attribute mediump vec3 position;
				attribute mediump vec2 uv;

				varying mediump vec2 vUv;

				uniform mediump vec2 ratio;

				// varying mediump vec2 noiseUv;
				// uniform mediump vec2 noiseScale;
				// uniform mediump vec2 noiseOffset;
				// uniform mediump mat2 noiseRotation;

				void main() {
					vUv = (vec2(uv.x, uv.y)-0.5) * ratio + 0.5;
					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}
			`,
			fragmentShader:`
				precision mediump float;
				precision mediump int;

				varying mediump vec2 vUv;
				uniform mediump sampler2D tDiffuse;
				uniform mediump sampler2D tDiffuse2;
				uniform mediump sampler2D tDiffuse3;


				// varying mediump vec2 noiseUv;
				// uniform mediump sampler2D tNoise;
				// uniform lowp float noisepc;

				uniform lowp vec3 colorA;
				uniform lowp vec3 colorB;
				uniform lowp vec3 colorC;

				uniform lowp float revealpc;


				void main() {
					mediump vec3 colA = texture2D(tDiffuse, vUv).rgb;
					mediump vec3 colB = texture2D(tDiffuse2, vUv).rgb;
					mediump vec3 colC = texture2D(tDiffuse3, vUv).rgb;

					float diffA = min(distance(colA, colB) * 1.0, 1.0);
					float diffB = min(distance(colA, colC) * 1.0, 1.0);
					float diffC = min(distance(colB, colC) * 1.0, 1.0);

					#if INVERT_COLORS == 1
						vec3 col = 1.0 - (colorA * diffA + colorB * diffB + colorC * diffC);
					#else
						vec3 col = colorA * diffA + colorB * diffB + colorC * diffC;
					#endif
					col = mix(colA, clamp(col,vec3(0.0),vec3(1.0)), revealpc);

					// mediump float noise = (texture2D(tNoise, noiseUv).r*2.0-1.0) * 0.11 * noisepc;
					gl_FragColor = vec4(col, 1.0);
				}
			`,
			uniforms: {
				ratio: {type:'v2', value: new THREE.Vector2(1,1)},

				tDiffuse: {type:'t', value: Utils.whiteTexture},
				tDiffuse2: {type:'t', value: Utils.whiteTexture},
				tDiffuse3: {type:'t', value: Utils.whiteTexture},

				colorA: {type:'c', value: new THREE.Color(0xffffff)},
				colorB: {type:'c', value: new THREE.Color(0xffffff)},
				colorC: {type:'c', value: new THREE.Color(0xffffff)},

				tNoise: {type:'t', value: Utils.whiteTexture},
				noisepc: {type:'f', value:0.0},
				noiseOffset: {type:'v2', value: new THREE.Vector2(0,0)},
				noiseScale: {type:'v2', value: new THREE.Vector2(1, 1)},
				noiseRotation: {type:'m2', value: {elements: new Float32Array(4)}},
				revealpc: {type:'f', value: 1.0}
			},
			transparent: false,
			depthTest: false,
			depthWrite: false,
			blending: THREE.NoBlending,
			side: THREE.DoubleSide
		}));

		this.addMaterial('playback_reference', new THREE.RawShaderMaterial({
				defines: {
					"BLEND_MODE": 0,
					"MIRROR": 0,
					"FEATHER": 0,
					"FEATHERPC": 1.0
				},
				vertexShader:`
					precision mediump float;
					precision mediump int;

					uniform mediump mat4 modelViewMatrix; // optional
					uniform mediump mat4 projectionMatrix; // optional

					attribute mediump vec3 position;
					attribute mediump vec2 uv;

					varying mediump vec2 vUv;
					varying mediump vec2 referenceUv;

					uniform mediump vec2 ratio;
					uniform mediump vec2 referenceRatio;
					uniform mediump vec2 referenceOffset;

					void main() {
						vUv = (vec2(uv.x, uv.y)-0.5) * ratio + 0.5;
						referenceUv = (vec2(uv.x, uv.y)-0.5) * referenceRatio * ratio + 0.5 + referenceOffset * referenceRatio;
						gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
					}
				`,
				fragmentShader:`
					precision mediump float;
					precision mediump int;

					varying mediump vec2 vUv;
					varying mediump vec2 referenceUv;
					uniform mediump sampler2D tDiffuse;
					uniform mediump sampler2D tReference;

					#define BlendScreenf(base, blend) 		(1.0 - ((1.0 - base) * (1.0 - blend)))
					#define Blend(base, blend, funcf) 		vec3(funcf(base.r, blend.r), funcf(base.g, blend.g), funcf(base.b, blend.b))
					#define BlendScreen(base, blend) 		Blend(base, blend, BlendScreenf)

					uniform mediump float referenceAlpha;

					void main() {
						#if MIRROR
							vec3 colA = texture2D(tDiffuse, vec2(abs(0.5-vUv.x)+0.5, vUv.y)).rgb;
						#else
							vec3 colA = texture2D(tDiffuse, vUv).rgb;
						#endif
						vec4 colB = texture2D(tReference, referenceUv);

						#if FEATHER
							float dst = max(0.5-distance(referenceUv,vec2(0.5)), 0.0);
							colB = colB * pow( min(dst*5.0,1.0), 2.0);
						#endif

						//normal
						#if BLEND_MODE == 0
							vec3 col = mix(colA, colB.rgb, colB.a*referenceAlpha);

						//add
						#elif BLEND_MODE == 1
							vec3 col = colA + colB.rgb * colB.a*referenceAlpha;

						//screen
						#elif BLEND_MODE == 2
							vec3 colC = colB.rgb;
							vec3 col = mix(colA, BlendScreen(colA, colC), colB.a*referenceAlpha);

						//difference
						#elif BLEND_MODE == 3
							vec3 col = mix(colA, abs(colA - colB.rgb), colB.a*referenceAlpha);

						//multiply
						#elif BLEND_MODE == 4
							vec3 col = mix(colA, (colA * colB.rgb), colB.a*referenceAlpha);
						#endif

						gl_FragColor = vec4(col, 1.0);
					}
				`,
				uniforms: {
					tDiffuse: {type:'t', value: Utils.blackTexture},
					ratio: {type:'v2', value: new THREE.Vector2(1, 1)},
					tReference: {type:'t', value: Utils.blackTexture},
					referenceRatio: {type:'v2', value: new THREE.Vector2(1, 1)},
					referenceOffset: {type:'v2', value: new THREE.Vector2(0, 0)},
					referenceAlpha: {type:'f', value: 1.0}
				},
				transparent: false,
				blending: THREE.NoBlending,
				side: THREE.DoubleSide,
				depthTest: false,
				depthWrite: false
			}));



		this.addMaterial('opticalFlow', new THREE.RawShaderMaterial({
			vertexShader:`
				precision mediump float;
				precision mediump int;

				attribute mediump vec3 position;
				attribute mediump vec2 uv;

				uniform mediump mat4 modelViewMatrix; // optional
				uniform mediump mat4 projectionMatrix; // optional

				varying mediump vec2 vUv;
				varying mediump vec2 vUvm1m1;
				varying mediump vec2 vUvm0m1;
				varying mediump vec2 vUvp1m1;
				varying mediump vec2 vUvm1m0;
				#define vUvm0m0 vUv
				varying mediump vec2 vUvp1m0;
				varying mediump vec2 vUvm1p1;
				varying mediump vec2 vUvm0p1;
				varying mediump vec2 vUvp1p1;

				// #define p1px 0.0078125
				// #define m1px -0.0078125

				#define p1px 0.015625
				#define m1px -0.015625

				void main() {
					vUv = uv;
					vUvm1m1 = uv + vec2(m1px, m1px);
					vUvm0m1 = uv + vec2(0.0, m1px);
					vUvp1m1 = uv + vec2(p1px, m1px);

					vUvm1m0 = uv + vec2(m1px, 0.0);
					vUvp1m0 = uv + vec2(p1px, 0.0);

					vUvm1p1 = uv + vec2(m1px, p1px);
					vUvm0p1 = uv + vec2(0.0, p1px);
					vUvp1p1 = uv + vec2(p1px, p1px);


					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}
			`,
			fragmentShader:`
				precision mediump float;
				precision mediump int;

				varying mediump vec2 vUv;
				varying mediump vec2 vUvm1m1;
				varying mediump vec2 vUvm0m1;
				varying mediump vec2 vUvp1m1;
				varying mediump vec2 vUvm1m0;
				#define vUvm0m0 vUv
				varying mediump vec2 vUvp1m0;
				varying mediump vec2 vUvm1p1;
				varying mediump vec2 vUvm0p1;
				varying mediump vec2 vUvp1p1;

				uniform mediump sampler2D tCurrent;
				uniform mediump sampler2D tPrev;

				#define gray(v) dot(v, vec3(0.33333))

				void main() {
					lowp float current_m1m1 = gray(texture2D(tCurrent, vUvm1m1).rgb);
					lowp float current_m0m1 = gray(texture2D(tCurrent, vUvm0m1).rgb);
					lowp float current_p1m1 = gray(texture2D(tCurrent, vUvp1m1).rgb);

					lowp float current_m1m0 = gray(texture2D(tCurrent, vUvm1m0).rgb);
					lowp float current_m0m0 = gray(texture2D(tCurrent, vUvm0m0).rgb);
					lowp float current_p1m0 = gray(texture2D(tCurrent, vUvp1m0).rgb);

					lowp float current_m1p1 = gray(texture2D(tCurrent, vUvm1p1).rgb);
					lowp float current_m0p1 = gray(texture2D(tCurrent, vUvm0p1).rgb);
					lowp float current_p1p1 = gray(texture2D(tCurrent, vUvp1p1).rgb);


					lowp float prev_m1m1 = gray(texture2D(tPrev, vUvm1m1).rgb);
					lowp float prev_m0m1 = gray(texture2D(tPrev, vUvm0m1).rgb);
					lowp float prev_p1m1 = gray(texture2D(tPrev, vUvp1m1).rgb);

					lowp float prev_m1m0 = gray(texture2D(tPrev, vUvm1m0).rgb);
					lowp float prev_m0m0 = gray(texture2D(tPrev, vUvm0m0).rgb);
					lowp float prev_p1m0 = gray(texture2D(tPrev, vUvp1m0).rgb);

					lowp float prev_m1p1 = gray(texture2D(tPrev, vUvm1p1).rgb);
					lowp float prev_m0p1 = gray(texture2D(tPrev, vUvm0p1).rgb);
					lowp float prev_p1p1 = gray(texture2D(tPrev, vUvp1p1).rgb);


					lowp float horizontalSobelCurrent = 
						current_m1m1 * -1.0 + current_m1m0 *-2.0 + current_m1p1 * -1.0 +
						current_p1m1 + current_p1m0 * 2.0 + current_p1p1;
					
					lowp float horizontalSobelPrev = 
						prev_m1m1 * -1.0 + prev_m1m0 *-2.0 + prev_m1p1 * -1.0 +
						prev_p1m1 + prev_p1m0 * 2.0 + prev_p1p1;

					lowp float verticalSobelCurrent = 
						current_m1m1 * -1.0 + current_m0m1*-2.0 + current_p1m1*-1.0 +
						current_m1p1 + current_m0p1*2.0 + current_p1p1;

					lowp float verticalSobelPrev = 
						prev_m1m1 * -1.0 + prev_m0m1*-2.0 + prev_p1m1*-1.0 +
						prev_m1p1 + prev_m0p1*2.0 + prev_p1p1;


					lowp float dt = current_m0m0 - prev_m0m0; // dt
					lowp float dx = horizontalSobelCurrent + horizontalSobelPrev; // dx_curr + dx_prev
					lowp float dy = verticalSobelCurrent + verticalSobelPrev; // dy_curr + dy_prev

					dt *= 3.0;
					dx *= 3.0;
					dy *= 3.0;

					// gradient length
					lowp float dd = sqrt(dx*dx + dy*dy + 0.0001);
					lowp vec2 flow =  (dt * vec2(dx, dy) / dd) * 0.5 + 0.5;

					// threshold
					//float len_old = sqrt(flow.x*flow.x + flow.y*flow.y + 0.00001);
					//float len_new = max(len_old - threshold, 0.0);
					//flow *= len_new / len_old;

					gl_FragColor = vec4(flow, 0.0, 1.0);
				}
			`,
			uniforms: {
				tCurrent: {type:'t', value: Utils.whiteTexture},
				tPrev: {type:'t', value: Utils.whiteTexture}
			},
			transparent: false,
			blending: THREE.NoBlending,
			depthTest: false,
			depthWrite: false,
			side: THREE.DoubleSide
		}));

		SpritesheetVideo.whitePlaneMaterial = new THREE.MeshBasicMaterial({
			blending: THREE.NormalBlending,
			side: THREE.DoubleSide,
			map: Utils.whiteTexture,
			depthTest: false,
			depthWrite: false,
			transparent: false,
			color: new THREE.Color('#ebebeb')
		});




		this.addMaterial('motionblur_trail', new THREE.RawShaderMaterial({
			vertexShader:`
				precision mediump float;
				precision mediump int;

				uniform mediump mat4 modelViewMatrix; // optional
				uniform mediump mat4 projectionMatrix; // optional

				attribute mediump vec3 position;
				attribute mediump vec2 uv;

				varying mediump vec2 vUv;

				uniform mediump float blurDirection;

				uniform mediump vec2 ratio;

				void main() {
					vUv = (uv - 0.5) * ratio + 0.5;
					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}
			`,
			fragmentShader:`
				precision mediump float;
				precision mediump int;

				varying mediump vec2 vUv;
				uniform mediump sampler2D tDiffuse;
				uniform mediump sampler2D tDiffuseLast;

				void main() {
					gl_FragColor = vec4(texture2D(tDiffuse, vec2(abs(0.5-vUv.x)+0.5, vUv.y)).rgb, 1.0);
				}
			`,
			uniforms: {
				tDiffuse: {type:'t', value: Utils.blackTexture},
				tDiffuseLast: {type:'t', value: Utils.blackTexture},
				blend: {type:'f', value: 0.0},
				ratio: {type:'v2', value: new THREE.Vector2(1, 1)}
			},
			transparent: false,
			blending: THREE.NoBlending,
			side: THREE.DoubleSide,
			depthTest: false,
			depthWrite: false
		}));



		this.addMaterial('cameracover_blend', new THREE.RawShaderMaterial({
			vertexShader:`
				precision mediump float;
				precision mediump int;

				uniform mediump mat4 modelViewMatrix; // optional
				uniform mediump mat4 projectionMatrix; // optional

				attribute mediump vec3 position;
				attribute mediump vec2 uv;

				varying mediump vec2 vUv;
				uniform mediump vec2 ratio;

				void main() {
					vUv = (uv - 0.5) * ratio + 0.5;
					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}
			`,
			fragmentShader:`
				precision mediump float;
				precision mediump int;

				varying mediump vec2 vUv;
				uniform mediump sampler2D tDiffuse;
				uniform mediump sampler2D tBlur;
				uniform mediump sampler2D tCamera;
				uniform mediump float blend;
				uniform mediump float exposure;
				uniform mediump float blurpc;

				void main() {
					mediump vec3 blurv = texture2D(tBlur, vUv, 1.0+blurpc*5.0).rgb;
					mediump vec3 nv = texture2D(tDiffuse, vUv).rgb;
					gl_FragColor = vec4(texture2D(tCamera, vUv).rgb * (1.0-blend*0.9) +  mix(nv,nv*pow(abs(1.0-blurpc),2.0)+blurv*(1.0+blurpc*0.5),blurpc) * blend * exposure, 1.0);
				}
			`,
			uniforms: {
				tDiffuse: {type:'t', value: Utils.blackTexture},
				tBlur: {type:'t', value: Utils.blackTexture},
				tCamera: {type:'t', value: Utils.blackTexture},
				blend: {type:'f', value: 0.0},
				exposure: {type:'f', value: 1.0},
				blurpc: {type:'f', value: 1.0},

				ratio: {type:'v2', value: new THREE.Vector2(1, 1)}
			},
			transparent: false,
			blending: THREE.NoBlending,
			side: THREE.DoubleSide,
			depthTest: false,
			depthWrite: false
		}));


		this.addMaterial('cameraspin_blend', new THREE.RawShaderMaterial({
			vertexShader:`
				precision mediump float;
				precision mediump int;

				uniform mediump mat4 modelViewMatrix; // optional
				uniform mediump mat4 projectionMatrix; // optional

				attribute mediump vec3 position;
				attribute mediump vec2 uv;

				varying mediump vec2 vUv;
				varying mediump vec2 vUv2;
				varying mediump vec2 vUv3;

				uniform mediump vec2 ratio;
				uniform mediump float speed;

				void main() {
					vUv = (uv - 0.5) * ratio + 0.5;
					vUv2 = vec2(vUv.x-0.1*speed, vUv.y);
					vUv3 = vec2(vUv.x+0.1*speed, vUv.y);
					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}
			`,
			fragmentShader:`
				precision mediump float;
				precision mediump int;

				varying mediump vec2 vUv;
				varying mediump vec2 vUv2;
				varying mediump vec2 vUv3;
				uniform mediump sampler2D tDiffuse;
				uniform mediump sampler2D tCamera;
				uniform mediump float blend;
				uniform mediump float exposure;

				void main() {
					gl_FragColor = vec4(
					(texture2D(tCamera, vUv).rgb + texture2D(tCamera, vUv2).rgb + texture2D(tCamera, vUv3).rgb ) * 0.3 * (1.0-blend*0.5)



					+  texture2D(tDiffuse, vUv).rgb * blend * exposure, 1.0);
				}
			`,
			uniforms: {
				tDiffuse: {type:'t', value: Utils.blackTexture},
				tCamera: {type:'t', value: Utils.blackTexture},
				blend: {type:'f', value: 0.0},
				exposure: {type:'f', value: 1.0},
				speed: {type:'f', value: 1.0},

				ratio: {type:'v2', value: new THREE.Vector2(1, 1)}
			},
			transparent: false,
			blending: THREE.NoBlending,
			side: THREE.DoubleSide,
			depthTest: false,
			depthWrite: false
		}));


		this.addMaterial('zoom', new THREE.RawShaderMaterial({
			vertexShader:`
				precision mediump float;
				precision mediump int;

				attribute mediump vec3 position;
				attribute mediump vec2 uv;
				varying mediump vec2 vUv;
				varying mediump vec2 noiseUv;

				uniform mediump mat4 modelViewMatrix; // optional
				uniform mediump mat4 projectionMatrix; // optional

				uniform mediump vec2 targetCenter;
				uniform mediump float zoomPc;

				uniform mediump vec2 noiseScale;
				uniform mediump vec2 noiseOffset;
				uniform mediump mat2 noiseRotation;


				void main() {
					vUv = (uv-targetCenter) * zoomPc + targetCenter;
					noiseUv = uv * noiseScale + noiseOffset;
					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}
			`,
			fragmentShader:`
				precision mediump float;
				precision mediump int;

				varying mediump vec2 vUv;
				varying mediump vec2 noiseUv;

				uniform sampler2D tDiffuse;
				uniform sampler2D tNoise;
				uniform lowp float noisePc;

				void main() {
					gl_FragColor = vec4(texture2D(tDiffuse,vUv).rgb + (texture2D(tNoise, noiseUv).r*2.0-1.0)*noisePc, 1.0);
				}
			`,
			uniforms: {
				tDiffuse: {type:'t', value: Utils.whiteTexture},
				targetCenter: {type:'v2', value: new THREE.Vector2(0.5, 0.5)},
				zoomPc:  {type:'f', value: 1.0},

				tNoise: {type:'t', value: Utils.whiteTexture},
				noisePc: {type:'f', value:1.0},
				noiseOffset: {type:'v2', value: new THREE.Vector2(0,0)},
				noiseScale: {type:'v2', value: new THREE.Vector2(1, 1)},
				noiseRotation: {type:'m2', value: {elements: new Float32Array(4)}}
			},
			transparent: false,
			blending: THREE.NoBlending,
			depthTest: false,
			depthWrite: false,
			side: THREE.DoubleSide
		}));


		this.addMaterial('spritesheet_motion_delay', new THREE.RawShaderMaterial({
				defines: {
					"INVERT_COLORS": 0,
					"TEXTURES_INVERT_MODE": 0
				},
				vertexShader:`
					precision mediump float;
					precision mediump int;

					uniform mediump mat4 modelViewMatrix; // optional
					uniform mediump mat4 projectionMatrix; // optional

					attribute mediump vec3 position;
					attribute mediump vec2 uv;

					varying mediump vec2 vUv;

					varying mediump vec2 vUv_spritesheet;
					uniform mediump vec2 spritesheetScale;
					uniform mediump vec2 spritesheetOffset;

					varying mediump vec2 vUv_spritesheet2;
					uniform mediump vec2 spritesheetOffset2;

					varying mediump vec2 vUv_spritesheet3;
					uniform mediump vec2 spritesheetOffset3;


					varying mediump vec2 noiseUv;
					uniform mediump vec2 noiseScale;
					uniform mediump vec2 noiseOffset;
					uniform mediump mat2 noiseRotation;

					void main() {
						vUv = uv;
						vUv_spritesheet =  vec2(uv.x, uv.y) * spritesheetScale + spritesheetOffset;
						vUv_spritesheet2 =  vec2(uv.x, uv.y) * spritesheetScale + spritesheetOffset2;
						vUv_spritesheet3 =  vec2(uv.x, uv.y) * spritesheetScale + spritesheetOffset3;

						#if TEXTURES_INVERT_MODE
							vUv_spritesheet.y = 1.0-vUv_spritesheet.y;
							vUv_spritesheet2.y = 1.0-vUv_spritesheet2.y;
							vUv_spritesheet3.y = 1.0-vUv_spritesheet3.y;
						#endif

						noiseUv = uv * noiseRotation * noiseScale + noiseOffset;
						gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
					}
				`,
				fragmentShader:`
					precision mediump float;
					precision mediump int;

					varying mediump vec2 vUv;
					varying mediump vec2 vUv_spritesheet;
					varying mediump vec2 vUv_spritesheet2;
					varying mediump vec2 vUv_spritesheet3;

					uniform mediump sampler2D tDiffuse;
					uniform mediump sampler2D tDiffuse2;
					uniform mediump sampler2D tDiffuse3;
					uniform mediump sampler2D tFaces;


					varying mediump vec2 noiseUv;
					uniform mediump sampler2D tNoise;
					uniform lowp float noisepc;


					uniform lowp vec3 colorA;
					uniform lowp vec3 colorB;
					uniform lowp vec3 colorC;


					void main() {
						mediump vec3 faceCol = vec3(1.0) - texture2D(tFaces, vUv).rgb;
						mediump vec3 colA = texture2D(tDiffuse, vUv_spritesheet).rgb * faceCol.r;
						mediump vec3 colB = texture2D(tDiffuse2, vUv_spritesheet2).rgb * faceCol.g;
						mediump vec3 colC = texture2D(tDiffuse3, vUv_spritesheet3).rgb * faceCol.b;

						float diffA = min(distance(colA, colB) * 1.0, 1.0);
						float diffB = min(distance(colA, colC) * 1.0, 1.0);
						float diffC = min(distance(colB, colC) * 1.0, 1.0);

						#if INVERT_COLORS == 1
							vec3 col = 1.0 - (colorA * diffA + colorB * diffB + colorC * diffC);
						#else
							vec3 col = colorA * diffA + colorB * diffB + colorC * diffC;
						#endif

						mediump float noise = (texture2D(tNoise, noiseUv).r*2.0-1.0) * 0.11 * noisepc;
						gl_FragColor = vec4(col + noise, 1.0);
					}
				`,
				uniforms: {
					playbackRatio: {type:'v2', value: new THREE.Vector2(1,1)},
					playbackCenter: {type:'v2', value: new THREE.Vector2(1,1)},

					tFaces: {type:'t', value: Utils.blackTexture},

					tDiffuse: {type:'t', value: Utils.whiteTexture},
					spritesheetOffset: {type:'v2', value: new THREE.Vector2(0,0)},
					spritesheetScale: {type:'v2', value: new THREE.Vector2(1/5, 1/5)},

					tDiffuse2: {type:'t', value: Utils.whiteTexture},
					spritesheetOffset2: {type:'v2', value: new THREE.Vector2(0,0)},

					tDiffuse3: {type:'t', value: Utils.whiteTexture},
					spritesheetOffset3: {type:'v2', value: new THREE.Vector2(0,0)},

					colorA: {type:'c', value: new THREE.Color(0xffffff)},
					colorB: {type:'c', value: new THREE.Color(0xffffff)},
					colorC: {type:'c', value: new THREE.Color(0xffffff)},


					tNoise: {type:'t', value: Utils.whiteTexture},
					noisepc: {type:'f', value:1.0},
					noiseOffset: {type:'v2', value: new THREE.Vector2(0,0)},
					noiseScale: {type:'v2', value: new THREE.Vector2(1, 1)},
					noiseRotation: {type:'m2', value: {elements: new Float32Array(4)}}
				},
				transparent: false,
				depthTest: false,
				depthWrite: false,
				blending: THREE.NoBlending,
				side: THREE.DoubleSide
			}));


	};

	this.addMaterial = function(id, mat) {
		if (this.materials[id]) return;

		this.materials[id] = {};
		this.materials[id] = mat;
		this.materials[id].compiled = false;

		var definesId = JSON.stringify(mat.defines||{});
		this.materialDefines[id] = this.materialDefines[id]||{};
		this.materialDefines[id][definesId] = this.materialDefines[id][definesId]||{};
		this.materialDefines[id][definesId] = mat;
	};

	this.getMaterial = function(id, defines) {

		var mat = null;

		if (defines) {
			var definesId = JSON.stringify(defines||{});
			if (this.materials[id] && this.materialDefines[id]) {
				if (!this.materialDefines[id][definesId]) {
					this.materialDefines[id][definesId] = this.materials[id].clone();
					this.materialDefines[id][definesId].defines = defines;
					this.materialDefines[id][definesId].needsUpdate = true;
				}
				if (!this.materialDefines[id][definesId].compiled) {
					this.materialDefines[id][definesId].compiled = true;
					Utils.compileMaterial(this.materialDefines[id][definesId]);
				}
			}

			mat = this.materialDefines[id][definesId].clone();
		} else {
			if (this.materials[id] && this.materials[id] && !this.materials[id].compiled) {
				this.materials[id].compiled = true;
				Utils.compileMaterial(this.materials[id]);
			}

			mat = this.materials[id].clone();
		}
		
		for (var o in mat.uniforms) {
			switch (mat.uniforms[o].type) {
				case 't':
					mat.uniforms[o].value = Utils.whiteTexture;
					break;

				case 'v2':
					mat.uniforms[o].value = mat.uniforms[o].value.clone();
					break;

				case 'v3':
					mat.uniforms[o].value = mat.uniforms[o].value.clone();
					break;

				case 'v4':
					mat.uniforms[o].value = mat.uniforms[o].value.clone();
					break;

				case 'c':
					mat.uniforms[o].value = mat.uniforms[o].value.clone();
					break;

				case 'm2':
					mat.uniforms[o].value = {elements: new Float32Array(4)};
					break;

				case 'm3':
					mat.uniforms[o].value = mat.uniforms[o].value.clone();
					break;

				case 'm4':
					mat.uniforms[o].value = mat.uniforms[o].value.clone();
					break;
			}
		}
		return mat;
	};

	this.disposeMaterial = function() {

	};


};

export default new MaterialController();