import * as THREE from 'three';


var Utils = {};

Utils.renderTexture = function(texture, renderTarget, clear) {

	renderer.setRenderTarget(renderTarget);
	if (clear) renderer.clear();

	Utils.defaultMaterial.map = texture;
	Utils.defaultPlane.material = Utils.defaultMaterial;
	renderer.render(Utils.defaultScene, Utils.orthographicCamera, renderTarget, clear);
	if (renderTarget) renderTarget.isActive = true;
	
	renderer.setRenderTarget();
};


Utils.renderTextureFlip = function(texture, renderTarget, clear) {

	renderer.setRenderTarget(renderTarget);
	if (clear) renderer.clear();

	Utils.defaultMaterial.map = texture;
	Utils.defaultPlane.material = Utils.defaultMaterial;
	Utils.defaultPlane.scale.x = -1;
	renderer.render(Utils.defaultScene, Utils.orthographicCamera, renderTarget, clear);
	if (renderTarget) renderTarget.isActive = true;
	Utils.defaultPlane.scale.x = 1;
	renderer.setRenderTarget();
};
Utils.renderTextureFlipInv = function(texture, renderTarget, clear) {

	renderer.setRenderTarget(renderTarget);
	if (clear) renderer.clear();

	Utils.defaultMaterial.map = texture;
	Utils.defaultPlane.material = Utils.defaultMaterial;
	Utils.defaultPlane.scale.x = -1;
	Utils.defaultPlane.scale.y = -1;
	renderer.render(Utils.defaultScene, Utils.orthographicCamera, renderTarget, clear);
	if (renderTarget) renderTarget.isActive = true;
	Utils.defaultPlane.scale.x = 1;
	Utils.defaultPlane.scale.y = 1;
	renderer.setRenderTarget();
};

Utils.renderTextureInv = function(texture, renderTarget, clear) {

	renderer.setRenderTarget(renderTarget);
	if (clear) renderer.clear();

	Utils.defaultMaterial.map = texture;
	Utils.defaultPlane.material = Utils.defaultMaterial;
	Utils.defaultPlane.scale.y = -1;
	renderer.render(Utils.defaultScene, Utils.orthographicCamera, renderTarget, clear);
	if (renderTarget) renderTarget.isActive = true;
	Utils.defaultPlane.scale.y = 1;
	renderer.setRenderTarget();
};


Utils.renderTextureAlpha = function(texture, renderTarget, clear) {

	renderer.setRenderTarget(renderTarget);
	if (clear) renderer.clear();

	Utils.defaultMaterialAlpha.map = texture;
	Utils.defaultPlane.material = Utils.defaultMaterialAlpha;
	
	renderer.render(Utils.defaultScene, Utils.orthographicCamera, renderTarget, clear);
	if (renderTarget) renderTarget.isActive = true;
	renderer.setRenderTarget();
};

Utils.compileMaterial = function(material) {
	Utils.defaultPlane.material = material;
	renderer.render(Utils.defaultScene, Utils.orthographicCamera, Utils.compileFbo, false);
};

Utils.renderMaterial = function(material, renderTarget, clear) {
	renderer.setRenderTarget(renderTarget);
	if (clear) renderer.clear();
	Utils.defaultPlane.material = material;
	renderer.render(Utils.defaultScene, Utils.orthographicCamera, renderTarget, clear);
	if (renderTarget) renderTarget.isActive = true;
};


Utils.renderMaterialFS = function(width, height, material, renderTarget, clear) {
	renderer.setRenderTarget(renderTarget);
	if (clear) renderer.clear();
	Utils.defaultPlane.material = material;

	Utils.defaultPlane.scale.set((renderer.getSize().height/renderer.getSize().width)/(height/width), 1.0, 1.0);
	renderer.render(Utils.defaultScene, Utils.orthographicCamera, renderTarget, clear);
	if (renderTarget) renderTarget.isActive = true;
	Utils.defaultPlane.scale.set(1,1,1);
};

Utils.renderMaterialNoCrop = function(width, height, material, renderTarget, clear) {
	renderer.setRenderTarget(renderTarget);
	if (clear) renderer.clear();
	Utils.defaultPlane.material = material;


	Utils.defaultPlane.scale.set(1.0, (renderer.getSize().width/renderer.getSize().height)/(width/height), 1.0);
	renderer.render(Utils.defaultScene, Utils.orthographicCamera, renderTarget, clear);
	if (renderTarget) renderTarget.isActive = true;
	Utils.defaultPlane.scale.set(1,1,1);
};


Utils.clearFboAlpha = function(target) {

	renderer.setRenderTarget(target);
	renderer.setClearColor(Utils.blackColor, 0);
	renderer.clear(true,true,true);
	renderer.setRenderTarget();
	target.isActive = false;
};



Utils.renderMesh = function(mesh, camera, renderTarget, clear) {

	renderer.setRenderTarget(renderTarget);
	if (clear) renderer.clear();

	if (mesh.geometry.needsUpdate || mesh.material.needsUpdate) {
		
		var oldScene = mesh.parent;
		if (oldScene) oldScene.remove(mesh);
		Utils.tmpScene.add(mesh);
		renderer.render(Utils.tmpScene, camera, renderTarget, clear);
		mesh.geometry.needsUpdate = false;
		Utils.tmpScene.remove(mesh);
		if (oldScene) oldScene.add(mesh);
	
	} else {

		renderer.renderBufferDirect(camera, null, mesh.geometry, mesh.material, mesh, null);

	}

	// this.renderBufferDirect = function ( camera, fog, geometry, material, object, group ) 

	/*
	PerspectiveCamera {uuid: "EE8B587D-D906-4412-84CE-19334CF5640B", name: "", type: "PerspectiveCamera", parent: null, children: Array(0)…}
	null
	BufferGeometry {uuid: "9ADCE1FB-9626-46A1-80E0-9C1418C599CA", name: "", type: "BufferGeometry", index: BufferAttribute, attributes: Object…}
	RawShaderMaterial {uuid: "B84B7DB6-9DDA-4D8A-9B5F-7FD8C09BF891", name: "", type: "RawShaderMaterial", fog: false, lights: false…}
	Mesh {uuid: "107EA156-5754-4D92-9828-6EA52CAFBC12", name: "", type: "Mesh", parent: Scene, children: Array(0)…}
	null
	*/
};



Utils.autoReloadShaderManual = function(material, vert, frag, extras) {
	//if (P2.RELEASE || P2.HOSTED) return;
	//window.setInterval(function() {
	material.lastReload = material.lastReload || 0;

	if (performance.now() - material.lastReload >= 1000) {
		material.lastReload = performance.now();

		var loader = new LoaderXHR(frag, 'text');
		loader.start(function() {
			var diff = false;

			if (material.fragmentShader !== (extras||'')+loader.data.value) diff = true;
			material.fragmentShader = (extras||'')+loader.data.value;
			if (vert) {
				var loader2 = new LoaderXHR(vert, 'text');
				loader2.start(function() {
					if (material.vertexShader !== loader2.data.value) diff = true;
					material.vertexShader = loader2.data.value;
					material.needsUpdate = diff;
				},console.error);
			} else {
				material.needsUpdate = diff;
			}
		},console.error);
	}
};


Utils.timeSyncBuffer = new Uint8Array(4);
Utils.time3d = function(name) {
	var gl = renderer.getContext();
	renderer.setRenderTarget( null );
	gl.readPixels(0,0,1,1,gl.RGBA,gl.UNSIGNED_BYTE, Utils.timeSyncBuffer);
	Utils.time(name);
};
Utils.time3dEnd = Utils.timeEnd3d = function(name) {
	var gl = renderer.getContext();
	renderer.setRenderTarget( null )
	gl.readPixels(0,0,1,1,gl.RGBA,gl.UNSIGNED_BYTE, Utils.timeSyncBuffer);
	Utils.timeEnd(name);
}


//Color
Utils.colorPalette = ['#ffffff'];
Utils.getRandomColor = function() {
	return '#'+Utils.colorPalette[Math.floor(Math.random()*Utils.colorPalette.length)];
};

//
// Create a texture with the given params
//
Utils.createTextureAutoDispose = function(img, format, mipmap, repeat) {
	var tex = new THREE.Texture(img);
	tex.format = format;
	tex.type = THREE.UnsignedByteType;
	tex.wrapT = tex.wrapS = repeat ? THREE.MirroredRepeatWrapping : THREE.ClampToEdgeWrapping;
	tex.magFilter = THREE.LinearFilter;
	tex.minFilter = mipmap ? THREE.LinearMipMapLinearFilter : THREE.LinearFilter;
	tex.generateMipmaps = mipmap;
	tex.anisotropy = 1; //window.renderer.getMaxAnisotropy() || 1;
	return tex;
};


Utils.createTexture = function(img, format, repeat, mipmap) {
	var tex = new THREE.Texture(img);
	tex.format = format;
	tex.type = THREE.UnsignedByteType;
	tex.wrapT = tex.wrapS = repeat ? THREE.MirroredRepeatWrapping : THREE.ClampToEdgeWrapping;
	tex.magFilter = THREE.LinearFilter;
	tex.minFilter = mipmap ? THREE.LinearMipMapLinearFilter : THREE.LinearFilter;
	tex.generateMipmaps = mipmap;
	tex.anisotropy = 1;
	tex.needsUpdate = true;
	return tex;
};

Utils.createTextureCropped = function(imgs, format, repeat, mipmap) {
	var tex = new THREE.WebGLRenderTarget(2048,2048);
	tex.image = imgs;
	tex.antialias = true;
    tex.depthBuffer = false;
    tex.stencilBuffer = false;
    tex.alpha = false;
    tex.format = (format == THREE.RGBFormat || format == THREE.RGBAFormat) ? format : THREE.RGBFormat;
	tex.type = THREE.UnsignedByteType;
	tex.wrapT = tex.wrapS = repeat ? THREE.MirroredRepeatWrapping : THREE.ClampToEdgeWrapping;
	tex.magFilter = THREE.LinearFilter;
	tex.minFilter = mipmap ? THREE.LinearMipMapLinearFilter : THREE.LinearFilter;
	tex.generateMipmaps = mipmap;
	tex.anisotropy = 1;
	return tex;
};

Utils.createCompressedTextureSimple = function(img, format, repeat, mipmap) {
	var tex = new THREE.CompressedTexture(img);
	tex.format = THREE.RGBFormat;
	tex.type = THREE.UnsignedByteType;
	tex.wrapT = tex.wrapS = THREE.ClampToEdgeWrapping;
	tex.magFilter = THREE.LinearFilter;
	tex.minFilter = mipmap ? THREE.LinearFilter : THREE.LinearFilter;
	tex.generateMipmaps = mipmap;
	tex.anisotropy = 1;
	tex.needsUpdate = true;
	return tex;
};

Utils.createCompressedTexture = function(image, format, repeat, mipmap) {
	var texture = new THREE.CompressedTexture();
	if (image) {
		texture.image = image;
		texture.mipmaps = image.mipmaps;
		texture.mipmaps[0].format = THREE.RGBA_S3TC_DXT5_Format;
	}
	texture.format = THREE.RGBA_S3TC_DXT5_Format;
	texture.magFilter = THREE.LinearFilter;
	texture.minFilter = mipmap ? THREE.LinearMipMapLinearFilter : THREE.LinearFilter;
	texture.wrapT = texture.wrapS = repeat ? THREE.MirroredRepeatWrapping : THREE.ClampToEdgeWrapping;
	texture.generateMipmaps = mipmap;
	texture.anisotropy = 1;
	// texture.onUpdate = function() {
	// 	if (!this.image) return;
	// 	this.mipmaps = this.image.mipmaps;
	// 	this.mipmaps[0].format = THREE.RGBA_S3TC_DXT5_Format;
	// };
	texture.needsUpdate = true;
	return texture;
};

THREE.Quaternion.prototype.lookAt = function(v) {
	var m = new THREE.Matrix4();
	m.lookAt(v, new THREE.Vector3(0,0,0), new THREE.Vector3(0,1,0));
	return this.setFromRotationMatrix(m);
	//return this;
};



//
// Extend THREE.js math
//
THREE.Object3D.prototype.billboard = function(target) {
	var a = target.x - this.position.x,
		b = target.z - this.position.z,
		angle = Math.atan2(a,b);
	this.rotation.y = angle;
	this.rotation.x = this.rotation.z = 0.0;
};
THREE.Vector3.prototype.setX = function(v) {
	this.x = v;
	return this;
};
THREE.Vector3.prototype.setY = function(v) {
	this.y = v;
	return this;
};
THREE.Vector3.prototype.setZ = function(v) {
	this.z = v;
	return this;
};
THREE.Vector3.prototype.xz = function(v) {
	return new THREE.Vector2(this.x, this.z);
};
THREE.Vector3.prototype.distance2d = function(v) {
	var a = this.x-v.x, b = this.z-v.z;
	return Math.sqrt(a*a + b*b);
};

THREE.Vector3.prototype.angleLerp = function(v,pc) {

	this.x -= Utils.distanceBetweenAngles(v.x,this.x) * Utils.directionBetweenAngles(v.x, this.x) * pc;
	this.y -= Utils.distanceBetweenAngles(v.y,this.y) * Utils.directionBetweenAngles(v.y, this.y) * pc;
	this.z -= Utils.distanceBetweenAngles(v.z,this.z) * Utils.directionBetweenAngles(v.z, this.z) * pc;

	this.x = Utils.normalizeAngle(this.x);
	this.y = Utils.normalizeAngle(this.y);
	this.z = Utils.normalizeAngle(this.z);

	return this;
};

THREE.Matrix3.prototype.makeRotation = function(angle) {
	this.set(
		Math.cos(angle),  Math.sin(angle),  0.0,
    	-Math.sin(angle),  Math.cos(angle),  0.0,
    	 0.0,      0.0,  1.0
	);
};
THREE.BufferGeometry.prototype.getVertex = function(id) {
	var ar = this.getAttribute('position').array;
	return new THREE.Vector3(ar[id*3+0],ar[id*3+1],ar[id*3+2]);
};


//let's not create new vector3 every frame
Utils.zeroVector = new THREE.Vector3(0,0,0);
Utils.upVector = new THREE.Vector3(0,1,0);
Utils.tmpVector = new THREE.Vector3(0,0,0);
Utils.tmpVector2 = new THREE.Vector3(0,0,0);
Utils.tmpEuler = new THREE.Euler(0,0,0, 'XYZ');
Utils.tmpQuaternion = new THREE.Quaternion();
Utils.tmpMatrix = new THREE.Matrix4();

Utils.sphereGeometrySmall = new THREE.SphereBufferGeometry(1,12,12);
Utils.sphereGeometry = new THREE.SphereBufferGeometry(1,32,32);
Utils.planeGeometry = new THREE.PlaneBufferGeometry(1,1,1,1);
Utils.planeGeometryBottom = new THREE.PlaneBufferGeometry(1,1,1,1);
Utils.planeGeometryBottom.applyMatrix(new THREE.Matrix4().makeTranslation(0.0,0.5,0.0));
Utils.planeGeometryFace= new THREE.PlaneBufferGeometry(1,1,1,1);
Utils.planeGeometryFace.applyMatrix(new THREE.Matrix4().makeTranslation(0.0,0.3,0.0));
Utils.planeGeometryFaceInv= new THREE.PlaneBufferGeometry(1,1,1,1);
Utils.planeGeometryFaceInv.applyMatrix(new THREE.Matrix4().makeTranslation(0.0,-0.25,0.0));
Utils.orthographicCamera = new THREE.OrthographicCamera( -0.5, 0.5, 0.5, -0.5, -1, 1 );
Utils.topLeftCamera = new THREE.OrthographicCamera( 0, 1, 0, 1, -1, 1 );
Utils.perspectiveCamera = new THREE.PerspectiveCamera( 55, 1, 0.0001, 10000.0 );
Utils.planeGeometryCorner = new THREE.PlaneBufferGeometry(1,1,1,1);
Utils.planeGeometryCorner.applyMatrix(new THREE.Matrix4().makeTranslation(0.5,0.5,0.0));

Utils.defaultPlane = new THREE.Mesh(Utils.planeGeometry, null);
Utils.defaultScene = new THREE.Scene();
Utils.defaultScene.add(Utils.defaultPlane);
Utils.tmpScene = new THREE.Scene();

Utils.tmpVectors = [];
for (var i=0; i<12; i++) {
	Utils.tmpVectors[i] = new THREE.Vector3(0,0,0);
}

//
// Materials & Blend Modes
//
Utils.setDefine = function(material, define, value, force) {
	if (material.defines[define] === value) return false;
	material.defines[define] = value;
	material.needsUpdate = true;
	return true
};
Utils.BLEND_MODES = Utils.BLENDMODES = {
	"NONE":1,
	"NORMAL":2,
	"LIGHTEN":3,
	"DARKEN":4,
	"MULTIPLY":5,
	"AVERAGE":6,
	"ADD":7,
	"SUBSTRACT":8,
	"ADDSUBSTRACT":9,
	"OVERLAY":10,
	"SCREEN":11,
	"SOFTLIGHT":12,
	"HARDLIGHT":13,
	"DIFFERENCE":14,
	"LUMINOSITY":15,
	"REFLECT":16,
	"GLOW":17,
	"COLOR":18,
	"HUE":19,
	"SATURATION":20,
	"MASK": 21
};


//
// Clone material with only uniform values
//
Utils.cloneMaterial = function(mat) {
	var m2 = mat.clone();
	if (mat.uniforms) {
		m2.uniforms = {};
		for (var o in mat.uniforms) {
			m2.uniforms[o] = {type:mat.uniforms[o].type, value:mat.uniforms[o].value};
			if (mat.uniforms[o].value && mat.uniforms[o].value.clone) m2.uniforms[o].value = mat.uniforms[o].value.clone();
		}			
	}
	if (mat.defines) {
		m2.defines = {};
		for (var o in mat.defines) {
			m2.defines[o] = mat.defines[o];
		}
	}
	return m2;		
};


Utils.emptyCanvas = document.createElement('canvas');
if (Utils.emptyCanvas) {
	Utils.emptyCanvas.width = Utils.emptyCanvas.height = 4;
}
Utils.blackTexture = Utils.createTexture(Utils.emptyCanvas, THREE.LuminanceFormat, false, false);
Utils.blackTexture.needsUpdate = !!Utils.emptyCanvas;

Utils.emptyTexture = Utils.emptyTextureAlpha = Utils.createTexture(Utils.emptyCanvas, THREE.RGBAFormat, false, false);
Utils.emptyTextureAlpha.needsUpdate = !!Utils.emptyCanvas;

Utils.blackColor = new THREE.Color(0);
Utils.whiteColor = new THREE.Color(0xffffff);


Utils.compileFbo = new THREE.WebGLRenderTarget(4,4);

Utils.whiteCanvas = document.createElement('canvas');
if (Utils.whiteCanvas) {
	Utils.whiteCanvas.width = Utils.whiteCanvas.height = 4;
	var ctx=Utils.whiteCanvas.getContext("2d");
	ctx.fillStyle = '#ffffff';
	ctx.fillRect(0,0,Utils.whiteCanvas.width,Utils.whiteCanvas.height);
}
Utils.whiteTexture = Utils.createTexture(Utils.whiteCanvas, THREE.LuminanceFormat, false, false);
Utils.whiteTexture.needsUpdate = !!Utils.whiteCanvas;

Utils.defaultMaterialAlpha = new THREE.MeshBasicMaterial({map: Utils.emptyTexture, transparent: true, blending: THREE.NormalBlending, depthTest: false, depthWrite:false, side: THREE.DoubleSide });
Utils.defaultMaterial = new THREE.MeshBasicMaterial({map: Utils.emptyTexture, transparent: true, blending: THREE.NoBlending, depthTest: false, depthWrite:false, side: THREE.DoubleSide });
Utils.emptyMaterial = new THREE.MeshBasicMaterial({transparent: false, blending: THREE.NoBlending, depthTest: false, depthWrite:false, side: THREE.DoubleSide, color: 0});

Utils.clearScene = new THREE.Scene();
Utils.clearPlane = new THREE.Mesh(Utils.planeGeometry, new THREE.MeshBasicMaterial({color:new THREE.Color(0xffffff), transparent: false, blending: THREE.NoBlending, side: THREE.DoubleSide, depthTest: false, depthWrite: false}));
Utils.clearScene.add(Utils.clearPlane);
Utils.tmpColor = new THREE.Color(0xffffff);
Utils.colorIsBlack = function(col) {
	Utils.tmpColor.set(col);
	return Utils.tmpColor.r + Utils.tmpColor.g + Utils.tmpColor.b <= 0;
};

/**
 * @author zz85 / http://www.lab4games.net/zz85/blog
 */
Utils.interpolateCurve = function interpolateCurve( p0, p1, p2, p3, t ) {
	var v0 = ( p2 - p0 ) * 0.5;
	var v1 = ( p3 - p1 ) * 0.5;
	var t2 = t * t;
	var t3 = t * t2;
	return ( 2 * p1 - 2 * p2 + v0 + v1 ) * t3 + ( - 3 * p1 + 3 * p2 - 2 * v0 - v1 ) * t2 + v0 * t + p1;
};

export default Utils;
