import * as THREE from 'three';
import SETTINGS from '../controllers/Settings.js';

import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import RendererController from '../controllers/RendererController.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';

import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import SpritesheetVideo from './SpritesheetVideo.js';

//------------------------
class SpritesheetVideoDebugText extends SpritesheetVideo {
	constructor(_info, batchName) {
		super(_info, batchName);

		this.textDebugShown = false;
		this.textDebugScene = new THREE.Scene();
		this.textDebugPlane = null;
		this.textDebugCanvas = null;
		this.textDebugContext = null;
		this.textDebugTexture = null;
	}

	preload(batchName) {
		super.preload(batchName);
	}

	prepare() {
		super.prepare();
	}

	update(opt) {
		super.update(opt);
		if (this.isReady() && this.analysis && !this.textDebugShown && this.contentDiv) {
			this.textDebugShown = true;

			var classesText = '';
			for (var i=0; i<Math.min(this.analysis.categories.length, 5); i++) {
				classesText += this.analysis.categories[i] +' - '+this.analysis.confidences[i].toPrecision(3)+'<br>';
			}
			if (!classesText) {
				classesText = 'Nothing';
			}

			var dc = document.createElement('div');
			$(dc).addClass('spritesheet-overlay-text-container');
			$(dc).css('background', 'none');
			$(this.contentDiv).append(dc);

			var d = document.createElement('div');
			$(d).addClass('spritesheet-overlay-text');
			$(d).css('color', 'white');
			$(d).css('font-size', '0.75em');
			$(d).css('text-shadow', 'black 1px 1px');
			$(dc).append(d);

			// $(d).html(this.params.faces.text||'');
			this.debugDivs = {
				container: dc,
				div:d,
				classid: null,
				visible: false,
				fontSize: 0,
				text: null
			};

			var renderWidth = renderer.getSize().width,
				renderHeight = renderer.getSize().height;
			if (SETTINGS.STORYBOARD_MODE) {
				renderWidth = 162;
				renderHeight = 288;
			}


			$(this.debugDivs.div).html(classesText);
			$(this.debugDivs.div).css('font-size', '0.75em');
			$(this.debugDivs.container).css({
				// 'transform': 'translate('+((p.position.x)*renderWidth - w/2)+'px,'+((p.position.y)*renderHeight - h/2)+'px)',
				'width': renderWidth+'px',
				'height': renderHeight+'px'
			});
			$(dc).css({
				// 'transform': 'translate('+((p.position.x)*renderWidth - w/2)+'px,'+((p.position.y)*renderHeight - h/2)+'px)',
				'width': renderWidth+'px',
				'height': renderHeight+'px'
			});



			// var p = new THREE.Mesh(Utils.planeGeometryCorner, MaterialController.getMaterial('invertedTextureTransparent') );
			// p.material.uniforms.tint.value.set(0xffffff);
			// this.textDebugScene.add(p);


			// var canvas = document.createElement('canvas');
			// var c = canvas.getContext('2d', {alpha: false});

			// var t = new THREE.Texture(canvas);
			// t.format = THREE.RGBAFormat;
			// t.type = THREE.UnsignedByteType;
			// t.wrapT = t.wrapS = THREE.ClampToEdgeWrapping;
			// t.magFilter = THREE.LinearFilter;
			// t.minFilter = THREE.LinearFilter;
			// t.generateMipmaps = false;
			// t.anisotropy = 3;
			// t.needsUpdate = true;
			// t.flipY = true;
			// p.material.uniforms.tDiffuse.value = t;
			// // p.material.needsUpdate = true;
			
			// canvas.width = Math.floor(this.videoWidth);
			// canvas.height = Math.floor(this.videoHeight);

			// c.clearRect(0,0,canvas.width,canvas.height);
			// c.fillStyle = 'white';
			// c.strokeStyle = 'white';
			// c.textAlign = "center"; 
			// c.textBaseline = "middle";

			// c.font = (this.videoWidth/16)+'px Open Sans';	
			// c.shadowColor = '#000000';
			// c.shadowBlur = 4;
			// c.shadowOffsetX = 0;
			// c.shadowOffsetY = 0;
			
			// // c.strokeText(this.analysis.classes[currentFrame+this.frameOffset][i]||'class', canvas.width/2, canvas.height/2, canvas.width, canvas.height);
			// // c.fillText(this.analysis.classes[currentFrame+this.frameOffset][i]||'class', canvas.width/2, canvas.height/2, canvas.width, canvas.height);
			// console.log(this.videoWidth);
			// CanvasTextWrapper(canvas, classesText, {
			// 	font: (this.videoWidth/16)+'px Open Sans',
		 //        lineHeight: 1,
		 //        textAlign: 'center',
		 //        verticalAlign: 'middle',
		 //        paddingX: this.videoWidth*0.1,
		 //        paddingY: this.videoHeight*0.1,
		 //        fitParent: false,
		 //        lineBreak: 'auto',
		 //        strokeText: true,
		 //        sizeToFill: true,
		 //        maxFontSizeToFill: (this.videoWidth/16),
		 //        allowNewLine: true,
		 //        justifyLines: false,
		 //        renderHDPI: false,
		 //        textDecoration: 'none'
			// });



		}
	}

	dispose() {
		super.dispose();
		this.debugDivShown = false;

	}
	stop() {
		super.stop();
		if (this.debugDivs) {
			$(this.debugDivs.container).remove();
			$(this.debugDivs.div).remove();
		}
		this.debugDivShown = false;
		this.debugDivs = null;
	}


	render() {
		super.render();
		renderer.render(this.textDebugScene, Utils.topLeftCamera, this.fbo.texture, false);
	}
};

export default SpritesheetVideoDebugText;


