import SETTINGS from "../controllers/Settings.js"

function MultiStorage() {

	this.testDone = false;
	this.localStorageEnabled = false;
	this.state_lng_suffix = '';

	this.testStorage = function() {
		if (this.testDone) return this.localStorageEnabled;
		this.testDone = true;
		// if (window.localStorage) {
		try 
	    {
	        window.localStorage.setItem("localstorage-test", '1');
	        window.localStorage.removeItem("localstorage-test");
	        this.localStorageEnabled = true;
	    }  catch (error)  {}
		// }
		return this.localStorageEnabled;
	};
	
	this.setup = function(id) {
		if (SETTINGS.LANGUAGE=='fr') this.state_lng_suffix = '_fr';
		if (!this.testDone) this.testStorage();

		//clear cache
		if (this.localStorageEnabled) {
			if (/nosave|nocache|clearcache|clear/.test(window.location.href)) {
				window.oneAlert = true;
				window.localStorage.setItem("global_state", JSON.stringify({}));
				window.localStorage.setItem("state_"+SETTINGS.tasklist_id_json, JSON.stringify({}));
				window.localStorage.clear();
				var nl = window.location.href.toString().replace(/&nosave|&nocache|&clearcache|&clear/gi, '');
				nl = nl.replace(/nosave|nocache|clearcache|clear/gi, '');
				if (!/alwaysclear/.test(window.location.href)) {
					window.location.href = nl;
					return;
				}
			}

			// if (!window.localStorage.getItem('version') || parseFloat(window.localStorage.getItem('version'),10) < SETTINGS.VERSION ) {
			// 	window.localStorage.clear();
			// }
			window.localStorage.setItem('version', SETTINGS.VERSION);
		}
	};


	//
	// set global state
	//
	this.setGlobalState = function(state) {
		if (!this.localStorageEnabled) {window.tmpGlobalState = state; return};
		// console.log(JSON.stringify(state));
		if (!state || (state && !state.userVideos)) {
			console.warn("No global state");
		}
		window.localStorage.setItem("global_state", JSON.stringify(state||{}));
	};

	//
	// get global saved state
	//
	this.getGlobalState = function() {
		if (!this.localStorageEnabled) return window.tmpGlobalState||{};
		var n = window.localStorage.getItem("global_state");
		return n ? JSON.parse(n) : {};
	};


	//
	// set state
	//
	this.setState = function(state) {
		if (!this.localStorageEnabled) {window.tmpLocalState = state; return;};
		// console.log(JSON.stringify(state));
		window.localStorage.setItem("state_"+SETTINGS.tasklist_id_json, JSON.stringify(state));
	};

	//
	// get saved state
	//
	this.getState = function() {
		if (!this.localStorageEnabled) return window.tmpLocalState||{};
		var n = window.localStorage.getItem("state_"+SETTINGS.tasklist_id_json);
		return n ? JSON.parse(n) : {};
	};

	// window.localStorage.setItem(
	// var storedVar = window.localStorage.getItem("videos_"+clickCode+'_variation');

}
window.MultiStorage = window.MultiStorage || new MultiStorage();
export default window.MultiStorage;
