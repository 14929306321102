import * as THREE from 'three';
import SETTINGS from '../controllers/Settings.js';

import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import RendererController from '../controllers/RendererController.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';

import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import SpritesheetVideo from './SpritesheetVideo.js';

// var CanvasTextWrapper = require('canvas-text-wrapper').CanvasTextWrapper;

//------------------------
class SpritesheetVideoDebugPoses extends SpritesheetVideo {
	constructor(_info, batchName) {
		super(_info, batchName);
		this.boxesScene = new THREE.Scene();
		this.boxes = [];
	}

	preload(batchName) {
		super.preload(batchName);
	}

	prepare() {
		super.prepare();
	}

	onAnalysisLoaded(info) {
		super.onAnalysisLoaded(info);
	}

	update(opt) {
		super.update(opt);

		if (this.isReady() && this.analysis) {

			var currentFrame = this.lastFrame;
			var ci = 0;

			var poses = this.analysis.result[currentFrame+this.frameOffset];
			console.log(poses);
			if (poses && poses.length) {
				for (var i=0; i<poses.length; i++) {
					ci++;
					if (!this.boxes[i]) {
						var boxes_t = []
						for (var j=0; j<18; j++) {
							var p = new THREE.Mesh(Utils.planeGeometry, SpritesheetVideo.whitePlaneMaterial.clone() );
							// p.material.uniforms.tint.value.set(0xff0000);
							this.boxesScene.add(p);
							boxes_t.push(p);
						}
						this.boxes.push(boxes_t);
					}
					
					for (var j=0; j<18; j++) {
						var bone = poses[i][j];
						var p = this.boxes[i][j]
						if (bone) {
							p.scale.set(0.05,0.05,1.0);
							p.position.set(bone.x,bone.y, 0);
							p.visible = true;
						} else {
							p.visible = false;
						}
					}
				}
			}
			for (var i=ci; i<this.boxes.length; i++) {
				for (var j=0; j<18; j++) {
					this.boxes[i][j].visible = false;
				}
			}
		}
	}

	dispose() {
		super.dispose();


	}

	render() {
		super.render();
		renderer.render(this.boxesScene, Utils.topLeftCamera, this.fbo.texture, false);

	}
};

export default SpritesheetVideoDebugPoses;


