function getBranchTasks(_info) {
	var branches = [];
	for (var condition in _info) {
		if (/\//gi.test(condition)) {//condition !== "default") { //
			var condition_arr = condition.split('/');
			var branchInfo = {
				"condition": condition_arr[0],
				"condition_id": condition_arr[1],
				"skip": _info[condition]=='skip'
			};
			if (typeof _info[condition] == 'object' || typeof _info[condition]=='array') branchInfo.tasks = [].concat(_info[condition]);
			branches.push(branchInfo);
		}
	}

	if (_info['default'] && _info['default'] !== 'wait') {
		var branchInfo = {
			"condition": 'default',
			"condition_id": 'default',
			"skip": _info['default']=='skip'
		};
		if (typeof _info['default'] == 'object' || typeof _info['default']=='array') branchInfo.tasks = [].concat(_info['default']);
		if (!branchInfo.tasks || (branchInfo.tasks && !branchInfo.tasks.length==0)) branchInfo.condition_id = 'skip'; else branchInfo.condition_id = 'tasks';
		branches.push(branchInfo);

	} else if (branches.length == 0) {
		var branchInfo = {
			"condition": 'default',
			"condition_id": 'skip',
			"skip": true,
			"tasks": []
		};
		branches.push(branchInfo);
	}
	return branches;
};

export default getBranchTasks;