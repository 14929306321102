import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';
import Fbo from '../utils/Fbo.js'
import RendererController from '../controllers/RendererController.js';
import MultiStorage from '../utils/MultiStorage.js';

import MontageController from '../controllers/MontageController.js';
import Montage from '../objects/Montage.js';
import SpritesheetVideo from '../objects/SpritesheetVideo.js';
import MaterialController from '../controllers/MaterialController.js';;


import MottoView from './MottoView.js';
import MontageView from './MontageView.js'

//------------------------

//------------------------
// 
//  Base class for all views
//
//-------------------------
class MirrorMontageView extends MontageView {

	constructor(_info, batchName) {
		super(_info, batchName);
		
		
	}


	//extend material with mirroring
	start() {
		if (this.started) return;
		super.start();
		this.playbackMaterial = MaterialController.getMaterial('mirror');
		this.plane.material = this.playbackMaterial;
		this.playbackMaterial.uniforms.tDiffuse.value = Utils.blackTexture;
	}
};

export default MirrorMontageView;

