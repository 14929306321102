import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';
import Fbo from '../utils/Fbo.js'
import RendererController from '../controllers/RendererController.js';
import MultiStorage from '../utils/MultiStorage.js';
import CameraController from '../controllers/CameraController.js'

import MottoView from './MottoView.js';

class CameraRefusedView extends MottoView {

	constructor(_info, batchName, doneCallback) {
		super(_info, batchName);
		this.doneCallback = doneCallback;
		this.isCameraRefused = true;
	}

	activate() {
		if (this.active) return;
		this.info.textType = 'center';
		super.activate();
		

		//
		// First reload
		//
		var globalState = MultiStorage.getGlobalState();
		var firstReload = true;
		if (SETTINGS.isIOS) {
			globalState.cameraAttempts = globalState.cameraAttempts||0;
			globalState.cameraAttempts++;
			firstReload = globalState.cameraAttempts<2;
			MultiStorage.setGlobalState(globalState);
			if (firstReload) {
				window.location.reload();
			}
		}


		this.lockRight = this.lockLeft = true;

		if (SETTINGS.isIOS) {
			if (firstReload) $(this.textDiv).html(this.info.textReload);
			else $(this.textDiv).html(this.info.textIos);
		} else if (SETTINGS.isAndroid) {
			$(this.textDiv).html(this.info.textAndroid);
		} else {
			$(this.textDiv).html(this.info.textOthers);
		}
		$(this.pageCounterDiv).hide();
		$(this.textDiv).show();
	}

	update() {
		super.update();
		this.lockRight = this.lockLeft = true;

		if (CameraController.isReady() && CameraController.cameraAllowed) {
			this.lockRight = this.lockLeft = false;
			if (this.doneCallback) this.doneCallback();
			this.doneCallback = null;
		}
	}

	start() {
		if (this.started) return;
		this.info.textType = 'center';
		super.start();
		$(this.pageCounterDiv).hide();
	}
}

export default CameraRefusedView;	

