import * as THREE from 'three';

import FaceRenderer from './FaceRenderer.js'


import SETTINGS from '../controllers/Settings.js';
import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import RendererController from '../controllers/RendererController.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';
import CameraController from '../controllers/CameraController.js';

import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import SpritesheetVideo from './SpritesheetVideo.js';

//
// Handles spritesheet playback, material and rendering
//
class SpritesheetLivefeed {

	constructor() {
		this.currentTime = 0;
		this.lastTime = 0;
		this.currentFrame = 0;
		this.playing = false;
		this.videoSpeed = 1.0;
		this.lastFrame = -1;
		this.ratio = 16/9

		this.numFrames = 0;
		this.numFramesPerSpritesheet = 0;
		this.numSpritesheet = 0;
		this.numFramesX = 1;
		this.numFramesY = 1;
		this.frameRate = 24;
		this.palindrome = false;

		this.frames = [];

		this.binaryData = [];
		this.decodedImages = [];
		this.textures = [];
		this.blobURLs = [];
		this.childs = [];

		this.startOffset = 0;

		this.frameOffset = 0;
		this.loading = false;
		this.loaded = false;

		this.numDecoded = 0;
		this.decoded = false;
		this.decoding = false;
		this.isUser = false;
		this.isShowing = false;
		this.uuid = Utils.generateUUID();
		this.isPhoto = false;
		this.lastCameraTime = 0;
		this.lastUpdate = 0;

		//
		// Setup faces
		//
		this.textCanvas = [];
		this.textContext = [];
		this.textTexture = [];

		this.analysisLoading = false;
		this.analysisLoaded = false;

	}

	//setup frames & variables for playback
	setup(_params) {
		this.scene = new THREE.Scene();
		this.plane = new THREE.Mesh(Utils.planeGeometry, Utils.emptyMaterial);
		this.scene.add(this.plane);

		this.setParams(_params);
	}


	preload(batchName) {
		if (this.params && this.params.faces) {
			// this.faceRenderer = new FaceRenderer();
			// this.faceRenderer.isLive = true;
			// this.faceRenderer.setup(this.params);
			// this.faceRenderer.preload(batchName);
			FaceTracker.preload(batchName);
		}
	}


	//reset params of existing spritesheet
	setParams(_params) {
		this.params = _params;

		var montageParams = _params;
		this.params = {
			name: "livefeed",
			numFrames: 48,
			duration: 2.0,
			palindrome: false,
			numX: 1,
			numY: 1,
			videoWidth: 288,
			videoHeight: 512, 
			resolutionWidth: 288,
			resolutionHeight: 512,
			videoSpeed: 1.0,
			reverse: false,
			facesInfo: undefined,
			faces: undefined,
			noFaces: true,
			invertColors: false,
			beginAtZero: true,
			jpeg: undefined,
			mpeg: undefined,

			camera: montageParams.camera||'back',

			//effects
			faces: montageParams.faces||undefined,
			fill: montageParams.fill||undefined,
			padding: montageParams.padding||undefined,
			upsideDown: montageParams.upsideDown||undefined,
			noise: montageParams.noise||undefined,
			analysis: null,
			analysis_class: undefined,
			effect: null
		};
		if (montageParams.videoDuration !== undefined) {
			this.params.duration = montageParams.videoDuration;
			this.params.numFrames = Math.round(this.params.duration*24);
		}

		if (this.params.faces) {
			this.faceRenderer = new FaceRenderer();
			this.faceRenderer.isLive = true;
			this.faceRenderer.setup(this.params);
			// this.faceRenderer.preload(batchName);
			// FaceTracker.preload(batchName);
		}

		this.framesType = 'textures';
		this.frames = [];
		this.loaded = false;

		//set params
		this.name = this.params.name || "";
		this.numFrames = this.params.numFrames;
		this.numFramesX = this.params.numX;
		this.numFramesY = this.params.numY;

		this.numFramesPerSpritesheet = this.numFramesX * this.numFramesY;
		this.numSpritesheet = Math.ceil(this.params.numFrames / this.numFramesPerSpritesheet);

		this.videoSpeed = this.params.videoSpeed;
		this.frameRate = 24; //(this.params.numFrames/this.params.duration);
		this.palindrome = this.params.palindrome;

		this.videoWidth = 288*2;
		this.videoHeight = 512*2;
		if (this.fbo) this.fbo.resize(this.videoWidth, this.videoHeight);

		//faces preloading
		this.noFaces = true;
	}

	//FrameRecorder exporting spritesheets as high-res jpeg
	waitForExport() {
		this.waitingForExport = false;
	}

	exportDone(frames) {
	}
	
	//decode images if necessary
	prepare() {
		CameraController.start(this.params.camera || "back");
		if (this.faceRenderer) {
			this.faceRenderer.prepare();
			FaceTracker.activate();
		}
		return true;
	};

	onAnalysisLoaded(info) {
	};
	setLiveInfo(info) {
	}

	//waiting for loading & decoding
	isReady() {
		return CameraController.isReady(); // && (!this.loadingFaceInfo && !!this.facesInfo && this.facesInfo.loaded);
	};

	//dispose when done
	dispose() {
		if (this.fbo) {
			SpritesheetVideo.disposeFbo(this.fbo);
			this.fbo = null;
		}
		// this.frames = [];
		this.blobURLs = [];
		this.textures = [];
		this.decoding = false;
		this.decoded = false;
		this.waitingToPrepare = false;
		this.playing = false;
		this.currentTime = 0;
		this.lastFrame = -1;
		this.contentDiv = null;
		this.textDiv = null;

	};

	//playback
	play(contentDiv, textDiv) {
		this.lastTime = performance.now();
		this.currentTime = 0.0;
		this.playing = true;
		this.lastFrame = -1;
		this.contentDiv = contentDiv;
		this.textDiv = textDiv;

		this.fbo = SpritesheetVideo.getFbo(288*2, 512*2);
		if (this.faceRenderer) {
			FaceTracker.activate();
			this.faceRenderer.activate(this.contentDiv);
		}

		this.plane.material = this.playbackMaterial = MaterialController.getMaterial('playback');	
	};
	
	pause() {
		if (this.faceRenderer) this.faceRenderer.deactivate();
		this.lastTime = performance.now();
		this.playing = false;
	};
	stop() {
		this.lastTime = performance.now();
		this.playing = false;
		this.currentTime = 0;
		this.lastFrame = -1;

		this.textCanvas = [];
		this.textContext = [];
		this.textTexture = [];

		if (this.faceRenderer) {
			this.faceRenderer.dispose();
		}

		if (this.fbo) this.fbo.dispose();
		this.fbo = null;

		if (this.playbackMaterial) this.playbackMaterial.dispose();
	};
	prepareTextures() {
	}

	//
	// Update playback every frame
	//
	update(opt) {

		if (!this.isReady()) this.lastTime = performance.now();

		this.needsUpdate = (performance.now()-this.lastUpdate) >= 1/24;
		if (this.needsUpdate) this.lastUpdate = performance.now();

		if (CameraController.isReady() && this.faceRenderer && this.needsUpdate) {
			FaceTracker.track();
			this.faceRenderer.contentDiv = this.contentDiv;
			this.faceRenderer.updateLiveInfo(FaceTracker.getFaces(1, 1));
			this.faceRenderer.update();
			this.playbackMaterial.uniforms.tDiffuse.value = CameraController.texture;
			// this.playbackMaterial.ratio.value.set

			

		}

		// if (CameraController.isReady()) {
		// 	this.videoWidth = CameraController.getVideo().videoWidth;
		// 	this.videoHeight = CameraController.getVideo().videoHeight;
		// 	if (this.videoWidth/this.videoHeight > 288/512) { //wider
		// 		console.log("wider");
		// 		this.playbackMaterial.uniforms.ratio.value.set((288/512)/(this.videoHeight/this.videoWidth), 1);
		// 	} else { //taller
		// 		console.log("taller");
		// 		this.playbackMaterial.uniforms.ratio.value.set(1, (512/288)/(this.videoHeight/this.videoWidth));
		// 	}
		// }
		// // this.playbackMaterial.uniforms.ratio.value.set(1,3);
		// console.log(this.playbackMaterial.uniforms.ratio.value);
		// this.playbackMaterial.uniforms.ratio.value.set(1,1);

		//---------------
		//  
		//  Update frame
		//  
		//---------------
		var numFramesTotal = Math.floor(this.numFrames); //this.frameLimit
		if (this.playing) this.currentTime += (performance.now()-this.lastTime) / 1000 * this.videoSpeed;
		this.lastTime = performance.now();
		if (opt && opt.currentTime !== undefined) {
			if (this.params.palindrome) this.currentTime = Utils.clamp(opt.currentTime * this.frameRate, 0, numFramesTotal*2.0-0.0001) / this.frameRate;
			else this.currentTime = Utils.clamp(opt.currentTime * this.frameRate, 0, numFramesTotal-0.0001) / this.frameRate;
		}

		var currentFrame = 0;
		if (this.palindrome) {
			var absoluteFrame = this.currentTime * this.frameRate;
			currentFrame = absoluteFrame % numFramesTotal;
			if (Math.floor(absoluteFrame/numFramesTotal) % 2 == 1) currentFrame = (numFramesTotal-currentFrame);
		} else {
			currentFrame = (this.currentTime * this.frameRate) % numFramesTotal;
		}
		if (this.params.reverse) {
			currentFrame = Utils.clamp(numFramesTotal - 1 - currentFrame, 0, numFramesTotal-1);
		}

		if (opt && opt.forceTime !== undefined) {
			this.currentTime = Utils.clamp(opt.forceTime * this.frameRate, 0, numFramesTotal-0.0001) / this.frameRate;
			currentFrame = (this.currentTime * this.frameRate) % numFramesTotal;
		}

		var cf = Math.floor(currentFrame) + this.startOffset;

		// this.videoWidth = CameraController.getVideo().videoWidth; 
		// this.videoHeight = CameraController.getVideo().videoHeight;
		// if (this.videoWidth/this.videoHeight > this.fbo.width / this.fbo.height) {
		// 	this.playbackMaterial.uniforms.ratio.value.set((this.fbo.width / this.fbo.height)/(this.videoWidth/this.videoHeight), 1);
		// } else {
		// 	this.playbackMaterial.uniforms.ratio.value.set(1, (this.fbo.height / this.fbo.width)/(this.videoHeight/this.videoWidth));
		// }

		if (this.playing) {
			if (CameraController.isReady()) {
				// if (CameraController.getVideo().currentTime - this.lastCameraTime >= 1/30) {
					
					this.needsUpdate = CameraController.updateTexture();
				// }
			}
		}


		this.playbackMaterial.uniforms.tDiffuse.value = CameraController.isReady() ? CameraController.texture : Utils.blackTexture;
		this.lastFrame = cf;
		// console.log(this.playbackMaterial.uniforms.ratio.value);	
	};

	getTexture() {
		return CameraController.isReady() ? this.fbo.texture : Utils.blackTexture;;
		// if (!this.fbo || !this.isReady()) return Utils.blackTexture;
		// return this.fbo.texture.texture;
	}

	getDuration() {
		var d = this.params.duration;
		if (this.params.palindrome) d = (this.params.duration * 2);
		return d;
	}

	// this.userBin = null;
	// this.getSelf = function() {
	// 	if (!this.userBin) return this;
	// 	return UserVideoController.getUserVideo(bin) || this
	// };

	//
	// Render the texture into a RenderTarget or into its own fbo
	//
	render() {
		if (!this.isReady() || !this.fbo || !this.needsUpdate) return;
		this.needsUpdate = false;
		CameraController.updateTexture();
		renderer.render(this.scene, Utils.orthographicCamera, this.fbo.texture, false);
		if (this.faceRenderer && CameraController.isReady()) {
			if (this.params.faces && this.faceRenderer.faceType === 'average' && this.faceRenderer.faceFbo) renderer.render(this.scene, Utils.orthographicCamera, this.faceRenderer.faceFbo.texture, false);
			this.faceRenderer.render(this.fbo.texture);
		}
	}

	clone() {
		var sp = new SpritesheetLivefeed();
		sp.name = this.name;
		sp.isUser = this.isUser;
		sp.reloaded = this.reloaded;
		sp.temporary = this.temporary;
		sp.setParams(Utils.clone(this.params));
		return sp;
	}
};


export default SpritesheetLivefeed;

