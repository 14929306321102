import SETTINGS from './Settings.js';
import AppStatus from './AppStatus.js';
import * as THREE from 'three';
import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
// import window.FFMpegModule from '../../libs/ffmpeg.js'
// var window.FFMpegModule = {};

function MpegDecodeController() {

	this.ffmpegab = null;

	this.moduleab = null;
	this.module = null;
	this.width = 0;
	this.height = 0;
	this.currentFbo = null;

	this.jsmpegReady = false;
	this.ffmpegReady = false;
	this.ready = false;
	this.prepareQuota = 0;
	this.lastUpdate = 0;

	this.update = function() {
		this.prepareQuota = 0; //Math.max(this.prepareQuota - (performance.now()-this.lastUpdate), 0);
		this.lastUpdate = performance.now();
	}

	this.preload = function(batchName) {
		if (!SETTINGS.MPEG2_ONLY) this.moduleab = Loader.addXHR(batchName, SETTINGS.OTHER_ASSETS_URL+'libs/jsmpeg.wasm', 'arraybuffer');
		this.ffmpegab = Loader.addXHR(batchName, SETTINGS.OTHER_ASSETS_URL+ 'libs/m2v.wasm', 'arraybuffer');
		Loader.addScript(batchName, SETTINGS.OTHER_ASSETS_URL+'libs/m2v.js', 'arraybuffer');

	};

	this.init = function() {
		  this.convertMaterial = new THREE.RawShaderMaterial({
			vertexShader:`
				precision mediump float;
				precision mediump int;

				uniform mediump mat4 modelViewMatrix; // optional
				uniform mediump mat4 projectionMatrix; // optional

				attribute mediump vec3 position;
				attribute mediump vec2 uv;

				varying mediump vec2 vUv;

				void main() {
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}
			`,
			fragmentShader:`
				precision mediump float;
				uniform sampler2D textureY;
				uniform sampler2D textureCb;
				uniform sampler2D textureCr;
				varying mediump vec2 vUv;

				const mat4 rec601 = mat4(
					1.16438,  0.00000,  1.59603, -0.87079,
					1.16438, -0.39176, -0.81297,  0.52959,
					1.16438,  2.01723,  0.00000, -1.08139,
					0, 0, 0, 1
				);

				void main() {
					gl_FragColor = vec4(texture2D(textureY, vUv).r, texture2D(textureCr, vUv).r, texture2D(textureCb, vUv).r, 1.0) * rec601;
				}
			`,
			uniforms: {
				textureY: {type:'t', value: this.textureY},
				textureCb: {type:'t', value: this.textureCb},
				textureCr: {type:'t', value: this.textureCr}
			},
			transparent: false,
			blending: THREE.NoBlending,
			side: THREE.DoubleSide,
			depthTest: false,
			depthWrite: false
		});

		if (!SETTINGS.MPEG2_ONLY) {
			this.wasmModule = new JSMpeg.WASMModule();
			this.wasmModule.loadFromBuffer(this.moduleab.value,this.moduleReady.bind(this));
		} else {
			this.jsmpegReady = true;
		}
		window.FFMpegModule.setupWasm(this.ffmpegab.value).then(this.ffmpegReady.bind(this));
	};

	this.ffmpegReady = function() {
		this.ffmpegReady = true;
		this.ffmpegab = null;
		this.ready = this.ffmpegReady && this.jsmpegReady;

	};

	this.moduleReady = function(module) {
		this.wasmModule = null;
		this.moduleab = this.moduleab.value = null;
		this.module = module;
		this.jsmpegReady = true;
		this.ready = this.ffmpegReady && this.jsmpegReady;
		console.log("jsmpeg Ready");
	};


	this.createDecoder = function(mpegData, width, height, isLive) {
		return new MpegDecoder(this, mpegData,width, height, isLive);
	};

	this.createDecoder2 = function(mpegData, width, height, isLive, numFrames) {
		return new Mpeg2Decoder(this, mpegData,width, height, isLive, numFrames);
	};



	//--------------
	//
	// Ycbcr converting
	//
	//--------------
	this.resize = function(w,h) {
		if (!this.textureY || Math.abs(this.width - w) > 0.1 || Math.abs(this.height-h) > 0.1) {
			this.width = w;
			this.height = h;
			this.codedWidth = ((this.width + 15) >> 4) << 4;

			this.textureY = new THREE.DataTexture( new Uint8Array(), w, h, THREE.LuminanceFormat, THREE.UnsignedByteType );
		 	this.textureY.type = THREE.UnsignedByteType;
	 		this.textureY.minFilter = THREE.NearestFilter;
		    this.textureY.magFilter = THREE.NearestFilter;
		    this.textureY.generateMipmaps = false;
		    this.textureY.flipY = false;
		    this.textureY.needsUpdate = true;

		    this.textureCb = new THREE.DataTexture( new Uint8Array(), w>>1, h>>1, THREE.LuminanceFormat, THREE.UnsignedByteType );
		 	this.textureCb.type = THREE.UnsignedByteType;
	 		this.textureCb.minFilter = THREE.LinearFilter;
		    this.textureCb.magFilter = THREE.LinearFilter;
		    this.textureCb.generateMipmaps = false;
		    this.textureCb.flipY = false;
		    this.textureCb.needsUpdate = true;

		    this.textureCr = new THREE.DataTexture( new Uint8Array(), w>>1, h>>1, THREE.LuminanceFormat, THREE.UnsignedByteType );
		 	this.textureCr.type = THREE.UnsignedByteType;
	 		this.textureCr.minFilter = THREE.LinearFilter;
		    this.textureCr.magFilter = THREE.LinearFilter;
		    this.textureCr.generateMipmaps = false;
		    this.textureCr.flipY = false;
		    this.textureCr.needsUpdate = true;
		}
	};
	this.render = function(y, cb, cr, isClampedArray) {
		this.prepareQuota++;
		if (!this.currentFbo) return;
		var w = ((this.width + 15) >> 4) << 4,
		h = this.height,
		w2 = w >> 1,
		h2 = h >> 1;


		if (!this.textureY || this.textureY.width !== this.width) this.resize(this.width, this.height);

		if (isClampedArray) {
			y = new Uint8Array(y.buffer);
			cb = new Uint8Array(cb.buffer);
			cr = new Uint8Array(cr.buffer);
		}
		this.textureY.image.data = y;
		this.textureCb.image.data = cb;
		this.textureCr.image.data = cr;
		
		this.convertMaterial.uniforms.textureY.value = this.textureY;
		this.convertMaterial.uniforms.textureCb.value = this.textureCb;
		this.convertMaterial.uniforms.textureCr.value = this.textureCr;
		
		this.textureY.needsUpdate = true;
		this.textureCb.needsUpdate = true;
		this.textureCr.needsUpdate = true;

		this.currentFbo.decoded = true;
		Utils.renderMaterial(this.convertMaterial, this.currentFbo, true);
	};

	//--------------
	//
	// Fbo Handling
	//
	//--------------
	this.disposedTextures = [];
	this.getTexture = function(width, height) {

		if (this.disposedTextures.length > 0) {
			var t = this.disposedTextures.pop();
			if (Math.abs(t.width - width) > 0.1 || Math.abs(t.height-height) > 0.1) {
				console.warn('Warning: Resizing Mpeg Texture', width, height)
				t.setSize(width, height);
				t.width = width;
				t.height = height;
			}
			return t
		}

		var t = new THREE.WebGLRenderTarget(width||288, height||512);
	    t.texture.antialias = false;
	    t.texture.minFilter = THREE.LinearFilter;
	    t.texture.magFilter = THREE.LinearFilter;
	    t.texture.format = THREE.RGBAFormat;
	    t.texture.generateMipmaps = false;
	    t.texture.wrapS = t.texture.wrapT = THREE.ClampToEdgeWrapping;
	    t.texture.premultiplyAlpha = false;
	    t.texture.flipY = false;
	    t.depthBuffer = false;
	    t.stencilBuffer = false;
	    t.width = width||288;
	    t.height = height||512;
	    return t;
	};

	this.disposeTexture = function(t) {
		if (this.disposedTextures.length > 24*5) {
			t.dispose();
			t = null;
			return;
		}
		this.disposedTextures.push(t);
	};

	this.disposeAll = function() {
		for (var i = 0; i < this.disposedTextures.length; i++) {
			if (this.disposedTextures[i]) this.disposedTextures[i].dispose();
			this.disposedTextures[i] = null;
		}
		this.disposedTextures = [];

		if (this.textureY) this.textureY.dispose();
		if (this.textureCb) this.textureCb.dispose();
		if (this.textureCr) this.textureCr.dispose();
		
	};
};



//--------------
//
// WASM-Based Decoder for an mpeg video
//
//--------------
class MpegDecoder {

	constructor(controller, mpegData, width, height, isLive) {
		this.textures = [];
		this.fbos = [];
		this.ready = false;
		this.cached = false;
		this.progress = 0;
		this.wasSetup = false;
		this.controller = controller;
		this.mpegData = mpegData;
		this.width  = width;
		this.height = height;
		this.isLive = isLive;
		this.lastFrame = -1;

		if (this.controller.ready) this.setup();
	};

	setup() {
		this.wasSetup = true;

		Utils.time("mpeg1 decoder init");

		var options = {
			decodeFirstFrame: false,
			videoBufferSize: 1024*1024,  //default 1024*512
			streaming: false,
			frameRate: 24,
			wasmModule: this.controller.module
		};
		var decoder = new JSMpeg.Decoder.MPEG1VideoWASM(options);
		decoder.connect(this.controller);
		decoder.initializeWasmDecoder();

		var demuxer = new JSMpeg.Demuxer.TS(options);
		demuxer.connect(JSMpeg.Demuxer.TS.STREAM.VIDEO_1, decoder);
		demuxer.write(this.mpegData);
		decoder.loadSequnceHeader();
		this.mpegData = null;

		if (!this.isLive) {
			console.log("Getting non-live textures:",decoder.timestamps.length);
			for (var i=0; i<decoder.timestamps.length; i++) {
				var t = this.controller.getTexture(this.width, this.height);
				t.decoded = false;
				this.fbos[i] = t;
				this.textures[i] = t.texture;
			}
		} else {
			this.fbo = this.controller.getTexture(this.width, this.height);
		}
		
		this.decoder = decoder;
		this.demuxer = demuxer;
		this.uuid = 0; //Utils.generateUUID();
		Utils.timeEnd("mpeg1 decoder init");

	}


	//cached real-time decoding
	getFrame(currentFrame) {
		if (!this.wasSetup) {
			if (this.controller.ready) this.setup(); else return false;
		}
		if (this.isLive) {
			console.warn("Getting frame for live Mpeg");
		}
		if (this.lastFrame == currentFrame) return this.fbos[currentFrame].texture;
		
		if (currentFrame-this.lastFrame>1) {
			if (this.lastFrame>currentFrame) this.lastFrame = -1; //loop
			for (var i=Math.max(this.lastFrame+1,0); i<currentFrame; i++) {
				if (!this.fbos[i].decoded) {
					this.controller.currentFbo = this.fbos[i];
					// console.log("fdecoding", i);
					this.decoder.bufferSetIndex(this.decoder.timestamps[i].index);
					this.decoder.decode();
					this.lastFrame = i;
				}
			}
		}
		if (!this.fbos[currentFrame]) {
			console.warn("MpegDecodeController no fbo error for frame:", currentFrame);
			this.fbos[currentFrame] = this.controller.getTexture(this.width, this.height);
			this.fbos[currentFrame].decoded = false;
			this.textures[currentFrame] = this.fbos[currentFrame].texture;
		}
		if (!this.fbos[currentFrame].decoded) {
			// console.log("decoding", currentFrame);
			this.controller.currentFbo = this.fbos[currentFrame];
			if (this.decoder.timestamps[currentFrame] == undefined) {
				console.log("Decoder error",this);
			}
			Utils.time('mpeg1_framedecode'+this.uuid, 1000);
			this.decoder.bufferSetIndex(this.decoder.timestamps[currentFrame].index);
			this.decoder.decode();
			Utils.timeEnd('mpeg1_framedecode'+this.uuid, 1000);
			this.lastFrame = currentFrame;
		}
		
		return this.fbos[currentFrame].texture;
	};

	//real-time decoding to prevent having to preload 120+ textures
	getFrameLive(currentFrame) {
		if (!this.wasSetup) {
			if (this.controller.ready) this.setup(); else return false;
		}
		if (this.lastFrame == currentFrame) return this.fbo.texture;
		
		if ((this.lastFrame==-1 && currentFrame > 0) || (this.lastFrame>-1 && currentFrame-this.lastFrame>1) || (this.lastFrame>currentFrame)) {
			if (this.lastFrame>currentFrame) this.lastFrame = -1; //loop
			for (var i=Math.max(this.lastFrame+1,0); i<currentFrame; i++) {
				this.controller.currentFbo = this.isLive ? null : this.fbos[i];
				// console.log("fdecoding", i);
				this.decoder.bufferSetIndex(this.decoder.timestamps[i].index);
				this.decoder.decode();
			}
		}
		// console.log("decoding", currentFrame);
		this.controller.currentFbo = this.fbo;
		Utils.time('mpeg1_framedecode'+this.uuid, 10000);
		this.decoder.bufferSetIndex(this.decoder.timestamps[currentFrame].index);
		this.decoder.decode();
		Utils.timeEnd('mpeg1_framedecode'+this.uuid, 10000);
		this.lastFrame = currentFrame;

		return this.fbo.texture;
	};

	prepare(force_all) {
		if (!this.wasSetup) {
			if (this.controller.ready) this.setup(); else return false;
		}
		this.ready = this.wasSetup;
		// if (this.isLive) {
		// 	this.ready = this.wasSetup;
		// 	return true;
		// }
		// if (this.ready) {
		// 	return true;
		// }

		if (!this.cached && !this.isLive) {
			var numf = Math.max(6-this.controller.prepareQuota, 0); //Math.ceil(this.decoder.timestamps.length/24); //force_all?this.decoder.timestamps.length:Math.ceil(this.decoder.timestamps.length/24);

			var lf = this.lastFrame;
			for (var i=Math.max(lf+1,0); i<Math.min(lf+numf,this.decoder.timestamps.length); i++) {
				this.fbos[i].decoded = true;
				Utils.time('mpeg1_framedecode'+this.uuid, 1000);
				this.controller.currentFbo = this.fbos[i];
				this.decoder.bufferSetIndex(this.decoder.timestamps[i].index);
				this.decoder.decode();
				this.lastFrame = i;
				Utils.timeEnd('mpeg1_framedecode'+this.uuid, 1000);
				if (i==0) this.controller.prepareQuota =+ 10;
			}

			if (this.lastFrame >= this.decoder.timestamps.length) {
				this.cached = true;
				this.decoder.destroy();
				this.decoder = null;
			}
		}
		
		return this.ready; //this.ready;
	};

	disposeDecoder() {
		if (this.isLive || !this.cached) return;
		if (this.decoder) this.decoder.destroy();
		this.decoder = this.demuxer = null;
	}

	dispose() {
		if (this.fbos) {
			for (var i=0; i<this.fbos.length; i++) {
				this.controller.disposeTexture(this.fbos[i]);
			}
			this.fbos = null;
		}
		if (this.fbo) {
			this.controller.disposeTexture(this.fbo);
			this.fbo = null;
		}

		if (this.decoder) this.decoder.destroy();
		this.decoder = this.demuxer = this.controller = null;
		this.textures = this.fbos = null;
		this.lastFrame = -1;
		this.ready = false;
		this.cached = false;
	};
};



//--------------
//
// WASM-Based Decoder for an mpeg video
//
//--------------
var MPEG2_VERSION = 1;
class Mpeg2Decoder {

	constructor(controller, mpegData, width, height, isLive, numFrames) {
		this.textures = [];
		this.fbos = [];
		this.ready = false;
		this.cached = false;
		this.progress = 0;
		this.wasSetup = false;
		this.controller = controller;
		this.mpegData = new Uint8Array(mpegData);

		this.width  = width;
		this.height = height;
		this.isLive = isLive;
		this.lastFrame = -1;
		this.numFrames = numFrames;

		this.decoder = null;

		// console.log("mpeg2decoder");
		if (this.controller.ready) this.setup();
	};

	setup() {
		this.wasSetup = true;
		

		if (!this.isLive) {
			// for (var i=0; i<this.numFrames; i++) {
			// 	if (this.numFrames >= 76) {
			// 		console.log("texture wtf");
			// 	}
			// 	console.log("Getting non-live textures:",this.numFrames);
			// 	var t = this.controller.getTexture(this.width, this.height);
			// 	t.decoded = false;
			// 	this.fbos[i] = t;
			// 	this.textures[i] = t.texture;
			// }
		} else {
			this.fbo = this.controller.getTexture(this.width, this.height);
		}
		

		this.uuid = 0; //Utils.generateUUID();

		// Utils.time("mpeg2 decoder init");
		this.decoder = window.FFMpegModule.getInstance();
		// console.log("got decoder!", this.decoder);

		window.FFMpegModule._allocSpace(this.decoder, this.mpegData.buffer.byteLength);
		if (window.FFMpegModule.MODULE_VERSION == 1) {
			window.FFMpegModule.HEAPU8.set(new Uint8Array(this.mpegData), window.FFMpegModule._getFileBufferRef(this.decoder));
		}
		window.FFMpegModule._start(this.decoder, this.mpegData.buffer.byteLength);
		if (window.FFMpegModule.MODULE_VERSION == 1) {
			this.mpegData = null;
		}
		// Utils.timeEnd("mpeg2 decoder init");

		// 

		this.ready = true;
	}


	//cached real-time decoding
	getFrame(currentFrame) {
		if (this.isLive) {
			console.warn("Getting frame for live Mpeg");
		}
		if (this.lastFrame == currentFrame) return this.fbos[currentFrame].texture;
		
		for (var f=Math.max(this.lastFrame, 0); f<= currentFrame; f++) {
			if (!this.fbos[f]) {
				this.fbos[f] = this.controller.getTexture(this.width, this.height);
				this.fbos[f].decoded = false;
				this.textures[f] = this.fbos[f].texture;
			}
			if (!this.fbos[f].decoded) {
				// console.log(f);

				// Utils.time('mpeg2_framedecode'+this.uuid, 1000);
				// console.log(this.mpegData);
				window.FFMpegModule.decodeFrame(this.decoder, this.mpegData);
			
				var luma = window.FFMpegModule._getLuma(this.decoder);
				var u = window.FFMpegModule._getV(this.decoder);
				var v = window.FFMpegModule._getU(this.decoder);
				// console.log(luma, u, v);
				// console.log(window.FFMpegModule.HEAPU8.subarray(luma, luma+this.width*this.height));
				this.controller.currentFbo = this.fbos[f];
				this.controller.resize(this.width, this.height);
				this.controller.render(
					window.FFMpegModule.HEAPU8.subarray(luma, luma+this.width*this.height),
					window.FFMpegModule.HEAPU8.subarray(u, u+(this.width/2)*(this.height/2)),
					window.FFMpegModule.HEAPU8.subarray(v, v+(this.width/2)*(this.height/2)),
					false
				);
				this.fbos[f].decoded = true;
				// this.mpegData = null;
				// Utils.timeEnd('mpeg2_framedecode'+this.uuid, 1000);
			}
		}
		this.lastFrame = currentFrame;
		
		return this.fbos[currentFrame].texture;
	};

	//real-time decoding to prevent having to preload 120+ textures
	getFrameLive(currentFrame) {
		if (this.lastFrame == currentFrame) return this.fbo.texture;
		
		if ((this.lastFrame==-1 && currentFrame > 0) || (this.lastFrame>-1 && currentFrame-this.lastFrame>1) || (this.lastFrame>currentFrame)) {
			if (this.lastFrame>currentFrame) {
				// console.log("loop");
				this.lastFrame = -1; //loop
				window.FFMpegModule._loop(this.decoder);
				// window.FFMpegModule._cleanup(this.decoder);
				// window.FFMpegModule._start(this.decoder, 0);
				if (currentFrame > 0) {
					for (var i=Math.max(this.lastFrame+1,0); i<currentFrame; i++) {
						console.log("skip frames",i);
						// this.controller.currentFbo = this.isLive ? null : this.fbos[i];
						// console.log(this.mpegData);
						window.FFMpegModule.decodeFrame(this.decoder, this.mpegData);
					}
				}	
			}
			
		}

		// console.log(currentFrame);

		// Utils.time('mpeg2_framedecode'+this.uuid, 10000);
		// console.log(this.mpegData);
		window.FFMpegModule.decodeFrame(this.decoder, this.mpegData);
	
		var luma = window.FFMpegModule._getLuma(this.decoder);
		var u = window.FFMpegModule._getV(this.decoder);
		var v = window.FFMpegModule._getU(this.decoder);
		this.controller.currentFbo = this.fbo;
		this.controller.resize(this.width, this.height);
		this.controller.render(
			window.FFMpegModule.HEAPU8.subarray(luma, luma+this.width*this.height),
			window.FFMpegModule.HEAPU8.subarray(u, u+(this.width/2)*(this.height/2)),
			window.FFMpegModule.HEAPU8.subarray(v, v+(this.width/2)*(this.height/2)),
			false
		);
		// Utils.timeEnd('mpeg2_framedecode'+this.uuid, 10000);
		this.lastFrame = currentFrame;

		return this.fbo.texture;
	};

	prepare(force_all) {
		if (!this.wasSetup) {
			if (this.controller.ready) this.setup(); else return false;
		}
		this.ready = this.wasSetup;

		if (!this.cached && !this.isLive) {
			var numf = Math.max(6-this.controller.prepareQuota, 0); //Math.ceil(this.decoder.timestamps.length/24); //force_all?this.decoder.timestamps.length:Math.ceil(this.decoder.timestamps.length/24);
			// if (numf > 1) console.log("preparing multi",numf);
			var lf = this.lastFrame;
			for (var i=Math.max(lf+1,0); i<Math.min(lf+numf,this.numFrames); i++) {
				if (!this.fbos[i]) {
					this.fbos[i] = this.controller.getTexture(this.width, this.height);
					this.fbos[i].decoded = false;
					this.textures[i] = this.fbos[i].texture;
				}
				this.fbos[i].decoded = true;
				// Utils.time('mpeg2_framedecode'+this.uuid, 1000);
				window.FFMpegModule.decodeFrame(this.decoder);
				var luma = window.FFMpegModule._getLuma(this.decoder);
				var u = window.FFMpegModule._getV(this.decoder);
				var v = window.FFMpegModule._getU(this.decoder);
				// console.log(luma, u, v);
				// console.log(window.FFMpegModule.HEAPU8.subarray(luma, luma+this.width*this.height));
				this.controller.currentFbo = this.fbos[i];
				this.controller.resize(this.width, this.height);
				this.controller.render(
					window.FFMpegModule.HEAPU8.subarray(luma, luma+this.width*this.height),
					window.FFMpegModule.HEAPU8.subarray(u, u+(this.width/2)*(this.height/2)),
					window.FFMpegModule.HEAPU8.subarray(v, v+(this.width/2)*(this.height/2)),
					false
				);
				// Utils.timeEnd('mpeg2_framedecode'+this.uuid, 1000);
				this.lastFrame = i;
				if (i==0) this.controller.prepareQuota =+ 10;
			}

			if (this.lastFrame >= this.numFrames) {
				this.cached = true;
				// this.decoder.destroy();
				window.FFMpegModule.disposeInstance(this.decoder);
				this.decoder = null;
			}
		}
		
		return this.ready; //this.ready;
	};

	disposeDecoder() {
		if (this.isLive || !this.cached) return;
		if (this.decoder) window.FFMpegModule.disposeInstance(this.decoder);
		this.decoder = null;
		this.mpegData = null;
	}

	dispose() {
		if (this.fbos) {
			for (var i=0; i<this.fbos.length; i++) {
				if (this.fbos[i]) this.controller.disposeTexture(this.fbos[i]);
			}
			this.fbos = null;
		}
		if (this.fbo) {
			this.controller.disposeTexture(this.fbo);
			this.fbo = null;
		}

		if (this.decoder) window.FFMpegModule.disposeInstance(this.decoder);
		this.decoder = this.demuxer = this.controller = null;
		this.textures = this.fbos = null;
		this.lastFrame = -1;
		this.ready = false;
		this.mpegData = null;
		this.cached = false;
	};
};


window.MpegDecodeController = new MpegDecodeController();
window.MpegDecoder = MpegDecoder;
window.Mpeg2Decoder = Mpeg2Decoder;

export default window.MpegDecodeController;


