

import SETTINGS from './Settings.js';
import AppStatus from './AppStatus.js';
import CameraController from './CameraController.js';
import Utils from '../utils/Utils.js';

	function BranchController() {

		this.currentId = 0;
		this.recorded = {};
		this.skipped = {};
		this.hasSkipped = false;
		this.tasksDone = null;

		this.cameraCoverDone = false;
		this.cameraSpinDon = false;

		this.altBranchIsImpossible = {
			"outside_allowed": true,
			"outside_not_allowed": true,
			"night": true,
			"is_night": true,
			"day": true,
			"is_day": true,
			"september_male": true,
			"september_female": true,
			"task_done_either": true,
			"task_not_done": true,
			"task": true,
			"task_done": true
		};


		this.isNight = ((SETTINGS.FORCE_SPECIFIC_DAY||new Date()).getHours() >= 19.5 || (SETTINGS.FORCE_SPECIFIC_DAY||new Date()).getHours() < 5);
		if (SETTINGS.FORCE_NIGHT) this.isNight = true;
		if (SETTINGS.FORCE_DAY) this.isNight = false;

		this.lockedCameraCondition = false;

		this.init = function() {
			this.recorded = Utils.clone(MultiStorage.getGlobalState().recordedById||{});
		};

		this.setRecord = function(record_id) {
			this.skipped[record_id] = false;
			this.recorded[record_id] = true;

			var state = MultiStorage.getGlobalState();
			state.recordedById = state.recordedById||{};
			state.recordedById[record_id] = true;
			MultiStorage.setGlobalState(state);
		};

		this.setSkip = function(record_id) {
			if (this.recorded[record_id]) return;
			this.hasSkipped = true;
			this.skipped[record_id] = true;
		};

		this.checkCondition = function(condition, id) {
			switch (condition) {
				case 'outside_allowed':
					return SETTINGS.OUTSIDE_ALLOWED;
					break;

				case 'outside_not_allowed':
					return SETTINGS.OUTSIDE_ALLOWED == false;
					break;

				case 'night':
				case 'is_night':
					return ((SETTINGS.FORCE_SPECIFIC_DAY||new Date()).getHours() >= 19.5 || (SETTINGS.FORCE_SPECIFIC_DAY||new Date()).getHours() < 5); //this.isNight;
					break;

				case 'day':
				case 'is_day':
					return !((SETTINGS.FORCE_SPECIFIC_DAY||new Date()).getHours() >= 19.5 || (SETTINGS.FORCE_SPECIFIC_DAY||new Date()).getHours() < 5); //this.isNight;
					break;

				case 'september_male':
					return AppStatus.SEPTEMBER_GENDER==0;
					break;

				case 'september_female':
					return AppStatus.SEPTEMBER_GENDER==1;
					break;

				case 'intro_mode':
					return SETTINGS.INTRO_MODE;
					break;

				case 'main_loading':
					return !SETTINGS.WAS_INTRO_MODE || (SETTINGS.WAS_INTRO_MODE && !AppStatus.MAIN_LOADED);
					break;	

				case 'camera_allowed':
					// if (this.lockedCameraCondition) return false;
					return CameraController.triedOnce;
					break;

				case 'camera_not_allowed':
					// if (this.lockedCameraCondition) return true;
					return !CameraController.triedOnce && !CameraController.cameraRefused;
					break;

				case 'camera_refused':
					// if (this.lockedCameraCondition) return true;
					return CameraController.cameraRefused;
					break;

				case 'camera_cover_done':
					return window.BranchController.cameraCoverDone;
					break;

				case 'camera_spin_done':
					return window.BranchController.cameraSpinDone;
					break;

				case 'camera_cover_not_done':
					return !window.BranchController.cameraCoverDone;
					break;

				case 'camera_spin_not_done':
					return !window.BranchController.cameraSpinDone;
					break;

				case 'record':
					return !!this.recorded[id];
					break;

				case 'record_both':
					var ids = id.split('&');
					return !!this.recorded[ids[0]] && !!this.recorded[ids[1]];
					break;

				case 'record_either':
					var ids = id.split('&');
					for (var i=0; i<ids.length; i++) {
						if (this.recorded[ids[i]]) return true
					}
					return false;
					break;

				case 'skip':
					return !this.recorded[id];
					break;


				case 'task_done_either':
					if (!this.tasksDone) this.tasksDone = MultiStorage.getGlobalState().tasksDone||{};
					ids = id.split('&');
					return this.tasksDone[ids[0]] || this.tasksDone[ids[1]];
					break;

				case 'task_not_done':
					if (!this.tasksDone) this.tasksDone = MultiStorage.getGlobalState().tasksDone||{};
					return !this.tasksDone[id];
					break;

				case 'task':
				case 'task_done':
					if (!this.tasksDone) this.tasksDone = MultiStorage.getGlobalState().tasksDone||{};
					return !!(this.tasksDone[id]);
					break;

				case 'default':
					return true;
					break;

				case 'analysis':
					break;
			}
			return false;
		};

	};
window.BranchController = window.BranchController||new BranchController();
export default window.BranchController;

