
import $ from 'jquery';
import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import MottoView from './MottoView.js';

//------------------------
class LoaderView extends MottoView {

	constructor(_info, batchName, doneCallback) {
		super(_info, batchName);
		this.isLoaderView = true;
		this.doneCallback = doneCallback;
	}

	activate() {
		if (this.active) return;
		super.activate();

		this.wasShown = false;
	}

	update() {
		super.update();
		this.lockRight = SETTINGS.WAS_INTRO_MODE && !AppStatus.MAIN_LOADED;
		if (this.lockRight) {
			this.wasShown = true;
			this.shownTime = performance.now();
		}
		if (this.active && !this.lockRight && this.doneCallback && (!this.wasShown || (this.wasShown && performance.now() - this.shownTime >= 2000))) {
			this.doneCallback( this );
			this.doneCallback = null;
		}
	}

	start() {
		if (this.started) return;
		super.start();
		$(this.pageCounterDiv).hide();
	}
}

export default LoaderView;	

