import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';
import Fbo from '../utils/Fbo.js'

import RendererController from '../controllers/RendererController.js';
import MotionController from '../controllers/MotionController.js';
import MultiStorage from '../utils/MultiStorage.js';
import SoundController from '../controllers/SoundController.js';
import MenuController from "../controllers/MenuController.js";

var divsPool = [];
// window.divsPool = divsPool;

//------------------------
// 
//  Base class for all views
//
//-------------------------
class MottoView {

	//called for every view @ start of story
	constructor(_info, batchName) {
		this.info = _info||{};
		this.params = this.info.params || {};
		this.viewUid = Math.random().toString().split('.').pop()+'_'+(MottoView.currentId++);
		this.parentBranch = null;

		this.started = false;
		this.active = false;

		this.textType = this.info["textType"] || 'center';
		this.textColor = this.info.textColor || (this.info.dark ? '#ffffff' : '#000000');
		this.textBackground = this.info.textBackground || false; //(this.info.textBackground || ( this.info.dark ? '#000000' : false));
		if (!this.info.textColor && !this.textBackground && (this.info.dark || this.info.background == 'black' || this.info.background == '#000000') && this.info.type=='text')
			this.textColor = '#ffffff';

		this.mainDiv = null;
		this.contentDiv = null;
		this.textContainer = null;
		this.textDiv = null;

		this.page = 0;
		this.numPages = 1;
		
		//dragging & direction
		this.positionX = 0.0;
		this.lockLeft = false;
		this.lockRight = false;
		this.backgroundColor = this.info.background || (this.info.dark?0x000000:0xffffff);
		this.noMenu = this.info.noMenu;


		this.loaderBackground = false;
		this.loaderBackgroundPc = 0.0;
		
		//--------------------------
		//
		//  Text info analysis
		// 
		//--------------------------
		this.hasTextAnalysis = !!this.params.textAnalysisId;
		this.textAnalysisLoading = false;
		this.textAnalysisLoaded = false;
		this.mainShown = false;
		this.hasLivefeed = false;


		//--------------------------
		//
		//  Edit mode settings
		// 
		//--------------------------
		if (SETTINGS.EDIT_MODE) {
			this.viewFbo = new Fbo(SETTINGS.EDIT_MODE_VIEW_WIDTH,SETTINGS.EDIT_MODE_VIEW_HEIGHT, {
				minFilter:THREE.LinearFilter,
				magFilter:THREE.LinearFilter,
				format:THREE.RGBAFormat,
				type:THREE.UnsignedByteType,
				antialias: true,
				depthBuffer: false,
				stencilBuffer: false,
				premultiplyAlpha: false,
				generateMipmaps: false,
				forceClear: false,
				pingpong: false,
				renderer: renderer
			});
			this.renderTarget = this.viewFbo.texture;
			this.viewRendered = false;
			this.mouseIsOver = false;
		}

		//--------------------------
		//
		//  Analysis Loading
		// 
		//--------------------------
		this.analysisLoading = false;
		this.analysisLoaded = false;
		// if (this.info.textAnalysis && this.info.textAnalysisId) {
		// 	this.analysisLoading = true;
		// 	console.log("LOADING TEXT ANALYSIS",this.info.textAnalysisId, true, this.info.textAnalysis)
		// 	AnalysisController.getAnalysis(this.info.textAnalysisId, true, this.info.textAnalysis, batchName).then(
		// 		function(a) {
		// 			console.log("LOADED TEXT ANALYSIS",a);
		// 		}
		// 		// this.onAnalysisLoaded.bind(this)
		// 	);
		// }
		if (this.info.sound) {
			this.audioBinary = PageLoader.addXHR(batchName, SETTINGS.OTHER_ASSETS_URL+this.info.sound, 'arraybuffer');
			SoundController.enabled = true;
		}
	}


	//
	// Make active, create divs if necessary
	//
	start() {
		if (this.started) return;
		this.started = true;
		// this.mainDiv = document.createElement('div');
		// $(this.mainDiv).addClass('motto-view');

		// this.contentDiv = document.createElement('div');
		// $(this.contentDiv).addClass('motto-view-content');
		// $(this.mainDiv).append(this.contentDiv);

		// this.textContainer = document.createElement('div');
		// $(this.textContainer).addClass('alt-text-container').addClass(this.textType);
		// $(this.contentDiv).append(this.textContainer);

		// this.textDiv = document.createElement('div');
		// $(this.textDiv).addClass('alt-text').addClass(this.textType);
		// if (this.info.textSize) $(this.textDiv).css('font-size', this.info.textSize);
		// if (this.info.font) $(this.textDiv).css('font-family', this.info.font);

		// this.menuDiv = document.createElement('div');
		// $(this.menuDiv).addClass('motto-view-menu-button');
		// $(this.mainDiv).append(this.menuDiv);
		// $(this.textContainer).append(this.textDiv);

		// this.pageCounterDiv = document.createElement('div');
		// $(this.pageCounterDiv).addClass('page-number');
		// $(this.contentDiv).append(this.pageCounterDiv);


		if (divsPool.length === 0) {
			this.mainDiv = document.createElement('div');
			$(this.mainDiv).addClass('motto-view');

			this.contentDiv = document.createElement('div');
			$(this.contentDiv).addClass('motto-view-content');
			$(this.mainDiv).append(this.contentDiv);

			this.textContainer = document.createElement('div');
			$(this.textContainer).addClass('alt-text-container');
			$(this.contentDiv).append(this.textContainer);

			this.textDiv = document.createElement('div');
			$(this.textDiv).addClass('alt-text');
			$(this.textContainer).append(this.textDiv);

			this.menuDiv = document.createElement('div');
			$(this.menuDiv).addClass('motto-view-menu-button');
			$(this.mainDiv).append(this.menuDiv);

			this.pageCounterDiv = document.createElement('div');
			$(this.pageCounterDiv).addClass('page-number');
			$(this.contentDiv).append(this.pageCounterDiv);

		} else {

			var divs = divsPool.pop();
			this.mainDiv = divs.mainDiv;
			this.contentDiv = divs.contentDiv;
			this.textContainer = divs.textContainer;
			this.textDiv = divs.textDiv;
			this.menuDiv = divs.menuDiv;
			this.pageCounterDiv = divs.pageCounterDiv;
		}


		$(this.textContainer).addClass(this.textType);
		$(this.textDiv).addClass(this.textType);
		if (this.info.textSize) $(this.textDiv).css('font-size', this.info.textSize);
		if (this.info.font) $(this.textDiv).css('font-family', this.info.font);
		if (this.info.font && /Crimson/gi.test(this.info.font)) {
			$(this.textDiv).addClass('ghost'); // (AppStatus.currentTaskId == 'ghost5' || SETTINGS.GHOST5_FONT) ? 'ghost5' : 
		}
		if (SETTINGS.ERROR_MODE) {
			$(this.textDiv).css('max-width', 'calc(100% - 10em)');
		}


		if (!this.info.orientationText) {
			var txt = Utils.replaceGender(this.info.description?this.info.description[SETTINGS.LANGUAGE]:(this.info.text||''), AppStatus.SEPTEMBER_GENDER);
			if (this.info.replaceDay) {
				var daystr = (SETTINGS.FORCE_SPECIFIC_DAY||new Date()).toLocaleDateString(SETTINGS.LANGUAGE=='fr'?'fr-FR':'en-EN', {'weekday':'long'});
				if (SETTINGS.LANGUAGE=='en') daystr = Utils.capitalize(daystr);
				txt = txt.replace(/{day}/, daystr);
			}
			if (this.info.replaceDay) txt = txt.replace(/{DAY}/, (SETTINGS.FORCE_SPECIFIC_DAY||new Date()).toLocaleDateString(SETTINGS.LANGUAGE=='fr'?'fr-FR':'en-EN', {'weekday':'long'}).toUpperCase());

			if (this.info.replaceSunday) {
				var daysToSunday = 7-(SETTINGS.FORCE_SPECIFIC_DAY||new Date()).getDay();
				if (daysToSunday==1 && this.info.tomorrowIsSunday) {
					txt = Utils.replaceGender(this.info.tomorrowIsSunday||'', AppStatus.SEPTEMBER_GENDER);
				} else if ((daysToSunday==0||daysToSunday==7) && this.info.todayIsSunday) {
					txt = Utils.replaceGender(this.info.todayIsSunday||'', AppStatus.SEPTEMBER_GENDER);
				} else if (daysToSunday==6 && this.info.yesterdayWasSunday) {
					txt = Utils.replaceGender(this.info.yesterdayWasSunday||'', AppStatus.SEPTEMBER_GENDER);
				} else {
					var chiffres = SETTINGS.LANGUAGE=='fr' ?
					['un','un', 'deux', 'trois', 'quatre', 'cinq', 'six', 'sept', 'huit', 'neuf']:
					['one','one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
					txt = txt.replace(/{num-days-to-sunday}/, chiffres[daysToSunday]+' '+(SETTINGS.LANGUAGE=='fr'?' jour':' day')+(daysToSunday>1?'s':''));
				}
			}

			if (this.info.replaceChapter) {
				// var chiffres = SETTINGS.LANGUAGE=='fr' ?
				// 	['un','un', 'deux', 'trois', 'quatre', 'cinq', 'six', 'sept', 'huit', 'neuf']:
				// 	['one','one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
				var doneOrder = (MultiStorage.getGlobalState().tasksDoneOrder||{})[AppStatus.currentTaskId]||0;
				txt = txt.replace(/{chapter}/, MenuController.chapterNumber[AppStatus.currentTaskId]);//Math.max(doneOrder+1,this.info.replaceChapter).toString());
				if (SETTINGS.STORYBOARD_MODE) txt = txt.replace(/{chapter}/, this.info.replaceChapter);
			}
			if (this.info.replaceChapterPart) {
				txt = txt.replace(/{chapter_name}/, MenuController.chapterPartOneTwo); //'<span class="chapter-part">'++'</span>');

			}

			if (this.info.replaceUserId) {
				txt = txt.replace(/{user_id}/, SETTINGS.uuid); //'<span class="chapter-part">'++'</span>');
			}


			if (this.info.replaceGreetings) {
				var hours = (SETTINGS.FORCE_SPECIFIC_DAY||new Date()).getHours();
				if (hours < 5 || hours >= 17) {
					txt = this.info.replaceGreetings.night;
				} else if (hours >= 12) {
					txt = this.info.replaceGreetings.afternoon;
				} else {
					txt = this.info.replaceGreetings.morning;
				}
				txt = Utils.replaceGender(txt, AppStatus.SEPTEMBER_GENDER);
			}

			if (this.info.replaceTimestamp) {
				var globalState = MultiStorage.getGlobalState();
				var timeDifference = new Date().getTime() - (1000 * 60 * 10);
				if (globalState.savedTimestamp && !globalState.savedTimestamp[this.info.replaceTimestamp] && globalState.savedTimestamp["first_page_load"]) {
					timeDifference = new Date().getTime() - globalState.savedTimestamp["first_page_load"];
				} else if (globalState.savedTimestamp && globalState.savedTimestamp[this.info.replaceTimestamp]) {
					timeDifference = new Date().getTime() - globalState.savedTimestamp[this.info.replaceTimestamp];
				}
			
				var chiffres = SETTINGS.LANGUAGE=='fr' ?
					['une','une', 'deux', 'trois', 'quatre', 'cinq', 'six', 'sept', 'huit', 'neuf']:
					['one','one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];

				var timeString = '';
				if (timeDifference < 1000 * 60) {
					var td = Math.floor(timeDifference / (1000));
					timeString = (td<10?chiffres[td]:td) + (SETTINGS.LANGUAGE=='fr'?' seconde':' second');
					if (td>1) timeString+='s';
				} else if (timeDifference < 1000 * 60 * 60) {
					var td = Math.floor(timeDifference / (1000*60));
					timeString = (td<10?chiffres[td]:td) + (SETTINGS.LANGUAGE=='fr'?' minute':' minute');
					if (td>1) timeString+='s';
				} else if (timeDifference < 1000 * 60 * 60 * 24) {
					var td = Math.floor(timeDifference / (1000*60*60));
					timeString = (td<10?chiffres[td]:td) + (SETTINGS.LANGUAGE=='fr'?' heure':' hour');
					if (td>1) timeString+='s';
				} else {
					var td = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
					if (SETTINGS.LANGUAGE == 'fr') {chiffres[0] = 'un'; chiffres[1] = 'un';}
					timeString = (td<10?chiffres[td]:td) + (SETTINGS.LANGUAGE=='fr'?' jour':' day');
					if (td>1) timeString+='s';
				}

				txt = txt.replace(/{timestamp}/, timeString);
			}

			if (this.info.isWelcomeBack) txt = txt.replace(/{page}/, this.page||1);

			if (!txt) $(this.textDiv).hide(); else $(this.textDiv).html(txt);
		} else {
			this.targetNorth = true;
			this.currentOrientationText = '';
			this.currentOrientationShown = false;
			$(this.textDiv).hide();
		}
	
		//--------------------------
		//
		//  Text background & color
		//
		//--------------------------
		$(this.textDiv).css('color', this.textColor);
		if (this.textBackground) $(this.textDiv).css('background-color', this.textBackground||(this.info.dark ? '#ffffff' : '#000000'));

		$('#main').append(this.mainDiv);
		$(this.mainDiv).hide();
		// $(this.mainDiv).append(this.textDiv);


		//--------------------------
		//
		//  Text analysis loading
		//
		//--------------------------
		// if (this.hasTextAnalysis && !this.textAnalysisLoading && !this.textAnalysisLoaded) {
		// 	this.textAnalysisLoading = true;
			
		// }
		// if (this.hasTextAnalysis) {
		// 	$(this.textDiv).html('');

		// 	this.textAnalysisLoaded = false;
		// 	AnalysisController.getAnalysis(this.params.textAnalysisId, this.isUser).then((analysis) => {
		// 		this.analysis = analysis;
		// 		this.textAnalysisLoaded = true;
		// 		if (this.active && !this.mainShown) {
		// 			this.mainShown = true;
		// 			var found = false;
		// 			$(this.mainDiv).show();
		// 		}
		// 	});
		// 	$(this.textDiv).html(Utils.replaceGender(this.info.description[SETTINGS.LANGUAGE], AppStatus.SEPTEMBER_GENDER));
		// }
		if (this.params.inout_select && $(this.textDiv).find('a')) {
			$(this.textDiv).css('pointer-events', 'auto');
			$(this.textDiv).find('#intro_select_inside').on('click', function() {
				window.introSelectInside();
			});
			$(this.textDiv).find('#intro_select_outside').on('click', function() {
				window.introSelectOutside();
			});
			this.lockRight = true;
		}


		if (this.params.replace_has_skipped || this.info.replace_has_skipped)  {
			if (BranchController.hasSkipped) {
				$(this.textDiv).find('.has_skipped').remove();
				$(this.textDiv).find('.has_skipped').remove();
			}
		}

		if (this.info.sound) SoundController.start();

		if (this.info.isWelcomeBack) {
			$(this.textDiv).css('pointer-events', 'auto');

		}

		//--------------------------
		//
		//  Edit mode settings
		// 
		//--------------------------
		if (SETTINGS.EDIT_MODE) {
			this.viewRendered = false;
			this.mouseIsOver = false;
			$(this.mainDiv).mouseover(function(e) {
				this.mouseIsOver = true;
			}.bind(this));
			$(this.mainDiv).mouseout(function(e) {
				this.mouseIsOver = false;
			}.bind(this));
		}
	}
	

	//
	// Delete divs when passed
	// Cleanup all memory
	//
	stop() {
		if (!this.started) return;
		this.started = false;

		$(this.mainDiv).remove();

		if (divsPool.length < 8) {
			var divHierarchy = {
				mainDiv: this.mainDiv,
				contentDiv: this.contentDiv,
				textContainer: this.textContainer,
				textDiv: this.textDiv,
				menuDiv: this.menuDiv,
				pageCounterDiv: this.pageCounterDiv
			};
			$(this.textDiv).attr({ 'style' : '' });
			$(this.textDiv).toggleClass('ghost', false);
			$(this.textDiv).toggleClass('ghost5', false);
			$(this.textContainer).attr({ 'style' : '' });
			$(this.pageCounterDiv).attr({ 'style' : '' });
			$(this.menuDiv).toggleClass('white', false).toggleClass('gray', false);
			$(this.menuDiv).attr({ 'style' : '' });
			
			$(this.textDiv).removeClass(this.textType);
			$(this.textContainer).removeClass(this.textType);

			divsPool.push(divHierarchy);
		} else {

			$(this.contentDiv).remove();
			$(this.textDiv).remove();
			$(this.textContainer).remove();
			$(this.menuDiv).remove();
			$(this.pageCounterDiv).remove();
		}

		
		this.menuDiv = null;
		this.mainDiv = null;
		this.contentDiv = null;
		this.textDiv = null;
		this.textContainer = null;
		this.pageCounterDiv = null;

		if (this.info.sound) SoundController.stop();
	}


	//add to dom
	activate() {
		if (this.active) return;
		this.active = true;
		if (!this.hasTextAnalysis || this.textAnalysisLoaded) {
			$(this.mainDiv).show();
			this.mainShown = true;
		}
		if (this.info.orientationText) {
			this.orientationReference = MotionController.horizontalOrientation;
			this.angleId = 0;
			this.numAngleId = 0;
			this.targetNorth = Math.abs(MotionController.northPc) <= Math.abs(MotionController.southPc);
			if (this.info["invertOrientation"] || this.info.orientationText["invertOrientation"]) this.targetNorth = !this.targetNorth;
		}
	

		// if (this.pageCounterDiv) {$(this.pageCounterDiv).remove(); this.pageCounterDiv=null;}
		this.setPageCounter();
		this.loadingCount = 0;

		if (this.parentBranch) this.parentBranch.activateView(this.viewUid);

		AppStatus.frameNeedsUpdate = true;
		this.loaderDotsPart = 0.0;

		if (this.info.noMenu) {
			// $(this.textContainer).css('height', '100%');
			$(this.menuDiv).hide();
			$(this.pageCounterDiv).hide();
		// if (SETTINGS.INTRO_MODE)  else $(this.menuDiv).show();
		}
		if (this.noPages) {
			$(this.pageCounterDiv).hide();
		}
		// if 
		if (this.info.sound) SoundController.activate(this.audioBinary);

		if (this.info.menu || this.tmpMenu) {
			MenuController.addToPage(this.mainDiv);
			$(this.textContainer).toggleClass('withmenu', true);
		}
	}

	setPageCounter() {
		$(this.pageCounterDiv).html(this.page+' / '+this.numPages);
		if (!this.info.pageNumberGray && !this.info.pageNumberDark && (this.info.pageNumberWhite || (this.pageNumberWhite && this.info && this.info.params && (!this.info.params.fill|| this.info.params.fill=='full')))) {
			$(this.pageCounterDiv).css('color', '#ffffff');
			$(this.menuDiv).toggleClass('white', true);
		} else if (!this.info.pageNumberDark && !this.info.pageNumberWhite && (Utils.colorIsBlack(this.backgroundColor) || this.info.pageNumberGray)) {
			$(this.pageCounterDiv).css('color', '#a3a3a3');
			$(this.menuDiv).toggleClass('gray', true);
		}
	}

	//remove from dom
	deactivate() {
		if (!this.active) return;
		this.active = false;
		$(this.mainDiv).hide();
		this.mainShown = false;

		// if (this.pageCounterDiv) {$(this.pageCounterDiv).remove(); this.pageCounterDiv=null;}
		if (this.parentBranch) this.parentBranch.deactivateView(this.viewUid);
		if (this.info.sound) SoundController.deactivate();
		if (this.info.menu || this.tmpMenu) {
			MenuController.removeFromPage(this.mainDiv);
			this.tmpMenu = false;
			$(this.textContainer).toggleClass('withmenu', false);
		}
	}

	//
	// Main loop when active
	//
	update() {
		if (!this.started) return;


		if (this.active && this.info.orientationText) {
			var shown = false;
			if (this.info.orientationText.up !== undefined || this.info.orientationText.down !== undefined) {	
				var ot = '';
				if (MotionController.upDownPc > 0.225 && this.info.orientationText.up !== undefined) ot = this.info.orientationText.up;
				else if (MotionController.upDownPc < -0.45 && this.info.orientationText.down) ot = this.info.orientationText.down;
				else if ((this.info.orientationText.middle||this.info.orientationText.front||this.info.orientationText.straight)!==undefined) ot = (this.info.orientationText.middle||this.info.orientationText.front||this.info.orientationText.straight);
				else ot = this.currentOrientationText;

				if ((this.info.orientationText.middle||this.info.orientationText.front||this.info.orientationText.straight)==undefined && !this.currentOrientationText) {
					ot = this.info.orientationText.down || this.info.orientationText.up;
				}

				shown = !!ot;
				if (ot !== this.currentOrientationText) {
					this.currentOrientationText = ot;
					$(this.textDiv).html(Utils.replaceGender(this.currentOrientationText, AppStatus.SEPTEMBER_GENDER));
				}
			}

			if (this.info.orientationText.left !== undefined || this.info.orientationText.right !== undefined) {	
				var ot = '';
				var angle = Utils.distanceBetweenAngles(this.orientationReference, MotionController.horizontalOrientation) * Utils.directionBetweenAngles(this.orientationReference, MotionController.horizontalOrientation);
					
				if (angle > 0.45 && this.info.orientationText.left!== undefined) ot = this.info.orientationText.left;
				else if (angle < -0.45 && this.info.orientationText.right!== undefined) ot = this.info.orientationText.right;
				else if ((this.info.orientationText.middle||this.info.orientationText.front||this.info.orientationText.straight)!==undefined) ot = (this.info.orientationText.middle||this.info.orientationText.front||this.info.orientationText.straight);
				else ot = this.currentOrientationText;

				if ((this.info.orientationText.middle||this.info.orientationText.front||this.info.orientationText.straight)==undefined && !this.currentOrientationText) {
					ot = this.info.orientationText.left || this.info.orientationText.right;
				}
				if (ot == this.angleId) {
					this.numAngleId++;
				} else {
					this.numAngleId = 0;
				}
				this.angleId = ot;

				if (ot !== this.currentOrientationText && this.numAngleId >= 3) {
					this.currentOrientationText = ot;
					$(this.textDiv).html(Utils.replaceGender(this.currentOrientationText, AppStatus.SEPTEMBER_GENDER));
					shown = !!ot;
				} else {
					shown = this.currentOrientationShown;
				}
			}

			if (this.info.orientationText.northsouth !== undefined) {
				var ot = '';
				var isNorth = this.currentOrientationText === this.info.orientationText.northsouth;
				var northBoundary = isNorth ? 0.225 : 0.2;
				var pc = this.targetNorth ? MotionController.northPc : MotionController.southPc;
				if (this.info.orientationText.northsouth && pc < northBoundary && pc > -northBoundary ) ot = this.info.orientationText.northsouth;
				else ot = this.info.orientationText.opposite;

				shown = !!ot;
				if (ot !== this.currentOrientationText) {
					this.currentOrientationText = ot;
					$(this.textDiv).html(Utils.replaceGender(this.currentOrientationText, AppStatus.SEPTEMBER_GENDER).replace('{direction}', SETTINGS.LANGUAGE=='fr'?(this.targetNorth?'nord':'sud'):(this.targetNorth?'north':'south') ));
				}
			}	

			if (this.info.orientationText.north !== undefined || this.info.orientationText.south !== undefined) {	
				var ot = '';
				var isNorth = this.currentOrientationText === this.info.orientationText.north;
				var northBoundary = isNorth ? 0.225 : 0.2;
				if (this.info.orientationText.north && MotionController.northPc < northBoundary && MotionController.northPc > -northBoundary ) ot = this.info.orientationText.north;
				else ot = this.info.orientationText.south;

				shown = !!ot;
				if (ot !== this.currentOrientationText) {
					this.currentOrientationText = ot;
					$(this.textDiv).html(Utils.replaceGender(this.currentOrientationText, AppStatus.SEPTEMBER_GENDER));
				}
			}

			if (shown !== this.currentOrientationShown) {
				this.currentOrientationShown = true;
				if (this.currentOrientationShown) $(this.textDiv).show(); else $(this.textDiv).hide();
			}
		}


		//
		// Pulsating background
		//
		if (this.active && !this.isReady()) {
			AppStatus.frameNeedsUpdate = true;
			this.viewRendered = false;
			this.loaderBackgroundPc = Math.min(this.loaderBackgroundPc*1.025+0.025, 1.0);
			this.loaderBackgroundColor = this.loaderBackgroundColor||new THREE.Color(this.backgroundColor);
			this.loaderOriginalBackgroundColor = this.loaderOriginalBackgroundColor||this.loaderBackgroundColor.clone();
			var dir = this.loaderOriginalBackgroundColor.r > 0.5 ? 1 : -1;
			this.loaderBackgroundColor.lerp(
				this.loaderOriginalBackgroundColor.clone().setRGB(
					// this.loaderOriginalBackgroundColor.r - 0.125*dir + Math.sin(performance.now()/250)*0.1,
					// this.loaderOriginalBackgroundColor.g - 0.125*dir + Math.sin(performance.now()/250)*0.1,
					// this.loaderOriginalBackgroundColor.b - 0.125*dir + Math.sin(performance.now()/250)*0.1

					this.loaderOriginalBackgroundColor.r - 0.125*dir + Math.abs( (performance.now()/400)%2.0 - 1.0) *0.1,
					this.loaderOriginalBackgroundColor.g - 0.125*dir + Math.abs( (performance.now()/400)%2.0 - 1.0)*0.1,
					this.loaderOriginalBackgroundColor.b - 0.125*dir + Math.abs( (performance.now()/400)%2.0 - 1.0)*0.1
				),
				this.loaderBackgroundPc
			);

			this.loadingCount++;
			if (this.loadingCount==2)	{
				$(this.pageCounterDiv).css('color', '#a3a3a3');
				$(this.menuDiv).toggleClass('gray', true).toggleClass('white', false);
				$(this.pageCounterDiv).html( (SETTINGS.LANGUAGE=='fr' ? 'Chargement' : 'Loading') );
			}
			this.loaderBackground = true;


		} else if (this.loaderBackground) {

			$(this.menuDiv).toggleClass('gray', false).toggleClass('white', false);

			this.setPageCounter();
			AppStatus.frameNeedsUpdate = true;
			this.viewRendered = false;
			this.loaderBackground = false;
			this.loaderBackgroundPc = 0;
		}
	}

	//
	// Render to screen, mostly used for webgl effects
	//
	render() {
		if (!this.active) return;
		this.viewRendered = true;
		RendererController.setViewport(this.positionX);
		Utils.clearPlane.material.color.set(this.backgroundColor);
		if (this.loaderBackground) {
			Utils.clearPlane.material.color.set(this.loaderBackgroundColor);
		}
		renderer.render(Utils.clearScene, Utils.orthographicCamera, this.renderTarget, false);
	}

	prepare() {}

	//
	// Modify position between -1 and 1
	//
	setPosition(pos) {
		this.positionX = pos;

		//
		//  Activate/deactivate/stop/start en fonction de la position
		//  Move div if not snapped
		//
		if (this.positionX > 2.0 || this.positionX <= -2.0) {
			if (this.active) this.deactivate();
			if (this.started) this.stop();
		} else {
			if (!this.started) this.start();
		}
		if (this.positionX < 0.9999 && this.positionX > -0.9999 && !this.locked) {
			if (!this.active) this.activate();
		} else {
			if (this.active) this.deactivate();
		}

		//
		// Css update
		//
		if (this.active) {
			if (!SETTINGS.EDIT_MODE) this.mainDiv.style['transform'] = 'translate('+(this.positionX*100.0)+'%,0)';
			// $(this.mainDiv).css('transform', 'translate('+(this.positionX*100.0)+'%,0)');
			if (this.positionX>-0.5 && this.positionX < 0.5) AppStatus.mainView = this;
		}
	}

	setPage(page, numPages) {
		if (this.page !== page || this.numPages!==numPages) {
			this.page = page;
			this.numPages = numPages;

			// if (this.active && this.pageCounterDiv) $(this.pageCounterDiv).html(this.page+' / '+this.numPages);

		}
	}

	unlockRight() {
		this.lockRight = false;
	}

	isReady() {
		return true; //!this.hasTextAnalysis || this.textAnalysisLoaded;
	}

	renderWidth() {
		return (!SETTINGS.EDIT_MODE) ? RendererController.renderWidth : this.viewFbo.width;
	}
	renderHeight() {
		return (!SETTINGS.EDIT_MODE) ? RendererController.renderHeight : this.viewFbo.height;
	}
	shouldRender() {
		if (!SETTINGS.EDIT_MODE) return true;
		return (!this.isReady || this.isReady()) && (!this.viewRendered || this.mouseIsOver);
	}
	getDivPosition() {
		this.divPosition = this.divPosition || $(this.mainDiv).position();
		return this.divPosition;
	}
	
};
MottoView.currentId = 0;	

export default MottoView;


