import SETTINGS from './Settings.js';

function AnalyticsController() {
	var nfb_ua_main_account = "UA-32257069-1";
    if (location.hostname.indexOf('dev') >= 0 || location.href.indexOf('september') >= 0 || location.hostname.indexOf('localhost') >= 0 || location.hostname.indexOf('stage') >= 0){
    	nfb_ua_main_account = "UA-32257069-11";
    }
	//Google Universal Analytics setup https://developers.google.com/analytics/devguides/collection/analyticsjs/
    (function(i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        i[r] = i[r] || function() {
            (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date();
        a = s.createElement(o),
            m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m)
    })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');


	var _lngType = SETTINGS.LANGUAGE;
	var _domainName = SETTINGS.LANGUAGE === 'fr' ? '.onf.ca' : '.nfb.ca';
	var _intType = 'interactif';
	var _projectName = "motto";
	var _projectType = 'website'; //SETTINGS.EVENEMENT_MODE ? "installation" : "website";

	ga('create', nfb_ua_main_account, 'auto', {'name':'onfglobal', 'allowLinker': true, cookieDomain: _domainName, legacyCookieDomain: _domainName});
	ga('create', 'UA-42015401-45', 'auto', {'name':'onfproject', 'allowLinker': true});
	ga('onfglobal.set', 'language', _lngType);
	ga('onfproject.set', 'language', _lngType);
	// ga('onfglobal.send', 'pageview', '/' + _intType + '/' + _projectName + '/' + _projectType);// /interactif/reve/website
	// ga('onfproject.send', 'pageview', '/' + _intType + '/' + _projectName + '/' + _projectType); // /interactif/reve/website
	
	function trackEventInternal(category, action) {
		ga('onfglobal.send', {
			dimension1: SETTINGS.LANGUAGE,
			hitType: 'event',
			eventCategory: 'interactif',
			eventAction: category+(action?('_'+action):''),
			eventLabel: 'MOTTO' //+'_'+_projectType
		});
		ga('onfproject.send', {
			dimension1: SETTINGS.LANGUAGE,
			hitType: 'event',
			eventCategory: category,
			eventAction: action,
			eventLabel: 'MOTTO' //+'_'+_projectType
		});
	};

	// General GA Universal Page view
    this.pageview = function(page) {
    	var _pageview = '/' + _intType + '/' + _projectName + '/' + _projectType;
	    if (page && page.length>1) {
	        if (page.charAt(0) != "/"){
	            page = '/' + page;
	        }
	        _pageview = _pageview + page;
	    }
    	ga('onfglobal.send', 'pageview', _pageview);
    	ga('onfproject.send', 'pageview', _pageview);
	};

	this.trackEvent = function(category,action) {
		setTimeout(trackEventInternal.bind(window,category,action), 10);
	};
	this.trackEventDirectly = function(category,action) {
		trackEventInternal(category,action);
	};
	// if (!SETTINGS.isMobile) this.pageview('desktop');
	// else
	this.pageview( (/index/gi.test(window.location.pathname)||window.location.pathname=='/'||!window.location.pathname) ? '/index.html' : window.location.pathname);
	// trackEventInternal(_intType, 'auto_begin');

};
window.AnalyticsController = window.AnalyticsController||new AnalyticsController();

export default window.AnalyticsController;
