
import * as THREE from 'three';

import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import RendererController from '../controllers/RendererController.js';
import SETTINGS from '../controllers/Settings.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';

import Montage from './Montage.js';
import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import Fbo from '../utils/Fbo.js';

import SpritesheetVideo from './SpritesheetVideo.js';

class FaceRenderer {

	constructor() {
		this.faceDivs = [];
		this.hasFaces = false;
		this.loadingFaceInfo = false;
		this.facesInfo = null;
		this.faceScene = new THREE.Scene();
		this.facePlanes = [];
		this.hidden = false;

		this.contentDiv = null;
		this.isLive = false;
	}

	setup(params) {
		this.params = params;

		this.faceType = (this.params.faces && this.params.faces.type)?this.params.faces.type:'average';
		this.noFaces = !!(this.params.noFaces || (this.params.faces && this.params.faces.disabled));
		this.facesInfo = this.params.facesInfo;
		this.params.faces = this.params.faces||{};
		this.replaceDate = this.params.faces && this.params.faces.text && /{date}/gi.test(this.params.faces.text);
		if (this.facesInfo && this.facesInfo.isLive) this.isLive = true;

		this.shouldScaleDown  = this.params.fill == 'center' || this.params.fill=='square';
		if (this.shouldScaleDown) {
			var paddingPc = parseFloat(this.params.padding||'0',10);
			this.scaleDown = 1.0-paddingPc;
			this.scaleDownFont = this.scaleDown + paddingPc*0.33;
			if (!this.scaleDown || this.scaleDown>=0.99) this.shouldScaleDown = false;
		}
	}

	setInfo(info) {
		this.facesInfo = info;
		if (this.facesInfo && this.facesInfo.isLive) this.isLive = true;
	}


	preload(batchName) {
		if (this.facesMontage) {
			this.facesMontage.deactivate();
			this.facesMontage.dispose();
			this.facesMontage = null;
		}
		if (!this.noFaces && this.params.faces && this.faceType=="montage" && this.params.faces.montageParams) {
			this.facesMontage  = new Montage(this.params.faces.montageParams, batchName);
			this.facesMontage.replaceUserVideos();
			this.facesMontage.prepare();
		}
	}


	prepare() {
		if (this.facesMontage) {
			this.facesMontage.replaceUserVideos();
			this.facesMontage.prepare();
		}
		// if (!this.faceFbo && this.params.faces && this.faceType=="average") this.faceFbo = FaceRenderer.getFbo(36, 64);
	}

	activate(contentDiv) {
		if (contentDiv) this.contentDiv = contentDiv;
		if (this.facesMontage) {
			this.facesMontage.replaceUserVideos();
			this.facesMontage.prepare();
			this.facesMontage.activate();
		}
		this.hasDivs = false;
		this.shouldUpdate = true;
		this.currentFrame = -1;
	}

	deactivate() {
		for (var i=0; i<this.faceDivs.length; i++) {
			if (this.faceDivs[i]) {
				if (this.faceDivs[i].container) $(this.faceDivs[i].container).remove();
				if (this.faceDivs[i].div) $(this.faceDivs[i].div).remove();
			}
		}
		this.hasDivs = false;
		this.faceDivs = [];
		this.faceScene = new THREE.Scene();
		this.facePlanes = [];
		if (this.facesMontage) this.facesMontage.deactivate();
	}


	dispose() {
		this.shouldUpdate = true;
		if (this.faceFbo) {
			FaceRenderer.disposeFbo(this.faceFbo);
			this.faceFbo = null;
		}
		if (this.facesMontage) {
			this.facesMontage.deactivate();
			this.facesMontage.dispose();
		}
	}


	//----------------
	//
	// Update faces position
	//
	//---------------
	setCurrentFrame(currentFrame) {

		if (!this.facesInfo || this.noFaces) return;

		this.shouldUpdate = this.shouldUpdate || (this.currentFrame !== currentFrame);
		this.currentFrame = currentFrame;
		this.faces = null;
		if (this.facesInfo && this.facesInfo.faces) this.faces = this.facesInfo.faces[currentFrame];

		// console.log(currentFrame, this.faces);
	}

	updateLiveInfo(faces) {
		this.shouldUpdate = true;
		this.faces = faces;
		this.isLive = true;
	}

	//----------------
	//
	// Update faces divs & planes
	//
	//---------------
	update() {
		if (!this.shouldUpdate) return;
		this.shouldUpdate = false;
		var faces = this.faces;

		window.f = this;

		if (faces && faces.length && !this.hidden) {

			if (!this.faceFbo && this.params.faces && this.faceType=="average") this.faceFbo = FaceRenderer.getFbo(36, 64);

			if (this.facesMontage && !this.facesMontage.isReady()) {
				this.facesMontage.prepare();
			}
			if (this.facesMontage) this.facesMontage.update();

			this.hasDivs = (this.faceType === 'text' ||(this.params.faces&&this.params.faces.text)); 
			


			for (var i=0; i<faces.length; i++) {
				var box = faces[i].face;

				if (!this.facePlanes[i]) {
					var mat = (this.facesMontage) ? MaterialController.getMaterial('faceMaterial') :
						(this.faceType === 'camera') ? MaterialController.getMaterial('faceMaterialCamera') : 
						(this.faceType === 'average') ? (this.isLive ? MaterialController.getMaterial('faceMaterialAverage') : MaterialController.getMaterial('faceMaterialAverage_center')) :
						MaterialController.getMaterial('whiteFaceMaterial');

					var p = new THREE.Mesh(Utils.planeGeometry, mat);
					p.material.uniforms.tint.value = new THREE.Color('#ffffff');
					p.visible = false;
					this.faceScene.add(p);
					this.facePlanes[i] = p;
					this.hasFaces = true;

					if (this.hasDivs && this.contentDiv && !this.faceDivs[i]) {
						var dc = document.createElement('div');
						$(dc).addClass('spritesheet-overlay-text-container');
						$(this.contentDiv).append(dc);

						var d = document.createElement('div');
						$(d).addClass('spritesheet-overlay-text');
						$(d).css('background-color', this.facesMontage ? 'transparent' : (this.params.faces.color || '#ebebeb'));
						if (this.params.faces.textColor) $(d).css('color', this.params.faces.textColor || '#000000');
						if (this.faceType == 'camera' ||this.faceType === 'average') {$(d).css('background', 'none'); $(dc).css('background', 'none');}
						$(dc).append(d);

						var txt = Utils.replaceGender(this.params.faces.text||'', AppStatus.SEPTEMBER_GENDER);
						if (this.replaceDate) txt = txt.replace(/{date}/gi, (SETTINGS.FORCE_SPECIFIC_DAY||new Date()).toLocaleDateString(SETTINGS.LANGUAGE, { year: 'numeric', month: 'long', day: 'numeric' }))
						$(d).html(txt);
						this.faceDivs[i] = {
							container: dc,
							div:d,
							visible: false,
							fontSize: 0,
							text: null,
							motion: false,
							ratioHidden: false
							// fit: fitty(d, {
							//   minSize: 12.32,
							//   maxSize: 19.36
							// })
						};
							// window.fit = this.faceDivs[i].fit;
					}
				}


				if (this.params.faces.textMotion && !SETTINGS.OLD_PHONE_MODE ) {
					if (FaceTracker.motionPc>1.5 && !this.faceDivs[i].motion) {
						this.faceDivs[i].motion = true;
						$(this.faceDivs[i].div).html(Utils.replaceGender(this.params.faces.textMotion||'', AppStatus.SEPTEMBER_GENDER));
					} else if (FaceTracker.motionPc<0.7 && this.faceDivs[i].motion) {
						this.faceDivs[i].motion = false;
						$(this.faceDivs[i].div).html(Utils.replaceGender(this.params.faces.text||'', AppStatus.SEPTEMBER_GENDER));
					}
				}


				// this.faceType = 'text';

				var p = this.facePlanes[i];
				p.scale.set(box[2],box[3],1.0);
				// p.scale.multiplyScalar( 0.9 * (this.params.faces.scale||1.0));
				p.position.set(box[0],box[1], 0);

				if (this.shouldScaleDown) {
					this.scaleDown
						p.position.x = (p.position.x-0.5)*this.scaleDown+0.5;
						p.position.y = (p.position.y-0.5)*this.scaleDown+0.5;
						p.scale.x *= this.scaleDown;
						p.scale.y *= this.scaleDown;
				}
			
				// this.isLive = true;
				if (this.params.faces.color && this.params.faces.color!=="transparent") p.material.uniforms.tint.value.set(this.params.faces.color || '#ebebeb');
				p.visible = this.isLive || (this.faceType == 'montage');


				// var renderWidth = RendererController.renderWidth;
				// var renderHeight = RendererController.renderHeight;
				var renderWidth = RendererController.rendererWidth;
				var renderHeight = RendererController.rendererHeight;
				if (SETTINGS.OLD_PHONE_MODE) {
					renderWidth = RendererController.renderWidth;
					renderHeight = RendererController.renderHeight;
				}

				if (SETTINGS.STORYBOARD_MODE) {
					renderWidth = 162;
					renderHeight = 288;
				}

				if (this.facesMontage) p.material.uniforms.tDiffuse.value = this.facesMontage.getTexture();

				if (this.faceType === 'camera') {
					if (!CameraController.isReady()) {
						CameraController.start('front');
						p.material.uniforms.tDiffuse.value = Utils.blackTexture;
					} else {
						// CameraController.texture.needsUpdate = CameraController.texture.updateFrame != AppStatus.currentFrame;
						// CameraController.texture.updateFrame = AppStatus.currentFrame;
						CameraController.updateTexture();
						p.material.uniforms.tDiffuse.value = CameraController.texture;
					}
				}

				if (this.faceType === 'average') {
					p.material.uniforms.uvCenter.value.set(box[0],box[1]);
					p.material.uniforms.faceScale.value.set(box[2], box[3]);
					p.material.uniforms.tDiffuse.value = this.faceFbo.texture.texture;
					// Utils.autoReloadShaderManual(p.material, 'shaders/tmp.vert', 'shaders/tmp.frag');
				}

				if (this.facesMontage || this.faceType === 'camera' || this.faceType === 'average') {
					if (p.material.uniforms.tDiffuse.value) {
						var targetRatio = ((renderWidth/renderHeight) / (9/16));
						if (Math.abs(p.scale.x / p.scale.y) > targetRatio) {
							p.material.uniforms.uvScale.value.set(1.0,  targetRatio / (Math.abs(p.scale.x / p.scale.y)))
						} else {
							p.material.uniforms.uvScale.value.set(Math.abs(p.scale.x / p.scale.y) / targetRatio, 1.0)
						}	
					}
				}

				if (this.hasDivs && this.contentDiv && this.faceDivs[i]) {

					var w = (p.scale.x*renderWidth);
					var h = (Math.abs(p.scale.y)*renderHeight);

					// $(this.faceDivs[i].container).css({
					// 	'width': w+'px',
					// 	'height': h+'px'
					// });

					// var fontSize =  Utils.clamp((w / 10), 0.7, 1.1); // * this.params.faces.scale;
					// $(this.faceDivs[i].d).css('font-size', fontSize +'em');
					// w = Math.max(w, $(this.faceDivs[i].container).width()) - 0.5;
					// h = Math.max(h, $(this.faceDivs[i].div).height());


					$(this.faceDivs[i].container).css({
						'transform': 'translate('+((p.position.x)*renderWidth)+'px,'+((p.position.y)*renderHeight)+'px)',
						'width': w+'px',
						// 'width': 'auto',
						// 'height': 'auto'
						'height': h+'px'
					});

						
					// - h*3/4

					$(this.faceDivs[i].div).css('font-size', '1.15em');
					if (this.shouldScaleDown) $(this.faceDivs[i].div).css('font-size', (this.scaleDownFont*1.15)+'em');

					// if (this.faceDivs[i].ratioHidden) $(this.faceDivs[i].div).html(Utils.replaceGender(this.params.faces.text||'', AppStatus.SEPTEMBER_GENDER));
					var tw = $(this.faceDivs[i].div).width();
					var th = $(this.faceDivs[i].div).height();
					// if (this.faceDivs[i].ratioHidden) $(this.faceDivs[i].div).html('');
					var fontSize = 21.16;

				

					
					if (Math.abs(tw - w) > 1 || Math.abs(th - h) > 1) {
						// console.log("fitting ratio", w/tw);
						var ratio = Math.min( (w)/tw, (h)/th);

						if (ratio < 0.075 && !this.params.faces.alwaysShow) {
							if (!this.faceDivs[i].ratioHidden) {
								$(this.faceDivs[i].div).html('');
								this.faceDivs[i].ratioHidden = true;
								// console.log(i, "hidden");
							}
						} else {
							if (this.faceDivs[i].ratioHidden) {
								// console.log(i, "shown");
								$(this.faceDivs[i].div).html(Utils.replaceGender(this.params.faces.text||'', AppStatus.SEPTEMBER_GENDER));
								tw = $(this.faceDivs[i].div).width();
								th = $(this.faceDivs[i].div).height();
								ratio = Math.min( (w)/tw, (h)/th);
							}
							this.faceDivs[i].ratioHidden = false;
							ratio = Utils.clamp(ratio, 0.7/1.15, 1.0);
							$(this.faceDivs[i].div).css('font-size', (1.15*ratio)+'em');
							if (this.shouldScaleDown) $(this.faceDivs[i].div).css('font-size', (this.scaleDownFont*1.15*ratio)+'em');
							fontSize *= ratio;
						}
						
						// tw = $(this.faceDivs[i].div).width();
						// th = $(this.faceDivs[i].div).height();
						// // console.log((th/tw) / (h/w));
						// if ( (th/tw) / (h/w) >= 2.0) {
						// 	var nratio = (th/tw); // / (h/w);
						// 	// nratio = (tw/th) / (w/h);
						// 	// console.log("ratio",nratio, w*nratio*2.0);
						// 	$(this.faceDivs[i].container).css('width', (w*nratio*2.5)+'px');
						// }
					}

					var offsetY = 0;
					if (SETTINGS.STORYBOARD_MODE) {
						offsetY = -window.scrollY;
					}
					tw = $(this.faceDivs[i].div).width();
					th = $(this.faceDivs[i].div).height();
					
					var prev = p.scale.clone();
					p.scale.x = tw/renderWidth + fontSize/renderWidth*0.5;
					p.scale.y = (th/renderHeight + fontSize/renderHeight*0.5) * Math.sign(p.scale.y);


					if (this.params.faces.text) {//prev.x>0.0 && (Math.abs(p.scale.x/prev.x-1.0)>0.05||Math.abs(p.scale.y/prev.y-1.0)>0.1) && (this.params.faces.text||'').length>0) {
						p.visible = true;
						p.scale.multiplyScalar(1.1);
					}


					if (!this.isLive) {
						if (renderWidth/renderHeight > 9/16) {
							var ratio =  (9/16)/(renderWidth/renderHeight);
							ratio = 1.0/ratio;
							var w = (p.scale.x*renderWidth);
							var h = (Math.abs(p.scale.y)*renderHeight);
							$(this.faceDivs[i].container).css({
								'transform': 'translate('+((p.position.x)*renderWidth)+'px,'+((p.position.y-0.5)*(renderHeight*ratio) + renderHeight/2+ offsetY )+'px)',
								'width': w+'px',
								'height': h+'px'
							});
						} else {
							var ratio =  (9/16)/(renderWidth/renderHeight);
							var w = (p.scale.x*renderWidth);
							var h = (Math.abs(p.scale.y)*renderHeight);
							$(this.faceDivs[i].container).css({
								'transform': 'translate('+((p.position.x-0.5)*(renderWidth*ratio) + renderWidth/2 )+'px,'+((p.position.y)*(renderHeight) + offsetY )+'px)',
								'width': w+'px',
								'height': h+'px'
							});
						}
					} else {
						var w = (p.scale.x*renderWidth);
						var h = (Math.abs(p.scale.y)*renderHeight);
						$(this.faceDivs[i].container).css({
							'transform': 'translate('+(p.position.x * renderWidth )+'px,'+((p.position.y)*(renderHeight) + offsetY )+'px)',
							'width': w+'px',
							'height': h+'px'
						});
					}
					


					


					// textFit(this.faceDivs[i].container, {
					// 	alignVert: true, // if true, textFit will align vertically using css tables
					//     alignHoriz: true, // if true, textFit will set text-align: center
					//     multiLine: true, // if true, textFit will not set white-space: no-wrap
					//     detectMultiLine: true, // disable to turn off automatic multi-line sensing
					//     minFontSize: 6,
					//     maxFontSize: 18,
					//     reProcess: true, // if true, textFit will re-process already-fit nodes. Set to 'false' for better performance
					//     widthOnly: false, // if true, textFit will fit text to element width, regardless of text height
					//     alignVertWithFlexbox: true, // if true, textFit will use flexbox for vertical alignment
					// })

					if (!this.faceDivs[i].visible) {
						$(this.faceDivs[i].container).css('display', 'table');
						this.faceDivs[i].visible = true;
					}

					var n = 0;
					// console.log($(this.faceDivs[i].div).width(), w);
					// while ($(this.faceDivs[i].div).width() > w*1.1 && n<100) {
					// 	this.faceDivs[i].fit.fit();
					// 	console.log("fit");
					// 	n++;
					// }
				} else if (this.isLive) {

					p.scale.x += 21.16/renderWidth*0.8;
					p.scale.y += 21.16/renderHeight*0.8;

				}

				// console.log(p.scale.x, p.scale.y, box[2], box[3]);
			}
			
			for (var i=faces.length; i<this.facePlanes.length; i++) {
				this.facePlanes[i].visible = false;
				if (this.faceDivs[i] && this.faceDivs[i].visible) {
					if (this.faceDivs[i].container) $(this.faceDivs[i].container).hide();
					this.faceDivs[i].visible = false;
				}
			}

		} else {
			for (var i=0; i<this.facePlanes.length; i++) {
				this.facePlanes[i].visible = false;
				if (this.faceDivs[i] && this.faceDivs[i].visible) {
					if (this.faceDivs[i].container) $(this.faceDivs[i].container).hide();
					this.faceDivs[i].visible = false;
				}
			}
		}
	}

	//----------------
	//
	// Render faces divs & planes over target
	//
	//---------------
	render(target) {
		if (this.facePlanes.length <= 0) return;
		if (this.facesMontage) this.facesMontage.render();
		renderer.render(this.faceScene, Utils.topLeftCamera, target, false);
	}
}





FaceRenderer.fboPool = [];
FaceRenderer.getFbo = function(videoWidth, videoHeight) {
	if (FaceRenderer.fboPool.length > 0) {
		var pooled = FaceRenderer.fboPool.pop();
		pooled.resize(videoWidth, videoHeight);
		return pooled;
	}
	var fbo = new Fbo( videoWidth, videoHeight, {
		minFilter:THREE.LinearFilter,
		magFilter:THREE.LinearFilter,
		format:THREE.RGBAFormat,
		type:THREE.UnsignedByteType,
		depthBuffer: false,
		stencilBuffer: false,
		premultiplyAlpha: false,
		generateMipmaps: false,
		forceClear: false,
		pingpong: false,
		renderer:renderer
	});
	return fbo;
};
FaceRenderer.disposeFbo = function(fbo) {
	if (FaceRenderer.fboPool.length < 16) {
		FaceRenderer.fboPool.push(fbo);
	} else {
		fbo.dispose();
	}
};

FaceRenderer.cleanupFboPool = function() {
	for (var i=0; i<FaceRenderer.fboPool.length; i++) {
		FaceRenderer.fboPool[i].dispose();
		FaceRenderer.fboPool[i] = null;
	}
	FaceRenderer.fboPool = [];
};

window.FaceRenderer = window.FaceRenderer||FaceRenderer;
export default window.FaceRenderer;


