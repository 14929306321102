import SETTINGS from '../controllers/Settings.js';
import * as THREE from 'three';

import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import RendererController from '../controllers/RendererController.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';

import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import SpritesheetVideo from './SpritesheetVideo.js';



function disableScroll() {
	var main = $('#video-fullscreen-overlay')[0];
	main.ontouchmove  = main.onwheel =  function(e) {
		e.preventDefault();
		e.returnValue = false;
		return false;
	}
}

function enableScroll() {
	var main = $('#video-fullscreen-overlay')[0];
	main.ontouchmove = main.onwheel = null;
}



//------------------------
class SpritesheetVideoFaceEditor extends SpritesheetVideo {
	constructor(_info, batchName) {
		super(_info, batchName);
		console.log("Created face editor");
		this.sceneSetup = false;
		this.originalFaces = null;
	}

	preload(batchName) {
		super.preload(batchName);
	}

	prepare() {
		super.prepare();
	}

	update(opt) {
		super.update(opt);
		this.currentTime = this.currentTime%(this.numFrames/24);
		if (this.isReady() && this.contentDiv) {
		}
	}

	updateFaces() {}

	updateFacesFrame(cf) {

		window.sp = this;
		//
		// Setup the scene
		//
		if (!this.sceneSetup) {
			enableScroll();
			this.sceneSetup = true;
			this.allFacesDiv = document.getElementById('all-faces');
			$(this.allFacesDiv).empty();
			if (!this.facesInfo || (this.facesInfo && !this.facesInfo.faces)) this.facesInfo = this.facesInfo||{"faces": []};
			this.facesScene = new THREE.Scene();
			this.currentFaces = [];
			this.selectedFace = null;
			this.lastSelectedFace = null;
		}

		this.facesFrames = cf;


		//
		// Add/Update/Remove all faces
		//
		this.facesInfo.faces[cf] = this.facesInfo.faces[cf]||[];
		if (!this.originalFaces) {
			this.originalFaces = Utils.clone(this.facesInfo)
		}
		var faces = this.facesInfo.faces[cf];
		
		console.log("OK",this.facesInfo)


		//
		// Delete old faces
		//
		var foundSelected = false;
		var foundFaces = [];
		for (var i=0; i<this.currentFaces.length; i++) {
			//update old faces
			var found = null;
			for (var j=0; j<faces.length; j++) {
				if (faces[j].id == this.currentFaces[i].id) {
					foundFaces[j] = true;
					found = true;
					this.currentFaces[i].plane.position.set(faces[j].face[0],faces[j].face[1],0);
					this.currentFaces[i].plane.scale.set(faces[j].face[2],faces[j].face[3],1);
					this.currentFaces[i].targetFaceObj = faces[j].face;
					// this.updateTrail(this.currentFaces[i]);
					if (this.lastSelectedFace && this.lastSelectedFace.id == this.currentFaces[i].id) {
						this.lastSelectedFace = this.currentFaces[i];
						foundSelected
					}
				}
			}

			//delete old faces
			if (!found) {
				this.facesScene.remove(this.currentFaces[i].plane);
				for (var k=0; k<this.currentFaces[i].trailPrev.length; k++) {
					this.facesScene.remove(this.currentFaces[i].trailPrev[k]);
				}
				for (var k=0; k<this.currentFaces[i].trailNext.length; k++) {
					this.facesScene.remove(this.currentFaces[i].trailNext[k]);
				}
				$(this.currentFaces[i].div).remove();
				this.currentFaces.splice(i,1);
				i--;
			}
		}
		if (!foundSelected) this.lastSelectedFace = null;

		//
		// Add new faces
		//
		for (var j=0; j<faces.length; j++) {
			if (!foundFaces[j]) {

				var planeContainer = new THREE.Object3D();

				var plane = new THREE.Mesh(Utils.planeGeometry, new THREE.MeshBasicMaterial({
					color: new THREE.Color(0xdddddd),
					transparent: true,
					depthTest:false,
					depthWrite: false,
					opacity: 0.75,
					blending: THREE.NormalBlending,
					side: THREE.DoubleSide
				}));

				Utils.segmentGeometry = Utils.segmentGeometry||new THREE.EdgesGeometry( new THREE.PlaneBufferGeometry(1,1,1,1) );
				var border = new THREE.LineSegments(Utils.segmentGeometry, new THREE.LineBasicMaterial( { color: 0x000000, linewidth: 6, side: THREE.DoubleSide, blending: THREE.NoBlending, transparent:false, depthTest:false, depthWrite:false } ));
				border.scale.set(1.02,1.02,1.0);

				var div = $(`
					<div class="face-info"><div class="face-text">face ${faces[j].id}</div><div class="smooth-face face-button">smooth</div> <div class="prev-face face-button">from prev</div> <div class="delete-face face-button">X</div></div>
				`);
				$(this.allFacesDiv).append(div);
				

				var faceObj = {
					id:faces[j].id,
					div:div,
					plane: planeContainer,
					trailPrev: [],
					trailNext: [],
					mat: plane.material,
					targetFaceObj: faces[j].face,
					sp: this
				};
				this.currentFaces.push(faceObj);



				// for (var i=0; i<2; i++) {
				// 	var trailPlane = new THREE.Mesh(Utils.planeGeometry, new THREE.MeshBasicMaterial({
				// 		color: new THREE.Color(0xdddddd),
				// 		transparent: true,
				// 		depthTest:false,
				// 		depthWrite: false,
				// 		opacity: 0.75 * (2-i)/4,
				// 		blending: THREE.NormalBlending,
				// 		side: THREE.DoubleSide
				// 	}));
				// 	trailPlane.visible = false;
				// 	this.facesScene.add(trailPlane);
				// 	faceObj.trailPrev.push(trailPlane);
				// }
				// for (var i=0; i<2; i++) {
				// 	var trailPlane = new THREE.Mesh(Utils.planeGeometry, new THREE.MeshBasicMaterial({
				// 		color: new THREE.Color(0xdddddd),
				// 		transparent: true,
				// 		depthTest:false,
				// 		depthWrite: false,
				// 		opacity: 0.75 * (2-i)/4,
				// 		blending: THREE.NormalBlending,
				// 		side: THREE.DoubleSide
				// 	}));
				// 	trailPlane.visible = false;
				// 	this.facesScene.add(trailPlane);
				// 	faceObj.trailNext.push(trailPlane);
				// }
				// this.updateTrail(faceObj);
				planeContainer.add(plane);
				planeContainer.add(border);
				this.facesScene.add(planeContainer);


				var border = new THREE.LineSegments(Utils.segmentGeometry, new THREE.LineBasicMaterial( { color: 0x000000, linewidth: 6, side: THREE.DoubleSide, blending: THREE.NoBlending, transparent:false, depthTest:false, depthWrite:false } ));
				border.scale.set(0.33,1.0,1.0);
				planeContainer.add(border);
				var border = new THREE.LineSegments(Utils.segmentGeometry, new THREE.LineBasicMaterial( { color: 0x000000, linewidth: 6, side: THREE.DoubleSide, blending: THREE.NoBlending, transparent:false, depthTest:false, depthWrite:false } ));
				border.scale.set(1.0,0.33,1.0);
				planeContainer.add(border);




				//
				// Delete face
				//
				$(div).find('.delete-face').on('click', function(e) {
					
					var sp = this.sp;
					sp.facesScene.remove(this.plane);
					$(this.div).remove();
					var faces = sp.facesInfo.faces[sp.facesFrames];
					for (var i=0; i<faces.length; i++) {
						if (faces[i].face == this.targetFaceObj) {
							faces.splice(i, 1);
							i--;
						}
					}
					for (var i=0; i<sp.currentFaces.length; i++) {
						if (sp.currentFaces[i] == this) {
							sp.currentFaces.splice(i,1);
							i--;
						}
					}
					console.log("delete",this);

				}.bind(faceObj));


				//
				// Smoothing functions for obj
				//
				$(div).find('.prev-face').on('click', function(e) {
					var found = false;
					var faces = sp.facesInfo.faces[sp.facesFrames-1];
					for (var i=0; i<faces.length; i++) {
						if (faces[i].id == this.id || faces.length==1) {
							found = true;
							this.targetFaceObj[0] = faces[i].face[0];
							this.targetFaceObj[1] = faces[i].face[1];
							this.targetFaceObj[2] = faces[i].face[2];
							this.targetFaceObj[3] = faces[i].face[3];
						}
					}
					if (!found) {
						var faces = sp.facesInfo.faces[sp.facesFrames+1];
						for (var i=0; i<faces.length; i++) {
							if (faces[i].id == this.id || faces.length==1) {
								found = true;
								this.targetFaceObj[0] = faces[i].face[0];
								this.targetFaceObj[1] = faces[i].face[1];
								this.targetFaceObj[2] = faces[i].face[2];
								this.targetFaceObj[3] = faces[i].face[3];
							}
						}
					}
					this.sp.updateFacesFrame(this.sp.facesFrames);
				}.bind(faceObj));

				
				$(div).find('.smooth-face').on('click', function(e) {
					var foundPrev = false;
					var foundNext = false;
					var faces = sp.facesInfo.faces[sp.facesFrames-1];
					if (faces) {
						for (var i=0; i<faces.length; i++) {
							if (faces[i].id == this.id || faces.length==1) {
								foundPrev = faces[i].face;
							}
						}
					}
					
					faces = sp.facesInfo.faces[sp.facesFrames+1];
					if (faces) {
						for (var i=0; i<faces.length; i++) {
							if (faces[i].id == this.id || faces.length==1) {
								foundNext = faces[i].face;
							}
						}
					}

					var val = 0.33;
					if (foundNext && foundPrev) val = 0.25;
					if (foundPrev && !foundNext) {
						this.targetFaceObj[0] = this.targetFaceObj[0] * (1.0-val) + foundPrev[0] * val;
						this.targetFaceObj[1] = this.targetFaceObj[1] * (1.0-val) + foundPrev[1] * val;
						this.targetFaceObj[2] = this.targetFaceObj[2] * (1.0-val) + foundPrev[2] * val;
						this.targetFaceObj[3] = this.targetFaceObj[3] * (1.0-val) + foundPrev[3] * val;
					} else if (!foundPrev && foundNext) {
						this.targetFaceObj[0] = this.targetFaceObj[0] * (1.0-val) + foundNext[0] * val;
						this.targetFaceObj[1] = this.targetFaceObj[1] * (1.0-val) + foundNext[1] * val;
						this.targetFaceObj[2] = this.targetFaceObj[2] * (1.0-val) + foundNext[2] * val;
						this.targetFaceObj[3] = this.targetFaceObj[3] * (1.0-val) + foundNext[3] * val;
					} else if (foundPrev && foundNext) {
						this.targetFaceObj[0] = this.targetFaceObj[0] * (1.0-(val*2)) + foundNext[0] * val + foundPrev[0] * val;
						this.targetFaceObj[1] = this.targetFaceObj[1] * (1.0-(val*2)) + foundNext[1] * val + foundPrev[1] * val;
						this.targetFaceObj[2] = this.targetFaceObj[2] * (1.0-(val*2)) + foundNext[2] * val + foundPrev[2] * val;
						this.targetFaceObj[3] = this.targetFaceObj[3] * (1.0-(val*2)) + foundNext[3] * val + foundPrev[3] * val;
					}

					this.sp.updateFacesFrame(this.sp.facesFrames);
				}.bind(faceObj));

				planeContainer.position.set(faces[j].face[0],faces[j].face[1],0);
				planeContainer.scale.set(faces[j].face[2],faces[j].face[3],1);

			}
		}
		this.mouseMove(false);
	}

	//update onion skin
	updateTrail(faceObj) {
		//previous trail
		for (var i=0; i<2; i++) {
			var faces = sp.facesInfo.faces[sp.facesFrames-i-1];
			if (faces) {
				faceObj.trailPrev[i].visible = false;
				for (var j=0; j<faces.length; j++) {
					if (faces[j].id == this.id || (faces.length==1 && this.currentFaces.length==1)) {
						var foundPrev = faces[j].face;
						faceObj.trailPrev[i].visible = true;
						faceObj.trailPrev[i].position.set(foundPrev[0],foundPrev[1],0);
						faceObj.trailPrev[i].scale.set(foundPrev[2],foundPrev[3],1);
					}
				}
			}
		}

		//next trail
		for (var i=0; i<2; i++) {
			var faces = sp.facesInfo.faces[sp.facesFrames+i+1];
			if (faces) {
				faceObj.trailNext[i].visible = false;
				for (var j=0; j<faces.length; j++) {
					if (faces[j].id == this.id || (faces.length==1 && this.currentFaces.length==1)) {
						var foundNext = faces[j].face;
						faceObj.trailNext[i].visible = true;
						faceObj.trailNext[i].position.set(foundNext[0],foundNext[1],0);
						faceObj.trailNext[i].scale.set(foundNext[2],foundNext[3],1);
					}
				}
			}
		}

		// for (var i=0; i<6; i++) {

	}

	addFace() {
		if (!this.facesInfo.faces[this.facesFrames]) this.facesInfo.faces[this.facesFrames] = [];
		var faces = this.facesInfo.faces[this.facesFrames];

		var minId = -1; var exists = true;
		while (exists) {
			minId++;
			exists = false;
			for (var i=0; i<faces.length; i++) {
				if (faces[i].id == minId) {
					exists = true;
				}
			}
		}
		var box = [0.5,0.5,0.5,0.25];
		var facesPrev = this.facesInfo.faces[this.facesFrames-1];
		if (facesPrev && facesPrev.length > 0 && facesPrev.length > faces.length) {
			var boxCopy = facesPrev[facesPrev.length-1].face;
			box = [boxCopy[0],boxCopy[1],boxCopy[2],boxCopy[3]];
		}

		faces.push({
			id: minId,
			face: box
		});
		this.updateFacesFrame(this.facesFrames);
		return
	}

	//-----------
	//
	// Webgl UI interaction
	//
	//-----------
	mouseDown(e, px, py) {
		if (e) { this.currentX = px; this.currentY = py; }
		if (this.selectedFace) this.draggingFace = true;

	}
	mouseUp(e, px, py) {
		if (e) { this.currentX = px; this.currentY = py; }
		this.draggingFace = false;

	}
	mouseMove(e, px, py, shiftKey) {
		var lastX = this.currentX;
		var lastY = this.currentY;
		if (e) { this.currentX = px; this.currentY = py; }
		
		//drag face
		if (this.draggingFace) {

			if (this.selectedFace) {

				var dx = this.currentX - lastX;
				var dy = this.currentY - lastY;
				var faceObj = this.selectedFace.targetFaceObj;
				var p = this.selectedFace.plane;

				//rescale
				if (shiftKey) {
					faceObj[2] = Math.max(faceObj[2]+dx,0.001);
					faceObj[3] = Math.max(faceObj[3]+dy,0.001);

					p.scale.set(faceObj[2],faceObj[3],1);

				} else {
					//move
					faceObj[0] += dx;
					faceObj[1] += dy;

					var p = this.selectedFace.plane;
					p.position.set(faceObj[0],faceObj[1],0);
				}
				
			}

		//hover select face
		} else if (this.currentFaces) {
			var target = null;
			for (var j=0; j<this.currentFaces.length; j++) {
				var p = this.currentFaces[j].plane;
				if (this.currentX >= p.position.x-p.scale.x/2 && this.currentX <= p.position.x+p.scale.x/2 && this.currentY >= p.position.y-p.scale.y/2 && this.currentY <= p.position.y+p.scale.y/2) {
					target = this.currentFaces[j];
				}
			}

			if (this.selectedFace && this.selectedFace !== target) {
				$(this.selectedFace.div).toggleClass('selected', false);
				this.selectedFace.mat.color.set(0xdddddd);
			}
			if (target) {
				this.lastSelectedFace = this.selectedFace = target;
				$(this.selectedFace.div).toggleClass('selected', true);
				this.selectedFace.mat.color.set(0xffffff);

				$(window.renderer.domElement).css('cursor', 'move');
			} else {
				$(window.renderer.domElement).css('cursor', 'pointer');
			}
		}
	}

	incrFrame(direction) {
		this.currentTime = Utils.clamp(
			Math.floor(this.currentTime*24 + direction)/24,
			0,
			(this.numFrames+2)/24
		);
	}


	cancelAll() {
		this.facesInfo = this.originalFaces;
		this.originalFaces = null;
		this.sceneSetup = false;
	}
	saveExit() {
		this.originalFaces = null;
		this.sceneSetup = false;
	}


	keydown(e) {
		console.log(e);
		if (e.keyCode == 32) {

		} else if (e.shiftKey && (e.keyCode == 39||e.keyCode == 37||e.keyCode == 38 || e.keyCode ==40)) {

			console.log(e.keyCode, this.lastSelectedFace);
			if (this.lastSelectedFace) {
				if (e.keyCode == 39) this.lastSelectedFace.targetFaceObj[0] += 1.0/288;
				if (e.keyCode == 37) this.lastSelectedFace.targetFaceObj[0] -= 1.0/288;
				if (e.keyCode == 38) this.lastSelectedFace.targetFaceObj[1] -= 1.0/288;
				if (e.keyCode == 40) this.lastSelectedFace.targetFaceObj[1] += 1.0/288;
				this.updateFacesFrame(this.facesFrames);
			}

		} else if (e.keyCode == 39||e.keyCode == 37) {
			var clonedFaces = [];
			if (e.altKey) {
				clonedFaces = [].concat(this.currentFaces);
				console.log("cloning",clonedFaces,this.facesFrames)
			}
			this.update({currentTime:Utils.clamp(
				Math.floor(this.currentTime*24 + (e.keyCode == 39?1:-1))/24,
				0, (this.numFrames+2)/24
			)});
			var cf = Math.floor(this.currentTime*24);
			this.updateFacesFrame(cf);

			if (e.altKey) {
				console.log("cloned, ",clonedFaces,this.facesFrames)
				for (var j=0; j<clonedFaces.length; j++) {
					var found = false;
					for (var i=0; i<this.currentFaces.length; i++) {
						found = found||this.currentFaces[i].id==clonedFaces[j].id;
						if (this.currentFaces[i].id==clonedFaces[j].id) {
							console.log("founnnd",this.currentFaces[i], clonedFaces[j]);
						}
					}
					if (!found) {
						var faces = this.facesInfo.faces[this.facesFrames];
						faces.push({
							id: clonedFaces[j].id,
							face: [clonedFaces[j].targetFaceObj[0],clonedFaces[j].targetFaceObj[1],clonedFaces[j].targetFaceObj[2],clonedFaces[j].targetFaceObj[3]]
						});
						this.updateFacesFrame(this.facesFrames);
					}
				}
			}

		}

	}

	dispose() {
		super.dispose();
		this.debugDivShown = false;
		$(window.renderer.domElement).css('cursor', 'pointer');
	}
	stop() {
		super.stop();
	}


	render() {
		super.render();
		if (!this.sceneSetup) return;
		renderer.render(this.facesScene, Utils.topLeftCamera, this.fbo.texture, false);
	}
};

export default SpritesheetVideoFaceEditor;


