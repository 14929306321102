import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';
import Fbo from '../utils/Fbo.js'
import RendererController from '../controllers/RendererController.js';
import MultiStorage from '../utils/MultiStorage.js';

import MottoView from './MottoView.js';

//------------------------
// 
//  Base class for all views
//
//-------------------------
class BranchDebugView extends MottoView {

	constructor(_info, batchName) {
		super({
			"description": {
				"en": "- Branch -",
				"fr": ""
			},
			"type": "text",
			"textType": "top",
			"background": "white",
			branches: _info.branches,
			selectedBranch: _info.selectedBranch
		}, batchName);
	}
	

	//
	// When starting - decode image for rendering
	//
	start() {
		// console.log("START: BRANCH: ",this.info.selectedBranch);
		if (this.started) return;
		super.start();
		if (!this.branchSelect) {
			this.branchSelect = document.createElement('select');
			$(this.branchSelect).addClass('branch-debug-select');
			for (var i=0; i<this.info.branches.length; i++) {
				var opt = $('<option value='+i+'>'+this.info.branches[i].condition+' - '+this.info.branches[i].condition_id+'</option>');
				if (i==this.info.selectedBranch) opt.attr('selected', 'true');
				$(this.branchSelect).append(opt);
			}
			$(this.mainDiv).css('border-color', 'red');
			this.branchSelect.onchange = this.onchange;

			this.contentDiv.appendChild(this.branchSelect);
		} else {
			$(this.branchSelect).remove();
			this.contentDiv.appendChild(this.branchSelect);
		}
		
	}

	//
	// When disposing - release image
	//
	stop() {
		if (!this.started) return;
		super.stop();
	}

	update() {
		if (!this.started) return;
		super.update();
	}

	//
	// Render image to webgl??
	//
	render() {
		if (!this.active) return;
		super.render();
	}
}

export default BranchDebugView;	

