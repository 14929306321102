import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';
import Fbo from '../utils/Fbo.js'
import RendererController from '../controllers/RendererController.js';
import MultiStorage from '../utils/MultiStorage.js';
import MotionController from '../controllers/MotionController.js';

import MontageController from '../controllers/MontageController.js';
import Montage from '../objects/Montage.js';
import SpritesheetVideo from '../objects/SpritesheetVideo.js';
import MaterialController from '../controllers/MaterialController.js';;

import MottoView from './MottoView.js';

//------------------------
// 
//  Base class for all views
//
//-------------------------
class MontageOrientationView extends MottoView {

	constructor(_info, batchName) {

		super(_info, batchName);

		this.textType = this.info["textType"] || 'top';
		this.uuid = Utils.generateUUID();
		// this.textBackground = (this.info.textBackground || (this.info.textBackground==undefined && this.info.textBackgroundColor)) ? (this.info.textBackgroundColor || ( this.info.dark ? '#000000' : '#ffffff')) : '#ffffff';
		this.textBackground = this.info.textBackground;
		this.pageNumberWhite = true;
		if (!this.info.textBackground) {
			this.textBackground = this.info.dark ? '#000000' : '#ffffff';
		}
		
		//
		// Add image to preloading queue
		//
		this.loaded = false;
		this.videoWidth = 288;
		this.videoHeight = 512;
		this.currentTime = 0;
		this.currentSpritesheet = 0;
		
		// Create all necessary spritesheets
		this.montages = [];
		this.montagesByName = {};
		if (this.params.up) {this.currentMontage = this.montageUp = new Montage(this.params.up, batchName); this.montages.push(this.currentMontage); this.montagesByName['up'] = this.currentMontage; this.currentOrientation='up';}
		if (this.params.down) {this.currentMontage = this.montageDown = new Montage(this.params.down, batchName); this.montages.push(this.currentMontage); this.montagesByName['down'] = this.currentMontage; this.currentOrientation='down';}
		if (this.params.left) {this.currentMontage = this.montageLeft = new Montage(this.params.left, batchName); this.montages.push(this.currentMontage); this.montagesByName['left'] = this.currentMontage; this.currentOrientation='left';}
		if (this.params.right) {this.currentMontage = this.montageRight = new Montage(this.params.right, batchName); this.montages.push(this.currentMontage); this.montagesByName['right'] = this.currentMontage; this.currentOrientation='right';}

		if (this.params.north) {this.currentMontage = this.montageNorth = new Montage(this.params.north, batchName); this.montages.push(this.currentMontage); this.montagesByName['north'] = this.currentMontage; this.currentOrientation='north';}
		if (this.params.south) {this.currentMontage = this.montageSouth = new Montage(this.params.south, batchName); this.montages.push(this.currentMontage); this.montagesByName['south'] = this.currentMontage; this.currentOrientation='south';}

		if (this.params.northsouth) {this.currentMontage = this.montageNorth = new Montage(this.params.northsouth, batchName); this.montages.push(this.currentMontage); this.montagesByName['northsouth'] = this.currentMontage; this.currentOrientation='northsouth';}
		if (this.params.opposite) {this.currentMontage = this.montageOpposite = new Montage(this.params.opposite, batchName); this.montages.push(this.currentMontage); this.montagesByName['opposite'] = this.currentMontage; this.currentOrientation='opposite';}


		if (this.params.middle || this.params.straight || this.params.front) {this.currentMontage = this.montageFront =  new Montage(this.params.middle || this.params.straight || this.params.front, batchName); this.montages.push(this.currentMontage); this.montagesByName['middle'] = this.currentMontage; this.currentOrientation='middle';}
		
		this.angleId = 0;
		this.numAngleId = 0;
		// this.montage = new Montage(this.params.montageA, batchName); //MontageController.create(this.params);
		// this.montageBlend = new Montage(this.params.montageB, batchName); //MontageController.create(this.params);
	
		if (this.params.arrow) {
			this.arrowTexture = Loader.addTexture(batchName, SETTINGS.UI_IMAGES_URL+"arrow.png", {
				format: THREE.RGBAFormat,
				wrapping: THREE.ClampToEdgeWrapping,
				generateMipmaps: false,
				minFilter: THREE.LinearFilter,
				magFilter:THREE.LinearFilter
			});
		}

	}


	//
	// When starting - decode image for rendering
	//
	start() {
		if (this.started) return;
		super.start();

		this.targetNorth = Math.abs(MotionController.northPc) <= Math.abs(MotionController.southPc);
		if (this.params.invertOrientation) this.targetNorth = !this.targetNorth;
		this.scene = new THREE.Scene();
		this.playbackMaterial = MaterialController.getMaterial('playback');
		this.plane = new THREE.Mesh(Utils.planeGeometry, this.playbackMaterial);
		this.playbackMaterial.uniforms.tDiffuse.value = Utils.blackTexture;
		this.scene.add(this.plane);
		this.camera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, -0.5, 0.5);

		//prepare spritesheets
		for (var i=0; i<this.montages.length; i++) {
			this.montages[i].replaceUserVideos();
			this.montages[i].prepare();
		}
	}

	activate() {
		if (this.active) return;
		super.activate();

		this.orientationReference = MotionController.horizontalOrientation;

		//prepare spritesheets
		for (var i=0; i<this.montages.length; i++) {
			this.montages[i].replaceUserVideos();
			this.montages[i].prepare();
			this.montages[i].activate();
		}

		if (this.params.arrow) {
			this.arrowScene = new THREE.Scene();
			this.arrowPlane = new THREE.Mesh(Utils.planeGeometry, new THREE.MeshBasicMaterial({
				side: THREE.DoubleSide,
				depthTest: false,
				depthWrite: false,
				transparent: true,
				blending: THREE.NormalBlending,
				// color: 'white'
				map: this.arrowTexture
			}));
			var ratio = this.renderWidth() / this.renderHeight();
			this.arrowCamera = new THREE.OrthographicCamera( -ratio, ratio, 1, -1, -1, 1);
			this.arrowScene.add(this.arrowPlane);
		}
	}

	//
	// When disposing - release image
	//
	stop() {
		if (!this.started) return;
		super.stop();
		this.playbackMaterial.dispose();
		this.scene.remove(this.plane);
		if (this.arrowTexture) this.arrowTexture.dispose();
		if (this.arrowPlane) {
			this.arrowPlane.material.dispose();
			this.arrowPlane = null;
			this.arrowScene = null;
			this.arrowCamera = null;
		}
		this.plane = this.scene = this.camera = null;
		for (var i=0; i<this.montages.length; i++) {
			this.montages[i].dispose();
			this.montages[i].prepare();
			this.montages[i].activate();
		}
	}
	
	update() {
		if (!this.started) return;
		super.update();
		for (var i=0; i<this.montages.length; i++) {
			this.montages[i].contentDiv = this.contentDiv;
		}



		//prepare spritesheets
		if (this.positionX > -0.001 || AppStatus.goingBackwards) {
			if (!this.active) {
				for (var i=0; i<this.montages.length; i++) {
					this.montages[i].replaceUserVideos();
				}
			}
			for (var i=0; i<this.montages.length; i++) {
				this.montages[i].prepare();
			}
		}
		var montageReady = true;
		for (var i=0; i<this.montages.length; i++) {
			if (!this.montages[i].isReady()) montageReady = false;
		}
		this.ready = this.active && montageReady && !!this.currentMontage;

		//
		// Playback
		//
		if (this.active) {
			for (var i=0; i<this.montages.length; i++) {
				this.montages[i].update();
				AppStatus.frameNeedsUpdate = AppStatus.frameNeedsUpdate||this.montages[i].needsUpdate;
			}

			var ot = this.currentOrientation;
			if (this.params.up && MotionController.upDownPc > 0.225) ot = 'up';
			else if (this.params.down && MotionController.upDownPc < -0.45) ot = 'down';
			else if ((this.params.up||this.params.down) && (this.params.middle || this.params.straight || this.params.front)) ot = 'middle';



			var angle = Utils.distanceBetweenAngles(this.orientationReference, MotionController.horizontalOrientation) * Utils.directionBetweenAngles(this.orientationReference, MotionController.horizontalOrientation);

			if (this.params.left && angle > 0.45) ot = 'left';
			else if (this.params.right && angle < -0.45) ot = 'right';
			else if ((this.params.left||this.params.right) && (this.params.middle || this.params.straight || this.params.front)) ot = 'middle';


			if (this.params.north) {
				var isNorth = this.currentOrientation === 'north';
				var northBoundary = isNorth ? 0.225 : 0.2;
				if (this.params.north && MotionController.northPc < northBoundary && MotionController.northPc > -northBoundary ) ot = 'north';
				else ot = 'south';
			}

			if (this.params.northsouth) {
				var isNorth = this.currentOrientation === 'northsouth';
				var northBoundary = isNorth ? 0.225 : 0.2;
				var pc = this.targetNorth ? MotionController.northPc : MotionController.southPc;
				if (this.params.northsouth && pc < northBoundary && pc > -northBoundary ) ot = 'northsouth';
				else ot = 'opposite';
			}



			// if (this.params.north && MotionController.northPc < 0.2 && MotionController.northPc > -0.2 ) ot = 'north';
			// else if (this.params.north) ot = 'south';


			if (this.params.arrow && this.arrowTexture.loaded) {
				this.arrowPlane.visible = true;

				var pc = this.targetNorth ? MotionController.northPc : MotionController.southPc;

				this.arrowPlane.rotation.z = Math.pow(Utils.cmap(Math.abs(pc),0.1,1.0,0.0,1.0),0.75) * Math.sign(pc) * Math.PI/2;
				//Utils.cmap(MotionController.northPc, -0.5, 0.5, -Math.PI, Math.PI);

				
				var vw = this.arrowTexture.image.width,
					vh = this.arrowTexture.image.height;

				this.arrowPlane.scale.set(1.0, vh/vw, 1.0).multiplyScalar(0.33 * 1.0/(this.params.arrowScale||1.0));
				// if (vw/vh > this.renderWidth()/this.renderHeight()) { //wider
				// 	this.arrowPlane.scale.set( 1.0 , (vh/vw) / (this.videoHeight/this.videoWidth), 1.0).multiplyScalar(0.33 * 1.0/(this.params.arrowScale||1.0));
				// } else { //taller
				// 	this.arrowPlane.scale.set( (vh/vw) / (this.videoHeight/this.videoWidth) , 1.0, 1.0).multiplyScalar(0.33 * 1.0/(this.params.arrowScale||1.0));
				// }

			}


			//stabilize for a couple of frames
			if (ot == this.angleId) {
				this.numAngleId++;
			} else {
				this.numAngleId = 0;
			}
			this.angleId = ot;

			if ((ot !== this.currentOrientation && this.numAngleId >= 2) || !this.currentOrientation) {
				this.currentOrientation = ot;
				this.currentMontage = this.montagesByName[ot];
			}

		} else {
			if (this.arrowPlane) this.arrowPlane.visible = false;
		}





		// if (this.active) this.montage.update();
		// if (this.active) this.montageBlend.update();
		if (this.ready) {


			this.playbackMaterial.uniforms.tDiffuse.value = this.currentMontage.getTexture();

			//update ratio
			var ratio = this.currentMontage.getRatio();

			if (this.params.fill === 'center') {
		
				this.plane.scale.set(1, ratio * 1 * (this.renderWidth() / this.renderHeight()), 1.0);
				this.plane.scale.multiplyScalar(1.0 - (this.params.padding==undefined?0.1:this.params.padding));
				this.plane.material.uniforms.ratio.value.set(1,1);
				
			} else if (this.params.fill === 'square') {

				if (ratio > 1.0) {
					this.plane.material.uniforms.ratio.value.set(1, this.videoWidth/this.videoHeight);
				} else {
					this.plane.material.uniforms.ratio.value.set(this.videoHeight/this.videoWidth, 1);
				}
				if (this.renderWidth() > this.renderHeight()) {
					this.plane.scale.set(1 / (this.renderWidth() / this.renderHeight()), 1, 1);
				} else {
					this.plane.scale.set(1, 1 / (this.renderHeight()/this.renderWidth()), 1);
				}
				this.plane.scale.multiplyScalar(1.0 - (this.params.padding==undefined?0.1:this.params.padding));

			} else {

				this.plane.material.uniforms.ratio.value.set(1,1);
				if ( (1/ratio) > this.renderWidth() / this.renderHeight()) {
					this.plane.scale.set((1/ratio) / (this.renderWidth() / this.renderHeight()), 1, 1);
				} else {
					this.plane.scale.set(1, (ratio) / (this.renderHeight()/this.renderWidth()), 1);
				}
			}
		}
	}

	//
	// Render image to webgl??
	//
	render() {
		if (!this.active) return;
		super.render();

		if (this.currentMontage) this.currentMontage.render();
		renderer.render(this.scene, this.camera, this.renderTarget, false);
		if (this.params.arrow) {
			renderer.render(this.arrowScene, this.arrowCamera, this.renderTarget, false);
			// console.log(this.arrowPlane.rotation);
		}

	}


	//outside access
	isReady() {
		return super.isReady() && this.ready;
	}
}

export default MontageOrientationView;	

