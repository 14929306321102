import $ from 'jquery';
import * as THREE from 'three';

import SETTINGS from '../controllers/Settings.js';
import AppStatus from '../controllers/AppStatus.js';
import Utils from '../utils/Utils.js';
import LoaderXHR from '../loading/LoaderXHR.js';
import PageLoader from '../loading/PageLoader.js';
import Loader from '../loading/Loader.js';
import Fbo from '../utils/Fbo.js'
import RendererController from '../controllers/RendererController.js';
import MultiStorage from '../utils/MultiStorage.js';
import TextureUpdateController from '../controllers/TextureUpdateController.js';

import MottoView from './MottoView.js';
import PrebakedView from './PrebakedView.js';




//------------------------
// 
//  Base class for all views
//
//-------------------------
class PrebakedImageView extends PrebakedView {

	constructor(_info, batchName) {
		super(_info, batchName);
		this.textType = this.info["textType"] || 'top';

		//
		// Add image to preloading queue
		//
		var url = this.params.image.toLowerCase();
		if (!url.startsWith('photos/')) {url = 'photos/'+url;}
		// url = url.replace('photos/', 'photos/q24/');
		Loader.addXHR(batchName, SETTINGS.IMAGES_URL+url, 'arraybuffer');
		this.extension = this.params.image.split('.').pop().toLowerCase();
		this.loaded = false;
		this.videoWidth = 256;
		this.videoHeight = 256;
	}


	//
	// When starting - decode image for rendering
	//
	start() {
		if (this.started) return;
		super.start();

		//create image & decode
		this.image = new Image();
		var url = this.params.image.toLowerCase();
		if (!url.startsWith('photos/')) {url = 'photos/'+url;}
		// url = url.replace('photos/', 'photos/q24/');
		Loader.getXHRPromise(SETTINGS.IMAGES_URL+url, 'arraybuffer').then(function(ab) {
			if (!this.started || !this.image) return;
			console.log(ab);
			var imageType = this.extension == 'jpg' ? 'jpeg' : this.extension;
			this.blob = ab.value.constructor == ArrayBuffer ? new Blob([new Uint8Array(ab.value)],{'type':'image/'+imageType}) : ab.value; //
			this.url = window.URL.createObjectURL(this.blob);

			this.image.onload = function() {
				if (!this.image) return;
				this.image.onload = null;
				this.loaded = true;
				this.videoWidth = this.image.width;
				this.videoHeight = this.image.height;
				this.texture.image = this.image;
				this.texture.needsUpdate = true;
				this.texture.wasUpdated = false;
				this.texture.loaded = true;
			}.bind(this);
			this.image.src = this.url;
		}.bind(this));
	}

	//
	// When disposing - release image
	//
	stop() {
		if (!this.started) return;
		super.stop();

		window.URL.revokeObjectURL(this.url);
		this.blob = null;
		this.url = null;
		this.image.onload = null;
		this.image = null;
		this.loaded = false;
	}

	update() {
		if (!this.started) return;
		super.update();

		if (this.position > -0.001) {
			if (this.texture && !this.texture.wasUpdated && this.texture.loaded) TextureUpdateController.update(this.texture, false);
		}

	}

	//
	// Render image to webgl??
	//
	render() {
		if (!this.active) return;
		if (this.texture && !this.texture.wasUpdated && this.texture.loaded) TextureUpdateController.update(this.texture, true);
		super.render();

	}
}

export default PrebakedImageView;	

