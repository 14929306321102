import * as THREE from 'three';
import SETTINGS from '../controllers/Settings.js';

import Utils from '../utils/Utils.js'
import MultiStorage from '../utils/MultiStorage.js'
import RendererController from '../controllers/RendererController.js';

import MaterialController from '../controllers/MaterialController.js';
import MontageController from '../controllers/MontageController.js';
import UserVideoController from '../controllers/UserVideoController.js';

import Loader from '../loading/Loader.js';
import PageLoader from '../loading/PageLoader.js';
import SpritesheetVideo from './SpritesheetVideo.js';

// var CanvasTextWrapper = require('canvas-text-wrapper').CanvasTextWrapper;

var NUM_FINGERS = 4;
var NUM_JOINTS = 3;

//------------------------
class SpritesheetVideoDebugHands extends SpritesheetVideo {
	constructor(_info, batchName) {
		super(_info, batchName);
		this.boxesScene = new THREE.Scene();
		this.bonesLeft = [];
		this.bonesRight = [];
		this.fingerPlanes = [];
		this.hands = [this.bonesLeft, this.bonesRight];
		this.trackingEnabled = true;
		this.trackingCenter = new THREE.Vector2(0.5,0.5);
		this.debug = true;

		this.bonesByFinger = [
			// [5,6,7,8],
			// [1,2,3,4],
			// [9,10,11,12],
			// [13,14,15,16],
			// [17,18,19,20]

			[6,7,8],
			[2,3,4],
			[10,11,12],
			[14,15,16],
			[18,19,20]
		];
		
		var colorByFinger = [
			0xff0000,
			0x00ff00,
			0x0000ff,
			0xff00ff,
			0x00ffff
		];

		for (var i=0; i<NUM_FINGERS; i++) {
			
			this.bonesRight.push([]);
			this.bonesLeft.push([]);

			for (var j=0; j<NUM_JOINTS; j++) {
				this.bonesLeft[i][j] = new THREE.Mesh(Utils.planeGeometry, new THREE.MeshBasicMaterial({
					transparent: true,
					opacity: 0.1,
					color: new THREE.Color(colorByFinger[i]),
					blending: THREE.NormalBlending,
					side: THREE.DoubleSide
				}));
				this.bonesLeft[i][j].scale.set(0.05, 0.05*9/16,0.05);
				this.boxesScene.add(this.bonesLeft[i][j]);


				this.bonesRight[i][j] = new THREE.Mesh(Utils.planeGeometry, new THREE.MeshBasicMaterial({
					transparent: true,
					opacity: 0.1,
					color: new THREE.Color(colorByFinger[i]),
					blending: THREE.NormalBlending,
					side: THREE.DoubleSide
				}));
				this.bonesRight[i][j].scale.set(0.05, 0.05*9/16,0.05);
				this.boxesScene.add(this.bonesRight[i][j]);
			}
		}

		for (var i=0; i<4; i++) {
			this.fingerPlanes[i] = new THREE.Mesh(Utils.planeGeometry, new THREE.MeshBasicMaterial({
				transparent: true,
				opacity: 1.0,
				color: new THREE.Color(0xffffff),
				blending: THREE.NormalBlending,
				side: THREE.DoubleSide
			}));
			this.fingerPlanes[i].scale.set(0.05, 0.05*9/16, 0.05);
			this.boxesScene.add(this.fingerPlanes[i]);
		}

		this.currentAngle = 0;
		this.currentDist = 0.0;

		this.targetBox = new THREE.Mesh(Utils.planeGeometry, new THREE.MeshBasicMaterial({
			transparent: true,
			opacity: 1.0,
			color: new THREE.Color(0x0000ff),
			blending: THREE.NormalBlending,
			side: THREE.DoubleSide
		}));
		this.targetBox.scale.set(0.05, 0.05*9/16,0.05);

		this.boxesScene.add(this.targetBox);
	}

	preload(batchName) {
		super.preload(batchName);
	}

	prepare() {
		super.prepare();
	}

	onAnalysisLoaded(info) {
		super.onAnalysisLoaded(info);
	}

	update(opt) {
		super.update(opt);

		if (this.isReady() && this.analysis) {

			this.trackingEnabled = true;

			var currentFrame =  Utils.clamp(this.lastFrame+1,0,Math.floor(this.numFrames)-1);

			// console.log(this.lastFrame, this.numFrames, this.currentFrame, this.frameOffset);
			var poses = this.analysis.hands[currentFrame+this.frameOffset];
			
			// for (var i=0; i<9; i++) {
			// 	this.bones[i][0].material.opacity = this.bones[i][1].material.opacity  = 0.0;				
			// }


			//select best hand during played frames
			var confByFinger = [[0,0,0,0,0], [0,0,0,0,0]];
			for (var k=0; k<Math.floor(this.numFrames); k++) {
				for (var finger=0; finger<NUM_FINGERS; finger++) {
					for (var boneInFinger=0; boneInFinger<NUM_JOINTS; boneInFinger++) {
						var bone = this.bonesByFinger[finger][boneInFinger];
						confByFinger[0][finger] += this.analysis.hands[k+this.frameOffset][0][0][bone][2];
						confByFinger[1][finger] += this.analysis.hands[k+this.frameOffset][1][0][bone][2];
					}
				}
			}

			//select hand
			var conf = [ 0, 0 ];
			for (var i=0; i<NUM_FINGERS; i++) {
				conf[0] += confByFinger[0][i] * (NUM_FINGERS-i);
				conf[1] += confByFinger[1][i] * (NUM_FINGERS-i);
			}
			var selected_hand = conf[0] > conf[1] ? 0 : 1;


			//now select the best finger, ideally the index
			confByFinger = confByFinger[selected_hand];
			var selectedFinger = 0;
			var selectedFingerConf = -1;
			for (var i=0; i<NUM_FINGERS; i++) {
				if (confByFinger[i] * (0.5 + (NUM_FINGERS-i)) >= selectedFingerConf) {
					selectedFingerConf = confByFinger[i] * (0.5 + (NUM_FINGERS-i));
					selectedFinger = i;
				}
			}


			if (confByFinger[0]/Math.floor(this.numFrames) > 0.5) selectedFinger = 0;

			// console.log(selectedFinger, confByFinger, confByFinger[0]/Math.floor(this.numFrames));

			//
			// now select for consistency with last
			// every frame recalc finger score by substraction distance to last positions from confidence
			//
			var lastFingerPos = [];
			for (var boneInFinger=0; boneInFinger<NUM_JOINTS; boneInFinger++) {
				var bone = this.bonesByFinger[selectedFinger][boneInFinger];
				lastFingerPos[boneInFinger] = [
					this.analysis.hands[0+this.frameOffset][selected_hand][0][bone][0]/341,
					this.analysis.hands[0+this.frameOffset][selected_hand][0][bone][1]/341
				];
			}

			// console.log("SELECTED FINGER:",0, selectedFinger, confByFinger);

			var selectedFingerLocal = selectedFinger;
			for (var k=1; k<currentFrame; k++) {
				var localConf = [[0,0,0,0,0], [0,0,0,0,0]];

				for (var hand=0; hand<2; hand++) {
					for (var finger=0; finger<NUM_FINGERS; finger++) {
						for (var boneInFinger=0; boneInFinger<NUM_JOINTS; boneInFinger++) {
							var bone = this.bonesByFinger[finger][boneInFinger];

							// console.log("adding conf", finger, boneInFinger, this.analysis.hands[k+this.frameOffset][selected_hand][0][bone][2], (0.5 + 0.5*Math.abs(5 - (selectedFingerLocal-finger))))
							localConf[hand][finger] += this.analysis.hands[k+this.frameOffset][hand][0][bone][2] * (0.5 + Math.abs(10 - (selectedFingerLocal-finger)*2));

							// if (boneInFinger == NUM_JOINTS-1) {
								localConf[hand][finger] -= Utils.distance(
									this.analysis.hands[k+this.frameOffset][hand][0][bone][0]/341,
									this.analysis.hands[k+this.frameOffset][hand][0][bone][1]/341,
									lastFingerPos[boneInFinger][0],
									lastFingerPos[boneInFinger][1]
								)*100.0;
							// }
							
						}
					}
				}

				// console.log(localConf);
				var selectedFingerLocalConf = -10000000;
				for (var hand=0; hand<2; hand++) {
					for (var finger=0; finger<NUM_FINGERS; finger++) {
						if (localConf[hand][finger] >= selectedFingerLocalConf) {
							selectedFingerLocalConf = localConf[hand][finger];
							selected_hand = hand;
							selectedFingerLocal = finger;
							// console.log("selecting ",hand,finger);
						}
					}
				}

				// console.log("SELECTED FINGER:",k, selectedFingerLocal, localConf);

				for (var boneInFinger=0; boneInFinger<NUM_JOINTS; boneInFinger++) {
					var bone = this.bonesByFinger[selectedFingerLocal][boneInFinger];
					lastFingerPos[boneInFinger] = [
						this.analysis.hands[k+this.frameOffset][selected_hand][0][bone][0]/341,
						this.analysis.hands[k+this.frameOffset][selected_hand][0][bone][1]/341
					];
				}
			}

			//
			// Place planes
			//
			for (var i=0; i<NUM_JOINTS; i++) {
				this.fingerPlanes[i].position.set(lastFingerPos[i][0], lastFingerPos[i][1], 0);
				if (i==NUM_JOINTS-1) this.targetBox.position.copy(this.fingerPlanes[i].position);
			}	
			for (var finger=0; finger<NUM_FINGERS; finger++) {
				for (var boneInFinger=0; boneInFinger<NUM_JOINTS; boneInFinger++) {
					var bone = this.bonesByFinger[finger][boneInFinger];
					 this.bonesRight[finger][boneInFinger].position.set(this.analysis.hands[currentFrame+this.frameOffset][0][0][bone][0]/341, this.analysis.hands[currentFrame+this.frameOffset][0][0][bone][1]/341, 0);
					 this.bonesLeft[finger][boneInFinger].position.set(this.analysis.hands[currentFrame+this.frameOffset][1][0][bone][0]/341, this.analysis.hands[currentFrame+this.frameOffset][1][0][bone][1]/341, 0);
				}
			}

			this.trackingCenter.x = this.targetBox.position.x;
			this.trackingCenter.y = this.targetBox.position.y;


			// for (var k=0; k<Math.floor(this.numFrames); k++) {
			// 	for (var j=0; j < 2; j++) {
			// 		for (var i = 0; i<this.analysis.hands[k+this.frameOffset][j][0].length; i++) {
			// 			conf[j] += this.analysis.hands[k+this.frameOffset][j][0][i][2];
			// 		}
			// 	}
			// }
			// selected_hand = conf[0] > conf[1] ? 0 : 1;


			//now select a finger based on conf and consistency






			return;


			// var handPoses = poses[j][0]
			// var bones = 
			var maxConf = Math.max(conf[0], conf[1]) / Math.floor(this.numFrames);
			var maxPouce = Math.max(pouceConf[0], pouceConf[1]) / Math.floor(this.numFrames);

			if (maxPouce > maxConf && maxConf < 0.5) {
				
				selected_hand = pouceConf[0] >= pouceConf[1] ? 0 : 1;
				var j = selected_hand;
				for (var i=1; i<5; i++) {
					this.bones[i][j].position.set(poses[j][0][i][0]/341, poses[j][0][i][1]/341, 0);
					this.bones[i][j].material.opacity = 0.33 + poses[j][0][i][2];
				}
				var boneA = this.bones[2][j].position;
				var boneB = this.bones[3][j].position;
				var boneC = this.bones[4][j].position;

				var angleA = Math.atan2(boneA.y-boneB.y, boneA.x-boneB.x);
				var angleB = Math.atan2(boneA.y-boneC.y, boneA.x-boneC.x);
				var angleC = Math.atan2(boneB.y-boneC.y, boneB.x-boneC.x);
				var targetAngle = Utils.lerpAngle(Utils.lerpAngle(angleA, angleB, 0.5),angleC,0.5);

				var dist = Math.sqrt(  Math.pow(boneB.x-boneC.x,2)  + Math.pow(boneB.y-boneC.y,2)  );

				this.currentAngle = Utils.lerpAngle(this.currentAngle, targetAngle, 0.5);
				this.currentDist = Utils.lerp(this.currentDist, dist, 0.5);

				this.targetBox.position.lerp(boneC.clone().sub(new THREE.Vector3(Math.cos(this.currentAngle)*this.currentDist, Math.sin(this.currentAngle)*this.currentDist)), 0.5);


			} else {

				selected_hand = conf[0] >= conf[1] ? 0 : 1;
				var j = selected_hand;
				for (var i=5; i<9; i++) {
					this.bones[i][j].position.set(poses[j][0][i][0]/341, poses[j][0][i][1]/341, 0);
					this.bones[i][j].material.opacity = 0.33 + poses[j][0][i][2];
				}
				var boneA = this.bones[6][j].position;
				var boneB = this.bones[7][j].position;
				var boneC = this.bones[8][j].position;

				var angleA = Math.atan2(boneA.y-boneB.y, boneA.x-boneB.x);
				var angleB = Math.atan2(boneA.y-boneC.y, boneA.x-boneC.x);
				var angleC = Math.atan2(boneB.y-boneC.y, boneB.x-boneC.x);
				var targetAngle = Utils.lerpAngle(Utils.lerpAngle(angleA, angleB, 0.5),angleC,0.5);

				var dist = Math.sqrt(  Math.pow(boneB.x-boneC.x,2)  + Math.pow(boneB.y-boneC.y,2)  );

				this.currentAngle = Utils.lerpAngle(this.currentAngle, targetAngle, 0.5);
				this.currentDist = Utils.lerp(this.currentDist, dist, 0.5);

				this.targetBox.position.lerp(boneC.clone().sub(new THREE.Vector3(Math.cos(this.currentAngle)*this.currentDist, Math.sin(this.currentAngle)*this.currentDist)), 0.5);

			}
		}
	}

	dispose() {
		super.dispose();


	}

	render() {
		super.render();
		if (this.debug) renderer.render(this.boxesScene, Utils.topLeftCamera, this.fbo.texture, false);

	}
};

export default SpritesheetVideoDebugHands;


